import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "../../../../_services/dataservices.service";
import { RefreshContentService } from "../../../../_services/refresh-content.service";

@Component({
  selector: "app-access-control-deleted",
  templateUrl: "./deleted.component.html",
  styleUrls: ["./deleted.component.css"],
})
export class DeletedAccessControlComponent implements OnInit {
  accessControlDeletedlist: any = [];
  //Pagination
  currentPage: any = 1;
  totalCount: any = 0;
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
  pageSize: any = 10;
  isMasterSel: Boolean;
  checkedAccessControlList: any;
  constructor(
    private calldata: DataservicesService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    public refreshContentService: RefreshContentService
  ) {
    this.isMasterSel = false;
  }

  ngOnInit(): void {
    this.getDeletedAccessControlList();
  }

  getDays(days) {
    return days
      .map(function (item) {
        return item.label;
      })
      .join(", ");
  }

  getDeletedAccessControlList() {
    this.spinner.show();
    this.calldata.getDeletedList("accesscontrol", '').subscribe(
      (res: any) => {
        if (res.status) {
          this.accessControlDeletedlist = res.data.reverse();
          this.spinner.hide();
        } else {
          this.toast.info(res.message);
          this.spinner.hide();
        }
      },
      (error) => {
        this.toast.error("something went wrong");
        this.spinner.hide();
      }
    );
  }

  //Pagination
  onProjectPageChange(event) {
    this.currentPage = event;
  }

  changePage(value) {
    this.pageSize = parseInt(value);
  }

  //Delete Permenatly
  deletePermantly() {
    this.spinner.show();
    let deleteIDs = [];
    document
      .querySelector("table tbody")
      .querySelectorAll('tr input[type="checkbox"]:checked')
      .forEach((item) => deleteIDs.push(item.getAttribute("id")));

    if (deleteIDs.length > 0) {
      for (let id of deleteIDs) {
        const data = {
          component: "accesscontrol",
          _id: id,
        };

        this.calldata.forceDeleteData(data).subscribe(
          (res: any) => {
            if (res.status) {
              this.isMasterSel = false;
              this.getDeletedAccessControlList();
              this.toast.success("Access Control " + res.message);
            } else {
              this.toast.info("Access Control " + res.message);
              this.spinner.hide();
            }
          },
          (error) => {
            this.toast.error("Access Control not deleted successfully");
            this.spinner.hide();
          }
        );
      }
    } else {
      this.toast.error("Select a Access Control");
      this.spinner.hide();
    }
  }

  checkUncheckAll() {
    for (var i = 0; i < this.accessControlDeletedlist.length; i++) {
      this.accessControlDeletedlist[i].isSelected = this.isMasterSel;
    }
  }

  isAllSelected() {
    this.isMasterSel = this.accessControlDeletedlist.every(function (
      item: any
    ) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedAccessControlList = [];
    for (var i = 0; i < this.accessControlDeletedlist.length; i++) {
      if (this.accessControlDeletedlist[i].isSelected)
        this.checkedAccessControlList.push(
          this.accessControlDeletedlist[i]._id
        );
    }

    return this.checkedAccessControlList;
  }

  //Restore Deleted
  restoreDeleted() {
    this.spinner.show();
    let restoreIDs = this.getCheckedItemList();

    if (restoreIDs.length > 0) {
      for (let id of restoreIDs) {
        const data = {
          component: "accesscontrol",
          _id: id,
        };

        this.calldata.restoreDeletedData(data).subscribe(
          (res: any) => {
            if (res.status) {
              this.getDeletedAccessControlList();
              this.toast.success(res.message);
            } else {
              this.toast.info(res.message);
              this.spinner.hide();
            }
          },
          (error) => {
            this.toast.error("Access Control not restored");
            this.spinner.hide();
          }
        );
      }
    } else {
      this.toast.error("Select a Access Control");
      this.spinner.hide();
    }
  }
}
