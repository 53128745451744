import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from 'src/app/_services/dataservices.service';

import QrCodeWithLogo from "qrcode-with-logos";

@Component({
    selector: 'app-view-eform-modal',
    templateUrl: './view-eform-modal.component.html',
    styleUrls: ['./view-eform-modal.component.css']
})
export class ViewEformModalComponent implements OnInit {

    scaneformid: string;
    formName: string;
    eFormurl: string;
    scaneformdata: any;
    qrcodeData: string = '';
    qrShortLink: any = "";
    componentType: string = '';

    eFormFields = [];
    eFormId: string;
    eFormDescription: string;
    eFormUrl: string;
    eFormRedirectURL: any;

    portalLogoImg: any = '';
    portalLogoLabel: any = '';

    constructor(
        private _bsModalRef: BsModalRef,
        private router: Router,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
    ) { }

    ngOnInit(): void {
        this.portalLogoImg = sessionStorage.getItem('portalLogo');
        this.getEformDetails();
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    //Fetch eForm 

    getEformDetails() {
        this.spinner.show();
        let data = {
            eformurl: this.eFormUrl
        }
        this.calldata.generateeFormDeepLinking(data).subscribe((res: any) => {
            if (res.status) {
                this.spinner.hide();

                this.qrcodeData = this.eFormUrl
                this.qrShortLink = res.data.shortLink

                //qrcode.toCanvas().then(() => {
                //    qrcode.toImage().then(() => {
                //        qrcode.downloadImage("hello world");
                //    });
                //});
            } else {
                this.spinner.hide();
                this.toast.error(res.message)
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
        //this.link(this.eFormRedirectURL);
        // let data = {
        //     "dynamicLinkInfo": {
        //         "domainUriPrefix": "https://eforms.akcess.io",
        //         "link": this.eFormRedirectURL,
        //         "androidInfo": {
        //             "androidPackageName": "com.akcess.io"
        //         },
        //         "iosInfo": {
        //             "iosBundleId": "com.akcess.io.ID"
        //         }
        //     }
        // }

        // this.calldata.getQRcodeURL(data).subscribe((res: any) => {
        //     this.qrcodeData = res.shortLink
        // })
        this.modifyFields(this.castFields());

    }

    //link(value) {
    //    let eformurl = value
    //    let data = {
    //        "dynamicLinkInfo": {
    //            "domainUriPrefix": "https://eforms.akcess.io",
    //            "link": eformurl,
    //            "androidInfo": {
    //                "androidPackageName": "com.akcess.io"
    //            },
    //            "iosInfo": {
    //                "iosBundleId": "com.akcess.io.ID"
    //            }
    //        }
    //    }

    //    this.calldata.getQRcodeURL(data).subscribe((res: any) => {
    //        this.qrcodeData = res.shortLink
    //    })
    //}

    modifyFields(fields) {
        let deleteIndexes = fields.deletedIndex.map(index => parseInt(index))

        // delete the index at given position
        let gfields = this.eFormFields.filter((field, index) => {
            return !deleteIndexes.includes(index)
        })

        this.eFormFields = gfields
        // append section field data
        for (let field of Object.values(fields.sectionFieldData))
            this.eFormFields.push(field)
        return
    }

    //Sorting Sections and Normal Fields
    castFields() {
        let sectionFieldData = {};
        let deletedIndex = [];

        for (let section in this.eFormFields) {
            if (this.eFormFields[section].section_lable) {
                deletedIndex.push(section)
                // check if section-label already exists
                let status = sectionFieldData[this.eFormFields[section].section_lable] ? true : false;

                let sectionfields = {
                    "options": this.eFormFields[section].options,
                    "_id": this.eFormFields[section]._id,
                    "labelname": this.eFormFields[section].labelname,
                    "key": this.eFormFields[section].key,
                    "keytype": (this.eFormFields[section] && this.eFormFields[section].keytype) ? this.eFormFields[section].keytype : null,
                    "signature_required": this.eFormFields[section].signature_required,
                    "file_verified": this.eFormFields[section].file_verified,
                    "verification_grade": this.eFormFields[section].verification_grade
                }

                if (status) {
                    //sectionFieldData[fields[section].section_lable].sectionfields.push(sectionfields)

                    if (sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title]) {

                        sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title].push(sectionfields)
                    } else {
                        sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title] = [sectionfields]
                    }

                } else {
                    sectionFieldData[this.eFormFields[section].section_lable] = {
                        labelname: this.eFormFields[section].section_lable,
                        key: this.eFormFields[section].section_lable.replace(/\s/g, '').toLowerCase(),
                        //keytype: (this.fields[section] && this.fields[section].section_type) ? this.fields[section].section_type : this.section_keytype,
                        section_color: "#cdc0c0",
                        "section_id": "Yes",
                        sectionfields: {}
                    }

                    sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title] = [sectionfields]
                }

            }

        }
        // add options if section-fields exists
        for (let sect in sectionFieldData) {
            if (sectionFieldData[sect].sectionfields) {
                sectionFieldData[sect].options = []
                for (let field of Object.keys(sectionFieldData[sect].sectionfields))
                    sectionFieldData[sect].options.push({
                        checked: false,
                        key: (field.replace(/\s/, '')).toLowerCase(),
                        uid: Math.random().toString(16).substring(3),
                        lable: field
                    })
            }
        }
        return {
            sectionFieldData: sectionFieldData,
            deletedIndex: deletedIndex
        }
    }


    openEditEform(id, title) {
        this.closeModal();
        this.router.navigate(['panel/addeform', id, title], { state: { example: 'eforms' } });
    }

    callServiceToCopy(inputElement) {
        inputElement.select();
        document.execCommand('copy');
        // alert("URL Copied..")
    }

    viewScanEform(scefid) {
        const tmpdata = { 'eformId': scefid };
        this.calldata.scanef(tmpdata).subscribe(data => {
            if (data['status'] === 1) {
                this.scaneformdata = JSON.stringify(data['results']);
                this.spinner.hide();
            } else {
                // this.toast.info(data['message']);
                this.spinner.hide();
            }
        },
            error => {
                this.toast.error('something went wrong');
                this.spinner.hide();
            }
        )
    }

}
