import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { CommonService } from "src/app/_services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
    FormGroup,
    FormControl,
    FormArray,
    FormBuilder,
    Validators,
} from "@angular/forms";
import { Property } from "src/app/models/property";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-property-form",
    templateUrl: "./form.component.html",
    styleUrls: ["./form.component.css"],
})
export class PropertyFormComponent implements OnInit {
    @Input() mode: String;
    @Input() propertyId: String;
    propertyForm: FormGroup;
    property: Property = new Property();
    submitted = false;
    time = { hour: 13, minute: 30 };
    meridian = true;
    dropdownSettings: any = {};
    file;
    photo: any = "";
    storage_url = environment.url + "storage/";
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        public commonService: CommonService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.storage_url = this.ds.getStorageUrl();
        if (this.propertyId) {
            this.propertyForm = this.fb.group({
                name: ["", Validators.required],
                description: ["", Validators.required],
                price: ["", [Validators.required]],
                photo: ["", [Validators.required]],
            });
            this.spinner.show();
            this.ds.getProperty(this.propertyId).subscribe(
                (response: any) => {
                    this.spinner.hide();
                    if (response.status) {
                        this.property = response.data;
                        this.propertyForm.patchValue({
                            name: this.property.name,
                            description: this.property.description,
                            price: this.property.price,
                            photo: this.property.photo,
                        });
                        this.photo = this.getFullURL(this.property.photo);
                    } else {
                        this.toast.error(response.message);
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        } else {
            this.propertyForm = this.fb.group({
                name: ["", Validators.required],
                description: ["", Validators.required],
                price: ["", [Validators.required]],
                photo: ["", [Validators.required]],
            });
        }
    }

    onUploadChange(evt: any) {
        this.file = evt.target.files[0];

        if (this.file) {
            const reader = new FileReader();

            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.file);

            const formData: FormData = new FormData();
            formData.append("file", this.file);
            this.spinner.show();
            this.ds.uploadPhoto(formData).subscribe(
                (response: any) => {
                    this.spinner.hide();
                    if (response.status == true) {
                        this.propertyForm.patchValue({
                            photo: response.data.file.upload_name,
                        });
                        this.photo = this.getFullURL(
                            response.data.file.upload_name
                        );
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        }
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/property/${file}`;
    }

    handleReaderLoaded(e) {
        this.photo = "data:image/png;base64," + btoa(e.target.result);
    }

    submitPropertyForm(formData) {
        if (this.propertyForm.valid) {
            this.spinner.show();

            if (this.mode == "edit") {
                this.ds.updateProperty(formData, this.propertyId).subscribe(
                    (response) => {
                        this.spinner.hide();
                        if (response["status"]) {
                            this.toast.info(response["message"]);
                            setTimeout(
                                () => this.router.navigate(["panel/property"]),
                                2000
                            );
                        } else {
                            this.toast.error(response["message"]);
                        }
                    },
                    (err) => {
                        this.spinner.hide();
                        this.toast.error(
                            "Oops, something went wrong. Please try again."
                        );
                    }
                );
            } else {
                this.ds.createProperty(formData).subscribe(
                    (response) => {
                        this.spinner.hide();
                        if (response["status"]) {
                            this.toast.info(response["message"]);
                            setTimeout(
                                () => this.router.navigate(["panel/property"]),
                                2000
                            );
                        } else {
                            this.toast.error(response["message"]);
                        }
                    },
                    (err) => {
                        this.spinner.hide();
                        this.toast.error(
                            "Oops, something went wrong. Please try again."
                        );
                    }
                );
            }
        }
    }
}
