


    <div class="header pb-8 pt-5 pt-md-8 pr-1 header-compact">
        <div class="container-fluid">
            <div class="header-body">
                <p-toast></p-toast>

                <!-- create id card form started -->
                <div class="row ml-1" *ngIf="act == 'add'">
                    <div class="col-xl-7 col-lg-8">
                        <h4 class="">Enter Your Details</h4>
                        <hr>
                        <form (ngSubmit)="adduser()" [formGroup]="adduserform" class="login-container">
                            <input *ngIf="act=='add'" type="hidden" formControlName="_id">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <label class="popup-form-label ml-3" for="firstName">
                                                <h5>First Name</h5>
                                            </label>
                                            <div class="col-md-12">
                                                <input class="form-control-input" formControlName="firstName"
                                                    [ngModel]="fn" (ngModelChange)="getChange($event)"
                                                    placeholder="Enter first name" type="text">
                                                <div *ngIf="isSubmitted && adduserform.get('firstName').hasError('required')"
                                                    class="alert-danger">
                                                    <div *ngIf="adduserform.get('firstName').hasError('required')">First
                                                        Name is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <label class="popup-form-label ml-3" for="lastName">
                                                <h5>Last Name</h5>
                                            </label>
                                            <div class="col-md-12">
                                                <input class="form-control-input" formControlName="lastName"
                                                    [ngModel]="ln" (ngModelChange)="getChange2($event)"
                                                    placeholder="Enter last name" type="text">
                                                <div *ngIf="isSubmitted && adduserform.get('lastName').hasError('required')"
                                                    class="alert-danger">
                                                    <div *ngIf="adduserform.get('lastName').hasError('required')">Last
                                                        Name
                                                        is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <label class="popup-form-label ml-3" for="email">
                                                <h5>Email</h5>
                                            </label>
                                            <div class="col-md-12">
                                                <input class="form-control-input" formControlName="email"
                                                    [ngModel]="email" placeholder="Enter email" type="text">
                                                <div *ngIf="isSubmitted && adduserform.get('email').hasError('required')"
                                                    class="alert-danger">
                                                    <div *ngIf="adduserform.get('email').hasError('required')">Email
                                                        Name is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <label class="popup-form-label ml-3" for="phone">
                                                <h5>Mobile</h5>
                                            </label>
                                            <div class="col-md-12">
                                                <input class="form-control-input" formControlName="phone"
                                                    [ngModel]="phone" placeholder="Enter phone" type="text">
                                                <div *ngIf="isSubmitted && adduserform.get('phone').hasError('required')"
                                                    class="alert-danger">
                                                    <div *ngIf="adduserform.get('phone').hasError('required')">
                                                        Mobile number is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="popup-form-label" for="idNo">
                                            <h5>ID No.</h5>
                                        </label>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <input class="form-control-input" formControlName="idNo"
                                                    [ngModel]="userid2" (ngModelChange)="getUserId($event)"
                                                    maxlength="12" placeholder="Enter ID No." type="text">
                                                <div *ngIf="adduserform.get('idNo').hasError('pattern')"
                                                    class="alert-danger">
                                                    <div *ngIf="adduserform.get('idNo').hasError('pattern')">Please
                                                        enter the valid number</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="popup-form-label" for="DOB">
                                            <h5>DOB</h5>
                                        </label>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!-- <input class="form-control-input" formControlName="DOB"
                                                    [ngModel]="dob2" (ngModelChange)="getDob($event)"
                                                    placeholder="select date" type="text" bsDatepicker
                                                    [bsConfig]="{dateInputFormat: 'DD/MM/YYYY'}"> -->
                                                <input type="date" class="form-control-input" name="DOB"
                                                    (change)="getDob($event)" formControlName="DOB">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="row">
                                            <label class="popup-form-label ml-3" for="idCardExpiyDate">
                                                <h5>ID Card Expiry Date</h5>
                                            </label>
                                            <div class="col-md-12">
                                                <input class="form-control-input"
                                                    formControlName="idCardExpiyDate" (change)="getIdexp($event)"
                                                    placeholder="select date" type="date">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="content-section implementation">
                                        <p-toast></p-toast>
                                        <label class="popup-form-label" for="DOB">
                                            <h5>Upload Profile Image</h5>
                                        </label>

                                        <div class="input-group mb-3">

                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input"
                                                    formControlName="photoUpload" id="importFile"
                                                    (change)="onFileChange($event.target.files)">
                                                <label class="custom-file-label" #labelImport for="importFile"> Choose
                                                    Image</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <button type="button" style="float: right; margin-left: 1%;" class="btn btn-secondary"
                                [routerLink]="['/idcards']">Close</button> &nbsp;&nbsp;
                            <button type="submit" style="float: right; margin-left: 1%;"
                                class="btn btn-primary">Save</button>
                        </form>
                    </div>

                    <div class="col-xl-5 col-lg-43">
                        <div class="col-md-10">
                            <br>
                            <hr>
                            <div class="profile-card-4 text-left">
                                <img class="card-img-top image2 " style="height: 14rem; width: 100%;"
                                    src="../../assets/img/kd9ROmR.png" class="img img-responsive">
                                <div class="circular--portrait">
                                    <!-- <img class="card-img-top image1" src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-4.jpg" alt="Card image cap"> -->
                                    <img class="card-img-top image1" [src]="url" *ngIf="url" alt="Card image cap">
                                    <div [innerHtml]='msg' *ngIf="msg" style="color: red;"></div>
                                </div>
                                <div class="profile-content">
                                    <div class="text-left">
                                        <div class="row">
                                            <h5 class="col-xs-4 col-md-4 left">Name :</h5>
                                            <!-- <h5 class="">{{cardData.data.firstName}} {{cardData.data.lastName}}</h5></div> -->
                                            <h5 class="">{{fn}} {{ln}}</h5>
                                        </div>
                                        <div class="row">
                                            <h5 class="col-xs-4 col-md-4 left">ID # :</h5>
                                            <!-- <h5 class="">{{cardData.data.idNo}}</h5></div> -->
                                            <h5 class="">{{userid2}}</h5>
                                        </div>
                                        <div class="row">
                                            <h5 class="col-xs-4 col-md-4 left">DOB : </h5>
                                            <!-- <h5 class="">{{cardData.data.DOB}}</h5></div> -->
                                            <h5 class="">{{dob2 | date:'d/M/yy'}}</h5>
                                        </div>
                                        <div class="row">
                                            <h5 class="col-xs-4 col-md-4 left">Valid Till :</h5>
                                            <!-- <h5 class="">{{cardData.data.idCardExpiyDate}}</h5></div> -->
                                            <h5 class="">{{idExp | date:'d/M/yy'}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- view id card -->
                <div class="row ml-1" *ngIf="act == 'edit'">
                    <div class="col-md-10">
                        <br>
                        <hr>
                        <div class="profile-card-4 text-left">
                            <pdf-viewer [src]="url" [render-text]="false" [original-size]="true" [fit-to-page]="true"
                            style="display: block;"></pdf-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
