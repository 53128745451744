import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { __spreadArrays } from "tslib";
import { observable } from "rxjs";
import { element } from "protractor";
import { YEAR } from "ngx-bootstrap/chronos/units/constants";
import { async } from "@angular/core/testing";
import { rejects } from "assert";
import { LogsModalComponent } from '../logs-modal/logs-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EformResponseModalComponent } from '../eform-response-modal/eform-response-modal.component';
import { UsersReportModalComponent } from '../users-report-modal/users-report-modal.component';
import { DataservicesService } from "src/app/_services/dataservices.service";
import { environment } from "src/environments/environment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-reports",
    templateUrl: "./reports.component.html",
    styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
    eformresponse = [];
    AllEformsResponse = [];
    fields = [];
    formname: any;
    imageUrl: string = environment.imgurl;
    imageUrlsForPDF = [];
    portalUser;
    portaldata;
    pdfcolums = [{}];
    xls_fileName = "ExcelSheet.xlsx";
    pdf_fileName = "PDF.pdf";
    eformId: any;
    eformResponseEformId: any;
    device_token: string;
    isSubmitted = false;
    eformStatus: string = "";
    gradedata = [];
    eFormverifyCheck: any = [];

    verifiedItems: Array<String> = [];
    fieldVerifierData: any;
    userAkcessID: any;
    graderLoader: boolean = false;

    eformDataWithForExpiryDate: any
    expiryDateIndex: any
    addExpiryDate: any

    currentPage: any = 1
    totalCount: any = 0
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50]
    pageSize: any = 10

    currentPage_client: any = 1
    totalCount_client: any = 0
    numberOfPage_client: Array<Number> = [10, 20, 30, 40, 50]
    pageSize_client: any = 10
    allUsers_client: any = [];
    //
    currentPage_uc: any = 1
    totalCount_uc: any = 0
    numberOfPage_uc: Array<Number> = [10, 20, 30, 40, 50]
    pageSize_uc: any = 10
    allUsers_uc: any = [];
    uc_reports: any = [];
    //
    user_reports: any = [];
    client_reports: any = [];
    allUsers: any = [];
    firstName: string;
    lastName: string;
    email: string;
    akcessId: string;
    bsModalRef: BsModalRef;

    client_search_input: string = "";
    user_search_input: string = "";
    search_userStatus: string = '';
    search_userStatus_client: string = '';
    cl_search_input: string = '';
    // Status: any = [
    //   "Pending",
    //   "Accept",
    //   "Update Client Field",
    //   "Verify and Accept",
    //   "Return",
    //   "Reject",
    // ];
    StatusDescription: any = {
        alive: "Alive",
        Pending: "Pending",
        createClient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        Accept:
            " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
        "Update Client Field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        "Verify and Accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        Return:
            "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
        Reject:
            "When you apply 'reject' action, user will receive a notification that you rejected his response",
    };

    orderByFilter: any = 'nto'

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        //public modalService: BsModalService
    ) { }

    ngOnInit() {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        //this.eformId = this.route.snapshot.paramMap.get("eformId");
        //this.getUserReport(null);
    }
    getUserReport(status: string, user_status: string) {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
            currentPage: this.currentPage,
            limit: this.pageSize,
            user_type: 'User',
            search_userStatus: user_status
        };

        if (status && status.length) tmpdata["status"] = status;
        if (this.client_search_input && this.client_search_input.length)
            tmpdata["client_search_input"] = this.client_search_input;

        // alert(JSON.stringify(tmpdata))
        this.calldata.fetchReports(tmpdata).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.user_reports = [];
                    this.user_reports = data["data"];
                    this.allUsers = data["data"];
                    this.totalCount = data["totalCount"]
                    this.spinner.hide();
                } else {
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    onProjectPageChange(event) {
        this.currentPage = event
        this.getUserReport(null, this.search_userStatus)
    }

    changePage(value) {

        this.pageSize = parseInt(value)
        this.getUserReport(null, this.search_userStatus)
    }

    dislayDate(date) {
        let filter_date = moment(date).format('DD-MMM-YYYY');

        return filter_date;
    }

    searchClient(searchValue: string) {
        this.client_search_input = searchValue;
        this.getUserReport(null, this.search_userStatus);
    }

    searchUsers_client(searchValue: string) {

        this.client_search_input = searchValue;
        this.getClientReport(null, this.search_userStatus_client);

    }

    searchUsers(filterValue: string) {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
            currentPage: 1,
            limit: 2000,
            user_type: 'User'
        };

        if (status && status.length) tmpdata["status"] = status;

        // alert(JSON.stringify(tmpdata))
        this.calldata.fetchReports(tmpdata).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    let allUsers = data["data"];
                    if (filterValue !== null) {
                        filterValue = filterValue.toLowerCase();
                        this.user_reports = allUsers.filter((user) => (user.firstName && user.firstName.toLowerCase().includes(filterValue)) || (user.lastName && user.lastName.toLowerCase().includes(filterValue)) || (user.akcessId && user.akcessId.toLowerCase().includes(filterValue)));
                    } else this.user_reports = allUsers;


                    this.spinner.hide();
                } else {
                    this.toast.info(data["msg"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );

    }

    getClientReport(status: string, user_status: string) {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
            currentPage: this.currentPage_client,
            limit: this.pageSize_client,
            user_type: 'Client',
            search_userStatus: user_status
        };

        if (status && status.length) tmpdata["status"] = status;
        if (this.user_search_input && this.user_search_input.length)
            tmpdata["client_search_input"] = this.user_search_input;

        // alert(JSON.stringify(tmpdata))
        this.calldata.fetchClientReports(tmpdata).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.client_reports = [];
                    this.client_reports = data["data"];
                    this.allUsers_client = data["data"];
                    this.totalCount_client = data["totalCount"]
                    this.spinner.hide();
                } else {
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    onProjectPageChange_client(event) {
        this.currentPage_client = event
        this.getClientReport(null, this.search_userStatus_client)
    }

    changePage_client(value) {
        this.pageSize_client = parseInt(value)
        this.getClientReport(null, this.search_userStatus_client)
    }


    getUserReport_uc(status: string) {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
            currentPage: this.currentPage_uc,
            limit: 2000
        };

        if (status && status.length) tmpdata["status"] = status;
        if (this.cl_search_input && this.cl_search_input.length)
            tmpdata["client_search_input"] = this.cl_search_input;
        // alert(JSON.stringify(tmpdata))
        this.calldata.fetchUpdatedClients(tmpdata).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.uc_reports = [];
                    this.uc_reports = data["data"];
                    this.allUsers_uc = data["data"];
                    this.totalCount_uc = data["totalCount"]
                    this.spinner.hide();
                } else {
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    onProjectPageChange_uc(event) {
        this.currentPage_uc = event
        this.getUserReport_uc(null)
    }

    changePage_uc(value) {
        this.pageSize_uc = parseInt(value)
        this.getUserReport_uc(null)
    }
    searchUsers_uc(filterValue: string) {
        if (filterValue !== null) {
            filterValue = filterValue.toLowerCase();
            this.uc_reports = this.allUsers_uc.filter((user) => (user.user.firstName && user.user.firstName.toLowerCase().includes(filterValue)) || (user.user.lastName && user.user.lastName.toLowerCase().includes(filterValue)) || (user._id && user._id.toLowerCase().includes(filterValue)));
        } else this.uc_reports = this.allUsers_uc;

    }

    generatePdf() {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.pdf_fileName = "Logs_.pdf";
        const documentDefinition = this.getDocumentDefinition();
        pdfMake.createPdf(documentDefinition).download(this.pdf_fileName);
    }
    getDocumentDefinition() {
        var html = htmlToPdfmake(
            document.getElementById("pdf_formfields").innerHTML
        );
        return {
            content: [html],
            styles: {
                "html-strong": {
                    background: "", // it will add a yellow background to all <STRONG> elements
                },
            },
        };
    }

    exportexcel(div): void {
        /* table id is passed over here */
        let element = document.getElementById(div);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        let fileName = "";

        if (div === 'pdf_formfields') {
            fileName = 'UpdatedCustomers.xlsx';
        } else if (div === 'client_reports') {
            fileName = 'ClientList.xlsx';
        } else if (div === 'user_reports') {
            fileName = "UserReport.xlsx";
        }

        /* save to file */
        XLSX.writeFile(wb, fileName);

    }

    openModal() {
        //this.bsModalRef = this.modalService.show(LogsModalComponent, {
        //    initialState,
        //    animated: true,
        //    backdrop: "static",
        //    ignoreBackdropClick: true,
        //    class: "modal-dialog-centered modal-xl",
        //});

        const modalRef = this.modalService.open(LogsModalComponent
            , {
                size: 'xl',
                backdrop: 'static',
                centered: true,
                scrollable: true
            });

        let data = {
            prop1: 'Some Data',
            prop2: 'From Parent Component',
            prop3: 'This Can be anything'
        }

        modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    }

    onKeydown(event) {
        if (event.key === "Backspace") {
        }
    }

    openResponseReport() {
        const modalRef = this.modalService.open(EformResponseModalComponent
            , {
                size: 'xl',
                backdrop: 'static',
                centered: true,
                scrollable: true
            });

        let data = {
            prop1: 'Some Data',
            prop2: 'From Parent Component',
            prop3: 'This Can be anything'
        }

        modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    }

    openUserReportModal() {

        const modalRef = this.modalService.open(UsersReportModalComponent
            , {
                size: 'xl',
                backdrop: 'static',
                centered: true,
                scrollable: true
            });

        let data = {
            prop1: 'Some Data',
            prop2: 'From Parent Component',
            prop3: 'This Can be anything'
        }

        modalRef.componentInstance.fromParent = data;
        modalRef.result.then((result) => {
        }, (reason) => {
        });
    }

}
