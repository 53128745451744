<div class="eform-response-deleted-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">eFormresponse Deleted List</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/panel/eformsresponse']">eFormresponse</a></li>
                <li class="breadcrumb-item active">eFormresponse Deleted List</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div class="input-group">
                                <input type="text" class="form-control" id="search"
                                    placeholder="Search eForm Response" [(ngModel)]="searchInput"
                                    (change)="getDeletedeFormResponseList()"
                                    (keyup.enter)="getDeletedeFormResponseList()" />
                            </div>
                        </div>
                    </div>
                    <div class="action-tab">
                        <button type="button" class="mx-1 btn" container="body"
                            [routerLink]="['/panel/eformsresponse']">
                            <i class="fas fa-arrow-left"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button type="button" class="mx-1 btn" tooltip="Remove Permanently" container="body"
                            (click)="deletePermantly()">
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button type="button" class="mx-1 btn" tooltip="Restore" container="body"
                            (click)="restoreDeleted()">
                            <i class="fas fa-trash-restore"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>
                                        <!--<label data-toggle="collapse" data-target="#actionBar" aria-expanded="false" aria-controls="actionBar" class="m-0">
                                            <input class="" type="checkbox">
                                        </label>-->
                                    </th>
                                    <th>Efrom Name <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span></th>
                                    <th>Name <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span></th>
                                    <th>AKcess Id <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span></th>
                                    <th>Created Date <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span></th>
                                    <th>Deleted Date <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="eFormResponseDeletedList?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">No Deleted eForm Respone Found</td>
                                </tr>
                            </tbody>

                            <tbody *ngIf="eFormResponseDeletedList?.length > 0">
                                <tr [id]="data?._id"
                                    *ngFor="let data of eFormResponseDeletedList | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                    <td>
                                        <input class="" type="checkbox" [attr.id]="data?._id">
                                    </td>
                                    <td>{{data.formName}}</td>
                                    <td>{{data.firstName}} {{data.lastName}}</td>
                                    <td>{{data.akcessId}}</td>
                                    <td>{{data.createdAt | date:'mediumDate'}}</td>
                                    <td>{{data.updatedAt | date:'mediumDate'}}</td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div class="table-page" *ngIf="eFormResponseDeletedList?.length > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value)"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>