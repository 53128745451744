import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { CommonService } from "../_services/common.service";

@Injectable({ providedIn: "root" })
export class PermissionGuard implements CanActivateChild {
    constructor(
        private router: Router,
        public commonService: CommonService,
        private toast: ToastrService
    ) {}

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const currentCompany = this.commonService.selectedCompanyDeatils;
        console.log(currentCompany.market_sector);
        if (currentCompany.market_sector == "Real Estate") {
            console.log(currentCompany.market_sector);
            return this.checkHavePermission(
                state.url,
                currentCompany.permission
            );
        } else {
            return true;
        }
    }

    private checkHavePermission(url, permissions) {
        let module = url.split("/")[2];
        return this.getPermission(module, permissions);
    }

    getPermission(module, permissions) {
        for (var i = 0; i < permissions.length; i++) {
            if (permissions[i] == module) {
                return true;
            }
        }
        //window.alert("Contact Us to enable these features.");
        this.toast.warning(
            "Contact Us to enable these features.",
            "Access Denied",
            { closeButton: true }
        );
        return false;
    }
}
