<div class="property-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/property']">Property</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="mode == 'create'">
                    Add Property
                </li>
                <li class="breadcrumb-item active" *ngIf="mode == 'edit'">
                    Edit Property
                </li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title mb-0">
                        {{ mode == "edit" ? "Edit" : "Add" }} Property
                    </h3>
                </div>
                <div class="card-body">
                    <form
                        class="property-form"
                        [formGroup]="propertyForm"
                        (ngSubmit)="submitPropertyForm(propertyForm.value)"
                    >
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label required"
                                        >Property Name / Address</label
                                    >
                                    <input
                                        type="text"
                                        name="name"
                                        class="form-control"
                                        formControlName="name"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label required"
                                        ><i class="fal fa-pound-sign"></i>
                                        Price</label
                                    >
                                    <input
                                        type="number"
                                        min="0"
                                        name="price"
                                        formControlName="price"
                                        class="form-control"
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="form-label required"
                                        >Description</label
                                    >
                                    <textarea
                                        name="description"
                                        class="form-control"
                                        formControlName="description"
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="upload-img-parent">
                                        <label
                                            >Upload Photo (Recommended size:
                                            1800x1100px)
                                        </label>
                                        <input
                                            class="form-control select-img"
                                            type="file"
                                            accept=".jpg,.png,.jpeg"
                                            (change)="onUploadChange($event)"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <img
                                    *ngIf="photo"
                                    class="property-photo"
                                    src="{{ photo }}"
                                    width="100"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-left mt-4">
                                    <button
                                        type="submit"
                                        class="btn btn-info"
                                        *ngIf="mode == 'create'"
                                        [disabled]="!propertyForm.valid"
                                    >
                                        Add Property
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-info"
                                        *ngIf="mode == 'edit'"
                                        [disabled]="!propertyForm.valid"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- /Page Wrapper -->
