<div>
    <button type="button" class="filter-button" (click)="toggleFilter()">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-filter"
        >
            <polygon
                points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"
            ></polygon>
        </svg>
        Filter
    </button>
    <div *ngIf="isOpenFilter" class="filter-container">
        <h3 class="font-weight-500">Filters</h3>
        <a class="btn-close-filter" (click)="closeFilterBox()">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 0 1 1.414 0L12 10.586l6.293-6.293a1 1 0 1 1 1.414 1.414L13.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L10.586 12 4.293 5.707a1 1 0 0 1 0-1.414Z"
                    clip-rule="evenodd"
                ></path>
            </svg>
        </a>
        <div
            class="filter-row"
            *ngFor="let filter of filters.fields; index as i"
        >
            <div class="cu-filter-group-op">
                <div class="cu-filter-group-op__item" *ngIf="i == 0">Where</div>
                <div class="cu-filter-group-op__item" *ngIf="i != 0">
                    <div
                        class="cu-filter-group-op__item-dropdown"
                        [ngClass]="{
                            dropdown_disabled:
                                i > 1 || filters.fields[0].fields.length != 0
                        }"
                        (click)="changeFilterGroup()"
                    >
                        <div
                            class="cu-filter-group-op__item"
                            *ngIf="filters.group == 'AND'"
                        >
                            AND
                        </div>
                        <div
                            class="cu-filter-group-op__item"
                            *ngIf="filters.group == 'OR'"
                        >
                            OR
                        </div>
                        <div
                            class="cu-filter-group-op__item-dropdown-toggle-icon icon-small"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                shape-rendering="geometricPrecision"
                                text-rendering="geometricPrecision"
                                image-rendering="optimizeQuality"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                viewBox="0 0 322 511.21"
                            >
                                <path
                                    fill-rule="nonzero"
                                    d="M295.27 211.54H26.71c-6.23-.02-12.48-2.18-17.54-6.58-11.12-9.69-12.29-26.57-2.61-37.69L144.3 9.16c.95-1.07 1.99-2.1 3.13-3.03 11.36-9.4 28.19-7.81 37.58 3.55l129.97 157.07a26.65 26.65 0 0 1 7.02 18.06c0 14.76-11.97 26.73-26.73 26.73zM26.71 299.68l268.56-.01c14.76 0 26.73 11.97 26.73 26.73 0 6.96-2.66 13.3-7.02 18.06L185.01 501.53c-9.39 11.36-26.22 12.95-37.58 3.55-1.14-.93-2.18-1.96-3.13-3.03L6.56 343.94c-9.68-11.12-8.51-28 2.61-37.69 5.06-4.4 11.31-6.56 17.54-6.57z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cu-filter-value">
                <select class="form-control filter-select">
                    <option>Filter By</option>
                    <option>Signature</option>
                </select>
                <select class="form-control filter-condition">
                    <option value="">Is</option>
                    <option value="">Is not</option>
                    <option value="">Is set</option>
                    <option value="">Is not set</option>
                </select>
                <select class="form-control filter-options">
                    <option>Select Option</option>
                </select>
                <a (click)="removeFilter(i)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash-2"
                    >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path
                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                        ></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                </a>
            </div>
            <div class="cu-filter-group-op" *ngIf="filter.fields.length >= 1">
                <div class="cu-filter-group-op__item">
                    <div
                        class="cu-filter-group-op__item-dropdown"
                        [ngClass]="{
                            dropdown_disabled:
                                i != 0 && filter.fields.length > 0
                        }"
                        (click)="changeFilterGroup()"
                    >
                        <div
                            class="cu-filter-group-op__item"
                            *ngIf="filters.group == 'AND'"
                        >
                            AND
                        </div>
                        <div
                            class="cu-filter-group-op__item"
                            *ngIf="filters.group == 'OR'"
                        >
                            OR
                        </div>
                        <div
                            class="cu-filter-group-op__item-dropdown-toggle-icon icon-small"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                shape-rendering="geometricPrecision"
                                text-rendering="geometricPrecision"
                                image-rendering="optimizeQuality"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                viewBox="0 0 322 511.21"
                            >
                                <path
                                    fill-rule="nonzero"
                                    d="M295.27 211.54H26.71c-6.23-.02-12.48-2.18-17.54-6.58-11.12-9.69-12.29-26.57-2.61-37.69L144.3 9.16c.95-1.07 1.99-2.1 3.13-3.03 11.36-9.4 28.19-7.81 37.58 3.55l129.97 157.07a26.65 26.65 0 0 1 7.02 18.06c0 14.76-11.97 26.73-26.73 26.73zM26.71 299.68l268.56-.01c14.76 0 26.73 11.97 26.73 26.73 0 6.96-2.66 13.3-7.02 18.06L185.01 501.53c-9.39 11.36-26.22 12.95-37.58 3.55-1.14-.93-2.18-1.96-3.13-3.03L6.56 343.94c-9.68-11.12-8.51-28 2.61-37.69 5.06-4.4 11.31-6.56 17.54-6.57z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cu-fields-group" *ngIf="filter.fields.length >= 1">
                <div
                    class="cu-filter-value-list"
                    *ngFor="let field_group of filter.fields; index as g"
                >
                    <div class="cu-filter-group-op">
                        <div class="cu-filter-group-op__item" *ngIf="g == 0">
                            Where
                        </div>
                        <div class="cu-filter-group-op__item" *ngIf="g != 0">
                            <div
                                class="cu-filter-group-op__item-dropdown"
                                [ngClass]="{
                                    dropdown_disabled: g > 1
                                }"
                                (click)="changeFilterFieldsGroup(i)"
                            >
                                <div
                                    class="cu-filter-group-op__item"
                                    *ngIf="filter.group == 'AND' && g != 0"
                                >
                                    AND
                                </div>
                                <div
                                    class="cu-filter-group-op__item"
                                    *ngIf="filter.group == 'OR' && g != 0"
                                >
                                    OR
                                </div>
                                <div
                                    class="cu-filter-group-op__item-dropdown-toggle-icon icon-small"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        shape-rendering="geometricPrecision"
                                        text-rendering="geometricPrecision"
                                        image-rendering="optimizeQuality"
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        viewBox="0 0 322 511.21"
                                    >
                                        <path
                                            fill-rule="nonzero"
                                            d="M295.27 211.54H26.71c-6.23-.02-12.48-2.18-17.54-6.58-11.12-9.69-12.29-26.57-2.61-37.69L144.3 9.16c.95-1.07 1.99-2.1 3.13-3.03 11.36-9.4 28.19-7.81 37.58 3.55l129.97 157.07a26.65 26.65 0 0 1 7.02 18.06c0 14.76-11.97 26.73-26.73 26.73zM26.71 299.68l268.56-.01c14.76 0 26.73 11.97 26.73 26.73 0 6.96-2.66 13.3-7.02 18.06L185.01 501.53c-9.39 11.36-26.22 12.95-37.58 3.55-1.14-.93-2.18-1.96-3.13-3.03L6.56 343.94c-9.68-11.12-8.51-28 2.61-37.69 5.06-4.4 11.31-6.56 17.54-6.57z"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cu-filter-value">
                        <select class="form-control filter-select">
                            <option>Filter By</option>
                            <option>Signature</option>
                        </select>
                        <select class="form-control filter-condition">
                            <option value="">Is</option>
                            <option value="">Is not</option>
                            <option value="">Is set</option>
                            <option value="">Is not set</option>
                        </select>
                        <select class="form-control filter-options">
                            <option>Select Option</option>
                        </select>
                        <a (click)="removeFilterGroup(i, g)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash-2"
                            >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                ></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                        </a>
                    </div>
                </div>
                <div class="cu-filter-value-list__container">
                    <a
                        data-test="filter-value-list__add_filter"
                        class="cu-filter-value-list__item cu-filter-value-list__item-add"
                        (click)="addFieldGroupFilter(i)"
                        >+ Add filter
                    </a>
                    <div class="cu-filter-value-list__group-control">
                        <a
                            (click)="clearFieldGroupFilters(i)"
                            class="cu-filter-value-list__item cu-filter-value-list__item-remove cu-filter-value-list_hidden"
                        >
                            Clear group
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="cu-filter-value-list__container">
            <a
                data-test="filter-value-list__add_filter"
                class="cu-filter-value-list__item cu-filter-value-list__item-add"
                (click)="addFilter()"
                >+ Add filter
            </a>
            <div
                class="cu-filter-value-list__group-control"
                *ngIf="filters.fields.length > 0"
            >
                <a
                    class="cu-filter-value-list__item cu-filter-value-list__item-add cu-filter-value-list_hidden"
                    (click)="addFieldGroup()"
                    >+ Add group </a
                ><a
                    (click)="clearFilters()"
                    class="cu-filter-value-list__item cu-filter-value-list__item-remove cu-filter-value-list_hidden"
                >
                    Clear all filters</a
                >
            </div>
        </div>
    </div>
</div>
