import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonModule } from "@angular/common";
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-update-id-card',
  templateUrl: './update-id-card.component.html',
  styleUrls: ['./update-id-card.component.css']
})
export class UpdateIdCardComponent implements OnInit {
  adduserform: FormGroup;
  uploadedFile: any = null;
  act: string;
  isSubmitted: boolean;
  cardData: any;
  fn;
  ln;
  userid2;
  dob2;
  idExp;
  url;
  msg = "";
  profileImage;
  phone;
  email;

  constructor(private formBuilder: FormBuilder,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private gs: GeneralServiceService,
    private router: Router,
    private toast: ToastrService,
    public commonService: CommonService) { }

  ngOnInit(): void {
    this.cardData = JSON.parse(this.route.snapshot.params['data']);
    if (this.cardData.data.firstName != "") {
      this.act = 'edit'
      this.url = this.cardData.data.fileUrl
    } else {
      this.act = 'add'
    }

    this.initAddUserForm()
  }

  getChange(event) {
    this.fn = event
  }
  getChange2(event) {
    this.ln = event
  }
  getUserId(event) {
    this.userid2 = event
  }
  getDob(event) {
    var date = new Date(event.target.value);

    this.dob2 = date.toISOString()
  }
  getIdexp(event) {
    var date = new Date(event.target.value);

    this.idExp = date.toISOString()
  }
  initAddUserForm() {
    this.adduserform = this.formBuilder.group({
      _id: [null],
      firstName: [this.cardData.data.firstName, [Validators.required]],
      lastName: [this.cardData.data.lastName, [Validators.required]],
      email: [this.cardData.data.email],
      phone: [this.cardData.data.phone],
      idNo: [this.cardData.data.idNo, [Validators.required]],
      DOB: [this.cardData.data.DOB],
      idCardExpiyDate: [this.cardData.data.idCardExpiyDate],
      photoUpload: [this.fileToUpload]
    });
  }
  // onUpload(event) {
  //   this.uploadedFile = event.files
  //   this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
  // }
  labelImport

  formImport: FormGroup;
  fileToUpload: File = null;
  // onFileChange(files: FileList) {
  onFileChange(files) {
    this.profileImage = files[0]
    // this.fileToUpload = files.item(0);
    // this.labelImport = this.fileToUpload.name
    // -------------------------------------------
    if (!files[0] || files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }

    var mimeType = files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.msg = "Only images are supported";
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (_event) => {
      this.msg = "";
      this.url = reader.result;
    }
  }

  adduser() {
    this.act = "add";
    this.isSubmitted = true;
    const tmpdate = moment().format();

    if (this.adduserform.invalid) {
      // alert("invalid");
      return;
    }
    this.spinner.show();
    var formData = this.adduserform.value;
    formData['file'] = this.profileImage;
    formData['domainName'] = this.commonService.selectedCompanyDeatils.domain;

    this.gs.saveIdCardData(formData).subscribe(data => {
      this.toast.success("Id card saved successfully");
      this.spinner.hide();
      this.router.navigate(['/panel/employees']);
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    }
    );
  }
}
