import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
//import { DataservicesService } from 'src/app/_services/dataservices.service';
import { Router } from '@angular/router';


@Component({
    selector: 'app-add-users',
    templateUrl: './add-users.component.html',
    styleUrls: ['./add-users.component.css']
})
export class AddUsersComponent implements OnInit {
    akcessIDChips = [];
    selectedCompany: any;
    listCompanyUsers: any = [];
    //companyDetails: any = [];
    saveuserData: any = [];
    userData: any = [];
    //portalUser;
    isLengthProper: boolean;
    //empList: string;
    empList: any = [];
    employeeListArray: any = [];

    //ng-select variable
    selectSubmitDisabled: boolean = false;

    constructor(
        private _bsModalRef: BsModalRef,
        private gs: GeneralServiceService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        //private calldata: DataservicesService,
        private router: Router
    ) { }

    ngOnInit(): void { }

    addTagFn(name) {
        return { name: name, tag: true };
    }

    addUserAsAdmin() {
        this.isLengthProper = true;
        if (this.akcessIDChips.length == 0) {
            this.toast.info('Enter/Select AKcess ID');
            return false;
        }
        let akcessIDChipsarray = [];

        //this.akcessIDChips.forEach(element => {
        //    if (element && !element.name && element.split(' ')[0] != 'undefined' && element.split(' ')[0]) {
        //        akcessIDChipsarray.push(element.split(' ')[0]);
        //    } else if (element && element.name) {
        //        akcessIDChipsarray.push(element.name);
        //    }
        //});

        this.akcessIDChips.forEach(element => {
            if (element && !element.name && element != 'undefined') {
                akcessIDChipsarray.push(element);
            } else if (element && element.name) {
                akcessIDChipsarray.push(element.name);
            }
        });

        akcessIDChipsarray = akcessIDChipsarray.map(el => el.toUpperCase());

        let arrayLength = akcessIDChipsarray.map(function (id) {
            return id.length;
        });

        arrayLength.forEach(item => {
            if (item < 11 || item > 12) {
                this.isLengthProper = false;
                return;
            }
        });

        if (!this.isLengthProper) {
            this.toast.error("AKcess Id should be of 11 or 12 letters.");
        }
        else {
            this.spinner.show();
            let adminArray = [];
            akcessIDChipsarray.forEach(item => {
                let admin = {
                    "akcessId": item.split(' ')[0]
                }
                adminArray.push(admin)
            });

            const reqBody = {
                "adminData": adminArray,
                "domain": this.selectedCompany.domain,
                added_by: sessionStorage.getItem('logedInUserAckessId')
            }

            this.gs.addUserAsAdmin(reqBody).subscribe((res: any) => {

                if (res.status) {
                    this.toast.success(res.message);
                }
                else {
                    this.toast.warning(res.message);
                }

                this.closeModal();

                this.router
                    .navigateByUrl("/", { skipLocationChange: true })
                    .then(() => {
                        //this.router.navigate(["/panel/company-users"]);
                        this.navigateTo();
                    });
                this.spinner.hide();
            },
                error => {
                    this.closeModal();
                    this.toast.error(error.error.message);
                    this.spinner.hide();
                }
            )
        }
    }

    navigateTo() {
        let data = this.selectedCompany;
        let path = '/panel/company-users';
        var temp = {
            selectedCompany: data,
        };
        this.router.navigate([path], {
            state: { ...temp },
        });
    }

    closeModal() {
        this._bsModalRef.hide();
        this.akcessIDChips = [];
    }


    //ng-select
    onOpenDropdown() {
        this.selectSubmitDisabled = true;
    }

    onCloseDropdown() {
        this.selectSubmitDisabled = false;
    }
}
