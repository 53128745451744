import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
    selector: "app-property-inquiry",
    templateUrl: "./property-inquiry.component.html",
    styleUrls: ["./property-inquiry.component.css"],
})
export class PropertyInquiryComponent implements OnInit {
    propertyId: string = "";
    searchName: string;
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;
    inquiryList: any = [];
    responseId: any;
    pullDataStatus: any = "";

    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private gs: GeneralServiceService,
        private toast: ToastrService,
        private refreshContentService: RefreshContentService,
        public commonService: CommonService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        let me = this;
        me.propertyId = this.route.snapshot.paramMap.get("propertyId");
        this.route.queryParams.subscribe((params) => {
            me.searchName = params.search ? params.search : "";
        });
    }

    ngOnInit(): void {
        this.getPropertyInquiries();
    }

    getPropertyInquiries() {
        this.spinner.show();
        const params = {
            search: this.searchName,
            page: this.currentPage,
            limit: this.pageSize,
        };

        this.ds
            .getPropertyInquiryList(params, this.propertyId)
            .subscribe((res: any) => {
                if (res.status) {
                    this.inquiryList = res.data.list;
                    this.totalCount = res.data.total;
                    this.toast.success(res.message);
                    this.spinner.hide();
                } else {
                    this.toast.error(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
    }

    searchPropertyInquiries(type: string, value: string) {
        this.getPropertyInquiries();
    }

    viewRecycle() {}

    onProjectPageChange(event) {
        this.currentPage = event;
        this.getPropertyInquiries();
    }

    changePage(value) {}
}
