<div class="location-page">
  <!-- Bread crumb -->
  <div class="row page-titles">
    <div class="col-md-12">
      <ol class="breadcrumb float-left">
        <li class="breadcrumb-item">
          <a [routerLink]="['/panel/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/panel/access-control']">Access Control</a>
        </li>
        <li class="breadcrumb-item active">View Access Control</li>
      </ol>
    </div>
  </div>
  <!-- Main Section -->
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title mb-0">View Access Control</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ accessControl?.locationName }}"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-label">Maximun number of Staff</label>
                    <input
                      type="text"
                      class="form-control"
                      value="{{ accessControl?.numberOfStaff }}"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div
                *ngFor="let schedule of accessControl.schedules; let i = index"
              >
                <div class="row schedule-list align-items-center">
                  <div class="col col-md-6">
                    <div class="form-group">
                      <label *ngIf="i == 0" class="form-label mr-2">Days</label>
                      <input
                        type="text"
                        class="form-control"
                        value="{{ getDays(schedule.days) }}"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label *ngIf="i == 0" class="form-label mr-2"
                        >Open From</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        value="{{ schedule.openFrom | date : 'hh:mm a' }}"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label *ngIf="i == 0" class="form-label mr-2"
                        >Open To
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value="{{ schedule.openTo | date : 'hh:mm a' }}"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="form-label required">Address</label>
                    <textarea name="address" class="form-control" readonly>{{
                      accessControl?.address
                    }}</textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="form-check custom-control pl-0">
                      <label class="form-check-label">
                        Only allow admins :
                        {{
                          accessControl?.onlyAllowAdmin ? "Yes" : "No"
                        }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <div class="card-body text-center">
              <img
                class="loader-img"
                *ngIf="!accessControl._id"
                src="./assets/img/loading.gif"
              />
              <qrcode
                class="qr-code"
                *ngIf="accessControl._id"
                [qrdata]="qrCodeData"
                [size]="256"
                [level]="'M'"
              ></qrcode>
            </div>
            <div class="card-footer text-center">
              QR for {{ accessControl?.locationName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- /Page Wrapper -->
