import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UploadDocumentComponent } from "./upload-document/upload-document.component";
import { DocSendFormComponent } from "./doc-send-form/doc-send-form.component";
import { DocViewFormComponent } from "./doc-view-form/doc-view-form.component";
import { DocumentViewerComponent } from "../document-viewer/document-viewer.component";
import { CreateFolderComponent } from "./create-folder/create-folder.component";

@Component({
    selector: "app-documents",
    templateUrl: "./documents.component.html",
    styleUrls: ["./documents.component.css"],
})
export class DocumentsComponent implements OnInit {
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;
    documents = [];
    document;
    url;
    isFileChosen: boolean = false;
    fileName: string = "";
    bsModalRef: BsModalRef;

    constructor(
        private toast: ToastrService,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private refreshContentService: RefreshContentService,
        public modalService: BsModalService
    ) { }

    ngOnInit(): void {
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                // this.getAllDocument();
            } else {
                // this.getAllDocument();
            }
        });
        this.getFolderList();
    }

    onProjectPageChange(event) {
        this.currentPage = event;
        // this.getAllDocument();
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        // this.getAllDocument();
    }

    onFileChange(files) {
        this.document = files[0];
        this.fileName = this.document.name;

        if (!files[0] || files[0].length == 0) {
            this.toast.info("You must select an imag");
            return;
        } else {
            this.isFileChosen = true;
            this.spinner.show();
            this.calldata.uploadDocument(files[0]).subscribe(
                (data) => {
                    if (data["status"] == true) {
                        this.documents = [];
                        this.documents = data["data"];
                        this.totalCount = this.documents.length;
                        this.toast.success("Success");
                        // this.getAllDocument();
                        this.spinner.hide();
                    } else {
                        this.toast.info(data["message"]);
                        // this.getAllDocument();
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }

    getAllDocument() {
        this.spinner.show();
        this.calldata.getAllDocuments(this.docName).subscribe(
            (data) => {
                if (data["status"] == true) {
                    this.documents = [];
                    //   this.documents = data["data"].reverse();
                    const data1 = data["data"].map((d) => {
                        var i = Math.floor(Math.log(d.file_detail.size) / Math.log(1024));
                        return {
                            origional_name: d.file_detail.origional_name || d.file_name,
                            file_size:
                                Math.round(d.file_detail.size / Math.pow(1024, i)) + " " + "KB",
                            createdAt: d.createdAt,
                            expiryDate: d.expiryDate,
                            _id: d._id,
                            fileUrl: d.file_url
                        };
                    });
                    this.documents = data1.reverse();
                    this.totalCount = this.documents.length;
                    this.spinner.hide();
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    /**
     * Send data to navigated page without showinhg it in url
     * @param data
     * @param path
     */
    openDocument(data, path) {
        var temp = {
            selectedDocument: data,
        };
        this.router.navigate([path], {
            state: { ...temp },
        });
    }

    //   openDocument(data) {
    //     sessionStorage.setItem("documentUrl", data.fileUrl)
    //     sessionStorage.setItem("fileName", data.fileName)
    //     sessionStorage.setItem("documentId", data.documentId)
    //     sessionStorage.setItem("AkcessID", data.AkcessID)
    //     sessionStorage.setItem("documentUrl", data.fileUrl)

    //     this.router.navigate([])
    //   }

    openModel(type, data) {
        if (type == "upload document") {
            this.folderList.isEdit = false;
            this.bsModalRef = this.modalService.show(UploadDocumentComponent, {
                //initialState,
                animated: true,
                backdrop: "static",
                class: "modal-dialog-centered",
                initialState: this.folderList
            });
        } else if (type == "create folder") {
            this.bsModalRef = this.modalService.show(CreateFolderComponent, {
                //initialState,
                animated: true,
                backdrop: "static",
                class: "modal-dialog-centered",
            });

            this.bsModalRef.onHide.subscribe((res: any) => {
                this.getFolderList();
            })
        } else if (type == "edit document") {
            console.log(data)
            let initialState: any = {
                isEdit: true,
                primaryFolderId: this.folderId._id,
                documentId: data._id
            }
            console.log(initialState)
            this.bsModalRef = this.modalService.show(UploadDocumentComponent, {
                initialState,
                animated: true,
                backdrop: "static",
                class: "modal-dialog-centered",
            });

            this.bsModalRef.onHide.subscribe((res: any) => {
                this.getIndividualFolderDetail(this.folderId);
            });
        }
    }

    openSendModal(data) {
        const initialState: any = {
            sendsingleEmployeeDataModal: data,
        };
        this.bsModalRef = this.modalService.show(DocSendFormComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered",
        });
    }

    openViewSendModal(docID) {
        const initialState: any = {
            docId: docID,
        };
        this.bsModalRef = this.modalService.show(DocViewFormComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-dialog-scrollable modal-lg",
        });
    }

    viewDocument(data) {
        let docData = JSON.stringify(data);
        const initialState: any = {
            selectedDocument: docData,
        };
        this.bsModalRef = this.modalService.show(DocumentViewerComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-dialog-scrollable modal-lg",
        });
    }

    //Delete Rows
    deleteRow() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));

        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data = {
                    component: "document",
                    _id: id
                };
                this.calldata.softDelete(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            // this.getAllDocument();
                            this.getIndividualFolderDetail(this.folderId)
                            this.toast.success("Documents " + res.message);
                            this.spinner.hide();
                        } else {
                            this.toast.info("Documents " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select a Document");
            this.spinner.hide();
        }


    }

    viewRecycle() {
        this.router.navigate(["/panel/received-docs/deleted"]);
    }

    folderList: any;

    getFolderList() {
        this.spinner.show();
        this.calldata.getDocumentFolderList().subscribe((res: any) => {
            this.spinner.hide();
            if (res.status) {
                this.spinner.hide();
                this.folderList = res.data.list;
                this.isShowDocumentTable = false;
            } else {
                this.spinner.hide();
                this.toast.error(res.message);
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    deleteFolder(value) {
        if (confirm("Are you sure want to delete this folder?")) {
            this.spinner.show()
            this.calldata.deleteDocumentFolder(value).subscribe((res: any) => {
                if (res.status) {
                    this.spinner.hide();
                    this.toast.success(res.message);
                    this.getFolderList();
                } else {
                    this.spinner.hide();
                    this.toast.error(res.message);
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            })
        }
    }

    editFolder(value) {
        let initialState = {
            folderList: value
        }
        this.bsModalRef = this.modalService.show(CreateFolderComponent, {
            //initialState,
            animated: true,
            backdrop: "static",
            class: "modal-dialog-centered",
            initialState
        });

        this.bsModalRef.onHide.subscribe((res: any) => {
            this.getFolderList();
        })
    }

    isShowDocumentTable: boolean = false;
    docName: any = "";
    docNameForNav: any = "";
    folderId: any = "";

    getIndividualFolderDetail(data) {
        this.spinner.show();
        this.folderId = data;
        this.calldata.getIndividualFolderDetail(data._id).subscribe((res: any) => {
            if (res.status) {
                this.isShowDocumentTable = true;
                this.docNameForNav = data.name;
                this.documents = res.data.documents;
                this.documents = [];
                //   this.documents = data["data"].reverse();
                const data1 = res.data.documents.map((d) => {
                    var i = Math.floor(Math.log(d.file_detail.size) / Math.log(1024));
                    return {
                        origional_name: d.file_detail.origional_name || d.file_name,
                        file_size:
                            Math.round(d.file_detail.size / Math.pow(1024, i)) + " " + "KB",
                        createdAt: d.createdAt,
                        expiryDate: d.expiryDate,
                        _id: d._id,
                        fileUrl: d.file_url,
                        document_ID: d._id
                    };
                });
                this.documents = data1.reverse();
                this.totalCount = this.documents.length;
                this.spinner.hide();
            } else {
                this.isShowDocumentTable = false;
                this.spinner.hide();
                this.toast.error(res.message)
            }
            this.spinner.hide();
        }, (err) => {
            this.isShowDocumentTable = false;
            this.spinner.hide();
            this.toast.error("Something went wrong")
        })
    }

    searchDocument(value) {
        this.docName = value;
        // this.getAllDocument();
    }

    reset() {
        this.getFolderList();
    }

    redirect() {
        this.isShowDocumentTable = false;
        this.getFolderList();
    }

    editDocument(val) {

        let data = {
            fileUrl: val.fileUrl,
            documentId: val._id
        }
        this.router.navigate(['panel/document/edit'], { state: { data: data } })
    }
}
