import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import {
    FormControl,
    FormGroupDirective,
    FormBuilder,
    FormGroup,
    NgForm,
    Validators,
    FormArray,
} from "@angular/forms";
import { environment } from "../../../../environments/environment";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { GeneralServiceService } from "../../../_services/general-service.service";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";
import { Router } from "@angular/router";
import { ViewInvitationListComponent } from "../../models/view-invitation-list/view-invitation-list.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ViewEformModalComponent } from "../eforms/view-eform-modal/view-eform-modal.component";
import { EformresponseViewComponent } from "../eformsresponse/eformresponse-view/eformresponse-view.component";
declare var $: any;

class ImageSnippet {
    constructor(public src: string, public file: File) { }
}
@Component({
    selector: "app-clients",
    templateUrl: "./clients.component.html",
    styleUrls: ["./clients.component.css"],
})
export class ClientsComponent implements OnInit {
    @ViewChild("fileInput")
    myInputVariable: ElementRef;

    somethingSelected: boolean = true;
    selectedList: any[] = [];
    userlist: any = [];
    allClients = [];
    form_file: any;
    form_domain: any;
    fields = [];
    fullname: string;
    adduserform: FormGroup;
    isSubmitted = false;
    currUserId: string;
    client_search_input: string = "";
    passcode: string;
    firstName: string;
    lastName: string;
    email: string;
    akcessId: string;
    act: string;
    phone: number;
    active_flg: Boolean;
    address: string;
    usertype: string;
    portalUser;
    eformresponse = [];
    formname: any;
    imageUrl: string = environment.imgurl;
    imageUrlsForPDF = [];
    device_token: string;
    userAkcessID: string;
    eFormverifyCheck: any = [];

    pdfcolums = [{}];
    xls_fileName = "ExcelSheet.xlsx";
    pdf_fileName = "PDF.pdf";
    clientStatus: string;
    clientProducts: string;
    USDAccountBalance: string;
    KDAccountBalance: string;
    eformStatus: string = "";
    verifiedItems: Array<String> = [];
    gradedata = [];

    eFormResposeFilterBy: any = "";

    Status: any = ["Verified Users"];
    graderLoader: boolean = false;

    Nationality_options: any = [];
    Gender_options: any = [];
    Education_options: any = [];
    Language_options: any = [];
    MaritalStatus_options: any = [];
    Country_options: any = [];
    sourceOfWealth_options: any = [
        {
            label: "Saving",
        },
        {
            label: "Investment",
        },
        {
            label: "Inheritance",
        },
        {
            label: "Business",
        },
        {
            label: "Salary Transfer",
        },
        {
            label: "Other",
        },
    ];
    showspecify_s: string;
    showspecify_bo: string;
    showspecify_oi: string;
    showspecify_pe: string;
    showspecify_trk: string;
    showspecify_trko: string;
    showspecify_troo: string;
    showspecify_tin: string;

    bulkEmail: any = [];
    clientData: any;
    globallist: any;
    clientAkcessId: any;
    StatusDescription: any = {
        alive: "Alive",
        Pending: "Pending",
        pending: "Pending",
        Accept: " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
        accept: " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
        createClient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        createclient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",

        "update client field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",

        "Update Client Field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        "Verify and Accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        "verify and accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        Return: "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
        return: "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
        Reject: "When you apply 'reject' action, user will receive a notification that you rejected his response",
        reject: "When you apply 'reject' action, user will receive a notification that you rejected his response",
    };

    eformDataWithForExpiryDate: any;
    expiryDateIndex: any;
    addExpiryDate: any;
    xslFile: any;
    Countries: any;
    selectedFile: ImageSnippet;

    currentPage: any = 1;
    currentEformResponsePage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    numberOfEformResponsePage: Array<Number> = [5, 10, 20, 30, 40, 50];
    pageSize: any = 10;
    pageEformResponseSize: any = 5;
    fieldVerifierData: any;
    minDate: Date;
    bulkMobile: any = [];

    responseId: any;
    pullDataStatus: any = "";
    facematch: any;
    faceMatchPic: any;

    isFacialMatched: any = null;

    score: number = 0;
    creditScore: number = 0;
    blackListStatus: string = "no";

    isFileChosen: boolean;
    fileName: string = "";

    otherBankClientList: any;
    bankList: any = [];
    otherBankData: any;
    showTabel: string = "";

    constructor(
        private gs: GeneralServiceService,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private formBuilder: FormBuilder,
        public refreshContentService: RefreshContentService,
        public commonService: CommonService,
        private router: Router,
        public modalService: BsModalService,
        public cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.minDate = new Date();
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                this.initAddUserForm();
                this.globalprofile();
            } else {
                this.initAddUserForm();
                this.globalprofile();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
        this.getclients("");
        this.isFileChosen = false;
    }

    globalprofile() {
        const country = "kuwait";
        this.gs.getglobalprofile(country.toLowerCase()).subscribe(
            (data) => {
                if (data["status"]) {
                    this.globallist = data["data"];
                    this.globallist.forEach((element) => {
                        if (element.key == "gender")
                            this.Gender_options = element.items;
                        if (element.key == "nationality")
                            this.Nationality_options = element.items;
                        if (element.key == "maritalstatus")
                            this.MaritalStatus_options = element.items;
                        if (element.key == "language")
                            this.Language_options = element.items;
                        if (element.key == "education")
                            this.Education_options = element.items;
                        if (element.key == "countryofresidence")
                            this.Countries = element.items;
                    });
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    validators: any;

    initAddUserForm() {
        this.adduserform = this.formBuilder.group({
            _id: [null],
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            domainname: environment.domainName,
            email: [null, [Validators.required, Validators.email]],
            phone: [null, [Validators.pattern("[0-9]*")]],
            country_code: [null],
            // lastNameArabic: [null],
            // active_flg: [null],
            // address: [null],
            // usertype: [null],
            // clientStatus: [null],
            // clientProducts: [null],
            // USDAccountBalance: [null, [Validators.pattern("[0-9]*")]],
            // KDAccountBalance: [null, [Validators.pattern("[0-9]*")]],
            // civilIDExpDate: [null],
            // civilIdNumber: [null, [Validators.pattern("[0-9]{12}")]],
            // passportExpDate: [null],
            // passportNumber: [null],
            // birthPlace: [null],
            // birthDate: [null],
            // nationality: [null],
            // gender: [null],
            // education: [null],
            // language: [null],
            // maritalStatus: [null],
            // occupation: [null],
            // recruitmentDate: [null],
            // employerName: [null],
            // salary: [null, [Validators.pattern("[0-9]*")]],
            // rollPosition: [null],
            // studentAtUniColl: [null],
            // graduationDate: [null],
            // officePhone: [null],
            // workAddress: [null],
            // faxNo: [null],
            // mobile: [null, [Validators.required, Validators.pattern("[0-9]*")]],
            // aveneu: [null],
            // street: [null],
            // block: [null],
            // area: [null],
            // buildingPlot: [null],
            // floor: [null],
            // unit: [null],
            // postOffice: [null],
            // poBox: [null, [Validators.pattern("^[A-Za-z0-9]*$")]],
            // zipCode: [null, [Validators.pattern("[0-9]{4,5}")]],
            // city: [null],
            // sourceOfWealth: this.formBuilder.array([this.initSourceOfWealth]),
            // otherIncomeBusiness: [null, Validators.required],
            // /* otherIncomeBusinessArray: this.formBuilder.array([
            //   this.initOtherIncomeBusinessArray,
            // ]), */
            // politicallyExp: [false],
            // politicallyExpSelf: [null],
            // taxResidentKuwait: [null],
            // CVDNo: [null, [Validators.pattern("[0-9]{12}")]],
            // taxResidentKandO: [null],
            // taxResidentOnlyO: [null],
            // taxResidentKand: this.formBuilder.array([this.initTaxResidentKand]),
            // taxResidentKandNot: this.formBuilder.array([this.initTaxResidentKandNot]),
            // TINReason_reason: [null],
            // TINReason_reasonB: this.formBuilder.array([this.initTINReason_reasonB]),
        });

        //this.politicallyExpDetails.valueChanges.subscribe(checked => {
        //    if (checked) {
        //        this.adduserform.setControl("politicallyExpArray", this.formBuilder.array([this.initPoliticallyExpArray]))
        //    } else {
        //        this.adduserform.removeControl('politicallyExpArray');
        //    }
        //})

        //this.otherIncomeBusinessDetails.valueChanges.subscribe(checked => {
        //    if (checked == 1) {
        //        this.adduserform.setControl("otherIncomeBusinessArray", this.formBuilder.array([this.initOtherIncomeBusinessArray]))
        //    } else {
        //        this.adduserform.removeControl('otherIncomeBusinessArray');
        //    }
        //})
    }

    handleSelected_s($event) {
        if ($event.target.value == "Other" && $event.target.checked === true) {
            this.showspecify_s = "1";
        } else {
            this.showspecify_s = "0";
        }
    }

    get initSourceOfWealth(): FormGroup {
        return this.formBuilder.group({
            saving: [false],
            investment: [false],
            inheritance: [false],
            business: [false],
            salaryTransfer: [false],
            other: [false],
            sourceOfWealthSp: [null],
        });
    }

    get initOtherIncomeBusinessArray(): FormGroup {
        return this.formBuilder.group({
            otherIncomeSpecification: [null, Validators.required],
            otherIncomeCountry: [null, Validators.required],
            otherIncomeDateOfEsta: [null, Validators.required],
            otherIncomeEstimatedIncome: [null, Validators.required],
            otherIncomeAnnual: [null],
            otherIncomeOther: [null],
            otherIncomeOtherspecification: [null],
        });
    }

    addOtherIncomeBusinessArray() {
        (this.adduserform.get("otherIncomeBusinessArray") as FormArray).push(
            this.initOtherIncomeBusinessArray
        );
    }

    removeOtherIncomeBusinessArray(i) {
        (
            this.adduserform.get("otherIncomeBusinessArray") as FormArray
        ).removeAt(i);
    }

    get initPoliticallyExpArray(): FormGroup {
        return this.formBuilder.group({
            politicallyExp_Name: [null, Validators.required],
            politicallyExp_Relation: [null, Validators.required],
            politicallyExp_Position: [null, Validators.required],
        });
    }

    addPoliticallyExpArray() {
        (this.adduserform.get("politicallyExpArray") as FormArray).push(
            this.initPoliticallyExpArray
        );
    }

    removePoliticallyExpArray(i) {
        (this.adduserform.get("politicallyExpArray") as FormArray).removeAt(i);
    }

    // TINReason_reason B

    get initTINReason_reasonB(): FormGroup {
        return this.formBuilder.group({
            country: [null],
            reason: [null],
        });
    }

    addTINReason_reasonB() {
        (this.adduserform.get("TINReason_reasonB") as FormArray).push(
            this.initTINReason_reasonB
        );
    }

    removeTINReason_reasonB(i) {
        (this.adduserform.get("TINReason_reasonB") as FormArray).removeAt(i);
    }

    // End

    get initTaxResidentKand(): FormGroup {
        return this.formBuilder.group({
            taxResidentKand_country: new FormControl(null),
            taxResidentKand_taxIdNo: new FormControl(null),
        });
    }

    addTaxResidentKand() {
        (this.adduserform.get("taxResidentKand") as FormArray).push(
            this.initTaxResidentKand
        );
    }

    removeTaxResidentKand(i) {
        (this.adduserform.get("taxResidentKand") as FormArray).removeAt(i);
    }

    get initTaxResidentKandNot(): FormGroup {
        return this.formBuilder.group({
            taxResidentNot_country: [null],
            taxResidentNot_taxIdNo: [null],
        });
    }

    addTaxResidentKandNot() {
        (this.adduserform.get("taxResidentKandNot") as FormArray).push(
            this.initTaxResidentKandNot
        );
    }

    removeTaxResidentKandNot(i) {
        (this.adduserform.get("taxResidentKandNot") as FormArray).removeAt(i);
    }

    handleSelected_bo($event) {
        if ($event.target.checked === true) {
            this.showspecify_bo = "1";
        } else {
            this.showspecify_bo = "0";
            (
                this.adduserform.get("otherIncomeBusinessArray") as FormArray
            ).clear();
            (
                this.adduserform.get("otherIncomeBusinessArray") as FormArray
            ).push(this.initOtherIncomeBusinessArray);
        }
    }
    handleSelected_oi($event) {
        if ($event.target.checked === true) {
            this.showspecify_oi = "1";
        } else {
            this.showspecify_oi = "0";
        }
    }
    handleSelected_pe($event) {
        if ($event.target.checked === true) {
            this.showspecify_pe = "1";
        } else {
            this.showspecify_pe = "0";
        }
    }
    handleSelected_trk($event) {
        if ($event.target.checked === true) {
            this.showspecify_trk = "1";
        } else {
            this.showspecify_trk = "0";
        }
    }
    handleSelected_trko($event) {
        if ($event.target.checked === true) {
            this.showspecify_trko = "1";
        } else {
            this.showspecify_trko = "0";
        }
    }
    handleSelected_troo($event) {
        if ($event.target.checked === true) {
            this.showspecify_troo = "1";
        } else {
            this.showspecify_troo = "0";
        }
    }

    handleSelected_tin($event) {
        if ($event.target.value == "B") {
            this.showspecify_tin = "1";
        } else {
            this.showspecify_tin = "0";
            (this.adduserform.get("TINReason_reasonB") as FormArray).clear();
            (this.adduserform.get("TINReason_reasonB") as FormArray).push(
                this.initTINReason_reasonB
            );
        }
    }


    getclients(searchVal) {
        this.spinner.show();
        const tmpdata = {
            search: searchVal,
            page: this.currentPage,
            limit: this.pageSize,
        };
        if (this.client_search_input && this.client_search_input.length)
            tmpdata["search"] = this.client_search_input;
        //   tmpdata["domainName"] = this.commonService.selectedCompanyDeatils.domain
        this.calldata.getclients(tmpdata).subscribe(
            (res: any) => {
                if (res.status) {
                    this.userlist = res.data.clientList;
                    this.totalCount = res.data.total;
                    if (this.userlist.length > 0 && res["data"]["eformData"]) {
                        this.formId = res.data.eformData.eformID;
                        this.formName = res.data.eformData.formName;
                    }
                    this.toast.success(res.message);
                    this.spinner.hide();
                } else {
                    this.spinner.hide();
                    this.toast.info(res.message)
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });

    }

    exportexcel(formname, fullname): void {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.xls_fileName =
            formname + "_" + formattedDate + "_" + fullname + ".xlsx";
        /* table id is passed over here */

        let element = document.getElementById("xls_formfields");
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        XLSX.writeFile(wb, this.xls_fileName);
    }

    // pdf merging

    async getPdfBytes(eform, arrayPdfurls, name) {
        let basePdfDoc;
        basePdfDoc = await PDFDocument.load(eform);
        if (arrayPdfurls.length > 0) {
            let requests = arrayPdfurls.map((url) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        let buffArrayTemp = await fetch(url).then((res) =>
                            res.arrayBuffer()
                        );
                        const loaddedPdf = await PDFDocument.load(
                            buffArrayTemp
                        );
                        const copiedPages = await basePdfDoc.copyPages(
                            loaddedPdf,
                            loaddedPdf.getPageIndices()
                        );
                        copiedPages.forEach((page) => basePdfDoc.addPage(page));
                        let pdfBytes = await basePdfDoc.save();
                        resolve(pdfBytes);
                    } catch (e) {
                        reject(e);
                    }
                });
            });

            Promise.all(requests).then(
                async (result) => {
                    var binaryData = [];
                    binaryData.push(result[result.length - 1]);

                    window.open(
                        window.URL.createObjectURL(
                            new Blob(binaryData, { type: "application/pdf" })
                        ),
                        "pdf",
                        "toolbar=no,location=no,directories=no,status=no, ar=no,scrollbar=no,resizable=no,copyhistory=yes"
                    );
                },
                (e) => {
                    // alert("no Files found");
                }
            );
        } else {
            var binaryData = [];
            let pdfBytes;
            pdfBytes = await basePdfDoc.save();
            binaryData.push(pdfBytes);

            window.open(
                window.URL.createObjectURL(
                    new Blob(binaryData, { type: "application/pdf" })
                ),
                "pdf",
                "toolbar=no,location=no,directories=no,status=no, ar=no,scrollbar=no,resizable=no,copyhistory=yes"
            );
        }
    }

    // PDF merging from urls end

    async generatePdf(formname, fullname) {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.pdf_fileName =
            formname + "_" + formattedDate + "_" + fullname + ".pdf";
        const documentDefinition = this.getDocumentDefinition();
        const pdfDocGenerator = await pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBuffer(async (buffer) => {
            await this.getPdfBytes(
                buffer,
                this.imageUrlsForPDF,
                this.pdf_fileName
            );
        });
    }
    getDocumentDefinition() {
        var html = htmlToPdfmake(
            document.getElementById("pdf_formfields").innerHTML
        );
        return {
            content: [html],
            styles: {
                "html-strong": {
                    background: "", // it will add a yellow background to all <STRONG> elements
                },
            },
        };
    }
    addclient() {
        this.act = "add";
        this.isSubmitted = true;
        const tmpdate = moment().format();

        // if (this.adduserform.invalid) {
        //     alert("invalid");
        //     return;
        // }
        // this.spinner.show();
        var formData = this.adduserform.value;
        if (formData._id == null) {
            this.calldata.createcompanyclient(formData).subscribe(
                (data) => {
                    if (data["status"]) {
                        this.toast.info(data["message"]);
                        $("#addformclose").click();
                        this.resetform();
                        this.getclients("");
                        // this.getclientList(this.eFormResposeFilterBy);
                        // this.spinner.hide();
                        // this.spinner.show();
                    } else {
                        this.toast.info(data["message"]);
                        // this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        } else {
            this.calldata.editclient(formData).subscribe((data) => {
                if (data["status"]) {
                    $("#addformclose").click();
                    this.resetform();
                    this.getclients("");
                    // this.getclientList(this.eFormResposeFilterBy);
                    // this.spinner.show();
                    // this.spinner.hide();
                } else {
                    this.toast.info(data["message"]);
                    // this.spinner.hide();
                    $("#addformclose").click();
                    this.resetform();
                }
                // this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
        }

        // this.calldata.adduser()
    }

    view(val) {
        this.act = "view";
        this.score = 0;
        this.creditScore = 0;
        this.blackListStatus = "no";
        this.fields = [];
        this.clientData = val;
        this.creditScore = val && val.creditScore ? val.creditScore : 0;
        this.score = val && val.score ? val.score : 0;
        this.blackListStatus = val && val.blackListStatus ? "yes" : "no";
    }

    async viewEformResponses(val, fullname, _id, akcessId) {
        const initialState: any = {
            val: val,
            fullname: fullname,
            _id: _id,
            akcessId: akcessId,
            eformresponse: this.eformresponse
        };
        $("#vieweformresponses").modal("hide");
        this.bsModalRef = this.modalService.show(EformresponseViewComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-xl",
        });

    }

    openExpiryDate(index) {
        this.expiryDateIndex = null;
        this.expiryDateIndex = index;
        this.eformDataWithForExpiryDate = {};
        this.eformDataWithForExpiryDate = this.fields[index];
    }

    pushExpiryDateInFiedls() {
        this.fields[this.expiryDateIndex].expiryDate = moment(
            this.addExpiryDate
        ).format();
        this.addExpiryDate = "";
        $("#expiryDateModal").modal("hide");
    }

    async openedituser(rowdata) {
        this.act = "edit";

        this.adduserform.patchValue({
            _id: rowdata._id,
            firstName: rowdata.firstName,
            lastName: rowdata.lastName,
            firstNameArabic: rowdata.firstNameArabic,
            lastNameArabic: rowdata.lastNameArabic,
            email: rowdata.email,
            phone: rowdata.phone,
            active_flg: rowdata.active_flg,
            address: rowdata.address,
            usertype: rowdata.usertype,
            clientStatus: rowdata.clientStatus,
            clientProducts: rowdata.clientProducts,
            USDAccountBalance: rowdata.USDAccountBalance,
            KDAccountBalance: rowdata.KDAccountBalance,
            civilIDExpDate: rowdata.civilIDExpDate
                ? new Date(rowdata.civilIDExpDate)
                : null,
            civilIdNumber: rowdata.civilIdNumber,
            passportExpDate: rowdata.passportExpDate
                ? new Date(rowdata.passportExpDate)
                : null,
            passportNumber: rowdata.passportNumber,
            birthPlace: rowdata.birthPlace,
            birthDate: rowdata.birthDate ? new Date(rowdata.birthDate) : null,
            nationality: rowdata.nationality,
            gender: rowdata.gender,
            education: rowdata.education,
            language: rowdata.language,
            maritalStatus: rowdata.maritalStatus,
            occupation: rowdata.occupation,
            recruitmentDate: rowdata.recruitmentDate
                ? new Date(rowdata.recruitmentDate)
                : null,
            employerName: rowdata.employerName,
            salary: rowdata.salary,
            rollPosition: rowdata.rollPosition,
            studentAtUniColl: rowdata.studentAtUniColl,
            graduationDate: rowdata.graduationDate
                ? new Date(rowdata.graduationDate)
                : null,
            officePhone: rowdata.officePhone,
            workAddress: rowdata.workAddress,
            faxNo: rowdata.faxNo,
            mobile: rowdata.mobile,
            aveneu: rowdata.aveneu,
            street: rowdata.street,
            block: rowdata.block,
            area: rowdata.area,
            buildingPlot: rowdata.buildingPlot,
            floor: rowdata.floor,
            unit: rowdata.unit,
            postOffice: rowdata.postOffice,
            poBox: rowdata.poBox,
            zipCode: rowdata.zipCode,
            city: rowdata.city,
            otherIncomeBusiness: rowdata.otherIncomeBusiness,
            otherIncomeOtherspecification:
                rowdata.otherIncomeOtherspecification,
            politicallyExp: rowdata.politicallyExp,
            politicallyExpSelf: rowdata.politicallyExpSelf,
            taxResidentKuwait: rowdata.taxResidentKuwait,
            CVDNo: rowdata.CVDNo,
            taxResidentKandO: rowdata.taxResidentKandO,
            taxResidentOnlyO: rowdata.taxResidentOnlyO,
            TINReason_reason: rowdata.TINReason_reason,
        });

        this.adduserform.setControl(
            "taxResidentKand",
            this.gettaxResidentKandData(rowdata.taxResidentKand)
        );
        this.adduserform.setControl(
            "taxResidentKandNot",
            this.gettaxResidentKandNot(rowdata.taxResidentKandNot)
        );
        this.adduserform.setControl(
            "TINReason_reasonB",
            this.getTINReason_reasonB(rowdata.TINReason_reasonB)
        );
        this.adduserform.setControl(
            "politicallyExpArray",
            this.gettPoliticallyExpArray(rowdata.politicallyExpArray)
        );
        this.adduserform.setControl(
            "otherIncomeBusinessArray",
            this.getOtherIncomeBusinessArray(rowdata.otherIncomeBusinessArray)
        );
        this.adduserform.setControl(
            "sourceOfWealth",
            this.getSourceOfWealth(rowdata.sourceOfWealth)
        );
    }

    get politicallyExpOther() {
        return this.adduserform.get("politicallyExp") as FormControl;
    }

    getSourceOfWealth(result: any[]): FormArray {
        const formArray = new FormArray([]);
        if (result.length === 0) {
            result[0] = "";
        } else {
            result.forEach((element) => {
                formArray.push(
                    this.formBuilder.group({
                        saving: element.saving,
                        investment: element.investment,
                        inheritance: element.inheritance,
                        business: element.business,
                        salaryTransfer: element.salaryTransfer,
                        other: element.other,
                        sourceOfWealthSp: element.sourceOfWealthSp,
                    })
                );
            });
        }

        return formArray;
    }

    getOtherIncomeBusinessArray(result: any[]): FormArray {
        const formArray = new FormArray([]);
        if (result.length === 0) {
            result[0] = "";
        } else {
            this.showspecify_bo = "1";
            result.forEach((s, i) => {
                formArray.push(
                    this.formBuilder.group({
                        otherIncomeSpecification: s.otherIncomeSpecification,
                        otherIncomeCountry: s.otherIncomeCountry,
                        otherIncomeDateOfEsta: s.otherIncomeDateOfEsta
                            ? new Date(s.otherIncomeDateOfEsta)
                            : null,
                        otherIncomeEstimatedIncome:
                            s.otherIncomeEstimatedIncome,
                        otherIncomeAnnual: s.otherIncomeAnnual,
                        otherIncomeOther: s.otherIncomeOther,
                        otherIncomeOtherspecification:
                            s.otherIncomeOtherspecification,
                    })
                );
            });
        }
        return formArray;
    }

    gettPoliticallyExpArray(result: any[]): FormArray {
        const formArray = new FormArray([]);
        if (result.length === 0) {
            result[0] = "";
        } else {
            this.showspecify_pe = "1";
            result.forEach((s, i) => {
                formArray.push(
                    this.formBuilder.group({
                        politicallyExp_Name: s.politicallyExp_Name,
                        politicallyExp_Relation: s.politicallyExp_Relation,
                        politicallyExp_Position: s.politicallyExp_Position,
                    })
                );
            });
        }
        return formArray;
    }

    gettaxResidentKandData(result: any[]): FormArray {
        const formArray = new FormArray([]);
        if (result.length === 0) {
            result[0] = "";
        } else {
            this.showspecify_trko = "1";
            result.forEach((s, i) => {
                formArray.push(
                    this.formBuilder.group({
                        taxResidentKand_country: s.taxResidentKand_country,
                        taxResidentKand_taxIdNo: s.taxResidentKand_taxIdNo,
                    })
                );
            });
        }
        return formArray;
    }

    gettaxResidentKandNot(result) {
        const formArray = new FormArray([]);
        if (result.length === 0) {
            result[0] = "";
        } else {
            this.showspecify_troo = "1";
            result.forEach((s, i) => {
                formArray.push(
                    this.formBuilder.group({
                        taxResidentNot_country: s.taxResidentNot_country,
                        taxResidentNot_taxIdNo: s.taxResidentNot_taxIdNo,
                    })
                );
            });
        }
        return formArray;
    }

    getTINReason_reasonB(result) {
        const formArray = new FormArray([]);
        if (result.length === 0) {
            result[0] = "";
        } else {
            this.showspecify_tin = "1";
            result.forEach((s, i) => {
                formArray.push(
                    this.formBuilder.group({
                        country: s.country,
                        reason: s.reason,
                    })
                );
            });
        }

        return formArray;
    }
    deleteClient(id) {
        if (confirm("Are you sure you want to remove client?")) {
            this.spinner.show();
            const data = { _id: id };
            this.calldata.deleteClient(data).subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        // this.getclientList(this.eFormResposeFilterBy);
                        this.spinner.hide();
                        this.toast.success(data["message"]);
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }
    setuserid(uid) {
        this.currUserId = uid;
    }
    changepasscode() {
        this.spinner.show();
        const data = { _id: this.currUserId, passCode: this.passcode };
        this.calldata.changepasscode(data).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.toast.success(data["message"]);
                    this.spinner.hide();
                    this.passcode = "";
                    $("#close").click();
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }
    resetform() {
        // alert("reset")
        this.act = "";
        this.showspecify_s = "0";
        this.showspecify_bo = "0";
        this.showspecify_oi = "0";
        this.showspecify_pe = "0";
        this.showspecify_trk = "0";
        this.showspecify_trko = "0";
        this.showspecify_troo = "0";
        this.showspecify_tin = "0";
        this.isSubmitted = false;
        this.initAddUserForm();
        this.adduserform.reset();
        this.modalService.hide();

        this.isSubmitted = false;
        this.adduserform.reset();
        this.emailList = [];
        this.phoneNumberList = [];
        this.emailValue = "";
        this.phoneForm.controls.phone.setValue("");
        this.model.editorData = "";
        this.isEmailInvitation = false;
        this.isPhoneInvitation = false;
        this.isTemplate = false;
        this.modalService.hide()
    }

    totalEformResponseCount: any = 0;

    viewEformsResponses(akid) {
        this.eformresponse = [];
        this.spinner.show();
        this.clientAkcessId = "";
        this.clientAkcessId = akid;
        const tmpdata = {
            akcessId: akid,
            usertype: "Client",
            currentPage: this.currentEformResponsePage,
            limit: this.pageEformResponseSize,
            orderBy: "nto",
        };

        if (akid) {
            this.calldata.fetchresponse(tmpdata).subscribe(
                (data: any) => {
                    if (data["status"]) {
                        this.eformresponse = [];
                        this.totalEformResponseCount = data.data.totalCount;
                        this.eformresponse = data.data.data;
                        this.spinner.hide();
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        } else {
            this.eformresponse = [];
            this.spinner.hide();
        }
    }
    approveresponse(act, val, akid) {
        if (confirm("Are you sure you want to do this action?")) {
            this.spinner.show();
            const data = { _id: val, act: act };
            this.calldata.approveresponse(data).subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        this.viewEformsResponses(akid);
                        this.toast.success(data["message"]);
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }
    setVerifiedItem(evt, i): void {
        let id = this.fields[i]._id;

        if (evt.target.checked) {
            this.verifiedItems.push(id);
        } else {
            this.verifiedItems.splice(this.verifiedItems.indexOf(id), 1);
        }
    }
    resetVerifiedItems(): void {
        // reset verified items
        this.verifiedItems = [];
    }
    onSubmit() {
        let vdata = [];
        this.fields.forEach((field) => {
            var isPresent = this.verifiedItems.some(function (el) {
                return el === field._id;
            });
            if (isPresent) {
                vdata.push(field);
            }
        });
        if (!this.eformStatus) {
            this.toast.error("Please select status");
            return false;
        } else {
            this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
            let data = {
                eFormResponseId: this.fields["eFormResponseId"],
                fields_form: this.verifiedItems,
                eformsFields: vdata,
                eformStatus: this.eformStatus,
                akcessId: this.userAkcessID || this.portalUser.akcessId,
                portalTitle:
                    this.commonService.portalDetails.portalDetails.portalTitle,
                databaseURL:
                    this.commonService.portalDetails.portalDetails.portalData
                        .setting.databaseURL,
            };

            this.calldata.verifyfield(data).subscribe(
                (res) => {
                    if (res["status"] === 1) {
                        if (
                            data.eformStatus == "update client field" ||
                            data.eformStatus == "createclient"
                        ) {
                            this.updateClientForm(this.fields);
                        }
                        // this.getclientList(this.eFormResposeFilterBy);
                        this.toast.success(res["message"]);
                        $("#viewform").modal("hide");

                        let fieldsToVerify = [];
                        let expiryDateArray = [];

                        if (data.eformsFields.length > 0) {
                            data.eformsFields.forEach((element) => {
                                if (element.keytype == "file") {
                                    let object = {
                                        verifierAkcessId:
                                            this.portalUser.akcessId,
                                        userAkcessId: this.userAkcessID,
                                        documentId: element.value,
                                        expiryDate: element.expiryDate,
                                    };
                                    this.calldata
                                        .docVerifyField(object)
                                        .subscribe((res: any) => {
                                        });
                                } else {
                                    expiryDateArray.push(element.expiryDate);
                                    fieldsToVerify.push(element.key);
                                }
                            });

                            if (fieldsToVerify.length > 0) {
                                let object = {
                                    verifierAkcessId: this.portalUser.akcessId,
                                    userAkcessId: this.userAkcessID,
                                    profileField: fieldsToVerify,
                                    expiryDate: expiryDateArray,
                                };

                                this.calldata
                                    .VerifyField(object)
                                    .subscribe((res: any) => {
                                    });
                            }
                        }
                    } else {
                        this.toast.info(res["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }

    viewGradeVer(data) {
        this.graderLoader = true;

        this.fieldVerifierData = [];

        if (
            data.keytype == "string" ||
            data.keytype == "text" ||
            data.keytype == "list"
        ) {
            let object = {
                userAkcessId: this.userAkcessID,
                profileField: data.key,
            };
            this.calldata.getFieldVerifierOnPortal(object).subscribe(
                (res: any) => {
                    if (res.status) {
                        this.fieldVerifierData = res.data;
                        this.graderLoader = false;
                    } else {
                        this.graderLoader = false;
                    }
                },
                (err) => {
                    this.graderLoader = false;
                }
            );
        }

        if (data.keytype == "file") {
            let object = {
                userAkcessId: this.userAkcessID,
                documentId: data.value,
            };

            this.calldata
                .getDocVerifierOnPortal(object)
                .subscribe((res: any) => {
                    if (res.status) {
                        this.fieldVerifierData = res.data;
                    }
                });
        }

        this.gradedata["key"] = data.key;
    }

    getBulkEmailID(e) {
        if (e.target.checked == true) {
            this.selectedList.push(e.target.checked)
        } else if (e.target.checked == false) {
            if (this.selectedList.length > 0) {
                this.selectedList.splice(0, 1);
            }
        }
        if (this.selectedList.length === 0) {
            this.somethingSelected = true;
        } else {
            this.somethingSelected = false;
        }
        if (e.target.checked) {
            this.bulkEmail.push(e.target.value);
        } else {
            let indexOfEmail = this.bulkEmail.findIndex(
                (email) => email == e.target.value
            );
            this.bulkEmail.splice(indexOfEmail, 1);
        }
    }

    getBulkMobiles(e, mobile) {
        if (e.target.checked) {
            this.bulkMobile.push(mobile);
        } else {
            let indexOfMobile = this.bulkMobile.findIndex((m) => m == mobile);
            this.bulkMobile.splice(indexOfMobile, 1);
        }
    }
    dbName: any = this.commonService.selectedCompanyDeatils.dbName;

    sendEmailToClient(email) {
        this.spinner.show();
        let eformNameA = this.formName.replace(/\s+/g, "");
        let eformurl = environment.imgurl + `redirect/${eformNameA}/${this.formId}/${this.dbName}`;
        this.calldata
            .sendInvitation(email, eformurl)
            .subscribe((res: any) => {
                this.toast.success("Invitation sent");
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.");
            });
    }

    //For Bank
    //sendEmailToClient(email) {
    //    this.calldata.getClientInvitationEform({}).subscribe((res: any) => {
    //        if (res && res.data && res.data.isclientInvitationEform && res.data.isclientInvitationEform == 'yes') {
    //            let eformName = environment.eformname
    //            let eformNameA = eformName.replace(/\s+/g, "");
    //            let eformurl = environment.url + `redirect/${eformNameA}/${res.data._id}`
    //            let data2222 = {
    //                "dynamicLinkInfo": {
    //                    "domainUriPrefix": "https://eforms.akcess.io",
    //                    "link": eformurl,
    //                    "androidInfo": {
    //                        "androidPackageName": "com.akcess.io"
    //                    },
    //                    "iosInfo": {
    //                        "iosBundleId": "com.akcess.io.ID"
    //                    }
    //                }
    //            }

    //            this.calldata.getQRcodeURL(data2222).subscribe((res: any) => {
    //                this.calldata.sendInvitation(email, res.shortLink).subscribe((res: any) => {
    //                    this.toast.success('Invitation sent by email')
    //                });
    //            })
    //        } else {
    //            this.toast.warning('Please set the default eform for client')
    //        }
    //    }, err => {
    //        this.toast.error('Something went wrong')
    //    })
    //}

    sendBulkInvitationEmails(data) {
        if (data.length > 0) {
            let eformName = environment.eformname;
            let eformID = environment.eformid;
            if (
                this.commonService.portalDetails.portalDetails
                    .portalDomainName === "bank.akcess.dev"
            ) {
                eformID = "60019a5c9303bc1cbd1608a8";
            }
            let eformNameA = eformName.replace(/\s+/g, "");
            let eformurl =
                environment.url + `redirect/${eformNameA}/${eformID}`;
            let data2222 = {
                dynamicLinkInfo: {
                    domainUriPrefix: "https://eforms.akcess.io",
                    link: eformurl,
                    androidInfo: {
                        androidPackageName: "com.akcess.io",
                    },
                    iosInfo: {
                        iosBundleId: "com.akcess.io.ID",
                    },
                },
            };

            this.calldata.getQRcodeURL(data2222).subscribe((res: any) => {
                this.calldata
                    .sendBulkInvitationEmails(this.bulkEmail, res.shortLink)
                    .subscribe((res: any) => {
                        this.toast.success("Bulk emails send..");
                    });
            });
        } else {
            this.toast.error("Please select the checkbox");
        }
    }

    //For Bank
    //sendBulkInvitationEmails(data) {
    //    if (data.length > 0) {
    //        this.calldata.getClientInvitationEform({}).subscribe((res: any) => {
    //            if (res && res.data && res.data.isclientInvitationEform && res.data.isclientInvitationEform == 'yes') {
    //                let eformName = environment.eformname
    //                let eformNameA = eformName.replace(/\s+/g, "");
    //                let eformurl = environment.url + `redirect/${eformNameA}/${res.data._id}`
    //                let data2222 = {
    //                    "dynamicLinkInfo": {
    //                        "domainUriPrefix": "https://eforms.akcess.io",
    //                        "link": eformurl,
    //                        "androidInfo": {
    //                            "androidPackageName": "com.akcess.io"
    //                        },
    //                        "iosInfo": {
    //                            "iosBundleId": "com.akcess.io.ID"
    //                        }
    //                    }
    //                }
    //                this.calldata.getQRcodeURL(data2222).subscribe((res: any) => {
    //                    this.calldata.sendBulkInvitationEmails(this.bulkEmail, res.shortLink).subscribe((res: any) => {
    //                        this.toast.success('Bulk emails send..')
    //                    });
    //                })
    //            } else {
    //                this.toast.warning('Please set the default eform for client')
    //            }

    //        }, err => {
    //            this.toast.error('Something went wrong')
    //        })

    //    } else {
    //        this.toast.error("Please select the checkbox")
    //    }
    //}

    sendSMSToClient(mobileNumber) {
        let eformName = environment.eformname;
        let eformID = environment.eformid;
        if (
            this.commonService.portalDetails.portalDetails.portalDomainName ===
            "bank.akcess.dev"
        ) {
            eformID = "60019a5c9303bc1cbd1608a8";
        }
        let eformNameA = eformName.replace(/\s+/g, "");
        let eformurl = environment.url + `redirect/${eformNameA}/${eformID}`;
        let data2222 = {
            dynamicLinkInfo: {
                domainUriPrefix: "https://eforms.akcess.io",
                link: eformurl,
                androidInfo: {
                    androidPackageName: "com.akcess.io",
                },
                iosInfo: {
                    iosBundleId: "com.akcess.io.ID",
                },
            },
        };

        this.calldata.getQRcodeURL(data2222).subscribe((res: any) => {
            this.calldata
                .sendInvitationBySMS(mobileNumber, res.shortLink)
                .subscribe((res: any) => {
                    if (res.status == 1) {
                        this.toast.success("Invitation sent");
                    } else {
                        this.toast.error("Something went wrong while send sms");
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                });
        });
    }

    //For Bank
    //sendSMSToClient(mobileNumber) {
    //    this.calldata.getClientInvitationEform({}).subscribe((res: any) => {
    //        if (res && res.data && res.data.isclientInvitationEform && res.data.isclientInvitationEform == 'yes') {
    //            let eformName = environment.eformname
    //            let eformNameA = eformName.replace(/\s+/g, "");
    //            let eformurl = environment.url + `redirect/${eformNameA}/${res.data._id}`
    //            let data2222 = {
    //                "dynamicLinkInfo": {
    //                    "domainUriPrefix": "https://eforms.akcess.io",
    //                    "link": eformurl,
    //                    "androidInfo": {
    //                        "androidPackageName": "com.akcess.io"
    //                    },
    //                    "iosInfo": {
    //                        "iosBundleId": "com.akcess.io.ID"
    //                    }
    //                }
    //            }

    //            this.calldata.getQRcodeURL(data2222).subscribe((res: any) => {
    //                this.calldata.sendInvitationBySMS(mobileNumber, res.shortLink).subscribe((res: any) => {
    //                    if (res.status == 1) {
    //                        this.toast.success('Invitation sent by SMS')
    //                    } else {
    //                        this.toast.error('Something went wrong while send sms')
    //                    }
    //                });
    //            })
    //        } else {
    //            this.toast.warning('Please set the default eform for client')
    //        }
    //    }, err => {
    //        this.toast.error('Something went wrong')
    //    })
    //}

    sendBulkInvitationSMS(data) {
        if (data.length > 0) {
            let eformName = environment.eformname;
            let eformID = environment.eformid;
            let eformNameA = eformName.replace(/\s+/g, "");
            let eformurl =
                environment.url + `redirect/${eformNameA}/${eformID}`;
            let data2222 = {
                dynamicLinkInfo: {
                    domainUriPrefix: "https://eforms.akcess.io",
                    link: eformurl,
                    androidInfo: {
                        androidPackageName: "com.akcess.io",
                    },
                    iosInfo: {
                        iosBundleId: "com.akcess.io.ID",
                    },
                },
            };
            this.calldata.getQRcodeURL(data2222).subscribe((res: any) => {
                this.calldata
                    .sendBulkInvitationBySMS(this.bulkMobile, res.shortLink)
                    .subscribe((res: any) => {
                        if (res.status == 1) {
                            this.toast.success("Invitation sent");
                        } else {
                            this.toast.error(
                                "Something went wrong while send sms"
                            );
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    });
            });
        } else {
            this.toast.error("Please select the checkbox");
        }
    }

    //sendBulkInvitationSMS(data) {
    //    if (data.length > 0) {
    //        this.calldata.getClientInvitationEform({}).subscribe((res: any) => {
    //            if (res && res.data && res.data.isclientInvitationEform && res.data.isclientInvitationEform == 'yes') {
    //                let eformName = environment.eformname
    //                let eformNameA = eformName.replace(/\s+/g, "");
    //                let eformurl = environment.url + `redirect/${eformNameA}/${res.data._id}`
    //                let data2222 = {
    //                    "dynamicLinkInfo": {
    //                        "domainUriPrefix": "https://eforms.akcess.io",
    //                        "link": eformurl,
    //                        "androidInfo": {
    //                            "androidPackageName": "com.akcess.io"
    //                        },
    //                        "iosInfo": {
    //                            "iosBundleId": "com.akcess.io.ID"
    //                        }
    //                    }
    //                }
    //                this.calldata.getQRcodeURL(data2222).subscribe((res: any) => {
    //                    this.calldata.sendBulkInvitationBySMS(this.bulkMobile, res.shortLink).subscribe((res: any) => {
    //                        if (res.status == 1) {
    //                            this.toast.success('Invitation sent by SMS')
    //                        } else {
    //                            this.toast.error('Something went wrong while send sms')
    //                        }
    //                    });
    //                })
    //            } else {
    //                this.toast.warning('Please set the default eform for client')
    //            }

    //        }, err => {
    //            this.toast.error('Something went wrong')
    //        })
    //    } else {
    //        this.toast.error("Please select the checkbox")
    //    }
    //}

    filterBy(value) {
        this.eFormResposeFilterBy = value;
    }

    validDateFormat(val) {
        let split = val.split("/");
        if (split.length == 3) {
            return new Date(`${split[1]}/${split[0]}/${split[2]}`);
        } else {
            return new Date(val);
        }
    }

    updateClientForm(payload) {
        let bodyData: any = {
            akcessId: this.clientAkcessId,
        };

        payload.forEach((element) => {
            if (element.key == "occupation")
                bodyData.occupation = element.value;
            if (element.key == "education") bodyData.education = element.value;
            if (element.key == "university")
                bodyData.studentAtUniColl = element.value;
            if (element.key == "position")
                bodyData.rollPosition = element.value;
            if (element.key == "salary") bodyData.salary = element.value;
            if (element.key == "nationality")
                bodyData.nationality = element.value;
            if (element.key == "gender") bodyData.gender = element.value;
            if (element.key == "zipcode") bodyData.zipCode = element.value;
            if (element.key == "floor") bodyData.floor = element.value;
            if (element.key == "city") bodyData.city = element.value;
            if (element.key == "pooffice") bodyData.postOffice = element.value;
            if (element.key == "block") bodyData.block = element.value;
            if (element.key == "area") bodyData.area = element.value;
            if (element.key == "street") bodyData.street = element.value;
            if (element.key == "avenue") bodyData.aveneu = element.value;
            if (element.key == "faxnumber") bodyData.faxNo = element.value;
            if (element.key == "language") bodyData.language = element.value;
            if (element.key == "birthplace")
                bodyData.birthPlace = element.value;
            if (element.key == "passportnumber")
                bodyData.passportNumber = element.value;
            if (element.key == "passportexpirydate")
                bodyData.passportExpDate = this.validDateFormat(element.value);
            if (element.key == "civilidnumber")
                bodyData.civilIdNumber = element.value;
            if (element.key == "arabiclastname")
                bodyData.lastNameArabic = element.value;
            if (element.key == "arabicfirstname")
                bodyData.firstNameArabic = element.value;
            if (element.key == "civilidexpirydate")
                bodyData.civilIDExpDate = this.validDateFormat(element.value);
            if (element.key == "email") bodyData.email = element.value;
            if (element.key == "mobile") bodyData.mobile = element.value;
            if (element.key == "firstname") bodyData.firstName = element.value;
            if (element.key == "lastname") bodyData.lastName = element.value;
            if (element.key == "workphone")
                bodyData.officePhone = element.value;
            if (element.key == "address") bodyData.address = element.value;
            if (element.key == "workaddress")
                bodyData.workAddress = element.value;
            if (element.key == "employer")
                bodyData.employerName = element.value;
            if (element.key == "graduationdate")
                bodyData.graduationDate = this.validDateFormat(element.value);
            if (element.key == "maritalstatus")
                bodyData.maritalStatus = element.value;
            if (element.key == "dateofbirth")
                bodyData.birthDate = this.validDateFormat(element.value);
            if (element.key == "homephone") bodyData.phone = element.value;
            if (element.key == "cvdno") bodyData.CVDNo = element.value;
            if (element.key == "kdaccountbalance")
                bodyData.KDAccountBalance = element.value;
            if (element.key == "usdaccountbalance")
                bodyData.USDAccountBalance = element.value;
            if (element.key == "products")
                bodyData.clientProducts = element.value;
            if (element.key == "unit") bodyData.unit = element.value;
            if (element.key == "pobox") bodyData.poBox = element.value;
            if (element.key == "buildingplot")
                bodyData.buildingPlot = element.value;
            if (element.key == "recruitmentdate")
                bodyData.recruitmentDate = this.validDateFormat(element.value);
        });

        this.calldata
            .updateClientDataWithReverseOnBoarding(bodyData)
            .subscribe((res: any) => {
            });
    }

    processDocument(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        this.isFileChosen = true;
        this.fileName = file.name;

        reader.addEventListener("load", (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);

            this.calldata.importClientFromXsl(this.selectedFile.file).subscribe(
                (res: any) => {
                    if (res.success) {
                        this.toast.success("Date will be imported soon");
                        this.myInputVariable.nativeElement.value = "";
                        // this.getclientList(this.eFormResposeFilterBy);
                        $("#importBulk").modal("hide");
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        });

        reader.readAsDataURL(file);
    }

    downloadFile() {
        let link = document.createElement("a");
        link.download = "sample.xlsx";
        link.href = "assets/sample.xlsx";
        link.click();
    }

    onProjectPageChange(event, type) {
        if (type == "client") {
            this.currentPage = event;
        } else {
            this.currentEformResponsePage = event;
            this.viewEformsResponses(this.clientAkcessId)
        }
        // this.getclientList(this.eFormResposeFilterBy);
    }

    changePage(value, type) {
        if (type == "client") {
            this.pageSize = parseInt(value);
            this.currentPage = 1;
        } else {
            this.pageEformResponseSize = parseInt(value);
            this.currentEformResponsePage = 1;
            this.viewEformsResponses(this.clientAkcessId);
        }
        // this.getclientList(this.eFormResposeFilterBy);
    }

    get politicallyExpDetails() {
        return this.adduserform.get("politicallyExp") as FormControl;
    }

    get otherIncomeBusinessDetails() {
        return this.adduserform.get("otherIncomeBusiness") as FormControl;
    }

    fetchDataFromDoc(data) {
        let object = {
            id: this.responseId,
            path: data.path,
            facematch: this.facematch,
            faceMatchPic: this.faceMatchPic,
            docuementType: data.docuementType,
        };

        this.spinner.show();

        this.calldata.updateFieldsFromDocument(object).subscribe(
            (res: any) => {
                if (res.success) {
                    if (res.facematch) {
                        this.toast.success("Selfie Matched with document");
                    }

                    this.toast.success(res.message);
                    $("#viewform").modal("hide");
                    this.spinner.hide();
                } else {
                    this.toast.error(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    openMobilePdf() {
        let file_path = this.form_file.path;
        let domain = "https://" + this.form_domain + "/api/" + file_path;
        window.open(domain, "_blank");
    }

    parseMoreData(value) {
        try {
            return JSON.parse(value);
        } catch (error) {
            return [];
        }
    }

    clientCreditScoreUpdate() {
        this.spinner.show();
        let object = {
            userId: this.clientData._id,
            blackListStatus: this.blackListStatus == "yes" ? true : false,
            score: this.score,
            creditScore: this.creditScore,
        };

        this.calldata.updateClientCreditStatus(object).subscribe(
            (res: any) => {
                this.toast.success("Credit Score/Blacklist status updated");
                $("#creditScore").modal("hide");
                $("#viewuser").modal("hide");
                // this.getclientList(this.eFormResposeFilterBy);
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    deleteRow() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));

        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data: any = {
                    component: "client",
                    _id: id,
                };
                this.userlist.filter((e: any) => {
                    if (e._id == id) {
                        data.akcessId = e.akcessId
                    }
                })
                this.calldata.softDelete(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getclients("");
                            this.toast.success("Clients " + res.message);
                            this.spinner.hide();
                        } else {
                            this.toast.info("Clients " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        } else {
            this.toast.error("Select an client");
            this.spinner.hide();
        }
    }

    //other bank client
    otherBankClientData(value) {
        if (value.permission == "allow") {
            this.otherBankClientList = [];
            let payload = {
                bankName: value.name,
                url: value.url,
                akcessId: this.clientData.akcessId,
                email: this.clientData.email,
                firstName: this.clientData.firstName,
                lastName: this.clientData.lastName,
            };

            this.calldata.otherBankClient(payload).subscribe(
                (res: any) => {
                    if (res.success) {
                        this.otherBankClientList = res.data;
                        this.showTabel = "allow";
                    } else {
                        this.toast.warning(res.message);
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        } else {
            this.showTabel = "deny";
        }
    }

    viewRecycle() {
        this.router.navigate(["/panel/clients/deleted"]);
    }

    addClient() {
        this.router.navigate(["/panel/clients/add"]);
    }

    addEditClient(value: any, empData: any) {
        if (value == "add") {
            this.router.navigate(["/panel/clients/add"]);
        } else if (value == "view") {
            this.router.navigate(["panel/clients/edit"], {
                state: { userData: empData },
            });
        }
    }

    viewSentModel() {
        const initialState: any = {
            userType: "Client"
        };
        this.modalService.show(ViewInvitationListComponent, { initialState, class: 'modal-dialog-centered modal-exlg' })
    }

    formName: any = "";
    formId: any = "";
    eFormFields: any = "";
    eformList: any = [];
    selectedFormName: any = "";
    isEmailInvitation: boolean = false;
    isPhoneInvitation: boolean = false;
    emailList: any = [];
    phoneNumberList: any = [];
    public model = {
        editorData: ''
    };

    modifyFields(fields) {
        let deleteIndexes = fields.deletedIndex.map(index => parseInt(index))

        // delete the index at given position
        let gfields = this.eFormFields.filter((field, index) => {
            return !deleteIndexes.includes(index)
        })

        this.eFormFields = gfields
        // append section field data
        for (let field of Object.values(fields.sectionFieldData))
            this.eFormFields.push(field)
        return
    }

    //Sorting Sections and Normal Fields
    castFields() {
        let sectionFieldData = {};
        let deletedIndex = [];

        for (let section in this.eFormFields) {
            if (this.eFormFields[section].section_lable) {
                deletedIndex.push(section)
                // check if section-label already exists
                let status = sectionFieldData[this.eFormFields[section].section_lable] ? true : false;

                let sectionfields = {
                    "options": this.eFormFields[section].options,
                    "_id": this.eFormFields[section]._id,
                    "labelname": this.eFormFields[section].labelname,
                    "key": this.eFormFields[section].key,
                    "keytype": (this.eFormFields[section] && this.eFormFields[section].keytype) ? this.eFormFields[section].keytype : null,
                    "signature_required": this.eFormFields[section].signature_required,
                    "file_verified": this.eFormFields[section].file_verified,
                    "verification_grade": this.eFormFields[section].verification_grade
                }

                if (status) {
                    //sectionFieldData[fields[section].section_lable].sectionfields.push(sectionfields)

                    if (sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title]) {

                        sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title].push(sectionfields)
                    } else {
                        sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title] = [sectionfields]
                    }

                } else {
                    sectionFieldData[this.eFormFields[section].section_lable] = {
                        labelname: this.eFormFields[section].section_lable,
                        key: this.eFormFields[section].section_lable.replace(/\s/g, '').toLowerCase(),
                        //keytype: (this.fields[section] && this.fields[section].section_type) ? this.fields[section].section_type : this.section_keytype,
                        section_color: "#cdc0c0",
                        "section_id": "Yes",
                        sectionfields: {}
                    }

                    sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title] = [sectionfields]
                }

            }

        }
        // add options if section-fields exists
        for (let sect in sectionFieldData) {
            if (sectionFieldData[sect].sectionfields) {
                sectionFieldData[sect].options = []
                for (let field of Object.keys(sectionFieldData[sect].sectionfields))
                    sectionFieldData[sect].options.push({
                        checked: false,
                        key: (field.replace(/\s/, '')).toLowerCase(),
                        uid: Math.random().toString(16).substring(3),
                        lable: field
                    })
            }
        }
        return {
            sectionFieldData: sectionFieldData,
            deletedIndex: deletedIndex
        }
    }

    isTemplate: boolean = false;
    showTemplate(val) {
        if (val == 'show') {
            this.isTemplate = true;
        } else if (val == 'hide') {
            this.isTemplate = false;
        }
    }

    sendInvitationBy(e: any) {
        if (e.target.value == 'emaill') {
            this.isEmailInvitation = true;
            this.isPhoneInvitation = false;
        } else if (e.target.value == 'phoneNumber') {
            this.isEmailInvitation = false;
            this.isPhoneInvitation = true;
        }
        this.emailList = [];
        this.phoneNumberList = [];
        this.emailValue = "";
        this.phoneForm.controls.phone.setValue("");
        this.model.editorData = "";
    }

    emailValue = null;
    addEmail(e) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (e.target.value.match(validRegex)) {
            this.emailList.push(e.target.value);
            e.target.value = null;
        } else {
            this.toast.warning(e.target.value + " is not valid email address");
        }
        this.cdr.detectChanges();
    }

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required])
    });

    changePreferredCountries() {
        this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    }

    phoneNumber: any;
    addNumber() {
        if (this.phoneForm.valid) {
            let value = this.phoneForm.controls.phone.value
            let code = value.dialCode.split('+');
            let phone = value.e164Number.split(value.dialCode);
            let data = {
                countryCode: code[1],
                phone: phone[1]
            }
            this.phoneNumberList.push(data);
            this.phoneForm.reset();
        } else {
            this.toast.warning("Enter proper phone number");
        }
    }

    removeData(i, type) {
        if (type == 'email') {
            this.emailList.splice(i, 1);
        } else if (type == 'phone') {
            this.phoneNumberList.splice(i, 1)
        }
    }

    sendInvite() {
        this.spinner.show();
        let eformNameA = this.formName.replace(/\s+/g, "");
        let eformurl = environment.imgurl + `redirect/${eformNameA}/${this.formId}/${this.dbName}`
        let data: any = {
            link: eformurl,
            userType: "client"
        }

        if (this.isEmailInvitation) {
            data.emails = this.emailList;
        } else if (this.isPhoneInvitation) {
            data.phones = this.phoneNumberList;
        }

        if (this.formId == "") {
            this.toast.warning("Please create one eform first to send invitation.");
            this.spinner.hide();
            return
        }

        this.gs.sendInvitationToUser(data).subscribe((res: any) => {
            if (res.status) {
                this.spinner.hide();
                this.toast.success(res.message);
            } else {
                this.spinner.hide();
                this.toast.error(res.message);
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })

        this.resetform();

    }

    sendIndividualInvitation(value, type) {
        this.spinner.show();
        let eformNameA = this.formName.replace(/\s+/g, "");
        let eformurl = environment.imgurl + `redirect/${eformNameA}/${this.formId}/${this.dbName}`
        let data: any = {
            link: eformurl,
            userType: "client"
        }
        if (type == 'email') {
            let emailList = [];
            emailList.push(value.email)
            data.emails = emailList
        } else if (type == 'sms') {
            let smsList = [];
            let phoneData = {
                countryCode: value.countryCode,
                phone: value.phone
            }
            smsList.push(phoneData);
            data.phones = smsList
        }
        if (this.formName && this.formId) {
            this.gs.sendInvitationToUser(data).subscribe((res: any) => {
                if (res.status) {
                    this.spinner.hide();
                    this.toast.success(res.message);
                } else {
                    this.spinner.hide();
                    this.toast.error(res.message);
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            })
        } else {
            this.toast.warning("Please create one eform first to send invitation.");
            this.spinner.hide();
            return
        }

    }

    editEform() {
        $('#sendInvitation').modal('hide');
        this.router.navigate(["/panel/addeform/" + this.formId + "/" + this.formName + ""], { state: { example: 'clients' } })
    }

    getDefaultInvitation() {
        this.spinner.show();
        let data = {
            isEmployee: true
        }
        this.calldata.getDefaultInvitation(data).subscribe((res: any) => {
            if (res.status) {
                this.spinner.hide();
                if (res.data.defaultEform) {
                    this.eFormFields = res.data.defaultEform.fields;
                }

                if (res.data.eformList) {
                    this.eformList = res.data.eformList;
                }

                this.modifyFields(this.castFields())
                this.formName = res.data.defaultEform.formName;
                this.formId = res.data.defaultEform._id;
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    eformurl: string;
    scaneformid: string;
    description: string;
    redirectURL: any;
    isChangeEform: any;
    num = 1;
    bsModalRef: BsModalRef;

    geteformData() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            search_formName: this.selectedFormName ? this.selectedFormName : "",
            currentPage: 1,
            limit: 1,
        };

        this.calldata.geteformdata(tmpdata).subscribe(
            (res: any) => {
                if (res.status) {
                    this.eFormFields = res.data.results[0].fields;
                    this.formName = res.data.results[0].formName;
                    this.formId = res.data.results[0]._id;
                    this.description = res.data.results[0].description;
                    this.isChangeEform = false;
                    this.num = 1;
                    // this.eformdata = [];
                    // this.eformdata = res.data.results;s
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    viewEform() {

        this.fields = [];
        this.scaneformid = this.formId;
        this.formname = this.formName;
        this.description = this.description;
        this.fields = this.eFormFields;

        this.fields = this.fields.reduce((acc, row) => {
            if (row.sectionfields) {
                const data = row.options.reduce((op, l) => {
                    const option = row.sectionfields[l.lable]
                        ? row.sectionfields[l.lable].reduce((fields, section) => {
                            fields.push({
                                ...section,
                                section_lable: row.labelname,
                                section_color: row.section_color,
                                section_title: l.lable,
                                section_type: row.keytype,
                            });
                            return fields;
                        }, [])
                        : [];
                    return op.concat(option);
                }, []);
                return acc.concat(data);
            } else {
                acc.push(row);
            }
            return acc;
        }, []);

        let eformName = this.formName.replace(/\s+/g, "");
        this.eformurl =
            `https://sme.akcess.app/eform/` +
            eformName +
            "/" +
            this.formId +
            "/" +
            this.dbName;
        this.redirectURL =
            `https://sme.akcess.app/api/redirect/` +
            eformName +
            "/" +
            this.formId +
            "/" +
            this.dbName;


        const initialState: any = {
            eFormFields: this.fields,
            eFormId: this.formId,
            formName: this.formName,
            eFormDescription: this.description,
            eFormUrl: this.eformurl,
            eFormRedirectURL: this.redirectURL,
            componentType: "clients"
        };
        // $('#sendInvitation').modal('hide');
        this.bsModalRef = this.modalService.show(ViewEformModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg",
        });
    }

    changeDefaultForm() {
        if (this.num % 2 == 0) {
            this.isChangeEform = false;
            this.num++;
        } else {
            this.isChangeEform = true;
            this.num++;
        }
    }

}
