import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "../../../../_services/dataservices.service";
import { RefreshContentService } from "../../../../_services/refresh-content.service";
import { environment } from "../../../../../environments/environment";

@Component({
    selector: "app-property-deleted",
    templateUrl: "./deleted.component.html",
    styleUrls: ["./deleted.component.css"],
})
export class DeletedPropertyComponent implements OnInit {
    propertyDeletedlist: any = [];
    //Pagination
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;
    isMasterSel: Boolean;
    checkedPropertyList: any;
    storage_url = environment.url + "storage/";
    constructor(
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        public refreshContentService: RefreshContentService
    ) {
        this.isMasterSel = false;
    }

    ngOnInit(): void {
        this.storage_url = this.calldata.getStorageUrl();
        this.getDeletedPropertyList();
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/property/${file}`;
    }

    getDeletedPropertyList() {
        this.spinner.show();
        this.calldata.getDeletedList("property", "").subscribe(
            (res: any) => {
                if (res.status) {
                    this.propertyDeletedlist = res.data.reverse();
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    //Pagination
    onProjectPageChange(event) {
        this.currentPage = event;
    }

    changePage(value) {
        this.pageSize = parseInt(value);
    }

    //Delete Permenatly
    deletePermantly() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));

        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data = {
                    component: "property",
                    _id: id,
                };

                this.calldata.forceDeleteData(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.isMasterSel = false;
                            this.getDeletedPropertyList();
                            this.toast.success("Property " + res.message);
                        } else {
                            this.toast.info("Property " + res.message);
                            this.spinner.hide();
                        }
                    },
                    (err) => {
                        this.spinner.hide();
                        this.toast.error(
                            "Oops, something went wrong. Please try again."
                        );
                    }
                );
            }
        } else {
            this.toast.error("Select a property");
            this.spinner.hide();
        }
    }

    checkUncheckAll() {
        for (var i = 0; i < this.propertyDeletedlist.length; i++) {
            this.propertyDeletedlist[i].isSelected = this.isMasterSel;
        }
    }

    isAllSelected() {
        this.isMasterSel = this.propertyDeletedlist.every(function (item: any) {
            return item.isSelected == true;
        });
    }

    getCheckedItemList() {
        this.checkedPropertyList = [];
        for (var i = 0; i < this.propertyDeletedlist.length; i++) {
            if (this.propertyDeletedlist[i].isSelected)
                this.checkedPropertyList.push(this.propertyDeletedlist[i]._id);
        }

        return this.checkedPropertyList;
    }

    //Restore Deleted
    restoreDeleted() {
        this.spinner.show();
        let restoreIDs = this.getCheckedItemList();

        if (restoreIDs.length > 0) {
            for (let id of restoreIDs) {
                const data = {
                    component: "property",
                    _id: id,
                };

                this.calldata.restoreDeletedData(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getDeletedPropertyList();
                            this.toast.success("Property " + res.message);
                        } else {
                            this.toast.info("Property " + res.message);
                            this.spinner.hide();
                        }
                    },
                    (err) => {
                        this.spinner.hide();
                        this.toast.error(
                            "Oops, something went wrong. Please try again."
                        );
                    }
                );
            }
        } else {
            this.toast.error("Select a property");
            this.spinner.hide();
        }
    }
}
