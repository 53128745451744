import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CommonService } from "../../../../_services/common.service";

@Component({
    selector: "app-inquiry-view",
    templateUrl: "./inquiry-view.component.html",
    styleUrls: ["./inquiry-view.component.css"],
})
export class ViewPropertyInquiryComponent implements OnInit {
    inquiryId: string;
    inquiry: any = {};
    storage_url = environment.url + "storage/";
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        public commonService: CommonService
    ) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.inquiryId = params.inquiryId;
        });
    }

    ngOnInit(): void {
        this.storage_url = this.ds.getStorageUrl();
        if (this.inquiryId) {
            this.spinner.show();
            this.ds.getPropertyInquirySingle(this.inquiryId).subscribe(
                (response: any) => {
                    if (response.status) {
                        this.inquiry = response.data;
                    } else {
                        this.toast.error(response.message);
                    }
                    this.spinner.hide();
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        }
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/property/${file}`;
    }

    printQRCode() {
        window.print();
    }

    printHTML() {
        var markup = document.documentElement;
        var toPrint = document.getElementById("printarea");
        var popupWin = window.open(
            "",
            "_blank",
            "width=" +
                screen.width +
                ",height=" +
                screen.height +
                ",location=no,left=200px"
        );
        popupWin.document.open();
        popupWin.document.write(
            '<html><title>::Print Preview::</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"><style>.enquiry-details h3 { margin-bottom: 30px; } .enquiry-details div { font-size: 15px; } .enquiry-details label { font-weight: 400; color: #6c6c6c; font-size: 15px; } @media print { .fix-sidebar .left-sidebar { display: none !important; visibility: hidden !important; } * { visibility: hidden; } .property-card { visibility: visible !important; } .property-card * { visibility: visible; } .noprint, .property-card .noprint { visibility: hidden !important; } }</style></head><body">'
        );
        popupWin.document.write(toPrint.innerHTML);
        popupWin.document.write("</body>");
        popupWin.document.write("</html>");
        popupWin.document.close();
    }
}
