<div class="companies-list-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Other Bank Settings</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Other Bank Settings</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <div class="input-group">
                            <input type="text"
                                   class="form-control"
                                   id="search"
                                   placeholder="Search" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-8 col-xl-9 text-right">
                        <button type="button" class="btn waves-effect waves-light btn-info add-btn" data-toggle="modal" data-target="#addBank"
                                (click)="operation='add';resetFields();" tooltip="Add Bank" placement="bottom" container="body">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
                <div class="action-tab">
                    <button type="button" class="mx-1 btn" (click)="deleteRow()">
                        <i class="fas fa-trash"></i>
                    </button>
                    <span class="vertical-divider">|</span>
                    <button type="button" class="mx-1 btn" (click)="viewRecycle()" tooltip="Recycle Bin" container="body">
                        <i class="fal fa-recycle"></i>
                    </button>
                </div>
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table table-hover table-sm" mdbTable #tableEl="mdbTable">
                        <thead>
                            <tr>
                                <th>Bank Name</th>
                                <th>Website url</th>
                                <th>Permission</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of bank_list; let i = index">
                                <td>{{data.name}}</td>
                                <td>{{data.url}}</td>
                                <td>
                                    <div *ngIf="data.permission=='allow'">
                                        <input type="checkbox" id="customSwitch{{i}}" style="opacity:0;"
                                               class="filled-in chk-col-light-blue position-absolute opacity-none" checked disabled>
                                        <label for="customSwitch{{i}}"></label>
                                    </div>
                                    <div *ngIf="data.permission=='deny'">
                                        <input type="checkbox" id="customSwitch{{i}}" style="opacity:0;"
                                               class="filled-in chk-col-light-blue position-absolute opacity-none" disabled>
                                        <label for="customSwitch{{i}}"></label>
                                    </div>
                                </td>
                                <td>
                                    <button type="button" data-toggle="modal" data-target="#addBank"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="openEditModal(data)">
                                        Edit
                                    </button>
                                    <button type="button" data-toggle="modal" data-target="#addBank"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-danger"
                                            (click)="delete(data)">
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Bank modal -->
<div class="modal" id="addBank" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalCenterTitle" *ngIf="operation=='add'">Add Bank</h4>
                <h4 class="modal-title" id="exampleModalCenterTitle" *ngIf="operation=='edit'">Edit Bank</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="font-500" for="bankname">Bank Name</label>
                    <input type="text" class="form-control" [(ngModel)]="bank_name" id="bankname" placeholder="Bank Name">
                </div>
                <div class="form-group">
                    <label class="font-500" for="url">Bank URL</label>
                    <input type="text" class="form-control" [(ngModel)]="bank_url" (input)="onSearchChange($event.target.value)" id="url" placeholder="Bank URL">
                    <div class="invalid-feedback" *ngIf="!url_check">
                        Please enter a valid URL
                    </div>
                </div>
                <div>
                    <label for="permission">Permission</label>
                    <select class="form-control custom-select" id="permission" [(ngModel)]="bank_permission">
                        <option value="" selected>Choose...</option>
                        <option value="allow">Allow</option>
                        <option value="deny">Deny</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" [disabled]="!url_check" *ngIf="operation=='add'" class="btn waves-effect waves-light btn-info" (click)="addBank()">Save Changes</button>
                <button type="button" [disabled]="!url_check" *ngIf="operation=='edit'" class="btn waves-effect waves-light btn-info" (click)="updateBank()">Update Changes</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
