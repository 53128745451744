import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ToastrService } from "ngx-toastr";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";
import { environment } from "src/environments/environment";
import { ConfirmDialogService } from "../../confirm-dialog/confirm-dialog.service";
import { SendAssetModalComponent } from "./send-asset-modal/send-asset-modal.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ViewSentAssetModalComponent } from "./view-sent-asset-modal/view-sent-asset-modal.component";
import { Router } from "@angular/router";

@Component({
    selector: "app-assets-management",
    templateUrl: "./assets-management.component.html",
    styleUrls: ["./assets-management.component.css"],
})
export class AssetsManagementComponent implements OnInit {
    searchName: string = "";
    portalUser;
    assetslist: any = [];
    allAssets = [];

    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;

    isMasterSel: Boolean;
    checkedAssetsList: any;
    storage_url: any = environment.url + "storage/";
    bsModalRef: BsModalRef;
    userDatamodel: string;
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private refreshContentService: RefreshContentService,
        public commonService: CommonService,
        private router: Router,
        private confirmDialogService: ConfirmDialogService,
        public modalService: BsModalService
    ) {}

    ngOnInit(): void {
        this.storage_url = this.ds.getStorageUrl();
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                this.getAssets();
            } else {
                this.getAssets();
            }
        });
    }

    getAssets() {
        this.spinner.show();
        const params = {
            search: this.searchName,
            page: this.currentPage,
            limit: this.pageSize,
        };

        this.ds.getAssets(params).subscribe(
            (res: any) => {
                if (res.status) {
                    this.assetslist = res.data.list;
                    this.totalCount = res.data.total;
                    this.toast.success(res.message);
                    this.spinner.hide();
                } else {
                    this.toast.error(res.message);
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/asset/${file}`;
    }

    onProjectPageChange(event) {
        this.currentPage = event;
        this.getAssets();
    }

    changePage(value) {
        this.currentPage = 1;
        this.pageSize = parseInt(value);
        this.getAssets();
    }

    viewRecycle() {
        this.router.navigate(["/panel/assets/deleted"]);
    }

    searchAssets(type: string, value: string) {
        this.searchName = value;
        this.getAssets();
    }

    checkUncheckAll() {
        for (var i = 0; i < this.assetslist.length; i++) {
            this.assetslist[i].isSelected = this.isMasterSel;
        }
    }

    isAllSelected() {
        this.isMasterSel = this.assetslist.every(function (item: any) {
            return item.isSelected == true;
        });
    }

    getCheckedItemList() {
        this.checkedAssetsList = [];
        for (var i = 0; i < this.assetslist.length; i++) {
            if (this.assetslist[i].isSelected)
                this.checkedAssetsList.push(this.assetslist[i]._id);
        }

        return this.checkedAssetsList;
    }

    deleteSingleRows(id) {
        let me = this;
        me.confirmDialogService.confirmThis(
            "Are you sure to delete?",
            function () {
                me.spinner.show();
                const data = {
                    component: "assets",
                    _id: id,
                };
                me.ds.softDelete(data).subscribe(
                    (res: any) => {
                        me.spinner.hide();
                        if (res.status) {
                            me.assetslist = me.assetslist.filter((object) => {
                                return object._id !== id;
                            });
                            me.spinner.hide();
                            me.toast.success("Asset " + res.message);
                        } else {
                            me.toast.info("Asset " + res.message);
                        }
                    },
                    (error) => {
                        me.toast.error("Asset not deleted");
                        me.spinner.hide();
                    }
                );
            },
            function () {}
        );
    }

    deleteRows() {
        let me = this;
        me.confirmDialogService.confirmThis(
            "Are you sure to delete?",
            function () {
                me.spinner.show();
                let deleteIDs = me.getCheckedItemList();

                if (deleteIDs.length > 0) {
                    for (let id of deleteIDs) {
                        const data = {
                            component: "assets",
                            _id: id,
                        };
                        me.ds.softDelete(data).subscribe(
                            (res: any) => {
                                if (res.status) {
                                    me.assetslist = me.assetslist.filter(
                                        (object) => {
                                            return !object.isSelected;
                                        }
                                    );
                                    me.spinner.hide();
                                    me.toast.success("Asset " + res.message);
                                } else {
                                    me.toast.info("Asset " + res.message);
                                }
                            },
                            (error) => {
                                me.toast.error("Assets not deleted");
                                me.spinner.hide();
                            }
                        );
                    }
                } else {
                    me.toast.error("Select Assets");
                    me.spinner.hide();
                }
            },
            function () {}
        );
    }

    openShareAssetModal(data) {
        this.userDatamodel = "";
        let assetData = JSON.stringify(data);
        const initialState: any = {
            tmpasset: assetData,
        };

        this.bsModalRef = this.modalService.show(SendAssetModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered",
        });
    }

    viewSentAssetsModal(dataID, type, name) {
        const initialState: any = {
            dataId: dataID,
            sentType: type,
            assetName: name,
        };

        this.bsModalRef = this.modalService.show(ViewSentAssetModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg",
        });
    }
}
