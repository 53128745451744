import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { __spreadArrays } from "tslib";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/_services/common.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { EformresponseViewComponent } from "./eformresponse-view/eformresponse-view.component";
import { EformresponseDownloadComponent } from "./eformresponse-download/eformresponse-download.component";
import { Subscription } from "rxjs";
declare var $: any;

@Component({
    selector: "app-eformresponse",
    templateUrl: "./eformsresponse.component.html",
    styleUrls: ["./eformsresponse.component.css"],
})
export class EformsresponseComponent implements OnInit {
    excel_data: any[] = [];
    bsModalRef: BsModalRef;

    eformresponse = [];
    AllEformsResponse = [];
    fields = [];
    formname: any;
    form_file: any;
    form_domain: any;
    imageUrl: string = environment.imgurl;
    imageUrlsForPDF = [];
    portalUser;
    portaldata;
    pdfcolums = [{}];
    xls_fileName = "ExcelSheet.xlsx";
    pdf_fileName = "PDF.pdf";
    eformId: any;
    eformResponseEformId: any;
    device_token: string;
    isSubmitted = false;
    eformStatus: string = "";
    //gradedata = [];
    eFormverifyCheck: any = [];
    search_formName: any = "";

    verifiedItems: Array<String> = [];
    //fieldVerifierData: any;
    userAkcessID: any;
    //graderLoader: boolean = false;

    //eformDataWithForExpiryDate: any
    //expiryDateIndex: any
    //addExpiryDate: any;

    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;

    responseId: any;
    pullDataStatus: any = "";
    facematch: any;
    faceMatchPic: any;
    faceMatchPicname: any;
    isFacialMatched: any = null;
    mobile_local_id: any;

    // Status: any = [
    //   "Pending",
    //   "Accept",
    //   "Update Client Field",
    //   "Verify and Accept",
    //   "Return",
    //   "Reject",
    // ];
    StatusDescription: any = {
        alive: "Alive",
        pending: "Pending",
        createclient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        createClient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        Accept: " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
        accept: " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
        "update Client Field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        "update client field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        "verify and Accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        "verify and accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        Return: "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
        return: "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
        Reject: "When you apply 'reject' action, user will receive a notification that you rejected his response",
        reject: "When you apply 'reject' action, user will receive a notification that you rejected his response",
    };

    sortOrders: any = [
        {
            label: "Status",
            value: "status",
        },
        {
            label: "Date",
            value: "date",
        },
    ];
    selectedSortOrder: string = "Sort by";
    orderByFilter: any = "nto";
    orderByStatus: any = null;
    //minDate: Date;
    //maxDate: Date;

    refreshTableSubscription: Subscription;

    // google oauth
    client_id: "825330757877-t7gqka9mt8cff0lnr0duadb08vj8lo1j.apps.googleusercontent.com"; //client id for google sheet integration
    redirect_uri: "http://localhost:4200"; //redirect url for redirecting user after complete google login
    access_type: "offline"; //this is token access type
    scope: "https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fspreadsheets"; // scopes for google login
    response_type: "code"; // response type after login

    filterOptions: any = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        private akcessService: GeneralServiceService,
        public commonService: CommonService,
        private refreshContentService: RefreshContentService,
        public modalService: BsModalService
    ) {}

    ngOnInit() {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        this.eformId = this.route.snapshot.paramMap.get("eformId");
        //this.minDate = new Date();
        this.refreshContentService.notifyToAll.subscribe(
            (res) => {
                if (res && res.hasOwnProperty("option")) {
                    this.geteformresponse(null);
                } else {
                    this.geteformresponse(null);
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );

        if (this.refreshContentService.subsVar == undefined) {
            this.refreshContentService.subsVar =
                this.refreshContentService.refreshTableFunction.subscribe(
                    () => {
                        this.geteformresponse(null);
                    }
                );
        }

        this.getFilterOptions();
    }

    //setVerifiedItem(evt, i): void {
    //    let id = this.fields[i]._id;
    //    if (evt.target.checked) {
    //        this.eFormverifyCheck.push(id);
    //        this.verifiedItems.push(id);
    //    } else {
    //        this.eFormverifyCheck.splice(this.verifiedItems.indexOf(id), 1);
    //        this.verifiedItems.splice(this.verifiedItems.indexOf(id), 1);
    //    }
    //}

    //openExpiryDate(index) {
    //    this.expiryDateIndex = null
    //    this.expiryDateIndex = index
    //    this.eformDataWithForExpiryDate = {}
    //    this.eformDataWithForExpiryDate = this.fields[index];
    //}

    //pushExpiryDateInFiedls() {
    //    this.fields[this.expiryDateIndex].expiryDate = moment(this.addExpiryDate).format()
    //    this.addExpiryDate = ""
    //    $("#expiryDateModal").modal("hide")
    //}

    resetVerifiedItems(): void {
        this.verifiedItems = [];
        this.eFormverifyCheck = [];
    }

    geteformresponse(status: string) {
        this.eformresponse = [];
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: "",
            usertype: this.portalUser.usertype,
            orderBy: this.orderByFilter,
            currentPage: this.currentPage,
            limit: this.pageSize,
        };
        if (this.eformId != null) {
            tmpdata["eformId"] = this.eformId;
        }

        if (status && status.length) {
            tmpdata["status"] = status;
        }
        if (this.search_formName && this.search_formName.length)
            tmpdata["search_formName"] = this.search_formName;
        tmpdata["domainName"] =
            this.commonService.selectedCompanyDeatils.domain;
        // alert(JSON.stringify(tmpdata))
        this.calldata.fetchresponse(tmpdata).subscribe(
            (res: any) => {
                if (res.status) {
                    this.eformresponse = res.data.data;
                    this.totalCount = res.data.totalCount;
                    this.spinner.hide();
                } else {
                    this.toast.info(res["msg"]);
                    this.spinner.hide();
                }
            },
            (error) => {
                //this.toast.error("something went wrong");
                this.toast.error(error);
                this.spinner.hide();
            }
        );
    }

    orderBy(value: string) {
        this.orderByFilter = value;
        this.geteformresponse(this.orderByStatus);
    }

    ChangeSortOrder(newSortOrder: string) {
        this.selectedSortOrder = newSortOrder;
    }

    searchEformResponse(type: string, value: string) {
        if (type == "search") {
            if (value !== null) {
                this.currentPage = 1;
                this.search_formName = value;
                this.geteformresponse(null);
            }
        } else {
            this.orderByStatus = value;
            this.geteformresponse(value);
        }
    }

    //async view(val, fullname, _id, akcessId) {
    //    this.fields = [];
    //    try {
    //        this.eformresponse[val].fields.map((element, index) => {
    //            let options = JSON.parse(element.options[0])
    //            let sec = (element.sectionfields) ? element.sectionfields : "{}"
    //            this.eformresponse[val].fields[index]['optionsData'] = options
    //            options.forEach((option, i) => {
    //                if (JSON.parse(sec)[option.lable] && JSON.parse(sec).hasOwnProperty(option.lable)) {
    //                    this.eformresponse[val].fields[index]['optionsData'][i]['moreFields'] = JSON.parse(sec)[option.lable]
    //                }
    //            });
    //        })
    //    } catch (error) {
    //    }

    //    this.responseId = ''
    //    this.responseId = _id
    //    var fullname = fullname;
    //    this.userAkcessID = "";
    //    this.userAkcessID = akcessId;

    //    var a = "";
    //    if (this.eformresponse[val].facialMatch == true) {
    //        this.isFacialMatched = "true";
    //    } else if (this.eformresponse[val].facialMatch == false) {
    //        this.isFacialMatched = "false";
    //    } else {
    //        this.isFacialMatched = null;
    //    }
    //    this.form_file = this.eformresponse[val].eformasfile;
    //    this.form_domain = this.eformresponse[val].name;
    //    this.formname = this.eformresponse[val].formName;
    //    this.facematch = (this.eformresponse[val].facematch) ? this.eformresponse[val].facematch : 'no'
    //    this.faceMatchPic = (this.eformresponse[val].faceMatchPic && this.eformresponse[val].faceMatchPic.path) ? this.eformresponse[val].faceMatchPic.path : ''
    //    this.faceMatchPicname = (this.eformresponse[val].faceMatchPic && this.eformresponse[val].faceMatchPic.path) ? this.eformresponse[val].faceMatchPic.fieldname : ''

    //    let data = this.eformresponse[val].fields
    //    await data.forEach(async (element) => {
    //        if (element.isverified) {
    //            this.eFormverifyCheck.push(element._id);
    //        }
    //    });

    //    for (var i = 0; i < data.length; i++) {
    //        a = data[i].key
    //        data[i].val = a;

    //        data["fullname"] = fullname;
    //        if (data[i].keytype == "file" || data[i].keytype == "File") {

    //            data[i].path = (data[i] && data[i].file && data[i].file.path) ? data[i].file.path : null;
    //            this.imageUrlsForPDF.push(
    //                this.imageUrl + data[i].path
    //            );
    //        }

    //        if (data[i].isverified) {
    //            this.verifiedItems.push(data[i]._id);
    //        } else {
    //        }
    //    }

    //    data["eFormResponseId"] = _id;
    //    data["eformId"] = this.eformresponse[val].eformId;
    //    this.eformResponseEformId = this.eformresponse[val].eformId;
    //    this.eformStatus = this.eformresponse[val].status;
    //    this.device_token = this.eformresponse[val].device_token;
    //    this.mobile_local_id = this.eformresponse[val].mobile_local_id;
    //    data["akcessId"] = akcessId;
    //    data["email"] = (this.eformresponse[val] && this.eformresponse[val].email) ? this.eformresponse[val].email : '';
    //    data["mobile"] = (this.eformresponse[val] && this.eformresponse[val].mobile) ? this.eformresponse[val].mobile : '';

    //    this.fields = data;
    //}

    approveresponse(act, val) {
        if (confirm("Are you sure you want to do this action?")) {
            this.spinner.show();
            const data = { _id: val, act: act };
            this.calldata.approveresponse(data).subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        this.geteformresponse(null);
                        this.toast.success(data["message"]);
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        }
    }
    exportexcel(formname, fullname): void {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.xls_fileName =
            formname + "_" + formattedDate + "_" + fullname + ".xlsx";
        /* table id is passed over here */
        let element = document.getElementById("xls_formfields");
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        XLSX.writeFile(wb, this.xls_fileName);
    }

    // pdf merging

    async getPdfBytes(eform, arrayPdfurls, name) {
        const basePdfDoc = await PDFDocument.load(eform);
        if (arrayPdfurls.length > 0) {
            let requests = arrayPdfurls.map((url) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        let buffArrayTemp = await fetch(url).then((res) =>
                            res.arrayBuffer()
                        );
                        const loaddedPdf = await PDFDocument.load(
                            buffArrayTemp
                        );
                        const copiedPages = await basePdfDoc.copyPages(
                            loaddedPdf,
                            loaddedPdf.getPageIndices()
                        );
                        copiedPages.forEach((page) => basePdfDoc.addPage(page));
                        let pdfBytes = await basePdfDoc.save();
                        resolve(pdfBytes);
                    } catch (e) {
                        reject(e);
                    }
                });
            });

            Promise.all(requests).then(
                async (result) => {
                    var binaryData = [];
                    binaryData.push(result[result.length - 1]);

                    window.open(
                        window.URL.createObjectURL(
                            new Blob(binaryData, { type: "application/pdf" })
                        ),
                        "pdf",
                        "toolbar=no,location=no,directories=no,status=no, ar=no,scrollbar=no,resizable=no,copyhistory=yes"
                    );
                },
                (e) => {
                    //alert("no Files found");
                    this.toast.error("No Files found");
                }
            );
        } else {
            var binaryData = [];

            let pdfBytes = await basePdfDoc.save();
            binaryData.push(pdfBytes);

            window.open(
                window.URL.createObjectURL(
                    new Blob(binaryData, { type: "application/pdf" })
                ),
                "pdf",
                "toolbar=no,location=no,directories=no,status=no, ar=no,scrollbar=no,resizable=no,copyhistory=yes"
            );
        }
    }

    // PDF merging from urls end
    async generatePdf(formname, fullname) {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.pdf_fileName =
            formname + "_" + formattedDate + "_" + fullname + ".pdf";
        const documentDefinition = await this.getDocumentDefinition();
        // pdfMake.createPdf(documentDefinition).download(this.pdf_fileName);
        const pdfDocGenerator = await pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBuffer(async (buffer) => {
            await this.getPdfBytes(
                buffer,
                this.imageUrlsForPDF,
                this.pdf_fileName
            );
        });
    }

    getDocumentDefinition() {
        return new Promise(async (resolve, reject) => {
            var html = htmlToPdfmake(
                document.getElementById("pdf_formfields").innerHTML
            );
            resolve({
                content: [html],
                styles: {
                    "html-strong": {
                        background: "",
                    },
                },
            });
        });
    }
    verifyField() {
        return;
    }

    //onSubmit() {
    //    this.spinner.show();
    //    let vdata = [];

    //    this.fields.forEach((field) => {
    //        var isPresent = this.verifiedItems.some(function (el) {
    //            return el === field._id;
    //        });
    //        if (isPresent) {
    //            vdata.push(field);
    //        }
    //    });
    //    if (!this.eformStatus) {
    //        this.toast.error("Please select status");
    //        return false;
    //    } else {
    //        this.portaldata = this.commonService.portalDetails.portalDetails.portalData;
    //        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    //        let approved;

    //        if (this.eformStatus == "verify and accept") {
    //            approved = true;
    //        } else {
    //            approved = false;
    //        }

    //        let reqData = {
    //            eFormResponseId: this.fields["eFormResponseId"],
    //            fields_form: this.verifiedItems,
    //            eformsFields: vdata,
    //            eformStatus: this.eformStatus,
    //            akcessId: this.portalUser.akcessId,
    //            formName: this.formname,
    //            status: "alive",
    //            date: new Date(),
    //            approved: approved,
    //            eformId: this.eformResponseEformId,
    //            akcessVerification: true,
    //            akcessVerificationStatus: true,
    //            akcessVerificationStep: { type: "ready" },
    //            device_token: this.device_token,
    //            portalTitle: this.commonService.portalDetails.portalDetails.portalTitle,
    //            databaseURL: this.portaldata.setting.databaseURL,
    //        };
    //        this.calldata.verifyfield(reqData).subscribe(
    //            (data: any) => {
    //                if (data.status == 1) {
    //                    if (reqData.eformStatus == "update client field" || reqData.eformStatus == "createclient" || reqData.eformStatus == "Update Client Field" || reqData.eformStatus == "createClient") {
    //                        this.updateClientForm(this.fields);
    //                    } else if (reqData.eformStatus == "update employee field") {
    //                        this.updateEmployeeForm(this.fields);
    //                    } else {

    //                    }

    //                    let fieldsToVerify = []
    //                    let expiryDateArray = []

    //                    if (reqData.eformsFields.length > 0) {
    //                        reqData.eformsFields.forEach((element) => {
    //                            if (element.keytype == "file") {
    //                                this.spinner.show()
    //                                let object = {
    //                                    userAkcessId: this.userAkcessID,
    //                                    documentId: element.value,
    //                                    expiryDate: element.expiryDate
    //                                };
    //                                this.calldata.docVerifyField(object).subscribe((res: any) => {
    //                                    if (res.status == 1) {
    //                                        this.toast.success(res.data.message)
    //                                        $("#viewform").modal("hide");
    //                                        this.geteformresponse(null);
    //                                    } else {
    //                                        this.toast.error(res.message)
    //                                        $("#viewform").modal("hide");
    //                                        this.geteformresponse(null);
    //                                    }
    //                                });
    //                            } else {
    //                                expiryDateArray.push(element.expiryDate)
    //                                fieldsToVerify.push(element.key)
    //                            }
    //                        });

    //                        if (fieldsToVerify.length > 0) {
    //                            let object = {
    //                                userAkcessId: this.userAkcessID,
    //                                profileField: fieldsToVerify,
    //                                expiryDate: expiryDateArray
    //                            };

    //                            this.spinner.show()

    //                            this.calldata.VerifyField(object).subscribe(async (res: any) => {
    //                                if (res.status == 1) {
    //                                    this.toast.info(data.message);
    //                                    this.toast.success(res.data.message)
    //                                    $("#viewform").modal("hide");
    //                                    this.geteformresponse(null);
    //                                    this.spinner.hide()
    //                                } else {
    //                                    this.toast.info(data.message);
    //                                    this.toast.error(res.message)
    //                                    $("#viewform").modal("hide");
    //                                    this.geteformresponse(null);
    //                                    this.spinner.hide()

    //                                }
    //                            });
    //                        }

    //                    } else {
    //                        this.toast.info(data.message);
    //                        $("#viewform").modal("hide");
    //                        this.geteformresponse(null);

    //                    }
    //                    this.spinner.hide()

    //                } else {
    //                    this.toast.info(data.message);
    //                    this.spinner.hide();
    //                }
    //            },
    //            (error) => {
    //                //this.toast.error("something went wrong");
    //                this.toast.error(error);
    //                this.spinner.hide();
    //            }
    //        );
    //    }
    //}

    //viewGradeVer(data) {
    //    this.graderLoader = true;
    //    this.fieldVerifierData = [];
    //    if (
    //        data.keytype == "string" ||
    //        data.keytype == "text" ||
    //        data.keytype == "list"
    //    ) {
    //        let object = {
    //            userAkcessId: this.userAkcessID,
    //            profileField: data.key
    //        };

    //        this.calldata.getFieldVerifierOnPortal(object).subscribe((res: any) => {
    //            if (res.status) {
    //                this.fieldVerifierData = res.data;
    //                this.graderLoader = false;
    //            } else {
    //                this.graderLoader = false;
    //            }
    //        });
    //    }

    //    if (data.keytype == "file") {
    //        let object = {
    //            userAkcessId: this.userAkcessID,
    //            documentId: data.value
    //        };

    //        this.calldata.getDocVerifierOnPortal(object).subscribe((res: any) => {
    //            if (res.status) {
    //                this.graderLoader = false;
    //                this.fieldVerifierData = res.data;
    //            } else {
    //                this.graderLoader = false;
    //            }
    //        });
    //    }

    //    this.gradedata["key"] = data.key;
    //}

    //validDateFormat(val) {
    //    let split = val.split('/')
    //    if (split.length == 3) {
    //        return new Date(`${split[1]}/${split[0]}/${split[2]}`)
    //    } else {
    //        return new Date(val)
    //    }
    //}

    //updateClientForm(payload) {

    //    let bodyData: any = {
    //        akcessId: payload["akcessId"],
    //    };

    //    payload.forEach((element) => {
    //        if (element.key == "occupation") bodyData.occupation = element.value;
    //        if (element.key == "education") bodyData.education = element.value;
    //        if (element.key == "university") bodyData.studentAtUniColl = element.value;
    //        if (element.key == "position") bodyData.rollPosition = element.value;
    //        if (element.key == "salary") bodyData.salary = element.value;
    //        if (element.key == "nationality") bodyData.nationality = element.value;
    //        if (element.key == "gender") bodyData.gender = element.value;
    //        if (element.key == "zipcode") bodyData.zipCode = element.value;
    //        if (element.key == "floor") bodyData.floor = element.value;
    //        if (element.key == "city") bodyData.city = element.value;
    //        if (element.key == "pooffice") bodyData.postOffice = element.value;
    //        if (element.key == "block") bodyData.block = element.value;
    //        if (element.key == "area") bodyData.area = element.value;
    //        if (element.key == "street") bodyData.street = element.value;
    //        if (element.key == "avenue") bodyData.aveneu = element.value;
    //        if (element.key == "faxnumber") bodyData.faxNo = element.value;
    //        if (element.key == "language") bodyData.language = element.value;
    //        if (element.key == "birthplace") bodyData.birthPlace = element.value;
    //        if (element.key == "passportnumber") bodyData.passportNumber = element.value;
    //        if (element.key == "passportexpirydate") bodyData.passportExpDate = this.validDateFormat(element.value)
    //        if (element.key == "civilidnumber") bodyData.civilIdNumber = element.value;
    //        if (element.key == "arabiclastname") bodyData.lastNameArabic = element.value;
    //        if (element.key == "arabicfirstname") bodyData.firstNameArabic = element.value;
    //        if (element.key == "civilidexpirydate") bodyData.civilIDExpDate = this.validDateFormat(element.value)
    //        if (element.key == "email") bodyData.email = element.value;
    //        if (element.key == "mobile") bodyData.mobile = element.value;
    //        if (element.key == "firstname") bodyData.firstName = element.value;
    //        if (element.key == "lastname") bodyData.lastName = element.value;
    //        if (element.key == "workphone") bodyData.officePhone = element.value;
    //        if (element.key == "address") bodyData.address = element.value;
    //        if (element.key == "workaddress") bodyData.workAddress = element.value;
    //        if (element.key == "employer") bodyData.employerName = element.value;
    //        if (element.key == "graduationdate") bodyData.graduationDate = this.validDateFormat(element.value)
    //        if (element.key == "maritalstatus") bodyData.maritalStatus = element.value;
    //        if (element.key == "dateofbirth") bodyData.birthDate = this.validDateFormat(element.value)
    //        if (element.key == "homephone") bodyData.phone = element.value;
    //        if (element.key == "cvdno") bodyData.CVDNo = element.value;
    //        if (element.key == "kdaccountbalance") bodyData.KDAccountBalance = element.value;
    //        if (element.key == "usdaccountbalance") bodyData.USDAccountBalance = element.value;
    //        if (element.key == "products") bodyData.clientProducts = element.value;
    //        if (element.key == "unit") bodyData.unit = element.value;
    //        if (element.key == "pobox") bodyData.poBox = element.value;
    //        if (element.key == "buildingplot") bodyData.buildingPlot = element.value;
    //        if (element.key == "recruitmentdate") bodyData.recruitmentDate = this.validDateFormat(element.value)
    //    });

    //    this.calldata
    //        .updateClientDataWithReverseOnBoarding(bodyData)
    //        .subscribe((data: any) => {
    //            if (data.status) {
    //                this.toast.success(data["message"]);
    //            } else {
    //                this.toast.error(data["message"]);
    //            }
    //        });
    //}

    //updateEmployeeForm(payload) {

    //    let bodyData: any = {
    //        akcessId: payload["akcessId"],
    //    };

    //    payload.forEach((element) => {
    //        if (element.key == "occupation") bodyData.occupation = element.value;
    //        if (element.key == "education") bodyData.education = element.value;
    //        if (element.key == "university") bodyData.studentAtUniColl = element.value;
    //        if (element.key == "position") bodyData.rollPosition = element.value;
    //        if (element.key == "salary") bodyData.salary = element.value;
    //        if (element.key == "nationality") bodyData.nationality = element.value;
    //        if (element.key == "gender") bodyData.gender = element.value;
    //        if (element.key == "zipcode") bodyData.zipCode = element.value;
    //        if (element.key == "floor") bodyData.floor = element.value;
    //        if (element.key == "city") bodyData.city = element.value;
    //        if (element.key == "pooffice") bodyData.postOffice = element.value;
    //        if (element.key == "block") bodyData.block = element.value;
    //        if (element.key == "area") bodyData.area = element.value;
    //        if (element.key == "street") bodyData.street = element.value;
    //        if (element.key == "avenue") bodyData.aveneu = element.value;
    //        if (element.key == "faxnumber") bodyData.faxNo = element.value;
    //        if (element.key == "language") bodyData.language = element.value;
    //        if (element.key == "birthplace") bodyData.birthPlace = element.value;
    //        if (element.key == "passportnumber") bodyData.passportNumber = element.value;
    //        if (element.key == "passportexpirydate") bodyData.passportExpDate = this.validDateFormat(element.value)
    //        if (element.key == "civilidnumber") bodyData.civilIdNumber = element.value;
    //        if (element.key == "arabiclastname") bodyData.lastNameArabic = element.value;
    //        if (element.key == "arabicfirstname") bodyData.firstNameArabic = element.value;
    //        if (element.key == "civilidexpirydate") bodyData.civilIDExpDate = this.validDateFormat(element.value)
    //        if (element.key == "email") bodyData.email = element.value;
    //        if (element.key == "mobile") bodyData.mobile = element.value;
    //        if (element.key == "firstname") bodyData.firstName = element.value;
    //        if (element.key == "lastname") bodyData.lastName = element.value;
    //        if (element.key == "workphone") bodyData.officePhone = element.value;
    //        if (element.key == "address") bodyData.address = element.value;
    //        if (element.key == "workaddress") bodyData.workAddress = element.value;
    //        if (element.key == "employer") bodyData.employerName = element.value;
    //        if (element.key == "graduationdate") bodyData.graduationDate = this.validDateFormat(element.value)
    //        if (element.key == "maritalstatus") bodyData.maritalStatus = element.value;
    //        if (element.key == "dateofbirth") bodyData.birthDate = this.validDateFormat(element.value)
    //        if (element.key == "homephone") bodyData.phone = element.value;
    //        if (element.key == "cvdno") bodyData.CVDNo = element.value;
    //        if (element.key == "kdaccountbalance") bodyData.KDAccountBalance = element.value;
    //        if (element.key == "usdaccountbalance") bodyData.USDAccountBalance = element.value;
    //        if (element.key == "products") bodyData.clientProducts = element.value;
    //        if (element.key == "unit") bodyData.unit = element.value;
    //        if (element.key == "pobox") bodyData.poBox = element.value;
    //        if (element.key == "buildingplot") bodyData.buildingPlot = element.value;
    //        if (element.key == "recruitmentdate") bodyData.recruitmentDate = this.validDateFormat(element.value)
    //    });

    //    this.calldata
    //        .updateEmployeeDataWithReverseOnBoarding(bodyData)
    //        .subscribe((data: any) => {
    //            if (data.status) {
    //                this.toast.success(data["message"]);
    //            } else {
    //                this.toast.error(data["message"]);
    //            }
    //        });
    //}

    onProjectPageChange(event) {
        this.currentPage = event;
        this.geteformresponse(null);
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        this.geteformresponse(null);
    }

    //fetchDataFromDoc(data) {
    //    let object = {
    //        id: this.responseId,
    //        path: data.path,
    //        facematch: this.facematch,
    //        faceMatchPic: this.faceMatchPic,
    //        docuementType: data.docuementType
    //    }

    //    this.spinner.show()

    //    this.calldata.updateFieldsFromDocument(object).subscribe((res: any) => {
    //        if (res.success) {
    //            if (res.facematch) {
    //                this.toast.success("Selfie Matched with document")
    //            }

    //            this.toast.success(res.message)
    //            this.geteformresponse(null);
    //            $('#viewform').modal('hide')
    //            this.spinner.hide()
    //        } else {
    //            this.toast.error(res.message)
    //            this.spinner.hide()
    //        }
    //    }, err => {
    //        this.spinner.hide()
    //    })
    //}

    DisplayData(first) {
        if (first) {
            return first;
        } else {
            return "User's account deleted.";
        }
    }

    //openMobilePdf() {
    //    let file_path = (this.form_file.path) ? this.form_file.path : 'storage/' + this.form_file.upload_name;
    //    let domain = "https://sme.akcess.app/api/" + file_path;
    //    window.open(domain, '_blank');
    //}

    //parseMoreData(value) {
    //    try { return JSON.parse(value) }
    //    catch (error) { return [] }
    //}

    //Delete Rows
    deleteRow() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));

        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data = {
                    component: "eformresponse",
                    _id: id,
                };
                this.calldata.softDelete(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.geteformresponse(null);
                            this.toast.success(
                                "eForm Responses " + res.message
                            );
                        } else {
                            this.toast.info("eForm Responses " + res.message);
                            this.spinner.hide();
                        }
                    },
                    (err) => {
                        this.spinner.hide();
                        this.toast.error(
                            "Oops, something went wrong. Please try again."
                        );
                    }
                );
            }
        } else {
            this.toast.error("Select an eForm Response");
            this.spinner.hide();
        }
    }

    viewRecycle() {
        this.router.navigate(["/panel/eformsresponse/deleted"]);
    }

    //Download Excel
    // downloadExcel() {
    //     let downloadExcelIds = [];
    //     document
    //         .querySelector("table tbody")
    //         .querySelectorAll('tr input[type="checkbox"]:checked')
    //         .forEach((item) => downloadExcelIds.push(item.getAttribute("id")));
    // }
    //Zip File
    zipDocument() {
        let zipDocements = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => zipDocements.push(item.getAttribute("id")));
    }

    somethingSelected: boolean = true;
    selectedList: any[] = [];

    update_excel_data(data: any, status): void {
        if (status == true) {
            this.selectedList.push(status);
        } else if (status == false) {
            if (this.selectedList.length > 0) {
                this.selectedList.splice(0, 1);
            }
        }
        if (this.selectedList.length === 0) {
            this.somethingSelected = true;
        } else {
            this.somethingSelected = false;
        }
        // add default haeder available in each rows
        let headers = [
            "eformId",
            "_id",
            "formName",
            "akcessId",
            "firstName",
            "lastName",
            "email",
            "phone",
            "updatedAt",
        ];

        // add other headers as per previous excel data available
        if (this.excel_data.length)
            headers = headers.concat(Object.keys(this.excel_data[0]));

        // add new header fields given in data
        data.fields.forEach((item) => headers.push(item.labelname));

        // remove duplicate headers
        headers = [...new Set(headers)];

        if (status) {
            // cast headers into object with null value
            let obj = headers.reduce((obj, item) => {
                obj[item] = null;
                return obj;
            }, {});

            // replace fields value into obj
            for (let field of data.fields) obj[field.labelname] = field.value;

            // replace other valus excluding fields
            for (let key in data)
                if (obj.hasOwnProperty(key)) obj[key] = data[key];
            this.excel_data.push(obj);

            return;
        }

        // find records by id and remove from excel-data
        for (let index in this.excel_data) {
            if (data._id === this.excel_data[parseInt(index)]._id)
                this.excel_data.splice(parseInt(index), 1);
        }
    }

    //colspan(): void {
    //    let size = document.getElementById('excel-table').querySelectorAll(`thead tr th`).length

    //    for (let i = size; i > 0; i--) {
    //        let selected_cell: any = null

    //        document.getElementById('excel-table').querySelectorAll(`tbody tr td:nth-child(${i})`).forEach(html => {
    //            if (html.innerHTML) {
    //                if (!selected_cell) {
    //                    selected_cell = html
    //                } else {
    //                    if (html.innerHTML == selected_cell.innerHTML) {
    //                        let rowspan = parseInt(selected_cell.getAttribute('rowspan') || 1)
    //                        selected_cell.setAttribute('rowspan', rowspan + 1)
    //                        html.remove()
    //                    } else {
    //                        selected_cell = html
    //                    }
    //                }
    //            }
    //        })
    //    }
    //}

    openViewEformModal(val, fullName, _id, akcessId) {
        const initialState: any = {
            val: val,
            fullname: fullName,
            _id: _id,
            akcessId: akcessId,
            eformresponse: this.eformresponse,
        };

        this.bsModalRef = this.modalService.show(EformresponseViewComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-xl",
        });

        this.bsModalRef.onHide.subscribe((res: any) => {
            this.geteformresponse(null);
        });
    }

    openExportToExcelModal() {
        const initialState: any = {
            excel_data: this.excel_data,
        };

        this.bsModalRef = this.modalService.show(
            EformresponseDownloadComponent,
            {
                initialState,
                animated: true,
                backdrop: "static",
                ignoreBackdropClick: true,
                class: "modal-dialog-centered modal-xl",
            }
        );
    }

    sendResponseToGoogleSheet() {
        if (this.excel_data.length == 0) {
            this.toast.warning(
                "Please check eform responses from checkbox",
                "Warning"
            );
            return;
        }
        let headers = [];
        if (!this.excel_data.length) return headers;

        this.excel_data.forEach((obj) => {
            headers = headers.concat(Object.keys(obj));
        });
        let headersValue = [...new Set(headers)];

        let finalHeadersValue = [];
        let finalEformResdata = [];

        headersValue.filter((e) => {
            let value = {
                userEnteredValue: {
                    stringValue: e,
                },
            };
            finalHeadersValue.push(value);
        });

        this.excel_data.filter((e) => {
            let value = {
                values: [],
            };
            headersValue.filter((e1) => {
                let demo = e1;
                let fVal = {
                    userEnteredValue: {
                        stringValue: e[demo],
                    },
                };
                value.values.push(fVal);
            });
            finalEformResdata.push(value);
        });

        let data: any = {
            properties: {
                title: "received-eforms",
            },
            sheets: [
                {
                    data: [
                        {
                            startColumn: 0,
                            startRow: 0,
                            rowData: [
                                {
                                    values: finalHeadersValue,
                                },
                                finalEformResdata,
                            ],
                        },
                    ],
                },
            ],
        };
        localStorage.removeItem("eformreciveddata");
        localStorage.setItem("eformreciveddata", JSON.stringify(data));
        window.open(
            "https://accounts.google.com/o/oauth2/v2/auth?access_type=" +
                this.access_type +
                "&scope=" +
                this.scope +
                "&response_type=" +
                this.response_type +
                "&client_id=" +
                this.client_id +
                "&redirect_uri=" +
                this.redirect_uri,
            "_self"
        );
    }

    getFilterOptions() {
        this.calldata.getFilterOptions("eform").subscribe(
            (res: any) => {
                if (res.status) {
                    this.filterOptions = res.data;
                } else {
                    this.toast.info(res["msg"]);
                }
            },
            (error) => {
                this.toast.error(error);
            }
        );
    }
}
