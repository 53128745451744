import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";
import { Property } from "src/app/models/property";
import { environment } from "../../../../environments/environment";
import { ConfirmDialogService } from "../../confirm-dialog/confirm-dialog.service";
@Component({
    selector: "app-property",
    templateUrl: "./property.component.html",
    styleUrls: ["./property.component.css"],
})
export class PropertyComponent implements OnInit {
    searchName: string = "";

    currentPage: number = 1;
    totalCount: number = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: number = 10;
    propertyList: Array<Property> = [];
    responseId: any;
    pullDataStatus: any = "";
    isMasterSel: Boolean;
    checkedPropertyList: any;
    storage_url = environment.url + "storage/";
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private gs: GeneralServiceService,
        private toast: ToastrService,
        private refreshContentService: RefreshContentService,
        public commonService: CommonService,
        private route: ActivatedRoute,
        private router: Router,
        private confirmDialogService: ConfirmDialogService
    ) {
        this.isMasterSel = false;
    }

    ngOnInit(): void {
        this.storage_url = this.ds.getStorageUrl();
        this.getProperties();
    }

    getProperties() {
        this.spinner.show();
        const params = {
            search: this.searchName,
            page: this.currentPage,
            limit: this.pageSize,
        };

        this.ds.getProperties(params).subscribe(
            (res: any) => {
                if (res.status) {
                    this.propertyList = res.data.list;
                    this.totalCount = res.data.total;
                    this.toast.success(res.message);
                    this.spinner.hide();
                } else {
                    this.toast.error(res.message);
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    viewRecycle() {
        this.router.navigate(["/panel/property/deleted"]);
    }

    onProjectPageChange(event) {
        this.currentPage = event;
        this.getProperties();
    }

    changePage(value) {}

    searchProperty(type: string, value: string) {
        if (type == "search") {
            if (value !== null) {
                this.currentPage = 1;
                this.searchName = value;
                this.getProperties();
            }
        } else {
            this.searchName = value;
            this.getProperties();
        }
    }

    checkUncheckAll() {
        for (var i = 0; i < this.propertyList.length; i++) {
            this.propertyList[i].isSelected = this.isMasterSel;
        }
    }

    isAllSelected() {
        this.isMasterSel = this.propertyList.every(function (item: any) {
            return item.isSelected == true;
        });
    }

    getCheckedItemList() {
        this.checkedPropertyList = [];
        for (var i = 0; i < this.propertyList.length; i++) {
            if (this.propertyList[i].isSelected)
                this.checkedPropertyList.push(this.propertyList[i]._id);
        }

        return this.checkedPropertyList;
    }

    deleteSingleRows(id) {
        let me = this;
        me.confirmDialogService.confirmThis(
            "Are you sure to delete?",
            function () {
                me.spinner.show();
                const data = {
                    component: "property",
                    _id: id,
                };
                me.ds.softDelete(data).subscribe(
                    (res: any) => {
                        me.spinner.hide();
                        if (res.status) {
                            me.propertyList = me.propertyList.filter(
                                (object) => {
                                    return object._id !== id;
                                }
                            );
                            me.spinner.hide();
                            me.toast.success("Property  " + res.message);
                        } else {
                            me.toast.info("Property " + res.message);
                        }
                    },
                    (err) => {
                        this.spinner.hide();
                        this.toast.error(
                            "Oops, something went wrong. Please try again."
                        );
                    }
                );
            },
            function () {}
        );
    }

    deleteRows() {
        let deleteIDs = this.getCheckedItemList();

        if (deleteIDs.length > 0) {
            let me = this;
            me.confirmDialogService.confirmThis(
                "Are you sure to delete?",
                function () {
                    me.spinner.show();
                    for (let id of deleteIDs) {
                        const data = {
                            component: "property",
                            _id: id,
                        };
                        me.ds.softDelete(data).subscribe(
                            (res: any) => {
                                if (res.status) {
                                    me.propertyList = me.propertyList.filter(
                                        (object) => {
                                            return !object.isSelected;
                                        }
                                    );

                                    me.toast.success("Property " + res.message);
                                } else {
                                    me.toast.info("Property " + res.message);
                                }
                            },
                            (err) => {
                                this.toast.error(
                                    "Oops, something went wrong. Please try again."
                                );
                            }
                        );
                    }
                    me.spinner.hide();
                },
                function () {}
            );
        } else {
            this.toast.error("Select a Property");
        }
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/property/${file}`;
    }
}
