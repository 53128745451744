import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GetDataFromIdComponent } from 'src/app/layout/models/get-data-from-id/get-data-from-id.component';
import { GenerateIdModalComponent } from '../generate-id-modal/generate-id-modal.component';
import { UploadDocumentComponent } from '../../documents/upload-document/upload-document.component';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {

  public modalRef: BsModalRef;

  adduserform: FormGroup;
  uploadDocForm: FormGroup;

  act: string = "Add";
  isAkcessId: boolean = false;

  isSubmitted = false;
  countryList: any = [];
  userData: any;
  isEdit: boolean = false;
  btnText: string = "Save";
  empCard: any = '';

  apiLink: any = environment.url + 'storage/';

  constructor(public fb: FormBuilder,
    public router: Router,
    public cdr: ChangeDetectorRef,
    public dataService: DataservicesService,
    private spinner: NgxSpinnerService,
    public generalService: GeneralServiceService,
    private toast: ToastrService,
    public http: HttpClient, public modalService: BsModalService) {
    this.createAddEmployeeForm();
    this.createUploadDocForm();
    if (window.location.href.includes('add')) {
      this.btnText = "Save";
      this.isEdit = false;
    } else if (window.location.href.includes('edit')) {
      this.isEdit = true;
      this.btnText = "Update";
      this.userData = this.router.getCurrentNavigation().extras.state.userData;
      this.uploadProfileImage = this.userData.profileimg ? this.userData.profileimg : "../../../../assets/new-images/users/default-profile.svg";
      this.docList = this.userData.document;
      if (this.userData.idcard_id) {
        this.getEmployeeIdCard(this.userData.idcard_id);
      }
    }
  }

  storage_url: any = environment.url + "storage/";
  
  ngOnInit(): void {
    this.storage_url = this.dataService.getStorageUrl();
    this.getCountryList();
  }

  createAddEmployeeForm() {
    this.adduserform = this.fb.group({
      _id: [null],
      searchakcessid: [null],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      //email:["",Validators.compose([Validators.required, Validators.email])],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", Validators.required],
      // phone: ["", [Validators.required, Validators.pattern("^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$")]],
      // phone: [""],
      akcessId: [null, [Validators.required, Validators.minLength(11)]],
      address: [""],
      // haveAkcessId:[""]
      // clientStatus:[null],
      // clientProducts:[null],
      // USDAccountBalance:[null],
      // KDAccountBalance:[null],
      DOB: [null],
      placeOfBirth: [null],
      gender: [""],
      nationality: [""],
      isAdmin: [false]
    });
  }

  createUploadDocForm() {
    this.uploadDocForm = this.fb.group({
      docName: ["", Validators.required],
      docType: ["", Validators.required],
      docExpiryDate: ["", Validators.required],
      docFile: ["", Validators.required]
    })
  }

  get formControls() {
    return this.adduserform.controls;
  }

  getCountryList() {
    this.http.get('/assets/country-list.json').subscribe((res: any) => {
      res.filter((e) => {
        this.countryList.push(e.label)
      })
    })
  }

  uploadProfileImage: any = "../../../../assets/new-images/users/default-profile.svg";

  fileBlobData: any;

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.fileBlobData = file;
    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.uploadProfileImage = 'data:image/png;base64,' + btoa(e.target.result);
  }

  addEmployee() {
    this.isSubmitted = true;
    if (this.adduserform.invalid) {
      return
    }
    this.spinner.show();
    let number: any = this.adduserform.controls.phone.value;

    let phoneNumber = number.e164Number.split(number.dialCode);
    let countryCode = number.dialCode.split("+");

    let data: any = {
      akcessId: this.adduserform.controls.akcessId.value,
      firstName: this.adduserform.controls.firstName.value,
      lastName: this.adduserform.controls.lastName.value,
      email: this.adduserform.controls.email.value,
      phone: phoneNumber[1],
      countryCode: countryCode[1],
      address: this.adduserform.controls.address.value,
      profileimg: this.uploadProfileImage.includes("data") ? this.uploadProfileImage : "",
      nationality: this.adduserform.controls.nationality.value,
      gender: this.adduserform.controls.gender.value,
      placeofbirth: this.adduserform.controls.placeOfBirth.value,
      dateofbirth: this.adduserform.controls.DOB.value,
      isAdmin: this.adduserform.controls.isAdmin.value
    }
    if (this.btnText.toLocaleLowerCase() == 'save') {
      this.dataService.addEmployee(data).subscribe((res: any) => {
        if (res.status) {
          this.router.navigate(['/panel/employees'])
          this.toast.success(`${res.message}`);
          this.spinner.hide();
        } else {
          this.toast.error(`${res.message}`);
          this.spinner.hide();
        }
      }, (err) => {
        this.toast.error("Oops, something went wrong. Please try again.");
        this.spinner.hide();
      })
    } else if (this.btnText.toLocaleLowerCase() == 'update') {
      data._id = this.userData._id;
      this.dataService.editEmployee(data).subscribe((res: any) => {
        if (res.status) {
          this.router.navigate(['/panel/employees']);
          this.toast.success(`${res.message}`);
          this.spinner.hide();
        } else {
          this.toast.error("something went wrong");
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      })
    }



  }

  resetform() {
    this.modalService.hide();
  }


  setUserData() {
    this.adduserform.controls.akcessId.setValue(this.userData.akcessId);
    // this.adduserform.controls.phone.setValue(phone);    
    this.adduserform.controls.firstName.setValue(this.userData.firstName);
    this.adduserform.controls.lastName.setValue(this.userData.lastName);
    this.adduserform.controls.email.setValue(this.userData.email);
    this.adduserform.controls.phone.setValue(this.userData.mobile);
    this.adduserform.controls.address.setValue(this.userData.address);
    this.adduserform.controls.DOB.setValue(this.userData.dateofbirth);
    this.adduserform.controls.placeOfBirth.setValue(this.userData.placeofbirth);
    this.adduserform.controls.gender.setValue(this.userData.gender);
    this.adduserform.controls.nationality.setValue(this.userData.nationality);
    this.uploadProfileImage = this.userData.profileimg ? this.userData.profileimg : "../../../../assets/new-images/users/default-profile.svg";
    this.adduserform.controls.isAdmin.setValue(this.userData.isAdmin);
    this.cdr.detectChanges();
  }

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }


  openModal() {
    this.modalRef = this.modalService.show(GetDataFromIdComponent, { class: 'modal-dialog-centered' });
    this.modalRef.content.myContent = 'Add Employee';
    this.modalRef.content.onClose.subscribe((res) => {
      this.adduserform.controls.akcessId.setValue(res.data.akcessId);
      this.adduserform.controls.email.setValue(res.data.email);
      this.adduserform.controls.firstName.setValue(res.data.firstName);
      this.adduserform.controls.lastName.setValue(res.data.lastName);
      this.cdr.detectChanges();
    })
  }


  createIdCard() {
    this.userData.profileimg = this.uploadProfileImage.length > 100 ? this.uploadProfileImage : "";
    this.userData.fileBlobData = this.fileBlobData;
    let initialState: any = this.userData;
    this.modalRef = this.modalService.show(GenerateIdModalComponent, { class: "modal-lg modal-dialog-centered", initialState });

    this.modalRef.content.event.subscribe(res => {
      this.empCard = res.data.data
    });
  }
  isUploadSubmit: boolean = false
  docList = [];

  uploadDocument() {
    // this.modalRef = this.modalService.show(UploadDocumentComponent, { class: 'modal-dialog-centered' });
    this.isUploadSubmit = true;
    if (this.uploadDocForm.invalid) {
      return
    }
    let ele = document.getElementById("closeBtn") as HTMLElement;
    ele.click();
    const formData: FormData = new FormData();
    formData.append("file", this.document);
    formData.append("document_name", this.uploadDocForm.controls.docName.value);
    formData.append("document_type", this.uploadDocForm.controls.docType.value);
    formData.append("expiryDate", this.uploadDocForm.controls.docExpiryDate.value);
    formData.append("employeeId", this.userData._id);
    this.spinner.show();
    this.resetform();
    this.generalService.uploadEmployeeDoc(formData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toast.success(res.message);
        this.docList = res.data.document;
      } else {
        this.toast.error(res.message);
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    })
  }

  document: any;
  onFileChange(files) {
    this.document = files[0];

    if (!files[0] || files[0].length == 0) {
      // this.isFileUploaded = true;
      return;
    } else {
      // this.isFileChosen = true;
      // this.fileName = this.document.name;
      this.document = files[0];
    }
  }
  async editEmployee() {
    this.setUserData();
    this.isEdit = false;
    await this.http.get('/assets/countryCode.json').subscribe((res: any) => {
      res.filter((e) => {
        if (this.userData.countryCode == e.dial_code) {
          this.adduserform.patchValue({
            phone: {
              "number": this.userData.phone,
              "countryCode": e.code,
              "dialCode": "+" + this.userData.countryCode
            }
          });
        }
      })
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    })
  }

  deleteEmployee() {
    if (confirm("Are you sure you want to remove user?")) {
      this.spinner.show();
      const data = { _id: this.userData._id };
      this.dataService.deleteEmployee(data).subscribe(
        (res: any) => {
          if (res.status) {
            this.router.navigate(['/panel/employees']);
            this.toast.success(res.message);
          } else {
            this.toast.info(res.message);
            this.spinner.hide();
          }
        }, (err) => {
          this.spinner.hide();
          this.toast.error("Oops, something went wrong. Please try again.")
        }
      );
    }
  }



  deleteDocument(value) {
    if (confirm("Are you sure you want to delete document?")) {
      this.spinner.show();
      let data = {
        _id: this.userData._id,
        docId: value._id
      }

      this.generalService.deleteEmployeeDocument(data).subscribe((res: any) => {
        if (res.status == 1) {
          this.spinner.hide();
          this.toast.success(res.message);
          this.docList = res.data
        } else {
          this.spinner.hide();
          this.toast.error('Something went wrong');
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      })
    }
  }

  getEmployeeIdCard(id) {
    this.spinner.show();
    let data = {
      id: id
    }
    this.generalService.getIdDetailById(data).subscribe((res: any) => {
      if (res.status) {
        this.spinner.hide();
        this.empCard = res.data;
      } else {
        this.toast.error('Something went wrong');
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    })
  }

  deleteEmployeeCard() {
    if (confirm('Are you sure want to delete this Id Card?')) {
      this.spinner.show();
      let data = {
        id: this.empCard._id,
        empId: this.userData._id
      }
      this.generalService.deleteIdCard(data).subscribe((res: any) => {
        if (res.status) {
          this.spinner.hide();
          this.toast.success(res.message)
          this.empCard = '';
        } else {
          this.spinner.hide();
          this.toast.error("Something went wrong");
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      })
    }
  }

  getDocmentFullURL(file = null) {
    if (file) return `${environment.url}${file}`;
  }
  getIdCardFullURL(file = null) {
    if (file) return `${this.storage_url}/idcard/${file}`;
  }

  redirect() {
    this.router.navigate(['/panel/employees']);
  }
}