<router-outlet></router-outlet>

<ngx-spinner bdOpacity=0.9 bdColor="rgba(0,0,0,0.88)" size="medium" color="#fff" type="ball-pulse" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>


<router-outlet>
    <div *ngIf="showLoader">
    <mat-spinner></mat-spinner>
    </div>
    </router-outlet>