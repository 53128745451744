import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { MessageService } from "primeng/api";
import { CommonModule } from "@angular/common";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/_services/common.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
    selector: 'app-create-certificate',
    templateUrl: './create-certificate.component.html',
    styleUrls: ['./create-certificate.component.css']
})
export class CreateCertificateComponent implements OnInit {
    adduserform: FormGroup;
    uploadedFile: any = null;
    act: string;
    isSubmitted: boolean;
    cardData: any;
    fn;
    ln;
    userid2;
    idExp;
    url;
    msg = "";
    profileImage;
    manager_name;
    director_name;
    //certificateData: any = {};

    //@ViewChild("getCertificateData") divgetCertificateData: ElementRef;

    constructor(
        private formBuilder: FormBuilder,
        //private messageService: MessageService,
        private spinner: NgxSpinnerService,
        //private route: ActivatedRoute,
        private gs: GeneralServiceService,
        private router: Router,
        private toast: ToastrService,
        public commonService: CommonService,
        private _bsModalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        if (window.history.state.hasOwnProperty("selectedCertificate")) {
            this.cardData = window.history.state.selectedCertificate;
            sessionStorage.setItem("cardData", JSON.stringify(this.cardData));
        } else {
            this.cardData = JSON.parse(sessionStorage.getItem("cardData"));
        }

        this.initAddUserForm();
    }

    ngAfterViewInit() {
        //this.certificateData = JSON.parse(
        //    this.divgetCertificateData.nativeElement.value
        //);
        //this.addCertificateForm();
    }

    getChange(event) {
        this.fn = event;
    }
    getChange2(event) {
        this.ln = event;
    }
    getUserId(event) {
        this.userid2 = event;
    }

    initAddUserForm() {
        var certNum = Math.round(Date.now());
        if (this.cardData) {
            this.adduserform = this.formBuilder.group({
                _id: [null],
                owner_name: [this.cardData.owner_name, [Validators.required]],
                certificate_name: [
                    this.cardData.certificate_name,
                    [Validators.required],
                ],
                director_name: [this.cardData.director_name],
                manager_name: [this.cardData.manager_name],
                cert_num: [certNum],
                expiry_date: [this.cardData.expiry_date],
            });
        } else {
            this.adduserform = this.formBuilder.group({
                _id: [null],
                owner_name: ["", [Validators.required]],
                certificate_name: ["", [Validators.required]],
                director_name: [""],
                manager_name: [""],
                cert_num: [certNum],
                expiry_date: [null],
            });
        }
    }

    addCertificateForm() {
        var certNum = Math.round(Date.now());
        //if (this.certificateData) {
        //    this.adduserform = this.formBuilder.group({
        //        _id: [null],
        //        owner_name: [this.certificateData.owner_name, [Validators.required]],
        //        certificate_name: [
        //            this.certificateData.certificate_name,
        //            [Validators.required],
        //        ],
        //        director_name: [this.certificateData.director_name],
        //        manager_name: [this.certificateData.manager_name],
        //        cert_num: [certNum],
        //        expiry_date: [this.certificateData.expiry_date],
        //    });
        //} else {
            
        //}
        this.adduserform = this.formBuilder.group({
            _id: [null],
            owner_name: ["", [Validators.required]],
            certificate_name: ["", [Validators.required]],
            director_name: [""],
            manager_name: [""],
            cert_num: [certNum],
            expiry_date: [null],
        });
    }

    getCertexp(event) {

        var date = new Date(event);

        this.idExp = date.toISOString();
    }

    createCertificate() {
        // this.act = "add";
        this.isSubmitted = true;


        //const tmpdate = moment().format();

        if (this.adduserform.invalid) {
            // alert("invalid");
            return;
        }
        this.spinner.show();
        var formData = this.adduserform.value;
        formData["domain_name"] = this.commonService.selectedCompanyDeatils.domain;
        formData["certExpDate"] = this.idExp;

        this._bsModalRef.hide();

        this.gs.saveCertificateData(formData).subscribe(
            (res: any) => {
                if (res.status) {
                    this.toast.success("Certificate Created successfully");
                    this.router
                        .navigateByUrl("/", { skipLocationChange: true })
                        .then(() => {
                            this.router.navigate(["/panel/certificate"]);
                        });
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    closeModal() {
        this._bsModalRef.hide();
    }

}
