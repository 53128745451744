import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CommonService } from "../../../../_services/common.service";

@Component({
    selector: "app-asset-view",
    templateUrl: "./view.component.html",
    styleUrls: ["./view.component.css"],
})
export class ViewAssetComponent implements OnInit {
    assetId: string;
    asset: any = {};
    storage_url = environment.url + "storage/";
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        public commonService: CommonService
    ) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.assetId = params.assetId;
        });
    }

    ngOnInit(): void {
        if (this.assetId) {
            this.storage_url = this.ds.getStorageUrl();
            this.spinner.show();
            this.ds.getAsset(this.assetId).subscribe((response: any) => {
                if (response.status) {
                    this.asset = response.data;
                } else {
                    this.toast.error(response.message);
                }
                this.spinner.hide();
            });
        }
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/asset/${file}`;
    }

    getDocuments(documents) {
        if (documents) {
            return documents[0]
                .split(",")
                .filter((document) => document != "undefined");
        } else {
            return [];
        }
    }
}
