import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../../../_services/authentication.service";
import { DataservicesService } from "../../../_services/dataservices.service";
import { RefreshContentService } from 'src/app/_services/refresh-content.service';
import { CommonService } from "src/app/_services/common.service";
@Component({
    selector: "app-profilemenu",
    templateUrl: "./profilemenu.component.html",
    styleUrls: ["./profilemenu.component.css"],
})
export class ProfilemenuComponent implements OnInit {
    headeremail: string;
    headername: string;
    initials: string;
    portalUser;
    colors = [
        "#0d3d56", "#1496bb", "#829356", "#bca136", "#fe6d21", "#co2f1d", "#37306B", "#1A120B", "#FB2576", "#FB2576", "#0C7B93", "#0d3d56", "#1496bb", "#829356", "#bca136", "#fe6d21", "#co2f1d", "#37306B", "#1A120B", "#FB2576", "#FB2576", "#0C7B93"
    ];
    background;
    constructor(
        private spinner: NgxSpinnerService,
        private as: AuthenticationService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private refreshContentService: RefreshContentService,
        private commonService: CommonService
    ) { }

    ngOnInit() {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        this.headeremail = this.portalUser.email;
        this.headername =
            this.portalUser.firstName + " " + this.portalUser.lastName;
        this.initials = this.headername.charAt(0);
        let randomColor = this.colors[this.headername.length];
        this.background = randomColor;

    }
    async logout() {
        await this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const filter = {
            akcessId: this.portalUser.akcessId,
            isLogin: false,
        };
        await this.ds.patchUser(filter).subscribe(
            (data) => {
                this.refreshContentService.setPortalData(null)
                this.refreshContentService.notiFyToNavbar(null)
                this.refreshContentService.notiFyToAllChildComponents(null)
                this.refreshContentService.resetAll()
                this.commonService.resetselectedCompanyDeatils()
                sessionStorage.clear();
                //localStorage.removeItem('pageReload');
                this.spinner.hide();
                this.toast.success("Logout successfully");
                this.as.logout();
            },
            (error) => {
                // this.toast.error("something went wrong");
                this.refreshContentService.setPortalData(null)
                this.refreshContentService.notiFyToNavbar(null)
                this.refreshContentService.notiFyToAllChildComponents(null)
                this.refreshContentService.resetAll()
                this.commonService.resetselectedCompanyDeatils()
                sessionStorage.clear();
                //localStorage.removeItem('pageReload');
                this.spinner.hide();
                this.toast.success("Logout successfully");
                this.as.logout();
                this.spinner.hide();
            }
        );
    }
}
