<div class="company-profile-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">{{this.companyDetails.name}} Profile</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">{{this.companyDetails.name}} Profile</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <div class="container-fluid">
        <div class="card card-outline-info">
            <div class="card-header">
                <h4 class="m-b-0 text-white">Company Profile Details</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-lg-6">
                        <label class="font-500">Domain Name</label>
                        <input type="text" class="form-control" [(ngModel)]="companyDetails.domain" readonly>
                    </div>
                    <div class="form-group col-lg-6">
                        <label class="font-500">API Key</label>
                        <input type="text" class="form-control" [(ngModel)]="companyDetails.apikey" readonly>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>
