export class Property {
    _id: number;
    name: string = "";
    description: string = "";
    price: string = "";
    photo: string = "";
    isActive: string = "";
    isDeleted: string = "";
    createdAt: string = "";
    updatedAt: string = "";
    deletedAt: string = "";
    isSelected: Boolean = false;
}
