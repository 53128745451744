import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import {html2pdf} from 'html2pdf.js';
import { HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';



@Component({
    selector: 'app-verification-history',
    templateUrl: './verification-history.component.html',
    styleUrls: ['./verification-history.component.css']
    
})
export class VerificationHistoryComponent implements OnInit {

    gbgResultView: boolean = false; 
    isIDVerifiactionResult: boolean = false;   
    gbgUploadDocResult: any;
    portalUser: any;
    search_employees = '';
    currentPage: any = 1;
    totalCount: number = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;
    gbgData: any[] = [];
    entityid: string | null = null;
    personalInfoForm: FormGroup;
    modalRef: NgbModalRef | null = null;
    modalData: any;
    uploadForm: FormGroup;
    myGroup: any = {};
    selectedValue: string;  

    showSuccessMessage: boolean = false;
    showSelectJourneyModal: boolean = false;
    validationMessage: string;  
    formData = {        
        firstName: '',
        lastName: '',
        birthdate: '',
        reference:'',
        email: '',
        telephone:'',
        address:'',
        countryCode:'',
    };

    // logData() {
    //     console.log('Modal Data:', this.modalData);
    //   }
    
    // modalData = {
    //     title: 'Quick Key request',
    //     options: [
    //       { id: 'profile7', imgSrc: 'assets/img/send.png', label: 'Send new invite to an individual' },
    //       { id: 'profile1', imgSrc: 'assets/img/search-profile.png', label: 'Create and go to profile' },
    //       // Add more options as needed
    //     ]
    //   };

    // logSelectedValue(value: string) {
    //     this.selectedValue = value;
    //     console.log("Modal data:", value);
    //     console.log("Form Data:", this.formData);

    // }
    
    constructor(
        private fb: FormBuilder, 
        private router: Router,
        private calldata: DataservicesService,
        private modalService: NgbModal,  
        private spinner: NgxSpinnerService,      
        private toast: ToastrService,     
    ) { }
    formdata = { 
        entityId:'',   
        firstName: '',
        lastName: '',
        birthdate: '',
        reference:'',
        email: '',
        telephone:'',
        address:'',
        countryCode:'',
    };

//     openLargeModal() {
//     this.modalRef = this.modalService.open('largeModal', { size: 'lg' });
//     this.modalRef.componentInstance.modalData = this.modalData;
//     this.myGroup = new FormGroup({
        
//      });
    
//   }

    displayStyle = "none"; 
  
  openPopup() { 
    this.displayStyle = "block"; 
  } 
  closePopup(modalId: string) {
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.style.display = 'none';
    }
  }

  goToNextModal(nextModalId: string) {
        
    console.log("Modal data:", this.selectedValue);
   
   console.log("Form Data:", this.formdata);

   // Get the current visible modal
   const currentModal = document.querySelector('.modal[style*="display: block"]')
   
   if (currentModal) {
     currentModal.setAttribute('style', 'display: none');
   }
   // this.router.navigate(['/user-details']);
   
   // Show the next modal
   const nextModal = document.getElementById(nextModalId);
   if (nextModal) {
     nextModal.style.display = 'block';
   }
  
   // if (this.selectedValue) {            
   //     this.displayStyle = 'none';            
   //   } else {
   //     alert('Please make a selection before proceeding.');
   //   }
      
    if (this.uploadForm.valid) {
       // console.log("hi");
       this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));       
       // const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
       

       let datas = { 
           "entityId" :(this.portalUser.entityid),     
           "accessId": (this.portalUser.akcessId),  
           "firstName": (this.formdata.firstName),
           "surname": (this.formdata.lastName),
           "email": (this.formdata.email),
           "phoneNumber": (this.formdata.telephone),             
       }
      
       // console.log('datasdatas' , datas);
       this.spinner.show();
      
       this.calldata.addentity( JSON.stringify(datas, null, 2)).subscribe(                
           (res: any) => { 
               if (res.statusCode == 200   )  {
                   console.log('resss', res);
           //    this.entityId = res.data.id;
              datas.entityId = res.data.id;
              console.log('Updated datas:', datas);
           //  console.log('entityId :', this.entityId);  
           // console.log('accessId print:', this.formdata);   
           console.log('entity entityid print ', res.data.id);
           // console.log('entity id print ', this.calldata.id);
               // console.log('Email:', res.data.email);                    
             }
               // this.router.navigate(['/panel/verification-userdetails']).then(() => {
               //     this.spinner.hide();
               //   });
               // this.router.navigate(['/panel/verification-userdetails/{{res.data.id}}'])
               const navigationExtras = {
                   queryParams: { open: this.selectedValue } // Specify the query parameter and its value here
                 };
                 this.router.navigate(['panel/verification-userdetails/',res.data.id,this.selectedValue]).then(() => {
                   this.spinner.hide();
                 });
                       
               //   this.router.navigate(['verification-IframeModal']).then(() => {
               //     this.spinner.hide();
               //   });

               this.toast.success("create profile successfully");                    
               this.showSuccessMessage = true;
               setTimeout(() => {  
                   this.showSuccessMessage = false;
               }, 3000)
           },                
           (err) => {                
               console.log('error log', err);      
               this.spinner.hide();             
                               
               this.toast.error("An error when create profile")
               setTimeout(() => {
                   this.showSuccessMessage = false;
                 }, 3000); // Adjust time as needed
           }  
       )

       console.log("hello1");
       this.showSelectJourneyModal = true;

       this.calldata.createprocess( JSON.stringify(datas, null, 2)).subscribe( 
           (res: any) => { 
             console.log("hello");
             console.log('resss', res);    
             if (res.statusCode == 200   )  {
               console.log("hello2");
               console.log('Updated datas:', datas);
             }
           }
         )

         
     }

     const selectedOption = document.querySelector('input[name="profile"]:checked');
     if (!selectedOption) {
       console.log("hello3");
       this.validationMessage = 'Please select an option before proceeding.';
     } else {
       // this.showSelectJourneyModal = true;
       console.log("hello4");
       this.validationMessage = '';
       // Logic to show the next modal
       this.displayStyle = 'none'; // Close the current modal
       // Your logic to open the next modal
     }
   
 }
 goToPreviousModal(previousModalId: string) {
    // Get the current visible modal
    const currentModal = document.querySelector('.modal[style*="display: block"]');
    if (currentModal) {
      currentModal.setAttribute('style', 'display: none');
    }

    // Show the previous modal
    const previousModal = document.getElementById(previousModalId);
    if (previousModal) {
      previousModal.style.display = 'block';
    }
  }
//   goToNextModal(nextModalId: string) {
//      console.log("Modal data:", this.selectedValue);
    
//     console.log("Form Data:", this.formData);

//     // Get the current visible modal
//     const currentModal = document.querySelector('.modal[style*="display: block"]');
//     if (currentModal) {
//       currentModal.setAttribute('style', 'display: none');
//     }
//     this.router.navigate(['/user-details']);
    
//     // Show the next modal
//     const nextModal = document.getElementById(nextModalId);
//     if (nextModal) {
//       nextModal.style.display = 'block';
//     }


       
//      if (this.uploadForm.valid) {
//         console.log("hi");
//         this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));       
        
//         let datas = {            
//             "accessId": (this.portalUser.akcessId),  
//             "firstName": (this.formData.firstName),
//             "surname": (this.formData.lastName),
//             "emailAddress": (this.formData.email),
//             "phoneNumber": (this.formData.telephone),
         
//         }
       
//         console.log(datas);
//         this.calldata.createprocess( JSON.stringify(datas, null, 2)).subscribe(
            
//             (res: HttpResponse<any>) => {
//                 console.log("hi");               
                
//             console.log('accessId :', this.formData);
                
                
//             },
//             (err) => {                
//                 console.log('error log', err);                   
              
//             }  
//         )
//       }


      




//   }

//   goToPreviousModal(previousModalId: string) {
//     // Get the current visible modal
//     const currentModal = document.querySelector('.modal[style*="display: block"]');
//     if (currentModal) {
//       currentModal.setAttribute('style', 'display: none');
//     }

//     // Show the previous modal
//     const previousModal = document.getElementById(previousModalId);
//     if (previousModal) {
//       previousModal.style.display = 'block';
//     }
//   }

//   closePersonalInfoModal() {
//     const personalInfoModal = document.getElementById('personalInfoModal');
//     if (personalInfoModal) {
//       personalInfoModal.style.display = 'none';
//     }
//   }
    

    ngOnInit(): void {
        this.getAllgbgDocument();

        this.uploadForm = this.fb.group({            
           firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            birthdate: [''],
            reference: [''],
            email: [''],
            telephone: [''],
            address: [''],
            countryCode: ['']
          });
        

    }

    logSelectedValue(value: string) {
        this.selectedValue = value;
        console.log("Modal data:", value);
        console.log("Form Data:", this.formdata);

    }
    // logFormData(): void {
    //     console.log('Form Data:', this.uploadForm.value);
    //   }

    

    // getAllgbgDocument() {
    //     this.calldata.getAllgbgDocument().subscribe(
    //         (res) => {
    //             console.log(res)
    //             this.gbgData = res['data']
    //         }
    //     )
    // }
    deleteData(data: any): void {
        // handle delete logic here
        const index = this.gbgData.indexOf(data);
        if (index > -1) {
          this.gbgData.splice(index, 1);
        }
      }

      getprocessresult(){
       
    }

    goprofile(entityId: any):void{
        // this.router.navigate(['panel/verification-userdetails']).then(() => {
        //         this.spinner.hide();
        //       });
        console.log(entityId);
        this.router.navigate(['panel/verification-userdetails',entityId]).then(() => {
          this.spinner.hide();
        }).catch(err => {
          console.error('Navigation error:', err);
        });
    }
    getAllgbgDocument() : void  {
       console.log("inisde get summerysssssss");
        let portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        

        this.calldata.checkSummaryCredasEntityhistory( portalUser.akcessId).subscribe(
            (response) => {
                // Handle the response here
                console.log('Response:', response);
                this.gbgData = response.data;
                console.log(response.data)
                
                // this.totalCount = this.gbgData.length;
                // Further processing if needed
            },
            (error) => {
                // Handle errors
                console.error('Error:', error);
                // Optional: Handle error-specific logic
            }

        );
        if (this.isIDVerifiactionResult) {
        console.log("if isIDVerifiactionResult ");
        this.portalUser = JSON.parse(sessionStorage.getItem('entityid'));
        let data = {
            "entityId":(this.entityid),
            "accessId": (this.portalUser.akcessId)
        }
        this.calldata.checkSummary( JSON.stringify(data, null, 2)).subscribe(
            (res: HttpResponse<any>) => {
                // this.spinner.hide();
                console.log("hi");
            console.log('Response Status Code:', res.status);
                console.log('Response Body checkSummary:', res.body);
                console.log("API Response:", res);  
                // this.entityId = res['entityId'];
                               
                this.gbgUploadDocResult = res;
                
            },
            (err) => {
                // this.spinner.hide();
                console.log('error log', err);                   
                // this.toast.error(err.message)
            }  
        )
        
    }
}
   


    veiwGbgDoc(data: any): void{
        console.log("if isIDVerifiactionResult");
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        // const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
        let datas = {
            "entityId":(data.entityId),
            "accessId": (this.portalUser.akcessId)
        }
        this.entityid=data.entityId;
       
            // this.gbgUploadDocResult();
          
        this.calldata.checkSummary( JSON.stringify(datas, null, 2)).subscribe(
            (res: HttpResponse<any>) => {
                console.log("hi")
            console.log('Response Status Code:', res.status);
                console.log('Response Body checkSummary:', res.body);
                console.log("API Response:", res);  
                // this.entityId = res['entityId'];
                // this.spinner.hide();               
                this.gbgUploadDocResult = res;
                // cons
                
            })
        this.gbgResultView = true;
        console.log(data);
        this.gbgUploadDocResult = data ;
        this.getAllgbgDocument = data ;
    }
    
    onProjectPageChange(event) {
        this.currentPage = event
        this.getAllgbgDocument();
    }

    changePage(value) {
        this.pageSize = parseInt(value)
        this.getAllgbgDocument();
    }


    navigate() {
        this.router.navigate(["/panel/verifications"])
    }

    exportIdResultPDF() {
        // define the html element to export
        let element = document.getElementById('gbgResultPreview');
        // define optional configuration
        let opt = {
            margin: 1,
            filename: 'result.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        // create an instance of html2pdf class
        let exporter = new html2pdf(element, opt);
        // download the pdf
        exporter.getPdf(true).then((pdf) => { console.log('pdf downloaded!', pdf); });



        html2pdf.getPdf(opt).then((pdf) => { console.log(pdf.output('datauristring')); });
    }

    printIdResultHTML() {
        var markup = document.documentElement;
        var toPrint = document.getElementById("gbgResultPreview");
        var popupWin = window.open(
            "",
            "_blank",
            "width=" +
            screen.width +
            ",height=" +
            screen.height +
            ",location=no,left=200px"
        );
        popupWin.document.open();
        popupWin.document.write(
            '<html><title>::Print Preview::</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"><style>.enquiry-details h3 { margin-bottom: 30px; } .cs-card{ border: 0; border-radius: 5px; box-shadow: 0 0 1px 1px #00000010; width: 100%; margin-bottom: 30px; padding: 5px;} @media print { .fix-sidebar .left-sidebar { display: none !important; visibility: hidden !important; } * { visibility: hidden; } .property-card { visibility: visible !important; } .property-card * { visibility: visible; } .noprint, .property-card .noprint { visibility: hidden !important; } }</style></head><body">'
        );
        popupWin.document.write(toPrint.innerHTML);
        popupWin.document.write("</body>");
        popupWin.document.write("</html>");
        popupWin.document.close();
    }



}
