<div class="clients-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Clients</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Clients</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="input-group">
                                <input type="text" class="form-control" [(ngModel)]="client_search_input" id="search"
                                    name="client_search_input" placeholder="Search Clients"
                                    (change)="getclients($event.target.value)" />
                                <!--<div class="input-group-append">
                                  <div class="input-group-text" (click)="getCertificateList()">
                                      <i class="fas fa-search"></i>
                                  </div>
                              </div>-->
                            </div>
                        </div>
                        <!-- <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-xl-1 mt-1 mt-md-0">
                            <label class="font-500">Filter By:</label>
                            <select class="form-control" #filterByData (change)="filterBy(filterByData.value)">
                                <option selected>Filter By</option>
                                <option *ngFor="let status of Status" value="verify and accept">{{ status }}</option>
                            </select>
                        </div> -->
                        <div class="col-12 col-md-6 col-lg-8 col-xl-9 text-right mt-1 mt-md-0">
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn send-invite-btn"
                                data-toggle="modal" (click)="viewSentModel()" tooltip="View Send Invitation"
                                placement="bottom" container="body">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                &nbsp; View Sent
                            </button>
                            &nbsp;
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn send-invite-btn"
                                data-toggle="modal" data-target="#sendInvitation" (click)="getDefaultInvitation()"
                                tooltip="Send Invitation" placement="bottom" container="body">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                &nbsp; Send Invitation
                            </button>
                            &nbsp;
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn"
                                data-toggle="modal" data-target="#addform" (click)="addEditClient('add','')">
                                <i class="fas fa-plus-circle" tooltip="Create Client" placement="bottom"
                                    container="body"></i> Add Client
                            </button>
                        </div>
                    </div>
                    <!-- Action Tab -->
                    <div class="action-tab">
                        <button type="button" class="mx-1 btn" (click)="deleteRow()" tooltip="Delete Client"
                            container="body">
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button type="button" class="mx-1 btn" (click)="viewRecycle()" tooltip="Recycle Bin"
                            container="body">
                            <i class="fal fa-recycle"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table text-center" mdbTable
                            #tableEl="mdbTable" small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>
                                        <!--<label data-toggle="collapse" data-target="#actionBar" aria-expanded="false" aria-controls="actionBar" class="m-0">
                                          <input class="" type="checkbox">
                                      </label>-->
                                    </th>
                                    <th [mdbTableSort]="userlist" sortBy="firstName">Name <span><mdb-icon icon="sort"
                                                class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>
                                    <th [mdbTableSort]="userlist" sortBy="firstName">AKcess ID <span><mdb-icon
                                                icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th [mdbTableSort]="userlist" sortBy="email">Email <span><mdb-icon icon="sort"
                                                class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>
                                    <th [mdbTableSort]="userlist" sortBy="mobile">Mobile Number <span><mdb-icon
                                                icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th [mdbTableSort]="userlist" sortBy="clientStatus">Created On<span><mdb-icon
                                                icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="userlist?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">No Clients Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="userlist?.length > 0">
                                <!-- [ngClass]="{'disable-table-row': !data.phone && !data.email}" -->
                                <tr [id]="data?._id"
                                    *ngFor="let data of userlist | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                    <td>
                                        <input class="" type="checkbox" [attr.id]="data?._id" value="{{ data?.email }}"
                                            (click)="getBulkEmailID($event); getBulkMobiles($event,data?.mobile)">
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditClient('view',data)"
                                            data-toggle="modal" data-target="#addform">
                                            {{data.firstName}} {{data.lastName}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditClient('view',data)"
                                            data-toggle="modal" data-target="#addform">
                                            {{data?.akcessId}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditClient('view',data)"
                                            data-toggle="modal" data-target="#addform">
                                            {{data?.email}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditClient('view',data)"
                                            data-toggle="modal" data-target="#addform">
                                            {{data?.phone}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditClient('view',data)"
                                            data-toggle="modal" data-target="#addform">
                                            {{data?.createdAt | date : 'medium'}}
                                        </a>
                                    </td>
                                    <td *ngIf="!data.clientStatus && !data.email && !data.phone" class="text-danger">
                                        Pending
                                    </td>
                                    <td class="text-left" *ngIf="data.email && data.phone">
                                        <button type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-outline-info"
                                            (click)="sendIndividualInvitation(data,'sms')">
                                            <i class="fas fa-mobile-alt" tooltip="Send Invitation By SMS"
                                                container="body" placement="bottom"></i>
                                        </button>
                                        <button type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-outline-info"
                                            (click)="sendIndividualInvitation(data,'email')">
                                            <i class="far fa-envelope" tooltip="Send Invitation By Email"
                                                container="body" placement="bottom"></i>
                                        </button>
                                        <!--<button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info" (click)="deleteClient(data._id)">
                                          <i class="fa fa-trash" tooltip="Delete" container="body" placement="bottom"></i>
                                      </button>-->
                                        <span tooltip="Missing Client AKcess ID" container="body" placement="bottom">
                                            <button type="button"
                                                class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                                *ngIf="!data.akcessId" disabled>
                                                eForm Responses
                                            </button>
                                        </span>

                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            data-toggle="modal" data-target="#vieweformresponses"
                                            (click)="viewEformsResponses(data.akcessId)" *ngIf="data.akcessId">
                                            eForm Responses
                                        </button>
                                        <!--
                                       <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info" data-toggle="modal" data-target="#viewuser"
                                              (click)="openedituser(data)" tooltip="View" container="body" placement="bottom">
                                          <i class="fa fa-eye"></i>
                                      </button>-->
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div class="table-page" *ngIf="userlist?.length > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event,'client')"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value,'client')"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- Modals -->
<!-- Add/Edit Client Modal -->
<div class="modal" id="addform" tabindex="-1" role="dialog" aria-labelledby="addformModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content add-client-body">
            <form (ngSubmit)="addclient()" [formGroup]="adduserform">
                <div class="modal-header">
                    <h4 class="modal-title" id="addformModalLabel">{{(act=="add")?'Add':'Edit'}} Client Information</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetform()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <input *ngIf="act=='add'" type="hidden" formControlName="_id">
                    <!-- Personal Information -->
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="firstName" class="font-500">First Name<span
                                    class="required-label">*</span></label>
                            <input type="text" class="form-control" formControlName="firstName" placeholder="First Name"
                                value="">
                            <div class="invalid-feedback"
                                *ngIf="isSubmitted && adduserform.get('firstName').hasError('required')">
                                First Name is required
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="lastName" class="font-500">Last Name<span
                                    class="required-label">*</span></label>
                            <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
                            <div class="invalid-feedback"
                                *ngIf="isSubmitted && adduserform.get('lastName').hasError('required')">
                                Last Name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-5">
                            <label for="email"><b>Email</b><span class="required-label">*</span></label>
                            <input type="text" class="form-control" formControlName="email" placeholder="Enter Email">
                            <div class="invalid-feedback"
                                *ngIf="isSubmitted && adduserform.get('email').hasError('required')">
                                Email is required
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="isSubmitted && adduserform.get('email').hasError('email')">
                                Email is invalid
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="phone"><b>Country Code</b></label>
                            <input type="number" class="form-control" formControlName="country_code"
                                placeholder="Enter Code">
                            <!-- <div class="invalid-feedback">
                              Phone number is not valid
                          </div> -->
                        </div>
                        <div class="form-group col-md-4">
                            <label for="phone"><b>Phone No.</b></label>
                            <input type="number" class="form-control" formControlName="phone"
                                placeholder="Enter Phone Number">
                            <div class="invalid-feedback" *ngIf="adduserform.get('phone').hasError('pattern')">
                                Phone number is not valid
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn waves-effect waves-light btn-info"
                        [disabled]="adduserform.invalid">
                        {{(act=="add")?'Add':'Update'}}
                    </button>
                    <button type="button" class="btn waves-effect waves-light btn-danger" id="addformclose"
                        data-dismiss="modal" (click)="resetform()">
                        Close
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- View Modal -->
<div class="modal" id="viewuser" tabindex="-1" role="dialog" aria-labelledby="viewUserModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content add-client-body">
            <div class="modal-header">
                <h4 class="modal-title" id="viewUserModalLabel">View Client Information</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="akceessId" class="font-500">AKcess Id</label>
                        <input type="text" class="form-control" value={{clientData?.akcessId}} readonly>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="firstName" class="font-500">First Name</label>
                        <input type="text" class="form-control" value={{clientData?.firstName}} readonly>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="lastName" class="font-500">Last Name</label>
                        <input type="text" class="form-control" value={{clientData?.lastName}} readonly>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="email" class="font-500">Email</label>
                        <input type="text" class="form-control" value={{clientData?.email}} readonly>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="phone" class="font-500">Mobile Number</label>
                        <input type="text" class="form-control" value={{clientData?.phone}} readonly>
                    </div>
                </div>
                <div class="credit-score-details">
                    <div class="form-group row">
                        <label class="col-8 col-md-4 font-500">Score</label>
                        <label class="col-4"
                            [ngClass]="(clientData && clientData.score < 50) ? 'text-danger' : 'text-success'">{{
                            clientData?.score }}</label>
                    </div>
                    <div class="form-group row">
                        <label class="col-8 col-md-4 font-500">Credit Score</label>
                        <label class="col-4"
                            [ngClass]="(clientData && clientData.creditScore < 50 ) ? 'text-danger' : 'text-success'">{{
                            clientData?.creditScore }}</label>
                    </div>
                    <div class="form-group row">
                        <label class="col-8 col-md-4 font-500">Blacklist Status</label>
                        <label class="col-4"
                            [ngClass]="(clientData && clientData.blackListStatus) ? 'text-danger' : 'text-success'">{{
                            clientData?.blackListStatus ? 'Y' : 'N' }}</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#creditScore">
                    Credit Score Update
                </button>
                <button type="submit" class="btn waves-effect waves-light btn-info" data-toggle="modal"
                    data-target="#addform" (click)="openedituser(clientData)" id="viewFormClose" data-dismiss="modal">
                    Edit
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="viewFormClose"
                    data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- View eForm Responses-->
<div class="modal" id="vieweformresponses" tabindex="-1" role="dialog" aria-labelledby="viewEformResponsesModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content add-client-body">
            <div class="modal-header">
                <h4 class="modal-title" id="viewEformResponsesModalLabel">View eForm responses</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                        small="true" hover="true">
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>AKcess Id</th>
                                <th>eForm Name</th>
                                <th>Status</th>
                                <th>Sent Date</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="eformresponse?.length == 0">
                            <tr>
                                <td colspan="4" class="text-center">No sent eForms Found</td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="eformresponse?.length > 0">
                            <tr *ngFor="let data of eformresponse| paginate: { itemsPerPage: pageEformResponseSize, currentPage: currentEformResponsePage, totalItems: totalEformResponseCount } ; let i = index;">
                                <td data-toggle="modal"
                                    (click)="viewEformResponses(i,data.userdata?.firstName+'_'+data.userdata?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data?.pulldata;">
                                    {{data.firstName }}
                                    {{data.lastName}}
                                </td>
                                <td data-toggle="modal" data-target="#viewform"
                                    (click)="viewEformResponses(i,data.userdata?.firstName+'_'+data.userdata?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data?.pulldata;">
                                    {{data.akcessId}}
                                </td>
                                <td data-toggle="modal" data-target="#viewform"
                                    (click)="viewEformResponses(i,data.userdata?.firstName+'_'+data.userdata?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data?.pulldata;">
                                    {{data.formName}}
                                </td>
                                <td data-toggle="modal" data-target="#viewform"
                                    (click)="viewEformResponses(i,data.userdata?.firstName+'_'+data.userdata?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data?.pulldata;">
                                    <div *ngIf="data.status=='update client field'">Update Client</div>
                                    <div *ngIf="data.status!=='update client field'">{{data.status}}</div>
                                </td>
                                <td data-target="#viewform"
                                    (click)="viewEformResponses(i,data.userdata?.firstName+'_'+data.userdata?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data?.pulldata;">
                                    {{data.createdAt | date : 'medium'}}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="7">
                                    <div class="table-page" *ngIf="totalEformResponseCount > 5">
                                        <div class="page-controls">
                                            <pagination-controls (pageChange)="onProjectPageChange($event,'eformresponse')"
                                                class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                nextLabel="Next"></pagination-controls>
                                        </div>
                                        <div class="page-size">
                                            <label class="mb-0 mr-2">Item per page</label>
                                            <select #val (change)="changePage(val.value,'eformresponse')"
                                                class="form-control form-control-sm" id="pageSelector">
                                                <option *ngFor="let pG of numberOfEformResponsePage" value="{{pG}}">{{pG}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="submit" class="btn waves-effect waves-light btn-info" data-toggle="modal"
                    data-target="#addform" (click)="openedituser(clientData)" id="viewFormClose" data-dismiss="modal">
                    Edit
                </button> -->
                <!-- <button type="button" class="btn waves-effect waves-light btn-danger" id="viewFormClose"
                    data-dismiss="modal">
                    Close
                </button> -->
            </div>
        </div>
    </div>
</div>

<!-- View Eform Response Modal -->
<div class="modal" id="viewform" tabindex="-1" role="dialog" aria-labelledby="viewformModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewformModalLabel">{{formname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                        small="true" hover="true">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Check To Verify</th>
                                <th>Expiry Date</th>
                                <th>Grade Verification</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of fields;let i = index;">
                                <td>
                                    <div *ngIf="data.keytype == 'File' || data.keytype == 'file'">
                                        <a class="btn btn-sm waves-effect waves-light btn-info"
                                            href="{{imageUrl}}{{data?.path}}" target="_blank">Document {{i}}</a>
                                        <button *ngIf="pullDataStatus == 'yes'" type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="fetchDataFromDoc(data)">
                                            <i class="fas fa-download" tooltip="Fetch data from document"
                                                container="body" placement="bottom"></i>
                                        </button>
                                        <div *ngIf="facematch == 'yes' && pullDataStatus == 'yes'">
                                            <h5 class="text-success" *ngIf="isFacialMatched == 'true'">Face Matched</h5>
                                            <h5 class="text-danger" *ngIf="isFacialMatched == 'false'">Face Mismatched
                                            </h5>
                                        </div>
                                    </div>
                                    <!-- Plain Fields -->
                                    <div
                                        *ngIf="data.keytype == 'text' || data.keytype == 'Text' || data.keytype == 'arabicfirstname' || data.keytype == 'arabicstring' || data.keytype == 'arabiclastname' || data.keytype == 'string' || data.keytype == 'list' || data.keytype == 'select' || data.keytype == 'number' || data.keytype == 'address' || data.keytype == 'phone' || data.keytype == 'mobile'">
                                        <div class="form-group">
                                            <label class="font-500">{{data?.labelname}}</label>
                                            <input class="form-control form-control-sm" type="{{data.keytype}}"
                                                value="{{data.value}}" placeholder="{{data.key}}" readonly>
                                        </div>
                                        <!-- Section fields  -->
                                        <div *ngIf="data && data.optionsData && data.optionsData.length > 0">
                                            <div *ngFor="let secVal of data.optionsData">
                                                <div class="form-group"
                                                    *ngIf="data.keytype != 'radio' && data.keytype != 'checkbox'">
                                                    <label class="font-500">{{secVal.labelname}}</label>
                                                    <input type="text" class="form-control form-control-sm"
                                                        value="{{secVal.value}}" placeholder="{{secVal.key}}" readonly>
                                                </div>
                                                <div *ngIf="secVal && secVal.moreFields">
                                                    <div *ngFor="let s of  secVal?.moreFields">
                                                        <div class="form-group">
                                                            <label class="font-500">{{s?.labelname}}</label>
                                                            <input type="text" class="form-control form-control-sm"
                                                                value="{{s?.value}}" placeholder="{{secVal.key}}"
                                                                readonly>
                                                        </div>
                                                        <div *ngFor="let d of parseMoreData(s?.options)">
                                                            <!--<div style="margin-left: 10px; background-color: rgb(248, 248, 248);" class="form-check" *ngIf="s && (s.keytype == 'radio' || s.keytype == 'checkbox')">
                                                              <input class="form-check-input" type="{{s?.keytype}}" [checked]="d?.checked || s?.value === d?.lable" name="box-name1{{s?.keytype}}" placeholder="{{d?.key}}" disabled>
                                                              <label class="form-check-label" for="demo">{{d?.lable}}</label>
                                                          </div>-->
                                                            <div class="form-group custom-control custom-checkbox"
                                                                *ngIf="s && (s.keytype == 'checkbox')">
                                                                <input type="{{s?.keytype}}"
                                                                    class="custom-control-input"
                                                                    name="box-name1{{s?.keytype}}"
                                                                    placeholder="{{d?.key}}" id="{{d?.key}}"
                                                                    [checked]="d?.checked || s?.value === d?.lable"
                                                                    disabled>
                                                                <label class="custom-control-label"
                                                                    for="{{d?.key}}">{{d?.lable}}</label>
                                                            </div>
                                                            <div class="form-group custom-control custom-radio"
                                                                *ngIf="s && (s.keytype == 'radio')">
                                                                <input type="{{s?.keytype}}"
                                                                    class="custom-control-input"
                                                                    name="box-name1{{s?.keytype}}"
                                                                    placeholder="{{d?.key}}" id="{{d?.key}}"
                                                                    [checked]="d?.checked || s?.value === d?.lable"
                                                                    disabled>
                                                                <label class="custom-control-label"
                                                                    for="{{d?.key}}">{{d?.lable}}</label>
                                                            </div>
                                                            <div class="form-group"
                                                                *ngIf="s && (s.keytype != 'radio' && s.keytype != 'checkbox')">
                                                                <label class="font-500">{{s?.labelname}}</label>
                                                                <input type="text" class="form-control form-control-sm"
                                                                    value="{{s?.value}}" placeholder="{{secVal.key}}"
                                                                    readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Plain Fields -->
                                    <div
                                        *ngIf="data.keytype == 'date' || data.keytype == 'radio' || data.keytype == 'password' || data.keytype == 'checkbox'">
                                        <div class="form-group">
                                            <label class="font-500">{{data?.labelname}}</label>
                                            <input class="form-control form-control-sm" type="text"
                                                value="{{data.value}}" placeholder="{{data?.key}}" readonly>
                                        </div>
                                        <!-- Section fields  -->
                                        <div *ngIf="data && data.optionsData && data.optionsData.length > 0">


                                            <div *ngFor="let secVal of data.optionsData">
                                                <!--<div *ngIf="(data.keytype == 'radio' || data.keytype == 'checkbox') && (secVal?.checked || secVal?.lable == data?.value)">
                                                  <div class="form-check">
                                                      <input class="form-check-input" value="{{secVal?.lable}}" type="{{data?.keytype}}" [checked]="secVal?.checked || secVal?.lable == data?.value" name="box-name{{data?.keytype}}" placeholder="{{secVal?.key}}" disabled>
                                                      <label class="form-check-label" for="demo">{{secVal?.lable}}</label>
                                                  </div>
                                              </div>-->
                                                <div class="form-group custom-control custom-checkbox"
                                                    *ngIf="(data.keytype == 'checkbox') && (secVal?.checked || secVal?.lable == data?.value)">
                                                    <input type="{{data?.keytype}}" class="custom-control-input"
                                                        name="box-name{{data?.keytype}}" placeholder="{{secVal?.key}}"
                                                        id="{{secVal?.lable}}"
                                                        [checked]="secVal?.checked || secVal?.lable == data?.value"
                                                        disabled>
                                                    <label class="custom-control-label"
                                                        for="{{secVal?.lable}}">{{secVal?.lable}}</label>
                                                </div>
                                                <div class="form-group custom-control custom-radio"
                                                    *ngIf="(data.keytype == 'radio') && (secVal?.checked || secVal?.lable == data?.value)">
                                                    <input type="{{data?.keytype}}" class="custom-control-input"
                                                        name="box-name{{data?.keytype}}" placeholder="{{secVal?.key}}"
                                                        id="{{secVal?.lable}}"
                                                        [checked]="secVal?.checked || secVal?.lable == data?.value"
                                                        disabled>
                                                    <label class="custom-control-label"
                                                        for="{{secVal?.lable}}">{{secVal?.lable}}</label>
                                                </div>
                                                <div
                                                    *ngIf="secVal && secVal.moreFields && ( secVal.checked || secVal.lable == data.value)">
                                                    <div *ngFor="let s of  secVal?.moreFields">
                                                        <div class="form-group">
                                                            <label class="font-500">{{s?.labelname}}</label>
                                                            <input type="text" class="form-control form-control-sm"
                                                                value="{{s?.value}}" placeholder="{{secVal.key}}"
                                                                readonly>
                                                        </div>
                                                        <div *ngFor="let d of parseMoreData(s?.options)">
                                                            <!--<div style="margin-left: 10px; background-color: rgb(248, 248, 248);" class="form-check" *ngIf="s && (s.keytype == 'radio' || s.keytype == 'checkbox') && (d?.checked || s?.value === d?.lable)">
                                                              <input class="form-check-input" type="{{s?.keytype}}" [checked]="d?.checked || s?.value === d?.lable" name="box-name" placeholder="{{d?.key}}" disabled>
                                                              <label class="form-check-label" for="demo">{{d?.lable}}</label>
                                                          </div>-->
                                                            <div class="form-group custom-control custom-checkbox"
                                                                *ngIf="s && (s.keytype == 'checkbox') && (d?.checked || s?.value === d?.lable)">
                                                                <input type="{{s?.keytype}}"
                                                                    class="custom-control-input" name="box-name"
                                                                    placeholder="{{d?.key}}" id="{{d?.key}}"
                                                                    [checked]="d?.checked || s?.value === d?.lable"
                                                                    disabled>
                                                                <label class="custom-control-label"
                                                                    for="{{d?.key}}">{{d?.lable}}</label>
                                                            </div>
                                                            <div class="form-group custom-control custom-radio"
                                                                *ngIf="s && (s.keytype == 'radio') && (d?.checked || s?.value === d?.lable)">
                                                                <input type="{{s?.keytype}}"
                                                                    class="custom-control-input" name="box-name"
                                                                    placeholder="{{d?.key}}" id="{{d?.key}}"
                                                                    [checked]="d?.checked || s?.value === d?.lable"
                                                                    disabled>
                                                                <label class="custom-control-label"
                                                                    for="{{d?.key}}">{{d?.lable}}</label>
                                                            </div>
                                                            <div class="form-group"
                                                                *ngIf="s && (s.keytype != 'radio' && s.keytype != 'checkbox')">
                                                                <label class="font-500">{{s?.labelname}}</label>
                                                                <input type="text" class="form-control form-control-sm"
                                                                    value="{{s?.value}}" placeholder="{{secVal.key}}"
                                                                    readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="!data.isDocFetched" class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input"
                                            [(ngModel)]="data.isverified" (change)="setVerifiedItem($event, i)"
                                            name="chk" id="chk{{i}}">
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="!data.isDocFetched" class="d-flex align-items-center">
                                        <div><label class="font-500">{{ data?.expiryDate | date: 'dd-MM-yyyy' }}</label>
                                        </div>
                                        <button type="button" class="btn btn-sm waves-effect waves-light btn-info"
                                            [disabled]="!data.isverified" (click)="openExpiryDate(i)"
                                            data-toggle="modal" data-target="#expiryDateModal">
                                            Add
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="data.labelname && !data.isDocFetched">
                                        <button type="button" class="btn btn-sm waves-effect waves-light btn-info"
                                            data-toggle="modal" data-target="#viewgrade" (click)="viewGradeVer(data)"
                                            id="sub">
                                            View
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="4"></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="form-group row">
                    <label class="col-12 col-md-4 col-form-label">eForm Status</label>
                    <div class="col-12 col-md-8">
                        <select class="custom-select">
                            <option selected>Select</option>
                            <option value="pending">Pending</option>
                            <option value="alive">Alive</option>
                            <option value="accept">Accept</option>
                            <option value="verify and accept">Verify and Accept</option>
                            <option value="createclient">Create Client </option>
                            <option value="update client field">Update Client </option>
                            <option value="return">Return</option>
                            <option value="reject">Reject</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="eformStatus">
                    <label class="d-block font-500">
                        <span *ngIf="eformStatus=='update client field'">Update Client:</span>
                        <span *ngIf="eformStatus!='update client field'">{{eformStatus}}:</span>
                    </label>
                    <p>{{StatusDescription[eformStatus]}}</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onSubmit()" class="btn waves-effect waves-light btn-info">
                    Submit
                </button>
                <button type="button" class="btn waves-effect waves-light btn-success" id="exportbtnpdf"
                    (click)="openMobilePdf()">
                    View As PDF
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- View Expiry Modal -->
<div class="modal" id="expiryDateModal" tabindex="-1" role="dialog" aria-labelledby="expiryDateModalModalLabel"
    aria-hidden="true" *ngIf="eformDataWithForExpiryDate">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="expiryDateModalModalLabel">Add Expiry Date</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="addExpiryDate" class="font-500">Expiry Date</label>
                    <p-calendar [(ngModel)]="addExpiryDate" [minDate]="minDate" name="addExpiryDate"
                        [readonlyInput]="true" inputId="min-max"></p-calendar>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info"
                    (click)="pushExpiryDateInFiedls()">Submit</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<!-- View Grade Modal -->
<div class="modal" id="viewgrade" tabindex="-1" role="dialog" aria-labelledby="viewgradeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewgradeModalLabel">View Grade for {{formname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="graderLoader">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div *ngIf="!graderLoader">
                    <label class="font-500">This field has the following grades:</label>
                    <div *ngFor="let data of fieldVerifierData?.data">
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'G'">Goverment</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'F'">Financial</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'T'">Telecom</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'A'">AKcess</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'O'">Other</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- View Grade Modal -->
<div class="modal" id="importBulk" tabindex="-1" role="dialog" aria-labelledby="importBulkModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="importBulkModalLabel">Import Bulk Clients</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" #fileInput id="importFile"
                            (change)="processDocument(fileInput)" name="xslFile"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                        <!--<label class="custom-file-label" for="importFile">Choose Document</label>-->
                        <label class="custom-file-label" for="importFile" *ngIf=!isFileChosen>Bulk Import</label>
                        <label class="custom-file-label" for="importFile" *ngIf=isFileChosen>{{fileName}}</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="downloadFile()">Download
                    Sample</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Edit Credit Modal -->
<div class="modal" id="creditScore" tabindex="-1" role="dialog" aria-labelledby="creditScoreModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="creditScoreModalLabel">Credit Score / BlackList Status</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="scoreInput" class="font-500">Score <span
                            class="{{ score < 50 ? 'text-danger' : 'text-success' }}">{{ score }}</span></label>
                    <input type="range" class="form-control-range" id="scoreInput" name="score" [(ngModel)]="score"
                        min="0" max="100" step="5">
                </div>
                <div class="form-group">
                    <label for="creditScoreInput" class="font-500">Credit Score <span
                            class="{{ creditScore < 50 ? 'text-danger' : 'text-success' }}">{{ creditScore
                            }}</span></label>
                    <input type="range" class="form-control-range" id="creditScoreInput" name="creditScore"
                        [(ngModel)]="creditScore" min="0" max="100" step="5">
                </div>
                <div class="form-row">
                    <div class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="blackListStatus" id="blackListStatusYes"
                                [(ngModel)]="blackListStatus" value="yes">
                            <label class="form-check-label font-500" for="blackListStatusYes">
                                Blacklist
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="blackListStatus" id="blackListStatusNo"
                                [(ngModel)]="blackListStatus" value="no">
                            <label class="form-check-label font-500" for="blackListStatusNo">
                                Good Client
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" id="close"
                    (click)="clientCreditScoreUpdate()" data-dismiss="modal">Update Score</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Credit Score check from another bank Modal-->
<div class="modal" id="creditScoreOtherBank" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Other's Bank Credit Score</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <form>
                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Select Bank</label>
                                <select class="form-control" [(ngModel)]="otherBankData" name="otherBankData"
                                    (ngModelChange)="otherBankClientData($event)" id="exampleFormControlSelect1">
                                    <option value="''" disabled>Choose the bank</option>
                                    <option [ngValue]="data" *ngFor="let data of bankList">{{ data?.name }}</option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" *ngIf="showTabel == 'allow'">
                        <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">AKcess Id</th>
                                    <th scope="col">Score</th>
                                    <th scope="col">Credit Score</th>
                                    <th scope="col">Blacklisted</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of otherBankClientList">
                                    <td>{{ data?.firstName | titlecase }} {{ data?.lastName | titlecase }}</td>
                                    <td>{{ data?.email }}</td>
                                    <td>{{ data?.akcessId }}</td>
                                    <td class="{{ (data && data.score < 50) ? 'text-danger' : 'text-success' }}">{{
                                        (data && data.score) ? data?.score : 0 }}</td>
                                    <td class="{{ (data && data.creditScore < 50) ? 'text-danger' : 'text-success' }}">
                                        {{ (data && data.creditScore) ? data?.creditScore : 0 }}</td>
                                    <td class="{{ (data && data.blackListStatus) ? 'text-danger' : 'text-success' }}">{{
                                        (data && data.blackListStatus) ? 'Y' : 'N' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12" *ngIf="showTabel == 'deny'">
                        <div class="alert alert-danger" role="alert">
                            Permission Denied!
                        </div>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- Pay By Face -->
<div class="modal" id="paybyface" tabindex="-1" role="dialog" aria-labelledby="paybyface" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Pay by face</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="form-group">
                        <label for="sourceid">Source Id</label>
                        <input type="text" class="form-control" id="sourceid" value="2" readonly>
                    </div>
                    <div class="form-group">
                        <label for="marketid">Market Id</label>
                        <input type="text" class="form-control" id="marketid" value="1" readonly>
                    </div>
                    <div class="form-group">
                        <label for="enrollmentguid">Enrollment Guid</label>
                        <input type="text" class="form-control" id="enrollmentguid">
                    </div>
                    <div class="form-group">
                        <label for="sourcetype">Source Type</label>
                        <input type="text" class="form-control" id="sourcetype" value="TECHVENTURES BANK" readonly>
                    </div>
                    <div class="form-group">
                        <label for="cashier_auth">Cashier Auth</label>
                        <input type="text" class="form-control" id="cashier_auth" value="dffg689gd96f" readonly>
                    </div>
                    <div class="form-group">
                        <label for="firstname">First Name</label>
                        <input type="text" class="form-control" id="firstname">
                    </div>
                    <div class="form-group">
                        <label for="lastname">Last Name</label>
                        <input type="text" class="form-control" id="lastname">
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" id="email">
                    </div>
                    <div class="form-group">
                        <label for="mobile">Mobile</label>
                        <input type="text" class="form-control" id="mobile">
                    </div>
                    <div class="form-group">
                        <label for="selfieImg">Selfie Img</label>
                        <input type="file" class="form-control" id="selfieImg">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary btn-sm">Save</button>
            </div>
        </div>
    </div>
</div>


<table id="xls_formfields">
    <tr>
        <th colspan="2">{{formname}}</th>
    </tr>
    <tr *ngFor="let data of fields; let i = index;">
        <th style="font-weight: bold;">
            {{data.key}}
        </th>
        <td
            *ngIf="data.keytype == 'text' || data.keytype == 'Text' || data.keytype == 'string' || data.keytype == 'list'">
            {{data.value}}
        </td>
        <td *ngIf="data.keytype == 'File' || data.keytype == 'file'">
            <a href="{{imageUrl}}{{data?.val?.docUrl}}" target="_blank"> Document {{i}}</a>
        </td>
    </tr>
</table>
<div id="pdf_formfields" style=" display: none;">
    <h3 style="text-align: center;">{{formname}}</h3>
    <p *ngFor="let data of fields; let i = index;">
        <span class="font-500">{{data.key}}: {{data.value}}</span><br>
    </p>

</div>

<!-- Send invitation modal -->
<div class="modal" id="sendInvitation" tabindex="-1" role="dialog" aria-labelledby="sendInvitationModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="sendInvitationModalLabel">Send Invitation</h4>
                <button (click)="resetform()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="eFormFields.length == 0" class="text-center mt-2">
                    <h6>No eForm Found.</h6>
                </div>
                <div *ngIf="eFormFields.length != 0">
                    <label class="font-500 m-0">Selected default eform:</label>
                    <hr class="mt-2 mb-2">
                    <div class="row">
                        <div class="col-8">
                            <span *ngIf="!isChangeEform" class="mt-1">{{formName}}</span>
                            <span *ngIf="isChangeEform">
                                <ng-select [items]="eformList" [hideSelected]="true" bindLabel="name"
                                    placeholder="Select User" [(ngModel)]="selectedFormName" appendTo="body"
                                    class="akcess-id-dropdown" (change)="geteformData()">
                                </ng-select>
                            </span>
                        </div>
                        <div class="col-4 text-center">
                            <i class="fas fa-pencil mr-3" (click)="editEform()"></i>
                            <i class="fas fa-eye mr-3" (click)="viewEform()"></i>
                            <i class="fas fa-exchange" (click)="changeDefaultForm()" *ngIf="!isChangeEform"></i>
                            <i class="fas fa-times" (click)="changeDefaultForm()" *ngIf="isChangeEform"></i>
                        </div>
                    </div>
                </div>
                <div class="invitation-type mt-3">
                    <label class="font-500 m-0">Send invitation via:</label>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="emaill" name="invitationType" class="with-gap radio-col-light-blue"
                            value="emaill" [checked]="isEmailInvitation" (change)="sendInvitationBy($event)">
                        <label for="emaill">Email</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="phoneNumber" name="invitationType" class="with-gap radio-col-light-blue"
                            value="phoneNumber" [checked]="isPhoneInvitation" (change)="sendInvitationBy($event)">
                        <label for="phoneNumber">Mobile</label>
                    </div>
                </div>
                <div *ngIf="isEmailInvitation">
                    <div>
                        <input type="email" id="emailInput" (keyup.enter)="addEmail($event)"
                            class="form-control pt-2 pb-2" [value]="emailValue">
                        <span class="valid-feedback">Type the Email address and press ENTER to validate</span>
                    </div>
                    <span *ngIf="emailList.length > 0" class="mt-2">
                        <div class="chip" *ngFor="let email of emailList;let i =index">
                            <div class="chip-content">{{email}}</div>
                            <div class="chip-close" (click)="removeData(i,'email')">
                                <svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                                    </path>
                                </svg>

                            </div>
                        </div>
                    </span>
                </div>
                <div *ngIf="isPhoneInvitation">
                    <form #f="ngForm" [formGroup]="phoneForm" id="send-invitation-mobile-frm">
                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [(ngModel)]="phoneNumber" (keyup.enter)="addNumber()" [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedKingdom"
                            [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true"
                            [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone">
                        </ngx-intl-tel-input>
                        <!-- <div class="invalid-feedback" *ngIf="isSubmitPhone && f.form.controls['phone'].invalid">
                            Please enter proper phone number
                        </div> -->
                    </form>
                    <span class="valid-feedback">Type the mobile number with the country code and press ENTER</span>
                    <span *ngIf="phoneNumberList.length > 0" class="mt-2">
                        <div class="chip" *ngFor="let phone of phoneNumberList;let i =index">
                            <div class="chip-content">+{{phone.countryCode}} {{phone.phone}}</div>
                            <div class="chip-close" (click)="removeData(i,'phone')">
                                <svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                                    </path>
                                </svg>

                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button class="btn btn-info" (click)="sendInvite()"
                        [disabled]="!isPhoneInvitation ? emailList.length == 0 : phoneNumberList.length == 0">Send</button>
                    &nbsp;
                    <button class="btn btn-danger" (click)="resetform()" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>