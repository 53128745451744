import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import * as XLSX from 'xlsx';
//import * as _ from 'lodash';
import * as _ from 'lodash-es';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/_services/common.service';

declare var $: any;

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    userlist: any = [];
    allUsers = [];
    cols: any[];
    domainName: string = "";
    first = 0;

    rows = 10;

    fields = [];
    fullname: string;
    adduserform: FormGroup;
    isSubmitted = false;
    currUserId: string;
    passcode: string;
    firstName: string;
    lastName: string;
    email: string;
    akcessId: string;
    act: string;
    phone: number;
    active_flg: Boolean;
    address: string;
    usertype: string;
    portalUser;
    scanstatus: boolean = false;
    qrcodeData: string = "string";
    akcessid_success: boolean = false;
    uuid: string;
    searchakcessid: string;
    id;
    clientStatus: string;
    clientProducts: string;
    USDAccountBalance: string;
    KDAccountBalance: string;
    eformresponse = [];
    verifiedItems: Array<String> = [];
    formname: any;
    eformStatus: string = "";
    imageUrl: string = environment.imgurl;
    pdfcolums = [{}];
    xls_fileName = 'ExcelSheet.xlsx';
    pdf_fileName = 'PDF.pdf';
    gradedata = [];
    search_usertype: string = '';
    search_userStatus: string = '';
    search_user: string = "";

    public Editor = ClassicEditor;
    public editorData = '';
    emails: String;

    invalidemails: String = '';
    phone_or_email: String = "email";
    placeholder: String = "Enter email addresses comma separated";
    portalTitle: String = '';
    portalFrontendURL: String = ''
    Status: any = ['Pending', 'Accept', 'Verify and Accept', 'Return', 'Reject'];

    userDataEmail: any = [];
    userDataPhone: any = [];

    //Pagination
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;

    constructor(private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private formBuilder: FormBuilder,
        private akcessService: GeneralServiceService,
        private http: HttpClient,
        public sanitizer: DomSanitizer,
        public commonService: CommonService) { }

    ngOnInit(): void {

        //this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        this.portalFrontendURL = this.commonService.portalDetails.portalDetails.portalFrontendURL
        this.portalTitle = this.commonService.portalDetails.portalDetails.portalTitle

        this.getuserData(true);
        this.filterControls();
        // this.cols = [
        //     { field: 'name', header: 'name' },
        //     { field: 'email', header: 'email' },
        //     { field: 'Phone', header: 'Phone' },
        //     { field: 'User Type', header: 'User Type' },
        //     { field: 'Active', header: 'Active' },
        //     { field: 'Action', header: 'Action' }
        // ];
        // this.spinner.hide();
        this.adduserform = this.formBuilder.group({
            _id: [null],
            searchakcessid: [null],
            firstName: ["", Validators.compose([Validators.required])],
            lastName: ["", Validators.compose([Validators.required])],
            //email:["",Validators.compose([Validators.required, Validators.email])],
            email: [""],
            //phone:["",Validators.compose([Validators.required, Validators.pattern("[0-9]*")])],
            phone: [""],
            active_flg: [null],
            address: [""],
            usertype: ["", Validators.compose([Validators.required])],
            clientStatus: [null],
            clientProducts: [null],
            USDAccountBalance: [null],
            KDAccountBalance: [null],
            akcessId: [this.portalUser.akcessId]
        })
    }
    get formControls() { return this.adduserform.controls; }

    getuserData(flag) {
        this.spinner.show();
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
            search_usertype: this.search_usertype,
            search_userStatus: this.search_userStatus,
            currentPage: this.currentPage,
            limit: this.pageSize
        }

        //if (this.search_user && this.search_user.length) {
        //    tmpdata["search_user"] = this.search_user;
        //}

        this.calldata.getuserlist(tmpdata).subscribe(data => {
            if (data['status'] === 1) {
                this.userlist = [];
                this.userlist = data['data'].reverse();
                this.userlist = this.userlist.reverse();
                this.allUsers = data['data'];
                this.allUsers = this.allUsers.reverse();
                this.totalCount = data['totalCount'];

                //For Searching
                if (this.search_user && this.search_user.length) {
                    this.userlist = this.allUsers.filter((user) => (user.firstName && user.firstName.toLowerCase().includes(this.search_user)) || (user.lastName && user.lastName.toLowerCase().includes(this.search_user)) || (user.email && user.email.toLowerCase().includes(this.search_user)) || (user.phone && user.phone.includes(this.search_user)) || (user.akcessId && user.akcessId.toLowerCase().includes(this.search_user.toLowerCase())));
                    this.totalCount = this.userlist.length;
                }
                this.spinner.hide();
            } else {
                this.toast.info(data['message']);
                this.spinner.hide();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        }
        )
    }

    filterControls() {
        this.domainName = sessionStorage.getItem('portalDomainName');
    }

    // search call for user table


    searchUsers(filterValue: string) {

        if (filterValue !== null) {

            filterValue = filterValue.toLowerCase();
            this.search_user = filterValue;

            //this.userlist = this.allUsers.filter((user) => (user.firstName && user.firstName.toLowerCase().includes(filterValue)) || (user.lastName && user.lastName.toLowerCase().includes(filterValue)) || (user.email && user.email.toLowerCase().includes(filterValue)) || (user.phone && user.phone.includes(filterValue)) || (user.akcessId && user.akcessId.toLowerCase().includes(filterValue.toLowerCase())));

            this.currentPage = 1;
            this.getuserData(true);

        } else {
            this.userlist = this.allUsers;
        }
    }

    //Pagination
    onProjectPageChange(event) {
        this.currentPage = event
        this.getuserData(true);
    }

    changePage(value) {
        this.pageSize = parseInt(value)
        this.getuserData(true);
    }

    addUserWithAkcess(noLoginTimer) {
        this.akcessService.getQrData().subscribe(data => {
            if (data['status'] === 1) {
                this.uuid = data['data'].webUUID;
                let n = JSON.stringify(data['data']);

                this.qrcodeData = n;
                if (noLoginTimer == 0) {
                    this.callscanusertimer();
                }

            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }
    callscanusertimer() {
        this.id = setInterval(() => { this.addusercheckService('0'); }, 3000);
    }
    getDataByAkcessId() {
        if (!this.searchakcessid || this.searchakcessid == '') {
            this.toast.error("Please enter AKcess id");
        } else {

            let isAkcessPresent = this.userlist.find((data) => {
                if (data.akcessId === this.searchakcessid) {
                    return true
                }
            });

            if (isAkcessPresent !== undefined && isAkcessPresent.removed_flg === true) {
                this.toast.warning("User Exist But Deleted! You Can Reactivate");
                this.searchakcessid = '';
            }
            else if (isAkcessPresent !== undefined && isAkcessPresent.removed_flg === false) {
                this.toast.warning("User Already Exist");
                this.searchakcessid = '';
            }
            else {
                // alert(this.searchakcessid)
                const aid = { 'aid': this.searchakcessid }
                this.akcessService.getDataByAkcessId(aid).subscribe(data => {
                    // alert(JSON.stringify(data))
                    if (data['status'] === 1) {
                        // this.uuid= data['data'].webUUID;
                        this.checkPortalLogin(data['data']);
                        this.searchakcessid = '';
                        clearInterval(this.id);
                        //$("#addform").modal("hide");
                    } else {
                        this.searchakcessid = '';
                        this.toast.error("User with this AKcess ID not available on AKcess Server");
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                });
            }
        }
    }
    addusercheckService(showtoast) {
        const uuidData = { uuid: this.uuid };
        // alert(this.uuid)
        this.akcessService.loginstatus(this.uuid).subscribe(data => {

            if (data['status'] === 1) {
                // alert('here')
                const tmpdata = {
                    'UserName': data['data'].UserName,
                    'email': data['data'].userEmail,
                    'phone': data['data'].userPhone,
                    'akcessId': data['data'].akcessId
                }
                this.checkPortalLogin(data['data']);
                clearInterval(this.id);
                // clearInterval(this.qid);

            } else {
                if (showtoast == 1) {
                    this.toast.error("User with this AKcess ID not available on AKcess Server");
                }

            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }
    checkPortalLogin(data) {
        // alert(data)
        this.spinner.show();
        data['add'] = true
        this.akcessService.portalLoginStatus(data).subscribe(data => {
            if (data["status"]) {
                // alert('here')
                if (data["message"] == 'Welcome') {
                    var msg = "User with this AKcess ID already exists";
                    $('#addform').modal('hide');
                    this.toast.success(msg);
                    this.resetform();
                    this.getuserData(false);
                    this.spinner.show();
                } else {
                    var msg = "User added successfully";
                    $('#addform').modal('hide');
                    this.toast.success(msg);
                    this.resetform();
                    this.getuserData(false);
                    this.spinner.show();
                }

            } else {
                this.spinner.hide();

                this.toast.info(data["message"]);
            }
            // alert('r '+r);

        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })

    }
    public findInvalidControls() {
        const invalid = [];
        const controls = this.adduserform.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }
    adduser() {
        // this.act = "add";
        this.isSubmitted = true;
        const tmpdate = moment().format();
        // alert(this.searchakcessid)
        // alert(this.findInvalidControls())
        if (this.adduserform.invalid) {
            //alert("invalid")
            //alert(this.findInvalidControls())

            return;
        } else {
            this.spinner.show();
            var formData = this.adduserform.value;
            if (formData._id == null) {
                this.calldata.adduser(formData).subscribe(data => {
                    // alert(data["status"])
                    if (data['status']) {
                        $('#addform').modal('hide');
                        this.resetform();
                        this.getuserData(false);
                        this.spinner.hide();
                    } else {
                        $('#addform').modal('hide');
                        this.toast.info(data['message']);
                        this.spinner.hide();
                        this.resetform();
                    }
                },
                    error => { this.toast.error('something went wrong'); this.spinner.hide(); })
            } else {
                this.calldata.edituser(formData).subscribe(data => {
                    if (data['status']) {
                        $('#addform').modal('hide');
                        this.resetform();
                        this.getuserData(false);
                        this.spinner.hide();
                    } else {
                        $('#addform').modal('hide');
                        this.toast.error(data['message']);
                        this.spinner.hide();
                        this.resetform();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                })
            }

            // this.calldata.adduser()
        }

    }
    view(val) {
        this.act = "view";
        this.fields = [];
        this.fullname = val.firstName + ' ' + val.lastName;
        this.email = val.email;
        this.phone = val.phone;
        this.address = val.address;
        this.usertype = val.usertype;
        this.active_flg = val.active_flg;
        this.akcessId = val.akcessId;
        this.active_flg = val.active_flg;
        this.akcessId = val.akcessId;
        this.clientStatus = val.clientStatus;
        this.clientProducts = val.clientProducts;
        this.USDAccountBalance = val.USDAccountBalance;
        this.KDAccountBalance = val.KDAccountBalance;
        // alert(JSON.stringify(this.fields))
    }
    //view(val) {
    //    this.act = "view";
    //    this.fields = [];
    //    this.fullname = this.userlist[val].firstName + ' ' + this.userlist[val].lastName;
    //    this.email = this.userlist[val].email;
    //    this.phone = this.userlist[val].phone;
    //    this.address = this.userlist[val].address;
    //    this.usertype = this.userlist[val].usertype;
    //    this.active_flg = this.userlist[val].active_flg;
    //    this.akcessId = this.userlist[val].akcessId;
    //    this.active_flg = this.userlist[val].active_flg;
    //    this.akcessId = this.userlist[val].akcessId;
    //    this.clientStatus = this.userlist[val].clientStatus;
    //    this.clientProducts = this.userlist[val].clientProducts;
    //    this.USDAccountBalance = this.userlist[val].USDAccountBalance;
    //    this.KDAccountBalance = this.userlist[val].KDAccountBalance;
    //    // alert(JSON.stringify(this.fields))
    //}
    openedituser(rowdata) {
        // alert(rowdata)
        this.act = "edit";
        // alert(rowdata.firstName)
        //$('#addform').modal('show')
        this.adduserform.patchValue(rowdata)
        this.usertype = rowdata.usertype;

    }
    deleteuser(id) {
        if (confirm("Are you sure you want to remove user?")) {
            this.spinner.show();
            const data = { '_id': id };
            this.calldata.deleteUsers(data).subscribe(data => {

                if (data['status'] === 1) {
                    this.getuserData(true);
                    this.toast.success(data['message']);

                } else {
                    this.toast.info(data['message']);
                    this.spinner.hide();
                }
            },
                error => { this.toast.error('something went wrong'); this.spinner.hide(); })
        }

    }
    reactiveUser(id) {
        if (confirm("Are you sure you want to reactive user?")) {
            this.spinner.show();
            const data = { '_id': id };
            this.calldata.reactiveUser(data).subscribe(data => {

                if (data['status'] === 1) {
                    this.getuserData(true);
                    this.toast.success(data['message']);

                } else {
                    this.toast.info(data['message']);
                    this.spinner.hide();
                }
            },
                error => { this.toast.error('something went wrong'); this.spinner.hide(); })
        }

    }
    setuserid(uid) {
        this.currUserId = uid;
    }
    changepasscode() {
        this.spinner.show();
        const data = { '_id': this.currUserId, 'passCode': this.passcode };
        this.calldata.changepasscode(data).subscribe(data => {

            if (data['status'] === 1) {

                this.toast.success(data['message']);
                this.spinner.hide();
                this.passcode = '';
                $('#close').click();
            } else {
                this.toast.info(data['message']);
                this.spinner.hide();
            }
        },
            error => { this.toast.error('something went wrong'); this.spinner.hide(); }
        )
    }
    resetform() {
        // alert("reset")
        // this.act = "";
        this.isSubmitted = false;
        this.adduserform.reset();
    }

    viewEformsResponses(akid) {
        this.spinner.show();
        // this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { 'akcessId': akid, 'usertype': 'Client' };

        this.calldata.fetchresponse(tmpdata).subscribe(data => {
            if (data['status'] === 1) {
                this.eformresponse = [];
                this.eformresponse = data['data'];
                this.spinner.hide();
            } else {
                this.toast.info(data['msg']);
                this.spinner.hide();
            }
        },
            error => { this.toast.error(error); this.spinner.hide(); }
        )
    }
    resetVerifiedItems(): void {
        // reset verified items
        this.verifiedItems = []
    }
    viewEformResponses(val, fullname, _id) {
        var fullname = fullname;
        this.fields = [];
        var a = '';
        this.formname = this.eformresponse[val].formName;
        var data = this.eformresponse[val].eformsFields;
        var udata = this.eformresponse[val].userdata;
        for (var i = 0; i < data.length; i++) {

            a = this.eformresponse[val][data[i].key];
            data[i].val = a;
            data['fullname'] = fullname;

            // add values into setVerifiedItem array
            if (data[i].isverified) {
                this.verifiedItems.push(data[i]._id);
                // data[i]["isverified"]=true;
                // $("chk"+_id).prop("checked",true);
            } else {
                // data[i]["isverified"]=false;
            }
        }
        data['eFormResponseId'] = _id;
        data['eformId'] = this.eformresponse[val].eformId;
        // data['eformStatus'] = this.eformresponse[val].status;
        this.eformStatus = this.eformresponse[val].status;
        this.fields = data;
    }
    onSubmit() {
        // alert(this.eformStatus)
        //lll
        let vdata = [];

        this.fields.forEach(field => {
            var isPresent = this.verifiedItems.some(function (el) { return el === field._id });
            if (isPresent) {
                vdata.push(field);
            }
        })
        if (!this.eformStatus) {
            this.toast.error("Please select status");
            return false;
        } else {
            // alert(JSON.stringify(this.form.value))
            this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
            let data = {
                eFormResponseId: this.fields['eFormResponseId'],
                fields_form: this.verifiedItems,
                fields_data: vdata,
                eformStatus: this.eformStatus,
                'akcessId': this.portalUser.akcessId
            }

            this.calldata.verifyfield(data).subscribe(data => {
                if (data['status'] === 1) {
                    this.getuserData(true);
                    this.toast.success(data['message']);
                    $('#viewform').modal('hide');
                } else {
                    this.toast.info(data['message']);
                    this.spinner.hide();
                }
            },
                error => { this.toast.error('something went wrong'); this.spinner.hide(); })
        }

    }
    exportexcel(formname, fullname): void {
        var date = new Date();
        var formattedDate = moment(date).format('YYYYMMDD');
        this.xls_fileName = formname + '_' + formattedDate + '_' + fullname + '.xlsx';
        /* table id is passed over here */
        let element = document.getElementById('xls_formfields');
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, this.xls_fileName);

    }
    generatePdf(formname, fullname) {
        var date = new Date();
        var formattedDate = moment(date).format('YYYYMMDD');
        this.pdf_fileName = formname + '_' + formattedDate + '_' + fullname + '.pdf';
        const documentDefinition = this.getDocumentDefinition();
        pdfMake.createPdf(documentDefinition).download(this.pdf_fileName);
    }
    getDocumentDefinition() {

        var html = htmlToPdfmake(document.getElementById('pdf_formfields').innerHTML);
        return {
            content: [

                html



            ],
            styles: {
                'html-strong': {
                    background: '' // it will add a yellow background to all <STRONG> elements
                }
            }
        };
    }
    setVerifiedItem(evt, i): void {
        let id = this.fields[i]._id;

        if (evt.target.checked) {
            this.verifiedItems.push(id)
        } else {
            this.verifiedItems.splice(this.verifiedItems.indexOf(id), 1)
        }
    }
    viewGradeVer(data) {
        // alert(JSON.stringify(data))
        this.gradedata["key"] = data.key;
    }

    next() {
        this.first = this.first + this.rows;
    }

    prev() {
        this.first = this.first - this.rows;
    }

    reset() {
        this.first = 0;
    }

    isLastPage(): boolean {
        return this.first === (this.userlist.length - this.rows);
    }

    isFirstPage(): boolean {
        return this.first === 0;
    }

    sendForm() {
        if (this.emails && this.editorData) {
            if ((this.phone_or_email == 'email' && this.checkEmails()) || (this.phone_or_email == 'phone' && this.checkPhones())) {
                // alert("here");
                // return;
                this.spinner.show();
                this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
                const tmpdata = { 'type': this.phone_or_email, 'emails': this.emails, 'sendername': this.portalUser.firstName + ' ' + this.portalUser.lastName, 'akcessId': this.portalUser.akcessId, 'msgbody': this.editorData };

                let firebaseWizard = {
                    "dynamicLinkInfo": {
                        "domainUriPrefix": "https://eforms.akcess.io",
                        "link": 'https://play.google.com/store/apps/details?id=com.akcess.io',
                        "androidInfo": {
                            "androidPackageName": "com.akcess.io"
                        },
                        "iosInfo": {
                            "iosBundleId": "com.akcess.io.ID"
                        }
                    }
                }

                this.calldata.getQRcodeURL(firebaseWizard).subscribe((res: any) => {
                    tmpdata['link'] = res.shortLink
                    this.akcessService.sendinvitation(tmpdata).subscribe(data => {
                        if (data['status'] === 1) {
                            this.toast.info(data['message']);
                            this.spinner.hide();
                            this.phone_or_email = 'email';
                            this.editorData = '';
                            this.invalidemails = '';
                            this.emails = '';
                            //$("#prepareformclose").click();
                            $('#prepareform').modal('hide');
                        } else {
                            this.toast.error(data['message']);
                            this.spinner.hide();
                            this.phone_or_email = 'email';
                            this.editorData = '';
                            this.invalidemails = '';
                            this.emails = '';
                            //$("#prepareformclose").click();
                            $('#prepareform').modal('hide');
                        }
                    },
                        error => { this.toast.error('something went wrong'); this.spinner.hide(); }
                    )
                });
            } else {
                // this.invalidemails='';
            }
        } else {
            if (!this.editorData)
                this.toast.error("Body field can't be empty");
            else
                this.toast.info('Enter valid values');

        }

    }
    checkEmail(email) {
        //var regExp = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{3})(\.[a-z]{2})*$)/i;
        var regExp = /(^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$)/g;

        return regExp.test(email);
    }
    checkEmails() {
        this.invalidemails = '';
        let temp_emails = this.emails.split(",");
        temp_emails = _.uniq(temp_emails);
        temp_emails = _.compact(temp_emails);
        this.emails = temp_emails.join(',')
        var emails = this.emails;
        var emailArray = emails.split(",");
        var tf = true;
        for (var i = 0; i <= (emailArray.length - 1); i++) {

            if (this.checkEmail(emailArray[i])) {
                //Do what ever with the email.

            } else {
                // this.invalidemails='';
                if (this.invalidemails == '') {
                    this.invalidemails = 'Invalid Emails: ';
                }
                this.invalidemails = this.invalidemails + " " + emailArray[i] + ", ";
                // alert("invalid email: " + emailArray[i]);
                // return false;
                var tf = false;
                // return tf;
            }

        }
        // if()
        return tf;
    }
    checkPhone(phone) {
        //var regExp = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{3})(\.[a-z]{2})*$)/i;
        //var regExp = /^(([0-9]+)(,(?=[0-9]))?)+$/g;
        //var regExp = /^\+\d{10,15}(,\+\d{10,15})*$/g;
        var regExp = /^\+?\d{10,15}(,\+?\d{10,15})*$/g;

        return regExp.test(phone);
    }
    checkPhones() {
        this.invalidemails = '';
        let temp_emails = this.emails.split(",");
        temp_emails = _.uniq(temp_emails);
        temp_emails = _.compact(temp_emails);
        this.emails = temp_emails.join(',');
        var phones = this.emails;
        var phoneArray = phones.split(",");
        for (var i = 0; i <= (phoneArray.length - 1); i++) {
            var tf = true;
            if (this.checkPhone(phoneArray[i])) {

            } else {
                if (this.invalidemails == '') {
                    this.invalidemails = 'Invalid Mobile Numbers: ';
                }
                this.invalidemails = this.invalidemails + " " + phoneArray[i] + ", ";
                var tf = false;
            }
        }
        return tf;
    }
    checkUserInput(temp_emails) {
        this.invalidemails = "";
        let temp_emails_beforeUniq = temp_emails.split(",");
        let temp_emails_afterUniq = _.uniq(temp_emails_beforeUniq);
        if (temp_emails_beforeUniq.length !== temp_emails_afterUniq.length) {
            if (this.phone_or_email == "phone") {
                this.invalidemails = "Invalid Input values(duplicate numbers entered)";
            } else {
                this.invalidemails = "Invalid Input values(duplicate Emails entered)";
            }
        }
    }
}
