import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import {
    FormControl,
    FormGroupDirective,
    FormBuilder,
    FormGroup,
    NgForm,
    Validators,
} from "@angular/forms";
import { JsonPipe } from "@angular/common";
import * as XLSX from "xlsx";
//import * as _ from "lodash";
import * as _ from "lodash-es";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/_services/common.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GenerateIdModalComponent } from "./generate-id-modal/generate-id-modal.component";
import { SendIdCardComponent } from "./send-id-card/send-id-card.component";
import { ViewSentIdCardComponent } from "./view-sent-id-card/view-sent-id-card.component";
import { ViewIdCardComponent } from "./view-id-card/view-id-card.component";
import { Router } from "@angular/router";
import { BlurEvent, ChangeEvent, CKEditor5 } from "@ckeditor/ckeditor5-angular";
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ViewInvitationListComponent } from "../../models/view-invitation-list/view-invitation-list.component";
import { ViewEformModalComponent } from "../eforms/view-eform-modal/view-eform-modal.component";
declare var $: any;

@Component({
    selector: "app-employees",
    templateUrl: "./employees.component.html",
    styleUrls: ["./employees.component.css"],
})
export class EmployeesComponent implements OnInit {
    defaultGender: String = "male";
    stateOptions: any;
    userlist: any = [];
    allUsers = [];
    cols: any[];

    first = 0;

    rows = 10;

    fields = [];
    // fullname:string;
    adduserform: FormGroup;
    adduserform2: FormGroup;
    isSubmitted = false;
    currUserId: string;
    passcode: string;
    firstName: string;
    lastName: string;
    email: string;
    akcessId: string;
    act: string;
    phone: number;
    active_flg: Boolean;
    address: string;
    usertype: string;
    portalUser;
    scanstatus: boolean = false;
    qrcodeData: string = "string";
    akcessid_success: boolean = false;
    uuid: string;
    searchakcessid: string;
    id;
    clientStatus: string;
    clientProducts: string;
    USDAccountBalance: string;
    KDAccountBalance: string;
    eformresponse = [];
    verifiedItems: Array<String> = [];
    formname: any;
    eformStatus: string = "";
    imageUrl: string = environment.imgurl;
    pdfcolums = [{}];
    xls_fileName = "ExcelSheet.xlsx";
    pdf_fileName = "PDF.pdf";
    gradedata = [];
    search_usertype: string = "";
    search_userStatus: string = "";
    eFormResposeFilterBy: any = "";
    public Editor = ClassicEditor;
    public editorData = "";
    emails: String;
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;
    invalidemails: String = "";
    phone_or_email: String = "email";
    placeholder: String = "Enter email addresses comma separated";
    Status: any = ["Pending", "Accept", "Verify and Accept", "Return", "Reject"];

    search_employees: string = '';
    singleEmployeeData: any = {};
    bsModalRef: BsModalRef;
    checkIDCardDetails: boolean = false;

    masterSelected: boolean;
    checkedList: any;

    countryList: any = [];

    isAkcessId: boolean = false;

    emailList: any = [];
    phoneNumberList: any = [];

    public model = {
        editorData: ''
    };

    constructor(
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private formBuilder: FormBuilder,
        private akcessService: GeneralServiceService,
        private http: HttpClient,
        public modalService: BsModalService,
        public sanitizer: DomSanitizer,
        public commonService: CommonService,
        public refreshContentService: RefreshContentService,
        private router: Router,
        public cdr: ChangeDetectorRef

    ) { }

    ngOnInit(): void {
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                // this.getuserData(true);
                this.getemployeeList();
                this.stateOptions = [
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                ];
                this.createAddEmployeeForm();
            } else {
                // this.getuserData(true);
                this.getemployeeList();
                this.stateOptions = [
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                ];
                this.createAddEmployeeForm();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
        this.getCountryList();
        this.masterSelected = false;
    }

    getemployeeList() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));

        const tmpdata = {
            'akcessId': this.portalUser.akcessId,
            'search_employees': this.search_employees,
            'currentPage': this.currentPage,
            'limit': this.pageSize
        };

        this.calldata.getemployeeList(tmpdata).subscribe(
            (res: any) => {
                if (res.status) {
                    this.userlist = res.data.employeeList.reverse();
                    this.allUsers = res.data.employeeList.reverse();
                    this.formName = res.data.eformdata.formName;
                    this.formId = res.data.eformdata.eformID;
                    this.userlist.forEach(el => {
                        el['isSelected'] = false;
                    });
                    this.allUsers.forEach(el => {
                        el['isSelected'] = false;
                    });
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //search call for employee table
    searchEmployee(searchValue: string) {
        this.currentPage = 1;
        this.search_employees = searchValue;
        this.getemployeeList();
    }

    createAddEmployeeForm() {
        this.adduserform = this.formBuilder.group({
            _id: [null],
            searchakcessid: [null],
            firstName: [""],
            lastName: [""],
            //email:["",Validators.compose([Validators.required, Validators.email])],
            email: [""],
            //email: ["", [Validators.required, Validators.email]],
            //phone: ["", Validators.compose([Validators.required, Validators.pattern("[0-9]*")])],
            //phone: ["", [Validators.required, Validators.pattern("^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$")]],
            phone: [""],
            akcessId: [null],
            address: [""],
            // haveAkcessId:[""]
            // clientStatus:[null],
            // clientProducts:[null],
            // USDAccountBalance:[null],
            // KDAccountBalance:[null],
            DOB: [null, Validators.required],
            placeOfBirth: [null, Validators.required],
            gender: ["", Validators.required],
            nationality: [""],
        });
    }

    get formControls() {
        return this.adduserform.controls;
    }

    // getuserData(flag){
    //   this.spinner.show();
    //   this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
    //   const tmpdata = {'akcessId':this.portalUser.akcessId, 'usertype':this.portalUser.usertype, isdomainname:this.commonService.selectedCompanyDeatils.domain,'search_usertype':this.search_usertype,search_userStatus: this.search_userStatus};

    //   this.calldata.getuserlist(tmpdata).subscribe(data => {
    //     //alert(JSON.stringify(data))
    //     if(data['status']===1){

    //       this.userlist = data['data'].reverse();
    //       this.allUsers=data['data'].reverse();
    //       this.spinner.hide();
    //       if(flag)
    //       this.toast.success(data['message']);
    //     }else{
    //       this.toast.info(data['message']);
    //       this.spinner.hide();
    //     }
    //    } ,
    //     error=>{ this.toast.error('something went wrong'); this.spinner.hide(); }
    //     )
    // }

    // search call for user table

    searchUsers(filterValue: string) {
        if (filterValue !== null) {
            filterValue = filterValue.toLowerCase();
            this.userlist = this.allUsers.filter(
                (user) =>
                    (user.firstName &&
                        user.firstName.toLowerCase().includes(filterValue)) ||
                    (user.lastName &&
                        user.lastName.toLowerCase().includes(filterValue)) ||
                    (user.email && user.email.toLowerCase().includes(filterValue)) ||
                    (user.phone && user.phone.includes(filterValue))
            );
        } else this.userlist = this.allUsers;
    }

    // addUserWithAkcess(noLoginTimer){
    //   this.akcessService.getQrData().subscribe(data => {
    //     if(data['status'] === 1) {
    //       this.uuid= data['data'].webUUID;
    //       let n = JSON.stringify(data['data']);

    //       this.qrcodeData = n;
    //       if(noLoginTimer==0){
    //         this.callscanusertimer();
    //       }

    //     }
    //   })
    // }

    callscanusertimer() {
        this.id = setInterval(() => {
            this.addusercheckService("0");
        }, 3000);
    }

    getDataByAkcessId() {
        if (!this.searchakcessid || this.searchakcessid == "") {
            this.toast.error("Please enter AKcess id");
        } else {
            const aid = { aid: this.searchakcessid };
            this.akcessService.getDataByAkcessId(aid).subscribe((data) => {
                if (data["status"] === 1) {
                    this.checkPortalLogin(data["data"]);
                    clearInterval(this.id);
                } else {
                    this.toast.error(
                        "User with this AKcess ID not available on AKcess Server"
                    );
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
        }
    }

    addusercheckService(showtoast) {
        const uuidData = { uuid: this.uuid };
        this.akcessService.loginstatus(this.uuid).subscribe((data) => {
            if (data["status"] === 1) {
                const tmpdata = {
                    UserName: data["data"].UserName,
                    email: data["data"].userEmail,
                    phone: data["data"].userPhone,
                    akcessId: data["data"].akcessId,
                };
                this.checkPortalLogin(data["data"]);
                clearInterval(this.id);
            } else {
                if (showtoast == 1) {
                    this.toast.error(
                        "User with this AKcess ID not available on AKcess Server"
                    );
                }
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
    }

    checkPortalLogin(data) {
        this.spinner.show();
        data["add"] = true;
        this.akcessService.portalLoginStatus(data).subscribe((data) => {
            if (data["status"]) {
                if (data["message"] == "Welcome") {
                    var msg = "User with this AKcess ID already exists";

                    $("#addform").modal("hide");
                    this.toast.success(msg);
                    this.resetform();
                    // this.getuserData(false);
                    this.spinner.show();
                } else {
                    var msg = "User added successfully";
                    $("#addform").modal("hide");
                    this.toast.success(msg);
                    this.resetform();
                    // this.getuserData(false);
                    this.spinner.show();
                }
            } else {
                this.spinner.hide();

                this.toast.info(data["message"]);
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.adduserform.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }
    // adduser(){
    //   this.isSubmitted = true;
    //   const tmpdate = moment().format();

    //   if(this.adduserform.invalid){
    //     return;
    //   }else{
    //     this.spinner.show();
    //     var formData = this.adduserform.value;
    //     formData.isdomainname = this.commonService.selectedCompanyDeatils.domain

    //     if(formData._id==null){

    //       this.calldata.adduser(formData).subscribe(data => {
    //         if(data['status']){
    //           $('#addEmployeeDetail').modal('hide');
    //           this.resetform();
    //           this.getuserData(false);
    //           this.spinner.show();
    //         } else {
    //           $('#addEmployeeDetail').modal('hide');
    //           this.toast.info(data['message']);
    //           this.spinner.hide();
    //           this.resetform();
    //         }
    //        },
    //         error=>{ this.toast.error('something went wrong'); this.spinner.hide(); })
    //     }else{
    //       alert('edit')
    //       this.calldata.edituser(formData).subscribe(data =>{
    //         // alert(data)
    //         if(data['status']){
    //           $('#addEmployeeDetail').modal('hide');
    //           this.resetform();
    //           this.getuserData(false);
    //           this.spinner.show();
    //         }else{
    //           $('#addEmployeeDetail').modal('hide');
    //           this.toast.info(data['message']);
    //           this.spinner.hide();
    //           this.resetform();
    //         }
    //       })
    //     }

    //     // this.calldata.adduser()
    //   }

    //  }

    addEmployee() {
        this.isSubmitted = true;
        const tmpdate = moment().format();

        if (this.adduserform.invalid) {
            return;
        } else {
            this.spinner.show();
            var formData = this.adduserform.value;
            // formData.isdomainname = this.commonService.selectedCompanyDeatils.domain

            if (formData._id == null) {
                this.calldata.addEmployee(formData).subscribe(
                    (res: any) => {
                        if (res.status) {
                            $("#addEmployeeDetail").modal("hide");
                            this.toast.success(`${res.data.firstName} , ${res.message}`);
                            this.resetform();
                            this.getemployeeList();
                            this.spinner.show();
                        } else {
                            $("#addEmployeeDetail").modal("hide");
                            this.toast.info(res.message);
                            this.spinner.hide();
                            this.resetform();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            } else {
                // alert("edit");
                this.calldata.editEmployee(formData).subscribe((res: any) => {
                    // alert(data)
                    if (res.status) {
                        $("#addEmployeeDetail").modal("hide");
                        this.spinner.show();
                        this.resetform();
                        this.toast.success(res.message);
                        this.getemployeeList();
                    } else {
                        $("#addEmployeeDetail").modal("hide");
                        this.spinner.hide();
                        this.resetform();
                        this.toast.warning(res.message);
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                });
            }

            // this.calldata.adduser()
        }
    }
    view(val) {
        this.act = "view";
        this.fields = [];
        this.singleEmployeeData = this.userlist[val];
        // this.fullname = this.userlist[val].firstName+' '+this.userlist[val].lastName;
        this.firstName = this.userlist[val].firstName;
        this.lastName = this.userlist[val].lastName;
        this.email = this.userlist[val].email;
        this.phone = this.userlist[val].phone;
        this.address = this.userlist[val].address;
        this.usertype = this.userlist[val].usertype;
        this.active_flg = this.userlist[val].active_flg;
        this.akcessId = this.userlist[val].akcessId;
        this.active_flg = this.userlist[val].active_flg;
        this.akcessId = this.userlist[val].akcessId;
        this.clientStatus = this.userlist[val].clientStatus;
        this.clientProducts = this.userlist[val].clientProducts;
        this.USDAccountBalance = this.userlist[val].USDAccountBalance;
        this.KDAccountBalance = this.userlist[val].KDAccountBalance;

        // alert(JSON.stringify(this.fields))
    }
    openedituser(rowdata) {
        // alert(rowdata)
        this.act = "edit";
        // alert(rowdata.firstName)
        this.adduserform.patchValue(rowdata);
        this.usertype = rowdata.usertype;
        $("#viewuser").modal("hide");
    }

    deleteEmployee(id) {
        if (confirm("Are you sure you want to remove user?")) {
            this.spinner.show();
            const data = { _id: id };
            this.calldata.deleteEmployee(data).subscribe(
                (res: any) => {
                    if (res.status) {
                        this.getemployeeList();
                        this.toast.success(res.message);
                    } else {
                        this.toast.info(res.message);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }
    reactiveUser(id) {
        if (confirm("Are you sure you want to reactive user?")) {
            this.spinner.show();
            const data = { _id: id };
            this.calldata.reactiveUser(data).subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        // this.getuserData(true);
                        this.toast.success(data["message"]);
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }
    setuserid(uid) {
        this.currUserId = uid;
    }
    changepasscode() {
        this.spinner.show();
        const data = { _id: this.currUserId, passCode: this.passcode };
        this.calldata.changepasscode(data).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.toast.success(data["message"]);
                    this.spinner.hide();
                    this.passcode = "";
                    $("#close").click();
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }
    resetform() {
        // this.act = "";
        this.isSubmitted = false;
        this.adduserform.reset();
        this.emailList = [];
        this.phoneNumberList = [];
        this.emailValue = "";
        this.phoneForm.controls.phone.setValue("");
        this.model.editorData = "";
        this.isEmailInvitation = false;
        this.isPhoneInvitation = false;
        this.isTemplate = false;
        this.modalService.hide()
    }

    viewEformsResponses(akid) {
        this.spinner.show();
        // this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { akcessId: akid, usertype: "employee" };

        this.calldata.fetchresponse(tmpdata).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.eformresponse = [];
                    this.eformresponse = data["data"];
                    this.spinner.hide();
                } else {
                    this.toast.info(data["msg"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }
    resetVerifiedItems(): void {
        // reset verified items
        this.verifiedItems = [];
    }
    viewEformResponses(val, firstName, _id) {
        var firstName = firstName;
        this.fields = [];
        var a = "";
        this.formname = this.eformresponse[val].formName;
        var data = this.eformresponse[val].eformsFields;
        var udata = this.eformresponse[val].userdata;
        for (var i = 0; i < data.length; i++) {
            a = this.eformresponse[val][data[i].key];
            data[i].val = a;
            data["firstName"] = firstName;

            // add values into setVerifiedItem array
            if (data[i].isverified) {
                this.verifiedItems.push(data[i]._id);
                // data[i]["isverified"]=true;
                // $("chk"+_id).prop("checked",true);
            } else {
                // data[i]["isverified"]=false;
            }
        }
        data["eFormResponseId"] = _id;
        data["eformId"] = this.eformresponse[val].eformId;
        // data['eformStatus'] = this.eformresponse[val].status;
        this.eformStatus = this.eformresponse[val].status;
        this.fields = data;
    }
    onSubmit() {
        // alert(this.eformStatus)
        //lll
        let vdata = [];

        this.fields.forEach((field) => {
            var isPresent = this.verifiedItems.some(function (el) {
                return el === field._id;
            });
            if (isPresent) {
                vdata.push(field);
            }
        });
        if (!this.eformStatus) {
            this.toast.error("Please select status");
            return false;
        } else {
            // alert(JSON.stringify(this.form.value))
            this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
            let data = {
                eFormResponseId: this.fields["eFormResponseId"],
                fields_form: this.verifiedItems,
                fields_data: vdata,
                eformStatus: this.eformStatus,
                akcessId: this.portalUser.akcessId,
            };

            this.calldata.verifyfield(data).subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        // this.getuserData(true);
                        this.toast.success(data["message"]);
                        $("#viewform").modal("hide");
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }
    exportexcel(formname, firstName): void {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.xls_fileName =
            formname + "_" + formattedDate + "_" + firstName + ".xlsx";
        /* table id is passed over here */
        let element = document.getElementById("xls_formfields");
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        XLSX.writeFile(wb, this.xls_fileName);
    }
    generatePdf(formname, firstName) {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.pdf_fileName =
            formname + "_" + formattedDate + "_" + firstName + ".pdf";
        const documentDefinition = this.getDocumentDefinition();
        pdfMake.createPdf(documentDefinition).download(this.pdf_fileName);
    }
    getDocumentDefinition() {
        var html = htmlToPdfmake(
            document.getElementById("pdf_formfields").innerHTML
        );
        return {
            content: [html],
            styles: {
                "html-strong": {
                    background: "", // it will add a yellow background to all <STRONG> elements
                },
            },
        };
    }
    setVerifiedItem(evt, i): void {
        let id = this.fields[i]._id;

        if (evt.target.checked) {
            this.verifiedItems.push(id);
        } else {
            this.verifiedItems.splice(this.verifiedItems.indexOf(id), 1);
        }
    }
    viewGradeVer(data) {
        // alert(JSON.stringify(data))
        this.gradedata["key"] = data.key;
    }

    next() {
        this.first = this.first + this.rows;
    }

    prev() {
        this.first = this.first - this.rows;
    }

    reset() {
        this.first = 0;
    }

    isLastPage(): boolean {
        return this.first === this.userlist.length - this.rows;
    }

    isFirstPage(): boolean {
        return this.first === 0;
    }

    sendForm() {
        if (this.emails && this.editorData) {
            if (
                (this.phone_or_email == "email" && this.checkEmails()) ||
                (this.phone_or_email == "phone" && this.checkPhones())
            ) {
                // alert("here");
                // return;
                this.spinner.show();
                this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
                const tmpdata = {
                    type: this.phone_or_email,
                    emails: this.emails,
                    sendername:
                        this.portalUser.firstName + " " + this.portalUser.lastName,
                    akcessId: this.portalUser.akcessId,
                    msgbody: this.editorData,
                };

                this.akcessService.sendinvitation(tmpdata).subscribe(
                    (data) => {
                        if (data["status"] === 1) {
                            this.toast.info(data["message"]);
                            this.spinner.hide();
                            this.phone_or_email = "email";
                            this.editorData = "";
                            this.invalidemails = "";
                            this.emails = "";
                            //$("#prepareformclose").click();
                            $("#prepareform").modal("hide");
                        } else {
                            this.toast.error(data["message"]);
                            this.spinner.hide();
                            this.phone_or_email = "email";
                            this.editorData = "";
                            this.invalidemails = "";
                            this.emails = "";
                            //$("#prepareformclose").click();
                            $("#prepareform").modal("hide");
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            } else {
                // this.invalidemails='';
            }
        } else {
            if (!this.editorData) this.toast.error("Body field can't be empty");
            else this.toast.info("Enter valid values");
        }
    }
    checkEmail(email) {
        //var regExp = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{3})(\.[a-z]{2})*$)/i;
        var regExp = /(^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$)/g;

        return regExp.test(email);
    }
    checkEmails() {
        this.invalidemails = "";
        let temp_emails = this.emails.split(",");
        temp_emails = _.uniq(temp_emails);
        temp_emails = _.compact(temp_emails);
        this.emails = temp_emails.join(",");
        var emails = this.emails;
        var emailArray = emails.split(",");
        var tf = true;
        for (var i = 0; i <= emailArray.length - 1; i++) {
            if (this.checkEmail(emailArray[i])) {
                //Do what ever with the email.
            } else {
                // this.invalidemails='';
                if (this.invalidemails == "") {
                    this.invalidemails = "Invalid Emails: ";
                }
                this.invalidemails = this.invalidemails + " " + emailArray[i] + ", ";
                // alert("invalid email: " + emailArray[i]);
                // return false;
                var tf = false;
                // return tf;
            }
        }
        // if()
        return tf;
    }
    checkPhone(phone) {
        //var regExp = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{3})(\.[a-z]{2})*$)/i;
        //var regExp = /^(([0-9]+)(,(?=[0-9]))?)+$/g;
        //var regExp = /^\+\d{10,15}(,\+\d{10,15})*$/g;
        var regExp = /^\+?\d{10,15}(,\+?\d{10,15})*$/g;

        return regExp.test(phone);
    }
    checkPhones() {
        this.invalidemails = "";
        let temp_emails = this.emails.split(",");
        temp_emails = _.uniq(temp_emails);
        temp_emails = _.compact(temp_emails);
        this.emails = temp_emails.join(",");
        var phones = this.emails;
        var phoneArray = phones.split(",");
        for (var i = 0; i <= phoneArray.length - 1; i++) {
            var tf = true;
            if (this.checkPhone(phoneArray[i])) {
            } else {
                if (this.invalidemails == "") {
                    this.invalidemails = "Invalid Mobile Number: ";
                }
                this.invalidemails = this.invalidemails + " " + phoneArray[i] + ", ";
                var tf = false;
            }
        }
        return tf;
    }
    checkUserInput(temp_emails) {
        this.invalidemails = "";
        let temp_emails_beforeUniq = temp_emails.split(",");
        let temp_emails_afterUniq = _.uniq(temp_emails_beforeUniq);
        if (temp_emails_beforeUniq.length !== temp_emails_afterUniq.length) {
            if (this.phone_or_email == "phone") {
                this.invalidemails = "Invalid Input values(duplicate numbers entered)";
            } else {
                this.invalidemails = "Invalid Input values(duplicate Emails entered)";
            }
        }
    }
    onProjectPageChange(event) {
        this.currentPage = event;
        // this.getuserData(true);
        this.getemployeeList();
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        this.currentPage = 1;
        // this.getuserData(true);
        this.getemployeeList();
    }

    somethingSelected: boolean = false;
    selectedList: any[] = [];

    // The master checkbox will check/ uncheck all items
    checkUncheckAll(e) {
        if (e.target.checked == true) {
            this.selectedList.push(e.target.checked)
        } else if (e.target.checked == false) {
            if (this.selectedList.length > 0) {
                this.selectedList.splice(0, 1);
            }
        }
        if (this.selectedList.length === 0) {
            this.somethingSelected = false;
        } else {
            this.somethingSelected = true;
        }
        for (var i = 0; i < this.userlist.length; i++) {
            this.userlist[i].isSelected = this.masterSelected;
        }
    }

    // Check All Checkbox Checked
    isAllSelected(e) {
        this.somethingSelected = e.target.checked;
        this.masterSelected = this.userlist.every(function (item: any) {
            return item.isSelected == true;
        });
    }

    deleteRow() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));
        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data: any = {
                    component: "employee",
                    _id: id
                };

                this.userlist.filter((e: any) => {
                    if (e._id == id) {
                        data.akcessId = e.akcessId
                    }
                })
                this.calldata.softDelete(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getemployeeList();
                            this.toast.success("Employees " + res.message);
                            this.spinner.hide();
                        } else {
                            this.toast.info("Employees " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select an employee");
            this.spinner.hide();
        }

    }

    checkGenerateIdModal() {
        this.singleEmployeeData;
        if (
            this.singleEmployeeData.akcessId &&
            this.singleEmployeeData.email &&
            this.singleEmployeeData.phone &&
            this.singleEmployeeData.usertype
        ) {
            return false;
        } else {
            return true;
        }
    }

    openGenerateIdModal(data) {
        const initialState: any = {
            singleEmployeeDataModal: data,
        };
        $("#viewuser").modal("hide");
        this.bsModalRef = this.modalService.show(GenerateIdModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg",
        });
    }

    openSendIdCardModal(data) {
        const initialState: any = {
            sendsingleEmployeeDataModal: data,
        };

        this.bsModalRef = this.modalService.show(SendIdCardComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered",
        });
    }

    openViewSendModal(cardID) {
        const initialState: any = {
            cardId: cardID
        };
        this.bsModalRef = this.modalService.show(ViewSentIdCardComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-dialog-scrollable modal-lg",
        });
    }

    openViewEmpIdCard(fileUrl) {

        const initialState: any = {
            filePath: fileUrl,
        };

        this.bsModalRef = this.modalService.show(ViewIdCardComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-dialog-scrollable",
        });
    }

    viewRecycle() {
        this.router.navigate(["/panel/employees/deleted"]);
    }

    getToggleValue(e: any) {
        if (e.target.checked) {
            this.adduserform.controls.akcessId.enable();
            this.isAkcessId = true;
        } else {
            this.isAkcessId = false;
            this.adduserform.controls.akcessId.disable();
        }
    }

    sendSMSToClient(mobileNumber) {
        // let eformName = environment.eformname
        // let eformID = environment.eformid
        // if (this.commonService.portalDetails.portalDetails.portalDomainName === "bank.akcess.dev") {
        //     eformID = "60019a5c9303bc1cbd1608a8"
        // }
        let eformNameA = this.formName.replace(/\s+/g, "");
        let eformurl = environment.imgurl + `redirect/${eformNameA}/${this.formId}/${this.dbName}`

        this.calldata.sendInvitationBySMS(mobileNumber, eformurl).subscribe((res: any) => {
            if (res.status == 1) {
                this.toast.success('Invitation sent')
            } else {
                this.toast.error('Something went wrong while send sms')
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
        // })
    }

    sendEmailToClient(email) {
        // let eformName = environment.eformname
        // let eformID = environment.eformid
        let eformNameA = this.formName.replace(/\s+/g, "");
        let eformurl = environment.imgurl + `redirect/${eformNameA}/${this.formId}/${this.dbName}`

        this.calldata.sendInvitation(email, eformurl).subscribe((res: any) => {
            if (res.status == 1) {
                this.toast.success('Invitation sent')
            } else {
                this.toast.error('Something went wrong while send sms')
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
        // })
    }

    sendIndividualInvitation(value, type) {
        this.spinner.show();
        let eformNameA = this.formName.replace(/\s+/g, "");
        let eformurl = environment.imgurl + `redirect/${eformNameA}/${this.formId}/${this.dbName}`
        let data: any = {
            link: eformurl,
            userType: "staff"
        }
        if (type == 'email') {
            let emailList = [];
            emailList.push(value.email)
            data.emails = emailList
        } else if (type == 'sms') {
            let smsList = [];
            let phoneData = {
                countryCode: value.countryCode,
                phone: value.phone
            }
            smsList.push(phoneData);
            data.phones = smsList
        }
        if (this.formName && this.formId) {
            this.akcessService.sendInvitationToUser(data).subscribe((res: any) => {
                if (res.status) {
                    this.spinner.hide();
                    this.toast.success(res.message);
                } else {
                    this.spinner.hide();
                    this.toast.error(res.message);
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            })
        } else {
            this.toast.warning("Please create one eform first to send invitation.");
            this.spinner.hide();
            return
        }

    }
    isEmailInvitation: boolean = false;
    isPhoneInvitation: boolean = false;

    sendInvitationBy(e: any) {
        if (e.target.value == 'email') {
            this.isEmailInvitation = true;
            this.isPhoneInvitation = false;
        } else if (e.target.value == 'phoneNumber') {
            this.isEmailInvitation = false;
            this.isPhoneInvitation = true;
        }
        this.emailList = [];
        this.phoneNumberList = [];
        this.emailValue = "";
        this.phoneForm.controls.phone.setValue("");
        this.model.editorData = "";
    }

    public componentEvents: string[] = [];
    onReady(editor: CKEditor5.Editor): void {
        this.componentEvents.push('The editor is ready.');
    }

    onChange(event: ChangeEvent): void {
        this.componentEvents.push('Editor model changed.');
        this.editorData = event.editor.getData();
    }

    onFocus(event: FocusEvent): void {
        this.componentEvents.push('Focused the editing view.');
    }

    onBlur(event: BlurEvent): void {
        this.componentEvents.push('Blurred the editing view.');
    }

    getCountryList() {
        this.http.get('/assets/country-list.json').subscribe((res: any) => {
            res.filter((e) => {
                this.countryList.push(e.label)
            })
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Something went wrong");
        })
    }

    uploadProfileImage: any = "../../../../assets/new-images/users/default-profile.svg";

    base64textString = [];

    onUploadChange(evt: any) {
        const file = evt.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleReaderLoaded(e) {
        this.uploadProfileImage = 'data:image/png;base64,' + btoa(e.target.result);
    }

    addEditEmployee(value: any, empData: any) {
        if (value == 'add') {
            this.router.navigate(["/panel/employees/add"])
        } else if (value == 'view') {
            this.router.navigate(['panel/employees/edit'], { state: { userData: empData } })
        }
    }

    emailValue = null;
    addEmail(e) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (e.target.value.match(validRegex)) {
            this.emailList.push(e.target.value);
            e.target.value = null;
        } else {
            this.toast.warning(e.target.value + " is not valid email address");
        }
        this.cdr.detectChanges();
    }

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required])
    });

    changePreferredCountries() {
        this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    }

    phoneNumber: any;
    addNumber() {
        if (this.phoneForm.valid) {
            let value = this.phoneForm.controls.phone.value
            let code = value.dialCode.split('+');
            let phone = value.e164Number.split(value.dialCode);
            let data = {
                countryCode: code[1],
                phone: phone[1]
            }
            this.phoneNumberList.push(data);
            this.phoneForm.reset();
        } else {
            this.toast.warning("Enter proper phone number");
        }
    }

    removeData(i, type) {
        if (type == 'email') {
            this.emailList.splice(i, 1);
        } else if (type == 'phone') {
            this.phoneNumberList.splice(i, 1)
        }
    }

    sendInvite() {
        this.spinner.show();
        let eformNameA = this.formName.replace(/\s+/g, "");
        let eformurl = environment.imgurl + `redirect/${eformNameA}/${this.formId}/${this.dbName}`
        let data: any = {
            link: eformurl,
            userType: "staff"
        }
        if (this.isEmailInvitation) {
            data.emails = this.emailList;
        } else if (this.isPhoneInvitation) {
            data.phones = this.phoneNumberList;
        }

        if (this.formId == "") {
            this.toast.warning("Please create one eform first to send invitation.");
            this.spinner.hide();
            return
        }
        this.akcessService.sendInvitationToUser(data).subscribe((res: any) => {
            if (res.status) {
                this.spinner.hide();
                this.toast.success(res.message);
            } else {
                this.spinner.hide();
                this.toast.error(res.message);
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })

        this.resetform();

    }

    eFormFields: any = ""
    formName: any = "";
    formId: any = "";
    dbName: any = this.commonService.selectedCompanyDeatils.dbName;
    eformList: any = [];
    selectedFormName: any = "";

    getDefaultInvitation() {
        this.spinner.show();
        let data = {
            isEmployee: true
        }
        this.calldata.getDefaultInvitation(data).subscribe((res: any) => {
            if (res.status) {
                this.spinner.hide();
                if (res.data.defaultEform) {
                    this.eFormFields = res.data.defaultEform.fields;
                }

                if (res.data.eformList) {
                    this.eformList = res.data.eformList;
                }

                this.modifyFields(this.castFields())
                this.formName = res.data.defaultEform.formName;
                this.formId = res.data.defaultEform._id;
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    geteformData() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            search_formName: this.selectedFormName ? this.selectedFormName : "",
            currentPage: 1,
            limit: 1,
        };

        this.calldata.geteformdata(tmpdata).subscribe(
            (res: any) => {
                if (res.status) {
                    this.eFormFields = res.data.results[0].fields;
                    this.formName = res.data.results[0].formName;
                    this.formId = res.data.results[0]._id;
                    this.description = res.data.results[0].description;
                    this.isChangeEform = false;
                    this.num = 1;
                    // this.eformdata = [];
                    // this.eformdata = res.data.results;
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    isTemplate: boolean = false;
    showTemplate(val) {
        if (val == 'show') {
            this.isTemplate = true;
        } else if (val == 'hide') {
            this.isTemplate = false;
        }
    }
    // eFormFields = [];

    modifyFields(fields) {
        let deleteIndexes = fields.deletedIndex.map(index => parseInt(index))

        // delete the index at given position
        let gfields = this.eFormFields.filter((field, index) => {
            return !deleteIndexes.includes(index)
        })

        this.eFormFields = gfields
        // append section field data
        for (let field of Object.values(fields.sectionFieldData))
            this.eFormFields.push(field)
        return
    }

    //Sorting Sections and Normal Fields
    castFields() {
        let sectionFieldData = {};
        let deletedIndex = [];

        for (let section in this.eFormFields) {
            if (this.eFormFields[section].section_lable) {
                deletedIndex.push(section)
                // check if section-label already exists
                let status = sectionFieldData[this.eFormFields[section].section_lable] ? true : false;

                let sectionfields = {
                    "options": this.eFormFields[section].options,
                    "_id": this.eFormFields[section]._id,
                    "labelname": this.eFormFields[section].labelname,
                    "key": this.eFormFields[section].key,
                    "keytype": (this.eFormFields[section] && this.eFormFields[section].keytype) ? this.eFormFields[section].keytype : null,
                    "signature_required": this.eFormFields[section].signature_required,
                    "file_verified": this.eFormFields[section].file_verified,
                    "verification_grade": this.eFormFields[section].verification_grade
                }

                if (status) {
                    //sectionFieldData[fields[section].section_lable].sectionfields.push(sectionfields)

                    if (sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title]) {

                        sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title].push(sectionfields)
                    } else {
                        sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title] = [sectionfields]
                    }

                } else {
                    sectionFieldData[this.eFormFields[section].section_lable] = {
                        labelname: this.eFormFields[section].section_lable,
                        key: this.eFormFields[section].section_lable.replace(/\s/g, '').toLowerCase(),
                        //keytype: (this.fields[section] && this.fields[section].section_type) ? this.fields[section].section_type : this.section_keytype,
                        section_color: "#cdc0c0",
                        "section_id": "Yes",
                        sectionfields: {}
                    }

                    sectionFieldData[this.eFormFields[section].section_lable].sectionfields[this.eFormFields[section].section_title] = [sectionfields]
                }

            }

        }
        // add options if section-fields exists
        for (let sect in sectionFieldData) {
            if (sectionFieldData[sect].sectionfields) {
                sectionFieldData[sect].options = []
                for (let field of Object.keys(sectionFieldData[sect].sectionfields))
                    sectionFieldData[sect].options.push({
                        checked: false,
                        key: (field.replace(/\s/, '')).toLowerCase(),
                        uid: Math.random().toString(16).substring(3),
                        lable: field
                    })
            }
        }
        return {
            sectionFieldData: sectionFieldData,
            deletedIndex: deletedIndex
        }
    }

    viewSentModel() {
        const initialState: any = {
            userType: "Staff"
        };
        this.modalService.show(ViewInvitationListComponent, { initialState, class: 'modal-dialog-centered modal-exlg' })
    }

    editEform() {
        $('#sendInvitation').modal('hide');
        this.router.navigate(["/panel/addeform/" + this.formId + "/" + this.formName + ""], { state: { example: 'employees' } })
    }

    isChangeEform: boolean = false;
    num = 1
    changeDefaultForm() {
        if (this.num % 2 == 0) {
            this.isChangeEform = false;
            this.num++;
        } else {
            this.isChangeEform = true;
            this.num++;
        }
    }

    eformurl: string;
    scaneformid: string;
    description: string;
    redirectURL: any;

    viewEform() {

        this.fields = [];
        this.scaneformid = this.formId;
        this.formname = this.formName;
        this.description = this.description;
        this.fields = this.eFormFields;

        this.fields = this.fields.reduce((acc, row) => {
            if (row.sectionfields) {
                const data = row.options.reduce((op, l) => {
                    const option = row.sectionfields[l.lable]
                        ? row.sectionfields[l.lable].reduce((fields, section) => {
                            fields.push({
                                ...section,
                                section_lable: row.labelname,
                                section_color: row.section_color,
                                section_title: l.lable,
                                section_type: row.keytype,
                            });
                            return fields;
                        }, [])
                        : [];
                    return op.concat(option);
                }, []);
                return acc.concat(data);
            } else {
                acc.push(row);
            }
            return acc;
        }, []);

        let eformName = this.formName.replace(/\s+/g, "");
        this.eformurl =
            `https://sme.akcess.app/eform/` +
            eformName +
            "/" +
            this.formId +
            "/" +
            this.dbName;
        this.redirectURL =
            `https://sme.akcess.app/api/redirect/` +
            eformName +
            "/" +
            this.formId +
            "/" +
            this.dbName;


        const initialState: any = {
            eFormFields: this.fields,
            eFormId: this.formId,
            formName: this.formName,
            eFormDescription: this.description,
            eFormUrl: this.eformurl,
            eFormRedirectURL: this.redirectURL,
            componentType: "employees"
        };
        // $('#sendInvitation').modal('hide');
        this.bsModalRef = this.modalService.show(ViewEformModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg",
        });
    }
}

