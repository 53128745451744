import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";
import { DataservicesService } from "src/app/_services/dataservices.service";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50, 100];
    pageSize: any = 10;
    totalPages: any = 0;
    search_notification: any;
    notificationList: any = [];
    orderByFilter: any = "nto";

    constructor(
        private calldata: DataservicesService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService
    ) {}

    ngOnInit() {
        this.getNotifications();
    }
    markasread(e) {
        this.notificationList[e].read = true;
    }
    getNotifications() {
        this.spinner.show();
        let tmpdata: any = {
            page: this.currentPage,
            limit: this.pageSize,
        };

        if (this.search_notification) {
            tmpdata.search = this.search_notification;
        }
        this.calldata.getNotifications(tmpdata).subscribe(
            (response: any) => {
                if (response.status) {
                    this.notificationList = response.data.docs;
                    this.totalCount = response.data.totalDocs;
                    this.totalPages = response.data.totalPages;
                    this.spinner.hide();
                } else {
                    this.toast.info(response.message);
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    onProjectPageChange(event) {
        this.currentPage = event;
        this.getNotifications();
    }

    changePageSize(event) {
        this.currentPage = 1;
        this.getNotifications();
    }

    dislayDate(date) {
        let filter_date = moment(date).format("DD-MMM-YYYY");

        return filter_date;
    }

    searchNotification(searchValue: string) {
        this.currentPage = 1;
        this.search_notification = searchValue;
        this.getNotifications();
    }
}
