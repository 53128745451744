<div class="update-fields">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Update Fields</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Update Fields</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" hover="true" small="true">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>AKcess Id</th>
                                    <th>Key</th>
                                    <th>Received Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="updateFieldsList.length == 0">
                                <tr>
                                    <td colspan="5" class="text-center">No Records Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="updateFieldsList?.length > 0">
                                <tr *ngFor="let data of updateFieldsList | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount };let i = index;">
                                    <td>
                                        <a class="d-inline-block" (click)="view(data)" data-toggle="modal" data-target="#viewUpdateField">
                                            {{ data?.users[0]?.firstName | titlecase }} {{ data?.users[0]?.lastName | titlecase }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="view(data)" data-toggle="modal" data-target="#viewUpdateField">
                                            {{ data?.akcessId }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="view(data)" data-toggle="modal" data-target="#viewUpdateField">
                                            {{ data?.key }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="view(data)" data-toggle="modal" data-target="#viewUpdateField">
                                            {{ data?.createdAt | date:'mediumDate' }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="view(data)" data-toggle="modal" data-target="#viewUpdateField">
                                            <div *ngIf="!data.status_updated_on" class="text-center">-</div>
                                            <div *ngIf="data.status_updated_on" class="text-center">
                                                {{ data?.status_updated_on | date:'mediumDate' }}
                                            </div>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="5">
                                        <div class="table-page" *ngIf="updateFieldsList?.length > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                     class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                                     nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value)" class="form-control form-control-sm"
                                                        id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<div class="modal" id="viewUpdateField" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Update</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    Update client request from <span>{{userName}}({{userAkcessId}})</span>
                </p>
                <div class="form-group">
                    <label class="font-500">{{key}}</label>
                    <input type="text" class="form-control" value="{{fieldValue}}" readonly>
                </div>
                <p *ngIf="resp_status!=''">
                    Client already updated on {{resp_date | date: 'dd-MM-yyyy hh:mm a'}}
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="updateField()">Update Client</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>