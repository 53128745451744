import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
//data.userdetails[0].fields

import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import * as XLSX from 'xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-eformssent',
  templateUrl: './eformssent.component.html',
  styleUrls: ['./eformssent.component.css']
})
export class EformssentComponent implements OnInit {
  eformsents = [];
  fields = [];
  formname: any;
  imageUrl: string = environment.imgurl;
  portalUser;
  pdfcolums = [{}];
  xls_fileName = 'ExcelSheet.xlsx';
  pdf_fileName = 'PDF.pdf';
  eformId: string = "";
  constructor(private gs: GeneralServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private calldata: DataservicesService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.eformId = this.route.snapshot.paramMap.get('eformId');
    this.geteformsent();
  }
  geteformsent() {
    // alert(this.eformId)
    this.spinner.show();
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
    const tmpdata = { 'akcessId': this.portalUser.akcessId, 'usertype': this.portalUser.usertype };
    if (this.eformId != null) {
      tmpdata['eformId'] = this.eformId;
    }
    // alert(JSON.stringify(tmpdata))
    this.calldata.fetcheformsents(tmpdata).subscribe(data => {
      if (data['status'] === 1) {
        this.eformsents = [];
        this.eformsents = data['results'];
        this.spinner.hide();
      } else {
        this.toast.info(data['msg']);
        this.spinner.hide();
      }
    },
      error => { this.toast.error('something went wrong'); this.spinner.hide(); }
    )
  }
  view(val, fullname) {
    var fullname = fullname;
    this.fields = [];
    var a = '';
    this.formname = this.eformsents[val].formName;
    var data = this.eformsents[val].fields;
    for (var i = 0; i < data.length; i++) {

      a = this.eformsents[val][data[i].key];
      data[i].val = a;
      data['fullname'] = fullname;
    }
    this.fields = data;
  }
  exportexcel(formname, fullname): void {
    var date = new Date();
    var formattedDate = moment(date).format('YYYYMMDD');
    this.xls_fileName = formname + '_' + formattedDate + '_' + fullname + '.xlsx';
    /* table id is passed over here */
    let element = document.getElementById('xls_formfields');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.xls_fileName);

  }
  generatePdf(formname, fullname) {
    var date = new Date();
    var formattedDate = moment(date).format('YYYYMMDD');
    this.pdf_fileName = formname + '_' + formattedDate + '_' + fullname + '.pdf';
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download(this.pdf_fileName);
  }
  getDocumentDefinition() {

    var html = htmlToPdfmake(document.getElementById('pdf_formfields').innerHTML);
    return {
      content: [

        html



      ],
      styles: {
        'html-strong': {
          background: '' // it will add a yellow background to all <STRONG> elements
        }
      }
    };
  }
}
