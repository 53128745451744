<section class="section-bg-grey">
    <div class="container pt-5 pb-3">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col">
                <div class="logo-container mb-4 text-center">
                    <img
                        *ngIf="propertyCompany.logo"
                        src="{{ propertyCompany.logo }}"
                        class="img-fluid"
                        width="160"
                        alt="propertyCompany.name"
                    />
                    <img
                        *ngIf="!propertyCompany.logo"
                        src="https://akcess.io/wp-content/themes/akcess/assets/img/logo.png"
                        class="img-fluid"
                        width="160"
                        alt="AKcess"
                    />
                </div>
                <div class="card card-registration my-4">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card-body p-md-5 text-black">
                                <form
                                    #piForm="ngForm"
                                    [formGroup]="propertyInquiryFG"
                                    class="property-inquiry-form"
                                >
                                    <h3 class="text-uppercase">
                                        Property Enquiry
                                    </h3>
                                    <p class="mt-3 mb-3">
                                        <strong>Property Name:</strong>
                                        {{ property?.name }}
                                    </p>
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label
                                                    class="form-label required"
                                                    for="firstName"
                                                    >First Name</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="firstName"
                                                    formControlName="firstName"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    *ngIf="
                                                        isSubmitted &&
                                                        propertyInquiryFG
                                                            .controls.firstName
                                                            .errors
                                                    "
                                                >
                                                    First Name is required.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label
                                                    class="form-label required"
                                                    for="lastName"
                                                    >Family Name</label
                                                >
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="lastName"
                                                    formControlName="lastName"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    *ngIf="
                                                        isSubmitted &&
                                                        propertyInquiryFG
                                                            .controls.lastName
                                                            .errors
                                                    "
                                                >
                                                    Last Name is required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="form-group">
                                                <label
                                                    class="form-label required"
                                                    for="email"
                                                    >Email</label
                                                >
                                                <input
                                                    type="email"
                                                    class="form-control"
                                                    id="email"
                                                    formControlName="email"
                                                />
                                                <div
                                                    class="invalid-feedback"
                                                    *ngIf="
                                                        isSubmitted &&
                                                        propertyInquiryFG
                                                            .controls.email
                                                            .errors
                                                    "
                                                >
                                                    Please enter valid email.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-xl-6">
                                            <label for="mobile"
                                                >Mobile<span
                                                    class="required-label"
                                                    >*</span
                                                ></label
                                            >
                                            <ngx-intl-tel-input
                                                [cssClass]="'custom'"
                                                [preferredCountries]="
                                                    preferredCountries
                                                "
                                                [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [searchCountryField]="[
                                                    SearchCountryField.Iso2,
                                                    SearchCountryField.Name
                                                ]"
                                                [selectFirstCountry]="false"
                                                [selectedCountryISO]="
                                                    CountryISO.UnitedKingdom
                                                "
                                                [maxLength]="15"
                                                [phoneValidation]="true"
                                                [separateDialCode]="true"
                                                name="mobile"
                                                formControlName="mobile"
                                            >
                                            </ngx-intl-tel-input>
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    !piForm.form.value.mobile
                                                "
                                                class="invalid-feedback"
                                            >
                                                Mobile Number is required
                                            </div>
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    piForm.form.value.mobile &&
                                                    piForm.form.controls[
                                                        'mobile'
                                                    ].errors
                                                "
                                                class="invalid-feedback"
                                            >
                                                Mobile Number is not valid
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="notes"
                                                    >Notes</label
                                                >
                                                <textarea
                                                    class="form-control"
                                                    id="notes"
                                                    rows="4"
                                                    formControlName="notes"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="d-flex justify-content-end pt-3"
                                    >
                                        <button
                                            type="button"
                                            (click)="submitFrom()"
                                            class="btn btn-warning ms-2"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-logo-container text-center">
                    <p>
                        Powered by
                        <a href="https://akcess.io" target="_blank"
                            ><img
                                src="https://akcess.io/wp-content/themes/akcess/assets/img/logo.png"
                                class="img-fluid"
                                width="50"
                        /></a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="clearfix"></div>
