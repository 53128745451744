import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-update-property",
    templateUrl: "./update.component.html",
    styleUrls: ["./update.component.css"],
})
export class UpdatePropertyComponent implements OnInit {
    propertyId: string;
    constructor(private route: ActivatedRoute, private router: Router) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.propertyId = params.propertyId;
        });
    }

    ngOnInit(): void {}
}
