<!-- Add Admin Users List -->
<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="addAdminModalLabel">Add Admin</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!--<input type="hidden" [(ngModel)]="empList" value="{{empList}}" />-->
        <input type="hidden" [(ngModel)]="selectedCompany" value="{{selectedCompany}}" />
        <div class="form-group">
            <label class="font-500">Search User</label>
            <ng-select [items]="empList"
                       [addTag]="addTagFn"
                       bindLabel="name"
                       [clearable]="true"
                       placeholder="Enter or Select User AKcess Id"
                       [(ngModel)]="akcessIDChips"
                       [multiple]="true"
                       appendTo="body"
                       [hideSelected]="true"
                       class="akcess-id-dropdown"
                       (open)="onOpenDropdown()"
                       (close)="onCloseDropdown()">
            </ng-select>
            <div class="valid-feedback">Enter AKcess ID and press Enter</div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-info" (click)="addUserAsAdmin()"
                [disabled]="selectSubmitDisabled">
            Add
        </button>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()" id="close">
            Close
        </button>
    </div>
</div>