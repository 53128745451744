import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-filter-component",
    templateUrl: "./filter-component.component.html",
    styleUrls: ["./filter-component.component.css"],
})
export class FilterComponentComponent implements OnInit {
    isOpenFilter: boolean = false;
    filters: any = {};
    filterList: any = [];
    @Input() filterOptions: any;
    constructor() {}

    ngOnInit(): void {
        this.filters = {
            group: "AND",
            fields: [
                {
                    group: "AND",
                    filter_by: null,
                    condition: null,
                    option: null,
                    fields: [
                        {
                            filter_by: null,
                            condition: null,
                            option: null,
                        },
                    ],
                },
            ],
        };

        console.log(this.filterOptions);
    }

    toggleFilter() {
        this.isOpenFilter = this.isOpenFilter == false ? true : false;
    }

    addFilter() {
        let obj = {
            group: "AND",
            filter_by: null,
            condition: null,
            option: null,
            fields: [],
        };

        this.filters.fields.push(obj);
    }

    addFieldGroup() {
        let obj = {
            filter_by: null,
            condition: null,
            option: null,
        };

        let lastFilter = this.filters.fields.slice(-1)[0];
        lastFilter.fields.push(obj);
        this.filters.fields[this.filters.fields.length - 1] = lastFilter;
    }

    addFieldGroupFilter(index) {
        let obj = {
            filter_by: null,
            condition: null,
            option: null,
        };

        let lastFilter = this.filters.fields[index];
        lastFilter.fields.push(obj);
        this.filters.fields[index] = lastFilter;
    }

    removeFilter(ind) {
        this.filters.fields.splice(ind, 1);
    }

    removeFilterGroup(index, gindex) {
        this.filters.fields[index].fields.splice(gindex, 1);
    }

    closeFilterBox() {
        this.isOpenFilter = false;
    }

    clearFilters() {
        this.filters.fields = [];
    }

    clearFieldGroupFilters(index) {
        this.filters.fields[index].fields = [];
    }

    changeFilterGroup() {
        if (this.filters.group == "AND") {
            this.filters.group = "OR";
        } else {
            this.filters.group = "AND";
        }
    }

    changeFilterFieldsGroup(index) {
        if (this.filters.fields[index].group == "AND") {
            this.filters.fields[index].group = "OR";
        } else {
            this.filters.fields[index].group = "AND";
        }
    }
}
