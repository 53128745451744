import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
import { DataservicesService } from "src/app/_services/dataservices.service";
declare var $: any;

@Component({
    selector: 'app-update-fields',
    templateUrl: './update-fields.component.html',
    styleUrls: ['./update-fields.component.css']
})
export class UpdateFieldsComponent implements OnInit {
    portalUser: any
    updateFieldsList: any = [];
    loader: boolean = true   

    pageSize: any = 10
    currentPage: any = 1
    totalCount: any = 0
    numberOfPage: any = [10, 20, 30, 40, 50]

    key: any
    fieldValue: any
    userAkcessId: any
    userName: any
    response_id: any
    resp_status: any
    resp_date: any

    constructor(private router: Router,
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        this.getUpdateFields()
    }

    getUpdateFields() {
        this.spinner.show()
        this.calldata.fetchUpdatFields({ limit: this.pageSize, currentpage: this.currentPage }).subscribe((res: any) => {
            if (res.status == '1') {
                this.updateFieldsList = res.data
                this.totalCount = res.totalCount
                this.toast.success('Update fields list')
                this.spinner.hide()
            } else {
                this.spinner.hide()
                this.toast.error('Something went wrong')
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    view(val) {
        this.key = val.key;
        this.fieldValue = val.value;
        this.userAkcessId = val.akcessId;
        this.userName = val.added_by;
        this.response_id = val._id;
        this.resp_status = val.status;
        this.resp_date = val.status_updated_on;
    }

    changePage(val) {
        this.pageSize = parseInt(val)
        this.getUpdateFields()
    }

    onProjectPageChange(val) {
        this.currentPage = val
        this.getUpdateFields()
    }

    updateField() {
        let object = {
            key: this.key,
            value: this.fieldValue,
            akcessId: this.userAkcessId
        }

        this.calldata.updateFields(object).subscribe((res: any) => {
            if (res.status == '1') {
                $('#viewform').modal('hide')
                this.spinner.hide();
                this.markStatus();
                this.toast.success(res.message)
            } else {
                this.spinner.hide()
                this.toast.error(res.message)
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })

    }

    markStatus() {
        let object = {
            id: this.response_id,
            values: {
                status: 'client updated',
                status_updated_on: new Date()
            }
        }
        this.calldata.updateClientStatus(object).subscribe((res: any) => {
            if (res.status == '1') {
            } else {
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

}
