import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { CommonService } from "src/app/_services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Location } from "src/app/models/location";

@Component({
  selector: "app-location-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class LocationFormComponent implements OnInit {
  @Input() mode: String;
  @Input() locationId: String;
  locationForm: FormGroup;
  dayOptions: Array<any> = [];
  location: Location = new Location();
  submitted = false;
  time = { hour: 13, minute: 30 };
  meridian = true;
  dropdownSettings: any = {};
  constructor(
    private spinner: NgxSpinnerService,
    private ds: DataservicesService,
    private toast: ToastrService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.dayOptions = [
      {
        label: "Monday",
        value: "monday",
        isDisabled: false,
      },
      {
        label: "Tuesday",
        value: "tuesday",
        isDisabled: false,
      },
      {
        label: "Wednesday",
        value: "wednesday",
        isDisabled: false,
      },
      {
        label: "Thursday",
        value: "thursday",
        isDisabled: false,
      },
      {
        label: "Friday",
        value: "friday",
        isDisabled: false,
      },
      {
        label: "Saturday",
        value: "saturday",
      },
      {
        label: "Sunday",
        value: "sunday",
        isDisabled: false,
      },
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: "value",
      textField: "label",
      selectAllText: "Every day",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };

    this.locationForm = this.fb.group({
      locationName: ["", Validators.required],
      numberOfStaff: ["", Validators.required],
      schedules: this.fb.array([
        this.fb.group({
          days: ["", [Validators.required]],
          openFrom: ["", [Validators.required]],
          openTo: ["", [Validators.required]],
        }),
      ]),
      address: ["", [Validators.required]],
      onlyAllowAdmin: [false, []],
    });
    if (this.locationId) {
      this.spinner.show();
      this.ds.getLocation(this.locationId).subscribe((response: any) => {
        this.spinner.hide();
        if (response.status) {
          this.location = response.data;
          this.locationForm.patchValue({
            locationName: this.location.locationName,
            numberOfStaff: this.location.numberOfStaff,
            schedules: this.location.schedules,
            address: this.location.address,
            onlyAllowAdmin: this.location.onlyAllowAdmin,
          });
        } else {
          this.toast.error(response.message);
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
    });
    }
  }

  get getItems() {
    return this.dayOptions.reduce((acc, curr) => {
      acc[curr.item_id] = curr;
      return acc;
    }, {});
  }

  schedules(): FormArray {
    return this.locationForm.get("schedules") as FormArray;
  }

  newDay(): FormGroup {
    return this.fb.group({
      days: ["", [Validators.required]],
      openFrom: ["", [Validators.required]],
      openTo: ["", [Validators.required]],
    });
  }

  addRemoveDay(i: number) {
    if (i == 0) {
      this.schedules().push(this.newDay());
    } else {
      this.schedules().removeAt(i);
    }
  }

  submitLocationForm(formData) {
    if (this.locationForm.valid) {
      this.spinner.show();
      if (this.mode == "edit") {
        this.ds
          .updateLocation(formData, this.locationId)
          .subscribe((response) => {
            this.spinner.hide();
            if (response["status"]) {
              this.toast.info(response["message"]);
              setTimeout(() => this.router.navigate(["panel/location"]), 2000);
            } else {
              this.toast.error(response["message"]);
            }
          }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
      } else {
        this.ds.createLocation(formData).subscribe((response) => {
          this.spinner.hide();
          if (response["status"]) {
            this.toast.info(response["message"]);
            setTimeout(() => this.router.navigate(["panel/location"]), 2000);
          } else {
            this.toast.error(response["message"]);
          }
        }, (err) => {
          this.spinner.hide();
          this.toast.error("Oops, something went wrong. Please try again.")
      });
      }
    }
  }
}
