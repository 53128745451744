<!-- View/Add Certificate Modal -->
<div class="modal-content no-border view-certificate-modal">

    <div class="modal-header">
        <!--<h4 class="modal-title" id="viewCertificateModalLabel" *ngIf="!certificateData">Create Certificate</h4>-->
        <h4 class="modal-title" id="viewCertificateModalLabel">View Certificate</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <input type="hidden" [(ngModel)]="selectedCertificate" value="{{selectedCertificate}}" />
        <!-- Create Certificate -->
        <!--<div *ngIf="!cardData">
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="owner_name"><b>First Name</b><span class="required-label">*</span></label>
                <input type="text" class="form-control" formControlName="owner_name" placeholder="Enter First Name"
                       [ngModel]="fn" (ngModelChange)="getChange($event)" required>
                <div *ngIf="isSubmitted && adduserform.get('owner_name').hasError('required')" class="invalid-feedback">
                    First Name is required
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="certificate_name"><b>Last Name</b><span class="required-label">*</span></label>
                <input type="text" class="form-control" formControlName="certificate_name" placeholder="Enter Last Name"
                       [ngModel]="ln" (ngModelChange)="getChange2($event)" required>
                <div *ngIf="isSubmitted && adduserform.get('certificate_name').hasError('required')" class="invalid-feedback">
                    Last Name is required
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="director_name"><b>Director Name</b><span class="required-label">*</span></label>
                <input type="text" class="form-control" formControlName="director_name" placeholder="Enter Director Name"
                       [ngModel]="director_name" required>
                <div *ngIf="isSubmitted && adduserform.get('director_name').hasError('required')" class="invalid-feedback">
                    Director Name is required
                </div>
            </div>
            <div class="form-group col-md-6">
                <label for="manager_name"><b>Manager Name</b><span class="required-label">*</span></label>
                <input type="text" class="form-control" formControlName="manager_name" placeholder="Enter Manager Name"
                       [ngModel]="manager_name" required>
                <div *ngIf="isSubmitted && adduserform.get('manager_name').hasError('required')" class="invalid-feedback">
                    Manager Name is required
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label for="cert_num"><b>Certificate No.</b></label>
                <input type="text" class="form-control" formControlName="cert_num" maxlength="12" readonly>
            </div>
            <div class="form-group col-md-6">
                <label for="expiry_date"><b>Certificate Expiry Date</b><span class="required-label">*</span></label>-->
        <!--<input type="date" class="form-control" formControlName="expiry_date" placeholder="Select Date"
        (change)="getCertexp($event)" required>-->
        <!--<input type="text" class="form-control" name="expiry_date" placeholder="Select Date" (bsValueChange)="getCertexp($event)"
                           formControlName="expiry_date" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red', isAnimated: true, adaptivePosition: true }" required>
                </div>
            </div>
        </div>-->
        <!--<div *ngIf="cardData">

        </div>-->
        <!--<pdf-viewer [src]="cardData.file_url" [render-text]="false" [original-size]="true" [fit-to-page]="true"
            style="display: block;"></pdf-viewer>-->
        <pdf-viewer [src]="fileUrl" [render-text]="false" [original-size]="true" [fit-to-page]="true"
                    style="display: block;"></pdf-viewer>
    </div>
    <div class="modal-footer">
        <!--<button type="submit" class="btn btn-primary" *ngIf="!cardData">
            Create
        </button>-->
        <a class="btn btn-primary mx-2" href="{{fileUrl.url}}" target="_blank" rel="noopener noreferrer">Download</a>
        <button type="button" class="btn btn-danger" id="close" (click)="closeModal()">
            Close
        </button>
    </div>
</div>