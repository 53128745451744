import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { NgxSpinnerService } from 'ngx-spinner';
import html2pdf from 'html2pdf.js';
import { HttpClient } from '@angular/common/http';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { constants } from 'buffer';
import { HttpResponse } from '@angular/common/http';
import { NgbModal,NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-verifications',
    templateUrl: './verifications.component.html',
    styleUrls: ['./verifications.component.css']
})




export class VerificationsComponent implements OnInit {

    @ViewChild('tabset1') tabset1: TabsetComponent;
    @ViewChild('tabset2') tabset2: TabsetComponent;
    @ViewChild('tabset3') tabset3: TabsetComponent;
    @ViewChild('tabset4') tabset4: TabsetComponent;
    @ViewChild('tabset5') tabset5: TabsetComponent;
    @ViewChild('tabset6') tabset6: TabsetComponent;
    @ViewChild('tabset7') tabset7: TabsetComponent;


    @ViewChild('tabsetId') tabsetid: TabsetComponent;
    @ViewChild('address') address: TabsetComponent;
    @ViewChild('address2') address2: TabsetComponent;
    @ViewChild('address3') address3: TabsetComponent;
    @ViewChild('address4') address4: TabsetComponent;
    @ViewChild('address5') address5: TabsetComponent;
    @ViewChild('address6') address6: TabsetComponent;
    @ViewChild('address7') address7: TabsetComponent;
    @ViewChild('pdfTable') pdfTable!: ElementRef;
    result: boolean = false;
    showTips: boolean = false;
    formData: any = {};
    formData1 = {
        email: '',
        first_name: '',
        sur_name: ''
      };
     
    
    // formAddress: any = {};


    // public exportPDF() {
    //   const pdfTable = this.pdfTable.nativeElement;
    //   let html = htmlToPdfmake(pdfTable.innerHTML);
    //   const documentDefinition = { content: html };
    //   pdfMake.createPdf(documentDefinition).download(); 

    // }
    private loading: boolean;
    portalUser: any;
    
    isGetStarted: boolean = true;
    isIdDocument: boolean = false;
    isIdDetails: boolean = false;
    isSelfie: boolean = false;
    isProofOfAddress: boolean = false;
    isFrontPhoto: boolean = true;
    isBackPhoto: boolean = false;
    isIDVerifiactionResult: boolean = false;
    uploadForm: FormGroup;
    uploadFormSelfie: FormGroup;
    responseMessage: string | null = null;
    showSuccessMessage: boolean = false;
    entityid: string | null = null;
    modalRef: NgbModalRef | null = null;
    myGroup: any = {};
    modaldata: any;
    selectedValue: string;  
    validationMessage: string;
    showSelectJourneyModal: boolean = false;


    formdata = { 
        entityId:'',   
        firstName: '',
        lastName: '',
        birthdate: '',
        reference:'',
        email: '',
        telephone:'',
        address:'',
        countryCode:'',
    };


    constructor(
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private router: Router,
        private dataServices: DataservicesService,
        private modalService: NgbModal,  
        private route: ActivatedRoute
       
    ) { 
        
    }

    logSelectedValue(value: string) {
        this.selectedValue = value;
        console.log("Modal data:", value);
        console.log("Form Data:", this.formdata);

    }

   

    openLargeModal() {
        this.modalRef = this.modalService.open('largeModal', { size: 'lg' });
        this.modalRef.componentInstance.modalData = this.formdata;
        this.myGroup = new FormGroup({
            
         });
        
      }
    
        displayStyle = "none"; 
      
      openPopup() { 
        this.displayStyle = "block"; 
      } 
      closePopup(modalId: string) {
        const modal = document.getElementById(modalId);
        if (modal) {
          modal.style.display = 'none';
        }
      }
    
    //   goToNextPage() {
    //     this.router.navigate(['/user-details']);
    //   }
      goToNextModal(nextModalId: string) {
        
         console.log("Modal data:", this.selectedValue);
        
        console.log("Form Data:", this.formdata);
    
        // Get the current visible modal
        const currentModal = document.querySelector('.modal[style*="display: block"]')
        
        if (currentModal) {
          currentModal.setAttribute('style', 'display: none');
        }
        // this.router.navigate(['/user-details']);
        
        // Show the next modal
        const nextModal = document.getElementById(nextModalId);
        if (nextModal) {
          nextModal.style.display = 'block';
        }
       
        // if (this.selectedValue) {            
        //     this.displayStyle = 'none';            
        //   } else {
        //     alert('Please make a selection before proceeding.');
        //   }
           
         if (this.uploadForm.valid) {
            // console.log("hi");
            this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));       
            // const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
            

            let datas = { 
                "entityId" :(this.portalUser.entityid),     
                "accessId": (this.portalUser.akcessId),  
                "firstName": (this.formdata.firstName),
                "surname": (this.formdata.lastName),
                "email": (this.formdata.email),
                "phoneNumber": (this.formdata.telephone),             
            }
           
            // console.log('datasdatas' , datas);
            this.spinner.show();
           
            this.calldata.addentity( JSON.stringify(datas, null, 2)).subscribe(                
                (res: any) => { 
                    if (res.statusCode == 200   )  {
                        console.log('resss', res);
                //    this.entityId = res.data.id;
                   datas.entityId = res.data.id;
                   console.log('Updated datas:', datas);
                //  console.log('entityId :', this.entityId);  
                // console.log('accessId print:', this.formdata);   
                console.log('entity entityid print ', res.data.id);
                // console.log('entity id print ', this.calldata.id);
                    // console.log('Email:', res.data.email);                    
                  }
                    // this.router.navigate(['/panel/verification-userdetails']).then(() => {
                    //     this.spinner.hide();
                    //   });
                    // this.router.navigate(['/panel/verification-userdetails/{{res.data.id}}'])
                    const navigationExtras = {
                        queryParams: { open: this.selectedValue } // Specify the query parameter and its value here
                      };
                      this.router.navigate(['panel/verification-userdetails/',res.data.id,this.selectedValue]).then(() => {
                        this.spinner.hide();
                      });
                            
                    //   this.router.navigate(['verification-IframeModal']).then(() => {
                    //     this.spinner.hide();
                    //   });

                    this.toast.success("create profile successfully");                    
                    this.showSuccessMessage = true;
                    setTimeout(() => {  
                        this.showSuccessMessage = false;
                    }, 3000)
                },                
                (err) => {                
                    console.log('error log', err);      
                    this.spinner.hide();             
                                    
                    this.toast.error("An error when create profile")
                    setTimeout(() => {
                        this.showSuccessMessage = false;
                      }, 3000); // Adjust time as needed
                }  
            )

            console.log("hello1");
            this.showSelectJourneyModal = true;

            this.calldata.createprocess( JSON.stringify(datas, null, 2)).subscribe( 
                (res: any) => { 
                  console.log("hello");
                  console.log('resss', res);    
                  if (res.statusCode == 200   )  {
                    console.log("hello2");
                    console.log('Updated datas:', datas);
                  }
                }
              )

              
          }

          const selectedOption = document.querySelector('input[name="profile"]:checked');
          if (!selectedOption) {
            console.log("hello3");
            this.validationMessage = 'Please select an option before proceeding.';
          } else {
            // this.showSelectJourneyModal = true;
            console.log("hello4");
            this.validationMessage = '';
            // Logic to show the next modal
            this.displayStyle = 'none'; // Close the current modal
            // Your logic to open the next modal
          }
        
      }


      goToPreviousModal(previousModalId: string) {
        // Get the current visible modal
        const currentModal = document.querySelector('.modal[style*="display: block"]');
        if (currentModal) {
          currentModal.setAttribute('style', 'display: none');
        }
    
        // Show the previous modal
        const previousModal = document.getElementById(previousModalId);
        if (previousModal) {
          previousModal.style.display = 'block';
        }
      }

    ngOnInit(): void {  
        this.entityId = this.route.snapshot.paramMap.get('entityId');   
        console.log('Entity ID:', this.entityId);   

        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));

        this.uploadForm = this.fb.group({
            avatar: [null],
        })
        this.uploadForm = this.fb.group({
            email: [this.formData1.email, [Validators.required, Validators.email]],
            first_name: [this.formData1.first_name, Validators.required],
            sur_name: [this.formData1.sur_name, Validators.required]
          });
        // this.gbgUploadDocResult = {
        //     "status": true,
        //     "message": "journey fetch Successfully",
        //     "data": {
        //         "JourneyId": "38724216-0896-4511-91f4-91917336e50a",
        //         "HighLevelResult": "Refer",
        //         "HighLevelResultDetails": [
        //             "DOCUMENTBACKSIDECHECK:SUCCESSNEEDED",
        //             "DOCUMENTBLOCKINGPOLICY:PASSED",
        //             "DOCUMENTEXPIRY:FAILED",
        //             "DOCUMENTSUPPORT:PASSED",
        //             "DOCUMENTPROOFPOLICY:SKIPPED",
        //             "DOCUMENTVALIDATION:PASSED",
        //             "DOCUMENTOVERALLVALIDATION:PASSED",
        //             "UNDERAGERULE:PASSED",
        //             "DOCUMENTBLOCKINGPOLICY:PASSED",
        //             "DOCUMENTEXPIRY:FAILED",
        //             "DOCUMENTSUPPORT:PASSED",
        //             "DOCUMENTPROOFPOLICY:SKIPPED",
        //             "DOCUMENTVALIDATION:PASSED",
        //             "FACEMATCHVALIDATION:FAILED",
        //             "DOCUMENTBACKSIDECHECK:NOTNEEDED",
        //             "DOCUMENTPROOFPOLICY:SKIPPED",
        //             "DOCUMENTBACKSIDECHECK:SKIPPED",
        //             "DOCUMENTPROOFPOLICY:SKIPPED"
        //         ],
        //         "InitiatedDateTime": "2023-04-26T04:10:25.9203313",
        //         "ProcessedDocuments": [
        //             {
        //                 "IssuingCountryCode": "DEU",
        //                 "IssuingCountryName": "Germany",
        //                 "IssuingStateName": "Not available",
        //                 "DocumentCategory": "National Identification Card",
        //                 "DocumentType": "National Identification Card",
        //                 "DocumentName": "Germany - National Identification Card - Front - 2010",
        //                 "DocumentSide": "Front",
        //                 "IsValidated": "true",
        //                 "ScanDateTime": "2023-04-26T04:10:25.9203313",
        //                 "HighLevelResult": "Expired",
        //                 "ExtractedFields": [
        //                     {
        //                         "Name": "NationalityName",
        //                         "Value": "GERMAN",
        //                         "OCRValue": "GERMAN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "BirthDate",
        //                         "Value": "1964-08-12",
        //                         "OCRValue": "1964-08-12",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "ExpiryDate",
        //                         "Value": "2020-10-31",
        //                         "OCRValue": "2020-10-31",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "FirstName",
        //                         "Value": "ERIKA",
        //                         "OCRValue": "ERIKA",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "SecondLastName",
        //                         "Value": "GABLER",
        //                         "OCRValue": "GABLER",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "LastName",
        //                         "Value": "MUSTERMANN",
        //                         "OCRValue": "MUSTERMANN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "DocumentNumber",
        //                         "Value": "T22000129",
        //                         "OCRValue": "T22000129",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "CardNumber",
        //                         "Value": "938568",
        //                         "OCRValue": "938568",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "NationalityCode",
        //                         "Value": "DEU",
        //                         "OCRValue": "DEU",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "DocumentCategory",
        //                         "Value": "National Identification Card",
        //                         "OCRValue": "National Identification Card"
        //                     },
        //                     {
        //                         "Name": "DocumentType",
        //                         "Value": "National Identification Card",
        //                         "OCRValue": "National Identification Card"
        //                     },
        //                     {
        //                         "Name": "IssuingStateName",
        //                         "Value": "Not available",
        //                         "OCRValue": "Not available"
        //                     },
        //                     {
        //                         "Name": "Front Document Type ID",
        //                         "Value": "DEUID0200A",
        //                         "OCRValue": "DEUID0200A"
        //                     }
        //                 ],
        //                 "DocumentImages": [
        //                     {
        //                         "Role": "WhiteImage",
        //                         "Url": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Search/GetImage?id=0b6fc3fb-edbb-4000-8027-6818f279ae94&role=OutputWhiteImage"
        //                     },
        //                     {
        //                         "Role": "FacePortraitImage",
        //                         "Url": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Search/GetImage?id=0b6fc3fb-edbb-4000-8027-6818f279ae94&role=FacePortraitImage"
        //                     }
        //                 ],
        //                 "DigitalTamperingValidator": "Passed"
        //             },
        //             {
        //                 "IssuingCountryCode": "DEU",
        //                 "IssuingCountryName": "Germany",
        //                 "IssuingStateName": "Not available",
        //                 "DocumentCategory": "National Identification Card",
        //                 "DocumentType": "National Identification Card",
        //                 "DocumentName": "Germany - National Identification Card - Back",
        //                 "DocumentSide": "Back",
        //                 "IsValidated": "true",
        //                 "ScanDateTime": "2023-04-26T04:11:13.2953239",
        //                 "HighLevelResult": "Expired",
        //                 "ExtractedFields": [
        //                     {
        //                         "Name": "NationalityName",
        //                         "Value": "GERMAN",
        //                         "OCRValue": "GERMAN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "BirthDate",
        //                         "Value": "1964-08-12",
        //                         "OCRValue": "1964-08-12",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "ExpiryDate",
        //                         "Value": "2020-10-31",
        //                         "OCRValue": "2020-10-31",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "LastName",
        //                         "Value": "MUSTERMANN",
        //                         "OCRValue": "MUSTERMANN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "FirstName",
        //                         "Value": "ERIKA",
        //                         "OCRValue": "ERIKA",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "DocumentNumber",
        //                         "Value": "T22000129",
        //                         "OCRValue": "T22000129",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "NationalityCode",
        //                         "Value": "DEU",
        //                         "OCRValue": "DEU",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "Sex",
        //                         "Value": "UNKNOWN",
        //                         "OCRValue": "UNKNOWN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZFull",
        //                         "Value": "IDD<<T220001293<<<<<<<<<<<<<<<\n6408125<2010315D<<<<<<<<<<<<<4\nMUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "OCRValue": "IDD<<T220001293<<<<<<<<<<<<<<<\n6408125<2010315D<<<<<<<<<<<<<4\nMUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZLine1",
        //                         "Value": "IDD<<T220001293<<<<<<<<<<<<<<<",
        //                         "OCRValue": "IDD<<T220001293<<<<<<<<<<<<<<<",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZLine2",
        //                         "Value": "6408125<2010315D<<<<<<<<<<<<<4",
        //                         "OCRValue": "6408125<2010315D<<<<<<<<<<<<<4",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZLine3",
        //                         "Value": "MUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "OCRValue": "MUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "LastName",
        //                         "Value": "MUSTERMANN",
        //                         "OCRValue": "MUSTERMANN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "ExpiryDate",
        //                         "Value": "2020-10-31",
        //                         "OCRValue": "2020-10-31",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "BirthDate",
        //                         "Value": "1964-08-12",
        //                         "OCRValue": "1964-08-12",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "NationalityName",
        //                         "Value": "GERMAN",
        //                         "OCRValue": "GERMAN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "SecondLastName",
        //                         "Value": "GABLER",
        //                         "OCRValue": "GABLER",
        //                         "LocalValue": "",ssh keys t
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "FirstName",
        //                         "Value": "ERIKA",
        //                         "OCRValue": "ERIKA",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "DocumentNumber",
        //                         "Value": "T22000129",
        //                         "OCRValue": "T22000129",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "NationalityCode",
        //                         "Value": "DEU",
        //                         "OCRValue": "DEU",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "Sex",
        //                         "Value": "UNKNOWN",
        //                         "OCRValue": "UNKNOWN",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "CardNumber",
        //                         "Value": "938568",
        //                         "OCRValue": "938568",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZFull",
        //                         "Value": "IDD<<T220001293<<<<<<<<<<<<<<<\n6408125<2010315D<<<<<<<<<<<<<4\nMUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "OCRValue": "IDD<<T220001293<<<<<<<<<<<<<<<\n6408125<2010315D<<<<<<<<<<<<<4\nMUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZLine1",
        //                         "Value": "IDD<<T220001293<<<<<<<<<<<<<<<",
        //                         "OCRValue": "IDD<<T220001293<<<<<<<<<<<<<<<",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZLine2",
        //                         "Value": "6408125<2010315D<<<<<<<<<<<<<4",
        //                         "OCRValue": "6408125<2010315D<<<<<<<<<<<<<4",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "MRZLine3",
        //                         "Value": "MUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "OCRValue": "MUSTERMANN<<ERIKA<<<<<<<<<<<<<",
        //                         "LocalValue": "",
        //                         "OCRLocalValue": ""
        //                     },
        //                     {
        //                         "Name": "DocumentCategory",
        //                         "Value": "National Identification Card",
        //                         "OCRValue": "National Identification Card"
        //                     },
        //                     {
        //                         "Name": "DocumentType",
        //                         "Value": "National Identification Card",
        //                         "OCRValue": "National Identification Card"
        //                     },
        //                     {
        //                         "Name": "IssuingStateName",
        //                         "Value": "Not available",
        //                         "OCRValue": "Not available"
        //                     },
        //                     {
        //                         "Name": "Back Document Type ID",
        //                         "Value": "DEUID9001B",
        //                         "OCRValue": "DEUID9001B"
        //                     }
        //                 ],
        //                 "DocumentImages": [
        //                     {
        //                         "Role": "WhiteImage",
        //                         "Url": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Search/GetImage?id=2d0ba08e-3280-486b-945b-d0d62dd89b2d&role=OutputWhiteImage"
        //                     }
        //                 ],
        //                 "DigitalTamperingValidator": "Passed"
        //             },
        //             {
        //                 "DocumentCategory": "Selfie",
        //                 "DocumentType": "Selfie Photo",
        //                 "IsValidated": "false",
        //                 "ScanDateTime": "2023-04-26T04:11:52.4343389",
        //                 "HighLevelResult": "Refer",
        //                 "ExtractedFields": [

        //                 ],
        //                 "DocumentImages": [
        //                     {
        //                         "Role": "SelfiePhoto",
        //                         "Url": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Search/GetImage?id=f901445c-83a6-4c3a-bd49-fc9df1c79139&role=SelfiePhoto"
        //                     }
        //                 ],
        //                 "FaceMatchConfidenceScore": "0"
        //             },
        //             {
        //                 "IssuingCountryCode": "",
        //                 "IssuingCountryName": "Unknown",
        //                 "IssuingStateName": "Not available",
        //                 "DocumentCategory": "Unknown",
        //                 "DocumentType": "Unknown",
        //                 "DocumentName": "Unknown - Unknown",
        //                 "DocumentSide": "Paper document",
        //                 "IsValidated": "undetermined",
        //                 "ScanDateTime": "2023-04-26T04:15:30.3233367",
        //                 "HighLevelResult": "Notsupported",
        //                 "ExtractedFields": [
        //                     {
        //                         "Name": "DocumentCategory",
        //                         "Value": "Unknown",
        //                         "OCRValue": "Unknown"
        //                     },
        //                     {
        //                         "Name": "DocumentType",
        //                         "Value": "Unknown",
        //                         "OCRValue": "Unknown"
        //                     }
        //                 ],
        //                 "DocumentImages": [
        //                     {
        //                         "Role": "WhiteImage",
        //                         "Url": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Search/GetImage?id=5be9bc02-7d7b-4387-9b52-518ce9b78244&role=OutputWhiteImage"
        //                     }
        //                 ]
        //             }
        //         ],
        //         "ReferenceNumber": "1000000163",
        //         "AuthenticationToleranceLevel": "default",
        //         "LastDecision": {
        //             "DecisionCode": "Rejected.Overridable",
        //             "DecisionDateTime": "26/04/2023 04:15:31",
        //             "DecisionId": "00000000-0000-0000-0000-000000000000",
        //             "Origin": "Automatic"
        //         },
        //         "AdditionalData": [
        //             {
        //                 "Name": "UserName",
        //                 "Value": "ak_scan"
        //             },
        //             {
        //                 "Name": "JourneyDefinitionId",
        //                 "Value": "887fa09f-e942-4d11-b937-84e8e4263415"
        //             },
        //             {
        //                 "Name": "RequiredJourneySteps",
        //                 "Value": "ADDRESSDOCUMENT,SELFIE,BACKSIDE,FRONTSIDE"
        //             },
        //             {
        //                 "Name": "journeyDefinitionName",
        //                 "Value": "Web/WJCS Journey Definition"
        //             },
        //             {
        //                 "Name": "OverallAuthenticationState",
        //                 "Value": "Passed"
        //             },
        //             {
        //                 "Name": "UnderAgeRuleResult",
        //                 "Value": "PASSED"
        //             },
        //             {
        //                 "Name": "AutomatedFaceMatchResult",
        //                 "Value": "FAILED"
        //             }
        //         ],
        //         "MetaData": [

        //         ],
        //         "ProcessedLiveness": {
        //             "LivenessActions": [

        //             ]
        //         },
        //         "JourneyImages": [
        //             {
        //                 "StepName": "ID Document",
        //                 "Attempt": 1,
        //                 "ImageRole": "OutputWhiteImage",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=0b6fc3fb-edbb-4000-8027-6818f279ae94&role=OutputWhiteImage"
        //             },
        //             {
        //                 "StepName": "ID Document",
        //                 "Attempt": 1,
        //                 "ImageRole": "WhiteImage",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=0b6fc3fb-edbb-4000-8027-6818f279ae94&role=WhiteImage"
        //             },
        //             {
        //                 "StepName": "ID Document",
        //                 "Attempt": 1,
        //                 "ImageRole": "FacePortraitImage",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=0b6fc3fb-edbb-4000-8027-6818f279ae94&role=FacePortraitImage"
        //             },
        //             {
        //                 "StepName": "ID Document Back",
        //                 "Attempt": 1,
        //                 "ImageRole": "OutputWhiteImage",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=2d0ba08e-3280-486b-945b-d0d62dd89b2d&role=OutputWhiteImage"
        //             },
        //             {
        //                 "StepName": "ID Document Back",
        //                 "Attempt": 1,
        //                 "ImageRole": "WhiteImage",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=2d0ba08e-3280-486b-945b-d0d62dd89b2d&role=WhiteImage"
        //             },
        //             {
        //                 "StepName": "Selfie Photo",
        //                 "Attempt": 1,
        //                 "ImageRole": "SelfiePhoto",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=f901445c-83a6-4c3a-bd49-fc9df1c79139&role=SelfiePhoto"
        //             },
        //             {
        //                 "StepName": "A4 Paper",
        //                 "Attempt": 1,
        //                 "ImageRole": "OutputWhiteImage",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=5be9bc02-7d7b-4387-9b52-518ce9b78244&role=OutputWhiteImage"
        //             },
        //             {
        //                 "StepName": "A4 Paper",
        //                 "Attempt": 1,
        //                 "ImageRole": "WhiteImage",
        //                 "ImageUrl": "https://poc.idscan.cloud/IDScanEnterpriseSvc/Journey/GetImage?id=5be9bc02-7d7b-4387-9b52-518ce9b78244&role=WhiteImage"
        //             }
        //         ],
        //         "JourneySteps": [
        //             {
        //                 "Type": "FRONTSIDE",
        //                 "HighLevelResult": "Expired",
        //                 "ValidationChecks": [
        //                     "DOCUMENTBLOCKINGPOLICY:PASSED",
        //                     "DOCUMENTEXPIRY:FAILED",
        //                     "DOCUMENTSUPPORT:PASSED",
        //                     "DOCUMENTVALIDATION:PASSED"
        //                 ],
        //                 "FailureReason": "",
        //                 "TripleScanAttempts": "1"
        //             },
        //             {
        //                 "Type": "BACKSIDE",
        //                 "HighLevelResult": "Expired",
        //                 "ValidationChecks": [
        //                     "DOCUMENTOVERALLVALIDATION:PASSED",
        //                     "UNDERAGERULE:PASSED",
        //                     "DOCUMENTBLOCKINGPOLICY:PASSED",
        //                     "DOCUMENTEXPIRY:FAILED",
        //                     "DOCUMENTSUPPORT:PASSED",
        //                     "DOCUMENTVALIDATION:PASSED"
        //                 ],
        //                 "FailureReason": "",
        //                 "TripleScanAttempts": "1"
        //             },
        //             {
        //                 "Type": "ADDRESSDOCUMENT",
        //                 "HighLevelResult": "Notsupported",
        //                 "ValidationChecks": [

        //                 ]
        //             },
        //             {
        //                 "Type": "SELFIE",
        //                 "HighLevelResult": "Refer",
        //                 "ValidationChecks": [

        //                 ],
        //                 "FailureReason": "No Facematch with Selfie and Portrait Image"
        //             }
        //         ],
        //         "IsOcrOnly": false
        //     }
        // };
   

        this.uploadForm = this.fb.group({            
            firstName: ['', Validators.required],
             lastName: ['', Validators.required],
             birthdate: [''],
             reference: [''],
             email: [''],
             telephone: [''],
             address: [''],
             countryCode: ['']
           });
    }

    logFormData(): void {
        console.log('Form Data:', this.uploadForm.value);
      }
    continueToIdDocument(): void {
        if (this.uploadForm.valid) {
          // Update formData1 with the latest values from the form group
          this.formData1 = this.uploadForm.value;
          console.log('Form Data:', JSON.stringify(this.formData1, null, 2));
          this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
         
          let data = {
            'email':this.formData1.email,
            'firstName': this.formData1.first_name,
            'surname': this.formData1.sur_name,
              "accessId": (this.portalUser.akcessId)
          }
          // Make HTTP POST request to your API
          this.calldata.addentity(JSON.stringify(data, null, 2)).subscribe((res: any) => {
            console.log(res);
            if (res.statusCode == 200) {
              console.log("inside status true");              
              console.log(res.data.id);
              this.entityid = res.data.id;

              // Save response to state variable and set success message flag
              this.responseMessage = res.message || 'Entity added successfully!';
              this.toast.info( this.responseMessage );
              this.showSuccessMessage = true;              
              // Hide success message after a few seconds
              setTimeout(() => {
                this.showSuccessMessage = false;
              }, 3000); // Adjust time as needed              
            }

            // Navigate to the next tab
            this.isIdDetails = false;
            this.isIdDocument = true;

          }, (error) => {
            console.error('API Error:', error);
            this.responseMessage = 'An error occurred while adding the entity.';
            this.showSuccessMessage = true;
    
            // Hide error message after a few seconds
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 3000); // Adjust time as needed
          });
    
        } else {
          console.log('Form is invalid');
        }
      }

    getStarted() {
        // this.isFrontPhoto = true;
        this.spinner.show();
        setTimeout(() => {
            this.isGetStarted = false;
            this.isIdDetails = true;
            // this.isIdDocument = true;
            // this.backPhoto = true;
            this.spinner.hide();
        }, 500)
    }

    gotoId(id) {
        this.tabsetid.tabs[id].active = true;
        console.log(this.tabsetid.tabs);
    }

    gotoNextAddress(id) {
        this.address.tabs[id].active = true;
        console.log(this.address.tabs);
    }
    

    imageURL: any;
    // Image Preview
    showPreview(event) {
        console.log("working");
        const file = (event.target as HTMLInputElement).files[0];
        this.uploadForm.patchValue({
            avatar: file
        });
        this.uploadForm.get('avatar').updateValueAndValidity()
        // File Preview
        const reader = new FileReader();
        reader.onload = () => {
            this.imageURL = reader.result as string;
        }
        reader.readAsDataURL(file)
    }

    isDisable: boolean = true;
    gbgUploadDocRes: any;
    gbgUploadDocResult: any = {}; 
    entityId: string;
    gbgUploadDoc() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
      

        if (this.isFrontPhoto) {
            console.log("inside isFrontPhoto");
            console.log(this.frontPhotoPL);

            // let data = {
            //     "akcessId": this.portalUser.akcessId,
            //     "InputImages": [
            //         {
            //             "Name": "WhiteImage",
            //             "ImageFormat": "jpg",
            //             "Data": this.frontPhotoPL
            //         }
            //     ],
            //     "IsDocumentExtracted": "false",
            //     "Source": 2,
            //     "ExcludeOutputImagesFromResponse": "true",
            // }
            
            let data = {
                "entityId":(this.entityid),
                "document_type":10,
                "base64Image":this.frontPhotoPL,
                "accessId": (this.portalUser.akcessId)
            }
            this.calldata.CredasUploadfront( JSON.stringify(data, null, 2)).subscribe(
                (res: HttpResponse<any>) => {
                    console.log('Response Status Code:', res);
                    // console.log('Response Body:', res.body);
                    this.toast.success("Document Uploaded successfully");
                    this.isIdDocument = false;
                    this.isSelfie = true;

                    this.spinner.hide();
                    // this.gbgUploadDocRes = res;
                    console.log(res);
                    // if (res.body.statusCode === 200) {
                      // Handle success
                      console.log("Entity added successfully!");
                    //   window.alert("Entity added successfully!");
                      // Navigate to the next tab or perform any other action
                
                     // Navigate to the next tab
                   
                    // }
                    // if (res['data']['RequiredAction'] == "BACKSIDE") {
                    //     this.isFrontPhoto = false;
                    //     this.isBackPhoto = true;
                    //     this.toast.info(res['message']);
                    //     this.isDisable = true;
                    // } else if (res['data']['RequiredAction'] == "NONE") {
                    //     this.isFrontPhoto = false;
                    //     this.isBackPhoto = false;
                    //     this.isIdDetails = false;
                    //     this.isIdDocument = false;
                    //     this.isSelfie = true;
                    //     this.toast.info(res['message']);
                    // } else if (res['data']['RequiredAction'] == "SELFIE") {
                    //     this.isFrontPhoto = false;
                    //     this.isBackPhoto = false;
                    //     this.isIdDetails = false;
                    //     this.isIdDocument = false;
                    //     this.isSelfie = true;
                    //     this.toast.info(res['message']);
                    // }
                },
                (err) => {
                    this.spinner.hide();
                    console.log(err);
                    // window.alert(`Error: ${err.message}`);
                    this.toast.error(err.message)
                }           
                    
                
            )
        }
        if (this.isBackPhoto) {
            let data =
            {
                "InputImages": [
                    {
                        "Name": "WhiteImage",
                        "ImageFormat": "jpg",
                        "Data": this.backPhotoPL
                    }
                ],
                "IsDocumentExtracted": "false",
                "Source": 2,
                "ExcludeOutputImagesFromResponse": "true",
                "PersonEntryId": this.gbgUploadDocRes.data.PersonEntryId
            }
            console.log("insideback");
            this.calldata.gbgUploadDocument(data).subscribe(
                (res) => {                    
                    this.spinner.hide();
                    this.toast.success(res['message'])
                    this.isFrontPhoto = false;
                    this.isBackPhoto = false;
                    this.isIdDetails = false;
                    this.isIdDocument = false;
                    this.isSelfie = true;
                },

            );
        }
        if (this.isSelfie) {
            console.log("inside selfie");
            // let data =
            // {
            //     "InputImages": [
            //         {
            //             "Name": "SelfiePhoto",
            //             "ImageFormat": "jpg",
            //             "Data": this.selfiePL
            //         }
            //     ],
            //     "IsDocumentExtracted": "false",
            //     "Source": 2,
            //     "ExcludeOutputImagesFromResponse": "true",
            //     "PersonEntryId": this.gbgUploadDocRes.data.PersonEntryId
            // }
            
            let data = {
                "entityId":(this.entityid),
                "document_type":0,
                "base64Image":this.selfiePL,
                "accessId": (this.portalUser.akcessId)
            }

            this.calldata.CredasUploadfront( JSON.stringify(data, null, 2)).subscribe(
                (res: HttpResponse<any>) => {
                    console.log('Response Status Code:', res.status);
                    console.log('Response Body:', res.body);
                    this.toast.success("selfie Uploaded successfully");
                    if (res.status === 200) {
                      // Handle success
                      console.log("Entity added successfully!");
                    //   window.alert("Entity added successfully!");
                      // Navigate to the next tab or perform any other action
                   

                    this.spinner.hide();
                    // this.toast.success(res['message']);
                    this.isFrontPhoto = false;
                    this.isBackPhoto = false;
                    this.isIdDetails = false;
                    this.isIdDocument = false;
                    this.isSelfie = false;
                    this.isProofOfAddress = true;
                }
                },
                (err) => {
                    this.spinner.hide();
                    console.log(err);   
                    this.isFrontPhoto = false;
                    this.isBackPhoto = false;
                    this.isIdDetails = false;
                    this.isIdDocument = false;
                    this.isSelfie = false;
                    this.isProofOfAddress = true;                
                    this.toast.error(err.message)
                }     

            );
        }
        if (this.isProofOfAddress) {
            console.log("inside proof");
            // let data =
            // {
            //     "InputImages": [
            //         {
            //             // "Name": "WhiteImage",
            //             // "ImageFormat": "jpg",
            //             // "Data": this.addressDocPL
            //             "entityId":(this.entityid),
            //             "document_type":0,
            //             "base64Image":this.addressDocPL
            //         }
            
            //     ],
            //     "IsDocumentExtracted": "false",
            //     "Source": 3,
            //     "ExcludeOutputImagesFromResponse": "true",
            //     "PersonEntryId": this.gbgUploadDocRes.data.PersonEntryId
            // }

            let data = {
                "entityId":(this.entityid),
                "document_type":2,
                "base64Image":this.addressDocPL,
                "accessId": (this.portalUser.akcessId)
            }
            this.calldata.CredasUploadfront( JSON.stringify(data, null, 2)).subscribe(
                (res: HttpResponse<any>) => {
                    // this.spinner.hide();
                    // this.toast.success(res['message']);
                    // this.isSelfie = false;
                    // this.isProofOfAddress = false;
                    // this.isIDVerifiactionResult = true;
                    // this.spinner.show();
                    // this.calldata.gbgUploadDocumentResult(this.gbgUploadDocRes.data.PersonEntryId).subscribe(
                    //     (result) => {
                    //         this.spinner.hide();
                    //         this.toast.success(result['message']);
                    //         this.gbgUploadDocResult = result;
                    //     }
                    // )

                    console.log('Response Status Code:', res.status);
                    console.log('Response Body:', res.body);
                    this.spinner.hide();
                    this.toast.success("Address Proof Uploaded successfully");
                    // this.isSelfie = false;
                    // this.isProofOfAddress = false;
                    // this.isIDVerifiactionResult = true;



                    this.isFrontPhoto = false;
                    this.isBackPhoto = false;
                    this.isIdDetails = false;
                    this.isIdDocument = false;
                    this.isSelfie = false;
                    this.isProofOfAddress = false;
                    this.isIDVerifiactionResult = true;
                    
                    if (this.isIDVerifiactionResult) {
                        console.log("if isIDVerifiactionResult");
                        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
                        // const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
                        let data = {
                            "entityId":(this.entityid),
                            "accessId": (this.portalUser.akcessId)
                        }
                       
                            // this.gbgUploadDocResult();
                          
                        this.calldata.checkSummary( JSON.stringify(data, null, 2)).subscribe(
                            (res: HttpResponse<any>) => {
                                this.spinner.hide();
                                console.log("hi");
                            console.log('Response Status Code:', res.status);
                                console.log('Response Body checkSummary:', res.body);
                                console.log("API Response:", res);  
                                // this.entityId = res['entityId'];
                                               
                                this.gbgUploadDocResult = res;
                                
                            },
                            (err) => {
                                this.spinner.hide();
                                console.log('error log', err);                   
                                // this.toast.error(err.message)
                            }  
                        )
                        
                          }
                    // if (res.status === 0) {
                      // Handle success
                    //   console.log("Entity added successfully!");
                    //   window.alert("Entity added successfully!");
                      // Navigate to the next tab or perform any other action
                    // }
                },

            );
        }
       
       
    }


    //  data = {
    //   "akcessId" : this.portalUser.akcessId,
    //   "InputImages": [
    //     {
    //         "Name": "WhiteImage",
    //         "ImageFormat": "jpg",
    //         "Data": this.frontPhotoPL     
    //     }
    //   ],
    // "IsDocumentExtracted": "false",
    // "Source": 2,
    // "ExcludeOutputImagesFromResponse": "true",
    // "PersonEntryId" : "debbb7b5-236a-465b-818f-05a38f44206f"
    // }
    getSummeryResult() {
        
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        // const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
        let data = {
            "entityId":(this.entityid),
            "accessId": (this.portalUser.akcessId)
        }
       
            // this.gbgUploadDocResult();
          
        this.calldata.checkSummary( JSON.stringify(data, null, 2)).subscribe(
            (res: HttpResponse<any>) => {
                console.log("hi")
            console.log('Response Status Code:', res.status);
                console.log('Response Body checkSummary:', res.body);
                console.log("API Response:", res);  
                // this.entityId = res['entityId'];
                this.spinner.hide();               
                this.gbgUploadDocResult = res;
                // cons
                
            })
    }

    uploadDocFront() {
        if (this.isFrontPhoto) {
            // this.isFrontPhoto = false;
            this.isBackPhoto = true;
        }
        if (this.isBackPhoto) {
            setTimeout(() => {
                this.spinner.hide();
                // this.isFrontPhoto = false;
                // this.isBackPhoto = false;
                this.isIdDetails = false;
                this.isIdDocument = false;
                this.isSelfie = true;
            }, 1000)
        }
    }
    uploadSelfie() {
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
            this.isSelfie = false;
            this.isProofOfAddress = true;
        }, 500);
    }
    uploadAddressDoc() {
        this.spinner.show();
        setTimeout(() => {
            this.spinner.hide();
            this.isProofOfAddress = true;
        }, 1500);
    }

    file: any;
    onUploadChange(evt: any) {
        this.file = evt.target.files[0]; console.log('Front Photo:', this.frontPhoto);
        console.log('Back Photo:', this.backPhoto);

        if (this.file) {
            const reader = new FileReader();

            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.file);
        }
    }
    // onUploadChanges(event: any) {
    //     const input = event.target as HTMLInputElement;
    //     if (input.files && input.files.length) {
    //       const file = input.files[0];
    //       const reader = new FileReader();
    //       reader.onload = () => {
    //         // Update the frontPhoto or backPhoto with the Base64 string
    //         if (input.id === 'frontImg') {
    //           this.frontPhoto = reader.result;
    //         } else if (input.id === 'backImg') {
    //           this.backPhoto = reader.result;
    //         }
    //       };
    //       reader.readAsDataURL(file); // Convert file to Base64 string
    //       console.log(reader);
    //     }
    // }
    frontPhoto: any;
    frontPhotoPL: any;
    backPhoto: any;
    backPhotoPL: any;
    selfie: any;
    selfiePL: any;
    addressDoc: any;
    addressDocPL: any;
    handleReaderLoaded(e) {

        if (this.isFrontPhoto) {
            this.frontPhoto = "data:image/png;base64," + btoa(e.target.result);
            this.frontPhotoPL = btoa(e.target.result);
            this.isDisable = false;
        }
        if (this.isBackPhoto) {
            this.backPhoto = "data:image/png;base64," + btoa(e.target.result);
            this.backPhotoPL = btoa(e.target.result);
            this.isDisable = false;
        }
        if (this.isSelfie) {
            this.selfie = "data:image/png;base64," + btoa(e.target.result);
            this.selfiePL = btoa(e.target.result);
            this.isDisable = false;
        }
        if (this.isProofOfAddress) {
            this.addressDoc = "data:image/png;base64," + btoa(e.target.result);
            this.addressDocPL = btoa(e.target.result);
            this.isDisable = false;
        }
    }

    goToStep(step) {
        switch (step) {
            case 0:
                this.isGetStarted = true;
                this.isIdDetails = false;
                this.isIdDocument = false;
                this.isSelfie = false;
                this.isProofOfAddress = false;
                this.isIDVerifiactionResult = false;
                break;
            case 5:
                this.isGetStarted = false;
                this.isIdDetails = true;
                this.isIdDocument = false;
                this.isSelfie = false;
                this.isProofOfAddress = false;
                this.isIDVerifiactionResult = false;
                break;
            case 1:
                this.isGetStarted = false;
                this.isIdDetails = false;
                this.isIdDocument = true;
                this.isSelfie = false;
                this.isProofOfAddress = false;
                this.isIDVerifiactionResult = false;
                break;
            case 2:
                this.isGetStarted = false;
                this.isIdDetails = false;
                this.isIdDocument = false;
                this.isSelfie = true;
                this.isProofOfAddress = false;
                this.isIDVerifiactionResult = false;
                break;
            case 3:
                this.isGetStarted = false;
                this.isIdDetails = false;
                this.isIdDocument = false;
                this.isSelfie = false;
                this.isProofOfAddress = true;
                this.isIDVerifiactionResult = false;
                break;
            case 4:
                this.isGetStarted = false;
                this.isIdDetails = false;
                this.isIdDocument = false;
                this.isSelfie = false;
                this.isProofOfAddress = false;
                this.isIDVerifiactionResult = true;
                break;
        }
    }

    // exportIdResultPDF() {
    //     let data = document.getElementById("gbgResultPreview");
    //     html2canvas(data, { useCORS: true, allowTaint: true }).then(
    //         (canvas) => {
    //             let imgWidth = 309;
    //             let pageHeight = 295;
    //             let imgHeight = (canvas.height * imgWidth) / canvas.width;
    //             let heightLeft = imgHeight;

    //             const contentDataURL = canvas.toDataURL("image/png");
    //             let pdf = new jsPDF("l", "mm", "a4");
    //             let position = 0;
    //             pdf.addImage(
    //                 contentDataURL,
    //                 "PNG",
    //                 0,
    //                 position,
    //                 imgWidth - 10,
    //                 imgHeight
    //             );
    //             pdf.save("Result.pdf");
    //         }
    //     );
    // }


    navigate(){
        this.router.navigate(["/panel/verification-history"]);
    }
    exportIdResultPDF() {
        // define the html element to export
        let element = document.getElementById('gbgResultPreview');
        // define optional configuration
        let opt = {
            margin:       1,
            filename:     'result.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
          };
        // create an instance of html2pdf class
        let exporter = new html2pdf(element, opt);
        // download the pdf
        exporter.getPdf(true).then((pdf) => { console.log('pdf downloaded!', pdf); });

        

        html2pdf.getPdf(opt).then((pdf) => { console.log(pdf.output('datauristring')); });
    }

    printIdResultHTML() {
        var markup = document.documentElement;
        var toPrint = document.getElementById("gbgResultPreview");
        var popupWin = window.open(
            "",
            "_blank",
            "width=" +
            screen.width +
            ",height=" +
            screen.height +
            ",location=no,left=200px"
        );
        popupWin.document.open();
        popupWin.document.write(
            '<html><title>::Print Preview::</title><link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous"><style>.enquiry-details h3 { margin-bottom: 30px; } .cs-card{ border: 0; border-radius: 5px; box-shadow: 0 0 1px 1px #00000010; width: 100%; margin-bottom: 30px; padding: 5px;} @media print { .fix-sidebar .left-sidebar { display: none !important; visibility: hidden !important; } * { visibility: hidden; } .property-card { visibility: visible !important; } .property-card * { visibility: visible; } .noprint, .property-card .noprint { visibility: hidden !important; } }</style></head><body">'
        );
        popupWin.document.write(toPrint.innerHTML);
        popupWin.document.write("</body>");
        popupWin.document.write("</html>");
        popupWin.document.close();
    }

    // Temp
    // gotoNextstep(id) {
    //     this.tabset7.tabs[id].active = true;
    //     console.log("Form Data:", this.formData); 
    // }
    gotoNext1(id) {        
        this.tabset1.tabs[id].active = true;
        console.log("Form Data:", this.formData);
        }
    gotoNext2(id) {
        this.tabset2.tabs[id].active = true;
        console.log("Form Data:", this.formData);
    }
    gotoNext3(id) {
        this.tabset3.tabs[id].active = true;  
        console.log("Form Data:", this.formData);          
    }
    gotoNext4(id) {
        this.tabset4.tabs[id].active = true;
        console.log("Form Data:", this.formData); 
    }
    gotoNext5(id) {
        this.tabset5.tabs[id].active = true;
        console.log("Form Data:", this.formData); 
    }
    gotoNext6(id) {
        this.tabset6.tabs[id].active = true;
        console.log("Form Data:", this.formData); 
    }
    gotoNext7(id) {
        this.tabset7.tabs[id].active = true;
        console.log("Form Data:", this.formData); 
    }
    




    
    gotoNextAddress1(id) {
        this.address.tabs[id].active = true;
        // console.log(this.address.tabs);
        console.log("Current Address", this.formData);       
    }
  
    gotoNextAddress2(id) {
        console.log("Previous Address 1",this.formData);
        this.address.tabs[id].active = true;
            
    }
    
    gotoNextAddress3(id) {       
        this.address.tabs[id].active = true;
        // console.log(this.address3.tabs);
        console.log("Previous Address 2", this.formData);
    }

    gotoNextAddress4(id) {        
        this.address.tabs[id].active = true;
        // console.log(this.address4.tabs);
        console.log("Previous Address 3", this.formData);
    }
    gotoNextAddressCredit(id) { 
        this.address.tabs[id].active = true;
        // console.log(this.address4.tabs);
        console.log("Credit Current Address", this.formData);
    }
    gotoNextAddressCredit3(id) {         
        this.address.tabs[id].active = true;
        // console.log(this.address4.tabs);
        console.log("Credit Previous Address 3", this.formData);
    }


    gotoNextAddress5(id) {
        this.address5.tabs[id].active = true;
        console.log(this.address5.tabs);
        console.log("Current Address", this.formData);
    }
    gotoNextAddress6(id) {
        this.address6.tabs[id].active = true;
        console.log(this.address6.tabs);
    }
    gotoNextAddress7(id) {
        this.address7.tabs[id].active = true;
        console.log(this.address7.tabs);
    }





    


}
