import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { tap, startWith, switchMap } from 'rxjs/operators';
import { DataservicesService } from 'src/app/_services/dataservices.service';

@Component({
  selector: 'app-messaging-history',
  templateUrl: './messaging-history.component.html',
  styleUrls: ['./messaging-history.component.css'],
  providers: [DataservicesService]
})
export class MessagingHistoryComponent implements OnInit {
  msgHistory: [];
  dataType: string;
  pageSize: any = 10;
  totalCount: any = 0;
  eformsents: any = [];
  currentPage: any = 0;
  currentPageDisplay: any = 0;

  history$: Observable<any>;
  subject$: Subject<void> = new Subject<void>();
  title: any = 'Messsge History';
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
  constructor(private _bsModalRef: BsModalRef, private calldata: DataservicesService,
    private spinner: NgxSpinnerService,) {
  }
  ngOnInit(): void {
    this.getHistory();
  }

  getHistory() {
    let getData = {
      "requesttype": this.dataType,
      "skip": this.currentPage * 10,
      "limit": this.pageSize
    }
    this.currentPageDisplay = 1 + this.currentPage
    // this.history$ = this.calldata.getMessagesHistory(getData);
    this.history$ = this.subject$.pipe(
      startWith(null), tap(() => { this.spinner.show() }),
      switchMap(() => this.calldata.getMessagesHistory(getData)),
      tap(() => { this.spinner.hide() })
    );

    // this.spinner.hide();
  }

  closeModal() {
    this._bsModalRef.hide();
  }

  changePage(value) {
    this.spinner.show()
    this.pageSize = parseInt(value)
    this.getHistory();

  }
  onProjectPageChange(event) {
    this.currentPage = event
    this.getHistory();
  }

  nextPage() {
    this.currentPage = this.currentPage + 1;
    this.getHistory();
  }
  prevPage() {
    this.currentPage = this.currentPage - 1;
    this.getHistory();
  }


}
