
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CookieService } from '../../../common/cookie.service'

@Injectable({ providedIn: 'root' })
export class ApiService {

  private serverUrl = environment.imgurl;  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json','api': environment.apiKey, 'userid': sessionStorage.getItem("_id"), 'Authorization': sessionStorage.getItem("token")}),
    // headers: new HttpHeaders({ 'Content-Type': 'application/json', 'api': environment.apiKey,'userid': "5fdc40de095abd17d9ce7b75" , 'Authorization': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJha2Nlc3NJZCI6IlNQMTAyMDE4MzM3IiwiZW1haWwiOiJzYWdhcnBhd2FyOTMyNkBnbWFpbC5jb20iLCJtb2JpbGUiOjgyODYyODc2NDIsImlhdCI6MTYwODQ0OTQ2NSwiZXhwIjoxNjA4NTM1ODY1fQ.w3xYlstok6sm8VXVoCcHqsUCWAXw0zu0L-Bd53P0nOM"}),
  
  };

  constructor(
    private http: HttpClient,
    private cs: CookieService
    // private messageService: MessageService
  ) { }

  /** GET records from the server */
  getRecords(): Observable<Object> {
    return this.http.get<any[]>(this.serverUrl, this.httpOptions)
      .pipe(
        tap(_ => this.log('fetched records')),
        catchError(this.handleError<any[]>('getRecords', []))
      );
  }

  /** GET record by id. Return `undefined` when id not found */
  getRecordsNo404<Data>(id: number = 12345): Observable<Object> {
    const url = `${this.serverUrl}/?id=${id}`;
    return this.http.get<any[]>(url)
      .pipe(
        // map(records => records[0]), // returns a {0|1} element array
        map(records => records), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} record id=${id}`);
        }),
        catchError(this.handleError<Object>(`getRecord id=${id}`))
      );
  }

  /** GET record by id. Will 404 if id not found */
  getRecord(id: any): Observable<Object> {
    const url = `${this.serverUrl}${id}`;
    return this.http.get<any[]>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched record...`)),
      catchError(this.handleError<Object>(`getRecord...`))
    );
  }

  /* GET records whose name contains search term */
  searchRecords(term: string): Observable<Object> {
    if (!term.trim()) {
      // if not search term, return empty record array.
      return of([]);
    }
    return this.http.get<any[]>(`${this.serverUrl}/?name=${term}`).pipe(
      tap(x => x.length ?
         this.log(`found records matching "${term}"`) :
         this.log(`no records matching "${term}"`)),
      catchError(this.handleError<any[]>('searchRecords', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new record to the server */
  addRecord(location: String, payload: Object): Observable<Object> {
    return this.http.post<Object>(this.serverUrl + location, payload, this.httpOptions).pipe(
      tap((newRecord: Object) => this.log(`added record w/ id=${12345}`)),
      catchError(this.handleError<Object>('addRecord'))
    );
  }

  /** DELETE: delete the record from the server */
  deleteRecord(record: Object | number): Observable<Object> {
    const id = typeof record === 'number' ? record : 12345;
    const url = `${this.serverUrl}/${id}`;

    return this.http.delete<Object>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted record id=${id}`)),
      catchError(this.handleError<Object>('deleteRecord'))
    );
  }

  /** PUT: update the record on the server */
  updateRecord(endPoint: String, record: Object): Observable<any> {
    return this.http.put(this.serverUrl+endPoint, record, this.httpOptions).pipe(
      tap(_ => this.log(`updated record id=${12345}`)),
      catchError(this.handleError<any>('updateRecord'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ApiService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`ApiService: ${message}`);
  }

  getSlugForQrCode(id) {
    return this.http.post<Object>(this.serverUrl + 'getforqrcode', {id: id},this.httpOptions).pipe(
      tap((newRecord: Object) => this.log(`et merchant slug..`)),
      catchError(this.handleError<Object>('get merchant slug'))
    );
  }

  bookSlot(obj) {
    return this.http.post<Object>(this.serverUrl + 'bookslot', {obj: obj},this.httpOptions).pipe(
      tap((newRecord: Object) => this.log(`post data..`)),
      catchError(this.handleError<Object>('err book slot'))
    );
  }

  fetchReservations(obj){
    return this.http.post(this.serverUrl+'fetchreservations', {obj: obj} ,this.httpOptions)
  }

  getAllLocationsSlug(){
    return this.http.get(this.serverUrl+'getalllocationsslug', this.httpOptions)
  }

  removeReservation(id){
    return this.http.post(this.serverUrl+'removereservation', {id:id},this.httpOptions)
  }

  reservationChangeStatus(obj) {
    return this.http.post(this.serverUrl+'changestatus', obj,this.httpOptions)
  }
}