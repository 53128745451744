import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";

import { CommonService } from "../_services/common.service";

@Injectable({ providedIn: "root" })
export class OnlyRealEstateGuard implements CanActivate {
    constructor(private router: Router, public commonService: CommonService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentCompany = this.commonService.selectedCompanyDeatils;
        if (currentCompany.market_sector === "Real Estate") {
            return true;
        } else {
            window.alert("Contact Us to enable these features.");
            return false;
        }
    }
}
