<!-- View Sent Asset Modal-->
<div class="modal-content view-sent-asset-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewSentModalLabel">{{ assetName }}</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <input type="hidden" [(ngModel)]="dataId" value="{{ dataId }}" />
        <input type="hidden" [(ngModel)]="sentType" value="{{ sentType }}" />
        <input type="hidden" [(ngModel)]="assetName" value="{{ assetName }}" />
        <div class="modal-tab">
            <tabset [justified]="true">
                <tab heading="AKcess Id" [active]="true">
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableViewById="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th>AKcess Id</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="assetSents?.length == 0">
                                <tr>
                                    <td colspan="3" class="text-center">
                                        No sent Asset Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="assetSents?.length > 0">
                                <ng-container
                                    *ngFor="
                                        let data of assetSents;
                                        let i = index
                                    "
                                >
                                    <tr
                                        *ngIf="
                                            data.akcessId &&
                                            !data.emailFlag &&
                                            !data.phoneFlag
                                        "
                                    >
                                        <td>
                                            {{ data?.akcessId }}
                                        </td>
                                        <td>
                                            {{
                                                data?.received
                                                    ? "Received"
                                                    : "Sent"
                                            }}
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="2">
                                        <div
                                            class="table-page"
                                            *ngIf="assetSents?.length > 0"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
                <tab heading="Email Address">
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableEl="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="assetSentsEmail?.length == 0">
                                <tr>
                                    <td colspan="2" class="text-center">
                                        No sent Asset Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="assetSentsEmail?.length > 0">
                                <tr
                                    *ngFor="
                                        let data of assetSentsEmail;
                                        let i = index
                                    "
                                >
                                    <td>
                                        {{ data?.email }}
                                    </td>
                                    <td>
                                        {{
                                            data?.received ? "Received" : "Sent"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="2">
                                        <div
                                            class="table-page"
                                            *ngIf="assetSentsEmail?.length > 0"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
                <tab heading="Mobile Number">
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableEl="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="assetSentsPhone?.length == 0">
                                <tr>
                                    <td colspan="2" class="text-center">
                                        No sent Asset Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="assetSentsPhone?.length > 0">
                                <tr
                                    *ngFor="
                                        let data of assetSentsPhone;
                                        let i = index
                                    "
                                >
                                    <td>
                                        {{ data?.phone }}
                                    </td>
                                    <td>
                                        {{
                                            data?.received ? "Received" : "Sent"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="2">
                                        <div
                                            class="table-page"
                                            *ngIf="assetSentsPhone?.length > 0"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn waves-effect waves-light btn-danger"
            (click)="closeModal()"
        >
            Close
        </button>
    </div>
</div>
