<!-- Add eForm Page -->
<div class="add-eform-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 *ngIf="efrom_data_status" class="text-themecolor">
                Create eForms
            </h3>
            <h3 *ngIf="!efrom_data_status" class="text-themecolor">
                Edit eForms
            </h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Home</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/eforms']">eForm</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="efrom_data_status">
                    Create eForms
                </li>
                <li class="breadcrumb-item active" *ngIf="!efrom_data_status">
                    Edit eForms
                </li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <tabset class="eform-tabset" [justified]="true" #staticTabs>
                        <tab heading="Settings" id="settings_tab">
                            <div class="card card-form mb-0">
                                <div class="card-body">
                                    <div class="form-row">
                                        <div class="form-group col-12 col-md-6">
                                            <label class="form-label font-500">eForm Name</label>
                                            <input type="text" class="form-control" [(ngModel)]="formname"
                                                placeholder="Name" />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-12 col-md-6">
                                            <label class="form-label font-500">eForm Description</label>
                                            <input type="text" class="form-control" [(ngModel)]="description"
                                                placeholder="Description" />
                                        </div>
                                        <div class="form-group col-12 col-md-6">
                                            <label class="form-label font-500">eForm Instruction</label>
                                            <input type="text" class="form-control" [(ngModel)]="form_instructions"
                                                placeholder="Instruction" />
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">
                                            Publish the eForm to the AKcess
                                            Marketplace?
                                        </label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <div class="custom-control custom-radio">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="publish_yes"
                                                   name="publish"
                                                   [(ngModel)]="publish"
                                                   value="yes" />
                                                   <label class="form-check-label" for="publish_yes">Yes</label>-->
                                                <input type="radio" [(ngModel)]="publish" value="yes" id="publish_yes"
                                                    name="publish" class="with-gap radio-col-light-blue" />
                                                <label for="publish_yes" class="font-500">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mx-3">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="publish_no"
                                                   name="publish"
                                                   [(ngModel)]="publish"
                                                   value="no" />
                                                   <label class="form-check-label" for="publish_no">No</label>-->
                                                <input type="radio" [(ngModel)]="publish" value="no" id="publish_no"
                                                    name="publish" class="with-gap radio-col-light-blue" />
                                                <label for="publish_no" class="font-500">No</label>
                                            </div>
                                            <div class="information-btn"
                                                tooltip="This will make your eform available on the AKcess Marketplace and in the Search of eforms.  The public users will be able to find it and send responses to you."
                                                placement="right" container="body">
                                                <i class="fas fa-info-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">
                                            Signature for eForm?
                                        </label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <div class="custom-control custom-radio">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="signature_yes"
                                                   name="signature"
                                                   [(ngModel)]="signature"
                                                   value="yes" />
                                                   <label class="form-check-label" for="signature_yes">
                                                   Yes
                                                   </label>-->
                                                <input type="radio" [(ngModel)]="signature" value="yes"
                                                    id="signature_yes" name="signature"
                                                    class="with-gap radio-col-light-blue" />
                                                <label for="signature_yes" class="font-500">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mx-3">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="signature_no"
                                                   name="signature"
                                                   [(ngModel)]="signature"
                                                   value="no" />
                                                   <label class="form-check-label" for="signature_no">
                                                   No
                                                   </label>-->
                                                <input type="radio" [(ngModel)]="signature" value="no" id="signature_no"
                                                    name="signature" class="with-gap radio-col-light-blue" />
                                                <label for="signature_no" class="font-500">No</label>
                                            </div>
                                            <div class="information-btn"
                                                tooltip="Require the user to sign the eform before submitting it."
                                                placement="right" container="body">
                                                <i class="fas fa-info-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">
                                            Is face match required?
                                        </label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <div class="custom-control custom-radio">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="facematch_yes"
                                                   name="facematch"
                                                   [(ngModel)]="facematch"
                                                   value="yes" />
                                                   <label class="form-check-label" for="facematch_yes">
                                                   Yes
                                                   </label>-->
                                                <input type="radio" [(ngModel)]="facematch" value="yes"
                                                    id="facematch_yes" name="facematch"
                                                    class="with-gap radio-col-light-blue" />
                                                <label for="facematch_yes" class="font-500">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mx-3">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="facematch_no"
                                                   name="facematch"
                                                   [(ngModel)]="facematch"
                                                   value="no" />
                                                   <label class="form-check-label" for="facematch_no">
                                                   No
                                                   </label>-->
                                                <input type="radio" [(ngModel)]="facematch" value="no" id="facematch_no"
                                                    name="facematch" class="with-gap radio-col-light-blue" />
                                                <label for="facematch_no" class="font-500">No</label>
                                            </div>
                                            <div class="information-btn" tooltip="Require the user to take a selfie.  AKcess will auto match the selfie with the photo on the attached document.
                                 This function requires you to have a Select File field on the eform."
                                                placement="right" container="body">
                                                <i class="fas fa-info-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">
                                            Pull data from document?
                                        </label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <div class="custom-control custom-radio">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="pulldata_yes"
                                                   name="pulldata"
                                                   [(ngModel)]="pulldata"
                                                   value="yes" />
                                                   <label class="form-check-label" for="pulldata_yes">
                                                   Yes
                                                   </label>-->
                                                <input type="radio" [(ngModel)]="pulldata" value="yes" id="pulldata_yes"
                                                    name="pulldata" class="with-gap radio-col-light-blue" />
                                                <label for="pulldata_yes" class="font-500">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mx-3">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="pulldata_yes"
                                                   name="pulldata"
                                                   [(ngModel)]="pulldata"
                                                   value="no" />
                                                   <label class="form-check-label" for="pulldata_yes">
                                                   No
                                                   </label>-->
                                                <input type="radio" [(ngModel)]="pulldata" value="no" id="pulldata_no"
                                                    name="pulldata" class="with-gap radio-col-light-blue" />
                                                <label for="pulldata_no" class="font-500">No</label>
                                            </div>
                                            <div class="information-btn" tooltip="This function reads the attached document(s) and saves all the fields to the response.  The fields will be available to you.
                                 This function requires you to have at least one Select File field on the eform."
                                                placement="right" container="body">
                                                <i class="fas fa-info-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">
                                            Store in profile?
                                            <!--<span class="information-btn" tooltip="xyz" placement="right" container="body">
                                               <i class="fas fa-info-circle"></i>
                                               </span>-->
                                        </label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <div class="custom-control custom-radio">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="store_yes"
                                                   name="storeinprofile"
                                                   [(ngModel)]="storeinprofile"
                                                   value="yes" />
                                                   <label class="form-check-label" for="store_yes">Yes</label>-->
                                                <input type="radio" [(ngModel)]="storeinprofile" value="yes"
                                                    id="store_yes" name="storeinprofile"
                                                    class="with-gap radio-col-light-blue" />
                                                <label for="store_yes" class="font-500">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mx-3">
                                                <!--<input class="form-check-input"
                                                   type="radio"
                                                   id="store_no"
                                                   name="storeinprofile"
                                                   [(ngModel)]="storeinprofile"
                                                   value="no" />
                                                   <label class="form-check-label" for="store_no">No</label>-->
                                                <input type="radio" [(ngModel)]="storeinprofile" value="no"
                                                    id="store_no" name="storeinprofile"
                                                    class="with-gap radio-col-light-blue" />
                                                <label for="store_no" class="font-500">No</label>
                                            </div>
                                            <div class="information-btn"
                                                tooltip="Allow users to store eForm fields in their profile."
                                                placement="right" container="body">
                                                <i class="fas fa-info-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">
                                            Does this eForm include a verification check?
                                        </label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <div class="custom-control custom-radio">
                                                <input type="radio" [(ngModel)]="eFormIncludeVerificationCheck"
                                                    value="yes" id="eFormIncludeVerificationCheck_Yes"
                                                    name="eFormIncludeVerificationCheck"
                                                    class="with-gap radio-col-light-blue" />
                                                <label for="eFormIncludeVerificationCheck_Yes"
                                                    class="font-500">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio mx-3">
                                                <input type="radio" [(ngModel)]="eFormIncludeVerificationCheck"
                                                    value="no" id="eFormIncludeVerificationCheck_No"
                                                    name="eFormIncludeVerificationCheck"
                                                    class="with-gap radio-col-light-blue" />
                                                <label for="eFormIncludeVerificationCheck_No"
                                                    class="font-500">No</label>
                                            </div>
                                            <div class="information-btn"
                                                tooltip="This function provides a verification on shared document and will requires you to have a select file field in the eForm"
                                                placement="right" container="body">
                                                <i class="fas fa-info-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-10 col-md-7 mt-2 offset-md-5"
                                        *ngIf="eFormIncludeVerificationCheck == 'yes'">
                                        <div class="form-check custom-control"
                                            *ngFor="let checkOption of eFormVerificationCheckOptions">
                                            <input type="checkbox" class="form-check-input filled-in chk-col-light-blue"
                                                id="{{ checkOption.id }}" [(ngModel)]="checkOption.isChecked" />
                                            <label class="form-check-label" for="{{ checkOption.id }}">{{
                                                checkOption.label }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-center">
                                    <button class="btn waves-effect waves-light btn-danger" (click)="cancelProgress()">
                                        Cancel
                                    </button>
                                    &nbsp;
                                    <button (click)="nextTab(1)" class="btn waves-effect waves-light btn-navigation">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </tab>
                        <!-- Added Fields -->
                        <tab heading="Fields" id="fields_tab">
                            <div class="row">
                                <div class="col-12 d-lg-none mb-2">
                                    <button type="button"
                                        class="btn btn-sm btn-add-field waves-effect waves-light btn-secondary collapsed"
                                        data-toggle="collapse" data-target="#collapseSubFields" aria-expanded="false"
                                        aria-controls="collapseSubFields">
                                        <i class="fal fa-plus"></i>
                                        <span class="mx-1">Add Fields</span>
                                    </button>
                                    <div class="collapse mt-1" id="collapseSubFields">
                                        <div class="card mb-0">
                                            <div class="card-body field-body">
                                                <div class="create-custom-fields d-flex">
                                                    <button data-toggle="modal" (click)="openfieldform(null)"
                                                        class="btn btn-sm waves-effect waves-light btn-info my-1">
                                                        Create New Field
                                                    </button>
                                                    <!--<button data-toggle="modal" (click)="openSectionForm()" class="btn btn-sm btn-primary mx-1 my-1">Create New Section</button>-->
                                                    <button data-toggle="modal" data-target="#documentform"
                                                        class="btn btn-sm waves-effect waves-light btn-secondary mx-1 my-1">
                                                        Select file
                                                    </button>
                                                </div>
                                                <div class="form-group">
                                                    <label for="searchLabel" class="font-500">Search Label:</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="search_eForm_labels" placeholder="Search Label" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="filterByCountry" class="font-500">Filter by
                                                        Country:</label>
                                                    <select class="form-control custom-select"
                                                        [(ngModel)]="filter_country" (change)="globalprofile()">
                                                        <option *ngFor="let country of filter_country_options"
                                                            value="{{ country.value }}">
                                                            {{ country.label }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!--<ng-select [(ngModel)]="filter_country">
                                                   <ng-option *ngFor="let country of filter_country_options" [value]="country.value">{{country.label}}</ng-option>
                                                   </ng-select>-->
                                                <div class="multi-fields">
                                                    <div *ngFor="
                                                            let data of globallist
                                                                | filter
                                                                    : search_eForm_labels;
                                                            let i = index
                                                        " class="mx-1 my-1">
                                                        <button type="button" data-toggle="modal" (click)="
                                                                openfieldform(
                                                                    data
                                                                )
                                                            "
                                                            class="btn btn-sm waves-effect waves-light btn-outline-secondary"
                                                            [ngClass]="{
                                                                'btn-outline-info':
                                                                    data.fieldType ===
                                                                    'custom'
                                                            }">
                                                            {{ data.labelname }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-8 pr-lg-0">
                                    <div class="card card-form mb-0 card-outline-info">
                                        <!--<div class="card-header">
                                           Generate eForm
                                           </div>-->
                                        <div class="card-header">
                                            <h4 class="m-b-0 text-white">
                                                Generate eForm
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div *ngIf="fields?.length > 0 || title != ''">
                                                <h3 *ngIf="title" class="text-center form-title"> {{title}}
                                                    &nbsp;&nbsp;
                                                    <button class="custom-btn" (click)="editTitle('title')">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button class="custom-btn" (click)="deleteTitle('title')">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </h3>
                                                <!-- <h5 *ngIf="subtitle" class="text-left form-title"> {{subtitle}}
                                                    &nbsp;&nbsp;
                                                    <button class="custom-btn" (click)="editTitle('subtitle')">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button class="custom-btn" (click)="deleteTitle('subtitle')">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </h5> -->
                                                <div *ngFor="
                                                        let data of fields;
                                                        let i = index
                                                    ">
                                                    <!-- Section Label -->
                                                    <!--<div class="section-body" *ngIf="data && data.section_lable && (i === 0 || (i>0 && fields[i-1].section_lable != fields[i].section_lable) )">
                                                        <h3 class="section-label">Section Lable:&nbsp;{{data?.section_lable}}</h3>
                                                        <div class="inline-block" *ngIf="data && data.section_title && (i === 0 || (i>0 && fields[i-1].section_title != fields[i].section_title))">
                                                            <div *ngIf="i > 0">
                                                                <br />
                                                            </div>
                                                            <button class="btn btn-section-delete" (click)="deleteele(data.section_title,'all')">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>-->
                                                    <!--<div class="position-relative form-group section-title"
                                                         *ngIf="data.section_title && (i === 0 || (i>0 && fields[i-1].section_title != fields[i].section_title) )">
                                                        <h4 class="m-0">-->
                                                    <!-------<input type="radio" disabled name={{data.section_title}} *ngIf="data.section_type === 'radio'">
                                                    <input type="checkbox" disabled name={{data.section_title}} *ngIf="data.section_type === 'checkbox'">-------->
                                                    <!--{{data?.section_title}}
                                                        </h4>
                                                    </div>-->
                                                    <div class="form-group row">
                                                        <!-- Label Name -->
                                                        <!-- {{data | json}} -->
                                                        <h5 *ngIf="data.keytype == 'subtitle'"
                                                            class="text-left form-title"> {{data.labelname}}
                                                            &nbsp;&nbsp;
                                                            <button class="custom-btn" (click)="editTitle('subtitle')">
                                                                <i class="fa fa-edit"></i>
                                                            </button>
                                                            &nbsp;
                                                            <button class="custom-btn"
                                                                (click)="deleteTitle('subtitle')">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </h5>
                                                        <label *ngIf="data.keytype != 'subtitle'"
                                                            for="{{ data.labelname }}"
                                                            class="col-12 col-md-4 col-form-label font-500">
                                                            {{ data?.labelname }}
                                                        </label>
                                                        <!-- Input Tags -->
                                                        <div *ngIf="data.keytype != 'subtitle'"
                                                            class="col-12 col-md-8 position-relative"
                                                            [ngSwitch]="data.keytype">
                                                            <!-- Radio Field -->
                                                            <div *ngSwitchCase="'radio'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div *ngFor="
                                    let option of data.options;
                                    let j = index
                                  ">
                                                                    <div>
                                                                        <input class="with-gap radio-col-light-blue"
                                                                            type="radio" id="{{ option.uid }}"
                                                                            name="{{ data.labelname }}"
                                                                            value="{{ option.lable }}"
                                                                            (click)="onclick($event, i, j)" [checked]="
                                        option.checked == true ? true : false
                                      " />
                                                                        <label class="form-check-label"
                                                                            for="{{ option.value }}">{{ option?.lable
                                                                            }}</label>
                                                                    </div>
                                                                    <button (click)="editfieldForm(data, i)"
                                                                        class="btn btn-form-edit">
                                                                        <i class="fa fa-edit"></i>
                                                                    </button>
                                                                    <button (click)="deleteele(i, '')"
                                                                        class="btn btn-form-delete">
                                                                        <i class="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <!-- Checkbox Field -->
                                                            <div *ngSwitchCase="'checkbox'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div *ngFor="
                                    let option of data.options;
                                    let j = index
                                  ">
                                                                    <div>
                                                                        <input type="checkbox" id="{{ option.uid }}"
                                                                            name="{{ data.labelname }}"
                                                                            value="{{ option.lable }}"
                                                                            (click)="checkbox($event, i, j)" [checked]="
                                        option.checked == true ? true : false
                                      " />
                                                                        <label for="{{ option.value }}">
                                                                            &nbsp; {{ option.lable }}</label>
                                                                    </div>
                                                                    <button (click)="editfieldForm(data, i)"
                                                                        class="btn btn-form-edit">
                                                                        <i class="fa fa-edit"></i>
                                                                    </button>
                                                                    <button (click)="deleteele(i, '')"
                                                                        class="btn btn-form-delete">
                                                                        <i class="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <!-- Select Field -->
                                                            <div *ngSwitchCase="'select'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div>
                                                                    <select class="form-control custom-select">
                                                                        <option *ngFor="let option of data.options"
                                                                            value="{{ option.key }}"
                                                                            id="{{ option.uid }}">
                                                                            {{ option.lable }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <button (click)="editfieldForm(data, i)"
                                                                    class="btn btn-form-edit">
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                                <button (click)="deleteele(i, '')"
                                                                    class="btn btn-form-delete">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                            <!-- List Field -->
                                                            <div *ngSwitchCase="'list'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div>
                                                                    <select class="form-control custom-select">
                                                                        <option *ngFor="let option of data.options"
                                                                            value="{{ option.key }}"
                                                                            id="{{ option.uid }}">
                                                                            {{ option.lable }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <button (click)="editfieldForm(data, i)"
                                                                    class="btn btn-form-edit">
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                                <button (click)="deleteele(i, '')"
                                                                    class="btn btn-form-delete">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                            <!-- Common Field -->
                                                            <div *ngSwitchDefault [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div>
                                                                    <input type="{{ data.keytype }}"
                                                                        class="form-control"
                                                                        placeholder="{{ data.keytype }}"
                                                                        id="{{ data.key }}"
                                                                        value="{{ data.field_value }}"
                                                                        (change)="onChangeEvent(i, $event, '')" />
                                                                    <select class="form-control custom-select-select"
                                                                        [(ngModel)]="data.date_type"
                                                                        *ngIf="data.keytype == 'date'">
                                                                        <option *ngFor="let fields of date_type_option"
                                                                            value="{{ fields.value }}">
                                                                            {{ fields.label }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <button (click)="editfieldForm(data, i)"
                                                                    class="btn btn-form-edit">
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                                <button (click)="deleteele(i, '')"
                                                                    class="btn btn-form-delete">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                            <!-- Message Field -->
                                                            <div *ngIf="data.keytype == 'text'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Field needs to be
                                                                        verified:</span>
                                                                    {{ data.file_verified }}
                                                                </div>
                                                            </div>
                                                            <!-- VerificationField Field -->
                                                            <div *ngIf="data.keytype == 'string'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Field needs to be verified:
                                                                    </span>
                                                                    {{ data.file_verified }}
                                                                </div>
                                                            </div>
                                                            <!-- Textarea Field -->
                                                            <div *ngSwitchCase="'textarea'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <textarea id="textarea" name="textarea" rows="2"
                                                                    class="w-100 form-control"
                                                                    placeholder="{{ data.keytype }}"></textarea>
                                                                <button (click)="editfieldForm(data, i)"
                                                                    class="btn btn-form-edit">
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                                <button (click)="deleteele(i, '')"
                                                                    class="btn btn-form-delete">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                            <!-- File Verification Field -->
                                                            <div *ngIf="data.keytype == 'file'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Signature required:</span>
                                                                    {{ data.signature_required }}
                                                                </div>
                                                                <div class="my-2">
                                                                    <span class="font-500">
                                                                        File needs to be verified:</span>
                                                                    {{ data.file_verified }}
                                                                </div>
                                                            </div>
                                                            <!--Grade Fields-->
                                                            <div *ngIf="data.file_verified == 'yes'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Verification Grade:
                                                                    </span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'G'">Goverment</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'F'">Financial</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'T'">Telecom</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'A'">AKcess</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'O'">Other</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="note.length > 0">
                                                    <p *ngFor="let item of note;let i = index">
                                                        {{item}}
                                                        &nbsp;&nbsp;
                                                        <button class="custom-btn" (click)="editNote(item,i)">
                                                            <i class="fa fa-edit"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button class="custom-btn" (click)="deleteNote(i)">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-center">
                                            <button class="btn waves-effect waves-light btn-danger"
                                                (click)="cancelProgress()">
                                                Cancel
                                            </button>
                                            &nbsp;
                                            <button (click)="prevTab(0)"
                                                class="btn waves-effect waves-light btn-navigation mx-2">
                                                Previous
                                            </button>
                                            <!--<button (click)="updateform()" class="btn waves-effect waves-light btn-info mx-2">Save</button>-->
                                            <button (click)="reset()"
                                                class="btn waves-effect waves-light btn-danger mx-2">
                                                Reset
                                            </button>
                                            <button (click)="nextTab(2)"
                                                class="btn waves-effect waves-light btn-navigation mx-2">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- Search/Add Fields -->
                                <div class="d-none d-lg-block col-lg-4">
                                    <div class="card card-outline-info right-bar-filter mb-0">
                                        <div class="card-header">
                                            <h4 class="m-b-0 text-white">Add Fields</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group create-custom-fields d-flex justify-content-between">
                                                <button data-toggle="modal" (click)="openfieldform(null)"
                                                    class="btn btn-sm btn-add-form waves-effect waves-light btn-info mx-1 my-1">
                                                    Create New Field
                                                </button>
                                                <!--<button data-toggle="modal" (click)="openSectionForm()" class="btn btn-sm btn-primary mx-1 my-1">Create New Section</button>-->
                                                <!--<button data-toggle="modal" data-target="#documentform" class="btn btn-sm waves-effect waves-light btn-secondary mx-1 my-1">Select file</button>-->
                                                <button data-toggle="modal" (click)="openSectionModal(null)"
                                                    class="btn btn-sm btn-add-form waves-effect waves-light btn-info mx-1 my-1">
                                                    Create Section
                                                </button>
                                                <button data-toggle="modal" (click)="openCreateDocumentModal('file')"
                                                    class="btn btn-sm btn-add-form waves-effect waves-light btn-info mx-1 my-1">
                                                    Create File Field
                                                </button>
                                                <button data-toggle="modal" (click)="openCreateDocumentModal('id')"
                                                    class="btn btn-sm btn-add-form waves-effect waves-light btn-info mx-1 my-1">
                                                    Create ID Document Field
                                                </button>
                                                <!-- <button data-toggle="modal" (click)="openCreateDocumentModal('text')"
                                                    class="btn btn-sm btn-add-form waves-effect waves-light btn-info mx-1 my-1">
                                                    Add Text
                                                </button> -->
                                                <!-- <button data-toggle="modal" (click)="openCreateDocumentModal('id')"
                                                    class="btn btn-sm btn-add-form waves-effect waves-light btn-info mx-1 my-1">
                                                    Upload File
                                                </button> -->
                                            </div>
                                            <div class="form-group">
                                                <label for="searchLabel" class="font-500">Search Label:</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="search_eForm_labels" placeholder=" Search Label" />
                                            </div>
                                            <div class="form-group">
                                                <label for="filterByCountry" class="font-500">Filter by Country:</label>
                                                <select class="form-control custom-select" [(ngModel)]="filter_country"
                                                    (change)="globalprofile()">
                                                    <option *ngFor="let country of filter_country_options"
                                                        value="{{ country.value }}">
                                                        {{ country.label }}
                                                    </option>
                                                </select>
                                            </div>
                                            <!--<ng-select [(ngModel)]="filter_country">
                                               <ng-option *ngFor="let country of filter_country_options" [value]="country.value">{{country.label}}</ng-option>
                                               </ng-select>-->
                                            <div class="multi-fields">
                                                <div *ngFor="
                                                        let data of globallist
                                                            | filter
                                                                : search_eForm_labels;
                                                        let i = index
                                                    " class="mx-1 my-1">
                                                    <button type="button" data-toggle="modal" (click)="
                                                            openfieldform(data)
                                                        "
                                                        class="btn btn-sm waves-effect waves-light btn-outline-secondary"
                                                        [ngClass]="{
                                                            'btn-outline-info':
                                                                data.fieldType ===
                                                                'custom'
                                                        }">
                                                        {{ data.labelname }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="Notifications" id="notification_tab">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card card-form mb-0">
                                        <div class="card-body">
                                            <div class="form-group row align-items-center">
                                                <label class="col-sm-2 col-md-5 col-form-label font-500">
                                                    Additional notification ?
                                                </label>
                                                <div class="col-sm-10 col-md-7 d-flex mb-0">
                                                    <div class="form-check">
                                                        <!--<input class="form-check-input"
                                                           type="radio"
                                                           id="an_yes"
                                                           name="additionalNotification"
                                                           [(ngmodel)]="additionalNotification"
                                                           (change)="addionalNotificalModal($event.target.value)"
                                                           [checked]="additionalNotification == 'yes'"
                                                           value="yes" />
                                                           <label class="form-check-label" for="an_yes">Yes</label>-->
                                                        <input type="radio" [(ngModel)]="
                                                                additionalNotification
                                                            " value="yes" id="an_yes" name="additionalNotification"
                                                            class="with-gap radio-col-light-blue" (change)="
                                                                addionalNotificalModal(
                                                                    $event
                                                                        .target
                                                                        .value
                                                                )
                                                            " [checked]="
                                                                additionalNotification ==
                                                                'yes'
                                                            " />
                                                        <label for="an_yes" class="font-500">Yes</label>
                                                    </div>
                                                    <div class="form-check ml-3">
                                                        <!--<input class="form-check-input"
                                                           type="radio"
                                                           id="an_no"
                                                           name="additionalNotification"
                                                           [(ngmodel)]="additionalNotification"
                                                           (change)="addionalNotificalModal($event.target.value)"
                                                           [checked]="additionalNotification == 'no'"
                                                           value="no" />
                                                           <label class="form-check-label" for="an_no">No</label>-->
                                                        <input type="radio" [(ngModel)]="additionalNotification"
                                                            value="no" id="an_no" name="additionalNotification"
                                                            class="with-gap radio-col-light-blue" (change)="
                                addionalNotificalModal($event.target.value)
                              " [checked]="additionalNotification == 'no'" />
                                                        <label for="an_no" class="font-500">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="additional-notification-list mt-3" *ngIf="
                          additionalNotificationTo &&
                          additionalNotificationTo.length > 0
                        ">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <label class="font-500">Additional Notification User
                                                            List</label>
                                                    </div>
                                                    <div class="col-4 text-right" style="margin: 5px 0px;">
                                                        <button (click)="addionalNotificalModal('yes')"
                                                            class="btn waves-effect waves-light btn-info mx-2">
                                                            Add Users
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 table-responsive">
                                                        <table
                                                            class="table color-bordered-table info-bordered-table table-hover table-sm"
                                                            mdbTable #tableEl="mdbTable" small="true" hover="true">
                                                            <thead>
                                                                <tr>
                                                                    <th>AKcess Id</th>
                                                                    <th>Email</th>
                                                                    <th>Mobile</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let data of additionalNotificationTo">
                                                                    <td>{{ data?.akcessId }}</td>
                                                                    <td>{{ data?.email }}</td>
                                                                    <td>{{data?.mobile}}</td>
                                                                    <td>
                                                                        <button type="button"
                                                                            class="btn btn-sm btn-action-icon blue-action"
                                                                            (click)="removeUserSearchDetials(data)">
                                                                            <i class="fal fa-times" tooltip="Remove"
                                                                                container="body" placement="bottom"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot class="w-100">
                                                                <tr>
                                                                    <td colspan="4"></td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-center">
                                            <button class="btn waves-effect waves-light btn-danger"
                                                (click)="cancelProgress()">
                                                Cancel
                                            </button>
                                            &nbsp;
                                            <button (click)="prevTab(1)"
                                                class="btn waves-effect waves-light btn-navigation mx-2">
                                                Previous
                                            </button>
                                            <button (click)="nextTab(3)"
                                                class="btn waves-effect waves-light btn-navigation mx-2">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="Preview" id="preview_tab">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card card-form mb-0">
                                        <!--<div class="card-header">
                                           Generate eForm
                                           </div>-->
                                        <div class="card-body">
                                            <div *ngIf="fields?.length > 0">
                                                <h3 *ngIf="title" class="text-center form-title">
                                                    {{title}}
                                                </h3>
                                                <h5 *ngIf="subtitle" class="text-left form-title">
                                                    {{subtitle}}
                                                </h5>
                                                <div *ngFor="
                                                        let data of fields;
                                                        let i = index
                                                    ">
                                                    <!-- Section Label -->
                                                    <!--<div class="section-body" *ngIf="data && data.section_lable && (i === 0 || (i>0 && fields[i-1].section_lable != fields[i].section_lable) )">
                                                        <h3 class="section-label">Section Lable:&nbsp;{{data?.section_lable}}</h3>
                                                        <div class="inline-block" *ngIf="data && data.section_title && (i === 0 || (i>0 && fields[i-1].section_title != fields[i].section_title))">
                                                            <div *ngIf="i > 0">
                                                                <br />
                                                            </div>
                                                            <button class="btn btn-section-delete" (click)="deleteele(data.section_title,'all')">
                                                                <i class="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>-->
                                                    <!--<div class="position-relative form-group section-title"
                                                     *ngIf="data.section_title && (i === 0 || (i>0 && fields[i-1].section_title != fields[i].section_title) )">
                                                    <h4 class="m-0">-->
                                                    <!-------<input type="radio" disabled name={{data.section_title}} *ngIf="data.section_type === 'radio'">
                                                    <input type="checkbox" disabled name={{data.section_title}} *ngIf="data.section_type === 'checkbox'">-------->
                                                    <!--{{data?.section_title}}
                                                        </h4>
                                                    </div>-->
                                                    <div class="form-group row">
                                                        <!-- Label Name -->
                                                        <label for="{{
                                                                data.labelname
                                                            }}" class="col-12 col-md-4 col-form-label font-500">
                                                            {{
                                                            data?.labelname
                                                            }}
                                                        </label>
                                                        <!-- Input Tags -->
                                                        <div class="col-12 col-md-8 position-relative" [ngSwitch]="
                                                                data.keytype
                                                            ">
                                                            <!-- Radio Field -->
                                                            <div *ngSwitchCase="
                                                                    'radio'
                                                                " [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <div *ngFor="
                                                                        let option of data.options;
                                                                        let j = index
                                                                    ">
                                                                    <div>
                                                                        <input class="with-gap radio-col-light-blue"
                                                                            type="radio" id="{{
                                                                                option.uid
                                                                            }}" name="{{
                                                                                data.labelname
                                                                            }}" value="{{
                                                                                option.lable
                                                                            }}" (click)="
                                                                                onclick(
                                                                                    $event,
                                                                                    i,
                                                                                    j
                                                                                )
                                                                            " [checked]="
                                                                                option.checked ==
                                                                                true
                                                                                    ? true
                                                                                    : false
                                                                            " />
                                                                        <label class="form-check-label" for="{{
                                                                                option.value
                                                                            }}">{{
                                                                            option?.lable
                                                                            }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- Checkbox Field -->
                                                            <div *ngSwitchCase="
                                                                    'checkbox'
                                                                " [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <div *ngFor="
                                                                        let option of data.options;
                                                                        let j = index
                                                                    ">
                                                                    <div>
                                                                        <input type="checkbox" id="{{
                                                                                option.uid
                                                                            }}" name="{{
                                                                                data.labelname
                                                                            }}" value="{{
                                                                                option.lable
                                                                            }}" (click)="
                                                                                checkbox(
                                                                                    $event,
                                                                                    i,
                                                                                    j
                                                                                )
                                                                            " [checked]="
                                                                                option.checked ==
                                                                                true
                                                                                    ? true
                                                                                    : false
                                                                            " />
                                                                        <label for="{{
                                                                                option.value
                                                                            }}">
                                                                            &nbsp;
                                                                            {{
                                                                            option.lable
                                                                            }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- Select Field -->
                                                            <div *ngSwitchCase="
                                                                    'select'
                                                                " [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <div>
                                                                    <select class="form-control custom-select">
                                                                        <option *ngFor="
                                                                                let option of data.options
                                                                            " value="{{
                                                                                option.key
                                                                            }}" id="{{
                                                                                option.uid
                                                                            }}">
                                                                            {{
                                                                            option.lable
                                                                            }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <!-- List Field -->
                                                            <div *ngSwitchCase="
                                                                    'list'
                                                                " [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <div>
                                                                    <select class="form-control custom-select">
                                                                        <option *ngFor="
                                                                                let option of data.options
                                                                            " value="{{
                                                                                option.key
                                                                            }}" id="{{
                                                                                option.uid
                                                                            }}">
                                                                            {{
                                                                            option.lable
                                                                            }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <!-- Common Field -->
                                                            <div *ngSwitchDefault [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <div>
                                                                    <input type="{{
                                                                            data.keytype
                                                                        }}" class="form-control" placeholder="{{
                                                                            data.keytype
                                                                        }}" id="{{
                                                                            data.key
                                                                        }}" value="{{
                                                                            data.field_value
                                                                        }}" (change)="
                                                                            onChangeEvent(
                                                                                i,
                                                                                $event,
                                                                                ''
                                                                            )
                                                                        " />
                                                                    <select class="form-control custom-select-select"
                                                                        [(ngModel)]="
                                                                            data.date_type
                                                                        " *ngIf="
                                                                            data.keytype ==
                                                                            'date'
                                                                        ">
                                                                        <option *ngFor="
                                                                                let fields of date_type_option
                                                                            " value="{{
                                                                                fields.value
                                                                            }}">
                                                                            {{
                                                                            fields.label
                                                                            }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <!-- Message Field -->
                                                            <div *ngIf="
                                                                    data.keytype ==
                                                                    'text'
                                                                " [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Field
                                                                        needs to
                                                                        be
                                                                        verified:</span>
                                                                    {{
                                                                    data.file_verified
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <!-- VerificationField Field -->
                                                            <div *ngIf="
                                                                    data.keytype ==
                                                                    'string'
                                                                " [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Field
                                                                        needs to
                                                                        be
                                                                        verified:
                                                                    </span>
                                                                    {{
                                                                    data.file_verified
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <!-- Textarea Field -->
                                                            <div *ngSwitchCase="
                                                                    'textarea'
                                                                " [ngStyle]="
                                                                    data.section_color
                                                                        ? {
                                                                              'background-color':
                                                                                  data.section_color
                                                                          }
                                                                        : {
                                                                              'background-color':
                                                                                  ''
                                                                          }
                                                                ">
                                                                <textarea class="w-100 form-control" id="textarea"
                                                                    name="textarea" rows="2"></textarea>
                                                                <!--<button (click)="deleteele(i,'')" class="btn btn-form-delete">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>-->
                                                            </div>
                                                            <!-- File Verification Field -->
                                                            <div *ngIf="data.keytype == 'file'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Signature required:</span>
                                                                    {{ data.signature_required }}
                                                                </div>
                                                                <div class="my-2">
                                                                    <span class="font-500">
                                                                        File needs to be verified:</span>
                                                                    {{ data.file_verified }}
                                                                </div>
                                                            </div>
                                                            <!--Grade Fields-->
                                                            <div *ngIf="data.file_verified == 'yes'" [ngStyle]="
                                  data.section_color
                                    ? { 'background-color': data.section_color }
                                    : { 'background-color': '' }
                                ">
                                                                <div class="my-2">
                                                                    <span class="font-500">Verification Grade:
                                                                    </span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'G'">Goverment</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'F'">Financial</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'T'">Telecom</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'A'">AKcess</span>
                                                                    <span
                                                                        *ngIf="data.verification_grade == 'O'">Other</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="note.length > 0">
                                                    <p *ngFor="let item of note;let i = index">
                                                        {{item}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-center">
                                            <button class="btn waves-effect waves-light btn-danger"
                                                (click)="cancelProgress()">
                                                Cancel
                                            </button>
                                            <button (click)="prevTab(2)"
                                                class="btn waves-effect waves-light btn-navigation mx-2">
                                                Previous
                                            </button>
                                            <button (click)="updateform()"
                                                class="btn waves-effect waves-light btn-info mx-2">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- Select Document Field -->
<div class="modal" id="documentform" tabindex="-1" role="dialog" area-labelledby="documentformModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="documentformModalLabel" *ngIf="this.documentType == 'file'">
                    Create File Upload Field
                </h4>
                <h4 class="modal-title" id="documentformModalLabel" *ngIf="this.documentType == 'id'">
                    Create Id Upload Field
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group" *ngIf="this.documentType == 'file'">
                    <label class="font-500">Label Name</label>
                    <input type="text" class="form-control" [(ngModel)]="document_label"
                        placeholder="Enter label name" />
                </div>
                <div class="form-group" *ngIf="this.documentType == 'id'">
                    <label class="font-500">Select ID Type</label>
                    <select class="form-control custom-select" name="id_label" [(ngModel)]="id_label">
                        <option [ngValue]="null" disabled>
                            Select your option
                        </option>
                        <option value="dl">Driving Licence</option>
                        <option value="pp-f">Passport - Front</option>
                        <option value="pp-b">Passport - Back</option>
                    </select>
                </div>
                <div class="row align-items-center" *ngIf="this.documentType == 'file'">
                    <label class="col-12 col-md-7 col-form-label font-500">Do you want to store this custom
                        field?</label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <div class="custom-control custom-radio">
                            <input class="with-gap radio-col-light-blue" type="radio" id="storeFieldFileYes"
                                name="storeFieldFile" [(ngModel)]="storeFieldFile" value="yes" />
                            <label for="storeFieldFileYes">Yes</label>
                        </div>
                        <div class="custom-control custom-radio mx-3">
                            <input class="with-gap radio-col-light-blue" type="radio" id="storeFieldFileNo"
                                name="storeFieldFile" [(ngModel)]="storeFieldFile" value="no" />
                            <label for="storeFieldFileNo">No</label>
                        </div>
                        <div class="information-btn"
                            tooltip="This will save your field in the fields list and can be used again in the future."
                            placement="top" container="body">
                            <i class="fas fa-info-circle"></i>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <label class="col-12 col-md-7 col-form-label font-500">Required digital signature?</label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <div class="custom-control custom-radio">
                            <input type="radio" [(ngModel)]="signature_required" value="yes" id="signreq_yes"
                                class="with-gap radio-col-light-blue" name="signature_required" />
                            <label for="signreq_yes" class="font-500">Yes</label>
                        </div>
                        <div class="custom-control custom-radio ml-3">
                            <input type="radio" [(ngModel)]="signature_required" value="no" id="signreq_no"
                                class="with-gap radio-col-light-blue" name="signature_required" />
                            <label for="signreq_no" class="font-500">No</label>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <label class="col-12 col-md-7 col-form-label font-500">
                        Field must be verified?
                    </label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <div class="custom-control custom-radio">
                            <input type="radio" [(ngModel)]="file_verified" value="yes" id="filever_yes"
                                class="with-gap radio-col-light-blue" name="file_verified" />
                            <label for="filever_yes" class="font-500">Yes</label>
                        </div>
                        <div class="custom-control custom-radio ml-3">
                            <input type="radio" [(ngModel)]="file_verified" value="no" id="filever_no"
                                class="with-gap radio-col-light-blue" name="file_verified" />
                            <label for="filever_no" class="font-500">No</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="file_verified == 'yes'" class="row align-items-center">
                    <label class="col-12 col-md-7 col-form-label font-500">What grade?</label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <select class="form-control custom-select" [(ngModel)]="verification_grade">
                            <option disabled>Select Grade</option>
                            <option *ngFor="let grade of grade" value="{{ grade.value }}">
                                {{ grade.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info"
                    (click)="addFileFieldForm(this.document_label)" *ngIf="this.documentType == 'file'">
                    Add File
                </button>
                <button type="button" class="btn waves-effect waves-light btn-info"
                    (click)="addFileFieldForm(this.id_label)" *ngIf="this.documentType == 'id'">
                    Add ID
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="documentformclose"
                    data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Create New Field modal -->
<div class="modal" id="fieldform" tabindex="-1" role="dialog" area-labelledby="fieldformModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="fieldformModalLabel">
                    Select field options
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="font-500">Label Name</label>
                    <input type="text" class="form-control" *ngIf="readOnlyField == true" [(ngModel)]="field_label"
                        placeholder="{{ field_label }}" />
                    <input type="text" class="form-control" *ngIf="readOnlyField == false" [(ngModel)]="field_label"
                        placeholder="{{ field_label }}" />
                </div>
                <div class="form-group">
                    <label class="font-500">Instructions</label>
                    <!--<textarea class="form-control"
                    [(ngModel)]="field_label_instructions"
                    placeholder="Instructions"
                    rows="3"></textarea>-->
                    <input type="text" class="form-control" [(ngModel)]="field_label_instructions"
                        placeholder="Instructions" />
                </div>
                <div class="form-group" *ngIf="readOnlyField == true && saveEditField == false">
                    <label class="font-500">Field Type</label>
                    <input type="text" class="form-control" value="{{ field_type }}" />
                </div>

                <div *ngIf="saveEditField == true">
                    <div class="form-group">
                        <label class="font-500">Field Type</label>
                        <select class="form-control" [(ngModel)]="field_type" (change)="reset('tag')">
                            <option *ngFor="let fields of field_type_option" value="{{ fields.value }}">
                                {{ fields.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div *ngIf="readOnlyField == false && saveEditField == false">
                    <div class="form-group" *ngIf="model_tag == 'createfield'">
                        <label class="font-500">Field Type</label>
                        <select class="form-control" [(ngModel)]="field_type" (change)="reset('tag')">
                            <option *ngFor="let fields of field_type_option" value="{{ fields.value }}">
                                {{ fields.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group" *ngIf="field_type == 'date'">
                    <label class="font-500">Date Type</label>
                    <select class="form-control custom-select" [(ngModel)]="date_type">
                        <option *ngFor="let fields of date_type_option" value="{{ fields.value }}">
                            {{ fields.label }}
                        </option>
                    </select>
                </div>
                <div *ngIf="optionsArray.length == 0">
                    <div class="form-group" *ngIf="
                            field_type == 'select' ||
                            field_type == 'radio' ||
                            field_type == 'checkbox'
                        ">
                        <label class="font-500">Field Options</label>
                        <ngx-tags-input class="form-control" [(ngModel)]="tags" name="tags"
                            (onTagsChanged)="onTagsChanged($event, '')" onNoOptionsMatch="true"
                            placeholder="Field Options"></ngx-tags-input>
                        <div class="valid-feedback">
                            Press enter after input the field.
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="optionsArray.length > 0">
                    <ul>
                        <li *ngFor="let option of optionsArray; let i = index" style="list-style-type: none">
                            <div *ngIf="field_type == 'checkbox'">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="{{ option.uid }}"
                                        id="{{ option.uid }}" />
                                    <label class="form-check-label" for="{{ option.uid }}">
                                        {{ option.lable }}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="field_type == 'radio'">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="{{ option.uid }}"
                                        value="{{ option.uid }}" />
                                    <label class="form-check-label" for="{{ option.uid }}">
                                        {{ option.lable }}
                                    </label>
                                </div>
                            </div>
                            <div *ngIf="field_type == 'select'">
                                {{ option.lable }}
                            </div>
                        </li>
                    </ul>
                </div>
                <!--<div class="form-group" *ngIf="!is_section && field_type =='radio'">
                <div class="custom-control custom-radio" *ngFor="let tags of tags;let i = index">
                    <input type="radio" class="custom-control-input" name="tags.displayValue" *ngIf="field_type === 'radio'">
                    <label class="custom-control-label">{{tags.displayValue}}</label>
                </div>
                </div>-->
                <div class="row align-items-center" *ngIf="readOnlyField == false">
                    <label class="col-12 col-md-7 col-form-label font-500">Do you want to store this custom
                        field?</label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <div class="form-check">
                            <input class="with-gap radio-col-light-blue" type="radio" id="storeFieldYes"
                                name="storeField" [(ngModel)]="storeField" value="yes" />
                            <label for="storeFieldYes">Yes</label>
                        </div>
                        <div class="form-check mx-3">
                            <input class="with-gap radio-col-light-blue" type="radio" id="storeFieldNo"
                                name="storeField" [(ngModel)]="storeField" value="no" />
                            <label for="storeFieldNo">No</label>
                        </div>
                        <div class="information-btn"
                            tooltip="This will save your field in the fields list and can be used again in the future."
                            placement="top" container="body">
                            <i class="fas fa-info-circle"></i>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <label class="col-12 col-md-7 col-form-label font-500">Is the field mandatory?</label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <div class="form-check">
                            <input class="with-gap radio-col-light-blue" type="radio" id="field_mandate_yes"
                                name="field_mandate_rad" [(ngModel)]="field_mandate" value="yes" />
                            <label for="field_mandate_yes">Yes</label>
                        </div>
                        <div class="form-check ml-3">
                            <input class="with-gap radio-col-light-blue" type="radio" id="field_mandate_no"
                                name="field_mandate_rad" [(ngModel)]="field_mandate" value="no" />
                            <label for="field_mandate_no">No</label>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <label class="col-12 col-md-7 col-form-label font-500">Field must be verified?</label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <div class="form-check">
                            <input class="with-gap radio-col-light-blue" type="radio" id="fieldver_yes"
                                name="field_verified" [(ngModel)]="field_verified" value="yes" />
                            <label for="fieldver_yes">Yes</label>
                        </div>
                        <div class="form-check ml-3">
                            <input class="with-gap radio-col-light-blue" type="radio" id="fieldver_no"
                                name="field_verified" [(ngModel)]="field_verified" value="no" />
                            <label for="fieldver_no">No</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="field_verified == 'yes'" class="row align-items-center">
                    <label class="col-12 col-md-7 col-form-label font-500">What grade?</label>
                    <div class="col-12 col-md-5 d-flex mb-0">
                        <select class="custom-select" [(ngModel)]="verification_grade">
                            <option disabled>Select Grade</option>
                            <option *ngFor="let grade of grade" value="{{ grade.value }}">
                                {{ grade.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="
                        (field_type === 'checkbox' || field_type === 'radio') &&
                        is_section
                    " type="button" class="btn waves-effect waves-light btn-info"
                    (click)="addfields('', this.field_type)">
                    Add
                </button>
                <button *ngIf="
                        !is_section &&
                        optionsArray.length == 0 &&
                        saveEditField == false
                    " type="button" class="btn waves-effect waves-light btn-info" (click)="
                        addinform(
                            'createdtext',
                            this.field_label,
                            this.field_label_instructions,
                            this.key,
                            this.field_type,
                            this.tags
                        )
                    ">
                    Add
                </button>
                <button *ngIf="saveEditField == true" type="button" class="btn waves-effect waves-light btn-info"
                    (click)="saveEditedFeild()">
                    Update Fields
                </button>
                <button *ngIf="!is_section && optionsArray.length > 0" type="button"
                    class="btn waves-effect waves-light btn-info" (click)="
                        addinform(
                            'createdtext',
                            this.field_label,
                            this.field_label_instructions,
                            this.key,
                            this.field_type,
                            optionsArray
                        )
                    ">
                    Add.
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="documentformclose"
                    data-dismiss="modal" (click)="(saveEditField != saveEditField)">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Create Sub Section Modal -->
<div class="modal" id="sectionfieldform" tabindex="-1" role="dialog" area-labelledby="sectionfieldformModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable create-section-modal"
        role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="sectionfieldformModalLabel">
                    Create New Section
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body background-light-blue">
                <div class="row">
                    <div class="d-lg-none col-12 mb-3">
                        <button type="button"
                            class="btn btn-sm btn-add-field waves-effect waves-light btn-secondary collapsed"
                            data-toggle="collapse" data-target="#collapseSubFieldsModal" aria-expanded="false"
                            aria-controls="collapseSubFieldsModal">
                            <i class="fal fa-plus"></i>
                            <span class="mx-1">Add Fields</span>
                        </button>
                        <div class="collapse mt-1" id="collapseSubFieldsModal">
                            <div class="card card-body field-body mb-0">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="searchLabel" class="font-500">Search Label:</label>
                                        <input type="text" class="form-control" [(ngModel)]="search_eForm_labels"
                                            placeholder="Search Label" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="filterByCountry" class="font-500">Filter by Country:</label>
                                        <select class="form-control custom-select" [(ngModel)]="filter_country"
                                            (change)="globalprofile()">
                                            <option *ngFor="
                                                    let country of filter_country_options
                                                " value="{{ country.value }}">
                                                {{ country.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="multi-fields">
                                    <div *ngFor="
                                            let data of globallist
                                                | filter : search_eForm_labels;
                                            let i = index
                                        " class="mx-1 my-1">
                                        <button type="button" data-toggle="modal" (click)="
                                                addsectionfield(
                                                    data.type,
                                                    data.labelname,
                                                    data.key,
                                                    '',
                                                    data.type,
                                                    section_title,
                                                    this.section_show
                                                )
                                            " class="btn btn-sm waves-effect waves-light btn-outline-secondary"
                                            [ngClass]="{
                                                'btn-outline-info':
                                                    data.fieldType === 'custom'
                                            }">
                                            {{ data.labelname }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 pr-lg-0">
                        <div class="card card-outline-info">
                            <div class="card-header">
                                <h4 class="m-b-0 text-white">Add New Field</h4>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <button type="button" class="btn btn-sm waves-effect waves-light btn-info" (click)="
                                            addsectionfield(
                                                'section',
                                                this.section_field_lable,
                                                this.key,
                                                this.section_field_type,
                                                this.section_tags,
                                                this.section_title,
                                                this.section_show
                                            )
                                        ">
                                        Add In Section
                                    </button>
                                </div>
                                <!-- Sub Field Section -->
                                <div class="sub-section-detail">
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">Section Name</label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <input class="form-control" [(ngModel)]="section_title" placeholder="{{
                                                    section_title
                                                }}" type="text" disabled />
                                        </div>
                                    </div>
                                    <div class="form-group row align-items-center">
                                        <label class="col-sm-2 col-md-5 col-form-label font-500">Hide Section</label>
                                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                                            <div class="form-check">
                                                <input class="with-gap radio-col-light-blue" type="radio"
                                                    name="section_show" [(ngModel)]="section_show" value="yes"
                                                    id="section_show_yes" />
                                                <label for="section_show_yes">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check ml-3">
                                                <input class="with-gap radio-col-light-blue" type="radio"
                                                    name="section_show" [(ngModel)]="section_show" value="no"
                                                    id="section_show_no" />
                                                <label for="section_show_no">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Added Sub Fields -->
                                    <div class="form-row" *ngIf="section_fields?.length > 0">
                                        <div class="form-group col-12 col-md-6" *ngFor="
                                                let data of filter_section;
                                                let i = index
                                            ">
                                            <label class="form-label font-500" for="{{ data.labelname }}">
                                                {{ data.labelname }}
                                            </label>
                                            <div [ngSwitch]="data.keytype">
                                                <!-- Sub Section Checkbox -->

                                                <!-- Sub Section Select Option -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-none d-lg-block col-lg-4">
                        <div class="card card-outline-info right-bar-filter mb-0">
                            <div class="card-header">
                                <h4 class="m-b-0 text-white">Add Fields</h4>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="searchLabel" class="font-500">Search Label:</label>
                                    <input type="text" [(ngModel)]="search_eForm_labels" class="form-control"
                                        placeholder="Search Label" />
                                </div>
                                <div class="form-group">
                                    <label for="filterByCountry" class="font-500">Filter by Country:</label>
                                    <select class="form-control custom-select" [(ngModel)]="filter_country"
                                        (change)="globalprofile()">
                                        <option *ngFor="
                                                let country of filter_country_options
                                            " value="{{ country.value }}">
                                            {{ country.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="multi-fields">
                                    <div *ngFor="
                                            let data of globallist
                                                | filter : search_eForm_labels;
                                            let i = index
                                        " class="mx-1 my-1">
                                        <button type="button" data-toggle="modal" (click)="
                                                addsectionfield(
                                                    data.type,
                                                    data.labelname,
                                                    data.key,
                                                    '',
                                                    data.type,
                                                    section_title,
                                                    this.section_show
                                                )
                                            " class="btn btn-sm waves-effect waves-light btn-secondary">
                                            {{ data.labelname }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" data-dismiss="modal"
                    (click)="addfields('section', this.field_type)">
                    Create Section
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="documentformclose"
                    (click)="hidemodal()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Notifiaciton  User Search Model -->
<div class="modal" id="prepareEform" tabindex="-1" role="dialog" aria-labelledby="prepareEformModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="prepareEformModalLabel">
                    Add Users
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="
                        additionalNotificationTo &&
                        additionalNotificationTo.length == 0
                            ? (additionalNotification = 'no')
                            : 'yes'
                    ">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <!--<p-multiSelect [style]="{width: '100%'}" [contentStyle]="{'overflow':'visible'}" [options]="userData"
                                   [(ngModel)]="userSearchData" defaultLabel="Select user" optionLabel="detail">
                                   </p-multiSelect>-->
                    <ng-select [items]="userData" [addTag]="true" bindLabel="detail" [multiple]="true" appendTo="body"
                        placeholder="Select User" [hideSelected]="true" [(ngModel)]="userSearchData">
                    </ng-select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info"
                    (click)="saveAdditionalNotification()">
                    Add
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal"
                    (click)="
                        additionalNotificationTo &&
                        additionalNotificationTo.length == 0
                            ? (additionalNotification = 'no')
                            : 'yes'
                    ">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Additional Notification: User list view modal -->
<!-- Modal -->
<div class="modal" id="additionalNotificationListView" tabindex="-1" role="dialog"
    aria-labelledby="additionalNotificationListViewModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="additionalNotificationListViewModal">
                    Additional Notification - User List
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul>
                    <li *ngFor="let data of additionalNotificationTo">
                        {{ data?.email }} {{ data?.mobile }}
                        {{ data?.akcessId }}
                        <a href="javascript: void(0)" (click)="removeUserSearchDetials(data)">
                            <i class="fa fa-times"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal">
                    Close
                </button>
                <button type="button" class="btn waves-effect waves-light btn-info">
                    Save Changes
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="addText" tabindex="-1" role="dialog" aria-labelledby="additionalNotificationListViewModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="additionalNotificationListViewModal">
                    Add Text
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="textType" class="font-500">Select Text Type:</label>
                    <select class="form-control custom-select" [(ngModel)]="textType">
                        <option value="title">Title</option>
                        <option value="subtitle">Sub-Title</option>
                        <option value="text">Text</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="addText" class="font-500">Text:</label>
                    <input type="text" class="form-control" placeholder="Enter text" [(ngModel)]="textValue" />
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="!isEdit" type="button" class="btn waves-effect waves-light btn-info" (click)="addText()">
                    Add
                </button>
                <button *ngIf="isEdit" type="button" class="btn waves-effect waves-light btn-info"
                    (click)="updateText()">
                    Update
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeAddTextModel()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>