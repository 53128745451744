import { Component, OnInit } from "@angular/core";
import { DataservicesService } from "../../../_services/dataservices.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from "moment";

@Component({
  selector: 'app-verification-logs',
  templateUrl: './verification-logs.component.html',
  styleUrls: ['./verification-logs.component.css']
})
export class VerificationLogsComponent implements OnInit {
  portalUser;
  verify_logs: any = [];
  allUsers_uc: any = [];
  body_obj = {};
  label = "";
  currentPage: any = 1
  totalCount: any = 0
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50]
  pageSize: any = 10
  constructor(private calldata: DataservicesService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getLogs();
  }

  getLogs() {
    this.spinner.show();

    this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    const tmpdata = {
      akcessId: this.portalUser.akcessId,
      usertype: this.portalUser.usertype,
      currentPage: this.currentPage,
      limit: this.pageSize
    };

    this.calldata.fetchVerificationLogs(tmpdata).subscribe(
      (data) => {
        if (data["status"] === 1) {
          this.verify_logs = data["data"];
          this.allUsers_uc = data["data"];
          this.totalCount = data["totalCount"]
          this.spinner.hide();
        } else {
          this.toast.info(data["msg"]);
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
    }
    );
  }

  dislayDate(date){
    let filter_date = moment(date).format('DD-MMM-YYYY');

    return filter_date;
  }

  showBody(obj,val){
    this.label = val;
    if(obj){
      this.body_obj = obj;
    }
  }

  searchUsers_uc(filterValue: string){
    if(filterValue!==null){
      filterValue = filterValue.toLowerCase();
      this.verify_logs = this.allUsers_uc.filter((user) => (user.akcessId && user.akcessId.toLowerCase().includes(filterValue)) || (user.apiName&&user.apiName.toLowerCase().includes(filterValue)));
    }else {
      this.verify_logs = this.allUsers_uc;
    }
  
  }

  onProjectPageChange(event){
    this.currentPage = event
    this.getLogs()
  }

  changePage(value){
    this.pageSize = parseInt(value)
    this.getLogs()
  }

}
