import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "app-create-assets",
    templateUrl: "./create.component.html",
    styleUrls: ["./create.component.css"],
})
export class CreateAssetsComponent implements OnInit {
    createAssetsForm: FormGroup;
    constructor() {}

    ngOnInit(): void {}
}
