<!--<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
    <div class="container-fluid">-->
<!-- Brand -->
<!--<a class="h3 mb-0 d-none d-lg-inline-block navbar-header-title">{{title}}</a>

        <app-profilemenu></app-profilemenu>
    </div>
</nav>
<hr class="navbar-delimiter" />-->
<!--<header class="header">
    <nav class="navbar">
        <div class="container-fluid">
            <div class="navbar-holder d-flex align-items-center justify-content-between">
                <div class="navbar-header d-flex align-items-center">
                    <a id="toggle-btn" class="menu-btn mr-2"><i class="fas fa-bars"></i></a>
                    <a routerLinkActive="active" [routerLink]="['/panel/company']" class="navbar-brand">-->
<!--<div class="brand-text d-none d-md-inline-block"><strong>Home</strong></div>-->
<!--<div class="brand-home d-none d-md-inline-block"><i class="fas fa-home"></i></div>
    </a>
</div>
<ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">-->
<!-- Profile dropdown    -->
<!--<li class="nav-item">
    <app-profilemenu></app-profilemenu>
</li>-->
<!-- Log out-->
<!--<li class="nav-item"><a href="login.html" class="nav-link logout"> <span class="d-none d-sm-inline-block">Logout</span><i class="fa fa-sign-out"></i></a></li>-->
<!--</ul>
            </div>
        </div>
    </nav>
</header>-->

<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <!--<img src="../assets/images/logo-icon.png" alt="homepage" class="dark-logo" />-->
                    <!-- Light Logo icon -->
                    <!--<img src="../assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />-->
                    <!--<img src="{{portalLogo}}" alt="{{portalLogoLabel}}" class="dark-logo">-->
                </b>
                <!--End Logo icon -->
                <!-- Logo text --><span>
                    <!-- dark Logo text -->
                    <!--<img src="../assets/images/logo-text.png" alt="homepage" class="dark-logo" />-->
                    <!-- Light Logo text -->
                    <!--<img src="../assets/images/logo-light-text.png" class="light-logo" alt="homepage" />-->
                    <img src="{{companyLargeLogoImg}}" alt="{{companyNameLabel}}" class="dark-logo">
                </span>
            </a>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto mt-md-0">
                <!--<li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark" href="javascript:void(0)"><i class="mdi mdi-menu"></i></a> </li>-->
                <li class="nav-item"> <a id="navToggler" class="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark" href="#" onclick="return false;"><i class="mdi mdi-menu"></i></a> </li>
                <!--<li class="nav-item m-l-10"> <a class="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>-->
                <li class="nav-item m-l-10"> <a id="sidebarToggler" class="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark" href="#" onclick="return false;"><i class="ti-menu"></i></a> </li>
            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- Profile -->
                <!-- ============================================================== -->
                <app-profilemenu></app-profilemenu>
            </ul>
        </div>
    </nav>
</header>
