import { Component, OnInit, Input } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { CommonService } from "src/app/_services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-assets-form",
    templateUrl: "./form.component.html",
    styleUrls: ["./form.component.css"],
})
export class AssetsFormComponent implements OnInit {
    @Input() mode: String;
    @Input() assetId: String;
    asset: any = {};
    assetForm: FormGroup;
    typeOptions: Array<any> = [];
    submitted = false;
    time = { hour: 13, minute: 30 };
    meridian = true;
    dropdownSettings: any = {};
    imageURL: string;
    uploadFiles: string[] = [];
    oldFiles: string[] = [];
    storage_url = environment.url + "storage/";
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        public commonService: CommonService,
        private router: Router,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.storage_url = this.ds.getStorageUrl();
        this.typeOptions = [
            {
                label: "Cash",
                value: "Cash",
                isDisabled: false,
            },
            {
                label: "Stocks",
                value: "Stocks",
                isDisabled: false,
            },
            {
                label: "Bonds",
                value: "Bonds",
                isDisabled: false,
            },
            {
                label: "Jewelry",
                value: "Jewelry",
                isDisabled: false,
            },
            {
                label: "Vehicles",
                value: "Vehicles",
                isDisabled: false,
            },
            {
                label: "Antiques",
                value: "Antiques",
            },
            {
                label: "Accounts Receivable",
                value: "Accounts Receivable",
                isDisabled: false,
            },
            {
                label: "Inventory",
                value: "Inventory",
                isDisabled: false,
            },
            {
                label: "Investments",
                value: "Investments",
                isDisabled: false,
            },
            {
                label: "PPE",
                value: "PPE",
                isDisabled: false,
            },
            {
                label: "Patents",
                value: "Patents",
                isDisabled: false,
            },
            {
                label: "Other",
                value: "Other",
                isDisabled: false,
            },
        ];

        if (this.assetId) {
            this.assetForm = this.fb.group({
                name: ["", Validators.required],
                type: ["", [Validators.required]],
                description: ["", [Validators.required]],
                picture: ["", []],
                documents: ["", []],
            });
            this.spinner.show();
            this.ds.getAsset(this.assetId).subscribe((response: any) => {
                this.spinner.hide();
                if (response.status) {
                    this.asset = response.data;
                    this.assetForm.patchValue({
                        name: this.asset.name,
                        type: this.asset.type,
                        description: this.asset.description,
                    });
                    if (this.asset.picture) {
                        this.imageURL = this.getFullURL(this.asset.picture);
                    }

                    if (this.asset.documents) {
                        this.oldFiles = this.asset.documents;
                    }
                } else {
                    this.toast.error(response.message);
                }
            });
        } else {
            this.assetForm = this.fb.group({
                name: ["", Validators.required],
                type: ["", [Validators.required]],
                description: ["", [Validators.required]],
                picture: ["", [Validators.required]],
                documents: ["", [Validators.required]],
            });
        }
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/asset/${file}`;
    }

    submitAssetForm() {
        const formData = new FormData();
        formData.append("name", this.assetForm.value.name);
        formData.append("type", this.assetForm.value.type);
        formData.append("description", this.assetForm.value.description);
        if (this.assetForm.value.picture) {
            formData.append("picture", this.assetForm.value.picture);
        }
        if (this.uploadFiles.length) {
            for (var i = 0; i < this.uploadFiles.length; i++) {
                formData.append("documents", this.uploadFiles[i]);
            }
        }
        this.spinner.show();
        if (this.mode == "edit") {
            if (this.oldFiles.length) {
                formData.append("oldFiles", this.oldFiles.toString());
            }
            this.ds
                .updateAsset(formData, this.assetId)
                .subscribe((response: any) => {
                    this.spinner.hide();
                    if (response.status) {
                        this.toast.info(response.message);
                        setTimeout(
                            () => this.router.navigate(["panel/assets"]),
                            2000
                        );
                    } else {
                        this.toast.error(response.message);
                    }
                });
        } else {
            this.ds.submitAssets(formData).subscribe((response: any) => {
                this.spinner.hide();
                if (response.status) {
                    this.toast.info(response.message);
                    setTimeout(
                        () => this.router.navigate(["panel/assets"]),
                        2000
                    );
                } else {
                    this.toast.error(response.message);
                }
            });
        }
    }

    // Image Preview
    showPreview(event) {
        const file = (event.target as HTMLInputElement).files[0];
        this.assetForm.patchValue({
            picture: file,
        });
        this.assetForm.get("picture").updateValueAndValidity();
        // File Preview
        const reader = new FileReader();
        reader.onload = () => {
            this.imageURL = reader.result as string;
        };
        reader.readAsDataURL(file);
    }

    onFileChange(event: any) {
        for (var i = 0; i < event.target.files.length; i++) {
            this.uploadFiles.push(event.target.files[i]);
        }
    }
}
