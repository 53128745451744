import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-view-id-card",
    templateUrl: "./view-id-card.component.html",
    styleUrls: ["./view-id-card.component.css"],
})
export class ViewIdCardComponent implements OnInit {
    filePath: string;
    fileUrl: string;
    storage_url: string;
    constructor(
        private calldata: DataservicesService,
        private _bsModalRef: BsModalRef
    ) {}

    ngOnInit(): void {
        this.storage_url = this.calldata.getStorageUrl();
        this.fileUrl = this.getFullURL(this.filePath);
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/idcard/${file}`;
    }

    closeModal() {
        this._bsModalRef.hide();
    }
}
