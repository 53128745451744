export class AccessControl {
  _id: number;
  locationName: string = "";
  numberOfStaff: string = "";
  schedules: any = [];
  address: string = "";
  onlyAllowAdmin: string = "";
  isActive: string = "";
  isDeleted: string = "";
  createdAt: string = "";
  updatedAt: string = "";
  deletedAt: string = "";
  isSelected: Boolean = false;
}
