<div class="location-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/received-docs']">Document</a>
                </li>
                <li class="breadcrumb-item active">View</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title mb-0">View Document</h3>
                </div>
                <div class="card-body">
                    <form class="document-form" [formGroup]="editDocumentForm">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="pdf-viewer">
                                    <div id="pspdfkit-container" style="width: 100%; height: 100vh">
                                        <pdf-viewer [src]="fileUrl" [render-text]="false" [original-size]="true"
                                            [fit-to-page]="true" style="display: block;"
                                            (on-progress)="onProgress($event)"></pdf-viewer>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <ul class="list-group" *ngIf="signatures.length > 0">
                                    <li class="list-group-item active">Received Signature</li>

                                    <li class="list-group-item custom-list" *ngFor="let i of signatures"
                                        style="cursor: pointer;">
                                        <a href="{{i.pdfUrl}}">
                                            <h6>{{i.fullName}} ( {{i.akcessId}} )</h6>
                                            <p>Signature received on {{i.createdAt | date}}</p>
                                        </a>
                                    </li>
                                </ul>
                                <p *ngIf="signatures.length == 0" class="text-center">No Signatures Found.</p>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="text-left mt-4">
                            <button type="submit" class="btn btn-info">
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</div>
<!-- /Page Wrapper -->