import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Config } from "./../config/config";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  // baseUrl: string = Config.url;
  baseUrlForThirdParty: string = Config.urlloginV2;
  httpOptions: any;
  httpMultipartOptions: any;
  data: any = {};
  portalUser;
  cbkToken: any = sessionStorage.getItem("ak-cbk-token");
  constructor(private http: HttpClient) {}

  // getauth() {
  //   this.httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'api': environment.apiKey,
  //       'AkcessToken': this.cbkToken,
  //       'logedInUserAckessId': sessionStorage.getItem('logedInUserAckessId')
  //     })
  //   };

  //   this.httpMultipartOptions = {
  //     headers: new HttpHeaders({
  //       'Accept': '*/*'
  //     }),
  //     responseType: 'text'
  //   };
  // }
  /**
   * @Function postData
   * This is common function to call(post) api to get data by decrypting token
   * @param apiName apiName
   * @param data
   * @Member token - token to call api
   */

  postData(baseUrl, apiName, headerFlag, data) {
    var Token;
    let headers, encryptedApi, encryptedData;
    if (headerFlag) {
      var tokenData = sessionStorage.getItem("ak-cbk-token");
      if (tokenData) {
        Token = JSON.parse(tokenData); //res
        headers = {
          "Content-Type": "application/json",
          apiKey: Config.apiKey,
          AkcessToken: tokenData,
        };
      } else {
        headers = {
          "Content-Type": "application/json",
          apiKey: Config.apiKey,
          AkcessToken: tokenData,
        };
      }
    }
    

    return (
      this.http
        .post(baseUrl + apiName, data, {
          headers: headers,
          observe: "response",
        })
        // return this.http.post(this.baseUrl + apiName, data, { headers: headers, observe: 'response' })
        .pipe(
          map((data: HttpResponse<any>) => {
            var decryptedData;
            // decryptedData = data.body
            // if (this.baseUrl == "http://20.191.208.186:5001/api/v1.1/")
            //   decryptedData = this.cryptoService.decryptData(
            //     data.body.encrypted_res
            //   );
            // else
            //  decryptedData = data.body;

            
            // if (decryptedData.statusCode != 200) {
            //   return decryptedData;
            // } else if (decryptedData.result) {
            //   var accessToken = decryptedData.result.token;
            //   if (accessToken) {
            //     localStorage.setItem(
            //       "accessTokenOfRover",
            //       JSON.stringify(accessToken)
            //     );
            //   }
            //   return decryptedData;
            // } else {
            return decryptedData;
            // }
          })
        )
    );
  }
}
