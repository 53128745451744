import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from '../../../../_services/dataservices.service';
import { RefreshContentService } from '../../../../_services/refresh-content.service';

@Component({
    selector: 'app-client-deleted',
    templateUrl: './client-deleted.component.html',
    styleUrls: ['./client-deleted.component.css']
})
export class ClientDeletedComponent implements OnInit {
    clientDeletedlist: any = [];
    //Pagination
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;

    constructor(
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        public refreshContentService: RefreshContentService,
    ) { }

    ngOnInit(): void {
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                // this.getuserData(true);
                this.getDeletedClientList();

            } else {
                // this.getuserData(true);
                this.getDeletedClientList();

            }
        });
    }
    searchInput: any = "";
    getDeletedClientList() {
        this.spinner.show();
        this.calldata.getDeletedList('client', this.searchInput).subscribe(
            (res: any) => {
                if (res.status) {
                    this.clientDeletedlist = res.data.reverse();
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //Pagination
    onProjectPageChange(event) {
        this.currentPage = event;
        // this.getuserData(true);
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        // this.getuserData(true);
    }

    //Delete Permenatly
    deletePermantly() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")))
        if (deleteIDs.length > 0) {
            if (confirm("ARE YOU SURE YOU WANT TO PERMANENTLY DELETE THE SELECTED ITEM(S)?")) {
                for (let id of deleteIDs) {
                    const data = {
                        component: "client",
                        _id: id
                    };

                    this.calldata.forceDeleteData(data).subscribe(
                        (res: any) => {
                            if (res.status) {
                                this.getDeletedClientList();
                                this.toast.success("Clients " + res.message);
                            } else {
                                this.toast.info("Clients " + res.message);
                                this.spinner.hide();
                            }
                        }, (err) => {
                            this.spinner.hide();
                            this.toast.error("Oops, something went wrong. Please try again.")
                        }
                    );
                }
            }
            this.spinner.hide();
        }
        else {
            this.toast.error("Select a client");
            this.spinner.hide();
        }

    }

    //Restore Deleted
    restoreDeleted() {
        this.spinner.show();
        let restoreIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => restoreIDs.push(item.getAttribute("id")));

        if (restoreIDs.length > 0) {
            for (let id of restoreIDs) {
                const data = {
                    component: "client",
                    _id: id
                };

                this.calldata.restoreDeletedData(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getDeletedClientList();
                            this.toast.success("Clients " + res.message);
                        } else {
                            this.toast.info("Clients " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select a client");
            this.spinner.hide();
        }

    }

}
