<!-- Information Compnay Modal -->
<div class="modal-content no-border information-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="informModalLabel">Information</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center">
            <i class="fas fa-info-circle info-logo"></i>
            <p class="ml-3 mb-0 info-msg">Your company registration has been submitted and the AKcess team will process it.
                You will receive an email notification once it is active.</p>
        </div>
    </div>
</div>