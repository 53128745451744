import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from './_services/authentication.service';
import { Router } from '@angular/router';
import { GeneralServiceService } from './_services/general-service.service';
import { GoogleApiService } from './_services/google-api.service';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
// import { LoaderService } from '../../services/loader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AKcess SME Portal';
  userActivity;
  showLoader: boolean;
  // isLoading = false;

  // verify() {
  //   this.isLoading= true;

  //   setTimeout(() => {
  //     this.isLoading =false;
  //   },2000);

  // }

  // private loading: boolean;

  // ngOnInit() {
  //   this.loaderService.status.subscribe((val: boolean) => {
  //     this.showLoader = val;
  //   });
  // }

  userInactive: Subject<any> = new Subject();
  constructor(private as: AuthenticationService, private router: Router, private spinner:NgxSpinnerService,
    // private loaderService: LoaderService,
    private gApiService: GoogleApiService) {
      
      
    if (window.location.href.toLocaleLowerCase().includes('code=')) {
      let link = window.location.href;
      let link1 = link.split('code=');
      let code = link1[1].split('&scope=');
      let data = {
        code: code[0]
      }

      

      this.gApiService.getGoogleAccessToken(data).subscribe((res: any) => {
        if (res.status == 1) {
          let excel_data = localStorage.getItem('eformreciveddata');
          this.gApiService.createGoogleSheet(res.data.access_token, excel_data).subscribe((res1: any) => {
            window.open(res1.spreadsheetUrl);
          })
        }
      })
      this.router.navigate(['/panel/eformsresponse']);
    }

    if (sessionStorage.getItem('portalUser') != null) {
      this.setTimeout("firsttime");
    }

    this.userInactive.subscribe(() => {
      if (confirm("You are inactive from long time do you want to continue login")) {
        // alert("login continue");
      } else {
        this.as.logout();
      }
    }
    );
  }

  // openSpinner(){
  //   this.spinner.show();
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   },5000);
  // }

  setTimeout(flg) {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);


  }
  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == sessionStorage) {
        let token = sessionStorage.getItem('jwt_token');
        if (sessionStorage.getItem('portalUser') == null) { // you can update this as per your key
          // DO LOGOUT FROM THIS TAB AS WELL
          this.router.navigate(['/login']); // If you are using router
          // OR
          // window.location.href = '<home page URL>';
        }
      }
    }, false);
  }
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    if (sessionStorage.getItem('portalUser') != null) {
      this.setTimeout("");
    }
  }
}
