// import { LoaderService } from '../services/loader.service';
import { BrowserModule, Title } from "@angular/platform-browser";
import {
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
} from "@angular/core";

import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { QRCodeModule } from "angularx-qrcode";
import { NgxTagsInputModule } from "ngx-tags-input";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { DashboardComponent } from "./layout/components/dashboard/dashboard.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ProfilemenuComponent } from "./layout/components/profilemenu/profilemenu.component";
import { UsersComponent } from "./layout/components/users/users.component";
import { EformsComponent } from "./layout/components/eforms/eforms.component";
import { PortalComponent } from "./layout/components/portal/portal.component";
import { AddeformComponent } from "./layout/components/addeform/addeform.component";
import { ClientsComponent } from "./layout/components/clients/clients.component";
import { LogsComponent } from "./layout/components/logs/logs.component";
import { EformsresponseComponent } from "./layout/components/eformsresponse/eformsresponse.component";
import { AccountComponent } from "./layout/components/account/account.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { EformssentComponent } from "./layout/components/eformssent/eformssent.component";
import { ProfileComponent } from "./layout/components/profile/profile.component";
import { NgxPaginationModule } from "ngx-pagination";

import { TableModule } from "primeng/table";
import { ChipsModule } from "primeng/chips";

import { TagInputModule } from "ngx-chips";
import { EformQrcodeComponent } from "./layout/components/eform-qrcode/eform-qrcode.component";
import { NotificationsComponent } from "./layout/components/notifications/notifications.component";
import { ReportsComponent } from "./layout/components/reports/reports.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LogsModalComponent } from "./layout/components/logs-modal/logs-modal.component";
import { EformResponseModalComponent } from "./layout/components/eform-response-modal/eform-response-modal.component";
import { UsersReportModalComponent } from "./layout/components/users-report-modal/users-report-modal.component";
import { UpdateFieldsComponent } from "./layout/components/update-fields/update-fields.component";
import { VerificationLogsComponent } from "./layout/components/verification-logs/verification-logs.component";
import { IdCardsComponent } from "./layout/components/id-cards/id-cards.component";
import { ChartModule } from "primeng/chart";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { TabViewModule } from "primeng/tabview";
import { FileUploadModule } from "primeng/fileupload";
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { DocumentsComponent } from "./layout/components/documents/documents.component";
import { EmployeesComponent } from "./layout/components/employees/employees.component";
import { CookieService } from "./common/cookie.service";
import { MerchantQrcodeComponent } from "./layout/components/merchant-qrcode/merchant-qrcode.component";

import { NgxDocViewerModule } from "ngx-doc-viewer";
import { UpdateIdCardComponent } from "./layout/components/update-id-card/update-id-card.component";
import { DocumentViewerComponent } from "./layout/components/document-viewer/document-viewer.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ViewerheaderComponent } from "./layout/components/viewerheader/viewerheader.component";

import { LayoutComponent } from "./layout/layout.component";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { CompanyManagementComponent } from "./layout/components/company-management/company-management.component";
import { CompanyUsersComponent } from "./layout/components/company-users/company-users.component";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { CommonService } from "./_services/common.service";
import { RefreshContentService } from "./_services/refresh-content.service";
import { CompanyProfileComponent } from "./layout/components/company-profile/company-profile.component";
import { CertificateComponent } from "./layout/components/certificate/certificate.component";
import { ViewCertificateComponent } from "./layout/components/view-certificate/view-certificate.component";
import { DocumentEditComponent } from "./layout/components/documents/edit-document/edit-document.component";
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConfirmDialogModule } from "./layout/confirm-dialog/confirm-dialog.module";

//MDBootstrap
import { MDBBootstrapModule } from "angular-bootstrap-md";
//ScrollBar
import { NgScrollbarModule } from "ngx-scrollbar";
//Fontawesome
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
//Ng-Select
import { NgSelectModule } from "@ng-select/ng-select";
//PrimeNg Theme
import { SelectButtonModule } from "primeng/primeng";
import { MultiSelectModule } from "primeng/multiselect";
//Ngx Bootstrap
import { TabsModule } from "ngx-bootstrap/tabs";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
//Ng2-Search Filter
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

//Generate ID
import { GenerateIdModalComponent } from "./layout/components/employees/generate-id-modal/generate-id-modal.component";
import { UserProfileComponent } from "./layout/components/user-profile/user-profile.component";
import { DeleteUsersComponent } from "./layout/components/company-users/delete-users/delete-users.component";
import { UploadDocumentComponent } from "./layout/components/documents/upload-document/upload-document.component";
//Employee
import { SendIdCardComponent } from "./layout/components/employees/send-id-card/send-id-card.component";
import { ViewSentIdCardComponent } from "./layout/components/employees/view-sent-id-card/view-sent-id-card.component";
import { ViewIdCardComponent } from "./layout/components/employees/view-id-card/view-id-card.component";
import { DocSendFormComponent } from "./layout/components/documents/doc-send-form/doc-send-form.component";
import { DocViewFormComponent } from "./layout/components/documents/doc-view-form/doc-view-form.component";
import { CompanyConfirmModalComponent } from "./layout/components/company-management/company-confirm-modal/company-confirm-modal.component";
import { MyEformResponsesComponent } from "./layout/components/my-eform-responses/my-eform-responses.component";
//New Register User
import { RegisterComponent } from "./register/register.component";
import { EmployeeDeletedComponent } from "./layout/components/employees/employee-deleted/employee-deleted.component";
//Client
import { ClientDeletedComponent } from "./layout/components/clients/client-deleted/client-deleted.component";
//eForms
import { EformsDeletedComponent } from "./layout/components/eforms/eforms-deleted/eforms-deleted.component";
//Certificate
import { CertificateDeletedComponent } from "./layout/components/certificate/certificate-deleted/certificate-deleted.component";
//Admin Users
import { AddUsersComponent } from "./layout/components/company-users/add-users/add-users.component";
//Certificate
import { CreateCertificateComponent } from "./layout/components/certificate/create-certificate/create-certificate.component";
import { EformresponseDeletedComponent } from "./layout/components/eformsresponse/eformresponse-deleted/eformresponse-deleted.component";
import { DocumentDeletedComponent } from "./layout/components/documents/document-deleted/document-deleted.component";
//eForm
import { SendEformModalComponent } from "./layout/components/eforms/send-eform-modal/send-eform-modal.component";
import { ViewSentEformModalComponent } from "./layout/components/eforms/view-sent-eform-modal/view-sent-eform-modal.component";
import { CloneEformModalComponent } from "./layout/components/eforms/clone-eform-modal/clone-eform-modal.component";
import { AddEformModalComponent } from "./layout/components/eforms/add-eform-modal/add-eform-modal.component";
import { ViewEformModalComponent } from "./layout/components/eforms/view-eform-modal/view-eform-modal.component";
//eFormResponse
import { EformresponseViewComponent } from "./layout/components/eformsresponse/eformresponse-view/eformresponse-view.component";
import { EformresponseExpiryDateComponent } from "./layout/components/eformsresponse/eformresponse-expiry-date/eformresponse-expiry-date.component";
import { EformresponseGradeComponent } from "./layout/components/eformsresponse/eformresponse-grade/eformresponse-grade.component";
import { EformresponseDownloadComponent } from "./layout/components/eformsresponse/eformresponse-download/eformresponse-download.component";
//company setting
import { CompanySettingsComponent } from "./layout/components/company-settings/company-settings.component";
//Credit Blacklist company
import { CreditScoreBlackListComponent } from "./layout/components/credit-score-black-list/credit-score-black-list.component";
//Usecase
import { Usecase1Component } from "./layout/components/usecase1/usecase1.component";
import { Usecase2Component } from "./layout/components/usecase2/usecase2.component";
import { Usecase3Component } from "./layout/components/usecase3/usecase3.component";
import { Usecase4Component } from "./layout/components/usecase4/usecase4.component";
import { Usecase5Component } from "./layout/components/usecase5/usecase5.component";
import { Usecase6Component } from "./layout/components/usecase6/usecase6.component";
import { Usecase7Component } from "./layout/components/usecase7/usecase7.component";
import { Usecase8Component } from "./layout/components/usecase8/usecase8.component";
//AssetsManagement
import { AssetsManagementComponent } from "./layout/components/assets-management/assets-management.component";
//BankSettings
import { BankSettingsComponent } from "./layout/components/bank-settings/bank-settings.component";
import { SectionModalComponent } from "./layout/components/addeform/section-modal/section-modal.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";

//Company Onboarding
import { CompanyOnboardingComponent } from "./layout/components/company-onboarding/company-onboarding.component";
import { MessagingComponent } from "./layout/components/messaging/messaging.component";

//Access Control
import { AccessControlComponent } from "./layout/components/access-control/access-control.component";
import { LocationComponent } from "./layout/components/location/location.component";
import { AddLocationComponent } from "./layout/components/location/add/add.component";
import { UpdateLocationComponent } from "./layout/components/location/update/update.component";
import { AttendanceReportComponent } from "./layout/components/attendance-report/attendance-report.component";
import { LocationFormComponent } from "./layout/components/location/form/form.component";
import { AccessControlFormComponent } from "./layout/components/access-control/form/form.component";
import { ManageStaffComponent } from "./layout/components/location/manage-staff/manage-staff.component";
import { ViewLocationComponent } from "./layout/components/location/view/view.component";
import { DeletedLocationComponent } from "./layout/components/location/deleted/deleted.component";
import { GetDataFromIdComponent } from "./layout/models/get-data-from-id/get-data-from-id.component";
import { AddEmployeeComponent } from "./layout/components/employees/add-employee/add-employee.component";
import { AddEditClientComponent } from "./layout/components/clients/add-edit-client/add-edit-client.component";
import { ViewStaffListModalComponent } from "./layout/components/location/manage-staff/view-staff-list-modal/view-staff-list-modal.component";
import { CreateFolderComponent } from "./layout/components/documents/create-folder/create-folder.component";
import { MessagingHistoryComponent } from "./layout/components/messaging/messaging-history/messaging-history.component";
import { AddAccessControlComponent } from "./layout/components/access-control/add/add.component";
import { UpdateAccessControlComponent } from "./layout/components/access-control/update/update.component";
import { ManageAccessControlStaffComponent } from "./layout/components/access-control/manage-staff/manage-staff.component";
import { ViewAccessControlComponent } from "./layout/components/access-control/view/view.component";
import { DeletedAccessControlComponent } from "./layout/components/access-control/deleted/deleted.component";
import { ViewAccessControlStaffListModalComponent } from "./layout/components/access-control/manage-staff/view-staff-list-modal/view-staff-list-modal.component";
import { InvitationListComponent } from "./layout/components/invitation-list/invitation-list.component";
import { InvitationListViewComponent } from "./layout/components/invitation-list/invitation-list-view/invitation-list-view.component";

import { PropertyComponent } from "./layout/components/property/property.component";
import { AddPropertyComponent } from "./layout/components/property/add/add.component";
import { UpdatePropertyComponent } from "./layout/components/property/update/update.component";
import { PropertyFormComponent } from "./layout/components/property/form/form.component";
import { ViewPropertyComponent } from "./layout/components/property/view/view.component";
import { DeletedPropertyComponent } from "./layout/components/property/deleted/deleted.component";
import { PropertyInquiryComponent } from "./layout/components/property/property-inquiry/property-inquiry.component";
import { ViewPropertyInquiryComponent } from "./layout/components/property/inquiry-view/inquiry-view.component";
import { VerificationsComponent } from "./layout/components/verifications/verifications.component";
import { ViewInvitationListComponent } from "./layout/models/view-invitation-list/view-invitation-list.component";
import { CreateAssetsComponent } from "./layout/components/assets-management/create/create.component";
import { AssetsFormComponent } from "./layout/components/assets-management/form/form.component";
import { SendAssetModalComponent } from "./layout/components/assets-management/send-asset-modal/send-asset-modal.component";
import { PropertyInquiryFormComponent } from "./layout/components/property/inquiry-form/inquiry-form.component";
import { ViewSentAssetModalComponent } from "./layout/components/assets-management/view-sent-asset-modal/view-sent-asset-modal.component";
import { EditAssetsComponent } from "./layout/components/assets-management/edit/edit.component";
import { DeletedAssetsComponent } from "./layout/components/assets-management/deleted/deleted.component";
import { ViewAssetComponent } from "./layout/components/assets-management/view/view.component";
import { DocumentQrcodeComponent } from './layout/components/documents/document-qrcode/document-qrcode.component';
import { FilterComponentComponent } from './layout/components/filter-component/filter-component.component';
import { VerificationHistoryComponent } from './layout/components/verifications/verification-history/verification-history.component';
import { UserDetailsComponent } from './layout/components/verifications/user-details/user-details.component';
import { IdentityChecksComponent } from './layout/components/verifications/identity-checks/identity-checks.component';
import { ProcessesComponent } from './layout/components/verifications/processes/processes.component';
import { EvidenceComponent } from './layout/components/verifications/evidence/evidence.component';
import { EsignDocumentsComponent } from './layout/components/verifications/esign-documents/esign-documents.component';
import { ExportsComponent } from './layout/components/verifications/exports/exports.component';
import { NotesComponent } from './layout/components/verifications/notes/notes.component';
import { IframeModalComponent } from './layout/components/verifications/iframe-modal/iframe-modal.component';
import { SafePipe } from './layout/components/verifications/safe.pipe';
import { ProcessComponent } from './layout/process/process.component'
// import { UserDetailsComponent } from "./layout/components/verifications/user-details/user-details.component";
@NgModule({
    declarations: [
        AccountComponent,
        AddeformComponent,
        AppComponent,
        ClientsComponent,
        DashboardComponent,
        EformsComponent,
        EformsresponseComponent,
        EformssentComponent,
        FooterComponent,
        HeaderComponent,
        LoginComponent,
        LogsComponent,
        PortalComponent,
        ProfileComponent,
        ProfilemenuComponent,
        SidebarComponent,
        UsersComponent,
        EformQrcodeComponent,
        NotificationsComponent,
        ReportsComponent,
        LogsModalComponent,
        EformResponseModalComponent,
        UsersReportModalComponent,
        UpdateFieldsComponent,
        VerificationLogsComponent,
        IdCardsComponent,
        DocumentsComponent,
        EmployeesComponent,
        UpdateIdCardComponent,
        MerchantQrcodeComponent,
        DocumentViewerComponent,
        ViewerheaderComponent,
        CompanyManagementComponent,
        LayoutComponent,
        CompanyUsersComponent,
        NavbarComponent,
        CompanyProfileComponent,
        CertificateComponent,
        ViewCertificateComponent,
        GenerateIdModalComponent,
        UserProfileComponent,
        DeleteUsersComponent,
        UploadDocumentComponent,
        SendIdCardComponent,
        ViewSentIdCardComponent,
        ViewIdCardComponent,
        DocSendFormComponent,
        DocViewFormComponent,
        CompanyConfirmModalComponent,
        MyEformResponsesComponent,
        RegisterComponent,
        EmployeeDeletedComponent,
        ClientDeletedComponent,
        EformsDeletedComponent,
        CertificateDeletedComponent,
        AddUsersComponent,
        CreateCertificateComponent,
        EformresponseDeletedComponent,
        DocumentDeletedComponent,
        SendEformModalComponent,
        ViewSentEformModalComponent,
        CloneEformModalComponent,
        AddEformModalComponent,
        ViewEformModalComponent,
        EformresponseViewComponent,
        EformresponseExpiryDateComponent,
        EformresponseGradeComponent,
        EformresponseDownloadComponent,
        CompanySettingsComponent,
        CreditScoreBlackListComponent,
        Usecase1Component,
        Usecase2Component,
        Usecase3Component,
        Usecase4Component,
        Usecase5Component,
        Usecase6Component,
        Usecase7Component,
        Usecase8Component,
        AssetsManagementComponent,
        BankSettingsComponent,
        SectionModalComponent,
        CompanyOnboardingComponent,
        MessagingComponent,
        LocationComponent,
        LocationFormComponent,
        AddLocationComponent,
        UpdateLocationComponent,
        ManageStaffComponent,
        ViewLocationComponent,
        DeletedLocationComponent,
        AccessControlComponent,
        AttendanceReportComponent,
        AddEmployeeComponent,
        GetDataFromIdComponent,
        AddEditClientComponent,
        ViewStaffListModalComponent,
        CreateFolderComponent,
        MessagingHistoryComponent,
        AccessControlFormComponent,
        AddAccessControlComponent,
        UpdateAccessControlComponent,
        ManageAccessControlStaffComponent,
        ViewAccessControlComponent,
        DeletedAccessControlComponent,
        ViewAccessControlStaffListModalComponent,
        InvitationListComponent,
        InvitationListViewComponent,
        PropertyComponent,
        AddPropertyComponent,
        UpdatePropertyComponent,
        PropertyFormComponent,
        ViewPropertyComponent,
        DeletedPropertyComponent,
        PropertyInquiryComponent,
        ViewPropertyInquiryComponent,
        VerificationsComponent,
        ViewInvitationListComponent,
        DocumentEditComponent,
        CreateAssetsComponent,
        AssetsFormComponent,
        SendAssetModalComponent,
        PropertyInquiryFormComponent,
        ViewSentAssetModalComponent,
        EditAssetsComponent,
        DeletedAssetsComponent,
        ViewAssetComponent,
        DocumentQrcodeComponent,
        FilterComponentComponent,
        VerificationHistoryComponent,
        UserDetailsComponent,
        IdentityChecksComponent,
        ProcessesComponent,
        EvidenceComponent,
        EsignDocumentsComponent,
        ExportsComponent,
        NotesComponent,
        IframeModalComponent,
        SafePipe,
        ProcessComponent
        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        QRCodeModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            timeOut: 2500,
            positionClass: "toast-bottom-right",
        }),
        NgxSpinnerModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        // MatProgressSpinnerModule,
        ReactiveFormsModule,
        CKEditorModule,
        TableModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        TagInputModule,
        NgxTagsInputModule,
        NgxPaginationModule,
        NgbModule,
        ChartModule,
        CardModule,
        ButtonModule,
        FileUploadModule,
        ToastModule,
        TabViewModule,
        SelectButtonModule,
        NgxDocViewerModule,
        PdfViewerModule,
        NavbarModule,
        FooterModule,
        ChipsModule,
        NgScrollbarModule,
        FontAwesomeModule,
        MDBBootstrapModule.forRoot(),
        NgSelectModule,
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        MultiSelectModule,
        Ng2SearchPipeModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        NgxIntlTelInputModule,
        NgMultiSelectDropDownModule.forRoot(),
        ConfirmDialogModule,
    ],
    providers: [
        Title,
        MessageService,
        CookieService,
        CommonService,
        RefreshContentService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        LogsModalComponent,
        EformResponseModalComponent,
        UsersReportModalComponent,
        GenerateIdModalComponent,
        DeleteUsersComponent,
        UploadDocumentComponent,
        ViewCertificateComponent,
        UploadDocumentComponent,
        SendIdCardComponent,
        ViewSentIdCardComponent,
        ViewIdCardComponent,
        DocSendFormComponent,
        DocViewFormComponent,
        DocumentViewerComponent,
        CompanyConfirmModalComponent,
        AddUsersComponent,
        CreateCertificateComponent,
        SendEformModalComponent,
        ViewSentEformModalComponent,
        CloneEformModalComponent,
        AddEformModalComponent,
        ViewEformModalComponent,
        EformresponseViewComponent,
        EformresponseExpiryDateComponent,
        EformresponseGradeComponent,
        EformresponseDownloadComponent,
        SectionModalComponent,
        CompanyOnboardingComponent,
        LocationComponent,
        LocationFormComponent,
        AddLocationComponent,
        UpdateLocationComponent,
        ManageStaffComponent,
        ViewLocationComponent,
        DeletedLocationComponent,
        AccessControlComponent,
        AttendanceReportComponent,
        GetDataFromIdComponent,
        ViewStaffListModalComponent,
        CreateFolderComponent,
        MessagingHistoryComponent,
        AccessControlFormComponent,
        AddAccessControlComponent,
        UpdateAccessControlComponent,
        ManageAccessControlStaffComponent,
        ViewAccessControlComponent,
        DeletedAccessControlComponent,
        ViewAccessControlStaffListModalComponent,
        InvitationListViewComponent,
        PropertyComponent,
        AddPropertyComponent,
        UpdatePropertyComponent,
        PropertyFormComponent,
        ViewPropertyComponent,
        DeletedPropertyComponent,
        PropertyInquiryComponent,
        ViewPropertyInquiryComponent,
        ViewInvitationListComponent,
        DocumentEditComponent,
        CreateAssetsComponent,
        AssetsFormComponent,
        SendAssetModalComponent,
        PropertyInquiryFormComponent,
        ViewSentAssetModalComponent,
        EditAssetsComponent,
        DeletedAssetsComponent,
        ViewAssetComponent,
        FilterComponentComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
