<div class="manage-staff-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/access-control']"
                        >Access Control</a
                    >
                </li>
                <li class="breadcrumb-item active">Manage Staff</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div class="input-group">
                                <input
                                    type="text"
                                    [(ngModel)]="searchName"
                                    (change)="
                                        searchAccessControlStaff(
                                            'search',
                                            $event.target.value
                                        )
                                    "
                                    class="form-control"
                                    id="search"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-6 col-lg-8 col-xl-9 text-right mt-1 mt-md-0"
                        >
                            <button
                                type="button"
                                class="btn waves-effect waves-light btn-info add-btn"
                                (click)="viewStaffListModal()"
                            >
                                <i
                                    class="fas fa-plus-circle"
                                    tooltip="Add Staff"
                                    placement="bottom"
                                    container="body"
                                ></i>
                                Add Staff
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive mb-1">
                        <table
                            class="table datatable-table color-bordered-table info-bordered-table"
                            mdbTable
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th>AKcess ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>User Type</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="staffList?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No users found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="staffList?.length > 0">
                                <tr
                                    [id]="staff?._id"
                                    *ngFor="
                                        let staff of staffList
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                >
                                    <td>
                                        {{ staff?.akcessId }}
                                    </td>
                                    <td>
                                        {{ staff?.name }}
                                    </td>
                                    <td>
                                        {{ staff?.email }}
                                    </td>
                                    <td>
                                        {{ staff?.usertype }}
                                    </td>
                                    <td>
                                        <div class="actions-div">
                                            <button
                                                (click)="
                                                    deleteSingleRows(staff._id)
                                                "
                                                title="Remove Staff"
                                                class="btn btn-link delete_btn"
                                            >
                                                <i
                                                    class="fa fa-trash red-txt"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div
                                            class="table-page"
                                            *ngIf="staffList?.length > 10"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
