import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import { __spreadArrays } from "tslib";
import { observable } from "rxjs";
import { element } from "protractor";
import { YEAR } from "ngx-bootstrap/chronos/units/constants";
import { async } from "@angular/core/testing";
import { rejects } from "assert";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/_services/common.service";

@Component({
    selector: 'app-eform-response-modal',
    templateUrl: './eform-response-modal.component.html',
    styleUrls: ['./eform-response-modal.component.css']
})
export class EformResponseModalComponent implements OnInit {
    eformresponse = [];
    AllEformsResponse = [];
    fields = [];
    formname: any;
    imageUrl: string = environment.imgurl;
    imageUrlsForPDF = [];
    portalUser;
    portaldata;
    pdfcolums = [{}];
    xls_fileName = "ExcelSheet.xlsx";
    pdf_fileName = "PDF.pdf";
    eformId: any;
    eformResponseEformId: any;
    device_token: string;
    isSubmitted = false;
    eformStatus: string = "";
    gradedata = [];
    eFormverifyCheck: any = [];
    search_formName: any = ""

    verifiedItems: Array<String> = [];
    fieldVerifierData: any;
    userAkcessID: any;
    graderLoader: boolean = false;

    eformDataWithForExpiryDate: any
    expiryDateIndex: any
    addExpiryDate: any

    currentPage: any = 1
    totalCount: any = 0
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50]
    pageSize: any = 10

    responseId: any;
    pullDataStatus: any = ''

    minDate: Date;

    // Status: any = [
    //   "Pending",
    //   "Accept",
    //   "Update Client Field",
    //   "Verify and Accept",
    //   "Return",
    //   "Reject",
    // ];
    StatusDescription: any = {
        alive: "Alive",
        Pending: "Pending",
        createClient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        Accept:
            " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
        "Update Client Field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        "Verify and Accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        Return:
            "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
        Reject:
            "When you apply 'reject' action, user will receive a notification that you rejected his response",
    };

    orderByFilter: any = 'nto'
    constructor(private router: Router,
        public activeModal: NgbActiveModal,
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        public commonService: CommonService) { }

    ngOnInit() {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        this.eformId = this.route.snapshot.paramMap.get("eformId");
        this.geteformresponse(null);
    }

    setVerifiedItem(evt, i): void {
        let id = this.fields[i]._id;
        if (evt.target.checked) {
            this.eFormverifyCheck.push(id);
            this.verifiedItems.push(id);
        } else {
            this.eFormverifyCheck.splice(this.verifiedItems.indexOf(id), 1);
            this.verifiedItems.splice(this.verifiedItems.indexOf(id), 1);
        }
    }

    openExpiryDate(index) {
        this.expiryDateIndex = null
        this.expiryDateIndex = index
        this.eformDataWithForExpiryDate = {}
        this.eformDataWithForExpiryDate = this.fields[index];
    }

    pushExpiryDateInFiedls() {
        this.fields[this.expiryDateIndex].expiryDate = moment(this.addExpiryDate).format()
        this.addExpiryDate = ""
        $("#expiryDateModal").modal("hide")
    }

    resetVerifiedItems(): void {
        this.verifiedItems = [];
        this.eFormverifyCheck = [];
    }

    geteformresponse(status: string) {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
            orderBy: this.orderByFilter,
            currentPage: this.currentPage,
            limit: this.pageSize
        };
        if (this.eformId != null) {
            tmpdata["eformId"] = this.eformId;
        }
        if (status && status.length) tmpdata["status"] = status;
        if (this.search_formName && this.search_formName.length) tmpdata["search_formName"] = this.search_formName;

        // alert(JSON.stringify(tmpdata))
        this.calldata.fetchresponse(tmpdata).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.eformresponse = [];
                    this.eformresponse = data["data"];

                    this.eformresponse.forEach(async (element, i) => {
                        element.publicFields.forEach(ele => {
                            if (ele.key == 'firstname') {
                                this.eformresponse[i].resFirstName = ele.value
                            }
                            if (ele.key == 'lastname') {
                                this.eformresponse[i].resLastName = ele.value
                            }
                        });
                    });

                    this.AllEformsResponse = data["data"];
                    this.totalCount = data["totalCount"]
                    this.spinner.hide();
                } else {
                    this.toast.info(data["msg"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    orderBy(value) {
        this.orderByFilter = value
    }

    searchEformResponse(type: string, value: string) {
        if (type == "search") {
            if (value !== null) {
                this.search_formName = value
                this.geteformresponse(null);
            }
        } else {
            this.geteformresponse(value);
        }
    }

    async view(val, fullname, _id, akcessId) {
        this.responseId = ''
        this.responseId = _id
        var fullname = fullname;
        this.userAkcessID = "";
        this.userAkcessID = akcessId;
        this.fields = [];
        var a = "";
        this.formname = this.eformresponse[val].formName;
        //var data = (this.eformresponse[val].fields) ? this.eformresponse[val].fields : this.eformresponse[val].eformsFields;
        let data = this.eformresponse[val].fields
        await data.forEach(async (element) => {
            if (element.isverified) {
                this.eFormverifyCheck.push(element._id);
            }
        });

        for (var i = 0; i < data.length; i++) {
            // a = this.eformresponse[val][data[i].key];
            a = data[i].key
            data[i].val = a;

            data["fullname"] = fullname;
            if (data[i].keytype == "file" || data[i].keytype == "File") {
                data[i].path = data[i].file.path;
                this.imageUrlsForPDF.push(
                    this.imageUrl +
                    data[i].file.path
                );
            }
            data[i].verifyDate = moment().format();
            data[i].expiryDate = moment().add(1, "M").format();

            if (data[i].isverified) {
                this.verifiedItems.push(data[i]._id);
            } else {
            }
        }

        data["eFormResponseId"] = _id;
        data["eformId"] = this.eformresponse[val].eformId;
        this.eformResponseEformId = this.eformresponse[val].eformId;
        this.eformStatus = this.eformresponse[val].status;
        this.device_token = this.eformresponse[val].device_token;
        data["akcessId"] = akcessId;
        data["email"] = (this.eformresponse[val] && this.eformresponse[val].userdata[0] && this.eformresponse[val].userdata[0].email) ? this.eformresponse[val].userdata[0].email : '';
        data["mobile"] = (this.eformresponse[val] && this.eformresponse[val].userdata[0] && this.eformresponse[val].userdata[0].mobile) ? this.eformresponse[val].userdata[0].mobile : '';

        this.fields = data;
    }

    approveresponse(act, val) {
        if (confirm("Are you sure you want to do this action?")) {
            this.spinner.show();
            const data = { _id: val, act: act };
            this.calldata.approveresponse(data).subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        this.geteformresponse(null);
                        this.toast.success(data["message"]);
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }
    exportexcel(formname, fullname): void {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.xls_fileName =
            formname + "_" + formattedDate + "_" + fullname + ".xlsx";
        /* table id is passed over here */
        let element = document.getElementById("xls_formfields_testmamxmx");
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        XLSX.writeFile(wb, this.xls_fileName);
    }

    // pdf merging

    async getPdfBytes(eform, arrayPdfurls, name) {
        const basePdfDoc = await PDFDocument.load(eform);
        if (arrayPdfurls.length > 0) {
            let requests = arrayPdfurls.map((url) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        let buffArrayTemp = await fetch(url).then((res) =>
                            res.arrayBuffer()
                        );
                        const loaddedPdf = await PDFDocument.load(buffArrayTemp);
                        const copiedPages = await basePdfDoc.copyPages(
                            loaddedPdf,
                            loaddedPdf.getPageIndices()
                        );
                        copiedPages.forEach((page) => basePdfDoc.addPage(page));
                        let pdfBytes = await basePdfDoc.save();
                        resolve(pdfBytes);
                    } catch (e) {
                        reject(e);
                    }
                });
            });

            Promise.all(requests).then(
                async (result) => {
                    var binaryData = [];
                    binaryData.push(result[result.length - 1]);

                    window.open(
                        window.URL.createObjectURL(
                            new Blob(binaryData, { type: "application/pdf" })
                        ),
                        "pdf",
                        "toolbar=no,location=no,directories=no,status=no, ar=no,scrollbar=no,resizable=no,copyhistory=yes"
                    );
                },
                (e) => {
                    this.toast.error("No Files found");
                }
            );
        } else {
            var binaryData = [];

            let pdfBytes = await basePdfDoc.save();
            binaryData.push(pdfBytes);

            window.open(
                window.URL.createObjectURL(
                    new Blob(binaryData, { type: "application/pdf" })
                ),
                "pdf",
                "toolbar=no,location=no,directories=no,status=no, ar=no,scrollbar=no,resizable=no,copyhistory=yes"
            );
        }
    }

    // PDF merging from urls end

    async generatePdf(formname, fullname) {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        this.pdf_fileName =
            formname + "_" + formattedDate + "_" + fullname + ".pdf";
        const documentDefinition = await this.getDocumentDefinition();
        // pdfMake.createPdf(documentDefinition).download(this.pdf_fileName);
        const pdfDocGenerator = await pdfMake.createPdf(documentDefinition);
        pdfDocGenerator.getBuffer(async (buffer) => {
            await this.getPdfBytes(buffer, this.imageUrlsForPDF, this.pdf_fileName);
        });
    }

    getDocumentDefinition() {
        return new Promise(async (resolve, reject) => {
            var html = htmlToPdfmake(
                document.getElementById("pdf_formfields_testmaxcan").innerHTML
            );
            resolve({
                content: [html],
                styles: {
                    "html-strong": {
                        background: "",
                    },
                },
            });
        });
    }
    verifyField() {
        // const tmpdata:any={};

        return;
        // if (event.target.checked) {alert(id)
        //   var tmpdata = {'isverified':true,'_id':id}
        // }else{
        //   var tmpdata = {'isverified':false,'_id':id}
        // }
        // this.calldata.verifyfield(tmpdata).subscribe(data => {
        //   if(data['status']===1){
        //     this.geteformresponse();
        //     this.toast.success(data['message']);
        //   } else {
        //     this.toast.info(data['message']);
        //     this.spinner.hide();
        //   }
        // },
    }

    onSubmit() {
        let vdata = [];

        this.fields.forEach((field) => {
            var isPresent = this.verifiedItems.some(function (el) {
                return el === field._id;
            });
            if (isPresent) {
                vdata.push(field);
            }
        });
        if (!this.eformStatus) {
            this.toast.error("Please select status");
            return false;
        } else {
            this.portaldata = this.commonService.portalDetails.portalDetails.portalData;
            this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
            let approved;

            if (this.eformStatus == "Verify and Accept") {
                approved = true;
            } else {
                approved = false;
            }

            let reqData = {
                eFormResponseId: this.fields["eFormResponseId"],
                fields_form: this.verifiedItems,
                eformsFields: vdata,
                eformStatus: this.eformStatus,
                akcessId: this.portalUser.akcessId,
                formName: this.formname,
                status: "alive",
                date: new Date(),
                approved: approved,
                eformId: this.eformResponseEformId,
                akcessVerification: true,
                akcessVerificationStatus: true,
                akcessVerificationStep: { type: "ready" },
                device_token: this.device_token,
                portalTitle: this.commonService.portalDetails.portalDetails.portalTitle,
                databaseURL: this.portaldata.setting.databaseURL,
            };

            this.calldata.verifyfield(reqData).subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        if (reqData.eformStatus == "Update Client Field" || reqData.eformStatus == "createClient") {
                            this.updateClientForm(this.fields);
                        }
                        this.geteformresponse(null);
                        this.toast.success(data["message"]);
                        $("#viewform").modal("hide");

                        let fieldsToVerify = []
                        let expiryDateArray = []

                        if (reqData.eformsFields.length > 0) {
                            reqData.eformsFields.forEach((element) => {
                                if (element.keytype == "file") {
                                    let object = {
                                        verifierAkcessId: this.portalUser.akcessId,
                                        userAkcessId: this.userAkcessID,
                                        documentId: element.value,
                                        expiryDate: element.expiryDate
                                    };
                                    this.calldata.docVerifyField(object).subscribe((res: any) => {
                                    });
                                } else {
                                    expiryDateArray.push(element.expiryDate)
                                    fieldsToVerify.push(element.key)
                                }
                            });

                            if (fieldsToVerify.length > 0) {
                                let object = {
                                    verifierAkcessId: this.portalUser.akcessId,
                                    userAkcessId: this.userAkcessID,
                                    profileField: fieldsToVerify,
                                    expiryDate: expiryDateArray
                                };

                                this.calldata.VerifyField(object).subscribe((res: any) => {
                                });
                            }

                        }
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }

    viewGradeVer(data) {
        this.graderLoader = true;

        this.fieldVerifierData = [];

        if (
            data.keytype == "string" ||
            data.keytype == "text" ||
            data.keytype == "list"
        ) {
            let object = {
                userAkcessId: this.userAkcessID,
                profileField: data.labelname,
                verifierAkcessId: this.portalUser.akcessId,
            };
            this.calldata.getFieldVerifierOnPortal(object).subscribe((res: any) => {
                if (res.status) {
                    this.fieldVerifierData = res.data;
                    this.graderLoader = false;
                } else {
                    this.graderLoader = false;
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
        }

        if (data.keytype == "file") {
            let object = {
                userAkcessId: this.userAkcessID,
                documentId: data.value,
                verifierAkcessId: this.portalUser.akcessId,
            };

            this.calldata.getDocVerifierOnPortal(object).subscribe((res: any) => {
                if (res.status) {
                    this.fieldVerifierData = res.data;
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
        }

        this.gradedata["key"] = data.key;
    }

    updateClientForm(payload) {
        let object = {
            akcessId: payload["akcessId"],
            firstName: "",
            lastName: "",
            firstNameArabic: "",
            lastNameArabic: "",
            email: "",
            phone: "",
            address: "",
            usertype: "",
            clientStatus: "",
            clientProducts: "",
            USDAccountBalance: "",
            KDAccountBalance: "",
            civilIDExpDate: "",
            civilIdNumber: "",
            passportExpDate: "",
            passportNumber: "",
            birthPlace: "",
            birthDate: "",
            nationality: "",
            gender: "",
            education: "",
            language: "",
            maritalStatus: "",
            occupation: "",
            recruitmentDate: "",
            employerName: "",
            salary: "",
            rollPosition: "",
            studentAtUniColl: "",
            graduationDate: "",
            officePhone: "",
            workAddress: "",
            faxNo: "",
            mobile: "",
            aveneu: "",
            street: "",
            block: "",
            area: "",
            buildingPlot: "",
            floor: "",
            unit: "",
            postOffice: "",
            poBox: "",
            zipCode: "",
            city: "",
            otherIncomeBusiness: "",
            politicallyExp: "",
            taxResidentKuwait: "",
            CVDNo: "",
            taxResidentKandO: "",
            taxResidentOnlyO: "",
            taxResidentKand: "",
            TINReason_reason: "",
        };

        let bodyData: any = {
            akcessId: payload["akcessId"],
        };

        payload.forEach((element) => {
            if (element.key == "occupation") bodyData.occupation = element.value;
            if (element.key == "education") bodyData.education = element.value;
            if (element.key == "university") bodyData.studentAtUniColl = element.value;
            if (element.key == "position") bodyData.rollPosition = element.value;
            if (element.key == "salary") bodyData.salary = element.value;
            if (element.key == "nationality") bodyData.nationality = element.value;
            if (element.key == "gender") bodyData.gender = element.value;
            if (element.key == "zipcode") bodyData.zipCode = element.value;
            if (element.key == "floor") bodyData.floor = element.value;
            if (element.key == "city") bodyData.city = element.value;
            if (element.key == "pooffice") bodyData.postOffice = element.value;
            if (element.key == "block") bodyData.block = element.value;
            if (element.key == "area") bodyData.area = element.value;
            if (element.key == "street") bodyData.street = element.value;
            if (element.key == "buildingPlot") bodyData.buildingplot = element.value;
            if (element.key == "avenue") bodyData.aveneu = element.value;
            if (element.key == "faxnumber") bodyData.faxNo = element.value;
            if (element.key == "recrutmentdate") bodyData.recruitmentDate = element.value;
            if (element.key == "language") bodyData.language = element.value;
            if (element.key == "birthplace") bodyData.birthPlace = element.value;
            if (element.key == "passportnumber") bodyData.passportNumber = element.value;
            if (element.key == "passportexpirydate") bodyData.passportExpDate = element.value;
            if (element.key == "civilidnumber") bodyData.civilIdNumber = element.value;
            if (element.key == "arabiclastname") bodyData.lastNameArabic = element.value;
            if (element.key == "arabicfirstname") bodyData.firstNameArabic = element.value;
            if (element.key == "civilidexpirydate") bodyData.civilIDExpDate = element.value;
            if (element.key == "email") bodyData.email = element.value;
            if (element.key == "mobile") bodyData.mobile = element.value;
            if (element.key == "firstname") bodyData.firstName = element.value;
            if (element.key == "lastname") bodyData.lastName = element.value;
            if (element.key == "workphone") bodyData.officePhone = element.value;
            if (element.key == "workaddress") bodyData.workAddress = element.value;
            if (element.key == "employer") bodyData.employerName = element.value;
            if (element.key == "graduationdate") bodyData.graduationDate = element.value;
            if (element.key == "maritalstatus") bodyData.maritalStatus = element.value;
            if (element.key == "dateofbirth") bodyData.birthDate = element.value;
            if (element.key == "homephone") bodyData.phone = element.value;
            if (element.key == "cvdno") bodyData.CVDNo = element.value;
            if (element.key == "kdaccountbalance") bodyData.KDAccountBalance = element.value;
            if (element.key == "usdaccountbalance") bodyData.USDAccountBalance = element.value;
            if (element.key == "products") bodyData.clientProducts = element.value;
            if (element.key == "unit") bodyData.unit = element.value;
            if (element.key == "pobox") bodyData.poBox = element.value;
        });

        this.calldata
            .updateClientDataWithReverseOnBoarding(bodyData)
            .subscribe((data: any) => {
                if (data.status) {
                    this.toast.success(data["message"]);
                } else {
                    this.toast.error(data["message"]);
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
    }

    onProjectPageChange(event) {
        this.currentPage = event
        this.geteformresponse(null)
    }

    changePage(value) {
        this.pageSize = parseInt(value)
        this.geteformresponse(null)
    }

    fetchDataFromDoc(data) {
        let object = {
            id: this.responseId,
            path: data.path
        }

        this.calldata.updateFieldsFromDocument(object).subscribe((res: any) => {
            if (res.success) {
                this.geteformresponse(null);
                this.toast.success(res.message)
                $('#viewform').modal('hide')
            } else {
                this.toast.error(res.message)
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }

    exportexcel2(div): void {
        /* table id is passed over here */
        let element = document.getElementById(div);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        let fileName = "";

        if (div === 'pdf_formfields') {
            fileName = 'UpdatedCustomers.xlsx';
        } else if (div === 'client_reports') {
            fileName = 'ClientList.xlsx';
        } else if (div === 'user_reports') {
            fileName = "UserReport.xlsx";
        } else if (div === 'user_logs') {
            fileName = "Eform_Response_Report.xlsx";
        }

        /* save to file */
        XLSX.writeFile(wb, fileName);

    }


}



