<div class="company-settings">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">{{loggedInName}} Company Settings</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Company Settings</li>
            </ol>
        </div>
    </div>
    <!--Main Section-->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <tabset [justified]="true">
                        <tab heading="Image Settings">
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <label class="font-500">Upload Logo for ID-Card</label>
                                    <!--<input type="text" class="form-control">-->
                                    <div class="custom-file">
                                        <input #Image type="file" class="custom-file-input" id="importCompanyLogo"
                                            (change)="handleFileInput($event.target.files)" />
                                        <label class="custom-file-label" for="importCompanyLogo">
                                            <span *ngIf=!isFileChosen>Choose Image</span><span
                                                *ngIf=isFileChosen>{{fileName}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <div class="image-preview">
                                        <img *ngIf="imageUrl" [src]="imageUrl" class="image">
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-lg-6">
                                    <label class="font-500">Upload mini logo for sidebar</label>
                                    <!--<input type="text" class="form-control">-->
                                    <div class="custom-file">
                                        <input #Image type="file" class="custom-file-input" id="uploadMiniCompanyLogo"
                                            (change)="handleFileInputMiniLogo($event.target.files)" />
                                        <label class="custom-file-label" for="uploadMiniCompanyLogo">
                                            <span *ngIf=!isCompanyFileChosen>Choose Image</span><span
                                                *ngIf=isCompanyFileChosen>{{fileNameCompany}}</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <div class="image-preview">
                                        <img *ngIf="companyMiniImageUrl" [src]="companyMiniImageUrl" class="image">
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="form-actions">
                                <button class="btn waves-effect waves-light btn-info" type="submit">Set</button>
                            </div>
                        </tab>
                        <tab heading="Custom Fields" (selectTab)="getCustomFieldList()">
                            <section *ngIf="isCustomFieldListView">
                                <div class="card-title row align-items-center">
                                    <div class="col-12 col-md-6 col-lg-4">
                                        <div class="input-group">
                                            <input type="text" [(ngModel)]="searchCustomFields" class="form-control"
                                                id="search" placeholder="Search Fields" (change)="search('customfield')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="action-tab">
                                    <button type="button" class="mx-1 btn" (click)="deleteRow()" tooltip="Delete"
                                        container="body">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                    <span class="vertical-divider">|</span>
                                    <button type="button" class="mx-1 btn" (click)="viewRecycle()" tooltip="Recycle Bin"
                                        container="body">
                                        <i class="fal fa-recycle"></i>
                                    </button>
                                </div>
                                <div class="table-responsive mb-1">
                                    <table class="table color-bordered-table info-bordered-table" mdbTable
                                        #tableEl="mdbTable" hover="true" small="true">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <!--<label data-toggle="collapse" data-target="#actionBar" aria-expanded="false" aria-controls="actionBar" class="m-0">
                                                        <input class="" type="checkbox">
                                                    </label>-->
                                                </th>
                                                <th>Label Name <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                                <th>Type <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                                <th>Key <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                                <th>Created On <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                        </thead>
                                        <tbody *ngIf="customFieldList.length == 0">
                                            <tr>
                                                <td colspan="5" class="text-center">No Custom Fields Found</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="customFieldList.length > 0">
                                            <tr [id]="data._id"
                                                *ngFor="let data of customFieldList | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                                <td>
                                                    <input class="" type="checkbox" [attr.id]="data?._id">
                                                </td>
                                                <td>
                                                    <!--<a class="d-inline-block" (click)="view(i)" data-toggle="modal" data-target="#viewuser">
                                                        {{data.email}}
                                                    </a>-->
                                                    {{data.labelname}}
                                                </td>
                                                <td>{{data.type}}</td>
                                                <td>{{data.key}}</td>
                                                <td>{{data.date| date:'mediumDate'}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="w-100">
                                            <tr>
                                                <td colspan="5">
                                                    <div class="table-page" *ngIf="totalCount > 10">
                                                        <div class="page-controls">
                                                            <pagination-controls
                                                                (pageChange)="onProjectPageChange($event,'customField')"
                                                                class="my-pagination" directionLinks="true"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"></pagination-controls>
                                                        </div>
                                                        <div class="page-size">
                                                            <label class="mb-0 mr-2">Item per page</label>
                                                            <select #val (change)="changePage(val.value,'customField')"
                                                                class="form-control form-control-sm" id="pageSelector">
                                                                <option *ngFor="let pG of numberOfPage" value="{{pG}}">
                                                                    {{pG}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </section>
                            <section *ngIf="isCustomFieldDeleteListView">
                                <div class="card-title row align-items-center">
                                    <div class="col-12 col-md-6 col-lg-4">
                                        <div class="input-group">
                                            <input type="text" [(ngModel)]="searchDeletedCustomFieldInput" class="form-control"
                                                id="search" placeholder="Search Fields"  (change)="search('deletedcustomfield')"/>
                                            <!--<div class="input-group-append">
                                                <div class="input-group-text" (click)="getCertificateList()">
                                                    <i class="fas fa-search"></i>
                                                </div>
                                            </div>-->
                                        </div>
                                    </div>
                                    <!--<div class="col-md-2">
                                        <a (click)="geteformData()" data-toggle="modal"
                                           class="btn btn-primary btn-sm white"><i class="fa fa-search"></i> Search</a>
                                        <a (click)="this.search_formName='';geteformData()" data-toggle="modal"
                                           class="btn btn-primary btn-sm white"><i class="fa fa-ban"></i> Clear</a>
                                    </div>-->
                                    <!--<div class="col-12 col-md-6 col-lg-8 col-xl-9 text-right">
                                        <button type="button" class="btn waves-effect waves-light btn-info add-btn" data-toggle="modal" data-target="#addEmployeeDetail"
                                                (click)="resetform();act='add';" tooltip="Add Employee" placement="bottom" container="body">
                                            <i class="fas fa-plus-circle"></i>
                                        </button>
                                    </div>-->
                                </div>
                                <div class="action-tab">
                                    <button type="button" class="mx-1 btn" container="body"
                                        (click)="goBackToCustomListView()">
                                        <i class="fas fa-arrow-left"></i>
                                    </button>
                                    <span class="vertical-divider">|</span>
                                    <button type="button" class="mx-1 btn" tooltip="Remove Permanently" container="body"
                                        (click)="deletePermantly()">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                    <span class="vertical-divider">|</span>
                                    <button type="button" class="mx-1 btn" tooltip="Restore" container="body"
                                        (click)="restoreDeleted()">
                                        <i class="fas fa-trash-restore"></i>
                                    </button>
                                </div>
                                <div class="table-responsive mb-1">
                                    <table class="table color-bordered-table info-bordered-table" mdbTable
                                        #tableEl="mdbTable" hover="true" small="true">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <!-- <label data-toggle="collapse" data-target="#actionBar"
                                                        aria-expanded="false" aria-controls="actionBar" class="m-0">
                                                        <input class="" type="checkbox">
                                                    </label> -->
                                                </th>
                                                <th>Label Name <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                                <th>Type <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                                <th>Key <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                                <th>Created On <span><mdb-icon icon="sort" class="fas"><i
                                                                class="fa-sort fas"></i></mdb-icon></span></th>
                                        </thead>
                                        <tbody *ngIf="deletedCustomFieldList?.length == 0">
                                            <tr>
                                                <td colspan="5" class="text-center">No Custom Fields Found</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="deletedCustomFieldList.length > 0">
                                            <tr [id]="data._id"
                                                *ngFor="let data of deletedCustomFieldList | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalDeletedCount } ; let i = index">
                                                <td>
                                                    <input class="" type="checkbox" [attr.id]="data?._id">
                                                </td>
                                                <td>
                                                    {{data.labelname}}
                                                </td>
                                                <td>{{data.type}}</td>
                                                <td>{{data.key}}</td>
                                                <td>{{data.date| date:'mediumDate'}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="w-100">
                                            <tr>
                                                <td colspan="5">
                                                    <div class="table-page" *ngIf="totalDeletedCount > 10">
                                                        <div class="page-controls">
                                                            <pagination-controls
                                                                (pageChange)="onProjectPageChange($event,'deletedCustomField')"
                                                                class="my-pagination" directionLinks="true"
                                                                previousLabel="Previous"
                                                                nextLabel="Next"></pagination-controls>
                                                        </div>
                                                        <div class="page-size">
                                                            <label class="mb-0 mr-2">Item per page</label>
                                                            <select #val (change)="changePage(val.value,'deletedCustomField')"
                                                                class="form-control form-control-sm" id="pageSelector">
                                                                <option *ngFor="let pG of numberOfPage" value="{{pG}}">
                                                                    {{pG}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </section>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </section>
</div>