<div class="certificate-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Certificates</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Certificate</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div class="input-group" *ngIf="certificateList.length != 0">
                                <input type="text" [(ngModel)]="search_idcardName" class="form-control" id="search"
                                    placeholder="Search By Name" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-8 col-xl-9 text-right mt-1 mt-md-0">
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn"
                                data-toggle="modal" (click)="createCertificate()">
                                <i class="fas fa-plus-circle" tooltip="Create Certificate" placement="bottom"
                                    container="body"></i>
                            </button>
                        </div>
                    </div>
                    <div class="action-tab">
                        <button type="button" class="mx-1 btn" (click)="deleteRow()" tooltip="Delete" container="body">
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button type="button" class="mx-1 btn" (click)="viewRecycle()" tooltip="Recycle Bin"
                            container="body">
                            <i class="fal fa-recycle"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>
                                        <!--<label data-toggle="collapse" data-target="#actionBar" aria-expanded="false" aria-controls="actionBar" class="m-0">
                                            <input class="" type="checkbox">
                                        </label>-->
                                    </th>
                                    <th [mdbTableSort]="certificateList" sortBy="owner_name">Owner Name <span><mdb-icon
                                                icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th [mdbTableSort]="certificateList" sortBy="certificate_name">Certificate Name
                                        <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th [mdbTableSort]="certificateList" sortBy="createdAt">Created Date <span><mdb-icon
                                                icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th [mdbTableSort]="certificateList" sortBy="expiry_date">Expiry Date
                                        <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="certificateList?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">No Certificates Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="certificateList?.length > 0">
                                <tr [id]="data?._id"
                                    *ngFor="let data of certificateList | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                    <td>
                                        <input class="" type="checkbox" [attr.id]="data?._id">
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="viewCertificate(data)" data-toggle="modal"
                                            data-target="#viewuser">
                                            {{data.owner_name}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="viewCertificate(data)" data-toggle="modal"
                                            data-target="#viewuser">
                                            {{data.certificate_name}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="viewCertificate(data)" data-toggle="modal"
                                            data-target="#viewuser">
                                            {{data.createdAt | date:'medium'}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="viewCertificate(data)" data-toggle="modal"
                                            data-target="#viewuser">
                                            {{data.expiry_date | date:'medium'}}
                                        </a>
                                    </td>
                                    <td>
                                        <!--<button type="button" class="mx-2 btn btn-action-icon" (click)="deleteIdCard(data.idNo)">
                                            <i class="fa fa-trash" tooltip="Delete" container="body" placement="bottom"></i>
                                        </button>-->
                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            data-toggle="modal" data-target="#emailsendCertificate"
                                            (click)="openEformEmail(data); userwithChips = []; akcessIDChips = []">
                                            Send Certificate
                                        </button>
                                        <!--<button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info" data-toggle="modal" data-target="#prepareeform"
                                                (click)="openSendEform(data); akcessIDChips = []">
                                            Send By AKcess Id
                                        </button>-->
                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            data-toggle="modal" data-target="#viewSent"
                                            (click)="viewSentIdCards(data.id)">
                                            View Sent
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div class="table-page" *ngIf="certificateList?.length > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value)"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- Modals -->
<!-- View Employee Modal -->
<div class="modal" id="emailsendCertificate" tabindex="-1" role="dialog"
    aria-labelledby="emailsendCertificateModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="emailSendEformModalLabel">Send Certificate</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-around pb-2">
                    <div class="custom-radio">
                        <!--<input type="radio" [(ngModel)]="phone_or_email" value="user" id="user"
                               class="custom-control-input" mdbInput>
                        <label for="user" class="custom-control-label"><b>AKcess Id</b></label>-->
                        <input type="radio" [(ngModel)]="phone_or_email" value="user" id="user"
                            (click)="placeholder='Enter AKcess Id'" class="with-gap radio-col-light-blue" />
                        <label for="user" class="font-500">AKcess Id</label>
                    </div>
                    <div class="custom-radio">
                        <!--<input type="radio" [(ngModel)]="phone_or_email" value="email" id="email"
                               class="custom-control-input" mdbInput>
                        <label for="email" class="custom-control-label"><b>Email</b></label>-->
                        <input type="radio" [(ngModel)]="phone_or_email" value="email" id="email"
                            (click)="placeholder='Enter Email'" class="with-gap radio-col-light-blue">
                        <label for="email" class="font-500">Email</label>
                    </div>
                    <div class="custom-radio">
                        <!--<input type="radio" [(ngModel)]="phone_or_email" value="phone" id="phone"
                               class="custom-control-input" mdbInput>
                        <label for="phone" class="custom-control-label"><b>Phone</b></label>-->
                        <input type="radio" [(ngModel)]="phone_or_email" value="phone" id="phone"
                            (click)="placeholder='Enter Mobile Number'" class="with-gap radio-col-light-blue">
                        <label for="phone" class="font-500">Mobile Number</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" *ngIf="phone_or_email=='user'">
                        <ng-select [items]="userDataAKcessId" [addTag]="addTagFn" [hideSelected]="true" multiple="true"
                            bindLabel="name" placeholder="Select/Enter User" [(ngModel)]="akcessIDChips" appendTo="body"
                            class="akcess-id-dropdown" (open)="onOpenDropdown()" (close)="onCloseDropdown()">
                        </ng-select>
                        <div class="valid-feedback">Type AKcessID and press ENTER to validate</div>
                    </div>
                    <div class="col-12" *ngIf="phone_or_email=='email'">
                        <ng-select [items]="userDataEmail" [addTag]="addTagFn" [hideSelected]="true" multiple="true"
                            bindLabel="name" placeholder="Select/Enter Email" [(ngModel)]="userwithChips"
                            appendTo="body" (open)="onOpenDropdown()" (close)="onCloseDropdown()">
                        </ng-select>
                        <div class="valid-feedback">Type the email address and press ENTER</div>
                    </div>
                    <div class="col-12" *ngIf="phone_or_email=='phone'">
                        <ng-select [items]="userDataPhone" [addTag]="addTagFn" [hideSelected]="true" multiple="true"
                            bindLabel="name" placeholder="Select/Enter Mobile Number (ex. +123 45678912)"
                            [(ngModel)]="userwithChips_phone" appendTo="body" (open)="onOpenDropdown()"
                            (close)="onCloseDropdown()">
                        </ng-select>
                        <div class="valid-feedback">Type the phone number with the country code and press ENTER (ex.
                            +123 456789)</div>
                    </div>

                    <!--<div class="col-12" *ngIf="phone_or_email=='phone'">
                        <div class="row send-by-phone-section align-items-center" *ngFor="let phone of listPhone; let i = index;">
                            <div class="col-3">
                                <ng-select class="country-code" [(ngModel)]="selectedCountryCode[i]" placeholder="Code" appendTo="body">
                                    <ng-option *ngFor="let country of countryListCode" [value]="country.dial_code">{{country.dial_code}}</ng-option>
                                </ng-select>
                            </div>
                            <div class="col-8 pl-0">
                                <input type="number" class="form-control" placeholder="Enter Phone Number" [(ngModel)]="userwithChips_phone[i]" />
                            </div>
                            <div class="col-1 pl-0">
                                <button type="button" class="btn btn-sm btn-action-icon blue-action pl-0 pr-0" (click)="addListPhone()" *ngIf="i == 0">
                                    <i class="fas fa-plus-circle"></i>
                                </button>
                                <button type="button" class="btn btn-sm btn-action-icon red-action pl-0 pr-0" (click)="removeListPhone(i)" *ngIf="i > 0">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="valid-feedback">Select Country Code and Enter the phone number</div>
                    </div>-->
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="phone_or_email=='user'" type="button" class="btn waves-effect waves-light btn-info"
                    [disabled]="selectSubmitDisabled" (click)="sendeCertificateByAkcessId(eformData)">
                    Send to AKcessID
                </button>
                <button *ngIf="phone_or_email=='email'" type="button" class="btn waves-effect waves-light btn-info"
                    [disabled]="selectSubmitDisabled" (click)="sendeCertificateByEmail(eformData)">
                    Send to Mail
                </button>
                <button *ngIf="phone_or_email=='phone'" type="button" class="btn waves-effect waves-light btn-info"
                    [disabled]="selectSubmitDisabled" (click)="sendeCertificateByPhone(eformData)">
                    Send to Mobile
                </button>
                <button *ngIf="phone_or_email=='email' || phone_or_email=='phone'" type="button"
                    class="btn waves-effect waves-light btn-danger" id="emailsendCertificate" data-dismiss="modal">
                    Close
                </button>
                <button *ngIf="phone_or_email=='user'" type="button" class="btn waves-effect waves-light btn-danger"
                    (click)="akcessIDChips = []" id="emailsendCertificate" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- View Sent eForm-->
<div class="modal" id="viewSent" tabindex="-1" role="dialog" aria-labelledby="viewSentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewSentModalLabel">{{formname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-tab">
                    <tabset [justified]="true">
                        <tab heading="AKcess Id" [active]="isSelect == 'akcessid'" (selectTab)="onSelect('akcessid')">
                            <div class="table-responsive mb-1">
                                <table class="table color-bordered-table info-bordered-table" mdbTable
                                    #tableEl="mdbTable" small="true" hover="true">
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>AKcess Id</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="sendList?.length == 0">
                                        <tr>
                                            <td colspan="3" class="text-center">No sent eForms Found (AKcessId)</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="sendList?.length > 0">
                                        <tr *ngFor="let data of sendList;let i = index;">
                                            <td>



                                                <!--{{data.sendList[0]?.firstName}} {{data.sendList[0]?.lastName}}-->
                                                <!--<div *ngIf="data.user_name">{{data.user_name.first_name}} {{data.user_name.last_name}}</div>-->
                                            </td>
                                            <td>
                                                {{data.akcessId}}
                                            </td>
                                            <td>
                                                {{data.status}}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="w-100">
                                        <tr>
                                            <td colspan="3">
                                                <!--<mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="eformdata"></mdb-table-pagination>-->
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </tab>
                        <tab heading="Email Address" [active]="isSelect == 'email'" (selectTab)="onSelect('email')">
                            <div class="table-responsive mb-1">
                                <table class="table color-bordered-table info-bordered-table" mdbTable
                                    #tableEl="mdbTable" small="true" hover="true">
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Email</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="sendList?.length == 0">
                                        <tr>
                                            <td colspan="3" class="text-center">No sent eForms Found (Email)</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="sendList?.length > 0">
                                        <tr *ngFor="let data of sendList;let i = index;">
                                            <td>
                                                <!--{{data.sendList[0]?.firstName}} {{data.sendList[0]?.lastName}}-->
                                            </td>
                                            <td>

                                                {{data.email}}
                                            </td>
                                            <td>
                                                {{data.status}}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="grey lighten-5 w-100">
                                        <tr>
                                            <td colspan="3">
                                                <!--<mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="eformdata"></mdb-table-pagination>-->
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </tab>
                        <tab heading="Phone Number" [active]="isSelect == 'phone'" (selectTab)="onSelect('phone')">
                            <div class="table-responsive mb-1">
                                <table class="table color-bordered-table info-bordered-table" mdbTable
                                    #tableEl="mdbTable" small="true" hover="true">
                                    <thead>
                                        <tr>
                                            <th>Full Name</th>
                                            <th>Mobile</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="sendList?.length == 0">
                                        <tr>
                                            <td colspan="3" class="text-center">No sent eForms Found (Mobile)</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="sendList?.length > 0">
                                        <tr *ngFor="let data of sendList;let i = index;">
                                            <td>
                                                <!--{{data.sendList[0]?.firstName}} {{data.sendList[0]?.lastName}}-->
                                            </td>
                                            <td>

                                                {{data.phone}}
                                            </td>
                                            <td>
                                                {{data.status}}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="w-100">
                                        <tr>
                                            <td colspan="3">
                                                <!--<mdb-table-pagination [tableEl]="tableEl" [searchDataSource]="eformdata"></mdb-table-pagination>-->
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>