import { Injectable } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { RefreshContentService } from './refresh-content.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    imageUrl: string = environment.imgurl;

    public portal = null;
    public portalLabel = '';
    public portalContacts = '';
    public portalLogo = '';
    imageurl: any;
    loggedInUser: any;
    portalDetails: any;
    selectedCompanyDeatils: any;
    constructor(public refreshContentService: RefreshContentService,
        public authenticationService: AuthenticationService,
        public sanitizer: DomSanitizer,
        public apiService: ApiService) {
        // await this.getData();
        this.initializePortalData();
        this.initializeSelectedCompanyData();
    }

    /**
      * Common function to get data from observable or localstorage for throughout application 
      */
    initializePortalData() {
        this.refreshContentService.getPortalData.subscribe((data: Object) => {
            if (data) {
                this.portalDetails = data;
            } else {
                this.portalDetails = JSON.parse(
                    sessionStorage.getItem("portalDetails")
                );
            }
        });

    }

    /**
     * Common function to get data from observable or localstorage for throughout application 
     */
    initializeSelectedCompanyData() {
        this.refreshContentService.notifyToAll.subscribe((data: Object) => {
            if (data) {
                this.selectedCompanyDeatils = data['selected_company'];
            } else {
                this.selectedCompanyDeatils = JSON.parse(
                    sessionStorage.getItem("selectedCompanyDetails")
                );
            }
        });

    }

    resetselectedCompanyDeatils() {
        this.selectedCompanyDeatils = null
    }

    /**
     * Common Function to handle data & error for all api call
     * @param apiUrl
     * @param data
     */
    apiCall(baseUrl, apiData, data) {
        // if (apiData.showLoader) {
        //   // this.loaderService.showLoader();
        //   this.loaderService.show();
        // }
        return new Promise((resolve, reject) => {
            this.apiService
                .postData(baseUrl, apiData.apiName, apiData.header, data)
                .pipe(first())
                .subscribe(
                    (response: any) => {
                        //debugger;
                        // if (response.statusCode != 200) {
                        //   if (response.statusCode === 102) {
                        //     this.loaderService.hide();
                        //     reject(response);
                        //     let msg = response.message;
                        //     this.openAlertDialogForSessioTimeout(
                        //       "Session Timeout, Please login again"
                        //     );
                        //     this.router.navigate(["/auth/login"]);
                        //   } else if (response.statusCode === 400) {
                        //     this.loaderService.hide();
                        //     reject(response);
                        //     let msg = response.message;
                        //     this.openAlertDialog(msg);
                        //   } else if (response.statusCode === 503) {
                        //     this.loaderService.hide();
                        //     reject(response);
                        //     let msg = response.message;
                        //     this.openAlertDialog(msg);
                        //   } else if (response.statusCode === 103) {
                        //      this.loaderService.hide();
                        //     reject(response);
                        //     let msg = response.message;
                        //     this.openAlertDialogForSessioTimeout(msg);
                        //   } else {
                        //     this.loaderService.hide();
                        //     resolve(response);
                        //   }
                        // } else {
                        //   this.loaderService.hide();
                        //   resolve(response);
                        // }
                    },
                    (error) => {
                        // this.loaderService.hide();
                        this.handleError(error);
                        reject(error);
                    }
                );
        });
    }

    /**
    * Function to handle error for all api
    * @param error
    */
    handleError(error) {
        if (error.statusCode == 0) {
            // this.snackbarService.showError("Server Error, Try later");
            // else
            //   this.snackbarService.showError(
            //     error.statusText ||
            //       error.message ||
            //       "Something went wrong, Please login again"
            //   );
        }

    }
}
