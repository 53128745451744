import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RefreshContentService } from 'src/app/_services/refresh-content.service';
import { GeneralServiceService } from '../../_services/general-service.service';
import { CommonService } from '../../_services/common.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    title: any = '';
    location: Location;
    portalLogoImg: any = '';
    portalLogoLabel: any = '';
    public portalLabel = '';
    public portalLogo = '';

    companyLargeLogoImg: any = '';
    companyNameLabel: any = '';

    // private listTitles: any[];
    constructor(private router: Router,
        location: Location,
        private refreshContentService: RefreshContentService,
        private gs: GeneralServiceService,
        public commonService: CommonService,) {
        this.location = location;
    }

    ngOnInit(): void {
        this.refreshContentService.notify.subscribe(res => {
            if (res && res.hasOwnProperty("option")) {
                this.title = res['title']
            }
            else {
                this.title = 'Company Management'
            }
        });

        this.getData();
    }

    getData() {
        this.gs.getPortalData().subscribe((data: any) => {
            if (data != null) {
                this.portalLabel = data.data.setting.label;
                this.portalLogo = data.data.setting.filename;
                sessionStorage.setItem('portalLogo', this.portalLogo);
                sessionStorage.setItem('portalTitle', this.portalLabel);
                //sessionStorage.setItem("selectedCompanyLogoImgLarge", companyDetail.logo);

                this.portalLogoImg = sessionStorage.getItem('portalLogo');
                this.portalLogoLabel = sessionStorage.getItem('portalTitle');

                if (!sessionStorage.getItem('selectedCompanyLogoImgLarge') && !sessionStorage.getItem('selectedCompanyName')) {
                    this.companyLargeLogoImg = sessionStorage.getItem('portalLogo');
                    this.companyNameLabel = sessionStorage.getItem('portalTitle');
                }
                else {
                    this.companyLargeLogoImg = sessionStorage.getItem('selectedCompanyLogoImgLarge');
                    this.companyNameLabel = sessionStorage.getItem('selectedCompanyName');
                }
            }
        }, err => {
        });
    }

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
            return titlee
        }
        // for (var item = 0; item < this.listTitles.length; item++) {
        //   if (this.listTitles[item].path === titlee) {
        //     return this.listTitles[item].title;
        //   } else {
        //     if (titlee.split('?')[0] === '/add-new-location') return 'Add Location'
        //     if (titlee.split('/')[1] == 'merchant-qrcode') return 'Merchant QRCode'
        //     if (titlee.split('/')[1] == 'user-profile') return 'User profile'
        //   }
    }


}
