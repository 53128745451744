<div class="modal-header">
    <h4 class="modal-title">User Report</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body background-light-blue">
    <!--<div *ngIf="portalUser.usertype!='User' && portalUser.usertype!='Client'"></div>-->
    <div class="card">
        <div class="card-body">
            <div class="card-title row align-items-center">
                <div class="col-12 col-md-6 col-lg-4 mb-1 mb-lg-0">
                    <div class="input-group">
                        <input type="text" class="form-control" id="search"
                               (change)="searchUsers($event.target.value)"
                               placeholder="Search" />
                        <!--<form autocomplete="off">
                        </form>-->
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-1 mb-lg-0">
                    <select class="form-control" [(ngModel)]="search_userStatus" (change)="this.getuserData(true)">
                        <option selected>Filter By Status</option>
                        <option value=false>Active</option>
                        <!-- <option value="no">Inactive</option> -->
                        <option value=true>Deleted</option>
                    </select>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!--<label><b>Order by Date:</b></label>-->
                    <select class="form-control" [(ngModel)]="search_usertype" (change)="this.getuserData(true)">
                        <option selected>Filter By User Type</option>
                        <option value="Admin">Admin</option>
                        <option value="Manager">Manager</option>
                        <option value="Representative">Representative</option>
                        <option value="User">User</option>
                    </select>
                </div>
            </div>
            <div class="action-tab">
                <button type="button" class="mx-1 btn" tooltip="Export" container="body"
                        id="exportbtnpdf" (click)="exporttoexcel('ussssr_rept')">
                    <i class="fas fa-file-export"></i>
                </button>
            </div>
            <div class="table-responsive mb-1">
                <table id="ussssr_rept" class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" small="true" hover="true">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>User Type</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="userlist?.length == 0">
                        <tr>
                            <td colspan="5" class="text-center">No Records Found</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="userlist?.length > 0">
                        <tr *ngFor="let data of userlist;let i = index;">
                            <td>
                                {{data.firstName}} {{data.lastName}}
                            </td>
                            <td>
                                {{data.email}}
                            </td>
                            <td>
                                {{data.phone}}
                            </td>
                            <td>
                                {{data.usertype}}
                            </td>
                            <td class="paint-{{(data.removed_flg) ? 'red':'green'}}">
                                {{(data.removed_flg)?'Deleted':'Active'}}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="w-100">
                        <tr>
                            <td colspan="5">
                                <!--<div class="table-page" *ngIf="userlist?.length > 10">
                                    <div class="page-controls">
                                        <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                             class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                             nextLabel="Next"></pagination-controls>
                                    </div>
                                    <div class="page-size">
                                        <label class="mb-0 mr-2">Item per page</label>
                                        <select #val (change)="changePage(val.value)"
                                                class="form-control form-control-sm" id="pageSelector">
                                            <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                        </select>
                                    </div>
                                </div>-->
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal" (click)="closeModal('close')">Close</button>
    <!-- <button type="button" class="btn btn-primary" (click)="closeModal('changes saved!')">Save changes</button> -->
</div>
