import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from 'src/app/_services/dataservices.service';
//import * as _ from 'lodash';
import * as _ from 'lodash-es';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';

@Component({
    selector: 'app-view-sent-eform-modal',
    templateUrl: './view-sent-eform-modal.component.html',
    styleUrls: ['./view-sent-eform-modal.component.css']
})
export class ViewSentEformModalComponent implements OnInit {

    dataId: string;
    sentType: string;
    formName: string;
    portalUser;
    eformsents = [];
    eformsentsEmail = [];
    eformsentsPhone = [];
    eformreceived = [];
    //eformdata: any = [];

    //Pagination
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;

    //@ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
    //@ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;

    elements: any = [];
    previous: any = [];

    constructor(
        private _bsModalRef: BsModalRef,
        private spinner: NgxSpinnerService,
        private calldata: DataservicesService,
        private toast: ToastrService
    ) { }

    ngOnInit(): void {

        // this.getSendDetails();
        this.getViewSentEform("akcessid");
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    getSendDetails() {

        if (this.sentType == "sent") {
            this.spinner.show();
            this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
            const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
            this.calldata.fetcheformsents(tmpdata).subscribe((data: any) => {
                if (data.status) {
                    this.eformsents = [];
                    this.eformsents = data.data.reverse();
                    this.spinner.hide();

                } else {
                    this.toast.info(data['msg']);
                    this.spinner.hide();
                }
            },
                error => { this.toast.error('something went wrong'); this.spinner.hide(); }
            )
            const xtmpdata = { 'eformId': this.dataId };

            this.calldata.getSentEformUrlByPhone_email(xtmpdata).subscribe((data: any) => {
                if (data.status) {
                    this.eformsentsEmail = [];
                    this.eformsentsPhone = []
                    this.eformsentsEmail = _.filter(data.data, { emailFlag: true });
                    this.eformsentsPhone = _.filter(data.data, { phoneFlag: true });
                    this.spinner.hide();
                } else {
                    this.toast.info(data['msg']);
                    this.spinner.hide();
                }
            },
                error => {
                    this.toast.error('something went wrong');
                    this.spinner.hide();
                }
            )


        } else if (this.sentType == "received") {
            this.spinner.show();
            this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
            const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
            this.calldata.fetcheformreceived(tmpdata).subscribe((data: any) => {
                if (data.status) {
                    this.eformreceived = [];
                    this.eformreceived = data.data;
                } else {
                    this.toast.info(data['msg']);
                }

                this.spinner.hide();
            },
                error => {
                    this.toast.error('something went wrong');
                    this.spinner.hide();
                }
            )
        }
    }

    //Pagination
    onProjectPageChange(event, senderType) {
        this.currentPage = event;
        this.getViewSentEform(senderType)
        // this.getuserData(true);
    }

    changePage(value, senderType) {
        this.currentPage = 1;
        this.pageSize = parseInt(value);
        this.getViewSentEform(senderType)
        // this.getuserData(true);
    }

    selectedTab: any = "AKcess Id";
    searchValue: any = "";

    getViewSentEform(senderType) {
        this.spinner.show()
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        let data = {
            senderType: senderType,
            page: this.currentPage,
            limit: this.pageSize,
            akcessId: this.portalUser.akcessId,
            eformId: this.dataId,
            search: this.searchValue
        }
        this.selectedTab = senderType == "phone" ? "phone" : senderType == "email" ? "Email" : "AKcess Id"
        if (this.sentType == "sent") {
            this.spinner.show();
            this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
            // const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
            this.calldata.fetcheformsents(data).subscribe((data: any) => {
                if (data.status) {
                    if (senderType == "akcessid") {
                        this.eformsents = [];
                        this.eformsents = data.data.docs.reverse();
                    } else if (senderType == "email") {
                        this.eformsentsEmail = [];
                        this.eformsentsEmail = data.data.docs.reverse();
                    } else if (senderType == "phone") {
                        this.eformsentsPhone = [];
                        this.eformsentsPhone = data.data.docs.reverse();
                    }
                    this.totalCount = data.data.totalDocs
                    this.spinner.hide();

                } else {
                    this.toast.info(data['msg']);
                    this.spinner.hide();
                }
            },
                error => { this.toast.error('something went wrong'); this.spinner.hide(); }
            )
        } else if (this.sentType == "received") {
            this.spinner.show();
            this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
            const tmpdata = { 'akcessId': this.portalUser.akcessId, 'eformId': this.dataId };
            this.calldata.fetcheformreceived(tmpdata).subscribe((data: any) => {
                if (data.status) {
                    this.eformreceived = [];
                    this.eformreceived = data.data.docs;
                } else {
                    this.toast.info(data['msg']);
                }

                this.spinner.hide();
            },
                error => {
                    this.toast.error('something went wrong');
                    this.spinner.hide();
                }
            )
        }
        this.searchValue = ""
    }
}
