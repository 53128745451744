<div class="eforms-response-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">eForms Responses</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Home</a>
                </li>
                <li class="breadcrumb-item active">eForms Response</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <span>
                        <div class="cu2-views-bar__options">
                            <div class="cu-filter-block">
                                <div class="input-group">
                                    <input
                                        type="search"
                                        class="form-control"
                                        id="search"
                                        (search)="
                                            searchEformResponse(
                                                'search',
                                                $event.target.value
                                            )
                                        "
                                        placeholder="Search"
                                    />
                                </div>
                            </div>
                            <div class="cu2-views-bar__options-divider"></div>
                            <div class="cu-filter-block">
                                <app-filter-component></app-filter-component>
                            </div>
                            <div class="cu-filter-block">
                                <div ngbDropdown>
                                    <div class="btn-sorting" ngbDropdownToggle>
                                        <div
                                            aria-hidden="true"
                                            class="cu2-views-bar__btn-icon icon"
                                        >
                                            <svg
                                                height="64px"
                                                width="64px"
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                viewBox="0 0 512 512"
                                                xml:space="preserve"
                                                fill="#000000"
                                            >
                                                <g
                                                    id="SVGRepo_bgCarrier"
                                                    stroke-width="0"
                                                ></g>
                                                <g
                                                    id="SVGRepo_tracerCarrier"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <g>
                                                        <path
                                                            style="
                                                                fill: #656f7d;
                                                            "
                                                            d="M123.177,505.18c0.545,0.543,1.117,1.058,1.713,1.547c0.264,0.219,0.546,0.408,0.818,0.613 c0.334,0.251,0.661,0.51,1.008,0.742c0.33,0.222,0.675,0.413,1.013,0.616c0.312,0.186,0.616,0.383,0.937,0.554 c0.349,0.186,0.704,0.343,1.06,0.51c0.334,0.158,0.662,0.324,1.004,0.467c0.346,0.143,0.697,0.258,1.049,0.383 c0.366,0.132,0.728,0.272,1.1,0.385c0.352,0.105,0.706,0.185,1.061,0.273c0.382,0.098,0.76,0.203,1.15,0.281 c0.408,0.081,0.816,0.129,1.227,0.188c0.341,0.048,0.678,0.113,1.022,0.147c0.765,0.074,1.531,0.115,2.298,0.115 s1.533-0.04,2.296-0.116c0.346-0.034,0.681-0.099,1.022-0.147c0.411-0.059,0.819-0.107,1.227-0.188 c0.389-0.078,0.768-0.185,1.15-0.281c0.355-0.088,0.709-0.166,1.061-0.273c0.372-0.113,0.734-0.253,1.1-0.385 c0.352-0.126,0.703-0.24,1.049-0.383c0.343-0.143,0.672-0.309,1.004-0.467c0.355-0.166,0.711-0.324,1.06-0.51 c0.321-0.172,0.625-0.368,0.937-0.554c0.34-0.203,0.683-0.394,1.013-0.616c0.349-0.233,0.676-0.492,1.008-0.742 c0.273-0.205,0.554-0.394,0.818-0.613c0.596-0.489,1.168-1.004,1.713-1.547l116.359-116.361c9.089-9.087,9.089-23.824,0-32.912 c-9.087-9.089-23.824-9.089-32.912,0l-76.632,76.637V23.273C162.91,10.42,152.49,0,139.637,0s-23.273,10.42-23.273,23.273v409.27 l-76.636-76.634c-9.087-9.089-23.824-9.089-32.912,0c-9.089,9.087-9.089,23.823,0,32.912L123.177,505.18z"
                                                        ></path>
                                                        <path
                                                            style="
                                                                fill: #656f7d;
                                                            "
                                                            d="M387.113,5.274c-0.261-0.216-0.538-0.402-0.807-0.604c-0.34-0.254-0.67-0.515-1.021-0.751 c-0.327-0.219-0.667-0.408-1.002-0.608c-0.316-0.189-0.625-0.388-0.953-0.562c-0.343-0.183-0.694-0.338-1.046-0.504 c-0.338-0.16-0.67-0.329-1.016-0.472c-0.341-0.141-0.689-0.256-1.035-0.379c-0.369-0.133-0.737-0.276-1.116-0.389 c-0.346-0.104-0.694-0.18-1.043-0.268c-0.388-0.098-0.771-0.206-1.167-0.285c-0.399-0.079-0.802-0.126-1.202-0.183 c-0.349-0.051-0.694-0.116-1.049-0.152c-0.74-0.073-1.482-0.11-2.225-0.112C372.409,0.003,372.389,0,372.364,0 s-0.045,0.003-0.07,0.003c-0.743,0.003-1.485,0.039-2.225,0.112c-0.355,0.036-0.7,0.101-1.049,0.152 c-0.402,0.057-0.805,0.104-1.202,0.183c-0.396,0.078-0.779,0.186-1.167,0.285c-0.349,0.088-0.697,0.164-1.043,0.268 c-0.379,0.115-0.745,0.256-1.116,0.389c-0.346,0.124-0.694,0.237-1.035,0.379c-0.348,0.143-0.68,0.312-1.016,0.472 c-0.352,0.164-0.703,0.32-1.046,0.504c-0.327,0.174-0.636,0.372-0.953,0.562c-0.335,0.2-0.675,0.389-1.002,0.608 c-0.352,0.236-0.681,0.496-1.021,0.751c-0.268,0.202-0.546,0.388-0.807,0.604c-0.596,0.489-1.168,1.004-1.713,1.547 L239.542,123.179c-9.089,9.087-9.089,23.824,0,32.912c9.087,9.089,23.824,9.089,32.912,0l76.637-76.632v409.268 c0,12.853,10.42,23.273,23.273,23.273s23.273-10.42,23.273-23.273V79.459l76.636,76.634c4.543,4.544,10.499,6.816,16.455,6.816 c5.956,0,11.913-2.271,16.455-6.817c9.089-9.087,9.089-23.824,0-32.912L388.826,6.82C388.281,6.277,387.709,5.762,387.113,5.274z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="cu2-views-bar__btn-title">
                                            Sort by
                                        </div>
                                    </div>
                                    <div
                                        ngbDropdownMenu
                                        autoClose
                                        aria-labelledby="dropdownBasic1"
                                    >
                                        <p class="heading">Sort by</p>
                                        <a
                                            ngbDropdownItem
                                            class="dropdown-item"
                                            *ngFor="let sortOrder of sortOrders"
                                            (click)="orderBy(sortOrder.value)"
                                            >{{ sortOrder.label }}</a
                                        >
                                    </div>
                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6 col-lg-3 col-xl-3 d-none"
                            >
                                <!--<label><b>Filter by eForm Status:</b></label>-->
                                <div class="input-group">
                                    <app-filter-component
                                        *ngIf="filterOptions.length > 0"
                                        [filterOptions]="filterOptions"
                                    ></app-filter-component>
                                    <select
                                        class="form-control"
                                        (change)="
                                            searchEformResponse(
                                                'select',
                                                $event.target.value
                                            )
                                        "
                                    >
                                        <option selected disabled>
                                            Filter By Status
                                        </option>
                                        <option value="">All</option>
                                        <option value="pending">Pending</option>
                                        <option value="alive">Alive</option>
                                        <option value="accept">Accept</option>
                                        <option value="verify and accept">
                                            Verify and Accept
                                        </option>
                                        <option value="update client field">
                                            Update Client
                                        </option>
                                        <option value="return">Return</option>
                                        <option value="reject">Reject</option>
                                    </select>
                                </div>
                            </div>
                            <div
                                class="col-12 col-md-6 col-lg-3 col-xl-3 d-none"
                            >
                                <div class="input-group">
                                    <div ngbDropdown class="d-inline-block">
                                        <button
                                            type="button"
                                            class="btn-sorting"
                                            id="dropdownBasic1"
                                            ngbDropdownToggle
                                        >
                                            Sort by
                                        </button>
                                        <div
                                            ngbDropdownMenu
                                            aria-labelledby="dropdownBasic1"
                                        >
                                            <p class="heading">SORT BY</p>
                                            <a>Newest to Oldest</a>
                                            <a>Oldest to Newest</a>
                                        </div>
                                    </div>
                                    <!--<label><b>Order by Date:</b></label>-->
                                    <select
                                        class="form-control"
                                        (change)="orderBy($event.target.value)"
                                    >
                                        <option selected disabled>
                                            Sort By
                                        </option>
                                        <option value="nto">
                                            Newest to Oldest
                                        </option>
                                        <option value="otn">
                                            Oldest to Newest
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </span>
                    <div class="action-tab">
                        <div class="row">
                            <div class="col-md-4">
                                <button
                                    type="button"
                                    class="mx-1 btn"
                                    (click)="deleteRow()"
                                    [disabled]="somethingSelected"
                                    tooltip="Delete"
                                    container="body"
                                >
                                    <i class="fas fa-trash"></i>
                                </button>
                                <span class="vertical-divider">|</span>
                                <button
                                    type="button"
                                    class="mx-1 btn"
                                    (click)="viewRecycle()"
                                    tooltip="Recycle Bin"
                                    container="body"
                                >
                                    <i class="fal fa-recycle"></i>
                                </button>
                                <span class="vertical-divider">|</span>
                                <button
                                    type="button"
                                    class="mx-1 btn"
                                    (click)="openExportToExcelModal()"
                                    tooltip="Download as Excel"
                                    container="body"
                                >
                                    <i class="fas fa-file-excel"></i>
                                </button>
                                <span class="vertical-divider">|</span>
                                <button
                                    type="button"
                                    class="mx-1 btn"
                                    (click)="zipDocument()"
                                    tooltip="Zip Files"
                                    container="body"
                                >
                                    <i class="fas fa-file-archive"></i>
                                </button>
                                <span class="vertical-divider">|</span>
                                <button
                                    type="button"
                                    class="mx-1 btn"
                                    (click)="sendResponseToGoogleSheet()"
                                >
                                    <i class="fas fa-file-text"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableEl="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th
                                        [mdbTableSort]="eformresponse"
                                        sortBy="formName"
                                    >
                                        eForm Name
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="eformresponse"
                                        sortBy="firstName"
                                    >
                                        First Name
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="eformresponse"
                                        sortBy="lastName"
                                    >
                                        Last Name
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="eformresponse"
                                        sortBy="akcessId"
                                    >
                                        AKcess Id
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="eformresponse"
                                        sortBy="date"
                                    >
                                        Date/Time
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="eformresponse"
                                        sortBy="removed_flg"
                                    >
                                        Status
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="eformresponse?.length == 0">
                                <tr>
                                    <td colspan="7" class="text-center">
                                        No eForms Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="eformresponse?.length > 0">
                                <tr
                                    [id]="data?._id"
                                    *ngFor="
                                        let data of eformresponse
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                >
                                    <td>
                                        <input
                                            class=""
                                            type="checkbox"
                                            [attr.id]="data?._id"
                                            (click)="
                                                update_excel_data(
                                                    data,
                                                    $event.target.checked
                                                )
                                            "
                                        />
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="
                                                openViewEformModal(
                                                    i,
                                                    data?.firstName +
                                                        '_' +
                                                        data?.lastName,
                                                    data._id,
                                                    data?.akcessId
                                                );
                                                resetVerifiedItems();
                                                pullDataStatus = data.pulldata
                                            "
                                        >
                                            {{ data?.formName | titlecase }}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="
                                                openViewEformModal(
                                                    i,
                                                    data?.firstName +
                                                        '_' +
                                                        data?.lastName,
                                                    data._id,
                                                    data?.akcessId
                                                );
                                                resetVerifiedItems();
                                                pullDataStatus = data.pulldata
                                            "
                                        >
                                            {{ data?.firstName }}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="
                                                openViewEformModal(
                                                    i,
                                                    data?.firstName +
                                                        '_' +
                                                        data?.lastName,
                                                    data._id,
                                                    data?.akcessId
                                                );
                                                resetVerifiedItems();
                                                pullDataStatus = data.pulldata
                                            "
                                        >
                                            {{ data?.lastName }}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="
                                                openViewEformModal(
                                                    i,
                                                    data?.firstName +
                                                        '_' +
                                                        data?.lastName,
                                                    data._id,
                                                    data?.akcessId
                                                );
                                                resetVerifiedItems();
                                                pullDataStatus = data.pulldata
                                            "
                                        >
                                            {{ data?.akcessId }}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="
                                                openViewEformModal(
                                                    i,
                                                    data?.firstName +
                                                        '_' +
                                                        data?.lastName,
                                                    data._id,
                                                    data?.akcessId
                                                );
                                                resetVerifiedItems();
                                                pullDataStatus = data.pulldata
                                            "
                                        >
                                            {{ data?.date | date : "medium" }}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="
                                                openViewEformModal(
                                                    i,
                                                    data?.firstName +
                                                        '_' +
                                                        data?.lastName,
                                                    data._id,
                                                    data?.akcessId
                                                );
                                                resetVerifiedItems();
                                                pullDataStatus = data.pulldata
                                            "
                                        >
                                            {{ data?.status | titlecase }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="7">
                                        <div
                                            class="table-page"
                                            *ngIf="totalCount > 10"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- Modals -->
<!-- View Eform Response Modal -->
<!--<div class="modal fade" id="viewform" tabindex="-1" role="dialog" aria-labelledby="viewformModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewformModalLabel">{{formname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="facematch == 'yes' && pullDataStatus == 'yes'">
                    <h5 class="text-success" *ngIf="isFacialMatched == 'true'">Face Matched</h5>
                    <h5 class="text-danger" *ngIf="isFacialMatched == 'false'">Face Mismatched</h5>
                </div>
                <div class="table-responsive mb-4">
                    <table mdbTable #tableEl="mdbTable" small="true" class="z-depth-1" hover="true">
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-center">Check To Verify</th>
                                <th class="text-center">Expiry Date</th>
                                <th class="text-center">Grade Verification</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of fields;let i = index;">
                                <td>
                                    <div class="form-group" *ngIf="data.keytype == 'text' || data.keytype == 'Text'
                                         || data.keytype == 'arabicfirstname' || data.keytype == 'arabiclastname'
                                         || data.keytype == 'string' || data.keytype == 'list'
                                         || data.keytype == 'number' || data.keytype == 'address'
                                         || data.keytype == 'phone' || data.keytype == 'mobile'">
                                        <label><b>{{data?.labelname}}</b></label>
                                        <input type="{{data.keytype}}" class="form-control form-control-sm mb-1" value="{{data.value}}" placeholder="{{data.key}}" readonly>-->
<!-- Section fields  -->
<!--<ng-container *ngIf="data && data.optionsData && data.optionsData.length > 0">
        <ng-container *ngFor="let secVal of data.optionsData">
            <div class="mb-2" *ngIf="data.keytype != 'radio' && data.keytype != 'checkbox'">
                <label class="mb-1">{{secVal.labelname}}</label>
                <input class="form-control form-control-sm" value="{{secVal.value}}" type="text" placeholder="{{secVal.key}}" readonly>
            </div>

            <ng-container *ngIf="secVal && secVal.moreFields">
                <ng-container class="background-light-grey" *ngFor="let s of  secVal?.moreFields">
                    <div class="mb-2">
                        <label class="mb-1">{{s?.labelname}}</label>
                        <input class="form-control form-control-sm mb-1" value="{{s?.value}}" type="text" placeholder="{{secVal.key}}" readonly>

                        <ng-container *ngFor="let d of parseMoreData(s?.options)">
                            <div class="form-check mb-1" *ngIf="s && (s.keytype == 'radio' || s.keytype == 'checkbox')">
                                <input class="form-check-input" type="{{s?.keytype}}" [checked]="d?.checked || s?.value === d?.lable" name="box-name1{{s?.keytype}}" placeholder="{{d?.key}}" disabled>
                                <label class="form-check-label">{{d?.lable}}</label>
                            </div>

                            <div class="mb-1" *ngIf="s && (s.keytype != 'radio' && s.keytype != 'checkbox')">
                                <label>{{s?.labelname}}</label>
                                <input class="form-control form-control-sm" value="{{s?.value}}" type="text" placeholder="{{secVal.key}}" readonly>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<div class="form-group" *ngIf="data.keytype == 'date' || data.keytype == 'radio'
     || data.keytype == 'password' || data.keytype == 'checkbox'
     || data.keytype == 'select'">
    <label class="mb-1"><b>{{data?.labelname}}</b></label>
    <input type="text" class="form-control form-control-sm" value="{{data.value}}" placeholder="{{data.key}}" readonly>-->
<!-- Section fields  -->
<!--<ng-container *ngIf="data && data.optionsData && data.optionsData.length > 0">
            <ng-container *ngFor="let secVal of data.optionsData">
                <div class="mb-2" *ngIf="(data.keytype == 'radio' || data.keytype == 'checkbox') && (secVal?.checked || secVal?.lable == data?.value)">
                    <div class="form-check">
                        <input class="form-check-input" value="{{secVal?.lable}}" type="{{data?.keytype}}" [checked]="secVal?.checked || secVal?.lable == data?.value" name="box-name{{data?.keytype}}" placeholder="{{secVal?.key}}" disabled>
                        <label class="form-check-label">{{secVal?.lable}}</label>
                    </div>
                </div>
                <ng-container *ngIf="secVal && secVal.moreFields && ( secVal.checked || secVal.lable == data.value)">
                    <div class="mb-2 px-2 py-1 background-light-grey" *ngFor="let s of  secVal?.moreFields">
                        <label class="mb-1">{{s?.labelname}}</label>
                        <input class="form-control form-control-sm mb-1" value="{{s?.value}}" type="text" placeholder="{{secVal.key}}" readonly>
                        <ng-container *ngFor="let d of parseMoreData(s?.options)">
                            <div class="form-check mb-1" *ngIf="s && (s.keytype == 'radio' || s.keytype == 'checkbox') && (d?.checked || s?.value === d?.lable)">
                                <input class="form-check-input" type="{{s?.keytype}}" [checked]="d?.checked || s?.value === d?.lable" name="box-name" placeholder="{{d?.key}}" disabled>
                                <label class="form-check-label">{{d?.lable}}</label>
                            </div>
                            <div class="mb-1" *ngIf="s && (s.keytype != 'radio' && s.keytype != 'checkbox')">
                                <label class="mb-1">{{s?.labelname}}</label>
                                <input class="form-control form-control-sm" value="{{s?.value}}" type="text" placeholder="{{secVal.key}}" readonly>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="data.sectionfields && data.sectionfields.length > 0">
        <div class="form-group" *ngFor="let secVal of data.sectionfields">
            <label class="mb-1"><b>{{secVal?.labelname}}</b></label>
            <input type="text" class="form-control form-control-sm" value="{{secVal.value}}" placeholder="{{secVal.key}}" readonly>
        </div>
    </div>
    <div class="form-group" *ngIf="data.keytype == 'File' || data.keytype == 'file'">
        <a class="btn btn-sm btn-primary" href="{{imageUrl}}{{data?.path}}" target="_blank">Document {{i}}</a>
        <button class="btn btn-primary mx-2" *ngIf="pullDataStatus == 'yes'" (click)="fetchDataFromDoc(data)">
            <i class="fas fa-download" tooltip="Fetch data from Document" container="body"></i>
        </button>
    </div>
</td>
<td class="text-center">
    <div>-->
<!-- <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" [(ngModel)]="data.isverified"
           (change)="setVerifiedItem($event, i)" name="chk" id="chk{{i}}">
</div> -->
<!--<div class="custom-control custom-checkbox" *ngIf="data.labelname">
                            <ng-container *ngIf="!data.isDocFetched">
                                <input type="checkbox" name="chk" [(ngModel)]="data.isverified"
                                       (change)="setVerifiedItem($event, i)" class="custom-control-input" id="chk{{i}}">
                                <label class="custom-control-label" for="chk{{i}}"></label>
                            </ng-container>
                        </div>
                    </div>
                </td>
                <td class="text-center">
                    <div class="d-flex align-items-center justify-content-center">
                        <label class="mb-0"><b>{{ data?.expiryDate | date: 'dd-MM-yyyy' }}</b></label>
                        <button type="button" class="btn btn-sm btn-primary ml-2" [disabled]="!data.isverified"
                                (click)="openExpiryDate(i)" data-toggle="modal" data-target="#expiryDateModal">
                            Add
                        </button>
                    </div>
                </td>
                <td class="text-center">
                    <div *ngIf="!data.isDocFetched">
                        <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#viewgrade"
                                (click)="viewGradeVer(data)" id="sub">
                            View
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot class="grey lighten-5 w-100">
            <tr>
                <td colspan="4"></td>
            </tr>
        </tfoot>
    </table>
</div>
<div class="form-group row">
    <label class="col-12 col-md-4 col-form-label">eForm Status</label>
    <div class="col-12 col-md-8">
        <select class="custom-select" [(ngModel)]="eformStatus">
            <option selected>Select</option>
            <option value="pending">Pending</option>
            <option value="alive">Alive</option>
            <option value="accept">Accept</option>
            <option value="verify and accept">Verify and Accept</option>-->
<!-- <option value="createclient">Create Client </option> -->
<!--<option value="update client field">Create/Update Client </option>
                            <option value="update employee field">Create/Update Employee </option>
                            <option value="return">Return</option>
                            <option value="reject">Reject</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="eformStatus">
                    <label class="d-block mb-0">
                        <b>
                            <span *ngIf="eformStatus=='update client field'">Update Client:</span>
                            <span class="text-capitalize"
                                *ngIf="eformStatus!='update client field'">{{eformStatus}}:</span>
                        </b>
                    </label>
                    <p>{{StatusDescription[eformStatus]}}</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onSubmit()" class="btn btn-primary">
                    Submit
                </button>
                <button type="button" class="btn btn-primary" id="exportbtnpdf" (click)="openMobilePdf()">
                    View As PDF
                </button>
                <button type="button" class="btn btn-danger" id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>-->
<!-- View Grade Modal -->
<!--<div class="modal fade" id="viewgrade" tabindex="-1" role="dialog" aria-labelledby="viewgradeModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewgradeModalLabel">View Grade</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="graderLoader">Loading.......</div>
                <div *ngIf="!graderLoader">
                    <label><b>This field has the following grades:</b></label>
                    <div *ngFor="let data of fieldVerifierData?.data">
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'G'">Goverment</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'F'">Financial</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'T'">Telecom</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'A'">AKcess</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'O'">Other</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>-->
<!--<div class="modal fade" id="expiryDateModal" tabindex="-1" role="dialog" aria-labelledby="expiryDateModalModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="expiryDateModalModalLabel">Add Expiry Date</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">-->
<!-- <div class="form-group">
    <label for="addExpiryDate"><b>Expiry Date</b></label>
    <p-calendar [(ngModel)]="addExpiryDate" [minDate]="minDate" name="addExpiryDate" [readonlyInput]="true" inputId="min-max"></p-calendar>
</div> -->
<!--</div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="pushExpiryDateInFiedls()">Submit</button>
                <button type="button" class="btn btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>-->
<!-- <table id="xls_formfields">
    <tr>
        <th colspan="2">{{formname}}</th>
    </tr>
    <tr *ngFor="let data of fields; let i = index;">
        <th style="font-weight: bold;">
            {{data.key}}
        </th>
        <td *ngIf="data.keytype == 'text' || data.keytype == 'Text'">
            {{data.value}}
        </td>
        <td *ngIf="data.keytype == 'File' || data.keytype == 'file'">
            <a href="{{imageUrl}}{{data?.val?.docUrl}}" target="_blank"> Document {{i}}</a>
        </td>
    </tr>
</table>

<div id="pdf_formfields" style=" display: none;">
    <h3 style="text-align: center;">{{formname}}</h3>
    <p *ngFor="let data of fields; let i = index;">
        <b>{{data.key}}: </b>{{data.value}}<br>
    </p>
</div> -->
<!-- {{ colspan() }} -->
