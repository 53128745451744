import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-update-access-control",
  templateUrl: "./update.component.html",
  styleUrls: ["./update.component.css"],
})
export class UpdateAccessControlComponent implements OnInit {
  locationId: string;
  constructor(private route: ActivatedRoute, private router: Router) {
    let me = this;
    this.route.params.subscribe((params) => {
      me.locationId = params.locationId;
    });
  }

  ngOnInit(): void {}
}
