<div class="notification-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Notifications</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Home</a>
                </li>
                <li class="breadcrumb-item active">Notifications</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->

    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div class="input-group">
                                <input
                                    type="search"
                                    [(ngModel)]="search_notification"
                                    class="form-control"
                                    id="search"
                                    placeholder="Search Notification"
                                    (search)="
                                        searchNotification($event.target.value)
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table text-center"
                            mdbTable
                            #tableEl="mdbTable"
                            hover="true"
                            small="true"
                        >
                            <thead>
                                <tr>
                                    <th
                                        [mdbTableSort]="notificationList"
                                        sortBy="akcessId"
                                    >
                                        AKcess ID
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="notificationList"
                                        sortBy="labelname"
                                    >
                                        Label Name
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="notificationList"
                                        sortBy="value"
                                    >
                                        Value
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="notificationList"
                                        sortBy="createdAt"
                                    >
                                        Date Time
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                    <th
                                        [mdbTableSort]="notificationList"
                                        sortBy="is_notification_new"
                                    >
                                        Status
                                        <span>
                                            <mdb-icon icon="sort" class="fas"
                                                ><i class="fa-sort fas"></i
                                            ></mdb-icon>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="notificationList?.length == 0">
                                <tr>
                                    <td colspan="7" class="text-center">
                                        No notification to show
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="notificationList?.length > 0">
                                <tr
                                    [id]="data?._id"
                                    *ngFor="
                                        let data of notificationList
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                >
                                    <td
                                        (click)="
                                            data.is_notification_new = false
                                        "
                                    >
                                        <a class="d-inline-block">
                                            {{ data?.akcessId }}
                                        </a>
                                    </td>
                                    <td
                                        (click)="
                                            data.is_notification_new = false
                                        "
                                    >
                                        <a class="d-inline-block">
                                            {{ data?.labelname }}
                                        </a>
                                    </td>
                                    <td
                                        (click)="
                                            data.is_notification_new = false
                                        "
                                    >
                                        <a class="d-inline-block">
                                            {{ data?.value }}
                                        </a>
                                    </td>
                                    <td
                                        (click)="
                                            data.is_notification_new = false
                                        "
                                    >
                                        <a class="d-inline-block">
                                            {{
                                                data.createdAt
                                                    | date : "mediumDate"
                                            }}
                                        </a>
                                    </td>
                                    <td class="text-center">
                                        <span
                                            *ngIf="
                                                data.is_notification_new == true
                                            "
                                            class="badge badge-primary"
                                            >New</span
                                        >
                                        <span
                                            *ngIf="
                                                data.is_notification_new ==
                                                false
                                            "
                                            class="badge badge-info"
                                            >Read</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="7">
                                        <div
                                            class="table-page"
                                            *ngIf="
                                                notificationList?.length &&
                                                totalPages >= currentPage
                                            "
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    (change)="
                                                        changePageSize($event)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                    [(ngModel)]="pageSize"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
