import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';

import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { tap, startWith, switchMap } from 'rxjs/operators';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { MessagingHistoryComponent } from './messaging-history/messaging-history.component';

@Component({
	selector: 'app-messaging',
	templateUrl: './messaging.component.html',
	styleUrls: ['./messaging.component.css'],
	providers: [DataservicesService],
})
export class MessagingComponent implements OnInit {
	msg: string;
	message: string;
	isSelectAllAkcessId = false;
	isSelectAllEmails = false;
	bsModalRef: BsModalRef;
	isSelectAllClientEmails = false;
	selectAllEmail: any = [];
	selectAllClients: any = [];
	selectAllAkcessId: any = [];
	remainingText = 160;
	remainingTextMsg = 160;
	saveUserData: any = [];
	userwithChips_phone: any;
	akcessIDChips: any = [];
	userData: any = [];
	userDataAKcessId: any = [];
	userDataEmail: any = [];
	userDataPhone: any = [];
	phoneNumberFG: FormGroup;
	sendEmailFG: FormGroup;
	pushNotificationFG: FormGroup;
	phoneNumbers: any = [];

	notificationMsgText: string = "";
	emailMsgText: string = "";

	allUsers$: Observable<any>;
	emailHistory$: Observable<any>;
	pushHistory$: Observable<any>;
	allUserData: any;
	clientlist: any;
	akcessIds: any = [];
	emails: any = [];

	subject$: Subject<void> = new Subject<void>();

	constructor(
		private calldata: DataservicesService,
		private toast: ToastrService,
		private formBuilder: FormBuilder,
		private _cdr: ChangeDetectorRef,
		public modalService: BsModalService,
		private spinner: NgxSpinnerService
	) { }

	ngOnInit(): void {
		// this.calldata.getuserlist_search().subscribe(
		//     res => {
		//         this.allUserData = res.data
		//     }
		// );
		this.allUsers$ = this.calldata.getuserlist_search();
		this.getEmaillHistory();
		this.getPushHistory();
		this.sendEmailFG = this.formBuilder.group({
			subject: ['', Validators.required],
			emails: ['', Validators.required],
			text: ['', Validators.required],
			html: [''],
			createdBy: [''],
		});
		this.getClientData();
		this.pushNotificationFG = this.formBuilder.group({
			akcessIds: [null, Validators.required],
			title: ['', Validators.required],
			description: ['', Validators.required],
			createdBy: [''],
		});
	}
	getPushHistory() {
		// this.pushHistory$ = this.calldata.getMessagesHistory({ requesttype: 'notification', skip: 0, limit: 5 });
		this.pushHistory$ = this.subject$.pipe(
			startWith(null), tap(() => { this.spinner.show() }),
			switchMap(() => this.calldata.getMessagesHistory({ requesttype: 'notification', skip: 0, limit: 5 })),
			tap(() => { this.spinner.hide() })
		);
	}
	getEmaillHistory() {
		// this.emailHistory$ = this.calldata.getMessagesHistory({ requesttype: 'email', skip: 0, limit: 5 });
		this.emailHistory$ = this.subject$.pipe(
			startWith(null), tap(() => { this.spinner.show() }),
			switchMap(() => this.calldata.getMessagesHistory({ requesttype: 'email', skip: 0, limit: 5 })),
			tap(() => { this.spinner.hide() })
		);

	}
	getClientData() {
		const tmpdata = {
			search: '',
			page: 1,
			limit: 100,
		};
		this.calldata.getclients(tmpdata).subscribe((res: any) => {
			if (res.status) {
				this.clientlist = res.data.list;
			}
		}, (err) => {
			this.spinner.hide();
			this.toast.error("Oops, something went wrong. Please try again.")
		});
	}
	valueChange(value) {
		// this.remainingText = 160 - val;
		this.remainingText = (160 - value.length);
	}
	valueChangeMsg(value) {
		this.remainingTextMsg = (160 - value.length);
	}


	sendPushNotification() {
		if (this.pushNotificationFG.valid) {
			this.spinner.show();
			const name = sessionStorage.getItem('selectedCompanyName');
			this.pushNotificationFG.get('createdBy').setValue(name);
			let akcessId = this.pushNotificationFG.get('akcessIds').value;
			akcessId.forEach((element) => {
				akcessId = element.$ngOptionLabel;
				// akcessId = akcessId.split(" - ")[1];
				akcessId = {
					akcessId: akcessId.split(' - ')[1],
					userName: akcessId.split(' - ')[0],
				};
				this.akcessIds.push(akcessId);
				this.pushNotificationFG.get('akcessIds').setValue(this.akcessIds);
			});
			this.calldata.sendNotification(this.pushNotificationFG.getRawValue()).subscribe(
				(res) => {
					this.toast.success(res['message']);
					this.akcessIds = [];
					this.pushNotificationFG.reset();
					this.isSelectAllAkcessId = false;
					this.getPushHistory();
					this.spinner.hide();
				}, (err) => {
					this.spinner.hide();
					this.toast.error("Oops, something went wrong. Please try again.")
				}
			);

			// if(akcessId.length === this.akcessIds.length){
			// this.calldata.sendNotification(this.pushNotificationFG.getRawValue()).subscribe((res) => {
			//     this.toast.success(res["message"]);
			//     this.isSelectAllAkcessId = false
			// });
			// }

			// }
		} else {
			this.toast.error('Fields Can not be Empty');
		}
	}

	sendEmail() {
		debugger;
		if (this.sendEmailFG.valid) {
			this.spinner.show();
			const html = '<p>' + this.sendEmailFG.get('text').value + '</p>';
			this.sendEmailFG.get('html').setValue(html);
			let to = this.sendEmailFG.get('emails').value;
			to.forEach((element) => {
				const email = element.email;
				const akcessId = element.akcessId;
				const username = element.firstName + ' ' + element.lastName;
				to = {
					email,
					userName: username,
					akcessId,
				};
				this.emails.push(to);
				this.sendEmailFG.get('emails').setValue(this.emails);
			});
			const name = sessionStorage.getItem('selectedCompanyName');
			this.sendEmailFG.get('createdBy').setValue(name);

			this.calldata.sendMessage(this.sendEmailFG.getRawValue()).subscribe(
				(res) => {
					this.toast.success(res['message'] ? res['message'] : 'Message Sent');
					this.isSelectAllEmails = false;
					this.sendEmailFG.reset();
					this.emails = [];
					this.getEmaillHistory();
					this.spinner.hide();
				}, (err) => {
					this.spinner.hide();
					this.toast.error("Oops, something went wrong. Please try again.")
				}
			);

			// to.forEach(element => {
			//     to = element
			//     this.sendEmailFG.get('emails').setValue(to);
			//     // this.calldata.sendemail(this.sendEmailFG.getRawValue()).subscribe((res) => {
			//     //     this.toast.success(res["message"] ? res["message"] : 'Message Sent');
			//     //     this.isSelectAllEmails = false;
			//     // });
			// });
		} else {
			this.toast.error('Fields Can not be Empty');
		}
	}
	selectAll(data) {
		if (this.isSelectAllAkcessId) {
			const tempData = data;
			let akid = '';
			let firstName = '';
			let lastName = '';
			tempData.forEach((element) => {
				firstName = element.firstName;
				lastName = element.lastName;
				akid = element.akcessId;
				const data = {
					$ngOptionLabel: firstName + ' ' + lastName + ' - ' + akid,
				};
				this.selectAllAkcessId.push(data);
			});
			this.pushNotificationFG.get('akcessIds').setValue(this.selectAllAkcessId);
		}
		if (!this.isSelectAllAkcessId) {
			this.selectAllAkcessId = [];
			this.pushNotificationFG.get('akcessIds').setValue('');
		}
	}

	selectAllClient(data) {
		if (this.isSelectAllClientEmails) {
			this.sendEmailFG.get('emails').setValue(data);
		}
		if (!this.isSelectAllClientEmails) {
			this.selectAllEmail = [];
			this.sendEmailFG.get('emails').setValue('');
		}
	}

	selectAllEmails(data, data2?) {
		if (this.isSelectAllEmails) {
			const tempData = this.getUniqueAfterMerge(data, data2);
			this.sendEmailFG.get('emails').setValue(tempData);
		}
		if (!this.isSelectAllEmails) {
			this.selectAllEmail = [];
			this.sendEmailFG.get('emails').setValue('');
		}
	}

	getUniqueAfterMerge(arr1, arr2) {
		if (arr2 == undefined) {
			return arr1;
		}
		const arr = arr1.concat(arr2);
		const uniqueArr = [];
		for (const i of arr) {
			if (uniqueArr.indexOf(i) === -1) {
				uniqueArr.push(i);
			}
		}
		return uniqueArr;
	}

	seeAllHistory(type?: string) {
		const initialState = {
			dataType: type,
		};
		this.bsModalRef = this.modalService.show(MessagingHistoryComponent, {
			initialState,
			animated: true,
			backdrop: 'static',
			ignoreBackdropClick: true,
			class: 'modal-dialog-centered modal-xl',
		});
	}
}
