<!-- eForm Page -->
<div class="eforms-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">eForms</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Home</a>
                </li>
                <li class="breadcrumb-item active">eForms</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="input-group">
                                <input
                                    type="text"
                                    [(ngModel)]="search_formName"
                                    class="form-control"
                                    id="search"
                                    placeholder="Search eForm"
                                    (change)="searchEform($event.target.value)"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="input-group">
                                <select
                                    name="short_filter"
                                    class="form-control"
                                    (change)="
                                        filterData(
                                            'filterBy',
                                            $event.target.value
                                        )
                                    "
                                >
                                    <option value="">Filter By</option>
                                    <option value="signature">Signature</option>
                                    <option value="facematch">
                                        Face match
                                    </option>
                                    <option value="notification">
                                        Notification
                                    </option>
                                    <option value="pulldata">Pull data</option>
                                    <option value="verification">
                                        Verification
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="input-group">
                                <div ngbDropdown class="d-inline-block">
                                    <button
                                        type="button"
                                        class="btn-sorting"
                                        id="dropdownBasic1"
                                        ngbDropdownToggle
                                    >
                                        Sort by
                                    </button>
                                    <div
                                        ngbDropdownMenu
                                        autoClose
                                        aria-labelledby="dropdownBasic1"
                                    >
                                        <p class="heading">SORT BY</p>
                                        <a
                                            ngbDropdownItem
                                            (click)="
                                                filterData('sortBy', 'name')
                                            "
                                            >Name</a
                                        >
                                        <a
                                            ngbDropdownItem
                                            (click)="
                                                filterData('sortBy', 'date')
                                            "
                                            >Date</a
                                        >
                                        <a
                                            ngbDropdownItem
                                            (click)="
                                                filterData(
                                                    'sortBy',
                                                    'last_used'
                                                )
                                            "
                                            >Last Used</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-6 col-lg-3 col-xl-3 text-right mt-1 mt-md-0"
                        >
                            <button
                                type="button"
                                class="btn waves-effect waves-light btn-info add-btn"
                                (click)="createEform()"
                                tooltip="Add eForm"
                                placement="bottom"
                                container="body"
                            >
                                <i class="fas fa-plus-circle"></i>
                            </button>
                        </div>
                    </div>
                    <div class="action-tab">
                        <button
                            type="button"
                            class="mx-1 btn"
                            (click)="deleteRow()"
                            [disabled]="somethingSelected"
                            tooltip="Delete"
                            container="body"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button
                            type="button"
                            class="mx-1 btn"
                            (click)="viewRecycle()"
                            tooltip="Recycle Bin"
                            container="body"
                        >
                            <i class="fal fa-recycle"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableEl="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th
                                        [mdbTableSort]="eformdata"
                                        sortBy="formName"
                                    >
                                        Name
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <!--<th [mdbTableSort]="eformdata" sortBy="status">Status <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>-->
                                    <th
                                        [mdbTableSort]="eformdata"
                                        sortBy="date"
                                    >
                                        Date
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th
                                        [mdbTableSort]="eformdata"
                                        sortBy="akcessId"
                                    >
                                        Created By
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="eformdata?.length == 0">
                                <tr>
                                    <td colspan="4" class="text-center">
                                        No eForms Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="eformdata?.length > 0">
                                <tr
                                    *ngFor="
                                        let data of eformdata
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                >
                                    <td>
                                        <input
                                            class=""
                                            type="checkbox"
                                            value="{{ data?._id }}"
                                            (click)="bulkEformDelete($event)"
                                            [attr.id]="data?._id"
                                        />
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="viewEformModal(i)"
                                        >
                                            {{ data.formName }}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="viewEformModal(i)"
                                        >
                                            {{ data.date | date : "medium" }}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            class="d-inline-block"
                                            (click)="viewEformModal(i)"
                                        >
                                            {{ data?.user?.fullName }}
                                        </a>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="openSendEformModal(data)"
                                            [disabled]="data.fields.length == 0"
                                        >
                                            Send eForm
                                        </button>
                                        <button
                                            type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="
                                                viewSentEformsModal(
                                                    data._id,
                                                    'sent',
                                                    data.formName
                                                )
                                            "
                                        >
                                            View Sent
                                        </button>
                                        <button
                                            type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="
                                                openCloneEformModal(
                                                    data?.formName +
                                                        '-' +
                                                        'copy',
                                                    data?._id,
                                                    data
                                                )
                                            "
                                        >
                                            Clone eForm
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="4">
                                        <div
                                            class="table-page"
                                            *ngIf="totalCount > 10"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div
    class="modal"
    id="viewReceived"
    tabindex="-1"
    role="dialog"
    aria-labelledby="viewReceivedModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewReceivedModalLabel">
                    {{ formname }}
                </h4>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive mb-1">
                    <table
                        class="table color-bordered-table info-bordered-table"
                        mdbTable
                        #tableEl="mdbTable"
                        small="true"
                        hover="true"
                    >
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>AKcess Id</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="eformreceived?.length == 0">
                            <tr>
                                <td colspan="3" class="text-center">
                                    No sent eForms Found
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="eformreceived?.length > 0">
                            <tr
                                *ngFor="
                                    let data of eformreceived;
                                    let i = index
                                "
                            >
                                <td>
                                    {{ data?.firstName }} {{ data?.lastName }}
                                </td>
                                <td>{{ data?.akcessId }}</td>
                                <!-- <td>
                                    {{data.formName}}
                                </td> -->
                                <td>{{ data?.status }}</td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="3">
                                    <mdb-table-pagination
                                        [tableEl]="tableEl"
                                        [searchDataSource]="eformdata"
                                    ></mdb-table-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn waves-effect waves-light btn-danger"
                    id="close"
                    data-dismiss="modal"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
