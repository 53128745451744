<aside class="left-sidebar noprint">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <li class="nav-devider"></li>
                <!--<li class="nav-small-cap">COMPANIES</li>-->
                <li routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/company']"
                        (click)="navigate('Company Management')"
                    >
                        <i class="fal fa-building"></i
                        ><span class="hide-menu">Companies List</span>
                    </a>
                </li>
                <li class="company-list">
                    <a aria-expanded="false">
                        <i class="fal fa-list-alt"></i>
                        <span class="hide-menu">
                            <ng-select
                                class="select-dropdown navbar-dropdown"
                                [(ngModel)]="companySelect"
                                (change)="onSelectChange($event)"
                                placeholder="Select Company"
                                appendTo="body"
                                [clearable]="true"
                            >
                                <ng-option
                                    class="option-list"
                                    *ngFor="let data of listCompany"
                                    [value]="data"
                                    >{{ data.name }}</ng-option
                                >
                            </ng-select>
                        </span>
                    </a>
                </li>
                <li
                    *ngIf="
                        domainName &&
                        currentCompany?.market_sector === 'Real Estate'
                    "
                    routerLinkActive="active"
                >
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/property']"
                        (click)="navigate('Property')"
                    >
                        <i class="fal fa-home"></i>
                        <span class="hide-menu">
                            <div class="btn-property">Properties</div>
                        </span>
                    </a>
                </li>
                <li
                    class="nav-small-cap"
                    *ngIf="domainName"
                    routerLinkActive="active"
                >
                    MAIN
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/dashboard']"
                        (click)="navigate('Dashboard')"
                    >
                        <i class="fal fa-columns"></i
                        ><span class="hide-menu">Dashboard</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/verifications']"
                        (click)="navigate('Verify')"
                    >
                        <i class="fa fa-check-circle-o"></i
                        ><span class="hide-menu">Verify</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/employees']"
                        (click)="navigate('Employees')"
                    >
                        <i class="fal fa-users"></i
                        ><span class="hide-menu">Staff</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/clients']"
                        (click)="navigate('Clients')"
                    >
                        <i class="fad fa-user-tie"></i
                        ><span class="hide-menu">Clients</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/idcards']"
                        (click)="navigate('ID Cards')"
                    >
                        <i class="fad fa-user-tie"></i
                        ><span class="hide-menu">ID Cards</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/eforms']"
                        (click)="navigate('eForm')"
                    >
                        <i class="fab fa-wpforms"></i
                        ><span class="hide-menu">eForms</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/eformsresponse']"
                        (click)="navigate('Received eForms')"
                    >
                        <i class="fal fa-file-check"></i
                        ><span class="hide-menu">Received eForms</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/received-docs']"
                        (click)="navigate('Documents')"
                    >
                        <i class="fal fa-file-alt"></i
                        ><span class="hide-menu">Documents</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/certificate']"
                        (click)="navigate('Certificates')"
                    >
                        <i class="fal fa-file-certificate"></i
                        ><span class="hide-menu">Certificates</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/assets']"
                        (click)="navigate('Assets')"
                    >
                        <i class="fa fa-folder-o"></i
                        ><span class="hide-menu">Assets</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active" className="">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        href="#AccessControlSubNav"
                        data-toggle="collapse"
                        aria-expanded="false"
                    >
                        <i class="fal fa-id-card"></i
                        ><span class="hide-menu">Access Control</span>
                    </a>
                    <ul id="AccessControlSubNav" class="collapse">
                        <li *ngIf="domainName" routerLinkActive="active">
                            <a
                                class="waves-effect waves-dark"
                                aria-expanded="false"
                                routerLinkActive="active"
                                [routerLink]="['/panel/access-control']"
                                (click)="navigate('AccessControl')"
                            >
                                <i class="fal fa-id-card"></i
                                ><span class="hide-menu">Access Control</span>
                            </a>
                        </li>
                        <li *ngIf="domainName" routerLinkActive="active">
                            <a
                                class="waves-effect waves-dark"
                                aria-expanded="false"
                                routerLinkActive="active"
                                [routerLink]="['/panel/location']"
                                (click)="navigate('Location')"
                            >
                                <i class="fal fa-location-circle"></i
                                ><span class="hide-menu">Location</span>
                            </a>
                        </li>
                        <li *ngIf="domainName" routerLinkActive="active">
                            <a
                                class="waves-effect waves-dark"
                                aria-expanded="false"
                                routerLinkActive="active"
                                [routerLink]="['/panel/attendance-report']"
                                (click)="navigate('AttendanceReport')"
                            >
                                <i class="fal fa-clipboard-user"></i
                                ><span class="hide-menu"
                                    >Attendance Report</span
                                >
                            </a>
                        </li>
                    </ul>
                </li>
                
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/messaging']"
                        (click)="navigate('Messaging')"
                    >
                        <i class="fa fa-comment-o"></i
                        ><span class="hide-menu">Messaging</span>
                    </a>
                </li>

                <!-- <li *ngIf="domainName" routerLinkActive="active">
					<a class="waves-effect waves-dark" aria-expanded="false" routerLinkActive="active" [routerLink]="['/panel/invitation-list']" (click)="navigate('Invitation List')">
						<i class="fa fa-user-plus"></i><span class="hide-menu">Add Invitation</span>
					</a>
				</li> -->
                <!-- <li *ngIf="domainName" routerLinkActive="active">
					<a class="waves-effect waves-dark" aria-expanded="false" routerLinkActive="active" [routerLink]="['/panel/location']" (click)="navigate('Location')">
						<i class="fal fa-location-circle"></i><span class="hide-menu">Location</span>
					</a>
				</li>
				<li *ngIf="domainName" routerLinkActive="active">
					<a class="waves-effect waves-dark" aria-expanded="false" routerLinkActive="active" [routerLink]="['/panel/attendance-report']" (click)="navigate('AttendanceReport')">
						<i class="fal fa-clipboard-user"></i><span class="hide-menu">Attendance Report</span>
					</a>
				</li> -->
                <!-- <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/verifications']"
                        (click)="navigate('Verifications')"
                    >
                        <i class="fa fa-check-circle-o" aria-hidden="true"></i
                        ><span class="hide-menu">Verifications</span>
                    </a>
                </li> -->
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/notifications']"
                        (click)="navigate('Notifications')"
                    >
                        <i class="fa fa-bell-o"></i
                        ><span class="hide-menu">Notifications</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/settings']"
                        (click)="navigate('Settings')"
                    >
                        <i class="fal fa-cog"></i
                        ><span class="hide-menu">Settings</span>
                    </a>
                </li>
                <li *ngIf="domainName" routerLinkActive="active">
                    <a
                        class="waves-effect waves-dark"
                        aria-expanded="false"
                        routerLinkActive="active"
                        [routerLink]="['/panel/logs']"
                        (click)="navigate('Logs')"
                    >
                        <i class="fal fa-clipboard-list"></i
                        ><span class="hide-menu">Logs</span>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
</aside>
