import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";

@Component({
    selector: "app-add-property",
    templateUrl: "./add.component.html",
    styleUrls: ["./add.component.css"],
})
export class AddPropertyComponent implements OnInit {
    addPropertyForm: FormGroup;
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService
    ) {}

    ngOnInit(): void {}
}
