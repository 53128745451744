<!-- View Sent id Cards-->
<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewSentModalLabel">View Sent List</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-tab">
            <input type="hidden" [(ngModal)]="cardId" />
            <tabset [justified]="true">
                <tab heading="AKcess Id" [active]="true" (selectTab)="onSelect('akcessid')">
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>AKcess Id</th>
                                    <th>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="sendList?.length == 0">
                                <tr>
                                    <td colspan="3" class="text-center">No sent Found (AKcessId)</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="sendList?.length > 0">
                                <tr *ngFor="let data of sendList | paginate
                                : {
                                    itemsPerPage: pageSize,
                                    currentPage: currentPage,
                                    totalItems: totalCount
                                  };let i = index;">
                                    <td>
                                        <div *ngIf="data.user_name">{{data.user_name.first_name}}
                                            {{data.user_name.last_name}}</div>
                                    </td>
                                    <td>
                                        {{data.akcessId}}
                                    </td>
                                    <td>
                                        {{data.createdAt | date :'mediumDate'}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="4">
                                        <div class="table-page" *ngIf="totalCount > 5">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event,'akcessid')"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value,'akcessid')"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
                <tab heading="Email Address" (selectTab)="onSelect('email')">
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="sendList?.length == 0">
                                <tr>
                                    <td colspan="3" class="text-center">No sent Found (Email)</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="sendList?.length > 0">
                                <tr *ngFor="let data of sendList | paginate
                                : {
                                    itemsPerPage: pageSize,
                                    currentPage: currentPage,
                                    totalItems: totalCount
                                  };let i = index;">
                                    <td>
                                        {{data.email}}
                                    </td>
                                    <td>
                                        {{data.createdAt | date: 'mediumDate'}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="4">
                                        <div class="table-page" *ngIf="totalCount > 5">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event,'email')"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value,'email')"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
                <tab heading="Mobile Number" (selectTab)="onSelect('phone')">
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>Mobile</th>
                                    <th>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="sendList?.length == 0">
                                <tr>
                                    <td colspan="3" class="text-center">No sent Found (Mobile)</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="sendList?.length > 0">
                                <tr *ngFor="let data of sendList | paginate
                                : {
                                    itemsPerPage: pageSize,
                                    currentPage: currentPage,
                                    totalItems: totalCount
                                  };let i = index;">
                                    <td>
                                        {{data.countryCode}}{{data.mobile}}
                                    </td>
                                    <td>
                                        {{data.createdAt | date: 'mediumDate'}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="4">
                                        <div class="table-page" *ngIf="totalCount > 5">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event,'phone')"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value,'phone')"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-danger" id="close"
            (click)="closeModal()">Close</button>
    </div>
</div>