<div class="idcards-page">
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">
                <i class="fal fa-id-card mr-1"></i>ID Cards
            </h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0)">Home</a>
                </li>
                <li class="breadcrumb-item active">ID Cards</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col card-no-border">
                    <div class="card box-border-round">
                        <div class="card-body">
                            <div class="card-title">
                                <div class="row align-items-center">
                                    <div
                                        class="col-12 col-md-6 col-lg-3 col-xl-3"
                                    >
                                        <div class="input-group">
                                            <select
                                                name="short_filter"
                                                class="form-control"
                                            >
                                                <option value="">
                                                    Filter By
                                                </option>
                                                <option value="signature">
                                                    Signature
                                                </option>
                                                <option value="facematch">
                                                    Face match
                                                </option>
                                                <option value="notification">
                                                    Notification
                                                </option>
                                                <option value="pulldata">
                                                    Pull data
                                                </option>
                                                <option value="verification">
                                                    Verification
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div
                                        class="col-12 col-md-6 col-lg-3 col-xl-3"
                                    >
                                        <div class="input-group search-box">
                                            <input
                                                type="text"
                                                [(ngModel)]="search_idcard"
                                                class="form-control"
                                                id="search"
                                                placeholder="Search ID Cards"
                                                (change)="
                                                    searchIdCards(
                                                        $event.target.value
                                                    )
                                                "
                                            />
                                            <i
                                                class="fal fa-search text-primary"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action-tab">
                                <button
                                    type="button"
                                    class="mx-1 btn"
                                    (click)="deleteRow()"
                                    tooltip="Delete"
                                    container="body"
                                >
                                    <i class="fas fa-trash"></i>
                                </button>
                                <span class="vertical-divider">|</span>
                                <button
                                    type="button"
                                    class="mx-1 btn"
                                    (click)="viewRecycle()"
                                    tooltip="Recycle Bin"
                                    container="body"
                                >
                                    <i class="fal fa-recycle"></i>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table
                                    class="table color-bordered-table info-bordered-table"
                                    mdbTable
                                    #tableEl="mdbTable"
                                    small="true"
                                    hover="true"
                                >
                                    <thead>
                                        <tr>
                                            <th class="py-3" scope="col">
                                                Name
                                            </th>
                                            <th class="py-3" scope="col">
                                                AKcess ID
                                            </th>
                                            <th class="py-3" scope="col">
                                                Email
                                            </th>
                                            <th class="py-3" scope="col">
                                                Phone
                                            </th>
                                            <th class="py-3" scope="col">
                                                Expiry Date
                                            </th>
                                            <th class="py-3" scope="col">
                                                Created Date
                                            </th>
                                            <th
                                                class="py-3 text-center"
                                                scope="col"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="idcarddata.length == 0">
                                        <tr>
                                            <td colspan="7" class="text-center">
                                                No ID Cards Found!
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="idcarddata?.length > 0">
                                        <tr
                                            *ngFor="
                                                let data of idcarddata
                                                    | paginate
                                                        : {
                                                              itemsPerPage:
                                                                  pageSize,
                                                              currentPage:
                                                                  currentPage,
                                                              totalItems:
                                                                  totalCount
                                                          };
                                                let i = index
                                            "
                                        >
                                            <th scope="row">
                                                <a href="#">
                                                    {{ data.firstName }}
                                                    {{ data.lastName }}
                                                </a>
                                            </th>
                                            <td>
                                                {{ data.idNo | uppercase }}
                                            </td>
                                            <td>
                                                {{ data.email }}
                                            </td>
                                            <td>
                                                {{ data.phone }}
                                            </td>
                                            <td>
                                                {{
                                                    data.expiryDate
                                                        | date : "longDate"
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    data.createdAt
                                                        | date : "medium"
                                                }}
                                            </td>
                                            <td>
                                                <a
                                                    href="{{
                                                        getFullURL(
                                                            data.transaction[0]
                                                        )
                                                    }}"
                                                    target="_blank"
                                                    title="View ID Card"
                                                    class="link-only-icon"
                                                    data-original-title="View ID Card"
                                                    ><i
                                                        class="fa fa-eye"
                                                        aria-hidden="true"
                                                    ></i
                                                ></a>
                                                <a
                                                    [routerLink]="[
                                                        '/panel/idcards/' +
                                                            data._id +
                                                            '/edit'
                                                    ]"
                                                    data-toggle="tooltip"
                                                    title="Edit ID Card"
                                                    class="link-only-icon"
                                                    data-original-title="Edit ID Card"
                                                    ><i
                                                        class="fa fa-edit green-txt"
                                                        aria-hidden="true"
                                                    ></i
                                                ></a>
                                                <button
                                                    (click)="deleteIdCard(data)"
                                                    title="Remove ID Card"
                                                    class="btn btn-link delete_btn link-only-icon"
                                                >
                                                    <i
                                                        class="fa fa-trash red-txt"
                                                        aria-hidden="true"
                                                    ></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tfoot class="w-100">
                                        <tr>
                                            <td colspan="7">
                                                <div
                                                    class="table-page"
                                                    *ngIf="
                                                        idcarddata?.length > 0
                                                    "
                                                >
                                                    <div class="page-controls">
                                                        <pagination-controls
                                                            (pageChange)="
                                                                onProjectPageChange(
                                                                    $event
                                                                )
                                                            "
                                                            class="my-pagination"
                                                            directionLinks="true"
                                                            previousLabel="Previous"
                                                            nextLabel="Next"
                                                        ></pagination-controls>
                                                    </div>
                                                    <div class="page-size">
                                                        <label class="mb-0 mr-2"
                                                            >Item per
                                                            page</label
                                                        >
                                                        <select
                                                            #val
                                                            (change)="
                                                                changePage(
                                                                    val.value
                                                                )
                                                            "
                                                            class="form-control form-control-sm"
                                                            id="pageSelector"
                                                        >
                                                            <option
                                                                *ngFor="
                                                                    let pG of numberOfPage
                                                                "
                                                                value="{{ pG }}"
                                                            >
                                                                {{ pG }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
