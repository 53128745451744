<div class="form-group" id="addform" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">You are not Registered in AtulBank. Please register with mobile app. {{akcessId}}</h5>
                
            </div>
            <!-- <form (ngSubmit)="adduser()" [formGroup]="adduserform" class="login-container">
                <input **ngIf="act=='add'" type="hidden" formControlName="_id">
                <div class="modal-body">

                    <div class="form-group">
                        <label for="firstName">First Name</label>
                        <div class="row">
                            <div class="col-md-10">
                                <input class="form-control" formControlName="firstName" placeholder="Enter first name" type="text" required>
                                <div *ngIf="isSubmitted && formControls.firstName.errors" class="alert-danger">
                                    <div *ngIf="formControls.firstName.errors.required">First Name is required</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="lastName">Last Name</label>
                        <div class="row">
                            <div class="col-md-10">
                                <input class="form-control" formControlName="lastName" placeholder="Enter last name" type="text">
                                <div *ngIf="isSubmitted && formControls.lastName.errors" class="alert-danger">
                                    <div *ngIf="formControls.lastName.errors.required">Last Name is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone</label>
                        <div class="row">
                            <div class="col-md-10">
                                <input class="form-control" formControlName="phone" placeholder="Enter phone number" type="text">
                                <div *ngIf="isSubmitted && formControls.phone.errors?.required" class="alert-danger">
                                    <div *ngIf="formControls.phone.errors.required">Phone number is required</div>
                                </div>
                                <div *ngIf="isSubmitted && formControls.phone.errors?.pattern" class="alert-danger">
                                    <div *ngIf="formControls.phone.errors?.pattern">Phone number is not valid</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <div class="row">
                            <div class="col-md-10">
                                <input class="form-control" formControlName="email" placeholder="Enter email" type="text">
                                <div *ngIf="isSubmitted && formControls.email.errors?.required" class="alert-danger">
                                    <div *ngIf="formControls.email.errors?.required">Email is required</div>
                                </div>
                                <div *ngIf="isSubmitted && formControls.email.errors?.email" class="alert-danger">
                                    <div *ngIf="formControls.email.errors?.email">Email is invalid</div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Address</label>
                        <div class="row">
                            <div class="col-md-10">
                                <input class="form-control" formControlName="address" placeholder="Enter address" type="text">
                                <div *ngIf="isSubmitted && formControls.address.errors?.required" class="alert-danger">
                                    <div *ngIf="formControls.address.errors?.required">Address is required</div>
                                </div>


                            </div>

                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" (click)="resetform()" class="btn btn-secondary" id="addformclose" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save</button>
                </div>
            </form> -->
        </div>
    </div>
</div>