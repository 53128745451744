<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewuserModalLabel">View Id Card</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <input type="hidden" [(ngModel)]="filePath" value="{{filePath}}" />
        <pdf-viewer [src]="fileUrl" [render-text]="false" [original-size]="true" [fit-to-page]="true"
                    style="display: block;"></pdf-viewer>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-danger" id="close" (click)="closeModal()">
            Close
        </button>
    </div>
</div>