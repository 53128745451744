import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { DataservicesService } from "../../_services/dataservices.service";
import { RefreshContentService } from "../../_services/refresh-content.service";
import { LocalDataService } from "src/app/_services/local-data.service";
import { CommonService } from "src/app/_services/common.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
    portalUser;
    show_notifier: boolean = false;
    notifications = [];
    listCompany = [];
    domainName: any = "";
    idDomainName = false;
    // companyDetail;
    location: Location;
    selectedCompany: any = null;
    selectedCompanyLogoImgLarge: any = "";

    companySelect: any = null;
    //sidebarReload: boolean = false;
    currentCompany: any = {};
    constructor(
        private calldata: DataservicesService,
        private refreshContentService: RefreshContentService,
        private router: Router,
        private localDataService: LocalDataService,
        location: Location,
        public commonService: CommonService,
        private spinner: NgxSpinnerService
    ) {
        this.location = location;
    }

    ngOnInit() {
        this.portalUserPermissions();
        this.getcompanyList();
        if (this.commonService.selectedCompanyDeatils) {
            this.selectedCompany = this.commonService.selectedCompanyDeatils;
            this.domainName = this.commonService.selectedCompanyDeatils.domain;
            this.idDomainName = true;
        }
        if (sessionStorage.getItem("selectedCompanyName")) {
            this.companySelect = sessionStorage.getItem("selectedCompanyName");
        }

        this.currentCompany = this.commonService.selectedCompanyDeatils;

        //if (!localStorage.getItem('sidebarReload')) {
        //    localStorage.setItem('sidebarReload', 'true')
        //    location.reload()
        //} else {
        //    localStorage.removeItem('sidebarReload')
        //}
    }

    refresh(): void {
        window.location.reload();
    }

    getcompanyList() {
        this.listCompany = [];
        let data = {
            akcessId: sessionStorage.getItem("logedInUserAckessId"),
        };

        this.calldata.listactivecompany(data).subscribe(
            (res: any) => {
                this.listCompany = res.data;
                // if(this.listCompany.length!=0){
                // sessionStorage.setItem('domainName',JSON.stringify(this.listCompany[0].domain))
                // }
            },
            (error) => {}
        );
    }

    onSelectChange(companyDetail) {
        let currentCompanyName = sessionStorage.getItem("selectedCompanyName");
        if (companyDetail) {
            this.domainName = companyDetail.domain;
            this.selectedCompany = companyDetail;
            this.currentCompany = this.selectedCompany;
            const titlee = this.router.url.split("/panel/");
            sessionStorage.setItem("selectedCompanyName", companyDetail.name);
            this.companySelect = sessionStorage.getItem("selectedCompanyName");

            sessionStorage.setItem(
                "selectedCompanyDetails",
                JSON.stringify(companyDetail)
            );
            sessionStorage.setItem(
                "selectedCompanyLogoImgLarge",
                companyDetail.logo
            );
            this.idDomainName = true;
            this.refreshContentService.notiFyToNavbar({
                option: "Refresh-Title",
                title: titlee[1],
            });
            this.refreshContentService.notiFyToAllChildComponents({
                option: "Refresh-Data",
                selected_company: this.selectedCompany,
            });

            //To refresh the Panel when different company is selected
            if (currentCompanyName != companyDetail) {
                //this.router.navigate(['/panel/dashboard']);
                //    location.reload();
                this.router
                    .navigateByUrl("/", { skipLocationChange: true })
                    .then(() => {
                        this.router.navigate(["/panel/dashboard"]);
                    });
            }
        } else if (companyDetail === undefined) {
            const titlee = this.router.url.split("/panel/");
            sessionStorage.removeItem("selectedCompanyDetails");
            sessionStorage.removeItem("selectedCompanyName");
            sessionStorage.removeItem("selectedCompanyLogoImgLarge");
            //sessionStorage.removeItem("selectedCompanyName");
            this.idDomainName = false;
            this.domainName = "";
            this.companySelect = null;
            this.refreshContentService.notiFyToNavbar({
                option: "Refresh-Title",
                title: titlee[1],
            });
            this.refreshContentService.notiFyToAllChildComponents({
                option: "Refresh-Data",
                selected_company: null,
            });
            this.router.navigate(["/panel/company"]);
        }
    }

    portalUserPermissions() {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        // this.getNotifications(null);
    }

    // getNotifications(status: string) {
    //   this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    //   const tmpdata = {
    //     akcessId: this.portalUser.akcessId,
    //     usertype: this.portalUser.usertype,
    //   };

    //   if (status && status.length) tmpdata["status"] = status;

    //   this.calldata.fetchNotifications(tmpdata).subscribe(
    //     (data) => {
    //       if (data["status"] === 1) {
    //         this.notifications = [];
    //         this.notifications = data["data"];
    //         this.notifications.forEach(element => {
    //           if (element['is_notification_new'] == "true") {
    //             this.show_notifier = true;
    //             return;
    //           }

    //         });
    //       } else {
    //       }
    //     },
    //     (error) => {
    //     }
    //   );
    // }

    navigate(pageTitle) {
        // this.refreshContentService.notiFyToAllChildComponents({
        //   option: "Refresh-Data",
        //   selected_company:this.selectedCompany,
        //   title:pageTitle
        // });
        this.refreshContentService.notiFyToNavbar({
            option: "Refresh-Title",
            title: pageTitle,
        });
    }
}
