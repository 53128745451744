<!-- Add eForm Modal -->
<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="addeFormModalLabel">Create eForm</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label class="font-500">eForm Name</label>
            <input type="text" class="form-control" [(ngModel)]="txteforms" placeholder="Enter eForm Name">
        </div>
        <div class="form-group">
            <label class="font-500">Description</label>
            <textarea class="form-control" [(ngModel)]="txtdescriptions" placeholder="Enter Description" rows="2"></textarea>
            <div class="valid-feedback mt-0">What you type here will appear to the user in the application.</div>
        </div>
        <div class="form-group">
            <label class="font-500">Instructions</label>
            <textarea class="form-control" [(ngModel)]="txtinstructions" placeholder="Enter Instructions" rows="2"></textarea>
            <div class="valid-feedback mt-0">Only this portal users can see the instruction of this eForm. </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-info" (click)="addGeneratedEform()">Save</button>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">
            Close
        </button>
    </div>
</div>