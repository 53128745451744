<!-- Eform send through email -->
<div class="modal-content send-eform-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="emailSendEformModalLabel">Share Asset</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <input type="hidden" [(ngModel)]="tmpasset" value="{{ tmpasset }}" />
        <div class="row justify-content-around pb-2">
            <div class="custom-radio">
                <!--<input type="radio" [(ngModel)]="phone_or_email" value="user" id="user"
                       class="custom-control-input" mdbInput>
                <label for="user" class="custom-control-label"><b>AKcess Id</b></label>-->

                <input
                    type="radio"
                    [(ngModel)]="phone_or_email"
                    value="user"
                    id="user"
                    class="with-gap radio-col-light-blue"
                />
                <label for="user" class="font-500">AKcess Id</label>
            </div>
            <div class="custom-radio">
                <!--<input type="radio" [(ngModel)]="phone_or_email" value="email" id="email"
                       class="custom-control-input" mdbInput>
                <label for="email" class="custom-control-label"><b>Email</b></label>-->

                <input
                    type="radio"
                    [(ngModel)]="phone_or_email"
                    value="email"
                    id="email"
                    class="with-gap radio-col-light-blue"
                />
                <label for="email" class="font-500">Email</label>
            </div>
            <div class="custom-radio">
                <!--<input type="radio" [(ngModel)]="phone_or_email" value="phone" id="phone"
                       class="custom-control-input" mdbInput>
                <label for="phone" class="custom-control-label"><b>Phone</b></label>-->

                <input
                    type="radio"
                    [(ngModel)]="phone_or_email"
                    value="phone"
                    id="phone"
                    class="with-gap radio-col-light-blue"
                />
                <label for="phone" class="font-500">Mobile</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="phone_or_email == 'user'">
                <ng-select
                    [items]="userDataAKcessId"
                    [addTag]="addTagFn"
                    [hideSelected]="true"
                    multiple="true"
                    bindLabel="name"
                    placeholder="Enter User AKcess Id"
                    [(ngModel)]="akcessIDChips"
                    appendTo="body"
                    class="akcess-id-dropdown"
                    (open)="onOpenDropdown()"
                    (close)="onCloseDropdown()"
                >
                    <ng-template ng-header-tmp>
                        <input
                            type="checkbox"
                            id="selectAllAkcessId"
                            name="selectAllAkcessId"
                            [(ngModel)]="isSelectAllAkcessId"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllAkcessId()"
                        />
                        <label class="mr-2" for="selectAllAkcessId"
                            >Select All</label
                        >
                        <input
                            type="checkbox"
                            id="selectAllClientsAkcessId"
                            name="selectAllClientsAkcessId"
                            [(ngModel)]="isSelectAllClientAkcessId"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllClientAkcessId()"
                        />
                        <label for="selectAllClientsAkcessId"
                            >Select All Client</label
                        >
                        <input
                            type="checkbox"
                            id="selectAllAdminsAkcessId"
                            name="selectAllAdminsAkcessId"
                            [(ngModel)]="isSelectAllAdminAkcessId"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllAdminAkcessId()"
                        />
                        <label for="selectAllAdminsAkcessId"
                            >Select All Admins</label
                        >
                    </ng-template>
                </ng-select>
                <div class="valid-feedback">
                    Type AKcessID and press ENTER to validate
                </div>
            </div>
            <div class="col-12" *ngIf="phone_or_email == 'email'">
                <ng-select
                    [items]="userDataEmail"
                    [addTag]="addTagEmailFn"
                    [hideSelected]="true"
                    multiple="true"
                    bindLabel="name"
                    placeholder="Enter User Email"
                    [(ngModel)]="userwithChips"
                    appendTo="body"
                    (open)="onOpenDropdown()"
                    (close)="onCloseDropdown()"
                >
                    <ng-template ng-header-tmp>
                        <input
                            type="checkbox"
                            id="selectAllEmails"
                            name="selectAllEmails"
                            [(ngModel)]="isSelectAllEmails"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllEmails()"
                        />
                        <label class="mr-2" for="selectAllEmails"
                            >Select All</label
                        >
                        <input
                            type="checkbox"
                            id="selectAllClientsEmails"
                            name="selectAllClientsEmails"
                            [(ngModel)]="isSelectAllClientEmails"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllClientEmails()"
                        />
                        <label for="selectAllClientsEmails"
                            >Select All Client</label
                        >
                        <input
                            type="checkbox"
                            id="selectAllAdminsEmails"
                            name="selectAllAdminsEmails"
                            [(ngModel)]="isSelectAllAdminEmails"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllAdminEmails()"
                        />
                        <label for="selectAllAdminsEmails"
                            >Select All Admins</label
                        >
                    </ng-template>
                </ng-select>
                <div class="valid-feedback">
                    Type the email address and press ENTER
                </div>
            </div>
            <div class="col-12" *ngIf="phone_or_email == 'phone'">
                <ng-select
                    [items]="userDataPhone"
                    [addTag]="addTagPhoneFn"
                    [hideSelected]="true"
                    multiple="true"
                    bindLabel="name"
                    placeholder="Select/Enter Mobile Number (ex. +123 45678912)"
                    [(ngModel)]="userwithChips_phone"
                    appendTo="body"
                    (open)="onOpenDropdown()"
                    (close)="onCloseDropdown()"
                >
                    <ng-template ng-header-tmp>
                        <input
                            type="checkbox"
                            id="selectAllPhone"
                            name="selectAllPhone"
                            [(ngModel)]="isSelectAllPhone"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllPhone()"
                        />
                        <label class="mr-2" for="selectAllPhone"
                            >Select All</label
                        >
                        <input
                            type="checkbox"
                            id="selectAllClientsPhone"
                            name="selectAllClientsPhone"
                            [(ngModel)]="isSelectAllClientPhone"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllClientPhone()"
                        />
                        <label for="selectAllClientsPhone"
                            >Select All Client</label
                        >
                        <input
                            type="checkbox"
                            id="selectAllAdminsPhone"
                            name="selectAllAdminsPhone"
                            [(ngModel)]="isSelectAllAdminPhone"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="selectAllAdminPhone()"
                        />
                        <label for="selectAllAdminsPhone"
                            >Select All Admins</label
                        >
                    </ng-template>
                </ng-select>
                <div class="valid-feedback">
                    Type the phone number with the country code and press ENTER
                    (ex. +123 456789)
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            *ngIf="phone_or_email == 'email'"
            type="button"
            class="btn waves-effect waves-light btn-info"
            [disabled]="!userwithChips.length"
            (click)="sendAssetEmail()"
        >
            Send to Mail
        </button>
        <button
            *ngIf="phone_or_email == 'phone'"
            type="button"
            class="btn waves-effect waves-light btn-info"
            [disabled]="!userwithChips_phone.length"
            (click)="sendAssetPhone()"
        >
            Send to Mobile
        </button>
        <button
            *ngIf="phone_or_email == 'user'"
            type="button"
            class="btn waves-effect waves-light btn-info"
            [disabled]="!akcessIDChips.length"
            (click)="sendAssetAKcessId()"
        >
            Send
        </button>
        <button
            *ngIf="phone_or_email == 'email' || phone_or_email == 'phone'"
            type="button"
            class="btn waves-effect waves-light btn-danger"
            (click)="closeModal()"
        >
            Close
        </button>
        <button
            *ngIf="phone_or_email == 'user'"
            type="button"
            class="btn waves-effect waves-light btn-danger"
            (click)="akcessIDChips = []; closeModal()"
        >
            Close
        </button>
    </div>
</div>
