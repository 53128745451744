import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-qrcode',
  templateUrl: './document-qrcode.component.html',
  styleUrls: ['./document-qrcode.component.css']
})
export class DocumentQrcodeComponent implements OnInit {

  documentId: any
  documentData: any
  documentName: any
  qrcodeData: any;
  dbname: any

  constructor(private route: ActivatedRoute, private _ds: DataservicesService) {
    this.documentId = this.route.snapshot.paramMap.get('documentid');
    this.documentName = this.route.snapshot.paramMap.get('documentname')
    this.dbname = this.route.snapshot.paramMap.get('dbname')
  }

  ngOnInit(): void {
    this.qrcodeData = environment.url + 'document/' + this.documentId + "/" + this.dbname
  }

}
