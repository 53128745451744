<div class="property-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/property']">Property</a>
                </li>
                <li class="breadcrumb-item active">Enquiry</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section id="printarea">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card property-card noprint" style="height: 96%">
                        <div class="card-body enquiry-details">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h3>Enquiry Details</h3>

                                    <div class="row mb-4">
                                        <div class="col-sm-3 mb-3">
                                            <label>Full Name: </label>
                                            <div>
                                                {{ inquiry.firstName }}
                                                {{ inquiry.lastName }}
                                            </div>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label>Enquiry Date: </label>
                                            <div>
                                                {{
                                                    inquiry.createdAt
                                                        | date : "MMMM d, y"
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label>Email Address: </label>
                                            <div>{{ inquiry.email }}</div>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label>Mobile: </label>
                                            <div>{{ inquiry.mobile }}</div>
                                        </div>
                                        <div class="col-sm-12 mb-3">
                                            <label>Notes: </label>
                                            <div class="text-justify">
                                                {{ inquiry.notes }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <hr />
                                </div>
                                <div class="col-sm-12">
                                    <h3>Property Details</h3>
                                    <div class="row">
                                        <div class="col-sm-3 mb-3">
                                            <img
                                                *ngIf="inquiry.property?.photo"
                                                src="{{
                                                    getFullURL(
                                                        inquiry.property?.photo
                                                    )
                                                }}"
                                                class="img-fluid img-rounded"
                                                alt="{{
                                                    inquiry.property.name
                                                }}"
                                                title="{{
                                                    inquiry.property.name
                                                }}"
                                                width="300"
                                            />
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label>Property Name: </label>
                                            <div>
                                                {{ inquiry.property.name }}
                                            </div>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label>Price: </label>
                                            <div>
                                                <i
                                                    class="fal fa-pound-sign ml-1"
                                                ></i>
                                                {{
                                                    inquiry.property.price
                                                        | number
                                                            : "1.0"
                                                            : "en-US"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container-fluid mb-4">
        <button class="btn btn-info noprint mr-3" (click)="printHTML()">
            Print HTML
        </button>
        <button class="btn btn-info noprint mr-3" (click)="printQRCode()">
            Save as PDF
        </button>
    </div>
</div>
<!-- /Page Wrapper -->
