import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../shared/services/api/api.service';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

declare var html2pdf: any

@Component({
  selector: 'app-merchant-qrcode',
  templateUrl: './merchant-qrcode.component.html',
  styleUrls: ['./merchant-qrcode.component.css']
})
export class MerchantQrcodeComponent implements OnInit {

  url: any = "string";
  data: any;

  constructor(private route: ActivatedRoute, private _as: ApiService, private spinner: NgxSpinnerService, private toastr: ToastrService) {

  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  ngOnInit(): void {
    this.loadScript('../../../assets/js/html2pdf.bundle.min.js')
    this.route.params.subscribe(param => {
      this.spinner.show()
      this._as.getSlugForQrCode(param['id']).subscribe((res: any) => {
        if (res.success) {
          this.data = res.slug
          this.url = environment.imgurl + 'loc/' + res.slug.slug
          this.spinner.hide()
        } else {
          this.toastr.error('something went wrong')
          this.spinner.hide()
        }
      }, (err) => {
        this.spinner.hide();
        // this.toastr.error("Oops, something went wrong. Please try again.")
      })
    });
  }

  print() {
    var element = document.getElementById('pdfGenerate');
    var opt = {
      margin: 0.5,
      filename: 'location_qr.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      pagebreak: { avoid: 'tr' },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(opt).save()
    this.toastr.success('Preparing PDF, your download will begin shortly')
  }

}
