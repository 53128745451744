<!-- Delete User Modal -->
<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewuserModalLabel">Delete User</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h4>Are you sure you want to delete selected user?</h4>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn waves-effect waves-light btn-info" (click)="deleteUser(userSendData,companyID)">
            Yes
        </button>
        <button type="button" class="btn waves-effect waves-light btn-danger" id="close" (click)="closeModal()">
            Close
        </button>
    </div>
</div>