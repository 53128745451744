import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from 'src/app/_services/dataservices.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.css']
})
export class CreateFolderComponent implements OnInit {

  folderList: any
  createFolderForm: FormGroup;
  btnText: any = "Create";

  constructor(public _bsModalRef: BsModalRef,
    public dataService: DataservicesService,
    private spinner: NgxSpinnerService,
    public toast: ToastrService,
    public fb: FormBuilder) { }


  ngOnInit(): void {
    this.createFolderForm = this.fb.group({
      folderName: ["", Validators.required]
    })
    if (this.folderList != undefined) {
      this.setData();
      this.btnText = "Update";
    } else {
      this.btnText = "Create";
    }
  }

  closeModal() {
    this._bsModalRef.hide();
  }

  submit() {
    this.spinner.show()
    let data: any = {
      name: this.createFolderForm.controls.folderName.value
    }
    if (this.btnText == "Create") {
      this.dataService.createDocumentFolder(data).subscribe((res: any) => {
        if (res.status) {
          this.toast.success(res.message);
          this.spinner.hide();
          this.closeModal();
        } else {
          this.spinner.hide();
          this.toast.error(res.message);
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      })
    } else if (this.btnText == "Update") {
      this.dataService.updateDocumentFolder(data, this.folderList._id).subscribe((res: any) => {
        if (res.status) {
          this.toast.success(res.message);
          this.spinner.hide();
          this.closeModal();
        } else {
          this.spinner.hide();
          this.toast.error(res.message);
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
    })
    }

  }

  setData() {
    this.createFolderForm.controls.folderName.setValue(this.folderList.name)
  }
}
