import { Injectable } from '@angular/core';
import { Config } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class ApiListService {

  constructor() { }

  getToken:any={
    apiName:"auth/generate-token",
    method:"post",
    showLoader:true,
    header:true
  }

  listOfCompany:any={
    apiName:"list-company",
    method:"post",
    showLoader:true,
    header:false
  }

  listCompanyByDomain:any={
    apiName:"list-company-by-domain",
    method:"post",
    showLoader:true,
    header:false
  }

  listactivecompany:any={
    apiName:"list-active-company",
    method:"post",
    showLoader:true,
    header:false
  }

  addCompany:any={
    apiName:"add-company",
    method:"post",
    showLoader:true,
    header:false
  }

  updateCompany:any={
    apiName:"company/update/",
    method:"post",
    showLoader:true,
    header:false
  }
  getuserlistWithSearch:any={
    apiName:"getuserlist_search",
    method:"post",
    showLoader:true,
    header:false
  }

  fetchoneform:any={
    apiName:"fetchoneform",
    method:"post",
    showLoader:true,
    header:false
  }

  getGlobalprofileField:any={
    apiName:"v5/getGlobalprofileField",
    method:"post",
    showLoader:true,
    header:false
  }

  getemployeeList:any={
    apiName:"getemployeeList",
    method:"post",
    showLoader:true,
    header:false
  }

  addEmployee:any={
    apiName:"addEmployee",
    method:"post",
    showLoader:true,
    header:false
  }

  editEmployee:any={
    apiName:"editEmployee",
    method:"post",
    showLoader:true,
    header:false
  }
  
  deleteEmployee:any={
    apiName:"deleteEmployee",
    method:"post",
    showLoader:true,
    header:false
  }
  
  sendinvitationbysms:any={
    apiName:"sendinvitationbysms",
    method:"post",
    showLoader:true,
    header:false
  }
  
}
