import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/_services/common.service';
import { ConstantService } from 'src/app/_services/constant.service';
import { DataservicesService } from 'src/app/_services/dataservices.service';

@Component({
  selector: 'app-view-invitation-list',
  templateUrl: './view-invitation-list.component.html',
  styleUrls: ['./view-invitation-list.component.css']
})
export class ViewInvitationListComponent implements OnInit {

  constructor(public modalService: BsModalService,
    public toast: ToastrService,
    public spinner: NgxSpinnerService,
    public constantService: ConstantService,
    public commonService: CommonService,
    public http: HttpClient,
    public dataService: DataservicesService) { }

  ngOnInit(): void {
    if (sessionStorage.getItem("selectedCompanyLogoImgLarge")) {
      this.companyLogo = sessionStorage.getItem("selectedCompanyLogoImgLarge");
    } else {
      this.http.get('/assets/default-logo.json').subscribe((res: any) => {
        this.companyLogo = res.logo;
      })
    }
    this.getStaffViewSent();
  }

  data: any = [];
  pageSize: any = 5;
  currentPage: any = 1;
  totalCount: any = 0;
  numberOfPage: Array<Number> = [5, 10, 15, 30, 40, 50];
  userType: any = "";

  closeModal() {
    this.modalService.hide();
  }

  getStaffViewSent() {
    this.spinner.show();
    let data = {
      currentPage: this.currentPage,
      totalData: this.pageSize,
      usertype: this.userType.toLowerCase()
    }

    this.dataService.getStaffInvitationSentList(data).subscribe((res: any) => {
      if (res.status) {
        this.data = res.data.list;
        this.totalCount = res.data.total;
        this.spinner.hide();
      } else {
        this.toast.error(res.message)
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    })
  }

  onProjectPageChange(event, type) {
    this.currentPage = event;
    if (type == 'send invitation') {
      this.getStaffViewSent();
    } else {
      this.getUserList();
    }
  }

  changePage(value, type) {
    this.currentPage = 1;
    this.pageSize = parseInt(value);
    if (type == 'invitation') {
      this.getStaffViewSent();
    } else {
      this.getUserList();
    }
  }

  userlist: any[] = [];
  companyLogo: any = "";

  resendInvitation(val) {
    this.spinner.show();
    let data = this.constantService.setData();
    let user: any = JSON.parse(sessionStorage.getItem("portalUser"));

    data.akcessId = val.akcessId;
    data.description = "Get data from AKcess id for " + val.usertype + 's';
    data.logo = this.companyLogo
    data.requestId = val._id;
    data.request_user = user.firstName + " " + user.lastName + " (" + user.akcessId + ")";
    data.request_type = val.usertype.toLocaleLowerCase() == "employee" ? "employee" : "client";
    data.type = val.usertype.toLocaleLowerCase() == "employee" ? "employee" : "client";
    data.domain_name = "sme.akcess.app";
    data.db_name = this.commonService.selectedCompanyDeatils.dbName;
    // data.requestId = val.data;
    this.dataService.getUserInformation(data).subscribe((res: any) => {
      if (res.status) {
        this.spinner.hide();
        this.toast.success(res.message);
        this.modalService.hide();
      } else {
        this.spinner.hide();
        this.toast.error(res.message);
        this.modalService.hide();
      }
    }, (err) => {
      this.toast.error("Oops, something went wrong. Please try again.");
      this.spinner.hide();
      this.modalService.hide();
    })
  }

  getUserList() {
    this.spinner.show();
    let data = {
      limit: this.pageSize,
      page: this.currentPage,
      search: '',
      usertype: this.userType == "Staff" ? "Employee" : "Client"
    }
    this.dataService.getSendInviteUsersData(data).subscribe(
      (res: any) => {
        this.userlist = res["data"]["docs"];
        this.totalCount = res.data.totalDocs;
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      })
  }

  onSelect(value) {
    this.pageSize = 5;
    this.currentPage = 1;
    this.totalCount = 0;
    this.numberOfPage = [5, 10, 15, 30, 40, 50];
    if (value == "invitationData") {
      this.getStaffViewSent();
    } else {
      this.getUserList();
    }
  }
}
