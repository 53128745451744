import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
//import * as _ from 'lodash';
import * as _ from "lodash-es";

@Component({
    selector: "app-view-staff-list-modal",
    templateUrl: "./view-staff-list-modal.component.html",
    styleUrls: ["./view-staff-list-modal.component.css"],
})
export class ViewStaffListModalComponent implements OnInit {
    locationId: string;
    onlyAllowAdmin: boolean;
    staffList: any = [];
    portalUser;
    allUsers: any = [];
    currentPage: number = 1;
    isMasterSel: Boolean;
    checkedStaffList: any = [];

    constructor(
        private _bsModalRef: BsModalRef,
        private spinner: NgxSpinnerService,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        console.log(this.onlyAllowAdmin);
        this.getStaffList();
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    getExistingStaffIds() {
        if (this.staffList) {
            return this.staffList.map((staff) => staff.akcessId);
        }
    }

    getStaffList() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));

        const tmpdata = {
            excludes: this.getExistingStaffIds(),
            onlyAllowAdmin: this.onlyAllowAdmin,
        };

        this.calldata.getUserAdminList(tmpdata).subscribe(
            (res: any) => {
                if (res.status) {
                    this.allUsers = res.data;
                    this.allUsers.forEach((el) => {
                        el["isSelected"] = false;
                    });
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    checkUncheckAll() {
        for (var i = 0; i < this.allUsers.length; i++) {
            this.allUsers[i].isSelected = this.isMasterSel;
        }
    }

    isAllSelected() {
        this.isMasterSel = this.allUsers.every(function (item: any) {
            return item.isSelected == true;
        });
    }

    getCheckedItemList() {
        this.checkedStaffList = [];
        for (var i = 0; i < this.allUsers.length; i++) {
            if (this.allUsers[i].isSelected)
                this.checkedStaffList.push(this.allUsers[i]);
        }

        return this.checkedStaffList;
    }

    addRemoveLocationStaff() {
        let staffs = this.getCheckedItemList();

        if (staffs.length > 0) {
            this.spinner.show();
            this.calldata
                .addRemoveLocationStaff({ staff: staffs }, this.locationId)
                .subscribe(
                    (res: any) => {
                        this.spinner.hide();
                        if (res.status) {
                            this.spinner.hide();
                            this.toast.success(res.message);
                            this.router
                                .navigateByUrl("/", {
                                    skipLocationChange: true,
                                })
                                .then(() =>
                                    this.router.navigate([
                                        "/panel/location/" +
                                            this.locationId +
                                            "/manageStaff",
                                    ])
                                );
                        } else {
                            this.toast.info(res.message);
                        }
                        this.closeModal();
                    },
                    (err) => {
                        this.spinner.hide();
                        this.toast.error(
                            "Oops, something went wrong. Please try again."
                        );
                    }
                );
        }
    }
}
