import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate  {
  
/**
 * @constructor 
 * @param router Router instance
 *  
 */
    constructor(public router:Router){}

/**
 * @Function canActivate
 * @param route ActivatedRouteSnapshot instance
 * 
 */
    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
            return this.isUserLoggedIn()
          .then(result=>{
            this.router.navigate(['/panel/company']);
            
            
           
          }).catch(error=>{
            return error;
          })
    }

/**
 * @Function isUserLoggedIn
 * @returns Promise
 */
    isUserLoggedIn():Promise<boolean>{
          return new Promise ((resolve,reject) =>{
              if(sessionStorage.getItem('portalUser')){
                resolve(true);	
              }else
                reject(true);
          });
    }
      
}