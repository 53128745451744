<div class="verification-log-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor text" >Verification Logs</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Verification Logs</li>
            </ol>
        </div>
    </div>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <div class="input-group">
                            <input type="text"
                                   class="form-control"
                                   id="search"
                                   placeholder="Search Logs"
                                   (change)="searchUsers_uc($event.target.value)" />
                            <!--<div class="input-group-append">
                                <div class="input-group-text" (click)="getCertificateList()">
                                    <i class="fas fa-search"></i>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table table-hover table-sm" mdbTable #tableEl="mdbTable">
                        <thead>
                            <tr>
                                <th>AkcessId</th>
                                <th>API</th>
                                <th>Date</th>
                                <th>Body</th>
                                <th>Output</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="verify_logs?.length == 0">
                            <tr>
                                <td colspan="5" class="text-center">No Log Records Found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="verify_logs?.length > 0">
                            <tr *ngFor="let data of verify_logs | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount };let i = index;">
                                <td>
                                    {{data?.akcessId}}
                                </td>
                                <td>
                                    {{data?.apiName}}
                                </td>
                                <td>
                                    {{dislayDate(data?.createdDate)}}
                                </td>
                                <td>
                                    <button type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="showBody(data?.body,'Body')"
                                            data-toggle="modal"
                                            data-target="#viewform">
                                        View Log Body
                                    </button>
                                </td>
                                <td>
                                    <button type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="showBody(data?.output,'Output')"
                                            data-toggle="modal"
                                            data-target="#viewform">
                                        View Log Output
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="5">
                                    <!--<div class="table-page" *ngIf="verify_logs?.length > 10">
                                        <div class="page-controls">
                                            <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                 class="my-pagination"
                                                                 directionLinks="true"
                                                                 previousLabel="Previous"
                                                                 nextLabel="Next"></pagination-controls>
                                        </div>
                                        <div class="page-size">
                                            <label class="mb-0 mr-2">Item per page</label>
                                            <select #val
                                                    (change)="changePage(val.value)"
                                                    class="form-control form-control-sm"
                                                    id="pageSelector">
                                                <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                                    {{ pG }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>-->
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="viewform" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">{{label}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div *ngFor="let item of body_obj | keyvalue">
                    <label class="font-500">{{item.key}} :</label>
                    <p>{{item.value}}</p>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
