import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-invitation-list-view',
  templateUrl: './invitation-list-view.component.html',
  styleUrls: ['./invitation-list-view.component.css']
})
export class InvitationListViewComponent implements OnInit {

  fname;
  lname;
  akid;
  date;
  userType;
  phone;
  email;
  status;
  data :any={};

  constructor(private _bsModalRef : BsModalRef ) { }

  ngOnInit(): void {
  }

  addEditEmployee(e){

  }

  close(){
    this._bsModalRef.hide();
  }

}
