import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { DataservicesService } from 'src/app/_services/dataservices.service';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
  processID: string;
  iframeSrc: string;

  constructor(private route: ActivatedRoute,

    private fb: FormBuilder,
    private calldata: DataservicesService,
  ) {}

  // constructor() { }

  ngOnInit(): void {
    this.fetchProcessID();
  }
  fetchProcessID(): void {
    this.route.queryParams.subscribe(params => {
      this.processID = params['processID'];
      console.log(this.processID); // Use the processID as needed
      this.fetchURL(this.processID);
    });
  }


  fetchURL(processID: string): void {

    console.log('inside processID detail fetch');
    this.calldata.getProcessURL(processID).subscribe( 
        
      (res: any) => {
        console.log('inside processID detail fetch');
        console.log(res.data);
        // this.userDetailss = res;
        // console.log(this.userDetailss);
        if (res.data) {
          this.iframeSrc = res.data;
        }
      },
      error => {
        console.error('Error fetching processID details:', error);
      }
    ); 
  }
}
