<div class="reports-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Reports</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Reports</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">User Report</h4>
                            <!-- (click)="getUserReport(null,''); currentPage = 1; search_userStatus=''; client_search_input='' data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"" -->
                            <p class="card-text">List of all the users with their Status.</p>
                            <button type="button" class="btn waves-effect waves-light btn-info" (click)="openUserReportModal()">Show Report</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Client Reports</h4>
                            <p class="card-text">List of all the clients with their Status.</p>
                            <button type="button" class="btn waves-effect waves-light btn-info" (click)="getClientReport(null,''); currentPage_client = 1;search_userStatus_client=''; user_search_input=''" data-toggle="modal" data-target="#clientModal" data-whatever="@mdo">Show Report</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Updated Customers</h4>
                            <p class="card-text">List of Updated Customers.</p>
                            <button type="button" class="btn waves-effect waves-light btn-info" (click)="getUserReport_uc(null); currentPage_uc = 1" data-toggle="modal" data-target="#ucModal" data-whatever="@mdo">Show Report</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Logs</h4>
                            <p class="card-text">List of Logs.</p>
                            <button type="button" class="btn waves-effect waves-light btn-info" (click)="openModal()">Show Report</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">E-Form Response Report</h4>
                            <p class="card-text">List of E-Form Responses.</p>
                            <button type="button" class="btn waves-effect waves-light btn-info" (click)="openResponseReport()">Show Report</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>




<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">List of User</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="currentPage = 1;search_userStatus=null">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body background-light-blue">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <div class="card box-border-round">
                                <div class="row">
                                    <div class="col-md-2 ml-2">
                                        Filter by:
                                    </div>
                                    <div class="col-md-3 ml">
                                        <select [(ngModel)]="search_userStatus" (change)="this.getUserReport(null,search_userStatus)"
                                                class="browser-default custom-select-input">
                                            <option selected value="">User Status</option>
                                            <option value='active'>Active</option>
                                            <!-- <option value="no">Inactive</option> -->
                                            <option value='deleted'>Deleted</option>

                                        </select>
                                    </div>
                                    <div class="col-md-2 ml-2">
                                        Search:
                                    </div>
                                    <div class="col-md-4 ml">
                                        <form autocomplete="off">
                                            <input id="staticEmail2" [(ngModel)]="client_search_input" type="text"
                                                   (change)="searchClient($event.target.value)" name="client_search_input"
                                                   class="form-control">
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="card box-border-round">
                            <div class="header pb-4 pt-4">
                                <div class="container-fluid">
                                    <div class="header-body">
                                        <a class="float-right btn btn-primary btn-sm ml-2 form-button-action-add  white"
                                           (click)="exportexcel('user_reports')"><i class="fa fa-download"></i> Export</a>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <div class="alert alert-info m-3" *ngIf="!user_reports">
                                    No record Found
                                </div>
                                <table *ngIf="user_reports"
                                       id="user_reports"
                                       class="table align-items-center table-white table-flush table-theme-white_black">
                                    <thead>
                                        <tr>
                                            <th class="py-3" scope="col">Name</th>
                                            <th class="py-3" scope="col">AKcessID</th>
                                            <th class="py-3" scope="col">Status</th>
                                            <th class="py-3" scope="col">Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let data of user_reports | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount, id: 'user_paginate' };let i = index;">
                                            <td>
                                                {{data.firstName}} {{data.lastName}}

                                            </td>
                                            <td>
                                                {{ data.akcessId }}

                                            </td>
                                            <td>
                                                {{(data.removed_flg)?'Deleted':'Active'}}
                                            </td>
                                            <td>
                                                {{dislayDate(data.createdon)}}

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="form-group col-md-8">
                                            <label for="inputState"></label>
                                            <pagination-controls id="user_paginate" (pageChange)="onProjectPageChange($event)" class="my-pagination" directionLinks="true" previousLabel="Previous" nextLabel="Next"></pagination-controls>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputState">Item per page</label>
                                            <select #val (change)="changePage(val.value)" class="form-control">
                                                <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="clientModal" tabindex="-1" role="dialog" aria-labelledby="clientModal" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">List of Client</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="currentPage_client = 1">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body background-light-blue">
                <div class="card mb-0">
                    <div class="card-body">
                        <div class="card-title row align-items-center">
                            <div class="col-12 col-md-6 col-lg-5 col-xl-4 mb-1 mb-lg-0">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="search" name="user_search_input"
                                           [(ngModel)]="user_search_input"
                                           (change)="searchUsers_client($event.target.value)"
                                           placeholder="Search" />
                                    <!--<form autocomplete="off"></form>-->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-5 col-xl-4 offset-xl-1 mb-1 mb-lg-0">
                                <!--<label><b>Filter by eForm Status:</b></label>-->
                                <select class="form-control" [(ngModel)]="search_userStatus_client"
                                        (change)="this.getClientReport(null,search_userStatus_client)">
                                    <option selected>Filter By User Status</option>
                                    <option value='active'>Active</option>
                                    <!-- <option value="no">Inactive</option> -->
                                    <option value='deleted'>Deleted</option>
                                </select>
                            </div>
                        </div>
                        <div class="action-tab">
                            <button type="button" class="mx-1 btn" tooltip="Export" container="body" (click)="exportexcel('client_reports')">
                                <i class="fas fa-file-export"></i>
                            </button>
                        </div>
                        <div class="table-responsive mb-1">
                            <table id="client_reports" class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" small="true" hover="true">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>AKcessID</th>
                                        <th>Status</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="client_reports?.length == 0">
                                    <tr>
                                        <td colspan="6" class="text-center">No Reports Found</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="client_reports?.length==0">
                                    <tr *ngFor="let data of client_reports | paginate: { itemsPerPage: pageSize_client, currentPage: currentPage_client, totalItems: totalCount_client };let i = index;">
                                        <td>
                                            {{data.firstName}} {{data.lastName}}
                                        </td>
                                        <td>
                                            {{ data.akcessId }}
                                        </td>
                                        <td>
                                            {{(data.removed_flg)?'Deleted':'Active'}}
                                        </td>
                                        <td>
                                            {{(data.email)}}
                                        </td>
                                        <td>
                                            {{(data.phone)}}
                                        </td>
                                        <td>
                                            {{dislayDate(data.createdon)}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="w-100">
                                    <tr>
                                        <td colspan="6">
                                            <div class="table-page" *ngIf="client_reports?.length > 10">
                                                <div class="page-controls">
                                                    <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                         class="my-pagination"
                                                                         directionLinks="true"
                                                                         previousLabel="Previous"
                                                                         nextLabel="Next"></pagination-controls>
                                                </div>
                                                <div class="page-size">
                                                    <label class="mb-0 mr-2">Item per page</label>
                                                    <select #val
                                                            (change)="changePage(val.value)"
                                                            class="form-control form-control-sm"
                                                            id="pageSelector">
                                                        <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                                            {{ pG }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ucModal" tabindex="-1" role="dialog" aria-labelledby="ucModal" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">List of Updated Customers</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="currentPage_uc = 1">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body background-light-blue">
                <div class="card mb-0">
                    <div class="card-body">
                        <div class="card-title row align-items-center">
                            <div class="col-12 col-md-6 col-lg-5 col-xl-4 mb-1 mb-lg-0">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="search"
                                           (change)="searchUsers_uc($event.target.value)"
                                           placeholder="Search" />
                                    <!--<form autocomplete="off"></form>-->
                                </div>
                            </div>
                        </div>
                        <div class="action-tab">
                            <button type="button" class="mx-1 btn" tooltip="Export" container="body"
                                    id="exportbtnpdf"
                                    (click)="exportexcel('pdf_formfields')">
                                <i class="fas fa-file-export"></i>
                            </button>
                        </div>
                        <div class="table-responsive mb-1">
                            <table id="pdf_formfields" class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" small="true" hover="true">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>AKcessID</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="uc_reports?.length == 0">
                                    <tr>
                                        <td colspan="4" class="text-center">No Reports Found</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="uc_reports?.length > 0">
                                    <tr *ngFor="let data of uc_reports">
                                        <td>
                                            {{data.user.firstName}} {{data.user.lastName}}
                                        </td>
                                        <td>
                                            {{ data._id }}
                                        </td>
                                        <td>
                                            {{(data.user.email)}}
                                        </td>
                                        <td>
                                            {{(data.user.phone)}}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="w-100">
                                    <tr>
                                        <td colspan="4">
                                            <div class="table-page" *ngIf="uc_reports?.length > 10">
                                                <div class="page-controls">
                                                    <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                         class="my-pagination"
                                                                         directionLinks="true"
                                                                         previousLabel="Previous"
                                                                         nextLabel="Next"></pagination-controls>
                                                </div>
                                                <div class="page-size">
                                                    <label class="mb-0 mr-2">Item per page</label>
                                                    <select #val
                                                            (change)="changePage(val.value)"
                                                            class="form-control form-control-sm"
                                                            id="pageSelector">
                                                        <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                                            {{ pG }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>