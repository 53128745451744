<div class="access-control-page">
  <!-- Bread crumb -->
  <div class="row page-titles">
    <div class="col-md-5 align-self-center">
      <h3 class="text-themecolor">Attendance Reports</h3>
    </div>
    <div class="col-md-7 align-self-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
        <li class="breadcrumb-item active">Attendance Reports</li>
      </ol>
    </div>
  </div>
  <!-- Main Section -->
  <section>
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="card-title row align-items-center">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
              <div class="input-group">
                <input
                  type="text"
                  [(ngModel)]="searchName"
                  (change)="
                    searchAttendanceReports('search', $event.target.value)
                  "
                  class="form-control"
                  id="search"
                  placeholder="Search by Name"
                />
              </div>
            </div>
          </div>
          <div class="table-responsive mb-1">
            <table
              class="table color-bordered-table info-bordered-table"
              mdbTable
              #tableEl="mdbTable"
              small="true"
              hover="true"
            >
              <thead>
                <tr>
                  <th>No.</th>
                  <th>AKcess ID</th>
                  <th>Name</th>
                  <th>Control Type</th>
                  <th>Attendance Date</th>
                  <th>Check-in</th>
                  <th>Check-in Time</th>
                  <th>Check-out</th>
                  <th>Check-out Time</th>
                </tr>
              </thead>
              <tbody *ngIf="attendanceList?.length == 0">
                <tr>
                  <td colspan="9" class="text-center">
                    No Attendance Reports Found
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="attendanceList?.length > 0">
                <tr
                  [id]="data?._id"
                  *ngFor="
                    let data of attendanceList
                      | paginate
                        : {
                            itemsPerPage: pageSize,
                            currentPage: currentPage,
                            totalItems: totalCount
                          };
                    let i = index
                  "
                >
                  <td>
                    {{ i + 1 }}
                  </td>
                  <td>{{ data?.akcessId }}</td>
                  <td>{{ data?.name }}</td>
                  <td>
                    {{
                      data.docTypeModel == "location"
                        ? "Location"
                        : "Access Control"
                    }}
                  </td>
                  <td>
                    {{ data.attendanceDateTime | date : "MMMM d, y" }}
                  </td>
                  <td>{{ data.checkIn ? "Yes" : "No" }}</td>
                  <td>
                    {{ data.checkInDateTime | date : "hh:mm a" }}
                  </td>
                  <td>{{ data.checkOut ? "Yes" : "No" }}</td>
                  <td>
                    {{ data.checkOutDateTime | date : "hh:mm a" }}
                  </td>
                </tr>
              </tbody>
              <tfoot class="w-100">
                <tr>
                  <td colspan="9">
                    <div class="table-page" *ngIf="attendanceList?.length > 10">
                      <div class="page-controls">
                        <pagination-controls
                          (pageChange)="onProjectPageChange($event)"
                          class="my-pagination"
                          directionLinks="true"
                          previousLabel="Previous"
                          nextLabel="Next"
                        ></pagination-controls>
                      </div>
                      <div class="page-size">
                        <label class="mb-0 mr-2">Item per page</label>
                        <select
                          #val
                          (change)="changePage(val.value)"
                          class="form-control form-control-sm"
                          id="pageSelector"
                        >
                          <option
                            *ngFor="let pG of numberOfPage"
                            value="{{ pG }}"
                          >
                            {{ pG }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
