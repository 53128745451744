import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from '../../../../_services/dataservices.service';

@Component({
    selector: 'app-clone-eform-modal',
    templateUrl: './clone-eform-modal.component.html',
    styleUrls: ['./clone-eform-modal.component.css']
})
export class CloneEformModalComponent implements OnInit {

    eformCopyName: string = '';
    eformIdForClone: any = '';
    eformData: any = '';
    portalUser;
    eformdata: any = [];
    search_formName: string = '';
    userwithChips: any = [];
    userwithChips_phone: any = [];

    currentPage: any = 1;
    pageSize: any = 10;
    totalCount: any = 0;

    selectedCompany: any = "";

    constructor(
        private _bsModalRef: BsModalRef,
        public modalService: BsModalService,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.getActiveCompanyList();

    }

    //cloneEform(val, id) {
    //    this.eformCopyName = ''
    //    this.eformIdForClone = ''
    //    this.eformCopyName = val
    //    this.eformIdForClone = id
    //    $('#cloneEform').modal('show')
    //}

    geteformData() {
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = {
            'akcessId': this.portalUser.akcessId,
            'search_formName': this.search_formName,
            'currentPage': this.currentPage,
            'limit': this.pageSize
        };

        this.calldata.geteformdata(tmpdata).subscribe((res: any) => {
            if (res.status) {
                this.eformdata = [];
                this.eformdata = res.data.results;
                this.totalCount = res.data.totalCount

            } else {
                this.toast.info(res.message);
                //this.spinner.hide();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        }
        )
    }

    addClone() {
        this.spinner.show();
        this.calldata.cloneEform(this.eformData, this.db_name).subscribe((res: any) => {
            if (res.status) {
                this.geteformData();
                this.toast.info('eForm Cloned Successfully');
                this.eformCopyName = '';
                this.eformIdForClone = '';
                window.location.reload();
                // this.closeModal();
                // this.router
                //     .navigateByUrl("/", { skipLocationChange: true })
                //     .then(() => {
                //         this.router.navigate(["/panel/eforms"]);
                //     });

            } else {
                this.toast.error('Something went wrong');
                this.spinner.hide();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
        // this.calldata.duplicateEformAdd({ name: this.eformCopyName, id: this.eformIdForClone }).subscribe((res: any) => {
        //     if (res.status == 1) {
        //         this.geteformData();
        //         this.toast.info('eForm Cloned Successfully');
        //         this.eformCopyName = '';
        //         this.eformIdForClone = '';
        //         this.closeModal();
        //         this.router
        //             .navigateByUrl("/", { skipLocationChange: true })
        //             .then(() => {
        //                 this.router.navigate(["/panel/eforms"]);
        //             });
        //         this.spinner.hide();
        //     } else {
        //         this.toast.warning(res.message);
        //         this.spinner.hide();
        //     }
        // }, err => {
        //     this.toast.error('Something went wrong');
        //     this.spinner.hide();
        // })
    }

    closeModal() {
        this.eformCopyName = '';
        this.eformIdForClone = '';
        this._bsModalRef.hide();
    }

    activeCompany = [];

    getActiveCompanyList() {
        let data = {
            akcessId: sessionStorage.getItem('logedInUserAckessId')
        }
        this.spinner.show();
        this.calldata.listactivecompany(data).subscribe((res: any) => {
            this.spinner.hide();
            this.activeCompany = res.data;
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    db_name: any;
    selectCompany(e) {
        this.selectedCompany = e.target.value
        this.activeCompany.filter((el) => {
            if (e.target.value == el._id) {
                this.db_name = el.dbName;
                this.eformData.logo = el.logo;
                // this.eformData.originCompany = el.name;
                this.eformData.originCompany = sessionStorage.getItem('selectedCompanyName');
                this.eformData.companyName = el.domain;
                this.eformData.companyDomain = el.name;
                this.eformData.formName = this.eformCopyName;
                delete this.eformData._id
                // this.eformData._id = '';
                // this.eformData.remove("_id");
            }
        })
    }
}
