import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { ConstantService } from "../../../_services/constant.service";
import { CompanyConfirmModalComponent } from "./company-confirm-modal/company-confirm-modal.component";
import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { ConfirmDialogService } from "../../confirm-dialog/confirm-dialog.service";
declare var $: any;

@Component({
    selector: "app-company-management",
    templateUrl: "./company-management.component.html",
    styleUrls: ["./company-management.component.css"],
})
export class CompanyManagementComponent implements OnInit {
    submitted = false;
    portalUser;
    phone_or_email: any = "email";
    userlist: any = [];
    pageSize: any = 10;
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    listCompany: any = [];
    addForm: FormGroup;
    updateCompanyForm: FormGroup;

    emailList: any = [];
    phoneList: any = [];
    phoneFG: FormGroup;

    akcessid: any = "";
    CompanyData;
    selectedCompany: any = {};
    search_companies: string;
    bsModalRef: BsModalRef;

    isFileUploaded: boolean = false;
    isFileChosen: boolean = false;
    fileName: string = "";
    document;

    filter_country_options: any = [];

    SearchCountryField = SearchCountryField;
    // TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.Qatar];
    checkedCompanyList: any = [];
    isMasterSel: Boolean;

    constructor(
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private calldata: DataservicesService,
        private callGeneral: GeneralServiceService,
        private router: Router,
        private formBuilder: FormBuilder,
        private refreshContentService: RefreshContentService,
        public modalService: BsModalService,
        private cs: ConstantService,
        private confirmDialogService: ConfirmDialogService
    ) {}

    resetForm() {
        this.addForm.reset();
    }

    /**
     * Send data to navigated page without showinhg it in url
     * @param data
     * @param path
     */
    navigateTo(data, path) {
        var temp = {
            selectedCompany: data,
        };
        this.router.navigate([path], {
            state: { ...temp },
        });
    }

    ngOnInit() {
        // this.refreshContentService.notifyToAll.subscribe(res => {
        //   if (res && res.hasOwnProperty("option")) {
        //     this.getcompanyList();
        //     this.getuserData(true);
        //   }
        //   else {
        //     this.getcompanyList();
        // this.getuserData(true);
        //   }
        // });

        //if (!localStorage.getItem('pageReload')) {
        //    localStorage.setItem('pageReload', 'true');
        //    window.location.reload();
        //}

        this.phoneFG = this.formBuilder.group({
            phone: [],
        });

        if (!sessionStorage.getItem("pageReload")) {
            sessionStorage.setItem("pageReload", "true");
            window.location.reload();
        }

        this.createCompanyForm();
        this.editCompanyForm();
        this.getcompanyList();
        this.getuserData(true);
        this.filter_country_options = this.cs.countries();
    }

    createCompanyForm() {
        this.addForm = this.fb.group({
            companyLogo: ["", Validators.required],
            name: ["", Validators.required],
            address: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            domain: ["", [Validators.pattern("^((?!(http|https|://)).)*$")]],
            country: ["", Validators.required],
            //domain: ["", [Validators.required, Validators.pattern("^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$")]],
            //domain: ["", [Validators.required, Validators.pattern("^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$")]],
            //domain: ["", [Validators.pattern("^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$")]],
            //domain: ["", [Validators.pattern("^((?!(http|https|://)).)*$")]],
        });
    }

    editCompanyForm() {
        this.updateCompanyForm = this.fb.group({
            name: ["", Validators.required],
            address: ["", Validators.required],
            country: ["", Validators.required],
        });
    }

    onCompanySubmit() {
        this.submitted = true;
        if (this.addForm.invalid) {
            return;
        }
        let values = this.addForm.value;
        values.akcessId = sessionStorage.getItem("logedInUserAckessId");
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        values.phone = this.portalUser.phone;
        values.logInUserAkcessId = sessionStorage.getItem(
            "logedInUserAckessId"
        );

        this.spinner.show();

        this.calldata.addCompany(values).subscribe(
            (res: any) => {
                if (res.status) {
                    this.CompanyData = res.data;
                    this.toast.success(res["message"]);
                } else {
                    this.toast.warning(res["message"]);
                }
                this.spinner.hide();
                $("#addCompany").modal("hide");
                this.getcompanyList();

                this.bsModalRef = this.modalService.show(
                    CompanyConfirmModalComponent,
                    {
                        animated: true,
                        backdrop: "static",
                        class: "modal-dialog-centered modal-lg",
                    }
                );
            },
            (error) => {
                this.spinner.hide();
                $("#addCompany").modal("hide");
                this.toast.error(error["statusText"]);
            }
        );
    }
    // refresh(): void {
    //   window.location.reload();
    // }
    getcompanyList() {
        let data = {
            akcessId: sessionStorage.getItem("logedInUserAckessId"),
        };
        this.spinner.show();
        this.calldata.listCompany(data).subscribe(
            (res: any) => {
                if (res.status) {
                    this.spinner.hide();
                    this.listCompany = res.data.map((object) => {
                        return { ...object, isSelected: false };
                    });
                }
                this.toast.success(res["message"]);
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }
    getuserData(flag) {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    }

    view(val) {
        let country = val.country ? val.country : null;
        this.selectedCompany = { ...val, country };
        // this.domainname = val.domain;
        // this.apikey = val.apikey || "";
        // this.companyname = val.name;
        // this.address = val.address;
        // this.administrator = val.akcessId;
        // this.status = val.status;
    }

    getBulkEmailID(event) {}

    onProjectPageChange(event) {
        this.currentPage = event;
    }

    changePage(value) {
        this.pageSize = parseInt(value);
    }

    clear() {
        this.selectedCompany = {};
    }

    updateCompany() {
        this.submitted = true;
        if (this.updateCompanyForm.invalid) {
            return;
        }
        let values = this.updateCompanyForm.value;
        values.akcessId = sessionStorage.getItem("logedInUserAckessId");
        values.domain = this.selectedCompany.domain;
        this.spinner.show();
        this.calldata.updateCompany(values).subscribe(
            (data) => {
                this.CompanyData = data;
                this.toast.success(data["message"]);
                this.spinner.hide();
                $("#updateCompanyForm").modal("hide");
                this.clear();
                this.getcompanyList();
                this.editCompanyForm();
                this.submitted = false;
            },
            (error) => {
                this.spinner.hide();
                $("#updateCompanyForm").modal("hide");
                this.editCompanyForm();
                this.submitted = false;
                this.clear();
                this.toast.error(error["statusText"]);
            }
        );
    }

    onFileChange(files) {
        let reader = new FileReader(),
            self = this;

        reader.addEventListener(
            "load",
            function () {
                // convert image file to base64 string
                self.addForm.controls["companyLogo"].setValue(reader.result);
            },
            false
        );

        if (files[0]) {
            reader.readAsDataURL(files[0]);
        }
    }

    addEmail(e) {
        var validRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (e.target.value.match(validRegex)) {
            this.emailList.push(e.target.value);
            e.target.value = null;
        } else {
            this.toast.warning(e.target.value + " is not valid email address");
        }
    }
    addPhone() {
        let cc = this.phoneFG.get("phone").value.dialCode;
        let phone = this.phoneFG.get("phone").value.e164Number;
        phone = phone.split(cc)[1];
        cc = cc.split("+")[1];
        this.phoneList.push({
            countryCode: cc,
            phone: phone,
        });
        this.phoneFG.get("phone").reset();
    }
    sendInvite() {
        let tmpData = {};
        if (this.phone_or_email == "email") {
            tmpData = {
                emails: this.emailList,
                // content: "Data we have to send",
            };
        } else if (this.phone_or_email == "phone") {
            tmpData = {
                phones: this.phoneList,
                // content: "Data we have to send",
            };
        }
        this.callGeneral.sendinvitationUser(tmpData).subscribe(
            (res) => {
                if (res["status"] === true) {
                    this.toast.success(res["message"]);
                    $("#addCompanyKYC").modal("hide");
                } else if (res["status"] === false) {
                    this.toast.error(res["message"]);
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    removeData(i, type) {
        if (type == "email") {
            this.emailList.splice(i, 1);
        } else if (type == "phone") {
            this.phoneList.splice(i, 1);
        }
    }
    resendActivation(data) {
        let obj = {
            subject: "Resent Activation Request",
            to: ["apps@akcess.io"],
            text: `${data.name} with ${data.domain} created at ${data.createdAt} is Requesting you to get verified.`,
            html: ` <p>${data.name} with ${data.domain} created at ${data.createdAt} is Requesting you to get verified. </p>`,
        };
        this.calldata.sendemail(obj).subscribe(
            (res) => {
                this.toast.success("Activation Request Sent Successfully");
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    getCheckedItemList() {
        this.checkedCompanyList = [];
        for (var i = 0; i < this.listCompany.length; i++) {
            if (
                this.listCompany[i].isSelected &&
                this.portalUser.akcessId ===
                    this.listCompany[i].logInUserAkcessId
            )
                this.checkedCompanyList.push(this.listCompany[i]._id);
        }

        return this.checkedCompanyList;
    }

    deleteSingleRows(id) {
        let me = this;
        me.confirmDialogService.confirmThis(
            "Are you sure to delete?",
            function () {
                me.spinner.show();
                me.calldata
                    .deleteCompany({
                        companyIds: [id],
                        logedinuserackessid: sessionStorage.getItem(
                            "logedInUserAckessId"
                        ),
                    })
                    .subscribe(
                        (res: any) => {
                            if (res.status) {
                                me.listCompany = me.listCompany.filter(
                                    (object) => {
                                        return !object.isSelected;
                                    }
                                );
                                me.spinner.hide();
                                me.toast.success("Company " + res.message);
                            } else {
                                me.toast.info("Company " + res.message);
                            }
                        },
                        (error) => {
                            me.toast.error("Company not deleted");
                            me.spinner.hide();
                        }
                    );
            },
            function () {}
        );
    }

    deleteRows() {
        let me = this;
        me.confirmDialogService.confirmThis(
            "Are you sure to delete?",
            function () {
                me.spinner.show();
                let deleteIDs = me.getCheckedItemList();

                if (deleteIDs.length > 0) {
                    me.calldata
                        .deleteCompany({
                            companyIds: deleteIDs,
                            logedinuserackessid: sessionStorage.getItem(
                                "logedInUserAckessId"
                            ),
                        })
                        .subscribe(
                            (res: any) => {
                                if (res.status) {
                                    me.listCompany = me.listCompany.filter(
                                        (object) => {
                                            return !object.isSelected;
                                        }
                                    );
                                    me.spinner.hide();
                                    me.toast.success("Company " + res.message);
                                } else {
                                    me.toast.info("Company " + res.message);
                                }
                            },
                            (error) => {
                                me.toast.error("Company not deleted");
                                me.spinner.hide();
                            }
                        );
                } else {
                    me.toast.error("Select a Company");
                    me.spinner.hide();
                }
            },
            function () {}
        );
    }

    checkUncheckAll() {
        for (var i = 0; i < this.listCompany.length; i++) {
            if (
                this.portalUser.akcessId ==
                this.listCompany[i].logInUserAkcessId
            ) {
                this.listCompany[i].isSelected = this.isMasterSel;
            }
        }
    }

    isAllSelected() {
        this.isMasterSel = this.listCompany.every(function (item: any) {
            if (this.portalUser.akcessId == item.logInUserAkcessId) {
                return item.isSelected == true;
            } else {
                return item.isSelected == false;
            }
        });
    }
}
