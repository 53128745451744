<div class="assets-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Assets</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 xs-mb-3">
                        <div class="input-group">
                            <input
                                type="search"
                                [(ngModel)]="searchName"
                                (search)="
                                    searchAssets('search', $event.target.value)
                                "
                                class="form-control"
                                id="search"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    <div class="col-12 col-md-4 col-lg-3 col-xl-3 xs-mb-3">
                        <div class="input-group">
                            <div ngbDropdown autoClose class="d-inline-block">
                                <button
                                    type="button"
                                    class="btn-sorting"
                                    id="dropdownBasic1"
                                    ngbDropdownToggle
                                >
                                    Sort by
                                </button>
                                <div
                                    ngbDropdownMenu
                                    autoClose
                                    aria-labelledby="dropdownBasic1"
                                >
                                    <p class="heading">SORT BY</p>
                                    <a ngbDropdownItem>Name</a>
                                    <a ngbDropdownItem>Type</a>
                                    <a ngbDropdownItem>Date</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-12 col-md-4 col-lg-3 col-xl-3 text-right mt-1 mt-md-0 ml-auto xs-mb-3"
                    >
                        <a
                            type="button"
                            class="btn waves-effect waves-light btn-info add-btn"
                            [routerLink]="['/panel/assets/create']"
                        >
                            <i
                                class="fas fa-plus-circle"
                                tooltip="Create Asset"
                                placement="bottom"
                                container="body"
                            ></i>
                            Create Asset
                        </a>
                    </div>
                </div>
                <div class="action-tab">
                    <button
                        type="button"
                        class="mx-1 btn"
                        (click)="deleteRows()"
                        tooltip="Delete"
                        container="body"
                    >
                        <i class="fas fa-trash"></i>
                    </button>
                    <span class="vertical-divider">|</span>
                    <button
                        type="button"
                        class="mx-1 btn"
                        (click)="viewRecycle()"
                        tooltip="Recycle Bin"
                        container="body"
                    >
                        <i class="fal fa-recycle"></i>
                    </button>
                </div>
                <div class="table-responsive mb-1">
                    <table
                        class="table color-bordered-table info-bordered-table table-hover table-sm"
                        mdbTable
                        #tableEl="mdbTable"
                    >
                        <thead>
                            <tr>
                                <th>
                                    <div class="form-check">
                                        <input
                                            class="datatable-row-checkbox form-check-input"
                                            type="checkbox"
                                            (change)="checkUncheckAll()"
                                            [(ngModel)]="isMasterSel"
                                        />
                                    </div>
                                </th>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Created</th>
                                <th>Status</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="assetslist?.length == 0">
                            <tr>
                                <td colspan="7" class="text-center">
                                    No Records Found
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="assetslist?.length > 0">
                            <tr
                                *ngFor="
                                    let data of assetslist
                                        | paginate
                                            : {
                                                  itemsPerPage: pageSize,
                                                  currentPage: currentPage,
                                                  totalItems: totalCount
                                              };
                                    let i = index
                                "
                            >
                                <td>
                                    <div class="form-check">
                                        <input
                                            class="datatable-row-checkbox form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="data.isSelected"
                                            [attr.id]="data?._id"
                                            value="data?._id"
                                            (change)="isAllSelected()"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <img
                                        *ngIf="data.picture"
                                        src="{{ getFullURL(data.picture) }}"
                                        class="img-fluid img-thumbnail"
                                        alt="{{ data.name }}"
                                        title="{{ data.name }}"
                                        width="80"
                                    />
                                </td>
                                <td>
                                    {{ data.name }}
                                </td>
                                <td>
                                    {{ data.type }}
                                </td>
                                <td>
                                    {{ data.createdAt | date : "medium" }}
                                </td>
                                <td>
                                    <button
                                        *ngIf="data.isVerified == 2"
                                        class="mx-2 btn btn-sm waves-effect waves-light btn-success"
                                    >
                                        Authentified
                                    </button>
                                    <button
                                        *ngIf="data.isVerified == 1"
                                        class="mx-2 btn btn-sm waves-effect waves-light btn-warning"
                                    >
                                        Pending
                                    </button>
                                    <button
                                        *ngIf="data.isVerified == 0"
                                        class="mx-2 btn btn-sm waves-effect waves-light btn-danger"
                                    >
                                    Authenticate
                                    </button>
                                </td>
                                <td>
                                    <button
                                        class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                        (click)="openShareAssetModal(data)"
                                    >
                                        Share
                                    </button>
                                    <button
                                        class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                        (click)="
                                            viewSentAssetsModal(
                                                data._id,
                                                'sent',
                                                data.name
                                            )
                                        "
                                    >
                                        View Sent
                                    </button>

                                    <a
                                        [routerLink]="[
                                            '/panel/assets/' +
                                                data._id +
                                                '/view'
                                        ]"
                                        data-toggle="tooltip"
                                        title="View Asset"
                                        class="link-only-icon"
                                        data-original-title="View Asset"
                                        ><i
                                            class="fa fa-eye"
                                            aria-hidden="true"
                                        ></i
                                    ></a>

                                    <a
                                        [routerLink]="[
                                            '/panel/assets/' +
                                                data._id +
                                                '/edit'
                                        ]"
                                        data-toggle="tooltip"
                                        title="Edit Asset"
                                        class="link-only-icon"
                                        data-original-title="Edit Asset"
                                        ><i
                                            class="fa fa-edit green-txt"
                                            aria-hidden="true"
                                        ></i
                                    ></a>
                                    <button
                                        (click)="deleteSingleRows(data._id)"
                                        title="Remove Asset"
                                        class="btn btn-link delete_btn link-only-icon"
                                    >
                                        <i
                                            class="fa fa-trash red-txt"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="7">
                                    <div
                                        class="table-page"
                                        *ngIf="assetslist?.length > 0"
                                    >
                                        <div class="page-controls">
                                            <pagination-controls
                                                (pageChange)="
                                                    onProjectPageChange($event)
                                                "
                                                class="my-pagination"
                                                directionLinks="true"
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                            ></pagination-controls>
                                        </div>
                                        <div class="page-size">
                                            <label class="mb-0 mr-2"
                                                >Item per page</label
                                            >
                                            <select
                                                #val
                                                (change)="changePage(val.value)"
                                                class="form-control form-control-sm"
                                                id="pageSelector"
                                            >
                                                <option
                                                    *ngFor="
                                                        let pG of numberOfPage
                                                    "
                                                    value="{{ pG }}"
                                                >
                                                    {{ pG }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-confirm-dialog></app-confirm-dialog>
