<div class="access-control-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/property']">Property</a>
                </li>
                <li class="breadcrumb-item active">Enquiries</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div class="input-group">
                                <input
                                    type="search"
                                    [(ngModel)]="searchName"
                                    (search)="
                                        searchPropertyInquiries(
                                            'search',
                                            $event.target.value
                                        )
                                    "
                                    class="form-control"
                                    id="search"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableEl="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Property Name</th>
                                    <th>
                                        <i class="fal fa-pound-sign"></i> Price
                                    </th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Notes</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="inquiryList?.length == 0">
                                <tr>
                                    <td colspan="9" class="text-center">
                                        No Enquiries Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="inquiryList?.length > 0">
                                <tr
                                    [id]="data?._id"
                                    *ngFor="
                                        let data of inquiryList
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                >
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>{{ data?.property.name }}</td>
                                    <td>
                                        <i class="fal fa-pound-sign"></i>
                                        {{
                                            data?.property.price
                                                | number : "1.0" : "en-US"
                                        }}
                                    </td>
                                    <td>{{ data?.firstName }}</td>
                                    <td>{{ data?.lastName }}</td>
                                    <td>{{ data?.email }}</td>
                                    <td>{{ data?.mobile }}</td>
                                    <td>{{ data?.notes }}</td>
                                    <td>
                                        <div class="actions-div">
                                            <a
                                                [routerLink]="[
                                                    '/panel/property/inquiry/' +
                                                        data._id +
                                                        '/view'
                                                ]"
                                                data-toggle="tooltip"
                                                title="View Enquiry"
                                                data-original-title="View Enquiry"
                                                ><i
                                                    class="fa fa-eye"
                                                    aria-hidden="true"
                                                ></i
                                            ></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="9">
                                        <div
                                            class="table-page"
                                            *ngIf="inquiryList?.length > 10"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
