import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from "ngx-intl-tel-input";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { GetDataFromIdComponent } from "src/app/layout/models/get-data-from-id/get-data-from-id.component";
import { UploadDocumentComponent } from "../../documents/upload-document/upload-document.component";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { GenerateIdModalComponent } from "../../employees/generate-id-modal/generate-id-modal.component";
import { ConfirmDialogService } from "../../../confirm-dialog/confirm-dialog.service";

@Component({
    selector: "app-add-edit-client",
    templateUrl: "./add-edit-client.component.html",
    styleUrls: ["./add-edit-client.component.css"],
})
export class AddEditClientComponent implements OnInit {
    public modalRef: BsModalRef;

    addclientform: FormGroup;
    uploadDocForm: FormGroup;

    act: string = "Add";
    isAkcessId: boolean = false;

    isSubmitted = false;
    countryList: any = [];
    userData: any;
    isEdit: boolean = false;
    btnText: string = "Save";

    constructor(
        public fb: FormBuilder,
        public router: Router,
        public cdr: ChangeDetectorRef,
        public dataService: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        public http: HttpClient,
        public modalService: BsModalService,
        private confirmDialogService: ConfirmDialogService
    ) {
        this.createAddEmployeeForm();
        this.createUploadDocForm();
        if (window.location.href.includes("add")) {
            this.btnText = "Save";
            this.isEdit = false;
        } else if (window.location.href.includes("edit")) {
            this.isEdit = true;
            this.btnText = "Update";
            this.userData =
                this.router.getCurrentNavigation().extras.state.userData;
            this.uploadProfileImage = this.userData.profileimg
                ? this.userData.profileimg
                : "../../../../assets/new-images/users/default-profile.svg";
        }
    }

    ngOnInit(): void {
        this.getCountryList();
    }

    createAddEmployeeForm() {
        this.addclientform = this.fb.group({
            _id: [null],
            searchakcessid: [null],
            firstName: ["", Validators.required],
            lastName: ["", Validators.required],
            //email:["",Validators.compose([Validators.required, Validators.email])],
            email: ["", [Validators.required, Validators.email]],
            phone: ["", Validators.required],
            // phone: ["", [Validators.required, Validators.pattern("^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$")]],
            // phone: [""],
            address: [""],
            // haveAkcessId:[""]
            // clientStatus:[null],
            // clientProducts:[null],
            // USDAccountBalance:[null],
            // KDAccountBalance:[null],
            DOB: [null],
            placeOfBirth: [null],
            gender: [""],
            nationality: [""],
            akcessId: ["", [Validators.required, Validators.minLength(11)]],
        });
    }

    createUploadDocForm() {
        this.uploadDocForm = this.fb.group({
            docName: ["", Validators.required],
            docType: ["", Validators.required],
            docExpiryDate: ["", Validators.required],
            docFile: ["", Validators.required],
        });
    }
    get formControls() {
        return this.addclientform.controls;
    }

    getCountryList() {
        this.http.get("/assets/country-list.json").subscribe((res: any) => {
            res.filter((e) => {
                this.countryList.push(e.label);
            });
        });
    }

    uploadProfileImage: any =
        "../../../../assets/new-images/users/default-profile.svg";

    onUploadChange(evt: any) {
        const file = evt.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(file);
        }
    }

    handleReaderLoaded(e) {
        this.uploadProfileImage =
            "data:image/png;base64," + btoa(e.target.result);
    }

    addClient() {
        this.isSubmitted = true;
        if (this.addclientform.invalid) {
            return;
        }
        this.spinner.show();
        let number: any = this.addclientform.controls.phone.value;

        let phoneNumber = number.e164Number.split(number.dialCode);
        let countryCode = number.dialCode.split("+");

        let data: any = {
            firstName: this.addclientform.controls.firstName.value,
            lastName: this.addclientform.controls.lastName.value,
            email: this.addclientform.controls.email.value,
            phone: phoneNumber[1],
            countryCode: countryCode[1],
            address: this.addclientform.controls.address.value,
            profileimg: this.uploadProfileImage.includes("data")
                ? this.uploadProfileImage
                : "",
            nationality: this.addclientform.controls.nationality.value,
            gender: this.addclientform.controls.gender.value,
            placeofbirth: this.addclientform.controls.placeOfBirth.value,
            dateofbirth: this.addclientform.controls.DOB.value,
            akcessId: this.addclientform.controls.akcessId.value,
        };
        let me = this;
        if (this.btnText.toLocaleLowerCase() == "save") {
            this.dataService.createcompanyclient(data).subscribe(
                (res: any) => {
                    this.spinner.hide();
                    if (res.status) {
                        this.router.navigate(["/panel/clients"]);
                        this.toast.success(`${res.message}`);
                    } else {
                        if (res.removed_flg != undefined) {
                            if (res.removed_flg == true) {
                                me.confirmDialogService.confirmThis(
                                    res.message,
                                    function () {
                                        me.restoreDeletedClientOnAdd(
                                            res.client_id
                                        );
                                    },
                                    function () {}
                                );
                            } else {
                                this.toast.error(res.message);
                            }
                        } else {
                            this.toast.error(res.message);
                        }
                    }
                },
                (err) => {
                    console.log(err);
                    this.toast.error(err.message);
                    this.spinner.hide();
                }
            );
        } else if (this.btnText.toLocaleLowerCase() == "update") {
            data._id = this.userData._id;
            this.dataService.editclient(data).subscribe(
                (res: any) => {
                    if (res.status) {
                        this.router.navigate(["/panel/clients"]);
                        this.toast.success(`${res.message}`);
                        this.spinner.hide();
                    } else {
                        this.toast.error(res.message);
                        this.spinner.hide();
                    }
                },
                (err) => {
                    this.toast.error(err.message);
                    this.spinner.hide();
                }
            );
        }
    }

    resetform() {}

    restoreDeletedClientOnAdd(client_id) {
        this.spinner.show();
        const data = {
            component: "client",
            _id: client_id,
        };

        this.dataService.restoreDeletedData(data).subscribe(
            (res: any) => {
                if (res.status) {
                    this.toast.success("Client successfully restored");
                    this.router.navigate(["/panel/clients"]);
                    this.spinner.hide();
                } else {
                    this.toast.info("Failed to restore client");
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    setUserData() {
        // this.addclientform.controls.phone.setValue(phone);
        this.addclientform.controls.akcessId.setValue(this.userData.akcessId);
        this.addclientform.controls.firstName.setValue(this.userData.firstName);
        this.addclientform.controls.lastName.setValue(this.userData.lastName);
        this.addclientform.controls.email.setValue(this.userData.email);
        this.addclientform.controls.phone.setValue(this.userData.mobile);
        this.addclientform.controls.address.setValue(this.userData.address);
        this.addclientform.controls.DOB.setValue(this.userData.dateofbirth);
        this.addclientform.controls.placeOfBirth.setValue(
            this.userData.placeofbirth
        );
        this.addclientform.controls.gender.setValue(this.userData.gender);
        this.addclientform.controls.nationality.setValue(
            this.userData.nationality
        );
        this.uploadProfileImage = this.userData.profileimg
            ? this.userData.profileimg
            : "../../../../assets/new-images/users/default-profile.svg";
        this.cdr.detectChanges();
    }

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required]),
    });

    changePreferredCountries() {
        this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    }

    async editClient() {
        this.setUserData();
        this.isEdit = false;
        await this.http
            .get("/assets/countryCode.json")
            .subscribe((res: any) => {
                res.filter((e) => {
                    if (this.userData.countryCode == e.dial_code) {
                        this.addclientform.patchValue({
                            phone: {
                                number: this.userData.phone,
                                countryCode: e.code,
                                dialCode: "+" + this.userData.countryCode,
                            },
                        });
                    }
                });
            });
    }

    deleteClient() {
        if (confirm("Are you sure you want to remove client?")) {
            this.spinner.show();
            const data = { _id: this.userData._id };
            this.dataService.deleteClient(data).subscribe(
                (res: any) => {
                    if (res.status) {
                        this.router.navigate(["/panel/clients"]);
                        this.toast.success(res.message);
                    } else {
                        this.toast.info(res.message);
                        this.spinner.hide();
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        }
    }

    redirect() {
        this.router.navigate(["/panel/clients"]);
    }

    openModal() {
        this.modalRef = this.modalService.show(GetDataFromIdComponent, {
            class: "modal-dialog-centered",
        });
        this.modalRef.content.myContent = "Add Client";
    }
}
