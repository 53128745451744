import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";

@Component({
  selector: "app-add-location",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"],
})
export class AddLocationComponent implements OnInit {
  addLocationForm: FormGroup;
  constructor(
    private spinner: NgxSpinnerService,
    private ds: DataservicesService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {}
}
