import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { DataservicesService } from '../../../../_services/dataservices.service';
import { GeneralServiceService } from '../../../../_services/general-service.service';


@Component({
    selector: 'app-delete-users',
    templateUrl: './delete-users.component.html',
    styleUrls: ['./delete-users.component.css']
})
export class DeleteUsersComponent implements OnInit {
    userSendData: any = ""
    companyID: any = ""
    listCompanyUsers: any = [];
    public onDelete: Subject<any> = new Subject();

    constructor(
        private _bsModalRef: BsModalRef,
        private gs: GeneralServiceService,
        private toast: ToastrService,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    deleteUser(data, companyID) {
        let obj = {
            akcessId: data,
            _id: companyID._id
        };

        this.gs.deleteCompanyAdmin(obj).subscribe((res: any) => {
            if (res.status)
                this.toast.success(res.message);
            else
                this.toast.warning(res.message);
            //$('#sendIdCardModelId').click();
            this._bsModalRef.hide();

            this.onDelete.next(res)

            this.getAllCompanyUsers(companyID);

            this.spinner.hide();
        },
            error => {

                this.toast.error(error.error.message);
                this.spinner.hide();
            }
        )


    }

    getAllCompanyUsers(companyID) {
        let data = {
            akcessId: sessionStorage.getItem('logedInUserAckessId'),
            domainName: companyID.domain
        }

        this.calldata.listCompanyByDomain(data).subscribe(
            (res: any) => {
                if (res.status) {
                    this.listCompanyUsers = res.data.admin;
                    //this.companyDetails = res.data._id;
                }
            },
            (error) => {
            }
        );
    }

}
