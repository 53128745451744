<!-- View Document Modal -->
<div class="modal-content view-document-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewDocumentModalLabel">Document More Information</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <input type="hidden" [(ngModel)]="selectedDocument" value="{{selectedDocument}}" />
        <div class="document-pdf">
            <pdf-viewer [src]="fileUrl" [render-text]="false" [original-size]="true" [fit-to-page]="true"
                        style="display: block;"></pdf-viewer>
        </div>
    </div>
    <div class="modal-footer">
        <!--<button type="button" class="btn btn-primary" (click)="akcessIDChips = [];sharedModal.show()">
            Share Document
        </button>-->
        <a class="btn waves-effect waves-light btn-info" href="{{fileUrl.url}}" target="_blank" rel="noopener noreferrer">Download</a>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">
            Close
        </button>
    </div>
</div>

<!-- Share Document User Modal -->
<!--<div class="modal fade" id="shareForm" tabindex="-1" role="dialog" aria-labelledby="shareFormModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="shareFormModalLabel">Share Documents</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label><b>Search User</b></label>
                    <tag-input [ngModel]="akcessIDChips" secondaryPlaceholder='Search Users'
                               placeholder='Search Users' theme='dark' (onAdd)="pushAkcessData($event)"
                               (onRemove)="removeAkcessData($event)">
                        <tag-input-dropdown [autocompleteItems]="userData" [showDropdownIfEmpty]="false"
                                            [dynamicUpdate]="true" [appendToBody]="false">
                        </tag-input-dropdown>
                    </tag-input>
                    <div class="my-4">
                        <ng-select [items]="userData"
                                   [addTag]="addTagFn"
                                   [hideSelected]="true"
                                   multiple="true"
                                   bindLabel="name"
                                   placeholder="Select User"
                                   [(ngModel)]="akcessIDChips"
                                   appendTo="body">
                        </ng-select>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="shareDocument()">
                    Send
                </button>
                <button type="button" class="btn btn-danger" (click)="akcessIDChips = []"
                        id="shareFormClose" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>-->
<!-- Share Document User Modal -->
<div class="modal" bsModal #sharedModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="shareFormModalLabel" (onShow)="onModalOpen()">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="shareFormModalLabel">Share Documents</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="sharedModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="font-500">Search User</label>
                    <tag-input [ngModel]="akcessIDChips" secondaryPlaceholder='Search Users'
                               placeholder='Search Users' theme='dark' (onAdd)="pushAkcessData($event)"
                               (onRemove)="removeAkcessData($event)">
                        <tag-input-dropdown [autocompleteItems]="userData" [showDropdownIfEmpty]="false"
                                            [dynamicUpdate]="true" [appendToBody]="false">
                        </tag-input-dropdown>
                    </tag-input>
                    <div class="my-4">
                        <ng-select [items]="userDataList$"
                                   [addTag]="addTagFn"
                                   [hideSelected]="true"
                                   multiple="true"
                                   bindLabel="name"
                                   placeholder="Select User"
                                   [(ngModel)]="akcessIDChips"
                                   appendTo="body"
                                   (open)="onOpenDropdown()"
                                   (close)="onCloseDropdown()">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="shareDocument()"
                        [disabled]="selectSubmitDisabled">
                    Send
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" (click)="akcessIDChips = []; sharedModal.hide()"
                        id="shareFormClose" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>