<!-- Side Navbar -->
<!--<nav class="side-navbar">
    <app-header></app-header>
</nav>-->
<!-- Page -->
<!--<div class="page">-->
<!-- Navbar-->
<!--<app-navbar></app-navbar>-->
<!-- Pages Section -->
<!--<section class="main-pages">
    <router-outlet></router-outlet>
</section>-->
<!-- Footer -->
<!--<footer class="main-footer">
        <app-footer></app-footer>
    </footer>
</div>-->

<div id="main-wrapper">
    <!--Topbar header-->
    <app-navbar></app-navbar>
    <!-- Left Sidebar -->
    <app-header></app-header>
    <!-- Page wrapper  -->
    <div class="page-wrapper">
        <router-outlet></router-outlet>
        <!-- Footer -->
        <app-footer></app-footer>
    </div>
</div>


