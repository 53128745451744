<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3 class="text-themecolor">Message</h3>
    </div>
    <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
            <li class="breadcrumb-item active">Message</li>
        </ol>
    </div>
</div>
<!-- <div class="row"> -->
<div class=" container-fluid messaging">
    <tabset type="pills" class="m-1">
        <tab>
            <ng-template tabHeading> <i class="fa fa-comment-o" aria-hidden="true"></i> Messaging </ng-template>
            <div class="row">
                <div class="col-lg-8">
                    <span style="margin-left: 1rem">To</span>
                    <div>
                        <form [formGroup]="sendEmailFG">
                            <div class="form-group col-12">
                                <!-- {{allUserData | json}} -->
                                <ng-select formControlName="emails" placeholder="Select Name" multiple="true"
                                    [clearable]="true" *ngIf="allUsers$ | async as user">
                                    <ng-template ng-header-tmp>
                                        <input type="checkbox" id="selectAllEmails" name="selectAllEmails"
                                            [(ngModel)]="isSelectAllEmails" [ngModelOptions]="{ standalone: true }"
                                            (change)="selectAllEmails(user.data, clientlist)" />
                                        <label class="mr-2" for="selectAllEmails">Select All</label>
                                        <input type="checkbox" id="selectAllClients" name="selectAllClients"
                                            [(ngModel)]="isSelectAllClientEmails"
                                            [ngModelOptions]="{ standalone: true }"
                                            (change)="selectAllClient(clientlist)" />
                                        <label for="selectAllClients">Select All Client</label>
                                    </ng-template>
                                    <ng-option class="option-list" *ngFor="let x of user.data" [value]="x">{{
                                        x.firstName }} {{ x.lastName }} - {{ x.email }}</ng-option>
                                    <ng-option class="option-list" *ngFor="let x of clientlist" [value]="x">{{
                                        x.firstName }} {{ x.lastName }} - {{ x.email }}</ng-option>
                                </ng-select>
                            </div>
                            <!-- <div *ngIf="(allUsers$ | async) as user"></div> -->
                            <div class="form-group col-12">
                                <input type="text" class="form-control" placeholder="subject"
                                    formControlName="subject" />
                            </div>
                            <div class="form-group col-12">
                                <textarea class="form-control" placeholder="type your message here"
                                formControlName="text" name="message" maxlength="160" rows="3" required [(ngModel)]="emailMsgText" (ngModelChange)="valueChangeMsg(emailMsgText)"></textarea>
                                <span>{{ remainingTextMsg }}/160 Char left</span>
                                <button class="btn btn-primary float-right" type="submit"
                                    (click)="sendEmail()">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-4 mt-md-2">
                    <ul class="list-group" *ngIf="(emailHistory$ | async) as email">
                        <li class="list-group-item active">Emails History</li>
                        <li class="list-group-item custom-list" *ngFor="let x of email?.result?.data">
                            <h6>{{x?.userName}} ({{x?.akcessId ? x?.akcessId : "No AKcess Id"}})</h6>
                            <p>{{x?.subject}}</p>
                        </li>
                        <p *ngIf="email?.result?.data.length  == 0" class="text-center">No Data Found</p>
                    </ul>
                    <small class="float-right" style="cursor: pointer;" (click)="seeAllHistory('email')">See Full Screen</small>
                </div>
            </div>
        </tab>
        <tab>
            <ng-template tabHeading> <i class="fa fa-mobile" aria-hidden="true"></i> Push Notification </ng-template>
            <div class="row">
                <div class="col-lg-8">
                    <span style="margin-left: 1rem">To</span>
                    <form [formGroup]="pushNotificationFG">
                        <div class="col-12">
                            <ng-select formControlName="akcessIds" placeholder="Select AKcessID" multiple="true"
                                [clearable]="true" *ngIf="allUsers$ | async as user">
                                <ng-template ng-header-tmp>
                                    <input type="checkbox" id="selectAll" name="selectAll"
                                        [(ngModel)]="isSelectAllAkcessId" [ngModelOptions]="{ standalone: true }"
                                        (change)="selectAll(user.data)" />
                                    <label for="selectAll">Select All</label>
                                </ng-template>
                                <ng-option class="option-list" *ngFor="let x of user.data" [value]="x.mail">{{
                                    x.firstName }} {{ x.lastName }} - {{ x.akcessId }}</ng-option>
                            </ng-select>
                            <div class="valid-feedback">Type AKcessID and press ENTER to validate</div>
                        </div>

                        <div class="form-group col-12">
                            <input type="text" placeholder="Subject" class="form-control" formControlName="title" />
                        </div>

                        <div class="form-group col-12">
                            <textarea class="form-control" id="msg" placeholder="type your message here" [(ngModel)]="notificationMsgText" (ngModelChange)="valueChange(notificationMsgText)" name="msg"
                                formControlName="description" maxlength="160" rows="3" required></textarea>
                            <span>{{ remainingText }}/160 Char left</span>
                            <button class="btn btn-primary float-right" type="submit"
                                (click)="sendPushNotification()">Send</button>
                        </div>
                    </form>
                </div>
                <div class="col-lg-4 mt-md-2">
                    <ul class="list-group" *ngIf="(pushHistory$ | async) as notification">
                        <li class="list-group-item active">Notifications History</li>
                        <li class="list-group-item" *ngFor="let x of notification?.result?.data">
                            <h5>{{x?.userName}} ({{x.akcessId}})</h5>
                            <span>{{x?.subject}}</span>
                        </li>
                        <p *ngIf="notification?.result?.data.length  == 0" class="text-center">No Data Found</p>
                    </ul>
                    <small class="float-right" (click)="seeAllHistory('notification')">See Full Screen</small>
                </div>
            </div>
        </tab>
    </tabset>
</div>