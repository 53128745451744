<!-- View Sent eForm Modal-->
<div class="modal-content view-sent-eForm-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewSentModalLabel">{{formName}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <input type="hidden" [(ngModel)]="dataId" value="{{dataId}}" />
        <input type="hidden" [(ngModel)]="sentType" value="{{sentType}}" />
        <input type="hidden" [(ngModel)]="formName" value="{{formName}}" />
        <div class="row mb-3">
            <div class="col-5">
                <input type="text" class="form-control" id="search" placeholder="Search by {{selectedTab}}"
                    [(ngModel)]="searchValue"
                    (change)="getViewSentEform(selectedTab == 'AKcess Id'? 'akcessid' : selectedTab == 'Email'? 'email' : 'phone')">
            </div>
        </div>
        <div class="modal-tab">
            <tabset [justified]="true">
                <tab heading="AKcess Id" [active]="true" (selectTab)="getViewSentEform('akcessid')">
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableViewById="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>AKcess Id</th>
                                    <th>Status</th>
                                    <th>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="eformsents?.length == 0">
                                <tr>
                                    <td colspan="3" class="text-center">No sent eForms Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="eformsents?.length > 0">
                                <ng-container
                                    *ngFor="let data of eformsents | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ;let i = index;">
                                    <tr *ngIf="data.akcessId && !data.emailFlag && !data.phoneFlag">
                                        <td>
                                            <span *ngIf="data?.firstName && data?.lastName">{{data.firstName}}
                                                {{data.lastName}}</span>
                                            <span *ngIf="!data?.firstName && !data.lastName">-</span>
                                        </td>
                                        <td>
                                            {{data?.akcessId}}
                                        </td>
                                        <td>
                                            {{(data?.received) ? 'Received' : 'Sent'}}
                                        </td>
                                        <td>
                                            {{data.date | date}}
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="3">
                                        <div class="table-page" *ngIf="totalCount > 10">
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="onProjectPageChange($event,'akcessid')"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value,'akcessid')"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
                <tab heading="Email Address" (selectTab)="getViewSentEform('email')">
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="eformsentsEmail?.length == 0">
                                <tr>
                                    <td colspan="2" class="text-center">No sent eForms Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="eformsentsEmail?.length > 0">
                                <tr
                                    *ngFor="let data of eformsentsEmail | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ;let i = index;">
                                    <td>
                                        <span *ngIf="data?.firstName && data?.lastName">{{data.firstName}}
                                            {{data.lastName}}</span>
                                        <span *ngIf="!data?.firstName && !data.lastName">-</span>
                                    </td>
                                    <td>
                                        {{data?.email}}
                                    </td>
                                    <td>
                                        {{(data?.received) ? 'Received' : 'Sent'}}
                                    </td>
                                    <td>
                                        {{data.date | date}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="2">
                                        <div class="table-page" *ngIf="totalCount > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event,'email')"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value,'email')"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
                <tab heading="Mobile Number" (selectTab)="getViewSentEform('phone')">
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Mobile</th>
                                    <th>Status</th>
                                    <th>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="eformsentsPhone?.length == 0">
                                <tr>
                                    <td colspan="2" class="text-center">No sent eForms Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="eformsentsPhone?.length > 0">
                                <tr
                                    *ngFor="let data of eformsentsPhone | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ;let i = index;">
                                    <td>
                                        <span *ngIf="data?.firstName && data?.lastName">{{data.firstName}}
                                            {{data.lastName}}</span>
                                        <span *ngIf="!data?.firstName && !data.lastName">-</span>
                                    </td>
                                    <td>
                                        {{data?.phone}}
                                    </td>
                                    <td>
                                        {{(data?.received) ? 'Received' : 'Sent'}}
                                    </td>
                                    <td>
                                        {{data.date | date}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="2">
                                        <div class="table-page" *ngIf="totalCount > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event,'phone')"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value,'phone')"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">
            Close
        </button>
    </div>
</div>