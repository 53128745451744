import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from '../../../../_services/dataservices.service';
import { RefreshContentService } from '../../../../_services/refresh-content.service';

@Component({
    selector: 'app-employee-deleted',
    templateUrl: './employee-deleted.component.html',
    styleUrls: ['./employee-deleted.component.css']
})
export class EmployeeDeletedComponent implements OnInit {
    userDeletedlist: any = [];

    //Pagination
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;

    empSearch: any = "";
    constructor(
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        public refreshContentService: RefreshContentService,
    ) { }

    ngOnInit(): void {
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                // this.getuserData(true);
                this.getDeletedEmployeeList();

            } else {
                // this.getuserData(true);
                this.getDeletedEmployeeList();

            }
        });
    }

    getDeletedEmployeeList() {
        this.spinner.show();
        this.calldata.getDeletedList('employee', this.empSearch).subscribe(
            (res: any) => {
                if (res.status) {
                    if (this.empSearch == "") {
                        this.userDeletedlist = res.data.reverse();
                    } else {
                        this.userDeletedlist = res.data;
                    }
                    this.spinner.hide();
                } else {
                    this.userDeletedlist = [];
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //Pagination
    onProjectPageChange(event) {
        this.currentPage = event;
        // this.getuserData(true);
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        // this.getuserData(true);
    }

    //Delete Permenatly
    deletePermantly() {

        this.spinner.show();
        let deleteIDs = [];
        let deleteAkcessId = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => {
                deleteIDs.push(item.getAttribute("id"))

            });
        if (deleteIDs.length > 0) {
            if (confirm("ARE YOU SURE YOU WANT TO PERMANENTLY DELETE THE SELECTED ITEM(S)?")) {
                for (let id of deleteIDs) {
                    const data: any = {
                        component: "employee",
                        _id: id,
                        userType: "Employee",
                    };
                    this.userDeletedlist.filter((e) => {
                        if (e._id == id) {
                            data.akcessId = e.akcessId
                        }
                    })
                    this.calldata.forceDeleteData(data).subscribe(
                        (res: any) => {
                            if (res.status) {
                                this.getDeletedEmployeeList();
                                this.toast.success("Employees " + res.message);
                            } else {
                                this.toast.info("Employees " + res.message);
                                this.spinner.hide();
                            }
                        }, (err) => {
                            this.spinner.hide();
                            this.toast.error("Oops, something went wrong. Please try again.")
                        }
                    );
                }
            }
            this.spinner.hide();
        }
        else {
            this.toast.error("Select an employee");
            this.spinner.hide();
        }
    }

    //Restore Deleted
    restoreDeleted() {
        this.spinner.show();
        let restoreIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => restoreIDs.push(item.getAttribute("id")));

        if (restoreIDs.length > 0) {
            for (let id of restoreIDs) {
                const data = {
                    component: "employee",
                    _id: id
                };
                //this.calldata.restoreEmployee(data).subscribe(
                //    (res: any) => {
                //        if (res.status) {
                //            this.getDeletedEmployeeList();
                //            this.toast.success(res.message);
                //        } else {
                //            this.toast.info(res.message);
                //            this.spinner.hide();
                //        }
                //    },
                //    (error) => {
                //        this.toast.error("Employee not restored");
                //        this.spinner.hide();
                //    }
                //);

                this.calldata.restoreDeletedData(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getDeletedEmployeeList();
                            this.toast.success("Employees " + res.message);
                            this.spinner.hide();
                        } else {
                            this.toast.info("Employees " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select an employee");
            this.spinner.hide();
        }
    }
}
