import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-attendance-report",
  templateUrl: "./attendance-report.component.html",
  styleUrls: ["./attendance-report.component.css"],
})
export class AttendanceReportComponent implements OnInit {
  searchName: string;
  currentPage: any = 1;
  totalCount: any = 0;
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
  pageSize: any = 10;
  attendanceList: any = [];
  responseId: any;
  pullDataStatus: any = "";

  constructor(
    private spinner: NgxSpinnerService,
    private ds: DataservicesService,
    private gs: GeneralServiceService,
    private toast: ToastrService,
    private refreshContentService: RefreshContentService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    let me = this;
    this.route.queryParams.subscribe((params) => {
      me.searchName = params.search ? params.search : "";
    });
  }

  ngOnInit(): void {
    this.getAttendanceReports();
  }

  getAttendanceReports() {
    this.spinner.show();
    const params = {
      search: this.searchName,
      page: this.currentPage,
      limit: this.pageSize,
    };

    this.ds.getAttendanceList(params).subscribe((res: any) => {
      if (res.status) {
        this.attendanceList = res.data.list;
        this.totalCount = res.data.total;
        this.toast.success(res.message);
        this.spinner.hide();
      } else {
        this.toast.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
  });
  }

  searchAttendanceReports(type: string, value: string) {
    this.getAttendanceReports();
  }

  viewRecycle() {}

  onProjectPageChange(event) {
    this.currentPage = event;
    this.getAttendanceReports();
  }

  changePage(value) {}
}
