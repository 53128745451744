import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/_services/common.service';
import { Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;

@Component({
    selector: 'app-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit {

    url;
    tmpeform;
    userDatamodel;
    akcessIDChips: any = [];
    saveuserData: any = [];
    userData: any = [];
    fileUrl: any;

    selectedDocument: any;

    userDataList$: Observable<any[]>;


    //ng-select variable
    selectSubmitDisabled: boolean = false;

    constructor(
        private toast: ToastrService,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
        public commonService: CommonService,
        private _bsModalRef: BsModalRef,
        private modalService: BsModalService
    ) { }

    ngOnInit(): void {
        this.getSearchUserData();
        this.selectedDocument = JSON.parse(this.selectedDocument);
        //if (window.history.state.hasOwnProperty("selectedDocument")) {
        //    this.selectedDocument = window.history.state.selectedDocument
        //        ? window.history.state.selectedDocument
        //        : {};
        //    sessionStorage.setItem("selectedDocument", JSON.stringify(this.selectedDocument));
        //} else {
        //    this.selectedDocument = JSON.parse(sessionStorage.getItem("selectedDocument"));
        //}
        this.fileUrl = {
            url: this.selectedDocument.fileUrl,
            withCredentials: false,
            // httpHeaders: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
        }

    }

    addTagFn(name) {
        return { name: name, tag: true };
    }


    shareDocument() {

        let data = {
            fileName: this.selectedDocument.file_name,
            pdf_url: this.selectedDocument.file_url,
            description: "Document",
            documentId: this.selectedDocument.document_ID,
            receivers: this.akcessIDChips[0],
            AkcessID: this.selectedDocument.akcessID,
            domainName: this.commonService.selectedCompanyDeatils.domain || "sme.akcess.app",
            apiKey: environment.apiKey,
            akcessToken: sessionStorage.getItem('token'),
            expiry_date: "2021-01-23T10:02:49.024Z",
            isDocument: true,
            isIdCard: false
        }
        this.spinner.show();

        this.calldata.shareDocument(data).subscribe(res => {
            if (res['status'] == true) {
                this.toast.success("Success");
                //$('#sharedModal').modal('hide');
                this._bsModalRef.hide();
                this.spinner.hide();
            } else {
                this.toast.info(res['message']);
                //$('#sharedModal').modal('hide');
                this._bsModalRef.hide();
                this.spinner.hide();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    openSendEform(val) {
        this.userDatamodel = '';
        this.tmpeform = val
    }

    pushAkcessData(e) {
        this.akcessIDChips.push(e.value.split(' ')[0])
    }
    removeAkcessData(e) {
        let indexOfEmail = this.akcessIDChips.findIndex(e => e == e.value)
        this.akcessIDChips.splice(indexOfEmail, 1);
    }

    getSearchUserData() {
        this.calldata.getuserlist_search().subscribe(
            res => {
                this.saveuserData = res['data'];
                res['data'].forEach((val, i) => {
                    var email = (val['email'] == 'undefined' || val['email'] == '' || val['email'] == null) ? 'No Email' : val['email'];
                    var phone = (val['phone'] == 'undefined' || val['phone'] == null || val['phone'] == '') ? 'No Mobile Number' : val['phone'];
                    var ackID = val['akcessId'];
                    var jointName = ackID + ' ' + email + ' ' + phone
                    this.userData.push(jointName);
                });
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
    }

    /**
  * Clear Data
  */
    ngOnDestroy() {
        sessionStorage.removeItem("selectedDocument");
    }

    openSharedModal(sharedModal: TemplateRef<any>) {
        this._bsModalRef = this.modalService.show(sharedModal,
            {
                animated: true,
            });
    }

    onModalOpen() {
        this.userDataList$ = this.userData;
    }


    closeModal() {
        this._bsModalRef.hide();
    }


    //ng-select
    onOpenDropdown() {
        this.selectSubmitDisabled = true;
    }

    onCloseDropdown() {
        this.selectSubmitDisabled = false;
    }
}
