<!--eForm Clone Modal -->
<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title">Clone eForm</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label class="col-4 col-form-label font-500">eForm Name</label>
            <div class="col-8">
                <input type="text" [(ngModel)]="eformCopyName" name="cloneEform" class="form-control"
                    id="eformIdForClone">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-4 col-form-label font-500">Clone eForm To</label>
            <div class="col-8">
                <select (change)="selectCompany($event)" class="form-control">
                    <option value="">Select Company</option>
                    <option *ngFor="let company of activeCompany" [value]="company._id">{{company.name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-info" (click)="addClone()"
            [disabled]="selectedCompany == ''">Clone eForm</button>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">Close</button>
    </div>
</div>