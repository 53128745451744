<!-- Login Page -->
<div class="login-page">
    <div class="container">
        <div class="form-outer text-center d-flex align-items-center">
            <div class="card w-100">
                <div class="card-body">
                    <div class="image-section mb-3">
                        <a href="../index.html">
                            <!--<img src="../assets/img/aub-logo.png" />
                            <img src="{{portalLogo}}" class="navbar-brand-img" />-->
                            <!--<img [src]="imageurl" class="navbar-brand-img" alt="{{portalLabel}}" title="{{portalLabel}}">-->
                            <img src="../assets/img/akcess-sme-logo.png" class="navbar-brand-img" alt="{{portalLabel}}" title="{{portalLabel}}">
                        </a>
                    </div>
                    <div class="welcome text-uppercase">Welcome</div>
                    <p>Sign in with QR code</p>
                    <div class="qr-section" *ngIf="!scanstatus">
                        <img class="loader-img mt-2" *ngIf="!qrcodeData" src="./assets/img/loading.gif" />
                        <qrcode *ngIf="qrcodeData!=''" [qrdata]="qrcodeData" [size]="256" [level]="'M'"></qrcode>
                    </div>
                    <span style="color:grey;text-align:center;font-size:x-small;">Please scan the QR code using your AKcess application<br> to login.
                    </span>
                    <div class="form-section mt-3" *ngIf="scanstatus">
                        <div class="form-group">
                            <input type="password" class="form-control" [(ngModel)]="passcode" name="passcode" placeholder="Enter OTP" />
                        </div>
                        
                        <div class="text-danger text-center mb-3 small">OTP has been sent to your {{mobile_email}}</div>
                        <div>
                            <button type="button" (click)="clickpasscode()" class="btn btn-primary">Enter</button>
                        </div>
                    </div><br/><br/>
                    <div class="mt-3">
                        <div class="position-relative">
                            <hr />
                            <p class="new-user-p">New to AKcess SME?</p>
                        </div>
                        <button type="button" class="btn btn-sm waves-effect waves-light btn-info mt-3" [routerLink]="['/register']">Create your AKcess Account</button>
                    </div>
                </div>
            </div>

            <div class="copyrights text-center">
                <p>
                    Copyright © {{currentYear}} AKcess Labs. All rights reserved
                </p>
            </div>
        </div>
    </div>
</div>