import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../../../_services/common.service";
import { DataservicesService } from "../../../../_services/dataservices.service";
import { EformresponseExpiryDateComponent } from "../eformresponse-expiry-date/eformresponse-expiry-date.component";
import { EformresponseGradeComponent } from "../eformresponse-grade/eformresponse-grade.component";
import { RefreshContentService } from "../../../../_services/refresh-content.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-eformresponse-view",
  templateUrl: "./eformresponse-view.component.html",
  styleUrls: ["./eformresponse-view.component.css"],
})
export class EformresponseViewComponent implements OnInit {
  bsModalRef: BsModalRef;
  isChecked: boolean;
  val: number;
  fullname: string;
  _id: string;
  akcessId: string;
  eformId: any;
  fields = [];
  eformResponseEformId: any;
  eformStatus: string = "";
  device_token: string;
  mobile_local_id: any;
  facematch: any;
  faceMatchPic: any;
  faceMatchPicname: any;
  form_file: any;
  form_domain: any;
  formname: any;
  isFacialMatched: any;

  chk: any;

  eformresponse = [];
  userAkcessID: any;
  responseId: any;

  eFormverifyCheck: any = [];
  verifiedItems: Array<String> = [];
  imageUrl: string = environment.imgurl;
  portalUser: any;
  orderByFilter: any = "nto";
  search_formName: any = "";
  pullDataStatus: any = "";
  portaldata: any;

  imageUrlsForPDF = [];

  //imageUrlsForPDF = [];
  //minDate: Date;
  //maxDate: Date;

  //fieldVerifierData: any;
  //graderLoader: boolean = false;
  //gradedata = [];
  //expiryDateIndex: any;
  //eformDataWithForExpiryDate: any;

  //pagination
  currentPage: any = 1;
  totalCount: any = 0;
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
  pageSize: any = 10;
  isVerified: boolean;
  StatusDescription: any = {
    alive: "Alive",
    pending: "Pending",
    createclient:
      "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
    createClient:
      "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
    Accept:
      " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
    accept:
      " When you apply 'accept' action, user will receive a notification that you accepted his response. The eform response will be accepted without any verification",
    "update Client Field":
      "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
    "update client field":
      "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
    "verify and Accept":
      "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
    "verify and accept":
      "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
    Return:
      "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
    return:
      "When you choose to apply 'return' action, user will have to re-fill and re-submit the eform.",
    Reject:
      "When you apply 'reject' action, user will receive a notification that you rejected his response",
    reject:
      "When you apply 'reject' action, user will receive a notification that you rejected his response",
  };

  constructor(
    private _bsModalRef: BsModalRef,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    public commonService: CommonService,
    private calldata: DataservicesService,
    public modalService: BsModalService,
    private refreshContentService: RefreshContentService
  ) { }

  ngOnInit(): void {
    this.responseId = this._id;
    this.val = this.val;
    this.userAkcessID = this.akcessId;
    this.eformresponse = this.eformresponse;

    //this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    this.verifiedItems = [];
    this.eFormverifyCheck = [];
    this.getEformResponseData();
  }

  closeModal() {
    this._bsModalRef.hide();
  }

  //Fetch eFormResponse Data
  async getEformResponseData() {
    this.fields = [];
    try {
      this.eformresponse[this.val].fields.map((element, index) => {
        let options = JSON.parse(element.options[0]);
        let sec = element.sectionfields ? element.sectionfields : "{}";
        this.eformresponse[this.val].fields[index]["optionsData"] = options;
        options.forEach((option, i) => {
          if (
            JSON.parse(sec)[option.lable] &&
            JSON.parse(sec).hasOwnProperty(option.lable)
          ) {
            this.eformresponse[this.val].fields[index]["optionsData"][i][
              "moreFields"
            ] = JSON.parse(sec)[option.lable];
          }
        });
      });
    } catch (error) { }

    this.responseId = "";
    this.responseId = this._id;
    var fullname = fullname;
    this.userAkcessID = "";
    this.userAkcessID = this.akcessId;

    var a = "";
    if (this.eformresponse[this.val].facialMatch == true) {
      this.isFacialMatched = "true";
    } else if (this.eformresponse[this.val].facialMatch == false) {
      this.isFacialMatched = "false";
    } else {
      this.isFacialMatched = null;
    }

    this.pullDataStatus = this.eformresponse[this.val].pulldata;
    this.form_file = this.eformresponse[this.val].eformasfile;
    this.form_domain = this.eformresponse[this.val].name;
    this.formname = this.eformresponse[this.val].formName;
    this.facematch = this.eformresponse[this.val].facematch
      ? this.eformresponse[this.val].facematch
      : "no";
    this.faceMatchPic =
      this.eformresponse[this.val].faceMatchPic &&
        this.eformresponse[this.val].faceMatchPic.path
        ? this.eformresponse[this.val].faceMatchPic.path
        : "";
    this.faceMatchPicname =
      this.eformresponse[this.val].faceMatchPic &&
        this.eformresponse[this.val].faceMatchPic.path
        ? this.eformresponse[this.val].faceMatchPic.fieldname
        : "";

    let data = this.eformresponse[this.val].fields;

    await data.forEach(async (element) => {
      if (element.isverified) {
        this.eFormverifyCheck.push(element._id);
      }
    });

    for (var i = 0; i < data.length; i++) {
      a = data[i].key;
      data[i].val = a;

      data["fullname"] = fullname;
      if (data[i].keytype == "file" || data[i].keytype == "File") {
        data[i].path =
          data[i] && data[i].file && data[i].file.path
            ? data[i].file.path
            : null;
        this.imageUrlsForPDF.push(this.imageUrl + data[i].path);
      }

      if (data[i].isverified) {
        this.verifiedItems.push(data[i]._id);
      } else {
      }
    }

    data["eFormResponseId"] = this._id;
    data["eformId"] = this.eformresponse[this.val].eformId;
    this.eformResponseEformId = this.eformresponse[this.val].eformId;
    this.eformStatus = this.eformresponse[this.val].status;
    this.device_token = this.eformresponse[this.val].device_token;
    this.mobile_local_id = this.eformresponse[this.val].mobile_local_id;
    data["akcessId"] = this.akcessId;
    data["email"] =
      this.eformresponse[this.val] && this.eformresponse[this.val].email
        ? this.eformresponse[this.val].email
        : "";
    data["mobile"] =
      this.eformresponse[this.val] && this.eformresponse[this.val].mobile
        ? this.eformresponse[this.val].mobile
        : "";

    this.fields = data;

  }

  //parseMoreData
  parseMoreData(value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return [];
    }
  }

  //Fetch data from document
  fetchDataFromDoc(data) {
    let object = {
      id: this.responseId,
      path: data.path,
      facematch: this.facematch,
      faceMatchPic: this.faceMatchPic,
      docuementType: data.docuementType,
    };

    this.spinner.show();

    this.calldata.updateFieldsFromDocument(object).subscribe(
      (res: any) => {
        if (res.success) {
          if (res.facematch) {
            this.toast.success("Selfie Matched with document");
          }

          this.toast.success(res.message);
          this.geteformresponse(null);
          //$('#viewform').modal('hide')
          this.closeModal();
          this.spinner.hide();
        } else {
          this.toast.error(res.message);
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      }
    );
  }

  geteformresponse(status: string) {
    this.eformresponse = [];
    this.spinner.show();
    this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    const tmpdata = {
      akcessId: this.portalUser.akcessId,
      usertype: this.portalUser.usertype,
      orderBy: this.orderByFilter,
      currentPage: this.currentPage,
      limit: this.pageSize,
    };


    if (this.eformId != null) {
      tmpdata["eformId"] = this.eformId;
    }

    if (status && status.length) tmpdata["status"] = status;

    if (this.search_formName && this.search_formName.length)
      tmpdata["search_formName"] = this.search_formName;

    tmpdata["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    // alert(JSON.stringify(tmpdata))
    this.calldata.fetchresponse(tmpdata).subscribe(
      (res: any) => {
        if (res.status) {
          this.eformresponse = res.data.data;
          this.totalCount = res.data.totalCount;
          this.spinner.hide();
        } else {
          this.toast.info(res["msg"]);
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      }
    );
  }

  setVerifiedItem(evt, i, v): void {
    
    let id = this.fields[i]._id;
    if (evt.target.checked) {
      // this.eFormverifyCheck.push(id);
      // this.verifiedItems.push(id);
      this.fields.filter((el) => {
        if (el._id == v) {
          el.isChecked = true;
        }

        // this.isVerified = true;
      })
    } else {
      this.fields.filter((el) => {
        if (el._id == v) {
          el.isChecked = false;
        }

        // this.isVerified = true;
      })
      // this.eFormverifyCheck.splice(this.verifiedItems.indexOf(id), 1);
      // this.verifiedItems.splice(this.verifiedItems.indexOf(id), 1);
    }
  }

  //openExpiryDate(index) {
  //    this.expiryDateIndex = null;
  //    this.expiryDateIndex = index;
  //    this.eformDataWithForExpiryDate = {};
  //    this.eformDataWithForExpiryDate = this.fields[index];
  //}

  validDateFormat(val) {
    let split = val.split("/");
    if (split.length == 3) {
      return new Date(`${split[1]}/${split[0]}/${split[2]}`);
    } else {
      return new Date(val);
    }
  }

  onSubmit() {
    this.spinner.show();
    let vdata = [];

    // this.fields.forEach((field) => {
    //   var isPresent = this.verifiedItems.some(function (el) {
    //     return el === field._id;
    //   });
    //   if (isPresent) {
    //     vdata.push(field);
    //   }
    // });
    if (!this.eformStatus) {
      this.toast.error("Please select status");
      return false;
    } else {
      this.portaldata =
        this.commonService;
       console.log("this.portaldata",this.portaldata);
        
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
      let approved;

      if (this.eformStatus == "verify and accept") {
        approved = true;
      } else {
        approved = false;
      }

      let reqData = {
        eFormResponseId: this.fields["eFormResponseId"],
        fields_form: this.verifiedItems,
        eformsFields: this.fields,
        eformStatus: this.eformStatus,
        akcessId: this.portalUser.akcessId,
        formName: this.formname,
        status: "alive",
        date: new Date(),
        approved: approved,
        eformId: this.eformResponseEformId,
        akcessVerification: true,
        akcessVerificationStatus: true,
        akcessVerificationStep: { type: "ready" },
        device_token: this.device_token,
        portalTitle: this.commonService.portalDetails.portalDetails.portalTitle,
        databaseURL: this.portaldata.setting.databaseURL,
      };

      this.calldata.verifyfield(reqData).subscribe(
        (data: any) => {
          if (data.status) {
            if (
              reqData.eformStatus == "update client field" ||
              reqData.eformStatus == "createclient" ||
              reqData.eformStatus == "Update Client Field" ||
              reqData.eformStatus == "createClient"
            ) {
              this.updateClientForm(this.fields);
            } else if (reqData.eformStatus == "update employee field") {
              this.updateEmployeeForm(this.fields);
            } else {
            }

            let fieldsToVerify = [];
            let expiryDateArray = [];

            if (reqData.eformsFields.length > 0) {
              reqData.eformsFields.forEach((element) => {
                if (element.keytype == "file") {
                  //this.spinner.show();
                  let object = {
                    userAkcessId: this.userAkcessID,
                    documentId: element.value,
                    expiryDate: element.expiryDate,
                  };
                  this.calldata.docVerifyField(object).subscribe((res: any) => {
                    if (res.status == 1) {
                      this.toast.success(res.data.message);
                      this.closeModal();
                      // $("#viewform").modal("hide");

                      //this.geteformresponse(null);
                      this.refreshContentService.onEformResponseStatusUpdate();
                    } else {
                      this.toast.error(res.message);
                      this.closeModal();
                      // $("#viewform").modal("hide");

                      //this.geteformresponse(null);
                      this.refreshContentService.onEformResponseStatusUpdate();
                    }
                  });
                } else {
                  expiryDateArray.push(element.expiryDate);
                  fieldsToVerify.push(element.key);
                }
              });

              if (fieldsToVerify.length > 0) {
                let object = {
                  userAkcessId: this.userAkcessID,
                  profileField: fieldsToVerify,
                  expiryDate: expiryDateArray,
                };

                //this.spinner.show();

                this.calldata
                  .VerifyField(object)
                  .subscribe(async (res: any) => {
                    if (res.status == 1) {
                      this.toast.info(data.message);
                      this.toast.success(res.data.message);
                      this.closeModal();
                      //$("#viewform").modal("hide");

                      //this.geteformresponse(null);
                      this.refreshContentService.onEformResponseStatusUpdate();
                      this.spinner.hide();
                    } else {
                      this.toast.info(data.message);
                      this.toast.error(res.message);
                      this.closeModal();
                      //$("#viewform").modal("hide");

                      //this.geteformresponse(null);
                      this.refreshContentService.onEformResponseStatusUpdate();
                      this.spinner.hide();
                    }
                  }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                  });
              }
            } else {
              this.toast.info(data.message);
              this.closeModal();
              //$("#viewform").modal("hide");

              //this.geteformresponse(null);
              this.refreshContentService.onEformResponseStatusUpdate();
            }
            this.spinner.hide();
          } else {
            this.toast.info(data.message);
            this.spinner.hide();
          }
        },
        (error) => {
          //this.toast.error("something went wrong");
          this.toast.error(error);
          this.spinner.hide();
        }
      );
    }
  }

  updateClientForm(payload) {
    let bodyData: any = {
      akcessId: payload["akcessId"],
    };

    payload.forEach((element) => {
      if (element.key == "occupation") bodyData.occupation = element.value;
      if (element.key == "education") bodyData.education = element.value;
      if (element.key == "university")
        bodyData.studentAtUniColl = element.value;
      if (element.key == "position") bodyData.rollPosition = element.value;
      if (element.key == "salary") bodyData.salary = element.value;
      if (element.key == "nationality") bodyData.nationality = element.value;
      if (element.key == "gender") bodyData.gender = element.value;
      if (element.key == "zipcode") bodyData.zipCode = element.value;
      if (element.key == "floor") bodyData.floor = element.value;
      if (element.key == "city") bodyData.city = element.value;
      if (element.key == "pooffice") bodyData.postOffice = element.value;
      if (element.key == "block") bodyData.block = element.value;
      if (element.key == "area") bodyData.area = element.value;
      if (element.key == "street") bodyData.street = element.value;
      if (element.key == "avenue") bodyData.aveneu = element.value;
      if (element.key == "faxnumber") bodyData.faxNo = element.value;
      if (element.key == "language") bodyData.language = element.value;
      if (element.key == "birthplace") bodyData.birthPlace = element.value;
      if (element.key == "passportnumber")
        bodyData.passportNumber = element.value;
      if (element.key == "passportexpirydate")
        bodyData.passportExpDate = this.validDateFormat(element.value);
      if (element.key == "civilidnumber")
        bodyData.civilIdNumber = element.value;
      if (element.key == "arabiclastname")
        bodyData.lastNameArabic = element.value;
      if (element.key == "arabicfirstname")
        bodyData.firstNameArabic = element.value;
      if (element.key == "civilidexpirydate")
        bodyData.civilIDExpDate = this.validDateFormat(element.value);
      if (element.key == "email") bodyData.email = element.value;
      if (element.key == "mobile") bodyData.mobile = element.value;
      if (element.key == "firstname") bodyData.firstName = element.value;
      if (element.key == "lastname") bodyData.lastName = element.value;
      if (element.key == "workphone") bodyData.officePhone = element.value;
      if (element.key == "address") bodyData.address = element.value;
      if (element.key == "workaddress") bodyData.workAddress = element.value;
      if (element.key == "employer") bodyData.employerName = element.value;
      if (element.key == "graduationdate")
        bodyData.graduationDate = this.validDateFormat(element.value);
      if (element.key == "maritalstatus")
        bodyData.maritalStatus = element.value;
      if (element.key == "dateofbirth")
        bodyData.birthDate = this.validDateFormat(element.value);
      if (element.key == "homephone") bodyData.phone = element.value;
      if (element.key == "cvdno") bodyData.CVDNo = element.value;
      if (element.key == "kdaccountbalance")
        bodyData.KDAccountBalance = element.value;
      if (element.key == "usdaccountbalance")
        bodyData.USDAccountBalance = element.value;
      if (element.key == "products") bodyData.clientProducts = element.value;
      if (element.key == "unit") bodyData.unit = element.value;
      if (element.key == "pobox") bodyData.poBox = element.value;
      if (element.key == "buildingplot") bodyData.buildingPlot = element.value;
      if (element.key == "recruitmentdate")
        bodyData.recruitmentDate = this.validDateFormat(element.value);
    });

    this.calldata
      .updateClientDataWithReverseOnBoarding(bodyData)
      .subscribe((data: any) => {
        if (data.status) {
          this.toast.success(data["message"]);
        } else {
          this.toast.error(data["message"]);
        }
      });
  }

  updateEmployeeForm(payload) {
    let bodyData: any = {
      akcessId: payload["akcessId"],
    };

    payload.forEach((element) => {
      if (element.key == "occupation") bodyData.occupation = element.value;
      if (element.key == "education") bodyData.education = element.value;
      if (element.key == "university")
        bodyData.studentAtUniColl = element.value;
      if (element.key == "position") bodyData.rollPosition = element.value;
      if (element.key == "salary") bodyData.salary = element.value;
      if (element.key == "nationality") bodyData.nationality = element.value;
      if (element.key == "gender") bodyData.gender = element.value;
      if (element.key == "zipcode") bodyData.zipCode = element.value;
      if (element.key == "floor") bodyData.floor = element.value;
      if (element.key == "city") bodyData.city = element.value;
      if (element.key == "pooffice") bodyData.postOffice = element.value;
      if (element.key == "block") bodyData.block = element.value;
      if (element.key == "area") bodyData.area = element.value;
      if (element.key == "street") bodyData.street = element.value;
      if (element.key == "avenue") bodyData.aveneu = element.value;
      if (element.key == "faxnumber") bodyData.faxNo = element.value;
      if (element.key == "language") bodyData.language = element.value;
      if (element.key == "birthplace") bodyData.birthPlace = element.value;
      if (element.key == "passportnumber")
        bodyData.passportNumber = element.value;
      if (element.key == "passportexpirydate")
        bodyData.passportExpDate = this.validDateFormat(element.value);
      if (element.key == "civilidnumber")
        bodyData.civilIdNumber = element.value;
      if (element.key == "arabiclastname")
        bodyData.lastNameArabic = element.value;
      if (element.key == "arabicfirstname")
        bodyData.firstNameArabic = element.value;
      if (element.key == "civilidexpirydate")
        bodyData.civilIDExpDate = this.validDateFormat(element.value);
      if (element.key == "email") bodyData.email = element.value;
      if (element.key == "mobile") bodyData.mobile = element.value;
      if (element.key == "firstname") bodyData.firstName = element.value;
      if (element.key == "lastname") bodyData.lastName = element.value;
      if (element.key == "workphone") bodyData.officePhone = element.value;
      if (element.key == "address") bodyData.address = element.value;
      if (element.key == "workaddress") bodyData.workAddress = element.value;
      if (element.key == "employer") bodyData.employerName = element.value;
      if (element.key == "graduationdate")
        bodyData.graduationDate = this.validDateFormat(element.value);
      if (element.key == "maritalstatus")
        bodyData.maritalStatus = element.value;
      if (element.key == "dateofbirth")
        bodyData.birthDate = this.validDateFormat(element.value);
      if (element.key == "homephone") bodyData.phone = element.value;
      if (element.key == "cvdno") bodyData.CVDNo = element.value;
      if (element.key == "kdaccountbalance")
        bodyData.KDAccountBalance = element.value;
      if (element.key == "usdaccountbalance")
        bodyData.USDAccountBalance = element.value;
      if (element.key == "products") bodyData.clientProducts = element.value;
      if (element.key == "unit") bodyData.unit = element.value;
      if (element.key == "pobox") bodyData.poBox = element.value;
      if (element.key == "buildingplot") bodyData.buildingPlot = element.value;
      if (element.key == "recruitmentdate")
        bodyData.recruitmentDate = this.validDateFormat(element.value);
    });

    this.calldata
      .updateEmployeeDataWithReverseOnBoarding(bodyData)
      .subscribe((data: any) => {
        if (data.status) {
          this.toast.success(data["message"]);
        } else {
          this.toast.error(data["message"]);
        }
      });
  }

  openMobilePdf() {
    let file_path = this.form_file.path
      ? this.form_file.path
      : "storage/" + this.form_file.upload_name;
    let domain = "https://sme.akcess.app/api/" + file_path;
    window.open(domain, "_blank");
  }

  openExpiryDateModal(i) {
    const initialState: any = {
      index: i,
      fields: this.fields,
    };

    this.bsModalRef = this.modalService.show(EformresponseExpiryDateComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      ignoreBackdropClick: true,
      class: "modal-dialog-centered",
    });
  }

  openGradeModal(gradeData) {
    const initialState: any = {
      data: gradeData,
      userAkcessID: this.userAkcessID,
    };

    this.bsModalRef = this.modalService.show(EformresponseGradeComponent, {
      initialState,
      animated: true,
      backdrop: "static",
      ignoreBackdropClick: true,
      class: "modal-dialog-centered",
    });
  }

  allCheck: any;
  selectAllcheck(e) {
    if (e.target.checked == true) {
      this.fields.filter((el) => {
        el.isChecked = true;
        el.checked = true;
        el.isVerified = true;
        this.isVerified = true;
      })
    } else if (e.target.checked == false) {
      this.fields.filter((el) => {
        el.isChecked = false;
        el.checked = false;
        el.isVerified = false;
        this.isVerified = false;
      })
    }
  }

  //  checkedOrUncheckedAll($event){
  //      if($event.target.value)
  //      this.isverified=true;
  //      else
  //      this.isverified=false;

  // }
}
