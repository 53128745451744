import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ConstantService } from '../../../../_services/constant.service';
import { GeneralServiceService } from '../../../../_services/general-service.service';

@Component({
    selector: 'app-section-modal',
    templateUrl: './section-modal.component.html',
    styleUrls: ['./section-modal.component.css']
})
export class SectionModalComponent implements OnInit {

    sectionType: boolean;
    fields: any = [];
    section_fields: any = [];
    filter_section: any = [];
    filter_country_options: any = [];
    filter_country: string;
    grade: any = [];
    globallist: any = [];
    localList: any = [];
    tag_index: number;
    tags: any = [];
    default = false;
    field_type: string = '';
    signature_required: string = "no";
    field_verified: string = "no";
    field_mandate: string = "no";
    key: string = '';
    set_color: string;

    section_lable: string;
    section_field_lable: '';
    section_field_type: '';
    section_title: string;
    search_eForm_labels: string;
    section_tags: any = [];
    section_show: string;
    count_section_field: number = 0;
    verification_grade: string = '';

    field_type_option: any = [
        {
            "value": "text",
            "label": "String"
        },
        {
            "value": "textarea",
            "label": "Text Area"
        },
        {
            "value": "number",
            "label": "Number"
        },
        {
            "value": "password",
            "label": "Password"
        },
        {
            "value": "date",
            "label": "Date"
        },
        {
            "value": "radio",
            "label": "Radio",
            "check": this.default
        },
        {
            "value": "select",
            "label": "Select List"
        },
        {
            "value": "checkbox",
            "label": "Checkbox"
        },
    ];

    date_type_option: any = [
        {
            "value": "past",
            "label": "Past"
        },
        {
            "value": "future",
            "label": "Future"
        },
        {
            "value": "both",
            "label": "Free Entry"
        },
    ];

    date_type: string = "future";

    //Custom Field
    storeField: string = "no";


    constructor(
        private _bsModalRef: BsModalRef,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private cs: ConstantService,
        private gs: GeneralServiceService,
    ) { }

    ngOnInit(): void {
        this.filter_country_options = this.cs.countries();
        this.grade = this.cs.grade();
        this.filter_country = "null";
    }

    globalprofile() {
        this.spinner.show();
        const country = this.filter_country;
        //Get Global Fields
        this.gs.getglobalprofile(country.toLowerCase()).subscribe(data => {
            if (data['status']) {
                let tempGlobalObj = data['data'];

                //this.globallist.forEach((element, i) => {
                //    if (element.key == 'firstname') this.globallist[i].orderNo = 1
                //    if (element.key == 'lastname') this.globallist[i].orderNo = 2
                //    if (element.key == 'arabicfirstname') this.globallist[i].orderNo = 3
                //    if (element.key == 'arabiclastname') this.globallist[i].orderNo = 4
                //    if (element.key == 'civilidexpirydate') this.globallist[i].orderNo = 5
                //    if (element.key == 'civilidnumber') this.globallist[i].orderNo = 6
                //    if (element.key == 'passportexpirydate') this.globallist[i].orderNo = 7
                //    if (element.key == 'passportnumber') this.globallist[i].orderNo = 8
                //    if (element.key == 'birthplace') this.globallist[i].orderNo = 9
                //    if (element.key == 'dateofbirth') this.globallist[i].orderNo = 10
                //    if (element.key == 'nationality') this.globallist[i].orderNo = 11
                //    if (element.key == 'gender') this.globallist[i].orderNo = 12
                //    if (element.key == 'education') this.globallist[i].orderNo = 13
                //    if (element.key == 'language') this.globallist[i].orderNo = 14
                //    if (element.key == 'maritalstatus') this.globallist[i].orderNo = 15
                //    if (element.key == 'occupation') this.globallist[i].orderNo = 16
                //    if (element.key == 'recrutmentdate') this.globallist[i].orderNo = 17
                //    if (element.key == 'employer') this.globallist[i].orderNo = 18
                //    if (element.key == 'salary') this.globallist[i].orderNo = 19
                //    if (element.key == 'position') this.globallist[i].orderNo = 20
                //    if (element.key == 'university') this.globallist[i].orderNo = 21
                //    if (element.key == 'graduationdate') this.globallist[i].orderNo = 22
                //    if (element.key == 'workphone') this.globallist[i].orderNo = 23
                //    if (element.key == 'address') this.globallist[i].orderNo = 24
                //    if (element.key == 'workaddress') this.globallist[i].orderNo = 25
                //    if (element.key == 'faxnumber') this.globallist[i].orderNo = 26
                //    if (element.key == 'homephone') this.globallist[i].orderNo = 27
                //    if (element.key == 'email') this.globallist[i].orderNo = 28
                //    if (element.key == 'avenue') this.globallist[i].orderNo = 29
                //    if (element.key == 'street') this.globallist[i].orderNo = 30
                //    if (element.key == 'block') this.globallist[i].orderNo = 31
                //    if (element.key == 'area') this.globallist[i].orderNo = 32
                //    if (element.key == 'buildingPlot') this.globallist[i].orderNo = 33
                //    if (element.key == 'floor') this.globallist[i].orderNo = 34
                //    if (element.key == 'unit') this.globallist[i].orderNo = 35
                //    if (element.key == 'pooffice') this.globallist[i].orderNo = 36
                //    if (element.key == 'pobox') this.globallist[i].orderNo = 37
                //    if (element.key == 'zipcode') this.globallist[i].orderNo = 38
                //    if (element.key == 'city') this.globallist[i].orderNo = 39
                //    if (element.key == 'cvdno') this.globallist[i].orderNo = 40
                //    if (element.key == 'products') this.globallist[i].orderNo = 41
                //    if (element.key == 'usdaccountbalance') this.globallist[i].orderNo = 42
                //    if (element.key == 'kdaccountbalance') this.globallist[i].orderNo = 43
                //    if (element.key == 'mobile') this.globallist[i].orderNo = 44
                //});

                //this.globallist.sort(function (a, b) {
                //    var keyA = new Date(a.orderNo),
                //        keyB = new Date(b.orderNo);
                //    if (keyA < keyB) return -1;
                //    if (keyA > keyB) return 1;
                //    return 0;
                //});

                this.globallist = tempGlobalObj.sort(function (a, b) {
                    return compareStrings(a.labelname, b.labelname);
                });

                let custFieldData = {
                    search: "",
                    page: 1,
                    limit: 10
                }
                //Get Custom Field
                this.gs.getCustomField(custFieldData).subscribe(data => {
                    if (data['status']) {
                        let tempList = data['data'];



                        tempList.forEach(el => {
                            if (el.key != null) {
                                let tempObj = {
                                    countries: el.countries,
                                    country: "Global",
                                    date: el.updatedAt,
                                    isVisible: el.isVisible,
                                    items: el.items,
                                    key: el.key,
                                    labelname: el.labelname,
                                    section: el.section,
                                    type: el.type,
                                    _id: el._id,
                                    fieldType: 'custom'
                                }
                                this.localList.push(tempObj);
                            }

                        });

                        //Merge two arrays
                        if (this.localList.length > 0) {
                            //Concating
                            let mergedArray = this.globallist.concat(this.localList);
                            //Sort After Merging
                            this.globallist = mergedArray.sort(function (a, b) {
                                return compareStrings(a.labelname, b.labelname);
                            });
                        }
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                });

                function compareStrings(a, b) {
                    // Assuming you want case-insensitive comparison
                    a = a.toLowerCase();
                    b = b.toLowerCase();
                    return (a < b) ? -1 : (a > b) ? 1 : 0;
                }

                this.spinner.hide();
            } else {
                this.toast.info(data['message']);
                this.spinner.hide();
            }
        },
            error => {
                this.toast.error('something went wrong');
                this.spinner.hide();
            }
        );

        //Get Custom Field
        //this.gs.getCustomField().subscribe(data => {
        //    if (data['status']) {
        //        let tempList = data['data'];

        //        tempList.forEach(el => {
        //            let tempObj = {
        //                countries: el.countries,
        //                country: "Global",
        //                date: el.updatedAt,
        //                isVisible: el.isVisible,
        //                items: el.items,
        //                key: el.key,
        //                labelname: el.labelname,
        //                section: el.section,
        //                type: el.type,
        //                _id: el._id
        //            }
        //            this.localList.push(tempObj);
        //        });
        //    }
        //});
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    createSection() {
        this._bsModalRef.hide();
    }


    //Radio, Checkbox, Select
    onclick(event, i, j) {
        for (let k = 0; k < this.fields[i].options.length; k++) {
            this.fields[i].options[k]['checked'] = false
        }
        this.fields[i].options[j]['checked'] = true;
    }

    checkbox(event, i, j) {
        if (this.fields[i].options[j]['checked'] == true) {
            this.fields[i].options[j]['checked'] = false;
        }
        else if (this.fields[i].options[j]['checked'] == false) {
            this.fields[i].options[j]['checked'] = true
        }
    }

    //On Change
    onChangeEvent(i: any, e: any, tag: string) {
        if (tag === 'section') {
            this.section_fields = this.section_fields.reduce((acc, value, index) => {
                if (index === this.section_fields.findIndex((value) => (value.labelname.toString().toLowerCase() === i.toString().toLowerCase()) && value.section_title.toString().toLowerCase() === this.section_title.toString().toLowerCase())) {
                    acc.push({ ...value, field_value: e.target.value });
                } else {
                    acc.push({ ...value });
                }
                return acc;
            }, [])
            this.filtersection(this.tag_index)
        }
        //else {
        //    this.fields = this.fields.reduce((acc, value, index) => {
        //        if (index === i) {
        //            acc.push({ ...value, field_value: e.target.value });
        //        } else {
        //            acc.push({ ...value });
        //        }
        //        return acc;
        //    }, [])
        //}
    }

    //Filter Record According Section Name
    filtersection(index: number) {
        this.tag_index = index;
        this.filter_section = this.section_fields && this.section_fields.filter((value) => this.tags[index].displayValue.toString().toLowerCase() === value.section_title.toString().toLowerCase());
    }

    //Delete
    deleteele(index: any, type: string) {
        if (type === 'delfilterrec') {
            this.section_fields.splice(this.section_fields.findIndex((value) => (value.labelname.toString().toLowerCase() === index.toString().toLowerCase()) && value.section_title.toString().toLowerCase() === this.section_title.toString().toLowerCase()), 1);
            this.filter_section.splice(this.filter_section.findIndex((value) => (value.labelname.toString().toLowerCase() === index.toString().toLowerCase()) && value.section_title.toString().toLowerCase() === this.section_title.toString().toLowerCase()), 1);
        }

        //else if (type === 'all') {
        //    this.fields = this.fields.filter((value) => value.section_title ? index.toString().toLowerCase() !== value.section_title.toString().toLowerCase() : value);
        //}
        //else if (type === 'single') {
        //    this.section_fields.splice(index, 1);
        //} else {
        //    if (this.count === 0 && this.section_color !== 1) {
        //        this.count++;
        //        this.section_color--;
        //    }
        //    this.fields.splice(index, 1);
        //}
    }

    //Tags Change
    onTagsChanged(event, type) {
        if (type == 'section') {
            if (this.section_tags.filter(element => event.tag.displayValue.toLowerCase() === element.displayValue.toLowerCase()).length > 1) {
                this.section_tags.pop();
            }
        } else {
            if (this.tags.filter(element => event.tag.displayValue.toLowerCase() === element.displayValue.toLowerCase()).length > 1) {
                this.tags.pop();
            }
        }
        let index = (this.tags.length - 1)
        this.tags[index]['value'] = event.tag.displayValue.replace(/[^a-z0-9]/gi, '')
    }

    // Add section modal fields 
    addsectionfield(datatype: string, labelname: string, key: string, labletype: string, options: any = [], sectiontitle: string = '', showsection: string) {

        if (this.section_fields.find((value) => (value.labelname.toLowerCase().toString() === labelname.toString().toLowerCase()) && value.section_title.toString().toLowerCase() === sectiontitle.toString().toLowerCase())) {
            return;
        }
        this.count_section_field++;
        //let keytype = '';

        if (datatype === 'section') {
            if (!this.section_field_lable || !this.section_field_type || !this.section_title) {
                this.toast.error("Please enter field labletypelabel and field type");
                return;
            }
            this.section_fields.push({
                'section_title': this.titleCase(sectiontitle),
                'section_type': this.field_type,
                'labelname': this.titleCase(labelname),
                "keytype": labletype,
                'key': key || labelname.replace(/\s+/g, '').toLowerCase(),
                options: options.map((value) => ({ checked: this.default, key: value.displayValue.toLowerCase().replace(/\s/g, '_'), uid: Math.random().toString(16).substring(3), lable: this.titleCase(value.displayValue) })),
                'signature_required': this.signature_required,
                'file_verified': this.field_verified,
                'verification_grade': this.verification_grade,
                //'section_id' : this.section_id,
                'section_color': this.set_color,
                'section_show': showsection,
                'section_lable': this.section_lable,
                'field_value': '',
                'is_mandatory': this.field_mandate,
                'date_type': this.date_type
            });



        } else {
            if (!this.section_title) {
                this.toast.error("Please enter section name");
                return;
            }
            else {
                this.section_fields.push({
                    'section_title': this.titleCase(sectiontitle),
                    'section_type': this.field_type,
                    'labelname': labelname,
                    'key': key,
                    'keytype': datatype,
                    'signature_required': this.signature_required,
                    'file_verified': this.field_verified,
                    'verification_grade': this.verification_grade,
                    'section_color': this.set_color,
                    'section_show': showsection,
                    'section_lable': this.section_lable,
                    'field_value': '',
                    'is_mandatory': this.field_mandate,
                    'date_type': this.date_type
                });
            }
        }

        this.storeField = "no";
        this.field_verified = "no";
        this.field_mandate = "no";
        this.date_type = "both";
        this.section_field_lable = "";
        this.section_field_type = "";
        this.section_tags = [];

        this.filtersection(this.tag_index);
    }

    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }


}
