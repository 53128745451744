import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from "../../../../_services/common.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
    selector: "app-generate-id-modal",
    templateUrl: "./generate-id-modal.component.html",
    styleUrls: ["./generate-id-modal.component.css"],
})
export class GenerateIdModalComponent implements OnInit {
    public initialState: any;
    isFileChosen: boolean = false;
    fileName: string = "";

    public event: EventEmitter<any> = new EventEmitter();


    addGenerateIdForm: FormGroup;
    uploadedFile: any = null;
    act: string;
    isSubmitted: boolean;
    cardData: any;
    fn;
    ln;
    userid2;
    dob2;
    idExp;
    url;
    msg = "";
    profileImage;
    phone;
    email;
    fileToUpload: File = null;
    DOB: any = "";
    idCardExpiyDate: any = "";
    domainName: any = "";
    singleEmployeeDataModal: any = {};
    cardEmpName: string;
    cardAkcessId: string;
    cardEmpComany: string;
    cardEmpEmail: string;
    cardEmpMobile: number;
    showPreview: boolean;
    showSaveBtn: boolean;
    showDobMsg: boolean;
    showExpMsg: boolean;
    showExpMsgCompare: boolean;
    showUploadMsg: boolean;
    minDate: Date;

    constructor(
        private _bsModalRef: BsModalRef,
        private spinner: NgxSpinnerService,
        public commonService: CommonService,
        private gs: GeneralServiceService,
        private toast: ToastrService,
        private router: Router,
        private formBuilder: FormBuilder,
        public options: ModalOptions
    ) {
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate() + 1);
    }

    ngOnInit(): void {
        this.showPreview = false;
        this.showSaveBtn = false;
        this.showDobMsg = false;
        this.showExpMsg = false;
        this.showExpMsgCompare = false;
        this.isFileChosen = false;
        this.showUploadMsg = false;
        this.initialState = this.options.initialState;
        this.initGenerateIdForm();
    }

    initGenerateIdForm() {
        this.addGenerateIdForm = this.formBuilder.group({
            //_id: [null],
            //firstName: ["", [Validators.required]],
            //lastName: ["", [Validators.required]],
            //email: [""],
            //phone: [""],
            //idNo: ["", [Validators.required]],
            DOB: [""],
            idCardExpiyDate: [""],
            photoUpload: [this.fileToUpload],
        });
        if (this.initialState) {
            this.addGenerateIdForm.controls.DOB.setValue(this.initialState.dateofbirth);
        }

        // if(this.initialState.profileimg){
        //     this.addGenerateIdForm.controls.photoUpload.disable();
        // }
    }

    onFileChange(files) {
        this.profileImage = files.target.files[0];
        // this.initialState.profileimg = this.profileImage;
        // this.fileToUpload = files.item(0);
        // this.labelImport = this.fileToUpload.name
        // -------------------------------------------
        if (!files.target.files[0] || files.target.files[0].length == 0) {
            this.msg = "You must select an image";
            this.isFileChosen = false;
            return;
        }

        var mimeType = files.target.files[0].type;

        if (mimeType.match(/image\/*/) == null) {
            this.msg = "Only images are supported";
            this.isFileChosen = false;
            return;
        }

        this.isFileChosen = true;
        this.showExpMsgCompare = false;
        this.fileName = files.target.files[0].name;

        var reader = new FileReader();
        reader.readAsDataURL(files.target.files[0]);
        if (files.target.files[0]) {
            const reader = new FileReader();

            reader.onload = this.handleReaderLoaded.bind(this);
            reader.readAsBinaryString(files.target.files[0]);
        }
        reader.onload = (_event) => {
            this.msg = "";
            this.url = reader.result;
        };
    }

    preview(cardData) {
        this.DOB = this.initialState.dateofbirth
        // this.DOB = this.addGenerateIdForm.value.DOB;
        this.idCardExpiyDate = this.addGenerateIdForm.value.idCardExpiyDate;
        this.cardEmpName = this.initialState.firstName + " " + this.initialState.lastName;
        this.cardAkcessId = this.initialState.akcessId;
        this.cardEmpEmail = this.initialState.email;
        this.cardEmpMobile = this.initialState.phone;
        this.domainName = this.commonService.selectedCompanyDeatils.domain;

        let currerntDate = new Date();
        let compExpiyDate = new Date(this.idCardExpiyDate);
        if (this.DOB === "") {
            this.showDobMsg = true;
        }
        if (this.idCardExpiyDate === "") {
            this.showExpMsg = true;
        }
        if (this.isFileChosen === false) {
            this.showUploadMsg = true;
        }
        if (this.DOB != "" && this.idCardExpiyDate != "" && this.initialState.profileimg || this.isFileChosen === true) {
            this.url = this.initialState.profileimg ? this.initialState.profileimg : "";
            this.showExpMsg = false;
            this.showDobMsg = false;
            this.showUploadMsg = false;

            if (currerntDate > compExpiyDate) {

                this.showExpMsgCompare = true;

            }
            else {
                this.showPreview = true;
                this.showSaveBtn = true;
                this.showExpMsgCompare = false;
            }

        }

    }

    closeModal() {
        this._bsModalRef.hide();
    }

    triggerEvent(item: any) {
        this.event.emit({ data: item, res: 200 });
    }

    convertDataURIToBinary() {
        var byteString = atob(this.initialState.profileimg.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }

    //GenerateIdCard
    genearteIdCard(empData) {
        this.spinner.show();
        // this.DOB = this.addGenerateIdForm.value.DOB;
        this.idCardExpiyDate = this.addGenerateIdForm.value.idCardExpiyDate;

        let currerntDate = new Date();
        let compExpiyDate = new Date(this.idCardExpiyDate);

        if (currerntDate > compExpiyDate) {
            this.showExpMsgCompare = true;
        }
        else {

            // this.addGenerateIdForm.value.DOB = new Date(this.addGenerateIdForm.value.DOB).toISOString()
            this.addGenerateIdForm.value.idCardExpiyDate = new Date(this.addGenerateIdForm.value.idCardExpiyDate).toISOString()

            this.act = "add";
            this.isSubmitted = true;
            var formData = this.addGenerateIdForm.value;
            formData["file"] = this.initialState.fileBlobData ? this.initialState.fileBlobData : this.profileImage;
            formData["domainName"] = this.commonService.selectedCompanyDeatils.domain;
            formData["firstName"] = this.initialState.firstName;
            formData["lastName"] = this.initialState.lastName;
            formData["akcessId"] = this.initialState.akcessId;
            formData["email"] = this.initialState.email;
            formData["phone"] = this.initialState.phone;
            formData["empliyee_id"] = this.initialState._id;
            formData["DOB"] = this.DOB || "";
            formData["idCardExpiyDate"] = this.idCardExpiyDate.toISOString();

            this.gs.saveIdCardData(formData).subscribe(
                (data) => {
                    this.toast.success("Id card saved successfully");
                    this.spinner.hide();
                    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    //     this.router.navigate(['/panel/employees']);
                    // });
                    this.triggerEvent(data);
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );

            this._bsModalRef.hide();
        }
    }

    handleReaderLoaded(e) {
        this.initialState.profileimg = 'data:image/png;base64,' + btoa(e.target.result);
    }
}
