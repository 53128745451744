<div class="eforms-response-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">My eForms Response</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">My eForms Responses</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-1 mb-lg-0">
                            <div class="input-group">
                                <input type="text" class="form-control" id="search" (change)="searchEformResponse('search',$event.target.value)" placeholder="Search eForm Response" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-xl-1 mb-1 mb-lg-0">
                            <!--<label><b>Filter by eForm Status:</b></label>-->
                            <select class="form-control" (change)="searchEformResponse('select',$event.target.value)">
                                <option selected>Filter By</option>
                                <option value="">All</option>
                                <option value="pending">Pending</option>
                                <option value="alive">Alive</option>
                                <option value="accept">Accept</option>
                                <option value="verify and accept">Verify and Accept</option>
                                <option value="update client field">Update Client</option>
                                <option value="return">Return</option>
                                <option value="reject">Reject</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3 offset-xl-1 mb-1 mb-lg-0">
                            <!--<label><b>Order by Date:</b></label>-->
                            <select class="form-control" (change)="orderBy($event.target.value); geteformresponse(null)">
                                <option selected>Filter By</option>
                                <option value="nto">Newest to Oldest</option>
                                <option value="otn">Oldest to Newest</option>
                            </select>
                        </div>
                    </div>
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" small="true" hover="true">
                            <thead>
                                <tr>
                                    <th [mdbTableSort]="eformresponse" sortBy="firstName">First Name <span><i class="fas fa-arrows-alt-v"></i></span></th>
                                    <th [mdbTableSort]="eformresponse" sortBy="lastName">Last Name <span><i class="fas fa-arrows-alt-v"></i></span></th>
                                    <!-- <th [mdbTableSort]="eformresponse" sortBy="akcessId">AKcess Id <span><i class="fas fa-arrows-alt-v"></i></span></th> -->
                                    <th [mdbTableSort]="eformresponse" sortBy="akcessId">Company Name <span><i class="fas fa-arrows-alt-v"></i></span></th>
                                    <th [mdbTableSort]="eformresponse" sortBy="formName">eForm Name <span><i class="fas fa-arrows-alt-v"></i></span></th>
                                    <th [mdbTableSort]="eformresponse" sortBy="removed_flg">Status <span><i class="fas fa-arrows-alt-v"></i></span></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="eformresponse?.length == 0">
                                <tr>
                                    <td colspan="5" class="text-center">No eForms Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="eformresponse?.length > 0">
                                <tr *ngFor="let data of eformresponse | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                    <td>{{ data?.firstName }}</td>
                                    <td>{{ data?.lastName }}</td>
                                    <!-- <td>{{data?.akcessId}}</td> -->
                                    <td> {{data?.companyName}}</td>
                                    <td> {{data?.formName | titlecase}}</td>
                                    <td>{{data?.status | titlecase}}</td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="5">
                                        <div class="table-page">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                     class="my-pagination" directionLinks="true"
                                                                     previousLabel="Previous" nextLabel="Next">
                                                </pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value)" class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<!-- <table id="xls_formfields">
    <tr>
        <th colspan="2">{{formname}}</th>
    </tr>
    <tr *ngFor="let data of fields; let i = index;">
        <th style="font-weight: bold;">
            {{data.key}}
        </th>
        <td *ngIf="data.keytype == 'text' || data.keytype == 'Text'">
            {{data.value}}
        </td>
        <td *ngIf="data.keytype == 'File' || data.keytype == 'file'">
            <a href="{{imageUrl}}{{data?.val?.docUrl}}" target="_blank"> Document {{i}}

        </td>
    </tr>
</table>

<div id="pdf_formfields" style=" display: none;">
    <h3 style="text-align: center;">{{formname}}</h3>
    <p *ngFor="let data of fields; let i = index;">
        <b>{{data.key}}: </b>{{data.value}}<br>
    </p>
</div> -->
