<div class="company-users-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Admin List Of {{this.selectedCompany.name}}</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/panel/company']">Company Management</a></li>
                <li class="breadcrumb-item active">Admin List</li>
            </ol>
        </div>
    </div>

    <!-- Main Section -->
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <div class="input-group">
                            <button type="button" class="mx-1 btn" (click)="deleteUser()"
                                [disabled]="listOfAkcessId.length == 0">
                                <i class="fas fa-trash" tooltip="Delete" placement="bottom"></i>
                            </button>
                            <input type="text" [(ngModel)]="search_admin" class="form-control" id="search"
                                placeholder="Search Admin" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-8 col-xl-9 text-right mt-1 mt-md-0">
                        <button type="button" class="btn waves-effect waves-light btn-info add-btn" data-toggle="modal"
                            data-target="#addCompany" (click)="createAdmin()" tooltip="Add Admin" placement="bottom"
                            container="body">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                        hover="true" small="true">
                        <thead>
                            <tr>
                                <th>
                                    <div class="form-check" *ngIf="portalUser.akcessId == logInUserAkcessId">
                                        <input class="datatable-row-checkbox form-check-input" type="checkbox"
                                            (change)="checkAll($event.target.checked)" />
                                    </div>
                                </th>
                                <th [mdbTableSort]="listCompanyUsers" sortBy="akcessId">
                                    AKcess Id <span>
                                        <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                    </span>
                                </th>
                                <th [mdbTableSort]="listCompanyUsers" sortBy="name">
                                    Name<span>
                                        <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                    </span>
                                </th>
                                <th [mdbTableSort]="listCompanyUsers" sortBy="email">
                                    Email <span>
                                        <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                    </span>
                                </th>
                                <th [mdbTableSort]="listCompanyUsers" sortBy="phone">
                                    Mobile <span>
                                        <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                    </span>
                                </th>
                                <th [mdbTableSort]="listCompanyUsers" sortBy="date">
                                    Created On <span>
                                        <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                    </span>
                                </th>
                                <!-- <th class="text-nowrap">Action</th> -->
                            </tr>
                        </thead>
                        <tbody *ngIf="listCompanyUsers?.length == 0">
                            <tr>
                                <td colspan="6" class="text-center">No Admin Found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="listCompanyUsers?.length > 0">
                            <tr
                                *ngFor="let data of listCompanyUsers | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } | filter: search_admin ; let i = index">
                                <td>
                                    <div class="form-check" *ngIf="portalUser.akcessId == logInUserAkcessId">
                                        <input class="datatable-row-checkbox form-check-input" type="checkbox"
                                            (change)="checkUncheckSingle($event.target.checked,data)"
                                            [attr.id]="data?._id" value="data?._id" [ngModel]="data.isChecked" />
                                    </div>
                                </td>
                                <td>
                                    {{data?.akcessId}}
                                </td>
                                <td>
                                    {{data.name}}
                                </td>
                                <td>
                                    {{data?.email}}
                                </td>
                                <td>
                                    <span *ngIf="data?.dial_code">+{{ data?.dial_code }}-</span>{{data?.phone}}
                                </td>
                                <td>
                                    {{data.currentdate | date:"mediumDate"}}
                                </td>
                                <!-- <td>
                                    <button type="button" class="mx-1 btn btn-action-icon delete-btn"
                                        (click)="deleteUser(data)">
                                        <i class="fas fa-trash" tooltip="Delete" placement="bottom"></i>
                                    </button>
                                </td> -->
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="6">
                                    <div class="table-page" *ngIf="listCompanyUsers?.length > 10">
                                        <div class="page-controls">
                                            <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                nextLabel="Next"></pagination-controls>
                                        </div>
                                        <div class="page-size">
                                            <label class="mb-0 mr-2">Item per page</label>
                                            <select #val (change)="changePage(val.value)"
                                                class="form-control form-control-sm" id="pageSelector">
                                                <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modals -->
<!-- View Employee Modal -->
<div class="modal fade" id="addAdmin" tabindex="-1" role="dialog" aria-labelledby="addAdminModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="addAdminModalLabel">Add Admin</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clearData()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="font-500">Search User</label>
                    <tag-input [ngModel]="akcessIDChips" secondaryPlaceholder='Search Users' placeholder='Search Users'
                        theme='dark' (onAdd)="pushAkcessData($event)" (onRemove)="removeAkcessData($event)">
                        <tag-input-dropdown [autocompleteItems]="userData" [showDropdownIfEmpty]="false"
                            [dynamicUpdate]="true" [appendToBody]="false">
                        </tag-input-dropdown>
                    </tag-input>
                    <!--<ng-select [items]="userData"
                               [addTag]="addTagFn"
                               bindLabel="name"
                               [clearable]="true"
                               placeholder="Enter/Select User AKcess Id"
                               [(ngModel)]="akcessIDChips"
                               appendTo="body">
                    </ng-select>-->
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="addUserAsAdmin()">
                    Add
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" (click)="akcessIDChips = []"
                    id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>