<li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle text-muted waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-user-circle user-account-icon"></i></a>
    <div class="dropdown-menu dropdown-menu-right scale-up">
        <ul class="dropdown-user">
            <li>
                <div class="dw-user-box d-flex align-items-center">
                    <div class="u-img mr-1">
                        <!--<img src="../../assets/new-images/users/1.jpg" alt="user">-->
                        <!-- <img src="../../assets/new-images/users/default-profile.svg" alt="user"> -->
                        <div [ngStyle]="{'background': background}" id="profileimg">{{initials}}</div>
                    </div>
                    <div class="u-text">
                        <h4>{{headername}}</h4>
                        <p class="text-muted">{{headeremail}}</p>
                        <!--<a href="pages-profile.html" class="btn btn-rounded btn-danger btn-sm">View Profile</a>-->
                    </div>
                </div>
            </li>
            <li role="separator" class="divider"></li>
            <li><a [routerLink]="['/panel/user-profile']"><i class="fal fa-user"></i> My Profile</a></li>
            <!--<li><a href="#"><i class="ti-wallet"></i> My Balance</a></li>
            <li><a href="#"><i class="ti-email"></i> Inbox</a></li>
            <li role="separator" class="divider"></li>
            <li><a href="#"><i class="ti-settings"></i> Account Setting</a></li>-->
            <li role="separator" class="divider"></li>
            <li><a (click)="logout()"><i class="fal fa-power-off"></i> Logout</a></li>
        </ul>
    </div>
</li>