import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeleteUsersComponent } from './delete-users/delete-users.component';
import { AddUsersComponent } from './add-users/add-users.component';

declare var $: any;

@Component({
    selector: 'app-company-users',
    templateUrl: './company-users.component.html',
    styleUrls: ['./company-users.component.css']
})
export class CompanyUsersComponent implements OnInit {
    listCompanyUsers: any = []
    pageSize: any = 10;
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    portalUser;
    addForm: FormGroup;
    akcessIDChips = [];
    userData: any = [];
    userDataAKcessID: any = [];
    saveuserData: any = [];
    selectedDomain;
    selectedCompany: any;
    search_admin: any;
    bsModalRef: BsModalRef;
    userSendData: any = {};
    companyDetails: any = [];

    logInUserAkcessId: any;

    constructor(
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private calldata: DataservicesService,
        private router: Router,
        private route: ActivatedRoute,
        private gs: GeneralServiceService,
        public modalService: BsModalService,
    ) { }

    ngOnInit(): void {
        if (window.history.state.hasOwnProperty("selectedCompany")) {
            this.selectedCompany = window.history.state.selectedCompany
                ? window.history.state.selectedCompany
                : {};
            sessionStorage.setItem("selectedCompany", JSON.stringify(this.selectedCompany));
        } else {
            this.selectedCompany = JSON.parse(sessionStorage.getItem("selectedCompany"));
        }

        this.addForm = this.fb.group({
            domain: ['', Validators.required],
        });


        // this.selectedDomain = this.route.snapshot.paramMap.get('selectedDomain');
        this.getAllCompanyUsers();
        this.getuserData(true);
        this.getserachuserData();
    }

    addTagFn(name) {
        return { name: name, tag: true };
    }

    getAllCompanyUsers() {
        this.spinner.show();
        let data = {
            akcessId: sessionStorage.getItem('logedInUserAckessId'),
            domainName: this.selectedCompany.domain
        }

        this.calldata.listCompanyByDomain(data).subscribe(
            (res: any) => {
                if (res.status) {
                    this.listCompanyUsers = res.data.admin.reverse();
                    this.companyDetails = res.data;
                    this.logInUserAkcessId = res.data.logInUserAkcessId;
                    this.spinner.hide();
                    this.toast.success("Admin User List Fetched");
                }
            },
            (error) => {
                this.spinner.hide();
                this.toast.error("List fetching is facing issue.");
            }
        );
    }

    getuserData(flag) {
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
    }

    getserachuserData() {
        let dbName = this.selectedCompany.dbName;
        this.calldata.getEmployeeListWithoutSelectingCompany(dbName).subscribe(
            (res: any) => {
                if (res.status) {
                    this.saveuserData = res.data;

                    this.saveuserData.forEach((val) => {
                        var email =
                            val["email"] == "undefined" ||
                                val["email"] == "" ||
                                val["email"] == null
                                ? "No-Email"
                                : val["email"];
                        var phone =
                            val["phone"] == "undefined" ||
                                val["phone"] == null ||
                                val["phone"] == ""
                                ? "No-Mobile-Number"
                                : val["phone"];

                        var akcessId = (val["akcessId"]) ? val["akcessId"] : 'undefined'

                        this.userData.push(akcessId + " " + email + " " + phone);
                        this.userDataAKcessID.push(akcessId);
                    });
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    addUserAsAdmin() {

        var adminArray = []
        this.akcessIDChips.forEach(item => {
            var admin = {
                "akcessId": item.split(' ')[0],
                "email": item.split(' ')[1],
                "phone": item.split(' ')[2]
            }
            adminArray.push(admin)
        })

        const reqBody = {
            "adminData": adminArray,
            "domain": this.selectedCompany.domain
        }
        this.clearData();
        this.gs.addUserAsAdmin(reqBody).subscribe((res: any) => {
            if (res.status)
                this.toast.success(res.message);
            else
                this.toast.warning(res.message);
            $('#sendIdCardModelId').click();
            $('#addAdmin').modal('hide');

            this.getAllCompanyUsers();
            this.filterSearchData();
            this.spinner.hide();
        },
            error => {
                this.clearData();
                this.toast.error(error.error.message);
                this.spinner.hide();
            }
        )

    }

    filterSearchData() {
        for (var i = 0; i < this.listCompanyUsers.length; i++) {
            this.saveuserData = this.saveuserData.filter((item) => {
                if (item.akcessId != this.listCompanyUsers[i].akcessId)
                    return item;
            })

        }
        this.saveuserData.forEach(val => {
            var email = (val['email'] == 'undefined' || val['email'] == '' || val['email'] == null) ? 'No Email' : val['email'];
            var phone = (val['phone'] == 'undefined' || val['phone'] == null || val['phone'] == '') ? 'No Mobile Number' : val['phone'];

            this.userData.push(val['akcessId'] + ' ' + email + ' ' + phone);
        });
    }

    clearData() {
        this.akcessIDChips = [];
    }

    onSubmit() {

    }

    onProjectPageChange(event) {
        this.currentPage = event;
    }

    changePage(value) {
        this.pageSize = parseInt(value);
    }

    pushAkcessData(e) {
        this.akcessIDChips.push(e.value)
    }
    removeAkcessData(e) {
        let indexOfEmail = this.akcessIDChips.findIndex(e => e == e.value)
        this.akcessIDChips.splice(indexOfEmail, 1);
    }

    /**
    * Clear Data
    */
    ngOnDestroy() {
        sessionStorage.removeItem("selectedCompany");
    }

    deleteUser() {

        if (this.listOfAkcessId.length == 0) {
            this.toast.warning("Please select one Admin.");
            return
        }

        const initialState: any = {
            userSendData: this.listOfAkcessId,
            companyID: this.companyDetails
        }

        this.bsModalRef = this.modalService.show(DeleteUsersComponent, {
            initialState,
            animated: true,
            backdrop: 'static',
            class: 'modal-dialog-centered'
        });

        this.bsModalRef.content.onDelete.subscribe(user => {
            this.listCompanyUsers = user.data.admin
        })
    }

    createAdmin() {

        let employeeAKceesId = this.userDataAKcessID;

        const initialState: any = {
            empList: employeeAKceesId,
            selectedCompany: this.selectedCompany
        }

        this.bsModalRef = this.modalService.show(AddUsersComponent, {
            initialState,
            animated: true,
            backdrop: 'static',
            class: 'modal-dialog-centered'
        });
    }

    checkUncheckSingle(checked, data) {
        if (checked) {
            this.listOfAkcessId.push(data.akcessId)
        } else {
            const i = this.listOfAkcessId.indexOf(data.akcessId)
            this.listOfAkcessId.splice(i, 1);
        }
    }

    listOfAkcessId: any = [];

    checkAll(checked) {
        if (checked) {
            this.listOfAkcessId = []
            this.listCompanyUsers.filter((e) => {
                e.isChecked = true;
                this.listOfAkcessId.push(e.akcessId);
            })
        } else {
            this.listOfAkcessId = []
            this.listCompanyUsers.filter((e) => {
                e.isChecked = false;
            })
        }
    }

}
