<div class="credit-scrore-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Credit Score / Blacklist</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Credit Score / Blacklist</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-12 col-md-6 col-lg-5 col-xl-4 mb-1 mb-lg-0">
                        <div class="input-group">
                            <input type="text" class="form-control" id="search" [(ngModel)]="search"
                                   placeholder="Search By Name, AKcess Id or email" />
                            <div class="input-group-append">
                                <div class="input-group-text" (click)="getCreditScoreList()">
                                    <i class="fal fa-search"></i>
                                </div>
                                <div class="input-group-text" (click)="sortByScore = ''; sortByCreditScore = ''; getCreditScoreList()">
                                    <i class="fal fa-times-circle"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 col-xl-4 mb-1 mb-lg-0">
                        <!--<label><b>Filter by eForm Status:</b></label>-->
                        <select class="form-control" [(ngModel)]="sortByScore" name="sortByScore"
                                (change)="sortByScoreMethod($event.target.value)" id="score">
                            <option selected>Sort by Score</option>
                            <option value='htl'>High to Low</option>
                            <option value='lth'>Low to High</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 col-xl-4 mb-1 mb-lg-0">
                        <!--<label><b>Order by Date:</b></label>-->
                        <select class="form-control" [(ngModel)]="sortByCreditScore" name="sortByCreditScore"
                                (change)="sortByCreditScoreMethod($event.target.value)" id="creditScore">
                            <option selected>Sort by Credit Score</option>
                            <option value='htl'>High to Low</option>
                            <option value='lth'>Low to High</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table table-hover table-sm" mdbTable #tableEl="mdbTable">
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>AKcess Id</th>
                                <th>Email</th>
                                <th>Score</th>
                                <th>Credit Score</th>
                                <th>Blacklisted</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="clientList?.length == 0">
                            <tr>
                                <td colspan="5" class="text-center">No Records Found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="clientList?.length > 0">
                            <tr *ngFor="let data of clientList">
                                <td>{{ data?.firstName | titlecase }} {{ data?.lastName | titlecase }}</td>
                                <td>{{ data?.akcessId }}</td>
                                <td>{{ data?.email }}</td>
                                <td class="text-center" [ngClass]="(data && data.score < 50) ? 'text-danger' : 'text-success'">{{ (data && data.score) ? data?.score : 0 }}</td>
                                <td class="text-center" [ngClass]="(data && data.creditScore < 50) ? 'text-danger' : 'text-success'">{{ (data && data.creditScore) ? data?.creditScore : 0 }}</td>
                                <td class="text-center" [ngClass]="(data && data.blackListStatus) ? 'text-danger' : 'text-success'">{{ (data && data.blackListStatus) ? 'Y' : 'N' }}</td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="6">
                                    <!--<div class="table-page" *ngIf="clientList?.length > 10">
                                        <div class="page-controls">
                                            <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                 class="my-pagination"
                                                                 directionLinks="true"
                                                                 previousLabel="Previous"
                                                                 nextLabel="Next"></pagination-controls>
                                        </div>
                                        <div class="page-size">
                                            <label class="mb-0 mr-2">Item per page</label>
                                            <select #val
                                                    (change)="changePage(val.value)"
                                                    class="form-control form-control-sm"
                                                    id="pageSelector">
                                                <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                                    {{ pG }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>-->
                                </td>
                                td
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>