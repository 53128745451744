import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
    FormBuilder,
    FormGroup,
    FormControl,
    Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { DataservicesService } from "src/app/_services/dataservices.service";

import {
    SearchCountryField,
    CountryISO,
    PhoneNumberFormat,
} from "ngx-intl-tel-input";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-company-onboarding",
    templateUrl: "./company-onboarding.component.html",
    styleUrls: ["./company-onboarding.component.css"],
})
export class CompanyOnboardingComponent implements OnInit {
    companyOnboardingFG: FormGroup;
    isSubmitted = false;
    allFields = [];
    countryList: any = [];

    eformId: any;
    eformData: any;
    formName: any;
    dbName: any;

    companyInviteId: any;
    companylogo: any;
    commerciallicense: any;
    civilidfront: any;
    civilidback: any;
    selfie: any;

    companyTypeOptions = [
        {
            checked: false,
            key: "w.l.l",
            uid: "85f53803e0fa",
            lable: "W.l.l",
        },
        {
            checked: false,
            key: "k.s.c",
            uid: "eb715b5eaadb",
            lable: "K.s.c",
        },
    ];

    marketSectorOptions = [
        {
            checked: false,
            key: "energy_sector",
            uid: "c3ed175ad485",
            lable: "Energy Sector",
        },
        {
            checked: false,
            key: "materials_sector",
            uid: "5c7518e9d887",
            lable: "Materials Sector",
        },
        {
            checked: false,
            key: "industrials_sector",
            uid: "0dc11924b9b1",
            lable: "Industrials Sector",
        },
        {
            checked: false,
            key: "consumer_discretionary_sector",
            uid: "aea225fe2d1a",
            lable: "Consumer Discretionary Sector",
        },
        {
            checked: false,
            key: "consumer_staples_sector",
            uid: "ff081cb8d2e2",
            lable: "Consumer Staples Sector",
        },
        {
            checked: false,
            key: "healthcare_sector",
            uid: "ca0ca7374468",
            lable: "Healthcare Sector",
        },
        {
            checked: false,
            key: "financials_sector",
            uid: "e16a9da8dc7c",
            lable: "Financials Sector",
        },
        {
            checked: false,
            key: "information_technology_sector",
            uid: "06ca505b5cf9",
            lable: "Information Technology Sector",
        },
        {
            checked: false,
            key: "communication_services_sector",
            uid: "427bfd4bb48",
            lable: "Communication Services Sector",
        },
        {
            checked: false,
            key: "utilities_sector",
            uid: "f9add97a38aa",
            lable: "Utilities Sector",
        },
    ];

    positionOptions = [
        {
            checked: false,
            key: "owner_",
            uid: "60b76db6c22e",
            lable: "Owner ",
        },
        {
            checked: false,
            key: "chief_executive_officer_(ceo)_or_president",
            uid: "f2da20d78bb2",
            lable: "Chief Executive Officer (ceo) Or President",
        },
        {
            checked: false,
            key: "general_manager",
            uid: "cbfd36b32c6",
            lable: "General Manager",
        },
        {
            checked: false,
            key: "marketing_manager",
            uid: "a90705204d1c",
            lable: "Marketing Manager",
        },
        {
            checked: false,
            key: "chief_financial_officer_(cfo)",
            uid: "492e1e57a03e",
            lable: "Chief Financial Officer (cfo)",
        },
        {
            checked: false,
            key: "production_manager",
            uid: "850894ca4bfc",
            lable: "Production Manager",
        },
        {
            checked: false,
            key: "environmental_manager",
            uid: "d05f9438f7f",
            lable: "Environmental Manager",
        },
        {
            checked: false,
            key: "office_manager.",
            uid: "399e36d99f3e",
            lable: "Office Manager.",
        },
    ];

    doespersonnelOptions = [
        {
            checked: false,
            key: "hold_an_american_passport_",
            uid: "46db155e3c85",
            lable: "Hold An American Passport ",
        },
        {
            checked: false,
            key: "hold_an_american_address",
            uid: "39161e8ca304",
            lable: "Hold An American Address",
        },
        {
            checked: false,
            key: "have_a_political_position_",
            uid: "a26f7add014c",
            lable: "Have A Political Position ",
        },
        {
            checked: false,
            key: "have_a_political_relative_first_or_second_degree_",
            uid: "0edb96f660b5",
            lable: "Have A Political Relative First Or Second Degree ",
        },
    ];

    genderOptions = [
        {
            checked: false,
            key: "male",
            uid: "46db155e3d84",
            lable: "Male",
        },
        {
            checked: false,
            key: "female",
            uid: "39161e8ac304",
            lable: "Female",
        },
        {
            checked: false,
            key: "other",
            uid: "39161e8dc304",
            lable: "Other",
        },
    ];

    maritalStatusOptions = [
        {
            checked: false,
            key: "married",
            uid: "46db155e3675",
            lable: "Married",
        },
        {
            checked: false,
            key: "unmarried",
            uid: "39161e8ac064",
            lable: "Unmarried",
        },
    ];

    fields = [
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7418354f18cc0",
            labelname: "Company English Name",
            key: "companyenglishname",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741415cf18cc1",
            labelname: "Company Arabic Name",
            key: "companyarabicname",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7410429f18cc2",
            labelname: "Upload Company Logo",
            key: "companylogo",
            keytype: "file",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "no",
            value: null,
        },
        {
            options: this.companyTypeOptions,
            date_type: "both",
            _id: "62b952bc5ff74124d3f18cc3",
            labelname: "Company Type",
            key: "companytype",
            keytype: "checkbox",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: this.marketSectorOptions,
            date_type: "both",
            _id: "62b952bc5ff7417db5f18cc4",
            labelname: "Market Sector",
            key: "marketsector",
            keytype: "select",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff74169baf18cc5",
            labelname: "Address",
            key: "address",
            keytype: "textarea",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741016df18cc6",
            labelname: "Domain",
            key: "domain",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "Domain should not have http: or https",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7414babf18cc7",
            labelname: "Description",
            key: "description",
            keytype: "textarea",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "future",
            _id: "62b952bc5ff7414409f18cc8",
            labelname: "License Expiry Date ",
            key: "licenseexpirydate",
            keytype: "date",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "future",
            _id: "62b952bc5ff741b35cf18cc9",
            labelname: "Commercial License ",
            key: "commerciallicense",
            keytype: "file",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7410084f18cca",
            labelname: "Personnel's First Name ",
            key: "firstName",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7412e9af18ccb",
            labelname: "Personnel's Last Name ",
            key: "lastName",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: this.positionOptions,
            date_type: "both",
            _id: "62b952bc5ff741855bf18ccc",
            labelname: "Position",
            key: "position",
            keytype: "select",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741a205f18ccd",
            labelname: "Email",
            key: "email",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741c73ff18cce",
            labelname: "Mobile",
            key: "mobile",
            keytype: "number",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: this.genderOptions,
            date_type: "both",
            _id: "62b952bc5ff741837bf18ccf",
            labelname: "Gender",
            key: "gender",
            keytype: "list",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741fa50f18cd0",
            labelname: "Date Of Birth",
            key: "dateofbirth",
            keytype: "date",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741a150f18cd1",
            labelname: "Civil Id Number",
            key: "civilidnumber",
            keytype: "number",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7416bdbf18cd2",
            labelname: "Civil Id Expiry Date",
            key: "civilidexpirydate",
            keytype: "date",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741587bf18cd3",
            labelname: "Civil ID- Front",
            key: "civilidfront",
            keytype: "file",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7411e70f18cd4",
            labelname: "Civil ID- Back",
            key: "civilidback",
            keytype: "file",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "no",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741cd9cf18cd5",
            labelname: "Nationality",
            key: "nationality",
            keytype: "list",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7413557f18cd6",
            labelname: "Religion",
            key: "religion",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: this.maritalStatusOptions,
            date_type: "both",
            _id: "62b952bc5ff741140ef18cd7",
            labelname: "Marital Status",
            key: "maritalstatus",
            keytype: "list",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741261ff18cd8",
            labelname: "Expected Monthly Number Of Transaction",
            key: "expectedmonthlynumberoftransaction",
            keytype: "number",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff74138ddf18cd9",
            labelname: "Monthly Expected Revenue",
            key: "monthlyexpectedrevenue",
            keytype: "number",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff7414fb0f18cda",
            labelname: "Source Of Income ",
            key: "sourceofincome",
            keytype: "string",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741a63df18cdb",
            labelname: "Home Address",
            key: "homeaddress",
            keytype: "address",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: this.doespersonnelOptions,
            date_type: "both",
            _id: "62b952bc5ff74114d2f18cdc",
            labelname: "Does Personnel ",
            key: "doespersonnel",
            keytype: "checkbox",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
        {
            options: [],
            date_type: "both",
            _id: "62b952bc5ff741380ef18cdd",
            labelname: "Selfie",
            key: "selfie",
            keytype: "file",
            signature_required: "no",
            file_verified: "no",
            verification_grade: "",
            instructions: "",
            is_mandatory: "yes",
            value: null,
        },
    ];

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required]),
    });

    changePreferredCountries() {
        this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    }

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private el: ElementRef,
        private spinner: NgxSpinnerService,
        public http: HttpClient
    ) {
        /* Live form data */
        this.eformId = "62b9412b5ff7410418f18c6c";
        this.formName = "Company Onboarding";
        this.dbName = "DB1672061789158";
        // this.dbName = "DB1645612714658";    // DB of AK-FORMS

        /* Local form data */
        /*this.eformId = "6389f5845c00c520c96643f7";
    this.formName = "Demo";
    this.dbName = "DB1669981055106";*/

        // DB1672061789158    - AK Kuwait

        this.companyOnboardingFG = this.formBuilder.group({
            name: [null, [Validators.required]],
            company_arabic_name: [null, [Validators.required]],
            logo: [null, [Validators.required]],
            company_type: [null, [Validators.required]],
            market_sector: [null, [Validators.required]],
            address: [null, [Validators.required]],
            domain: [null, [Validators.required]],
            description: [null, [Validators.required]],
            license_expiry_date: [null, [Validators.required]],
            commercial_license: [null, [Validators.required]],
            personnel_fname: [null, [Validators.required]],
            personnel_lname: [null, [Validators.required]],
            position: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            mobile: [null, [Validators.required]],
            gender: [null, [Validators.required]],
            dob: [null, [Validators.required]],
            civil_id_number: [null, [Validators.required]],
            civil_id_expiry_date: [null, [Validators.required]],
            civil_id_front: [null, [Validators.required]],
            civil_id_back: [null, [Validators.required]],
            nationality: [null, [Validators.required]],
            religion: [null, [Validators.required]],
            martial_status: [null, [Validators.required]],
            expected_monthly_no_transaction: [null, [Validators.required]],
            expected_monthly_revenue: [null, [Validators.required]],
            income_source: [null, [Validators.required]],
            home_address: [null, [Validators.required]],
            does_personnel: [null, [Validators.required]],
            selfie: [null, [Validators.required]],
            company_invite_id: [, [Validators.required]],
        });
    }

    ngOnInit(): void {
        // this.companyInviteId = this.router.getCurrentNavigation().extras.state.companyInviteId
        this.companyInviteId = this.route.snapshot.paramMap.get("id");
        this.companyOnboardingFG
            .get("company_invite_id")
            .setValue(this.companyInviteId);
        this.getCountryList();
    }

    getCountryList() {
        this.http.get("/assets/country-list.json").subscribe((res: any) => {
            res.filter((e) => {
                this.countryList.push(e.label);
            });
        });
    }

    submitFrom() {
        this.isSubmitted = true;

        let number: any = this.companyOnboardingFG.controls.mobile.value;
        if (number) {
            let phoneNumber = number.e164Number.split(number.dialCode);
            let countryCode = number.dialCode.split("+");
            this.companyOnboardingFG.controls.mobile = phoneNumber;
        }
        let formData = this.companyOnboardingFG.value;
        if (this.companyOnboardingFG.valid) {
            this.calldata.companiesOnboard(formData).subscribe((res) => {
                this.toast.success(res["message"]);
                this.router.navigate(["/panel/company"]);
            });
        }
    }

    onCTCheckboxChange(event, index) {
        event.target.checked
            ? (this.companyTypeOptions[index].checked = true)
            : (this.companyTypeOptions[index].checked = false);
    }

    onDPCheckboxChange(event, index) {
        event.target.checked
            ? (this.doespersonnelOptions[index].checked = true)
            : (this.doespersonnelOptions[index].checked = false);
    }

    onLogoChange(files) {
        this.companylogo = files[0];
    }

    onLicenseChange(files) {
        this.commerciallicense = files[0];
    }

    onCivilidFrontChange(files) {
        this.civilidfront = files[0];
    }

    onCivilidBackChange(files) {
        this.civilidback = files[0];
    }
    onSelfieChange(files) {
        this.selfie = files[0];
    }

    getEformData() {
        /*if (this.eformId) {
      this.calldata
        .getSingleEformData(this.eformId, this.dbname)
        .subscribe((data) => {
        });
    }*/
    }

    // saveCompanyOnboardingEformResponse() {
    //   this.isSubmitted = true;
    //   for (const key of Object.keys(this.companyOnboardingEform.controls)) {
    //     if (this.companyOnboardingEform.controls[key].invalid) {
    //       const invalidControl = this.el.nativeElement.querySelector(
    //         '[formcontrolname="' + key + '"]'
    //       );
    //       invalidControl.focus();
    //       break;
    //     }
    //   }
    //   if (!this.companyOnboardingEform.invalid) {
    //     this.spinner.show();
    //     var formValue = this.companyOnboardingEform.value;
    //     this.fields.map((field, index) => {
    //       if (field.key == "companyenglishname") {
    //         field.value = formValue.companyenglishname;
    //       } else if (field.key == "companyarabicname") {
    //         field.value = formValue.companyarabicname;
    //       } else if (field.key == "companylogo") {
    //         field.value = this.companylogo;
    //       } else if (field.key == "companytype") {
    //         let checkedOptions = [];
    //         this.companyTypeOptions.map((option) => {
    //           if (option.checked) {
    //             checkedOptions.push(option.lable);
    //           }
    //         });
    //         field.value = checkedOptions.toString();
    //       } else if (field.key == "marketsector") {
    //         field.value = formValue.marketsector;
    //       } else if (field.key == "address") {
    //         field.value = formValue.address;
    //       } else if (field.key == "domain") {
    //         field.value = formValue.domain;
    //       } else if (field.key == "description") {
    //         field.value = formValue.description;
    //       } else if (field.key == "licenseexpirydate") {
    //         field.value = formValue.licenseexpirydate;
    //       } else if (field.key == "commerciallicense") {
    //         field.value = this.commerciallicense;
    //       } else if (field.key == "firstName") {
    //         field.value = formValue.firstName;
    //       } else if (field.key == "lastName") {
    //         field.value = formValue.lastName;
    //       } else if (field.key == "position") {
    //         field.value = formValue.position;
    //       } else if (field.key == "email") {
    //         field.value = formValue.email;
    //       } else if (field.key == "mobile") {
    //         field.value = formValue.mobile;
    //       } else if (field.key == "gender") {
    //         field.value = formValue.gender;
    //       } else if (field.key == "dateofbirth") {
    //         field.value = formValue.dateofbirth;
    //       } else if (field.key == "civilidnumber") {
    //         field.value = formValue.civilidnumber;
    //       } else if (field.key == "civilidexpirydate") {
    //         field.value = formValue.civilidexpirydate;
    //       } else if (field.key == "civilidfront") {
    //         field.value = this.civilidfront;
    //       } else if (field.key == "civilidback") {
    //         field.value = this.civilidback;
    //       } else if (field.key == "nationality") {
    //         field.value = formValue.nationality;
    //       } else if (field.key == "religion") {
    //         field.value = formValue.religion;
    //       } else if (field.key == "maritalstatus") {
    //         field.options = field.options.filter((option) => {
    //           if (option.checked !== false) {
    //             option.checked = true;
    //           }
    //           return option;
    //         });
    //         field.value = formValue.maritalstatus;
    //       } else if (field.key == "expectedmonthlynumberoftransaction") {
    //         field.value = formValue.expectedmonthlynumberoftransaction;
    //       } else if (field.key == "monthlyexpectedrevenue") {
    //         field.value = formValue.monthlyexpectedrevenue;
    //       } else if (field.key == "sourceofincome") {
    //         field.value = formValue.sourceofincome;
    //       } else if (field.key == "homeaddress") {
    //         field.value = formValue.homeaddress;
    //       } else if (field.key == "doespersonnel") {
    //         let opVal = [];
    //         this.doespersonnelOptions.filter((option) => {
    //           if (option.checked) {
    //             opVal.push(option.lable);
    //           }
    //           return option;
    //         });
    //         field.value = opVal.toString();
    //       } else if (field.key == "selfie") {
    //         field.value = this.selfie;
    //       }
    //       this.fields[index] = field;
    //       return field;
    //     });

    //     const formData: FormData = new FormData();
    //     formData.append("companylogo", this.companylogo);
    //     formData.append("commerciallicense", this.commerciallicense);
    //     formData.append("civilidfront", this.civilidfront);
    //     formData.append("civilidback", this.civilidback);
    //     formData.append("selfie", this.selfie);
    //     formData.append("eformId", this.eformId);
    //     formData.append("formName", this.formName);
    //     formData.append("companyName", this.dbName);
    //     formData.append("firstName", formValue.firstName);
    //     formData.append("lastName", formValue.lastName);

    //     formData.append("email", formValue.email);
    //     formData.append("mobile", formValue.mobile);
    //     formData.append(
    //       "fullname",
    //       formValue.firstName + " " + formValue.lastName
    //     );

    //     this.fields.map((field, i) => {
    //       formData.append(
    //         "fields[" + i + "][options]",
    //         JSON.stringify(field.options)
    //       );
    //       formData.append("fields[" + i + "][date_type]", field.date_type);
    //       formData.append("fields[" + i + "][_id]", field._id);
    //       formData.append("fields[" + i + "][labelname]", field.labelname);
    //       formData.append("fields[" + i + "][key]", field.key);
    //       formData.append("fields[" + i + "][keytype]", field.keytype);
    //       formData.append(
    //         "fields[" + i + "][signature_required]",
    //         field.signature_required
    //       );
    //       formData.append(
    //         "fields[" + i + "][file_verified]",
    //         field.file_verified
    //       );
    //       formData.append("fields[" + i + "][instructions]", field.instructions);
    //       formData.append("fields[" + i + "][is_mandatory]", field.is_mandatory);
    //       formData.append(
    //         "fields[" + i + "][verification_grade]",
    //         field.verification_grade
    //       );
    //       formData.append("fields[" + i + "][value]", field.value);
    //     });


    //     // this.calldata.saveeformresponse(formData).subscribe(
    //     //   (response) => {
    //     //     if (response.status) {
    //     //       this.toast.success("eForm submitted successfully");
    //     //       this.spinner.hide();
    //     //       setTimeout(() => {
    //     //         location.reload();
    //     //       }, 2000);
    //     //     } else {
    //     //       this.toast.info(response.message);
    //     //       this.spinner.hide();
    //     //     }
    //     //   },
    //     //   (error) => {
    //     //     this.toast.error("something went wrong");
    //     //     this.spinner.hide();
    //     //   }
    //     // );
    //   }
    // }
}
