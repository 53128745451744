<div class="row page-titles mb-0">
    <div class="col-md-1 align-self-center">
        <button class="btn btn-info btn-sm" (click)="navigate()">back</button>
    </div>
    
	<div class="col-md-5 align-self-center">
		<h3 class="text-themecolor">Previous Checks</h3>
	</div>
    <div class="col-md-4 align-self-center text-right">
        <button  type="button"  class="btn btn-info btn-sm"  (click)="openPopup()">Add New <i class="fa fa-plus"></i>  </button>
    </div>
	<div class="col-md-2 align-self-center">
		<ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="j#">Add New <i class="fa-solid fa-plus"></i></a></li> -->
			<li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
			<li class="breadcrumb-item active">Verifications History</li>
		</ol>
	</div>
</div>

<!-- modal openPopup -->
<!-- <div class="modal " id="largeModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}"> 
    <div class="modal-dialog modal-lg" role="document"> 
      <div class="modal-content"> 
        <div class="modal-header"> 
            <div class="d-flex flex-column">
          <h3 class="modal-title ">Quick Key request</h3> 
          <p class="mt-3" style="font-size: 14px;">Select the action you would like to perform or create an individual and get taken straight to their profile.
          </p>
        </div>

          <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('largeModal')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>  
        <div class="modal-body">
            <div class="row mb-3" style="padding-left: 15px;">
              <h5 class="heding">Create an individual</h5>
            </div>
            <div class="row">
              <div class="col-md-3" (change)="logSelectedValue('Send new invite to an individual')" >
                <input type="radio" id="Send new invite to an individual" name="profile" class="radio-input"> 
                 <label for="Send new invite to an individual" class="box" >
                    <img src=" ../../../../assets/img/send.png"> 
                    <p class="mt-2">Send new invite to an individual</p>
                    
                </label>
            </div>

            <div class="col-md-3" (change)="logSelectedValue('Create and go to profile')">
                <input type="radio" id="Create and go to profile" name="profile" class="radio-input" >  
                <label for="Create and go to profile" class="box">  
                    <img src=" ../../../../assets/img/search-profile.png">
                    <p  class="mt-2">Create and go to profile</p>
                </label>
            </div>
              <div class="col-md-3" (change)="logSelectedValue('Create and ask to sign a document')">
                <input type="radio" id="Create and ask to sign a document" name="profile" class="radio-input" >
                  <label for="Create and ask to sign a document" class="box" >
                    
                    <img src=" ../../../../assets/img/report.png">
                  <p  class="mt-2"> Create and ask to sign a document</p>
                </label>
              </div>
              <div class="col-md-3">
                
              </div>
            </div>
            <div  style="border-top:1px solid #ccc;margin-top: 20px;"></div>
            <div class="row mt-4 mb-3" style="padding-left: 15px;">
              <h5 class="heding">Create individual and run a check</h5>
            </div>
           
            <div class="row">
              <div class="col-md-3" (change)="logSelectedValue('Address & Mortality')">
                <input type="radio" id="Address & Mortality" name="profile" class="radio-input">
                  <label for="Address & Mortality" class="box">                   
                    <img src=" ../../../../assets/img/home-address.png">
                  <p  class="mt-2"> Address & Mortality</p>
                </label>
              </div>
              <div class="col-md-3" (change)="logSelectedValue('Sanctions & PEPs')">
                <input type="radio" id="Sanctions & PEPs" name="profile" class="radio-input">
                <label for="Sanctions & PEPs" class="box">                   
                    <img src=" ../../../../assets/img/globe.png">
                  <p  class="mt-2"> Sanctions & PEPs</p>
                </label>
              </div>
              <div class="col-md-3" (change)="logSelectedValue('Bank Account')">
                <input type="radio" id="Bank Account" name="profile" class="radio-input">
                <label for="Bank Account" class="box">                   
                    <img src=" ../../../../assets/img/bank.png">
                  <p  class="mt-2"> Bank Account</p>
                </label>
              </div>
              <div class="col-md-3" (change)="logSelectedValue('Open Banking & Affordability')">
                <input type="radio" id="Open Banking & Affordability" name="profile" class="radio-input">
                <label for="Open Banking & Affordability" class="box">                    
                    <img src=" ../../../../assets/img/money.png">
                  <p class="mt-2">Open Banking & Affordability</p>
                </label>
              </div>
            </div>
          </div>
        <div class="mt-5 p-2  d-flex flex-column"> 
        <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('personalInfoModal')">  Confirm Details  </button>
    
        <button type="button" class="btn btn-outline-secondary col-md-12 mt-2"  (click)="closePopup('largeModal')" style="border-radius: 20px;"> cancel </button>                 
          
        </div> 
      </div> 
    </div> 
  </div> -->
  <!-- next modal -->
  <!-- <div class="modal" id="personalInfoModal" tabindex="-1" role="dialog" style="display:none;">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex flex-column">
          <h3 class="modal-title">Personal Information</h3>
            <p>Please Provide the details for the individual you would like to create.</p>
          </div>
          <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('personalInfoModal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="uploadForm">
            <label for="firstName">Name</label>
            <div class="row">              
              <div class="col-md-6">
                <div class="form-group">                  
                  <input type="text" id="firstName" placeholder="firstName" formControlName="firstName" class="form-control"  [(ngModel)]="formData.firstName"  required>
                </div>
              </div>
              </div>
              <div class="row"> 
              <div class="col-md-6">
                <div class="form-group">                  
                  <input type="text" id="lastName" placeholder="lastName" formControlName="lastName" class="form-control" [(ngModel)]="formData.lastName"  required>
                </div>
              </div>
            </div>
            <div  style="border-top:1px solid #ccc;margin-top: 20px;"></div>
            
            <div class="form-group mt-5">
              <label for="birthdate">Birthdate</label>
              <input type="date" id="birthdate" placeholder="DOB (optinal) " formControlName="birthdate" class="form-control" [(ngModel)]="formData.birthdate">
            </div>
            <div class="form-group">
              <label for="reference">Your Reference</label>
              <input type="text" id="reference" placeholder="Reference (optinal) " formControlName="reference" class="form-control" [(ngModel)]="formData.reference">
            </div>
            <div class="mt-5 p-2  d-flex flex-column"> 

              <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('contactInfoModal')">  Confirm Details  </button>
          
              <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="goToPreviousModal('largeModal')"  style="border-radius: 20px;"> Back </button>                 
                
              </div> 

            
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Contact Information Modal -->
<!-- <div class="modal" id="contactInfoModal" tabindex="-1" role="dialog" style="display:none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex flex-column">
          <h3 class="modal-title">Contact Information</h3>
          <p>Please Provide the contact details for the individual you wish like to create.</p>
        </div>
        <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('contactInfoModal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="uploadForm">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" placeholder="Email Address (optinal)" formControlName="email" class="form-control" [(ngModel)]="formData.email" required>
          </div>
          <div class="form-group">
            <label for="telephone">Telephone</label>
            <div class="input-group">
              <select formControlName="countryCode" id="countryCode" class="custom-select" placeholder="Dailing code" [(ngModel)]="formData.countryCode">
                <option value="+61">Australia +61</option>
                <option value="+33">France +33</option>
                <option value="+49">Germany +49 </option>
                <option value="+353">Ireland +353 </option>
                <option value="+39">Italy +39</option>
                <option value="+34">Spain +34 </option>
                <option value="+44">UK +44</option>
                <option value="+1">United States +1</option>
                
              </select>
              <input type="tel" id="telephone" formControlName="telephone" class="form-control ml-2" placeholder="Mobile Number (optinal)" [(ngModel)]="formData.telephone">
            </div>
          </div>

          <div  style="border-top:1px solid #ccc;margin-top: 20px;"></div>

          <div class="form-group mt-3">
            <label for="address">Address (Optional)</label>
            <textarea id="address" placeholder="Search using a postcode" formControlName="address" class="form-control" rows="3" [(ngModel)]="formData.address"></textarea>
          </div>
          <div class="mt-5 p-2 d-flex">
            <button type="button" class="btn btn-outline-secondary " (click)="goToPreviousModal('personalInfoModal')" style="border-radius: 20px;">Search</button>
            <button type="button" class="btn btn-dark confirm-btn ml-2" style="border-radius: 20px;">Enter Manually</button>
            
          </div>
        </form>
      </div>
      <div class="mt-5 p-2 d-flex flex-column">
        <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('selectJourneyModal')">Create Profile</button>
        <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="goToPreviousModal('personalInfoModal')"  style="border-radius: 20px;">Back</button>
      </div>
    </div>
  </div>
</div> -->


<!-- Select Journey Modal -->
<!-- <div class="modal" id="selectJourneyModal" tabindex="-1" role="dialog" style="display:none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex flex-column">
          <h3 class="modal-title">Select Journey(s)</h3>
          <p>Please select one of the following options:</p>
        </div>
        <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('selectJourneyModal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <input type="radio" id="journeyOption1" name="journeyOption" class="radio-input" value="option1" >
            <label for="journeyOption1">Option 1</label>
          </div>
          <div class="form-group">
            <input type="radio" id="journeyOption2" name="journeyOption" class="radio-input" value="option2" >
            <label for="journeyOption2">Option 2</label>
          </div>
          <div class="form-group">
            <input type="radio" id="journeyOption3" name="journeyOption" class="radio-input" value="option3" >
            <label for="journeyOption3">Option 3</label>
          </div>
        </form>
        <div class="mt-5 p-2 d-flex flex-column">
          <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;">Proceed</button>
          <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="closePopup('selectJourneyModal')" style="border-radius: 20px;">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

  



  <!-- modal openPopup -->
<div class="modal " id="largeModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}"> 
  <div class="modal-dialog modal-lg" role="document"> 
    <div class="modal-content"> 
      <div class="modal-header"> 
          <div class="d-flex flex-column">
        <h3 class="modal-title font-bold text-dark">Quick Key request</h3> 
        <p class="mt-2 mb-0" style="font-size: 14px;">Select the action you would like to perform or create an individual and get taken straight to their profile.
        </p>
      </div>

        <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('largeModal')">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>  
      <div class="modal-body">
          <div class="row mb-3" style="padding-left: 15px;">
            <h4 class="heding text-dark" style="font-weight: 600;">Create an individual</h4>
          </div>
          <div class="row">
            <div class="col-md-3" (change)="logSelectedValue('Send new invite to an individual')" >
              <input type="radio" id="Send new invite to an individual" name="profile" class="radio-input"> 
               <label for="Send new invite to an individual" class="box" >
                  <img src=" ../../../../assets/img/send.png"> 
                  <p class="mt-2 mb-0" style="line-height: 20px;font-size: 16px;">Send new invite to an individual</p>
                  
              </label>
          </div>

          <div class="col-md-3" (change)="logSelectedValue('Create and go to profile')">
              <input type="radio" id="Create and go to profile" name="profile" class="radio-input" >  
              <label for="Create and go to profile" class="box">  
                  <img src=" ../../../../assets/img/search-profile.png">
                  <p  class="mt-2 mb-0 font-dark" style="line-height: 20px; font-size: 16px;">Create and go to profile</p>
              </label>
          </div>
            <!-- <div class="col-md-3" (change)="logSelectedValue('Create and ask to sign a document')">
              <input type="radio" id="Create and ask to sign a document" name="profile" class="radio-input" >
                <label for="Create and ask to sign a document" class="box" >
                  
                  <img src=" ../../../../assets/img/report.png">
                <p  class="mt-2"> Create and ask to sign a document</p>
              </label>
            </div> -->
            <div class="col-md-3">
              <!-- <div class="box">
                  <img src=" ../../../../assets/img/report.png">
                <p> <b>Create and ask to sign a document</b></p>
              </div> -->
            </div>
          </div>
          <div  style="border-top:1px solid #ccc;margin-top: 20px;"></div>
          <div class="row mt-4 mb-3" style="padding-left: 15px;">
            <h4 class="heding text-dark"  style="font-weight: 600;">Create individual and run a check</h4>
          </div>
          <!-- <div class="row">
              <div class="col-md-3" *ngFor="let option of createIndividualOptions">
                  <div class="box" [class.selected]="selectedCreateIndividual === option.id" (click)="selectCreateIndividual(option.id)">
                      <img [src]="option.img" [alt]="option.alt">
                      <p><b>{{ option.text }}</b></p>
                      <input type="radio" [id]="option.id" name="create-individual" [checked]="selectedCreateIndividual === option.id">
                      <label [for]="option.id"><i class="fa fa-check"></i></label>
                  </div>
              </div>
          </div> -->
          <div class="row mb-4">
            <div class="col-md-3" (change)="logSelectedValue('Address and Mortality')">
              <input type="radio" id="Address & Mortality" name="profile" class="radio-input">
                <label for="Address & Mortality" class="box">                   
                  <img src=" ../../../../assets/img/home-address.png">
                <p  class="mt-2 mb-0" style="line-height: 20px; font-size: 16px;"> Address & Mortality</p>
              </label>
            </div>
            <div class="col-md-3" (change)="logSelectedValue('Sanctions and PEPs')">
              <input type="radio" id="Sanctions & PEPs" name="profile" class="radio-input">
              <label for="Sanctions & PEPs" class="box">                   
                  <img src=" ../../../../assets/img/globe.png">
                <p  class="mt-2 mb-0" style="line-height: 20px; font-size: 16px;"> Sanctions & PEPs</p>
              </label>
            </div>
            <div class="col-md-3" (change)="logSelectedValue('Bank Account')">
              <input type="radio" id="Bank Account" name="profile" class="radio-input">
              <label for="Bank Account" class="box">                   
                  <img src=" ../../../../assets/img/bank.png">
                <p  class="mt-2 mb-0" style="line-height: 20px; font-size: 16px;"> Bank Account</p>
              </label>
            </div>
            <!-- <div class="col-md-3" (change)="logSelectedValue('Open Banking & Affordability')">
              <input type="radio" id="Open Banking & Affordability" name="profile" class="radio-input">
              <label for="Open Banking & Affordability" class="box">                    
                  <img src=" ../../../../assets/img/money.png">
                <p class="mt-2">Open Banking & Affordability</p>
              </label>
            </div> -->
          </div>
    <div id="validation-message" class="text-danger" *ngIf="validationMessage">{{ validationMessage }}</div>
        </div>
      <div class="mt-5 p-3  d-flex flex-column"> 
      <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('personalInfoModal')">  Confirm Details  </button>
  
      <button type="button" class="btn btn-outline-secondary col-md-12 mt-2"  (click)="closePopup('largeModal')" style="border-radius: 20px;"> cancel </button>                 
        
      </div> 
    </div> 
  </div> 
</div>
<!-- next modal -->
<div class="modal" id="personalInfoModal" tabindex="-1" role="dialog" style="display:none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex flex-column">
        <h3 class="modal-title text-dark mb-1">Personal Information</h3>
          <p class="personal-info mb-0">Please Provide the details for the individual you would like to create.</p>
        </div>
        <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('personalInfoModal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="uploadForm">
          <label class="text-dark font-bold" for="firstName">Name</label>
          <div class="row">              
            <div class="col-md-6">
              <div class="form-group">                  
                <input type="text" id="firstName" placeholder="First Name" formControlName="firstName" class="form-control"  [(ngModel)]="formdata.firstName"  required>
              </div>
            </div>
            </div>
            <div class="row"> 
            <div class="col-md-6">
              <div class="form-group">                  
                <input type="text" id="lastName" placeholder="Surname" formControlName="lastName" class="form-control" [(ngModel)]="formdata.lastName"  required>
              </div>
            </div>
          </div>
          <div  style="border-top:1px solid #eee;margin-top: 20px;"></div>
          
          <div class="form-group mt-3">
            <label class="text-dark font-bold" for="birthdate">Date of birth <span style="font-weight: normal;">(optional)</span></label>
            <input type="date" id="birthdate" placeholder="DOB (optional) " formControlName="birthdate" class="form-control" [(ngModel)]="formdata.birthdate">
          </div>
    <div  style="border-top:1px solid #eee;margin-top: 30px;"></div>
          <div class="form-group">
            <label class="text-dark font-bold" for="reference">Your reference</label>
            <input type="text" id="reference" placeholder="Reference (optional) " formControlName="reference" class="form-control" [(ngModel)]="formdata.reference">
          </div>
          <div class="mt-5 p-2  d-flex flex-column"> 

            <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('contactInfoModal')">  Confirm Details  </button>
        
            <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="goToPreviousModal('largeModal')"  style="border-radius: 20px;"> Back </button>                 
              
            </div> 

          
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Contact Information Modal -->
<div class="modal" id="contactInfoModal" tabindex="-1" role="dialog" style="display:none;">
<div class="modal-dialog modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex flex-column">
        <h3 class="modal-title text-dark">Contact information</h3>
        <p class="mb-0">Please provide the contact details for the individual you wish like to create.</p>
      </div>
      <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('contactInfoModal')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="uploadForm">
        <div class="form-group">
          <label class="text-dark font-bold" for="email">Email</label>
          <input type="email" id="email" placeholder="Email address" formControlName="email" class="form-control" [(ngModel)]="formdata.email" required>
        </div>
        <div class="form-group">
          <label class="text-dark font-bold" for="telephone">Telephone</label>
          <div class="input-group">
            <select formControlName="countryCode" id="countryCode" class="custom-select" placeholder="Dailing code" [(ngModel)]="formdata.countryCode">
              <option value="+61">Australia +61</option>
              <option value="+33">France +33</option>
              <option value="+49">Germany +49 </option>
              <option value="+353">Ireland +353 </option>
              <option value="+39">Italy +39</option>
              <option value="+34">Spain +34 </option>
              <option value="+44">UK +44</option>
              <option value="+1">United States +1</option>
              <!-- Add more options as needed -->
            </select>
            <input type="tel" id="telephone" formControlName="telephone" class="form-control ml-2" placeholder="Mobile Number (optional)" [(ngModel)]="formdata.telephone">
          </div>
        </div>

        <div  style="border-top:1px solid #ccc;margin-top: 20px;"></div>

        <div class="form-group mt-3">
          <label class="text-dark font-bold" for="address">Address <span style="font-weight: normal;"> (Optional)</span></label>
          <textarea id="address" placeholder="Search using a postcode" formControlName="address" class="form-control" rows="3" [(ngModel)]="formdata.address"></textarea>
        </div>
        <div class="mt-2 p-2 d-flex">
          <button type="button" class="btn btn-outline-secondary " (click)="goToPreviousModal('personalInfoModal')" style="border-radius: 20px;">Search</button>
          <button type="button" class="btn btn-dark confirm-btn ml-2" style="border-radius: 20px;">Enter Manually</button>
          
        </div>
      </form>
    </div>
    <div class="mt-5 p-2 d-flex flex-column">
      <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('userprofile')">Create Profile</button>
      <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="goToPreviousModal('personalInfoModal')"  style="border-radius: 20px;">Back</button>
    </div>
  </div>
</div>
</div>
<div class="card my-4">
  <div class="row">
              <div class="col-xl-12" *ngIf="!gbgResultView">
                  <div class="table-responsive mb-1">
                      <table *ngIf="getAllgbgDocument" class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                          hover="true" small="true">
                          <thead class="text-center">
                              <tr>
                                  <!-- <th [mdbTableSort]="gbgData" sortBy="firstName">Name <span>
                                          <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                      </span></th> -->
                                  <th [mdbTableSort]="gbgData" sortBy="akcessId">AKcess ID <span>
                                          <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                      </span></th>
                                  <th [mdbTableSort]="gbgData" sortBy="email">Person Entery Id<span>
                                          <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                      </span></th>
                                      <th [mdbTableSort]="gbgData" sortBy="name">Name<span>
                                          <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                      </span></th>
                                  <!-- <th [mdbTableSort]="gbgData" sortBy="phone">By Mobile No.<span>
                                          <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                      </span></th> -->
                                  <!--<th [mdbTableSort]="gbgData" sortBy="usertype">User Type <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>-->
                                  <th [mdbTableSort]="gbgData" sortBy="createdon">Date <span>
                                          <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                      </span></th>
                                  <!--<th [mdbTableSort]="gbgData" sortBy="removed_flg">Active <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>-->
                                  <!-- <th class="text-nowrap">User Type</th>
                                  <th class="text-nowrap">Status</th> -->
                                  <th class="text-nowrap">Action</th>
                              </tr>
                          </thead>
                          <tbody *ngIf="gbgData?.length == 0">
                              <tr>
                                  <td colspan="9" class="text-center">No Data Found</td>
                              </tr>
                          </tbody>
                          <tbody *ngIf="gbgData?.length > 0" class="text-center">
                              <tr [id]="data?._id" *ngFor="let data of gbgData ">
                                  <td (click)="veiwGbgDoc(data)">
                                      <a class="d-inline-block">
                                          {{data.accessId || '-'}}
                                      </a>
                                  </td>
                                  <td (click)="goprofile(data.entityId)">
                                      <a class="d-inline-block" >
                                          {{data.entityId || '-'}}
                                      </a>
                                  </td>
                                  <td>
                                      <a class="d-inline-block">
                                           {{data.firstName || '-'}} {{data.surname}}
                                      </a>
                                  </td>

                                  <td>
                                      <a class="d-inline-block">
                                          {{data.createdAt || '-'}}
                                      </a>
                                  </td>
                                  <!-- <td>
                                      <a class="d-inline-block">
                                          <span *ngIf="data.createdon">{{data.createdon | date:'medium'}}</span>
                                          <span *ngIf="!data.createdon">-</span>
                                      </a>
                                  </td> -->
                                  <td>
                                      <div class="actions-div">
                                          <button title="Remove Staff" class="btn btn-link delete_btn">
                                              <i class="fa fa-trash red-txt" aria-hidden="true"></i>
                                          </button>
                                      </div>
                                  </td>
                              </tr>
                          </tbody>
                          
                          <!-- <tfoot class="w-100">
                              <tr>
                                  <td colspan="7">
                                      <div class="table-page" *ngIf="gbgData?.length > 10">
                                          <div class="page-controls">
                                              <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                  class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                  nextLabel="Next"></pagination-controls>
                                          </div>
                                          <div class="page-size">
                                              <label class="mb-0 mr-2">Item per page</label>
                                              <select #val (change)="changePage(val.value)"
                                                  class="form-control form-control-sm" id="pageSelector">
                                                  <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                  </option>
                                              </select>
                                          </div>
                                      </div>
                                  </td>
                              </tr>
                          </tfoot> -->
                      </table>
                  </div>
              </div>
              <div class="col-xl-12" *ngIf="gbgResultView">
                  <div id="gbgResultPreview" class="m-2">
                      <div class="container">
                          <div class="row">
                              <div class="col-md-12 mb-2">
                                  <img src="../../../../assets/img/akcess-sme-logo.png" width="150px"
                                      alt="AKcess" class="me-4 mt-2" style="float:right">
                              </div>
                          </div>
                          <div class="row" >
                              <div class="col-md-12 cs-card" >
                                  <!-- <button class="btn btn-info" (click)="getSummeryResult()">Refresh</button> -->
                                  <h3
                                      style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
                                      Summary</h3>


                                      <table border="1">
                                          <tr>
                                            <th>Entry Id:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].entryId }}</td>
                                          </tr>
                                          <tr>
                                              <th>Name:</th>
                                              <td>{{ gbgUploadDocResult?.data?.firstName }}</td>
                                            </tr>
                                          <tr>
                                            <th>Document Id:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentId }}</td>
                                          </tr>
                                          <tr>
                                            <th>Document Type:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentType }}</td>
                                          </tr>
                                          <tr>
                                            <th>Liveness Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].livenessResult === 0 ? 'false' : 'true'}}</td>
                                          </tr>
                                          <tr>
                                            <th>Document Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentResult === 0 ? 'false' : 'true'}}</td>
                                          </tr>
                                          <tr>
                                            <th>Visual Analysis Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].visualAnalysisResult === 0 ? 'false' : 'true' }}</td>
                                          </tr>
                                          <tr>
                                            <th>NFC Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].nfcResult === 0 ? 'false' : 'true' }}</td>
                                          </tr>
                                          <tr>
                                            <th>Age Validation Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].ageValidationResult === 0 ? 'false' : 'true' }}</td>
                                          </tr>
                                          <tr>
                                            <th>Police Record Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].policeRecordResult === 0 ? 'false' : 'true' }}</td>
                                          </tr>
                                          <tr>
                                            <th>Compromised Alert Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].compromisedAlertResult === 0 ? 'false' : 'true' }}</td>
                                          </tr>
                                          <tr>
                                            <th>Repeat Attempts Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].repeatAttemptsResult }}</td>
                                          </tr>
                                          <tr>
                                            <th>Name Match Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].nameMatchResult === 0 ? 'false' : 'true'}}</td>
                                          </tr>
                                          <tr>
                                            <th>Face Match Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].faceMatchResult === 0 ? 'false' : 'true'}}</td>
                                          </tr>
                                          <tr>
                                            <th>Document Number:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentNumber }}</td>
                                          </tr>
                                          <tr>
                                            <th>Document Expiry Date:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentExpiryDate }}</td>
                                          </tr>
                                          <tr>
                                            <th>Document Birth Date:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentBirthDate }}</td>
                                          </tr>
                                          <tr>
                                            <th>Document Issuing Country Code:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentIssuingCountryCode }}</td>
                                          </tr>
                                          <tr>
                                            <th>Supporting Documents:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].supportingDocuments }}</td>
                                          </tr>
                                          <tr>
                                            <th>Entity Id:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].entityId }}</td>
                                          </tr>
                                          <tr>
                                            <th>Date Completed:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].dateCompleted }}</td>
                                          </tr>
                                          <tr>
                                            <th>Overall Result:</th>
                                            <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].overallResult === 0 ? 'false' : 'true'}}</td>
                                          </tr>
                                        </table>
                                      

                                  <!-- <p class="d-flex justify-content-between"> <b> Authentication Id :
                                      </b> {{gbgUploadDocResult?.PersonEntryId }}</p>

                                  <p class="d-flex justify-content-between"> <b> Ocr Only : </b>
                                      {{gbgUploadDocResult?.IsOcrOnly ? 'Yes' : 'No'}}</p>
                                  <p class="d-flex justify-content-between"> <b> High Level Result :
                                      </b> {{gbgUploadDocResult?.HighLevelResult}}</p>

                                  <p class="d-flex justify-content-between"> <b> High Level Result
                                          Details : </b> <span
                                          class="d-flex flex-column text-right"><span
                                              *ngFor="let item of gbgUploadDocResult?.HighLevelResultDetails">{{item}}</span></span>
                                  </p> -->
                                  <!-- <p class="d-flex justify-content-between" *ngFor="let item of gbgUploadDocResult?.HighLevelResultDetails"> <b> </b> {{item}}</p> -->
                                  <!-- <p class="d-flex justify-content-between"><b>Time Stamps : </b>
                                      {{gbgUploadDocResult?.InitiatedDateTime | date :
                                      'mm/dd/yyyy hh:mm:ss'}}
                                  </p> -->
                              </div>
                          </div>

                          <div class="row justify-content-around"
                              *ngFor="let x of gbgUploadDocResult?.ProcessedDocuments">
                              <div class="row w-100">
                                  <div class="col-md-12 cs-card">
                                      <h3
                                          style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
                                          Document Details</h3>
                                      <p class="d-flex justify-content-between"> <b> Issuing Country
                                              Name : </b> <span> {{x?.IssuingCountryName}} </span>
                                      </p>

                                      <p class="d-flex justify-content-between"> <b> Document Type :
                                          </b> <span> {{x?.DocumentType}} </span> </p>

                                      <p class="d-flex justify-content-between"> <b> Document Name :
                                          </b> <span> {{x?.DocumentName}} </span> </p>
                                  </div>
                              </div>
                              <div class="row cs-card">
                                  <h3
                                      style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
                                      Extracted Values</h3>
                                  <div class="col-md-6" *ngFor="let item of x?.ExtractedFields">
                                      <p> <b> {{item?.Name}} - </b> {{item?.Value}} </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex justify-content-start pt-3 mb-2">
                      <button type="submit" class="btn btn-outline-primary ms-2 mr-3"
                          (click)="printIdResultHTML()">
                          Print Html
                      </button>
                      <button type="submit" class="btn btn-primary ms-2"
                          (click)="exportIdResultPDF()">
                          Print Pdf
                      </button>

                  </div>
              </div>
  </div>
</div>



<!-- <div class="container-fluid">

	<tabset type="pills" class="m-1">
		<tab>
			<ng-template tabHeading>Sanctions / PEP</ng-template>
			
		</tab>

		<tab>
			<ng-template tabHeading>ID Verification</ng-template>
			<div class="card my-4">
				<div class="row">
                    <div class="col-xl-12" *ngIf="!gbgResultView">
                        <div class="table-responsive mb-1">
                            <table *ngIf="getAllgbgDocument" class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                                hover="true" small="true">
                                <thead class="text-center">
                                    <tr>
                                        
                                        <th [mdbTableSort]="gbgData" sortBy="akcessId">AKcess ID <span>
                                                <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                            </span></th>
                                        <th [mdbTableSort]="gbgData" sortBy="email">Person Entery Id<span>
                                                <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                            </span></th>
                                            <th [mdbTableSort]="gbgData" sortBy="name">Name<span>
                                                <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                            </span></th>
                                       
                                        <th [mdbTableSort]="gbgData" sortBy="createdon">Date <span>
                                                <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                            </span></th>
                                        
                                        <th class="text-nowrap">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="gbgData?.length == 0">
                                    <tr>
                                        <td colspan="9" class="text-center">No Data Found</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="gbgData?.length > 0" class="text-center">
                                    <tr [id]="data?._id" *ngFor="let data of gbgData | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                        <td (click)="veiwGbgDoc(data)">
                                            <a class="d-inline-block">
                                                {{data.accessId || '-'}}
                                            </a>
                                        </td>
                                        <td (click)="goprofile(data.entityId)">
                                            <a class="d-inline-block" >
                                                {{data.entityId || '-'}}
                                            </a>
                                        </td>
                                        <td>
                                            <a class="d-inline-block">
                                                {{data.result.firstName || '-'}}
                                            </a>
                                        </td>

                                        <td>
                                            <a class="d-inline-block">
                                                {{data.createdAt || '-'}}
                                            </a>
                                        </td>
                                        
                                        <td>
                                            <div class="actions-div">
                                                <button title="Remove Staff" class="btn btn-link delete_btn">
                                                    <i class="fa fa-trash red-txt" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                
                                <tfoot class="w-100">
                                    <tr>
                                        <td colspan="7">
                                            <div class="table-page" *ngIf="gbgData?.length > 10">
                                                <div class="page-controls">
                                                    <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                        class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                        nextLabel="Next"></pagination-controls>
                                                </div>
                                                <div class="page-size">
                                                    <label class="mb-0 mr-2">Item per page</label>
                                                    <select #val (change)="changePage(val.value)"
                                                        class="form-control form-control-sm" id="pageSelector">
                                                        <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div class="col-xl-12" *ngIf="gbgResultView">
                        <div id="gbgResultPreview" class="m-2">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12 mb-2">
                                        <img src="../../../../assets/img/akcess-sme-logo.png" width="150px"
                                            alt="AKcess" class="me-4 mt-2" style="float:right">
                                    </div>
                                </div>
                                <div class="row" >
                                    <div class="col-md-12 cs-card" >
                                      
                                        <h3
                                            style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
                                            Summary</h3>


                                            <table border="1">
                                                <tr>
                                                  <th>Entry Id:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].entryId }}</td>
                                                </tr>
                                                <tr>
                                                    <th>Name:</th>
                                                    <td>{{ gbgUploadDocResult?.data?.firstName }}</td>
                                                  </tr>
                                                <tr>
                                                  <th>Document Id:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentId }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Document Type:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentType }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Liveness Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].livenessResult === 0 ? 'false' : 'true'}}</td>
                                                </tr>
                                                <tr>
                                                  <th>Document Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentResult === 0 ? 'false' : 'true'}}</td>
                                                </tr>
                                                <tr>
                                                  <th>Visual Analysis Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].visualAnalysisResult === 0 ? 'false' : 'true' }}</td>
                                                </tr>
                                                <tr>
                                                  <th>NFC Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].nfcResult === 0 ? 'false' : 'true' }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Age Validation Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].ageValidationResult === 0 ? 'false' : 'true' }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Police Record Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].policeRecordResult === 0 ? 'false' : 'true' }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Compromised Alert Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].compromisedAlertResult === 0 ? 'false' : 'true' }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Repeat Attempts Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].repeatAttemptsResult }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Name Match Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].nameMatchResult === 0 ? 'false' : 'true'}}</td>
                                                </tr>
                                                <tr>
                                                  <th>Face Match Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].faceMatchResult === 0 ? 'false' : 'true'}}</td>
                                                </tr>
                                                <tr>
                                                  <th>Document Number:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentNumber }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Document Expiry Date:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentExpiryDate }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Document Birth Date:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentBirthDate }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Document Issuing Country Code:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentIssuingCountryCode }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Supporting Documents:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].supportingDocuments }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Entity Id:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].entityId }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Date Completed:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].dateCompleted }}</td>
                                                </tr>
                                                <tr>
                                                  <th>Overall Result:</th>
                                                  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].overallResult === 0 ? 'false' : 'true'}}</td>
                                                </tr>
                                              </table>
                                            

                                       
                                    </div>
                                </div>

                                <div class="row justify-content-around"
                                    *ngFor="let x of gbgUploadDocResult?.ProcessedDocuments">
                                    <div class="row w-100">
                                        <div class="col-md-12 cs-card">
                                            <h3
                                                style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
                                                Document Details</h3>
                                            <p class="d-flex justify-content-between"> <b> Issuing Country
                                                    Name : </b> <span> {{x?.IssuingCountryName}} </span>
                                            </p>

                                            <p class="d-flex justify-content-between"> <b> Document Type :
                                                </b> <span> {{x?.DocumentType}} </span> </p>

                                            <p class="d-flex justify-content-between"> <b> Document Name :
                                                </b> <span> {{x?.DocumentName}} </span> </p>
                                        </div>
                                    </div>
                                    <div class="row cs-card">
                                        <h3
                                            style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
                                            Extracted Values</h3>
                                        <div class="col-md-6" *ngFor="let item of x?.ExtractedFields">
                                            <p> <b> {{item?.Name}} - </b> {{item?.Value}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start pt-3 mb-2">
                            <button type="submit" class="btn btn-outline-primary ms-2 mr-3"
                                (click)="printIdResultHTML()">
                                Print Html
                            </button>
                            <button type="submit" class="btn btn-primary ms-2"
                                (click)="exportIdResultPDF()">
                                Print Pdf
                            </button>

                        </div>
                    </div>
				</div>
			</div>
		</tab>

		<tab>
			<ng-template tabHeading>Credit Check</ng-template>
			
		</tab>
		<tab>
			<ng-template tabHeading>Proof of Ownership</ng-template>
			
		</tab>
		<tab>
			<ng-template tabHeading>Right to Rent/Work</ng-template>
			
		</tab>
		<tab>
			<ng-template tabHeading>Quad-Id</ng-template>
			
		</tab>
		<tab>
			<ng-template tabHeading>World Check(KYC/AML)</ng-template>
			
		</tab>


	</tabset>
</div> -->
<script>
    document.addEventListener('DOMContentLoaded', function() {
        const deleteButtons = document.querySelectorAll('.delete_btn');
        deleteButtons.forEach(function(button) {
            button.addEventListener('click', function(event) {
                // Your delete logic here
                console.log('Delete button clicked');
                // e.g., remove the parent row or make an AJAX call to delete the item
            });
        });
    });
</script>