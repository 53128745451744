<div class="row page-titles mb-0">
	<div class="col-xl-2 col-md-2 col-sm-2 align-self-center">
		<h3 class="text-themecolor">Verifications</h3>
	</div>
	<div class="col-xl-3 col-md-3 col-sm-2">
		<button class="btn btn-info btn-sm" (click)="navigate()">Previous Checks</button>
	</div>
	<div class="col-xl-3 col-md-3  col-sm-2 align-self-center text-right" >
        <button  type="button"  class="btn btn-info btn-sm"  (click)="openPopup()"><i class="fa fa-plus"></i>  New  Action </button>
    </div>	
	<div class="col-xl-2 col-md-2 col-sm-3 align-self-center">
		<ol class="breadcrumb">
			<li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
			<li class="breadcrumb-item active">Verifications</li>
		</ol>
	</div>
	<div class="col-md-2 col-md-2 col-sm-3 align-self-center text-right" >
		<div class="text-right"> <b>Available Credit :</b>
			<span>1508</span>
		</div> 
    </div>
	
</div>






<!-- modal openPopup -->
<div class="modal " id="largeModal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}"> 
    <div class="modal-dialog modal-lg" role="document"> 
      <div class="modal-content"> 
        <div class="modal-header"> 
            <div class="d-flex flex-column">
          <h3 class="modal-title font-bold text-dark">Quick Key request</h3> 
          <p class="mt-2 mb-0" style="font-size: 14px;">Select the action you would like to perform or create an individual and get taken straight to their profile.
          </p>
        </div>

          <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('largeModal')">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>  
        <div class="modal-body">
            <div class="row mb-3" style="padding-left: 15px;">
              <h4 class="heding text-dark" style="font-weight: 600;">Create an individual</h4>
            </div>
            <div class="row">
              <div class="col-md-3" (change)="logSelectedValue('Send new invite to an individual')" >
                <input type="radio" id="Send new invite to an individual" name="profile" class="radio-input"> 
                 <label for="Send new invite to an individual" class="box" >
                    <img src=" ../../../../assets/img/send.png"> 
                    <p class="mt-2 mb-0" style="line-height: 20px;font-size: 16px;">Send new invite to an individual</p>
                    
                </label>
            </div>

            <div class="col-md-3" (change)="logSelectedValue('Create and go to profile')">
                <input type="radio" id="Create and go to profile" name="profile" class="radio-input" >  
                <label for="Create and go to profile" class="box">  
                    <img src=" ../../../../assets/img/search-profile.png">
                    <p  class="mt-2 mb-0 font-dark" style="line-height: 20px; font-size: 16px;">Create and go to profile</p>
                </label>
            </div>
              <!-- <div class="col-md-3" (change)="logSelectedValue('Create and ask to sign a document')">
                <input type="radio" id="Create and ask to sign a document" name="profile" class="radio-input" >
                  <label for="Create and ask to sign a document" class="box" >
                    
                    <img src=" ../../../../assets/img/report.png">
                  <p  class="mt-2"> Create and ask to sign a document</p>
                </label>
              </div> -->
              <div class="col-md-3">
                <!-- <div class="box">
                    <img src=" ../../../../assets/img/report.png">
                  <p> <b>Create and ask to sign a document</b></p>
                </div> -->
              </div>
            </div>
            <div  style="border-top:1px solid #ccc;margin-top: 20px;"></div>
            <div class="row mt-4 mb-3" style="padding-left: 15px;">
              <h4 class="heding text-dark"  style="font-weight: 600;">Create individual and run a check</h4>
            </div>
            <!-- <div class="row">
                <div class="col-md-3" *ngFor="let option of createIndividualOptions">
                    <div class="box" [class.selected]="selectedCreateIndividual === option.id" (click)="selectCreateIndividual(option.id)">
                        <img [src]="option.img" [alt]="option.alt">
                        <p><b>{{ option.text }}</b></p>
                        <input type="radio" [id]="option.id" name="create-individual" [checked]="selectedCreateIndividual === option.id">
                        <label [for]="option.id"><i class="fa fa-check"></i></label>
                    </div>
                </div>
            </div> -->
            <div class="row mb-4">
              <div class="col-md-3" (change)="logSelectedValue('Address and Mortality')">
                <input type="radio" id="Address & Mortality" name="profile" class="radio-input">
                  <label for="Address & Mortality" class="box">                   
                    <img src=" ../../../../assets/img/home-address.png">
                  <p  class="mt-2 mb-0" style="line-height: 20px; font-size: 16px;"> Address & Mortality</p>
                </label>
              </div>
              <div class="col-md-3" (change)="logSelectedValue('Sanctions and PEPs')">
                <input type="radio" id="Sanctions & PEPs" name="profile" class="radio-input">
                <label for="Sanctions & PEPs" class="box">                   
                    <img src=" ../../../../assets/img/globe.png">
                  <p  class="mt-2 mb-0" style="line-height: 20px; font-size: 16px;"> Sanctions & PEPs</p>
                </label>
              </div>
              <div class="col-md-3" (change)="logSelectedValue('Bank Account')">
                <input type="radio" id="Bank Account" name="profile" class="radio-input">
                <label for="Bank Account" class="box">                   
                    <img src=" ../../../../assets/img/bank.png">
                  <p  class="mt-2 mb-0" style="line-height: 20px; font-size: 16px;"> Bank Account</p>
                </label>
              </div>
              <!-- <div class="col-md-3" (change)="logSelectedValue('Open Banking & Affordability')">
                <input type="radio" id="Open Banking & Affordability" name="profile" class="radio-input">
                <label for="Open Banking & Affordability" class="box">                    
                    <img src=" ../../../../assets/img/money.png">
                  <p class="mt-2">Open Banking & Affordability</p>
                </label>
              </div> -->
            </div>
			<div id="validation-message" class="text-danger" *ngIf="validationMessage">{{ validationMessage }}</div>
          </div>
        <div class="mt-5 p-3  d-flex flex-column"> 
        <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('personalInfoModal')">  Confirm Details  </button>
    
        <button type="button" class="btn btn-outline-secondary col-md-12 mt-2"  (click)="closePopup('largeModal')" style="border-radius: 20px;"> cancel </button>                 
          
        </div> 
      </div> 
    </div> 
  </div>
  <!-- next modal -->
  <div class="modal" id="personalInfoModal" tabindex="-1" role="dialog" style="display:none;">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="d-flex flex-column">
          <h3 class="modal-title text-dark mb-1">Personal Information</h3>
            <p class="mb-0">Please Provide the details for the individual you would like to create.</p>
          </div>
          <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('personalInfoModal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="uploadForm">
            <label class="text-dark font-bold" for="firstName">Name</label>
            <div class="row">              
              <div class="col-md-6">
                <div class="form-group">                  
                  <input type="text" id="firstName" placeholder="First Name" formControlName="firstName" class="form-control"  [(ngModel)]="formdata.firstName"  required>
                </div>
              </div>
              </div>
              <div class="row"> 
              <div class="col-md-6">
                <div class="form-group">                  
                  <input type="text" id="lastName" placeholder="Surname" formControlName="lastName" class="form-control" [(ngModel)]="formdata.lastName"  required>
                </div>
              </div>
            </div>
            <div  style="border-top:1px solid #eee;margin-top: 20px;"></div>
            
            <div class="form-group mt-3">
              <label class="text-dark font-bold" for="birthdate">Date of birth <span style="font-weight: normal;">(optional)</span></label>
              <input type="date" id="birthdate" placeholder="DOB (optional) " formControlName="birthdate" class="form-control" [(ngModel)]="formdata.birthdate">
            </div>
			<div  style="border-top:1px solid #eee;margin-top: 30px;"></div>
            <div class="form-group">
              <label class="text-dark font-bold" for="reference">Your reference</label>
              <input type="text" id="reference" placeholder="Reference (optional) " formControlName="reference" class="form-control" [(ngModel)]="formdata.reference">
            </div>
            <div class="mt-5 p-2  d-flex flex-column"> 

              <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('contactInfoModal')">  Confirm Details  </button>
          
              <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="goToPreviousModal('largeModal')"  style="border-radius: 20px;"> Back </button>                 
                
              </div> 

            
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact Information Modal -->
<div class="modal" id="contactInfoModal" tabindex="-1" role="dialog" style="display:none;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="d-flex flex-column">
          <h3 class="modal-title text-dark">Contact information</h3>
          <p class="mb-0">Please provide the contact details for the individual you wish like to create.</p>
        </div>
        <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('contactInfoModal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="uploadForm">
          <div class="form-group">
            <label class="text-dark font-bold" for="email">Email</label>
            <input type="email" id="email" placeholder="Email address" formControlName="email" class="form-control" [(ngModel)]="formdata.email" required>
          </div>
          <div class="form-group">
            <label class="text-dark font-bold" for="telephone">Telephone</label>
            <div class="input-group">
              <select formControlName="countryCode" id="countryCode" class="custom-select" placeholder="Dailing code" [(ngModel)]="formdata.countryCode">
                <option value="+61">Australia +61</option>
                <option value="+33">France +33</option>
                <option value="+49">Germany +49 </option>
                <option value="+353">Ireland +353 </option>
                <option value="+39">Italy +39</option>
                <option value="+34">Spain +34 </option>
                <option value="+44">UK +44</option>
                <option value="+1">United States +1</option>
                <!-- Add more options as needed -->
              </select>
              <input type="tel" id="telephone" formControlName="telephone" class="form-control ml-2" placeholder="Mobile Number (optional)" [(ngModel)]="formdata.telephone">
            </div>
          </div>

          <div  style="border-top:1px solid #ccc;margin-top: 20px;"></div>

          <div class="form-group mt-3">
            <label class="text-dark font-bold" for="address">Address <span style="font-weight: normal;"> (Optional)</span></label>
            <textarea id="address" placeholder="Search using a postcode" formControlName="address" class="form-control" rows="3" [(ngModel)]="formdata.address"></textarea>
          </div>
          <div class="mt-2 p-2 d-flex">
            <button type="button" class="btn btn-outline-secondary " (click)="goToPreviousModal('personalInfoModal')" style="border-radius: 20px;">Search</button>
            <button type="button" class="btn btn-dark confirm-btn ml-2" style="border-radius: 20px;">Enter Manually</button>
            
          </div>
        </form>
      </div>
      <div class="mt-5 p-2 d-flex flex-column">
        <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('userprofile')">Create Profile</button>
        <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="goToPreviousModal('personalInfoModal')"  style="border-radius: 20px;">Back</button>
      </div>
    </div>
  </div>
</div>







<div class="container-fluid">

	<tabset type="pills" class="m-1">
		
		<tab>
			<ng-template tabHeading>ID Verification</ng-template>
			<div class="card my-4">
				<div class="row">
					<div class="col-xl-12">
						<div class="card-body text-black py-0">
							<div *ngIf="isGetStarted">
								<div class="pernal-details-form" [formGroup]="uploadForm">
									<h3 class="text-uppercase text-center">Verify your Identity</h3>
									<p class="text-center">Identity verificaion consist a few steps, and shouldn't take
										more than a minute or two. Don't worry, all your data is safe and secure. To
										complete the process you will need the following:</p>
									<div class="card">
										<div class="row w-100 p-2 d-flex flex-row justify-content-center">
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 1 - Good.png">
												<h4 class="mt-2 bold text-center">1 ID Document</h4>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 5 - Good.png">
												<h4 class="mt-2 bold text-center">A Selfie</h4>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 9 - Good.png">
												<h4 class="mt-2 bold text-center">A Proof of Address</h4>
											</div>
										</div>
									</div>
									<div class="d-flex justify-content-center pt-3 mb-2">
										<button type="submit" class="btn btn-primary ms-2" (click)="getStarted()">
											Get Started
										</button>
									</div>
								</div>
							</div>
							<div *ngIf="!isGetStarted">
								<div class="d-flex justify-content-center mb-3">
									<a class="inner-tabs" (click)="goToStep(5)"> <span class="step-number"
										[ngClass]="isIdDetails == true ? 'active-step-number' : ''">0</span> <span
										class="step-title"
										[ngClass]="isIdDetails == true ? 'active-step-title' : ''">Individual Details</span>
									</a>
									<a class="inner-tabs" (click)="goToStep(1)"> <span class="step-number"
											[ngClass]="isIdDocument == true ? 'active-step-number' : ''">1</span> <span
											class="step-title"
											[ngClass]="isIdDocument == true ? 'active-step-title' : ''">Upload ID</span>
									</a>
									<a class="inner-tabs" (click)="goToStep(2)"> <span class="step-number"
											[ngClass]="isSelfie == true ? 'active-step-number' : ''">2</span> <span
											class="step-title"
											[ngClass]="isSelfie == true ? 'active-step-title' : ''">Upload Selfie</span>
									</a>
									<a class="inner-tabs" (click)="goToStep(3)"> <span class="step-number"
											[ngClass]="isProofOfAddress == true ? 'active-step-number' : ''">3</span>
										<span class="step-title"
											[ngClass]="isProofOfAddress == true ? 'active-step-title' : ''">Upload
											Address Proof</span> </a>
									<a class="inner-tabs" (click)="goToStep(4)"> <span class="step-number"
											[ngClass]="isIDVerifiactionResult == true ? 'active-step-number' : ''">4</span>
										<span class="step-title">Results</span> </a>
								</div>
							</div>
							<div *ngIf="isIdDetails">
								<div class="pernal-details-form" [formGroup]="uploadForm">
									<h3 class="text-uppercase">Individual Details</h3>
									<div class="card">
									<div class="row w-100 p-2 d-flex flex-row" *ngIf="!showTips">
										<div class="col-md-4">
										<div class="form-group">
											<label class="form-label required" for="email">Email</label>
											<input type="email" id="email" class="form-control" [(ngModel)]="formData1.email" name="email" formControlName="email"/>
										</div>
										</div>
										<div class="col-md-4">
										<div class="form-group">
											<label class="form-label required" for="first_name">First Name</label>
											<input type="text" id="first_name" class="form-control" [(ngModel)]="formData1.first_name" name="first_name" formControlName="first_name"/>
										</div>
										</div>
										<div class="col-md-4">
										<div class="form-group">
											<label class="form-label required" for="sur_name">Last Name</label>
											<input type="text" id="sur_name" class="form-control" [(ngModel)]="formData1.sur_name" name="sur_name" formControlName="sur_name"/>
										</div>
										</div>
									</div>
									</div>
									<div class="d-flex justify-content-start pt-3 mb-2">
									<button type="button" class="btn btn-outline-primary ms-2 mr-3" (click)="goToStep(0)">
										Cancel
									</button>
									<button type="button" class="btn btn-primary ms-2" (click)="continueToIdDocument()">
										Continue
									</button>
									</div>
								</div>
								</div>

							  <div *ngIf="isIdDocument">
								<div class="pernal-details-form" [formGroup]="uploadForm">
								  <h3 class="text-uppercase">Upload a photo of the Identity Document</h3>
								  <div class="card">
									<a class="text-uppercase" (click)="showTips = !showTips" class="pe-auto"><i class="fa fa-info-circle" aria-hidden="true"></i> Photo Quality tips</a>
									<div class="row w-100 p-2 d-flex flex-row" *ngIf="!showTips">
									  <div class="col-md-3 d-flex flex-column">
										<img src="../../../../assets/img/AKcess IDverification 1 - Good.png">
										<span class="img-good"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Good</span>
										<p>Photo is clear, in focus, and direclty face on</p>
									  </div>
									  <div class="col-md-3 d-flex flex-column">
										<img src="../../../../assets/img/AKcess IDverification 2 - Bad.png">
										<span class="img-bad"><i class="fa fa-times-circle-o" aria-hidden="true"></i> Bad</span>
										<p>Photo must be show all four corners.</p>
									  </div>
									  <div class="col-md-3 d-flex flex-column">
										<img src="../../../../assets/img/AKcess IDverification 3 - Bad.png">
										<span class="img-bad"><i class="fa fa-times-circle-o" aria-hidden="true"></i> Bad</span>
										<p>Document must not be covered.</p>
									  </div>
									  <div class="col-md-3 d-flex flex-column">
										<img src="../../../../assets/img/AKcess IDverification 4 - Bad.png">
										<span class="img-bad"><i class="fa fa-times-circle-o" aria-hidden="true"></i> Bad</span>
										<p>Photo must not be blured.</p>
									  </div>
									</div>
								  </div>
								  <div>
									<div class="row">
									  <div class="col-md-6">
										<div class="form-group">
										  <label class="form-label required" for="frontImg">Identity Document - Front</label>
										  <input type="file" id="frontImg" class="form-control" (change)="onUploadChange($event)" />
										  <img *ngIf="frontPhoto" class="preview" [src]="frontPhoto" width="500" />
										</div>
									  </div>
									</div>
									<div class="row" *ngIf="isBackPhoto">
									  <div class="col-md-6">
										<div class="form-group">
										  <label class="form-label required" for="frontImg">Identity Document - Back</label>
										  <input type="file" id="frontImg" class="form-control" (change)="onUploadChange($event)" />
										  <img *ngIf="backPhoto" class="preview" [src]="backPhoto" width="500" />
										</div>
									  </div>
									</div>
								  </div>
								  <div class="d-flex justify-content-start pt-3 mb-2">
									<button type="submit" class="btn btn-outline-primary ms-2 mr-3" (click)="goToStep(0)">
									  Cancel
									</button>
									<button type="submit" class="btn btn-primary ms-2" (click)="gbgUploadDoc()" [disabled]="isDisable">
									  Continue
									</button>
								  </div>
								</div>
							  </div>
							  
							<div *ngIf="isSelfie">
								<div class="pernal-details-form">
									<h3 class="text-uppercase">Upload a Selfie</h3>
									<div class="card">
										<a class="text-uppercase" (click)="showTips = !showTips" class="pe-auto"><i
												class="fa fa-info-circle" aria-hidden="true"></i> Photo Quality tips</a>
										<div class="row w-100 p-2 d-flex flex-row" *ngIf="!showTips">
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 5 - Good.png">
												<span class="img-good"><i class="fa fa-check-circle-o"
														aria-hidden="true"></i> Good</span>
												<p>Photo is clear, in focus, and direclty face on</p>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 6 - Bad.png">
												<span class="img-bad"><i class="fa fa-times-circle-o"
														aria-hidden="true"></i> Bad</span>
												<p>Photo must be show all four corners.</p>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 7 - Bad.png">
												<span class="img-bad"><i class="fa fa-times-circle-o"
														aria-hidden="true"></i> Bad</span>
												<p>Document must not be covered.</p>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 8 - Bad.png">
												<span class="img-bad"><i class="fa fa-times-circle-o"
														aria-hidden="true"></i> Bad</span>
												<p>Photo must not be blured.</p>
											</div>
										</div>
									</div>
									<div>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="frontImg">Upload
														selfie</label>
													<input type="file" id="frontImg" class="form-control"
														(change)="onUploadChange($event)" />
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6">
												<img *ngIf="selfie" class="preview" src="{{ selfie }}" width="500" />
											</div>
										</div>
									</div>
									<div class="d-flex justify-content-start pt-3 mb-2">
										<button type="submit" class="btn btn-outline-primary ms-2 mr-3"
											(click)="goToStep(1)">
											Cancel
										</button>
										<button type="submit" class="btn btn-primary ms-2" (click)="gbgUploadDoc()"	*ngIf="isSelfie" [disabled]="!selfie">
											Continue
										</button>

									</div>
								</div>
							</div>
							<div *ngIf="isProofOfAddress">
								<div class="pernal-details-form">
									<h3 class="text-uppercase">Upload a Document</h3>
									<div class="card">
										<a class="text-uppercase" (click)="showTips = !showTips" class="pe-auto"><i
												class="fa fa-info-circle" aria-hidden="true"></i> Photo Quality tips</a>
										<div class="row w-100 p-2 d-flex flex-row" *ngIf="!showTips">
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 9 - Good.png">
												<span class="img-good"><i class="fa fa-check-circle-o"
														aria-hidden="true"></i> Good</span>
												<p>Photo is clear, in focus, and direclty face on</p>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 10 - Bad.png">
												<span class="img-bad"><i class="fa fa-times-circle-o"
														aria-hidden="true"></i> Bad</span>
												<p>Photo must be show all four corners.</p>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 11 - Bad.png">
												<span class="img-bad"><i class="fa fa-times-circle-o"
														aria-hidden="true"></i> Bad</span>
												<p>Document must not be covered.</p>
											</div>
											<div class="col-md-3 d-flex flex-column">
												<img src="../../../../assets/img/AKcess IDverification 12 - Bad.png">
												<span class="img-bad"><i class="fa fa-times-circle-o"
														aria-hidden="true"></i> Bad</span>
												<p>Photo must not be blured.</p>
											</div>
										</div>
									</div>
									<div>
										<div class="row">viewGbgDoc
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="frontImg">Upload Document
														for Address proof</label>
													<input type="file" id="frontImg" class="form-control"
														(change)="onUploadChange($event)" />
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-6">
												<img *ngIf="addressDoc" class="preview" src="{{ addressDoc }}"
													width="500" />
											</div>
										</div>
									</div>
									<div class="d-flex justify-content-start pt-3 mb-2">
										<button type="submit" class="btn btn-outline-primary ms-2 mr-3"
											(click)="goToStep(2)">
											Cancel
										</button>
										<button type="submit" class="btn btn-primary ms-2" (click)="gbgUploadDoc()"
											*ngIf="isProofOfAddress" [disabled]="!addressDoc">
											Continue
										</button>

									</div>
								</div>
							</div>
							<div *ngIf="isIDVerifiactionResult">
								<div class="pernal-details-form">
									<div class="d-flex ml-2">
										<h3 class="text-uppercase ml-2" *ngIf="gbgUploadDocResult">Result</h3>
										<button class="btn btn-info" (click)="getSummeryResult()">Refresh</button>
										
									</div>
									<div id="gbgResultPreview" class="m-2">
										<div class="container">
											<div class="row">
												<div class="col-md-12 mb-2">
													<img src="../../../../assets/img/akcess-sme-logo.png" width="150px"
														alt="AKcess" class="me-4 mt-2" style="float:right">
												</div>
											</div>
											<div class="row">
												<div class="col-md-12 cs-card" *ngIf="gbgUploadDocResult">
													<h3
														style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
														Summary</h3>
														<!-- <p class="d-flex justify-content-between"> <b> First Name: </b> {{ gbgUploadDocResult?.data?.firstName }} </p>
														<p class="d-flex justify-content-between"> <b> Last Name: </b> {{ gbgUploadDocResult?.data?.surname}} </p>
														<p class="d-flex justify-content-between"> <b> Email Address: </b> {{ gbgUploadDocResult?.data?.emailAddress}} </p>
														<p class="d-flex justify-content-between"> <b> Id: </b> {{ gbgUploadDocResult?.data?.id}} </p>																								
													<p class="d-flex justify-content-between"> <b> Date Created: </b> {{ gbgUploadDocResult?.data?.dateCreated}} </p>													
													<p class="d-flex justify-content-between"> <b> phoneNumber: </b> {{ gbgUploadDocResult?.data?.phoneNumber}} </p>
													<p class="d-flex justify-content-between"> <b> Entity Tags: </b> {{ gbgUploadDocResult?.data?.entityTags}} </p>
													<p class="d-flex justify-content-between"> <b> Registration Code: </b> {{ gbgUploadDocResult?.data?.registrationCode}} </p>
													<p class="d-flex justify-content-between"> <b> Status: </b> {{ gbgUploadDocResult?.data?.status}} </p>
													<p class="d-flex justify-content-between"> <b> Account Type: </b> {{ gbgUploadDocResult?.data?.accountType}} </p> -->

													<table border="1">
														<tr>
														  <th>Entry Id:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].entryId }}</td>
														</tr>
														<tr>
															<th>Name:</th>
															<td>{{ gbgUploadDocResult?.data?.firstName }}</td>
														  </tr>
														<tr>
														  <th>Document Id:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentId }}</td>
														</tr>
														<tr>
														  <th>Document Type:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentType }}</td>
														</tr>
														<tr>
														  <th>Liveness Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].livenessResult === 0 ? 'false' : 'true'}}</td>
														</tr>
														<tr>
														  <th>Document Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentResult === 0 ? 'false' : 'true'}}</td>
														</tr>
														<tr>
														  <th>Visual Analysis Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].visualAnalysisResult === 0 ? 'false' : 'true' }}</td>
														</tr>
														<tr>
														  <th>NFC Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].nfcResult === 0 ? 'false' : 'true' }}</td>
														</tr>
														<tr>
														  <th>Age Validation Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].ageValidationResult === 0 ? 'false' : 'true' }}</td>
														</tr>
														<tr>
														  <th>Police Record Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].policeRecordResult === 0 ? 'false' : 'true' }}</td>
														</tr>
														<tr>
														  <th>Compromised Alert Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].compromisedAlertResult === 0 ? 'false' : 'true' }}</td>
														</tr>
														<tr>
														  <th>Repeat Attempts Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].repeatAttemptsResult }}</td>
														</tr>
														<tr>
														  <th>Name Match Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].nameMatchResult === 0 ? 'false' : 'true'}}</td>
														</tr>
														<tr>
														  <th>Face Match Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].faceMatchResult === 0 ? 'false' : 'true'}}</td>
														</tr>
														<tr>
														  <th>Document Number:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentNumber }}</td>
														</tr>
														<tr>
														  <th>Document Expiry Date:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentExpiryDate }}</td>
														</tr>
														<tr>
														  <th>Document Birth Date:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentBirthDate }}</td>
														</tr>
														<tr>
														  <th>Document Issuing Country Code:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].documentIssuingCountryCode }}</td>
														</tr>
														<tr>
														  <th>Supporting Documents:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].supportingDocuments }}</td>
														</tr>
														<tr>
														  <th>Entity Id:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].entityId }}</td>
														</tr>
														<tr>
														  <th>Date Completed:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].dateCompleted }}</td>
														</tr>
														<tr>
														  <th>Overall Result:</th>
														  <td>{{ gbgUploadDocResult?.data?.identityVerifications[0].overallResult === 0 ? 'false' : 'true'}}</td>
														</tr>
													  </table>
													
														
														
														
														
														
														
														
														<!-- <p class="d-flex justify-content-between"> <b> entityId: </b> {{ gbgUploadDocResult.entityId || '-'}} </p> -->
														

														
														


													<!-- <p class="d-flex justify-content-between"> <b> Ocr Only : </b>
														{{gbgUploadDocResult?.data?.status == 0? 'Yes' : 'No'}}</p>
													<p class="d-flex justify-content-between"> <b> High Level Result :
														</b> {{gbgUploadDocResult?.data?.status}}</p>

														<p class="d-flex justify-content-between">
															<b>High Level Result Details:</b>
															<span class="d-flex flex-column text-right">
															  <span *ngFor="let item of gbgUploadDocResult?.data?.processes">
																Process Name: {{ item.processName }}<br>
																Process ID: {{ item.processId }}<br>
																Journey Name: {{ item.journeyName }}<br>
																Journey ID: {{ item.journeyId }}<br>
																Date Created: {{ item.dateCreated }}<br>
																Status: {{ item.processStatus }}
															  </span>
															</span>
														  </p> -->
														  
													
														  <!-- <p class="d-flex justify-content-between" *ngFor="let item of gbgUploadDocResult?.data?.HighLevelResultDetails"> <b> </b> {{item}}</p> -->

													<!-- <p class="d-flex justify-content-between"> <b> processId: </b> {{ gbgUploadDocResult?.data?.processes[0].processId  }} </p>
													<p class="d-flex justify-content-between"> <b> processName: </b> {{ gbgUploadDocResult?.data?.processes[0].processName  }} </p>
													<p class="d-flex justify-content-between"> <b> journeyId: </b> {{ gbgUploadDocResult?.data?.processes[0].journeyId  }} </p>
													<p class="d-flex justify-content-between"> <b> journeyName: </b> {{ gbgUploadDocResult?.data?.processes[0].journeyName  }} </p>
													<p class="d-flex justify-content-between"> <b> dateCreated: </b> {{ gbgUploadDocResult?.data?.processes[0].dateCreated  }} </p>
													<p class="d-flex justify-content-between"> <b> processStatus: </b> {{ gbgUploadDocResult?.data?.processes[0].processStatus  }} </p> -->
													
													<!-- <p class="d-flex justify-content-between"><b>Time Stamps : </b>
														{{gbgUploadDocResult?.data?.dateCreated | date :
														'mm/dd/yyyy hh:mm:ss'}}
													</p> -->
													

												</div>
											</div>

											<div class="row justify-content-around"
												*ngFor="let x of gbgUploadDocResult?.data?.ProcessedDocuments">
												<div class="row w-100">
													<div class="col-md-12 cs-card">
														<h3
															style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
															Document Details</h3>
														<p class="d-flex justify-content-between"> <b> Issuing Country
																Name : </b> <span> {{x?.IssuingCountryName}} </span>
														</p>

														<p class="d-flex justify-content-between"> <b> Document Type :
															</b> <span> {{x?.DocumentType}} </span> </p>

														<p class="d-flex justify-content-between"> <b> Document Name :
															</b> <span> {{x?.DocumentName}} </span> </p>
													</div>
												</div>
												<div class="row cs-card">
													<h3
														style="background-color: #007bff; color: #fff; width: 100%; padding: 2px 10px;">
														Extracted Values</h3>
													<div class="col-md-6" *ngFor="let item of x?.ExtractedFields">
														<p> <b> {{item?.Name}} - </b> {{item?.Value}} </p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="d-flex justify-content-start pt-3 mb-2">
										<button type="submit" class="btn btn-outline-primary ms-2 mr-3"
											(click)="printIdResultHTML()">
											Print Html
										</button>
										<button type="submit" class="btn btn-primary ms-2"
											(click)="exportIdResultPDF()">
											Print Pdf
										</button>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</tab>

		<tab>
			<ng-template tabHeading>Sanctions / PEP</ng-template>
			<tabset type="pills" class="m-1" #tabset1>
				<tab>
					<ng-template tabHeading> <i class="fa fa-user" aria-hidden="true"></i> &nbsp; Personal
						Details</ng-template>
					<div class="card my-4">
						<div class="row">
							<div class="col-xl-12">
								<div class="card-body text-black py-0">
									<div class="pernal-details-form">
										<h3 class="text-uppercase">Personal Details</h3>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="title">Title</label>
													<select class="select form-control form-select" id="title" [(ngModel)]="formData.title" required>
														<option value="'Mr'">Mr.</option>
														<option value="'Mrs'">Mrs.</option>
														<option value="'Ms'">Ms.</option>
														<option value="'Dr'">Dr.</option>
													</select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="gender">Gender</label>
													<select class="select form-control form-select" id="gender" [(ngModel)]="formData.gender" required> 
														<option value="'male'">Male</option>
														<option value="'female'">Female</option>
														<option value="'other'">Other</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="firstname">First
														Name</label>
														<input type="text" id="firstname" class="form-control" [(ngModel)]="formData.firstName" required/>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="middlename">Middle
														Name</label>
														<input type="text" id="middlename" class="form-control" [(ngModel)]="formData.middleName" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="lastname">Last Name</label>
													<input type="text" id="lastname" class="form-control" [(ngModel)]="formData.lastName" />
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="dob">Date of Birth</label>
													<input type="date" class="form-control" id="dob" [(ngModel)]="formData.dateOfBirth" required/>
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-end pt-3 mb-2">
											<button type="submit" class="btn btn-warning ms-2" (click)="gotoNext1(1)">
												Next
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity
						Documents</ng-template>
					<tabset type="pills" class="m-1" #tabsetId>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp;
								International Passport</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="internationalPassport">International
																Passport</label>
															<input type="text" id="internationalPassport" class="form-control"  [(ngModel)]="formData.internationalPassport" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"(click)="gotoNext1(2)">Next</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity	Card</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="identityCard">Identity Card </label>
															<input type="text" id="identityCard" class="form-control" [(ngModel)]="formData.identityCard"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<ngx-spinner  bdColor="rgba(51,51,51,0.8)"  size="medium"  color="#fff"  type="ball-scale-multiple">
														<p style="font-size: 20px; color: white">Loading...</p>
													</ngx-spinner>
													<button type="submit" class="btn btn-warning ms-2" (click)="getStarted()"(click)="gotoNext1(2)">Verify</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-home" aria-hidden="true"></i> &nbsp; Address</ng-template>
					<tabset type="pills" class="m-1" #address>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Current
								Address</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="addressFormat">Address Format</label>
															<input type="text" id="addressFormat" class="form-control" [(ngModel)]="formData.addressFormat"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" [(ngModel)]="formData.postcodeZip" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" [(ngModel)]="formData.buildingName"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"	class="form-control" [(ngModel)]="formData.buildingNumber" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"	class="form-control" [(ngModel)]="formData.subBuildingNameNumber"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"	class="form-control" [(ngModel)]="formData.companyOrganization" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" [(ngModel)]="formData.street"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" [(ngModel)]="formData.subStreet"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" [(ngModel)]="formData.cityTown"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"	class="form-control" [(ngModel)]="formData.stateDistrict"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control"  [(ngModel)]="formData.region"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control"  [(ngModel)]="formData.country"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"	id="firstYearOfResidency" [(ngModel)]="formData.firstYearOfResidency"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="lastYearOfResidency" [(ngModel)]="formData.lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNextAddress1(1)">Next</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous	Address 1</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip1" class="form-control" [(ngModel)]="formData.postcodeZip1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName1" class="form-control" [(ngModel)]="formData.buildingName1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber1"	class="form-control" [(ngModel)]="formData.buildingNumber1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber1"	class="form-control" [(ngModel)]="formData.subBuildingNameNumber1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization1"	class="form-control" [(ngModel)]="formData.companyOrganization1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street1" class="form-control" [(ngModel)]="formData.street1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet1" class="form-control" [(ngModel)]="formData.subStreet1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown1" class="form-control" [(ngModel)]="formData.cityTown1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict1"	class="form-control" [(ngModel)]="formData.stateDistrict1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region1" class="form-control" [(ngModel)]="formData.region1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country1" class="form-control" [(ngModel)]="formData.country1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"	id="firstYearOfResidency1" [(ngModel)]="formData.firstYearOfResidency1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="lastYearOfResidency1" [(ngModel)]="formData.lastYearOfResidency1"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2" (click)="gotoNextAddress2(2)">Next</button>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous	Address 2</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip2" class="form-control" [(ngModel)]="formData.postcodeZip2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName2" class="form-control" [(ngModel)]="formData.buildingName2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber2"	class="form-control" [(ngModel)]="formData.buildingNumber2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber2"	class="form-control" [(ngModel)]="formData.subBuildingNameNumber2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization2"	class="form-control" [(ngModel)]="formData.companyOrganization2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street2" class="form-control" [(ngModel)]="formData.street2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet2" class="form-control" [(ngModel)]="formData.subStreet2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown2" class="form-control" [(ngModel)]="formData.cityTown2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict2"	class="form-control" [(ngModel)]="formData.stateDistrict2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region2" class="form-control" [(ngModel)]="formData.region2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country2" class="form-control" [(ngModel)]="formData.country2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"	id="firstYearOfResidency2" [(ngModel)]="formData.firstYearOfResidency2"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="lastYearOfResidency2" [(ngModel)]="formData.lastYearOfResidency2"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNextAddress3(3)">	Next	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous	Address 3</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip3" class="form-control" [(ngModel)]="formData.postcodeZip3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName3" class="form-control" [(ngModel)]="formData.buildingName3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber3"	class="form-control" [(ngModel)]="formData.buildingNumber3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber3"	class="form-control" [(ngModel)]="formData.subBuildingNameNumber3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization3"	class="form-control" [(ngModel)]="formData.companyOrganization3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street3" class="form-control" [(ngModel)]="formData.street3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet3" class="form-control" [(ngModel)]="formData.subStreet3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown3" class="form-control" [(ngModel)]="formData.cityTown3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict3"	class="form-control" [(ngModel)]="formData.stateDistrict3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region3" class="form-control" [(ngModel)]="formData.region3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country3" class="form-control" [(ngModel)]="formData.country3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of Residency</label>
															<input type="date" class="form-control"	id="firstYearOfResidency3" [(ngModel)]="formData.firstYearOfResidency3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="lastYearOfResidency3" [(ngModel)]="formData.lastYearOfResidency3"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2" (click)="gotoNextAddress4(3)">	Verify	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
			</tabset>
		</tab>


		<tab>
			<ng-template tabHeading>Credit Check</ng-template>
			<tabset type="pills" class="m-1" #tabset3>
				<tab>
					<ng-template tabHeading> <i class="fa fa-user" aria-hidden="true"></i> &nbsp; Personal	Details</ng-template>
					<div class="card my-4">
						<div class="row">
							<div class="col-xl-12">
								<div class="card-body text-black py-0">
									<div class="pernal-details-form">
										<h3 class="text-uppercase">Personal Details</h3>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="title">Title</label>
													<select class="select form-control form-select" id="Credittitle" [(ngModel)]="formData.Credittitle">
														<option value="'Mr'">Mr.</option>
														<option value="'Mrs'">Mrs.</option>
														<option value="'Ms'">Ms.</option>
														<option value="'Dr'">Dr.</option>
													</select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="gender">Gender</label>
													<select class="select form-control form-select" id="Creditgender" [(ngModel)]="formData.Creditgender">
														<option value="'male'">Male</option>
														<option value="'female'">Female</option>
														<option value="'other'">Other</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="firstname">First Name</label>
													<input type="text" id="firstname" class="form-control" [(ngModel)]="formData.firstname"/>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="middlename">Middle Name</label>
													<input type="text" id="Creditmiddlename" class="form-control" [(ngModel)]="formData.Creditmiddlename"/>
												</div>
											</div>
											<div class="col-md-4">(click)="gotoNext1(1)
												<div class="form-group">
													<label class="form-label required" for="lastname">Last Name</label>
													<input type="text" id="Creditlastname" class="form-control" [(ngModel)]="formData.Creditlastname"/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="dob">Date of Birth</label>
													<input type="date" class="form-control" id="Creditdob" [(ngModel)]="formData.Creditdob"/>
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-end pt-3 mb-2">
											<button type="submit" class="btn btn-warning ms-2" (click)="gotoNext1(1)">	Next	</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity	Documents</ng-template>
					<tabset type="pills" class="m-1" #tabsetId>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp;
								International Passport</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="internationalPassport">International Passport</label>
															<input type="text" id="CreditinternationalPassport"	class="form-control" [(ngModel)]="formData.CreditinternationalPassport"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNext3(2)">	Next   </button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity	Card</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"	for="identityCard">Identity Card </label>
															<input type="text" id="CreditidentityCard" class="form-control" [(ngModel)]="formData.CreditidentityCard"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													
													<button type="submit" class="btn btn-warning ms-2" >
														Verify
													</button>
													<!-- <button  type="submit"  class="btn btn-warning ms-2"  (click)="verify()"  [disabled]="isLoading">
  																<ng-container *ngIf="isLoading; else verifyText">    <mat-spinner diameter="20"></mat-spinner>  </ng-container>
													<ng-template #verifyText>Verify</ng-template>
													</button> -->

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-home" aria-hidden="true"></i> &nbsp; Address</ng-template>
					<tabset type="pills" class="m-1" #address3>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Current	Address </ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"	for="addressFormat">Address Format</label>
															<input type="text" id="CreditaddressFormat "	class="form-control" [(ngModel)]="formData.CreditaddressFormat"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"	for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="CreditpostcodeZip" class="form-control" [(ngModel)]="formData.CreditpostcodeZip"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="CreditbuildingName" class="form-control" [(ngModel)]="formData.CreditbuildingName"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="CreditbuildingNumber"	class="form-control" [(ngModel)]="formData.CreditbuildingNumber"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /	Number</label>
															<input type="text" id="CreditsubBuildingNameNumber"	class="form-control" [(ngModel)]="formData.CreditsubBuildingNameNumber"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="CreditcompanyOrganization"	class="form-control" [(ngModel)]="formData.CreditcompanyOrganization" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Creditstreet" class="form-control" [(ngModel)]="formData.Creditstreet"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub	Street</label>
															<input type="text" id="CreditsubStreet" class="form-control" [(ngModel)]="formData.CreditsubStreet"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City / Town</label>
															<input type="text" id="CreditcityTown" class="form-control" [(ngModel)]="formData.CreditcityTown"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State / District</label>
															<input type="text" id="CreditstateDistrict" 	class="form-control" [(ngModel)]="formData.CreditstateDistrict" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Creditregion" class="form-control" [(ngModel)]="formData.Creditregion"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="Creditcountry" class="form-control" [(ngModel)]="formData.Creditcountry"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of Residency</label>
															<input type="date" class="form-control"	id="CreditfirstYearOfResidency" [(ngModel)]="formData.CreditfirstYearOfResidency"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="CreditlastYearOfResidency" [(ngModel)]="formData.CreditlastYearOfResidency"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNextAddressCredit(1)">	Next </button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous	Address 1</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"	for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="CreditpostcodeZip1" class="form-control" [(ngModel)]="formData.CreditpostcodeZip1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="CreditbuildingName1" class="form-control" [(ngModel)]="formData.CreditbuildingName1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="CreditbuildingNumber1"	class="form-control" [(ngModel)]="formData.CreditbuildingNumber1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name / Number</label>
															<input type="text" id="CreditsubBuildingNameNumber1"	class="form-control" [(ngModel)]="formData.CreditsubBuildingNameNumber1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="CreditcompanyOrganization1"	class="form-control" [(ngModel)]="formData.CreditcompanyOrganization1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Creditstreet1" class="form-control" [(ngModel)]="formData.Creditstreet1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub	Street</label>
															<input type="text" id="CreditsubStreet1" class="form-control" [(ngModel)]="formData.CreditsubStreet1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="CreditcityTown1" class="form-control" [(ngModel)]="formData.CreditcityTown1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State	/ District</label>
															<input type="text" id="CreditstateDistrict1"	class="form-control" [(ngModel)]="formData.CreditstateDistrict1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Creditregion1" class="form-control" [(ngModel)]="formData.Creditregion1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="Creditcountry1" class="form-control" [(ngModel)]="formData.Creditcountry1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of Residency</label>
															<input type="date" class="form-control"	id="CreditfirstYearOfResidency1" [(ngModel)]="formData.CreditfirstYearOfResidency1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="CreditlastYearOfResidency1" [(ngModel)]="formData.CreditlastYearOfResidency1"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNextAddress2(2)"> Next	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous	Address 2</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" [(ngModel)]="formData.postcodeZip"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="CreditbuildingName" class="form-control" [(ngModel)]="formData.CreditbuildingName"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="CreditbuildingNumber"	class="form-control" [(ngModel)]="formData.CreditbuildingNumber"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /	Number</label>
															<input type="text" id="CreditsubBuildingNameNumber"	class="form-control" [(ngModel)]="formData.CreditsubBuildingNameNumber"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="CreditcompanyOrganization"	class="form-control" [(ngModel)]="formData.CreditcompanyOrganization"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Creditstreet" class="form-control" [(ngModel)]="formData.Creditstreet"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="CreditsubStreet" class="form-control" [(ngModel)]="formData.CreditsubStreet"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="CreditcityTown" class="form-control" [(ngModel)]="formData.CreditcityTown"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="CreditstateDistrict"	class="form-control" [(ngModel)]="formData.CreditstateDistrict"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Creditregion" class="form-control" [(ngModel)]="formData.Creditregion"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="Creditcountry" class="form-control" [(ngModel)]="formData.Creditcountry"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"	id="CreditfirstYearOfResidency" [(ngModel)]="formData.CreditfirstYearOfResidency"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="CreditlastYearOfResidency" [(ngModel)]="formData.CreditlastYearOfResidency"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNextAddress3(3)">	Next</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous	Address 3</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="CreditpostcodeZip3" class="form-control" [(ngModel)]="formData.CreditpostcodeZip3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="CreditbuildingName3" class="form-control" [(ngModel)]="formData.CreditbuildingName3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="CreditbuildingNumber3"	class="form-control" [(ngModel)]="formData.CreditbuildingNumber3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="CreditsubBuildingNameNumber3"	class="form-control" [(ngModel)]="formData.CreditsubBuildingNameNumber3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="CreditcompanyOrganization3"	class="form-control" [(ngModel)]="formData.CreditcompanyOrganization3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Creditstreet3" class="form-control" [(ngModel)]="formData.Creditstreet3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub	Street</label>
															<input type="text" id="CreditsubStreet3" class="form-control" [(ngModel)]="formData.CreditsubStreet3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City / Town</label>
															<input type="text" id="CreditcityTown3" class="form-control" [(ngModel)]="formData.CreditcityTown3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="CreditstateDistrict3"	class="form-control" [(ngModel)]="formData.CreditstateDistrict3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Creditregion3" class="form-control" [(ngModel)]="formData.Creditregion3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country3" class="form-control" [(ngModel)]="formData.Creditcountry3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"	id="CreditfirstYearOfResidency3" [(ngModel)]="formData.CreditfirstYearOfResidency3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="CreditlastYearOfResidency3" [(ngModel)]="formData.CreditlastYearOfResidency3"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2" (click)="gotoNextAddressCredit3(3)">	Verify	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
			</tabset>
		</tab>
		<tab>
			<ng-template tabHeading>Proof of Ownership</ng-template>
			<tabset type="pills" class="m-1" #tabset4>
				<tab>
					<ng-template tabHeading> <i class="fa fa-user" aria-hidden="true"></i> &nbsp; Personal	Details</ng-template>
					<div class="card my-4">
						<div class="row">
							<div class="col-xl-12">
								<div class="card-body text-black py-0">
									<div class="pernal-details-form">
										<h3 class="text-uppercase">Personal Details</h3>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="title">Title</label>
													<select class="select form-control form-select" id="title" [(ngModel)]="formData.title">
														<option value="'Mr'">Mr.</option>
														<option value="'Mrs'">Mrs.</option>
														<option value="'Ms'">Ms.</option>
														<option value="'Dr'">Dr.</option>
													</select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="gender">Gender</label>
													<select class="select form-control form-select" id="gender" [(ngModel)]="formData.gender">
														<option value="'male'">Male</option>
														<option value="'female'">Female</option>
														<option value="'other'">Other</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="firstname">First
														Name</label>
													<input type="text" id="firstname" class="form-control" [(ngModel)]="formData.firstname"/>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="middlename">Middle
														Name</label>
													<input type="text" id="middlename" class="form-control" [(ngModel)]="formData.middlename"/>
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="lastname">Last Name</label>
													<input type="text" id="lastname" class="form-control" [(ngModel)]="formData.lastname"/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="dob">Date of Birth</label>
													<input type="date" class="form-control" id="dob" [(ngModel)]="formData.dob"/>
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-end pt-3 mb-2">
											<button type="submit" class="btn btn-warning ms-2" (click)="gotoNext4(1)">	Next</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity
						Documents</ng-template>
					<tabset type="pills" class="m-1" #tabsetId>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp;
								International Passport</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="internationalPassport">International
																Passport</label>
															<input type="text" id="internationalPassport"	class="form-control" [(ngModel)]="formData.internationalPassport"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNext4(2)">	Next	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity	Card</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="identityCard">Identity Card </label>
															<input type="text" id="identityCard" class="form-control" [(ngModel)]="formData.identityCard"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2">	Verify	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-home" aria-hidden="true"></i> &nbsp; Address</ng-template>
					<tabset type="pills" class="m-1" #address4>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Current	Address</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="addressFormat">Address Format</label>
															<input type="text" id="proofaddressFormat"	class="form-control" [(ngModel)]="formData.proofaddressFormat"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="proofpostcodeZip" class="form-control" [(ngModel)]="formData.proofpostcodeZip"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="proofbuildingName" class="form-control" [(ngModel)]="formData.proofbuildingName"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="proofbuildingNumber"	class="form-control" [(ngModel)]="formData.proofbuildingNumber"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="proofsubBuildingNameNumber"	class="form-control" [(ngModel)]="formData.proofsubBuildingNameNumber"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="proofcompanyOrganization"	class="form-control" [(ngModel)]="formData.proofcompanyOrganization"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="proofstreet" class="form-control" [(ngModel)]="formData.proofstreet"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="proofsubStreet" class="form-control" [(ngModel)]="formData.proofsubStreet"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="proofcityTown" class="form-control" [(ngModel)]="formData.proofcityTown"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="proofstateDistrict"	class="form-control" [(ngModel)]="formData.proofstateDistrict"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="proofregion" class="form-control" [(ngModel)]="formData.proofregion"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="proofcountry" class="form-control" [(ngModel)]="formData.proofcountry"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"	id="prooffirstYearOfResidency" [(ngModel)]="formData.prooffirstYearOfResidency"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="prooflastYearOfResidency" [(ngModel)]="formData.prooflastYearOfResidency"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"Z	(click)="gotoNextAddress1(1)">	Next	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 1</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="proofpostcodeZip1" class="form-control" [(ngModel)]="formData.proofpostcodeZip1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="proofbuildingName1" class="form-control" [(ngModel)]="formData.proofbuildingName1"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="proofbuildingNumber1"	class="form-control"  [(ngModel)]="formData.proofbuildingNumber1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /Number</label>
															<input type="text" id="proofsubBuildingNameNumber1"	class="form-control"  [(ngModel)]="formData.proofsubBuildingNameNumber1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="proofcompanyOrganization1"		class="form-control"  [(ngModel)]="formData.proofcompanyOrganization1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="proofstreet1" class="form-control"  [(ngModel)]="formData.proofstreet1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="proofsubStreet1" class="form-control"  [(ngModel)]="formData.proofsubStreet1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="proofcityTown1" class="form-control"  [(ngModel)]="formData.proofcityTown1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="proofstateDistrict1"
																class="form-control"  [(ngModel)]="formData.proofstateDistrict1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="proofregion1" class="form-control"  [(ngModel)]="formData.proofregion1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="proofcountry1" class="form-control"  [(ngModel)]="formData.proofcountry1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"  [(ngModel)]="formData.prooffirstYearOfResidency1"
																id="prooffirstYearOfResidency1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"  [(ngModel)]="formData.prooflastYearOfResidency1"
																id="prooflastYearOfResidency1" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNextAddress2(2)">	Next	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous	Address 2</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="proofpostcodeZip2" class="form-control"  [(ngModel)]="formData.proofpostcodeZip2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="proofbuildingName2" class="form-control"  [(ngModel)]="formData.proofbuildingName2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="proofbuildingNumber2"
																class="form-control"  [(ngModel)]="formData.proofbuildingNumber2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="proofsubBuildingNameNumber2"
																class="form-control"  [(ngModel)]="formData.proofsubBuildingNameNumber2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="proofcompanyOrganization2"
																class="form-control"  [(ngModel)]="formData.proofcompanyOrganization2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="proofstreet2" class="form-control"  [(ngModel)]="formData.proofstreet2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="proofsubStreet2" class="form-control"  [(ngModel)]="formData.proofsubStreet2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="proofcityTown2" class="form-control"  [(ngModel)]="formData.proofcityTown2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State/ District</label>
															<input type="text" id="proofstateDistrict2"	class="form-control"  [(ngModel)]="formData.proofstateDistrict2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="proofregion2" class="form-control"  [(ngModel)]="formData.proofregion2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="proofcountry2" class="form-control"  [(ngModel)]="formData.proofcountry2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"  [(ngModel)]="formData.prooffirstYearOfResidency2"
																id="prooffirstYearOfResidency2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"  [(ngModel)]="formData.prooflastYearOfResidency2"
																id="prooflastYearOfResidency2" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress3(3)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 3</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="proofpostcodeZip3" class="form-control"  [(ngModel)]="formData.proofpostcodeZip3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="proofbuildingName3" class="form-control"  [(ngModel)]="formData.proofbuildingName3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="proofbuildingNumber3"	class="form-control"  [(ngModel)]="formData.proofbuildingNumber3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="proofsubBuildingNameNumber3"	class="form-control"  [(ngModel)]="formData.proofsubBuildingNameNumber3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="proofcompanyOrganization3"	class="form-control"  [(ngModel)]="formData.proofcompanyOrganization3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="proofstreet3" class="form-control"  [(ngModel)]="formData.proofstreet3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="proofsubStreet3" class="form-control" [(ngModel)]="formData.proofsubStreet3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="proofcityTown3" class="form-control" [(ngModel)]="formData.proofcityTown3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="proofstateDistrict3"	class="form-control" [(ngModel)]="formData.proofstateDistrict3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="proofregion3" class="form-control" [(ngModel)]="formData.proofregion3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="proofcountry3" class="form-control" [(ngModel)]="formData.proofcountry3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"	id="prooffirstYearOfResidency3" [(ngModel)]="formData.prooffirstYearOfResidency3"/>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"	id="prooflastYearOfResidency3" [(ngModel)]="formData.prooflastYearOfResidency3"/>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"  (click)="gotoNextAddress3(3)">
														Verify
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
			</tabset>
		</tab>
		<tab>
			<ng-template tabHeading>Right to Rent/Work</ng-template>
			<tabset type="pills" class="m-1" #tabset5>
				<tab>
					<ng-template tabHeading> <i class="fa fa-user" aria-hidden="true"></i> &nbsp; Personal	Details</ng-template>
					<div class="card my-4">
						<div class="row">
							<div class="col-xl-12">
								<div class="card-body text-black py-0">
									<div class="pernal-details-form">
										<h3 class="text-uppercase">Personal Details</h3>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="title">Title</label>
													<select class="select form-control form-select" id="Renttitle" [(ngModel)]="formData.Renttitle">
														<option value="'Mr'">Mr.</option>
														<option value="'Mrs'">Mrs.</option>
														<option value="'Ms'">Ms.</option>
														<option value="'Dr'">Dr.</option>
													</select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="gender">Gender</label>
													<select class="select form-control form-select" id="Rentgender" [(ngModel)]="formData.Rentgender">
														<option value="'male'">Male</option>
														<option value="'female'">Female</option>
														<option value="'other'">Other</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="firstname">First
														Name</label>
													<input type="text" id="Rentfirstname" class="form-control" [(ngModel)]="formData.Rentfirstname" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="middlename">Middle	Name</label>
													<input type="text" id="Rentmiddlename" class="form-control" [(ngModel)]="formData.Rentmiddlename" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="lastname">Last Name</label>
													<input type="text" id="Rentlastname" class="form-control" [(ngModel)]="formData.Rentlastname" />
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="dob">Date of Birth</label>
													<input type="date" class="form-control" [(ngModel)]="formData.Rentdob" id="Rentdob" />
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-end pt-3 mb-2">
											<button type="submit" class="btn btn-warning ms-2" (click)="gotoNext5(1)">
												Next
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity
						Documents</ng-template>
					<tabset type="pills" class="m-1" #tabsetId>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp;
								International Passport</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="internationalPassport">International
																Passport</label>
															<input type="text" id="RentinternationalPassport"	class="form-control" [(ngModel)]="formData.RentinternationalPassport" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"	(click)="gotoNext5(2)">	Next	</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity	Card</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="identityCard">Identity Card </label>
															<input type="text" id="RentidentityCard" class="form-control" [(ngModel)]="formData.RentidentityCard" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2">
														Verify
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-home" aria-hidden="true"></i> &nbsp; Address</ng-template>
					<tabset type="pills" class="m-1" #address5>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Current
								Address</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="addressFormat">Address Format</label>
															<input type="text" id="RentaddressFormat" 	class="form-control" [(ngModel)]="formData.RentaddressFormat" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="RentpostcodeZip" class="form-control" [(ngModel)]="formData.RentpostcodeZip" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" [(ngModel)]="formData.buildingName" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="RentbuildingNumber"	class="form-control" [(ngModel)]="formData.RentbuildingNumber" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" [(ngModel)]="formData.subBuildingNameNumber" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="RentcompanyOrganization"
																class="form-control" [(ngModel)]="formData.RentcompanyOrganization" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Rentstreet" class="form-control" [(ngModel)]="formData.Rentstreet" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="RentsubStreet" class="form-control" [(ngModel)]="formData.RentsubStreet" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="RentcityTown" class="form-control" [(ngModel)]="formData.RentcityTown" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="RentstateDistrict"
																class="form-control" [(ngModel)]="formData.RentstateDistrict" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Rentregion" class="form-control" [(ngModel)]="formData.Rentregion" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="Rentcountry" class="form-control" [(ngModel)]="formData.Rentcountry" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentfirstYearOfResidency"
																id="RentfirstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentlastYearOfResidency"
																id="RentlastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress5(1)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 1</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="RentpostcodeZip1" class="form-control" [(ngModel)]="formData.RentpostcodeZip1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="RentbuildingName1" class="form-control" [(ngModel)]="formData.RentbuildingName1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="RentbuildingNumber1"	class="form-control" [(ngModel)]="formData.RentbuildingNumber1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="RentsubBuildingNameNumber1"	class="form-control" [(ngModel)]="formData.RentsubBuildingNameNumber1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="RentcompanyOrganization1"
																class="form-control" [(ngModel)]="formData.RentcompanyOrganization1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Rentstreet1" class="form-control" [(ngModel)]="formData.Rentstreet1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="RentsubStreet1" class="form-control" [(ngModel)]="formData.RentsubStreet1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="RentcityTown1" class="form-control" [(ngModel)]="formData.RentcityTown1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="RentstateDistrict1"	class="form-control" [(ngModel)]="formData.RentstateDistrict1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Rentregion1" class="form-control" [(ngModel)]="formData.Rentregion1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="Rentcountry1" class="form-control" [(ngModel)]="formData.Rentcountry1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentfirstYearOfResidency1"
																id="RentfirstYearOfResidency1" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentlastYearOfResidency1"
																id="RentlastYearOfResidency1" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress2(2)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 2</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="RentpostcodeZip2" class="form-control" [(ngModel)]="formData.RentpostcodeZip2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="RentbuildingName2" class="form-control" [(ngModel)]="formData.RentbuildingName2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="RentbuildingNumber2"	class="form-control" [(ngModel)]="formData.RentbuildingNumber2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="RentsubBuildingNameNumber2"	class="form-control" [(ngModel)]="formData.RentsubBuildingNameNumber2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="RentcompanyOrganization2"	class="form-control" [(ngModel)]="formData.RentcompanyOrganization2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Rentstreet2" class="form-control" [(ngModel)]="formData.Rentstreet2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="RentsubStreet2" class="form-control" [(ngModel)]="formData.RentsubStreet2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="RentcityTown2" class="form-control" [(ngModel)]="formData.RentcityTown2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="RentstateDistrict2"	class="form-control" [(ngModel)]="formData.RentstateDistrict2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Rentregion2" class="form-control" [(ngModel)]="formData.Rentregion2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="Rentcountry2" class="form-control" [(ngModel)]="formData.Rentcountry2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentfirstYearOfResidency2"
																id="RentfirstYearOfResidency2" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentlastYearOfResidency2"
																id="RentlastYearOfResidency2" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress3(3)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 3</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="RentpostcodeZip3" class="form-control" [(ngModel)]="formData.RentpostcodeZip3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="RentbuildingName3" class="form-control" [(ngModel)]="formData.RentbuildingName3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="RentbuildingNumber3"
																class="form-control" [(ngModel)]="formData.RentbuildingNumber3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="RentsubBuildingNameNumber3"
																class="form-control" [(ngModel)]="formData.RentsubBuildingNameNumber3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="RentcompanyOrganization3"
																class="form-control" [(ngModel)]="formData.RentcompanyOrganization3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="Rentstreet3" class="form-control" [(ngModel)]="formData.Rentstreet3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="RentsubStreet3" class="form-control" [(ngModel)]="formData.RentsubStreet3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="RentcityTown3" class="form-control" [(ngModel)]="formData.RentcityTown3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="RentstateDistrict3"
																class="form-control" [(ngModel)]="formData.RentstateDistrict3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="Rentregion3" class="form-control" [(ngModel)]="formData.Rentregion3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="Rentcountry3" class="form-control" [(ngModel)]="formData.Rentcountry3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentfirstYearOfResidency3"
																id="RentfirstYearOfResidency3" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control" [(ngModel)]="formData.RentlastYearOfResidency3"
																id="RentlastYearOfResidency3" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2" (click)="gotoNextAddress3(3)">
														Verify
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
			</tabset>
		</tab>
		<tab>
			<ng-template tabHeading>Quad-Id</ng-template>
			<tabset type="pills" class="m-1" #tabset6>
				<tab>
					<ng-template tabHeading> <i class="fa fa-user" aria-hidden="true"></i> &nbsp; Personal
						Details</ng-template>
					<div class="card my-4">
						<div class="row">
							<div class="col-xl-12">
								<div class="card-body text-black py-0">
									<div class="pernal-details-form">
										<h3 class="text-uppercase">Personal Details</h3>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="title">Title</label>
													<select class="select form-control form-select" id="title">
														<option value="'Mr'">Mr.</option>
														<option value="'Mrs'">Mrs.</option>
														<option value="'Ms'">Ms.</option>
														<option value="'Dr'">Dr.</option>
													</select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="gender">Gender</label>
													<select class="select form-control form-select" id="gender">
														<option value="'male'">Male</option>
														<option value="'female'">Female</option>
														<option value="'other'">Other</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="firstname">First
														Name</label>
													<input type="text" id="firstname" class="form-control" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="middlename">Middle
														Name</label>
													<input type="text" id="middlename" class="form-control" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="lastname">Last Name</label>
													<input type="text" id="lastname" class="form-control" />
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="dob">Date of Birth</label>
													<input type="date" class="form-control" id="dob" />
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-end pt-3 mb-2">
											<button type="submit" class="btn btn-warning ms-2" (click)="gotoNext6(1)">
												Next
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity
						Documents</ng-template>
					<tabset type="pills" class="m-1" #tabsetId>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp;
								International Passport</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="internationalPassport">International
																Passport</label>
															<input type="text" id="internationalPassport"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNext6(2)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity
								Card</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="identityCard">Identity Card </label>
															<input type="text" id="identityCard" class="form-control" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2">
														Verify
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-home" aria-hidden="true"></i> &nbsp; Address</ng-template>
					<tabset type="pills" class="m-1" #address6>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Current
								Address</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="addressFormat">Address Format</label>
															<input type="text" id="addressFormat"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress6(1)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 1</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress6(2)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 2</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress6(3)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 3</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2">
														Verify
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
			</tabset>
		</tab>
		<tab>
			<ng-template tabHeading>World Check(KYC/AML)</ng-template>
			<tabset type="pills" class="m-1" #tabset7>
				<tab>
					<ng-template tabHeading> <i class="fa fa-user" aria-hidden="true"></i> &nbsp; Personal
						Details</ng-template>
					<div class="card my-4">
						<div class="row">
							<div class="col-xl-12">
								<div class="card-body text-black py-0">
									<div class="pernal-details-form">
										<h3 class="text-uppercase">Personal Details</h3>
										<div class="row">
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="title">Title</label>
													<select class="select form-control form-select" id="title">
														<option value="'Mr'">Mr.</option>
														<option value="'Mrs'">Mrs.</option>
														<option value="'Ms'">Ms.</option>
														<option value="'Dr'">Dr.</option>
													</select>
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<label class="form-label required" for="gender">Gender</label>
													<select class="select form-control form-select" id="gender">
														<option value="'male'">Male</option>
														<option value="'female'">Female</option>
														<option value="'other'">Other</option>
													</select>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="firstname">First
														Name</label>
													<input type="text" id="firstname" class="form-control" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="middlename">Middle
														Name</label>
													<input type="text" id="middlename" class="form-control" />
												</div>
											</div>
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="lastname">Last Name</label>
													<input type="text" id="lastname" class="form-control" />
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-md-4">
												<div class="form-group">
													<label class="form-label required" for="dob">Date of Birth</label>
													<input type="date" class="form-control" id="dob" />
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-end pt-3 mb-2">
											<button type="submit" class="btn btn-warning ms-2" (click)="gotoNext7(1)">
												Next
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity
						Documents</ng-template>
					<tabset type="pills" class="m-1" #tabsetId>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp;
								International Passport</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="internationalPassport">International
																Passport</label>
															<input type="text" id="internationalPassport"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNext7(2)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Identity
								Card</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="pernal-details-form">
												<div class="row">
													<div class="col-md-8">
														<div class="form-group">
															<label class="form-label required"
																for="identityCard">Identity Card </label>
															<input type="text" id="identityCard" class="form-control" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2">
														Verify
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
				<tab>
					<ng-template tabHeading><i class="fa fa-home" aria-hidden="true"></i> &nbsp; Address</ng-template>
					<tabset type="pills" class="m-1" #address7>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Current
								Address</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="addressFormat">Address Format</label>
															<input type="text" id="addressFormat"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress7(1)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 1</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress7(2)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 2</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2"
														(click)="gotoNextAddress7(3)">
														Next
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
						<tab>
							<ng-template tabHeading><i class="fa fa-id-card-o" aria-hidden="true"></i> &nbsp; Previous
								Address 3</ng-template>
							<div class="card my-4">
								<div class="row">
									<div class="col-xl-12">
										<div class="card-body text-black py-0">
											<div class="address-form">
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="postcodeZip">Postcode / Zip</label>
															<input type="text" id="postcodeZip" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingName">Building Name</label>
															<input type="text" id="buildingName" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="buildingNumber">Building Number</label>
															<input type="text" id="buildingNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="subBuildingNameNumber">Sub Building Name /
																Number</label>
															<input type="text" id="subBuildingNameNumber"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="companyOrganization">Company Organization </label>
															<input type="text" id="companyOrganization"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="street">Street</label>
															<input type="text" id="street" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="subStreet">Sub
																Street</label>
															<input type="text" id="subStreet" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="cityTown">City /
																Town</label>
															<input type="text" id="cityTown" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required" for="stateDistrict">State
																/ District</label>
															<input type="text" id="stateDistrict"
																class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="region">Region</label>
															<input type="text" id="region" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-6">
														<div class="form-group">
															<label class="form-label required"
																for="country">Country</label>
															<input type="text" id="country" class="form-control" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="firstYearOfResidency">First Year Of
																Residency</label>
															<input type="date" class="form-control"
																id="firstYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<label class="form-label required"
																for="lastYearOfResidency">Last Year Of Residency</label>
															<input type="date" class="form-control"
																id="lastYearOfResidency" />
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-end pt-3 mb-2">
													<button type="submit" class="btn btn-warning ms-2">
														Verify
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</tab>
					</tabset>
				</tab>
			</tabset>
		</tab>


	</tabset>


	<div *ngIf="result" class="card mb-4" id="pdfTable" #pdftable="">
		<div class="row">
			<div class="col-xl-12">
				<div class="card-body text-black py-2">
					<div class="pernal-details-form">
						<h3 class="text-uppercase">Result</h3>
						<div class="row">
							<div class="col-md-12">
								<span>
									Profile: International(V1)
								</span>
								<span>
									Authentication ID: 02a7b438-f2ce-4ad0-9a49-455d83335974
								</span>
							</div>
						</div>
						<div class="row">
							<div class="table-responsive mb-1">
								<table class="table color-bordered-table info-bordered-table text-center" mdbTable
									#tableEl="mdbTable" hover="true" small="true">
									<thead>
										<tr>
											<!-- <th [mdbTableSort]="notificationList" sortBy="firstName">Full Name <span>
                                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                                </span></th>
                                            <th [mdbTableSort]="notificationList" sortBy="akcessId">AKcess ID <span>
                                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                                </span></th>
                                            <th [mdbTableSort]="notificationList" sortBy="email">Type <span>
                                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                                </span></th>
                                            <th [mdbTableSort]="notificationList" sortBy="phone">Date Time <span>
                                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                                </span></th>
                                            <th>Status</th> -->
											<th>Item Check</th>
											<th>Address/Location</th>
											<th>Surname</th>
											<th>Forename</th>
											<th>DOB</th>
											<th>Warning</th>
											<th>Show Details</th>
										</tr>
									</thead>
									<!-- <tbody *ngIf="notificationList?.length == 0">
                                        <tr>
                                            <td colspan="7" class="text-center">No Notification Found</td>
                                        </tr>
                                    </tbody> -->
									<tbody>

									</tbody>
									<!-- <tbody *ngIf="notificationList?.length > 0">
                                        <tr [id]="data?._id"
                                            *ngFor="let data of notificationList | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                            <td>
                                                <a class="d-inline-block text-left" >
                                                    {{data.firstName ? data.firstName : '-'}} {{data.lastName ? data.lastName :
                                                    ''}} <span class="badge badge-warning" *ngIf="data.read" >{{data.read ? 'New' : ''}}</span>
                                                </a>
                                            </td>
                                            <td>
                                                <a class="d-inline-block">
                                                    {{data.akcessId ? data.akcessId : '-'}}
                                                </a>
                                            </td>
                                            <td>
                                                <a class="d-inline-block">
                                                    {{data.type ? data.type : '-'}}
                                                </a>
                                            </td>
                                            <td>
                                                <a class="d-inline-block">
                                                    {{data.datetime| date:'mediumDate'}}
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <span class="badge badge-primary">{{data.status}}</span>
                                            </td>
                                        </tr>
                                    </tbody> -->
									<!-- <tfoot class="w-100">
                                        <tr>
                                            <td colspan="7">
                                                <div class="table-page" *ngIf="notificationList?.length > 10">
                                                    <div class="page-controls">
                                                        <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                            class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                            nextLabel="Next"></pagination-controls>
                                                    </div>
                                                    <div class="page-size">
                                                        <label class="mb-0 mr-2">Item per page</label>
                                                        <select #val (change)="changePage(val.value)"
                                                            class="form-control form-control-sm" id="pageSelector">
                                                            <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot> -->
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- <button *ngIf="result"
	 (click)="exportPDF()" class="btn btn-primary ">
        Export as PDF
    </button> -->
</div>