import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./login/login.component";

import { DashboardComponent } from "./layout/components/dashboard/dashboard.component";
import { UsersComponent } from "./layout/components/users/users.component";
import { AddeformComponent } from "./layout/components/addeform/addeform.component";
import { LogsComponent } from "./layout/components/logs/logs.component";
import { ClientsComponent } from "./layout/components/clients/clients.component";
import { EformsComponent } from "./layout/components/eforms/eforms.component";
import { EformsresponseComponent } from "./layout/components/eformsresponse/eformsresponse.component";
import { AuthGuard } from "../app/guards/auth.guard";
import { from } from "rxjs";
import { PortalComponent } from "./layout/components/portal/portal.component";
import { AccountComponent } from "./layout/components/account/account.component";
import { EformssentComponent } from "./layout/components/eformssent/eformssent.component";
import { ProfileComponent } from "./layout/components/profile/profile.component";
import { EformQrcodeComponent } from "./layout/components/eform-qrcode/eform-qrcode.component";
import { NotificationsComponent } from "./layout/components/notifications/notifications.component";
import { ReportsComponent } from "./layout/components/reports/reports.component";
import { UpdateFieldsComponent } from "./layout/components/update-fields/update-fields.component";
import { VerificationLogsComponent } from "./layout/components/verification-logs/verification-logs.component";
import { IdCardsComponent } from "./layout/components/id-cards/id-cards.component";
import { DocumentsComponent } from "./layout/components/documents/documents.component";
import { DocumentViewerComponent } from "./layout/components/document-viewer/document-viewer.component";
import { ViewerheaderComponent } from "./layout/components/viewerheader/viewerheader.component";
import { EmployeesComponent } from "./layout/components/employees/employees.component";
import { UpdateIdCardComponent } from "./layout/components/update-id-card/update-id-card.component";
import { MerchantQrcodeComponent } from "./layout/components/merchant-qrcode/merchant-qrcode.component";
import { CompanyUsersComponent } from "./layout/components/company-users/company-users.component";
import { CompanyManagementComponent } from "./layout/components/company-management/company-management.component";
//Gauri Bane
import { LayoutComponent } from "./layout/layout.component";
import { LoginGuard } from "./guards/login.guard";
import { CompanyProfileComponent } from "./layout/components/company-profile/company-profile.component";
import { CertificateComponent } from "./layout/components/certificate/certificate.component";
import { ViewCertificateComponent } from "./layout/components/view-certificate/view-certificate.component";
import { UserProfileComponent } from "./layout/components/user-profile/user-profile.component";
import { MyEformResponsesComponent } from "./layout/components/my-eform-responses/my-eform-responses.component";
//Register
import { RegisterComponent } from "./register/register.component";
//Employee
import { EmployeeDeletedComponent } from "./layout/components/employees/employee-deleted/employee-deleted.component";
//Certificate
import { CertificateDeletedComponent } from "./layout/components/certificate/certificate-deleted/certificate-deleted.component";
//Clients
import { ClientDeletedComponent } from "./layout/components/clients/client-deleted/client-deleted.component";
//Eforms
import { EformsDeletedComponent } from "./layout/components/eforms/eforms-deleted/eforms-deleted.component";
//Eform Response
import { EformresponseDeletedComponent } from "./layout/components/eformsresponse/eformresponse-deleted/eformresponse-deleted.component";
//Document
import { DocumentDeletedComponent } from "./layout/components/documents/document-deleted/document-deleted.component";
//Settings
import { CompanySettingsComponent } from "./layout/components/company-settings/company-settings.component";
//Credit Score Blacklist
import { CreditScoreBlackListComponent } from "./layout/components/credit-score-black-list/credit-score-black-list.component";
//Usecase 8 Integration
import { Usecase8Component } from "./layout/components/usecase8/usecase8.component";
//Assets Management
import { AssetsManagementComponent } from "./layout/components/assets-management/assets-management.component";
//Bank Settings
import { BankSettingsComponent } from "./layout/components/bank-settings/bank-settings.component";
import { AddEmployeeComponent } from "./layout/components/employees/add-employee/add-employee.component";
import { AddEditClientComponent } from "./layout/components/clients/add-edit-client/add-edit-client.component";
//Company Onboarding
import { CompanyOnboardingComponent } from "./layout/components/company-onboarding/company-onboarding.component";
import { MessagingComponent } from "./layout/components/messaging/messaging.component";
import { VerificationsComponent } from "./layout/components/verifications/verifications.component";
import { UserDetailsComponent } from "./layout/components/verifications/user-details/user-details.component";
import { IframeModalComponent } from "./layout/components/verifications/iframe-modal/iframe-modal.component";
import { ProcessComponent } from "./layout/process/process.component";
//Access Control
import { AccessControlComponent } from "./layout/components/access-control/access-control.component";
import { LocationComponent } from "./layout/components/location/location.component";
import { AttendanceReportComponent } from "./layout/components/attendance-report/attendance-report.component";
import { AddLocationComponent } from "./layout/components/location/add/add.component";
import { UpdateLocationComponent } from "./layout/components/location/update/update.component";
import { ManageStaffComponent } from "./layout/components/location/manage-staff/manage-staff.component";
import { ViewLocationComponent } from "./layout/components/location/view/view.component";
import { DeletedLocationComponent } from "./layout/components/location/deleted/deleted.component";
import { AddAccessControlComponent } from "./layout/components/access-control/add/add.component";
import { UpdateAccessControlComponent } from "./layout/components/access-control/update/update.component";
import { ManageAccessControlStaffComponent } from "./layout/components/access-control/manage-staff/manage-staff.component";
import { ViewAccessControlComponent } from "./layout/components/access-control/view/view.component";
import { DeletedAccessControlComponent } from "./layout/components/access-control/deleted/deleted.component";
import { InvitationListComponent } from "./layout/components/invitation-list/invitation-list.component";

import { PropertyComponent } from "./layout/components/property/property.component";
import { AddPropertyComponent } from "./layout/components/property/add/add.component";
import { UpdatePropertyComponent } from "./layout/components/property/update/update.component";
import { ViewPropertyComponent } from "./layout/components/property/view/view.component";
import { DeletedPropertyComponent } from "./layout/components/property/deleted/deleted.component";

import { PropertyInquiryComponent } from "./layout/components/property/property-inquiry/property-inquiry.component";
import { ViewPropertyInquiryComponent } from "./layout/components/property/inquiry-view/inquiry-view.component";
import { DocumentEditComponent } from "./layout/components/documents/edit-document/edit-document.component";
import { CreateAssetsComponent } from "./layout/components/assets-management/create/create.component";

import { OnlyRealEstateGuard } from "./guards/OnlyRealEstateGuard";
import { NoneRealEstateGuard } from "./guards/NoneRealEstateGuard";
import { PropertyInquiryFormComponent } from "./layout/components/property/inquiry-form/inquiry-form.component";
import { DocumentQrcodeComponent } from "./layout/components/documents/document-qrcode/document-qrcode.component";
import { EditAssetsComponent } from "./layout/components/assets-management/edit/edit.component";
import { DeletedAssetsComponent } from "./layout/components/assets-management/deleted/deleted.component";
import { ViewAssetComponent } from "./layout/components/assets-management/view/view.component";
import { VerificationHistoryComponent } from "./layout/components/verifications/verification-history/verification-history.component";
import { PermissionGuard } from "./guards/permission.guard";

const routes: Routes = [
    { path: "", redirectTo: "login", pathMatch: "full" },
    { path: "eform/:eformname/:id/:dbname", component: EformQrcodeComponent },
    {
        path: "document/:documentid/:documentname/:dbname",
        component: DocumentQrcodeComponent,
    },
    {
        path: "companyonboarding/:id",
        component: CompanyOnboardingComponent,
    },
    { path: "login", canActivate: [LoginGuard], component: LoginComponent },
    {
        path: "register",
        canActivate: [LoginGuard],
        component: RegisterComponent,
    },
    {
        path: "realestate/property-enquiry/:dbName/:propertyId",
        component: PropertyInquiryFormComponent,
    },
    {
        path: "panel",
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: "", redirectTo: "company", pathMatch: "full" },
            { path: "company", component: CompanyManagementComponent },
            { path: "company-users", component: CompanyUsersComponent },
            { path: "company-profile", component: CompanyProfileComponent },
            { path: "dashboard", component: DashboardComponent },
            { path: "profile", component: ProfileComponent },
            { path: "user-profile", component: UserProfileComponent },
            { path: "logs", component: LogsComponent },

            { path: "settings", component: CompanySettingsComponent },
        ],
    },
    {
        path: "panel",
        component: LayoutComponent,
        canActivate: [AuthGuard, OnlyRealEstateGuard],
        children: [
            { path: "property", component: PropertyComponent },
            {
                path: "property/add",
                component: AddPropertyComponent,
            },
            {
                path: "property/:propertyId/view",
                component: ViewPropertyComponent,
            },
            {
                path: "property/:propertyId/edit",
                component: UpdatePropertyComponent,
            },
            { path: "property/deleted", component: DeletedPropertyComponent },
            {
                path: "property/inquiry",
                component: PropertyInquiryComponent,
            },
            {
                path: "property/inquiry/:propertyId",
                component: PropertyInquiryComponent,
            },
            {
                path: "property/inquiry/:inquiryId/view",
                component: ViewPropertyInquiryComponent,
            },
            { path: 'user-details', component: UserDetailsComponent },
        ],
    },
    {
        path: "panel",
        component: LayoutComponent,
        canActivate: [AuthGuard],
       canActivateChild: [PermissionGuard],
        children: [
            { path: "notifications", component: NotificationsComponent },
            { path: "clients", component: ClientsComponent },
            { path: "clients/deleted", component: ClientDeletedComponent },
            { path: "certificate", component: CertificateComponent },
            {
                path: "certificate/deleted",
                component: CertificateDeletedComponent,
            },
            { path: "view-certificate", component: ViewCertificateComponent },
            { path: "eforms", component: EformsComponent },
            { path: "eforms/deleted", component: EformsDeletedComponent },
            { path: "eformssent", component: EformssentComponent },
            { path: "eformssent/:eformId", component: EformssentComponent },
            { path: "eformsresponse", component: EformsresponseComponent },
            {
                path: "eformsresponse/deleted",
                component: EformresponseDeletedComponent,
            },
            {
                path: "eformsresponse/:eformId",
                component: EformsresponseComponent,
            },
            { path: "addeform/:id/:title", component: AddeformComponent },
            { path: "account", component: AccountComponent },
            {
                path: "mobile",
                redirectTo: "/mobile/index.html",
                pathMatch: "full",
            },
            { path: "reports", component: ReportsComponent },
            { path: "update-fields", component: UpdateFieldsComponent },
            { path: "verification-logs", component: VerificationLogsComponent },
            { path: "idcards", component: IdCardsComponent },
            { path: "viewidcards/:data", component: UpdateIdCardComponent },
            { path: "received-docs", component: DocumentsComponent },
            {
                path: "received-docs/deleted",
                component: DocumentDeletedComponent,
            },
            { path: "employees", component: EmployeesComponent },
            { path: "employees/deleted", component: EmployeeDeletedComponent },
            { path: "merchant-qrcode/:id", component: MerchantQrcodeComponent },
            { path: "documents", component: DocumentViewerComponent },
            { path: "viewerheader", component: ViewerheaderComponent },

            { path: "users", component: UsersComponent },
            { path: "portal", component: PortalComponent },

            { path: "myeform-responses", component: MyEformResponsesComponent },

            {
                path: "creditscrore-blacklist",
                component: CreditScoreBlackListComponent,
            },
            { path: "integration", component: Usecase8Component },
            { path: "assets", component: AssetsManagementComponent },
            { path: "assets/create", component: CreateAssetsComponent },
            { path: "assets/:assetId/edit", component: EditAssetsComponent },
            { path: "assets/deleted", component: DeletedAssetsComponent },
            { path: "assets/:assetId/view", component: ViewAssetComponent },
            { path: "bank-settings", component: BankSettingsComponent },
            { path: "messaging", component: MessagingComponent },
            { path: "verifications", component: VerificationsComponent },
            // { path: "verification-userdetails", component: UserDetailsComponent },
            { path: "verification-userdetails/:entityid", component: UserDetailsComponent },
            { path: "verification-userdetails/:entityid/:OpenModel", component: UserDetailsComponent },

           
            { path: "verification-history", component: VerificationHistoryComponent },
            { path: "location", component: LocationComponent },
            {
                path: "location/add",
                component: AddLocationComponent,
            },
            {
                path: "location/:locationId/view",
                component: ViewLocationComponent,
            },
            {
                path: "location/:locationId/edit",
                component: UpdateLocationComponent,
            },
            {
                path: "location/:locationId/manageStaff",
                component: ManageStaffComponent,
            },
            { path: "location/deleted", component: DeletedLocationComponent },
            { path: "access-control", component: AccessControlComponent },
            {
                path: "access-control/add",
                component: AddAccessControlComponent,
            },
            {
                path: "access-control/:locationId/view",
                component: ViewAccessControlComponent,
            },
            {
                path: "access-control/:locationId/edit",
                component: UpdateAccessControlComponent,
            },
            {
                path: "access-control/:locationId/manageStaff",
                component: ManageAccessControlStaffComponent,
            },
            {
                path: "access-control/deleted",
                component: DeletedAccessControlComponent,
            },
            // { path: "invitation-list", component: InvitationListComponent },
            { path: "attendance-report", component: AttendanceReportComponent },
            { path: "clients/add", component: AddEditClientComponent },
            { path: "clients/edit", component: AddEditClientComponent },
            { path: "employees/add", component: AddEmployeeComponent },
            { path: "employees/edit", component: AddEmployeeComponent },
            {
                path: "document/edit",
                component: DocumentEditComponent,
            },
        ],
    },
    { path: "verification-IframeModal", component: IframeModalComponent },
    { path: "process", component: ProcessComponent },

    { path: "**", redirectTo: "" },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "reload",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
