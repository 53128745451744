<div class="asset-form-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/assets']">Assets</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="mode == 'create'">
                    Create
                </li>
                <li class="breadcrumb-item active" *ngIf="mode == 'edit'">
                    Edit
                </li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title mb-0">
                        {{ mode == "edit" ? "Edit" : "Create" }} Asset
                    </h3>
                </div>
                <div class="card-body">
                    <form
                        class="asset-form"
                        [formGroup]="assetForm"
                        (ngSubmit)="submitAssetForm()"
                    >
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label required"
                                        >Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        formControlName="name"
                                        required
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label required"
                                        >Type</label
                                    >
                                    <select
                                        *ngIf="typeOptions"
                                        formControlName="type"
                                        class="browser-default custom-select"
                                    >
                                        <option value="" selected>
                                            Select Type
                                        </option>
                                        <option
                                            *ngFor="let option of typeOptions"
                                            [ngValue]="option.value"
                                        >
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="form-label required"
                                        >Description</label
                                    >
                                    <textarea
                                        class="form-control"
                                        formControlName="description"
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label
                                        class="form-label"
                                        [ngClass]="{
                                            required: mode != 'edit'
                                        }"
                                        >Picture</label
                                    >
                                    <input
                                        type="file"
                                        class="form-control"
                                        accept="image/*"
                                        (change)="showPreview($event)"
                                    />
                                    <!-- Image Preview -->
                                    <div
                                        class="imagePreview"
                                        *ngIf="imageURL && imageURL !== ''"
                                    >
                                        <img
                                            [src]="imageURL"
                                            [alt]="assetForm.value.name"
                                            class="img-fluid"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label
                                        class="form-label"
                                        [ngClass]="{
                                            required: mode != 'edit'
                                        }"
                                        >Documents</label
                                    >
                                    <input
                                        type="file"
                                        class="form-control"
                                        formControlName="documents"
                                        multiple
                                        (change)="onFileChange($event)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-left mt-4">
                                    <button
                                        type="submit"
                                        class="btn btn-info"
                                        *ngIf="mode == 'create'"
                                        [disabled]="!assetForm.valid"
                                    >
                                        Create Asset
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-info"
                                        *ngIf="mode == 'edit'"
                                        [disabled]="!assetForm.valid"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- /Page Wrapper -->
