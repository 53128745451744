import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ViewStaffListModalComponent } from "./view-staff-list-modal/view-staff-list-modal.component";
@Component({
    selector: "app-manage-location-staff",
    templateUrl: "./manage-staff.component.html",
    styleUrls: ["./manage-staff.component.css"],
})
export class ManageStaffComponent implements OnInit {
    locationId: string = "";
    searchName: string = "";
    currentPage: number = 1;
    totalCount: number = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: number = 10;
    staffList: any = [];
    isMasterSel: Boolean;
    checkedLocationStaffList: any;
    bsModalRef: BsModalRef;
    location: any = {};
    onlyAllowAdmin: boolean = false;
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        public modalService: BsModalService
    ) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.locationId = params.locationId;
        });
        me.isMasterSel = false;
    }

    ngOnInit(): void {
        this.ds.getLocation(this.locationId).subscribe(
            (res: any) => {
                if (res.status) {
                    this.location = res.data;
                    this.onlyAllowAdmin = this.location.onlyAllowAdmin;
                    this.getLocationStaff();
                } else {
                    this.router.navigate(["panel/location"]);
                }
            },
            (error) => {
                // this.toast.error(error.message);
                this.router.navigate(["panel/location"]);
            }
        );
    }

    getLocationStaff() {
        this.spinner.show();
        const params = {
            search: this.searchName,
            page: this.currentPage,
            limit: this.pageSize,
        };

        this.ds.getLocationStaff(params, this.locationId).subscribe(
            (res: any) => {
                if (res.status) {
                    this.staffList = res.data.list;
                    this.totalCount = res.data.total;
                    this.toast.success(res.message);
                    this.spinner.hide();
                } else {
                    this.toast.error(res.message);
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    onProjectPageChange(event) {
        this.currentPage = event;
        this.getLocationStaff();
    }

    changePage(value) {}

    searchLocationStaff(type: string, value: string) {
        if (type == "search") {
            if (value !== null) {
                this.currentPage = 1;
                this.searchName = value;
                this.getLocationStaff();
            }
        } else {
            this.searchName = value;
            this.getLocationStaff();
        }
    }

    deleteSingleRows(id) {
        this.spinner.show();

        const data = {
            component: "locationstaff",
            _id: id,
        };
        this.ds.softDelete(data).subscribe(
            (res: any) => {
                this.spinner.hide();
                if (res.status) {
                    this.staffList = this.staffList.filter((object) => {
                        return object._id !== id;
                    });
                    this.spinner.hide();
                    this.toast.success(res.message);
                } else {
                    this.toast.info(res.message);
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    viewStaffListModal() {
        const initialState: any = {
            locationId: this.locationId,
            staffList: this.staffList,
            onlyAllowAdmin: this.onlyAllowAdmin,
        };
        this.bsModalRef = this.modalService.show(ViewStaffListModalComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            ignoreBackdropClick: true,
            class: "modal-dialog-centered modal-lg",
        });
    }
}
