<div class="employees-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Staff</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Staff</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-8 col-lg-4 col-xl-4">
                            <div class="input-group">
                                <input type="text" [(ngModel)]="search_employees" class="form-control" id="search"
                                    placeholder="Search By Staff" (change)="searchEmployee($event.target.value)" />
                                <!--<div class="input-group-append">
                                    <div class="input-group-text" (click)="getCertificateList()">
                                        <i class="fas fa-search"></i>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <div class="col-12 col-md-8 col-lg-8 col-xl-8 text-right mt-1 mt-md-0">
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn send-invite-btn"
                                data-toggle="modal" (click)="viewSentModel()" tooltip="View Send Invitation"
                                placement="bottom" container="body">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                &nbsp; View Sent
                            </button>
                            &nbsp;
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn send-invite-btn"
                                data-toggle="modal" data-target="#sendInvitation" (click)="getDefaultInvitation()"
                                tooltip="Send Invitation" placement="bottom" container="body">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                &nbsp; Send Invitation
                            </button>
                            &nbsp;
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn add-emp-btn"
                                data-toggle="modal" data-target="#addEmployeeDetail" (click)="addEditEmployee('add','')"
                                tooltip="Add Staff" placement="bottom" container="body">
                                <i class="fas fa-plus-circle"></i>
                                &nbsp; Add Staff
                            </button>
                        </div>
                    </div>
                    <div class="action-tab">
                        <button type="button" class="mx-1 btn" (click)="deleteRow()" [disabled]="!somethingSelected"
                            tooltip="Delete" container="body">
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button type="button" class="mx-1 btn" (click)="viewRecycle()" tooltip="Recycle Bin"
                            container="body">
                            <i class="fal fa-recycle"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table class="table color-bordered-table info-bordered-table text-center" mdbTable
                            #tableEl="mdbTable" hover="true" small="true">
                            <thead>
                                <tr>
                                    <th>
                                        <!--<label data-toggle="collapse" data-target="#actionBar" aria-expanded="false" aria-controls="actionBar" class="m-0">
                                            <input class="" type="checkbox">
                                        </label>-->
                                        <input type="checkbox" [(ngModel)]="masterSelected" name="list_checkbox"
                                            value="m1" (change)="checkUncheckAll($event)" />
                                    </th>
                                    <th [mdbTableSort]="userlist" sortBy="firstName">Name <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span></th>
                                    <th [mdbTableSort]="userlist" sortBy="akcessId">AKcess ID <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span></th>
                                    <th [mdbTableSort]="userlist" sortBy="email">Email <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span></th>
                                    <th [mdbTableSort]="userlist" sortBy="phone">Mobile <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span></th>
                                    <!-- <th [mdbTableSort]="userlist" sortBy="phone">Status <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span></th> -->
                                    <!--<th [mdbTableSort]="userlist" sortBy="usertype">User Type <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>-->
                                    <th [mdbTableSort]="userlist" sortBy="createdon">Created On <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span></th>
                                    <!--<th [mdbTableSort]="userlist" sortBy="removed_flg">Active <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>-->
                                    <th class="text-nowrap">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="userlist?.length == 0">
                                <tr>
                                    <td colspan="7" class="text-center">No Staff Found</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="userlist?.length > 0">
                                <!-- [ngClass]="{'disable-table-row': !data.phone && !data.email}" -->
                                <tr [id]="data?._id"
                                    *ngFor="let data of userlist | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ; let i = index">
                                    <td>
                                        <input type="checkbox" [attr.id]="data?._id" (change)="isAllSelected($event)"
                                            [(ngModel)]="data.isSelected" name="list_checkbox" value="{{data?._id}}">
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditEmployee('view',data)">
                                            {{data.firstName ? data.firstName : '-'}} {{data.lastName ? data.lastName :
                                            ''}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditEmployee('view',data)">
                                            {{data.akcessId ? data.akcessId : '-'}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditEmployee('view',data)">
                                            {{data.email ? data.email : '-'}}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="addEditEmployee('view',data)">
                                            {{data.phone ? data.phone : '-'}}
                                            <!-- +{{data.phone ? data.countryCode + data.phone : '-'}} -->
                                        </a>
                                    </td>
                                    <!-- <td>
                                        <a class="d-inline-block">
                                            <span *ngIf="!data.email && !data.phone" class="text-danger">Pending</span>
                                            <span *ngIf="data.email && data.phone"
                                                class="text-success">Verified/Submit</span>
                                        </a>
                                    </td> -->
                                    <td>
                                        <a class="d-inline-block" (click)="addEditEmployee('view',data)">
                                            {{data.createdon| date:'mediumDate'}}
                                        </a>
                                    </td>
                                    <td class="text-left" *ngIf="data.email && data.phone">
                                        <button type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-outline-info"
                                            (click)="sendIndividualInvitation(data,'sms')">
                                            <i class="fas fa-mobile-alt" tooltip="Send Invitation By SMS"
                                                container="body" placement="bottom"></i>
                                        </button>
                                        <button type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-outline-info"
                                            (click)="sendIndividualInvitation(data,'email')">
                                            <i class="far fa-envelope" tooltip="Send Invitation By Email"
                                                container="body" placement="bottom"></i>
                                        </button>
                                        <span tooltip="Please Generate Id Card" *ngIf="!data.idcard_id">
                                            <button type="button"
                                                class="mx-2 btn btn-sm waves-effect waves-light btn-info" disabled>
                                                Send ID Card
                                            </button>
                                        </span>
                                        <span tooltip="Please Generate Id Card" *ngIf="!data.idcard_id">
                                            <button type="button"
                                                class="mx-2 btn btn-sm waves-effect waves-light btn-info" disabled>
                                                View Sent
                                            </button>
                                        </span>
                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="openViewEmpIdCard(data.pdf_name)" *ngIf="data.idcard_id">
                                            View Id Card
                                        </button>
                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="openSendIdCardModal(data)" *ngIf="data.idcard_id">
                                            Send ID Card
                                        </button>
                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="openViewSendModal(data.idcard_id)" *ngIf="data.idcard_id">
                                            View Sent
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="7">
                                        <div class="table-page" *ngIf="userlist?.length > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                    class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                    nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="changePage(val.value)"
                                                    class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- Modals -->
<!-- View Employee Modal -->
<div class="modal" id="viewuser" tabindex="-1" role="dialog" aria-labelledby="viewuserModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewuserModalLabel">View Details</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row profile-img-parent">
                    <img class="profile-image" src="../../../../assets/new-images/users/1.jpg">
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">AKcess ID</label>
                    <div class="col-12 col-md-8">{{akcessId}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Firstname</label>
                    <div class="col-12 col-md-8">{{(firstName)?firstName:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Lastname</label>
                    <div class="col-12 col-md-8">{{(lastName)?lastName:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Email</label>
                    <div class="col-12 col-md-8">{{(email)?email:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Mobile</label>
                    <div class="col-12 col-md-8">{{(phone)?phone:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Address</label>
                    <div class="col-12 col-md-8">{{(address)?address:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">User Type</label>
                    <div class="col-12 col-md-8">{{usertype}}</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" id="close" data-dismiss="modal"
                    (click)="addEditEmployee('edit',singleEmployeeData)">
                    Edit Details
                </button>
                <button type="button" class="btn btn waves-effect waves-light btn-primary"
                    (click)="openGenerateIdModal(singleEmployeeData)" [disabled]="checkGenerateIdModal()">
                    Generate ID Card
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Add/Edit Employee Modal -->
<div class="modal" id="addEmployeeDetail" tabindex="-1" role="dialog" aria-labelledby="addEmployeeDetailModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="addEmployeeDetailModalLabel">{{(act=="add")?'Add':'Edit'}} Staff
                    Information</h4>
                <button (click)="resetform()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="row modal-body">
                <div class="col-md-9 col-sm-12 col-xl-9">
                    <form (ngSubmit)="addEmployee()" [formGroup]="adduserform">
                        <input *ngIf="act=='add'" type="hidden" formControlName="_id">
                        <div class="form-row">
                            <div class="form-group col-md-6 toggle-box-parent">
                                <label class="font-500">Do you have AKcessID?</label>
                                <label class="switch">
                                    <input type="checkbox" (change)="getToggleValue($event)">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="form-group col-md-6" *ngIf="isAkcessId">
                                <label class="font-500" for="AKcessID">AKcess ID</label>
                                <input *ngIf="act!='add'" type="text" maxlength="12" minlength="11" class="form-control"
                                    formControlName="akcessId" placeholder="AKcess ID" required>
                                <input *ngIf="act=='add'" type="text" maxlength="12" minlength="11" class="form-control"
                                    formControlName="akcessId" placeholder="AKcess ID" required>
                                <div *ngIf="isSubmitted && formControls.akcessId.errors && formControls.akcessId.errors.required"
                                    class="invalid-feedback">
                                    AKcess ID is required
                                </div>
                                <div class="valid-feedback mt-0"><small>Please make sure to add the correct AKcess
                                        ID.</small></div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="font-500" for="firstName">First Name</label>
                                <input type="text" class="form-control" formControlName="firstName"
                                    placeholder="First Name" required>
                                <div *ngIf="isSubmitted && formControls.firstName.errors && formControls.firstName.errors.required"
                                    class="invalid-feedback">
                                    First name is required
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="font-500" for="lastName">Last Name</label>
                                <input type="text" class="form-control" formControlName="lastName"
                                    placeholder="Last Name" required>
                                <div *ngIf="isSubmitted && formControls.lastName.errors && formControls.lastName.errors.required"
                                    class="invalid-feedback">
                                    Last name is required
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="font-500" for="email">Email</label>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email"
                                    required>
                                <div *ngIf="isSubmitted && formControls.email.errors?.required"
                                    class="invalid-feedback">
                                    Email is required
                                </div>
                                <div *ngIf="isSubmitted && formControls.email.errors?.email" class="invalid-feedback">
                                    Email is invalid
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="font-500" for="phone">Mobile</label>
                                <input type="number" class="form-control" formControlName="phone"
                                    placeholder="Mobile Number" maxlength="12" required>
                                <div *ngIf="isSubmitted && formControls.phone.errors?.required && formControls.phone.errors.required"
                                    class="invalid-feedback">
                                    Mobile Number is required
                                </div>
                                <div *ngIf="isSubmitted && formControls.phone.errors?.pattern" class="invalid-feedback">
                                    Mobile Number is not valid
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="font-500">Date of Birth
                                    <!-- <span class="required-label">*</span> -->
                                </label>
                                <input type="text" class="form-control" name="DOB" placeholder="Select Date"
                                    formControlName="DOB" bsDatepicker
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red', isAnimated: true, adaptivePosition: true }">
                                <div class="invalid-feedback"
                                    *ngIf="isSubmitted && formControls.DOB.errors && formControls.DOB.errors.required ">
                                    Please select DOB
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="font-500" for="lastName">Place of Birth</label>
                                <input type="text" class="form-control" formControlName="placeOfBirth"
                                    placeholder="Address">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="font-500">Gender</label>
                                <select class="form-control" formControlName="gender">
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="font-500">Nationality</label>
                                <select name="wgtmsr" class="form-control" formControlName="nationality">
                                    <option value="">Select Country</option>
                                    <option *ngFor="let country of countryList" value="{{country}}">{{country}}</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-3 col-sm-12 col-xl-3">
                    <div class="upload-img-parent">
                        <img class="upload-profile-img" src="{{uploadProfileImage}}">
                        <label *ngIf="uploadProfileImage?.length < 100">Click here to upload photo</label>
                        <input class="form-control select-img" type="file" accept=".jpg;.png"
                            (change)="onUploadChange($event)">
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn waves-effect waves-light btn-info">Add</button>
                <button type="button" (click)="resetform()" class="btn waves-effect waves-light btn-danger"
                    id="addEmployeeDetailClose" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Send invitation modal -->
<div class="modal" id="sendInvitation" tabindex="-1" role="dialog" aria-labelledby="sendInvitationModalLabel">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="sendInvitationModalLabel">Send Invitation</h4>
                <button (click)="resetform()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="eFormFields.length == 0" class="text-center mt-2">
                    <h6>No eForm Found.</h6>
                </div>
                <div *ngIf="eFormFields.length != 0">
                    <label class="font-500 m-0">Selected default eform:</label>
                    <hr class="mt-2 mb-2">
                    <div class="row">
                        <div class="col-8">
                            <span *ngIf="!isChangeEform" class="mt-1">{{formName}}</span>
                            <span *ngIf="isChangeEform">
                                <ng-select [items]="eformList" [hideSelected]="true" bindLabel="name"
                                    placeholder="Select User" [(ngModel)]="selectedFormName" appendTo="body"
                                    class="akcess-id-dropdown" (change)="geteformData()">
                                </ng-select>
                            </span>
                        </div>
                        <div class="col-4 text-center">
                            <i class="fas fa-pencil mr-3" (click)="editEform()"></i>
                            <i class="fas fa-eye mr-3" (click)="viewEform()"></i>
                            <i class="fas fa-exchange" (click)="changeDefaultForm()" *ngIf="!isChangeEform"></i>
                            <i class="fas fa-times" (click)="changeDefaultForm()" *ngIf="isChangeEform"></i>
                        </div>
                    </div>
                </div>
                <div class="invitation-type mt-3">
                    <label class="font-500 m-0">Send invitation via:</label>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="email" name="invitationType" class="with-gap radio-col-light-blue"
                            value="email" [checked]="isEmailInvitation" (change)="sendInvitationBy($event)">
                        <label for="email">Email</label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="phoneNumber" name="invitationType" class="with-gap radio-col-light-blue"
                            value="phoneNumber" [checked]="isPhoneInvitation" (change)="sendInvitationBy($event)">
                        <label for="phoneNumber">Mobile</label>
                    </div>
                </div>
                <div *ngIf="isEmailInvitation">
                    <div>
                        <input type="email" id="emailInput" (keyup.enter)="addEmail($event)"
                            class="form-control pt-2 pb-2" [value]="emailValue">
                        <span class="valid-feedback">Type the Email address and press ENTER to validate</span>
                    </div>
                    <span *ngIf="emailList.length > 0" class="mt-2">
                        <div class="chip" *ngFor="let email of emailList;let i =index">
                            <div class="chip-content">{{email}}</div>
                            <div class="chip-close" (click)="removeData(i,'email')">
                                <svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                                    </path>
                                </svg>

                            </div>
                        </div>
                    </span>
                </div>
                <div *ngIf="isPhoneInvitation">
                    <form #f="ngForm" [formGroup]="phoneForm" id="send-invitation-mobile-frm">
                        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                            [(ngModel)]="phoneNumber" (keyup.enter)="addNumber()" [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true" [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                            [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedKingdom"
                            [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true"
                            [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone">
                        </ngx-intl-tel-input>
                        <!-- <div class="invalid-feedback" *ngIf="isSubmitPhone && f.form.controls['phone'].invalid">
                            Please enter proper phone number
                        </div> -->
                    </form>
                    <span class="valid-feedback">Type the mobile number with the country code and press ENTER</span>
                    <span *ngIf="phoneNumberList.length > 0" class="mt-2">
                        <div class="chip" *ngFor="let phone of phoneNumberList;let i =index">
                            <div class="chip-content">+{{phone.countryCode}} {{phone.phone}}</div>
                            <div class="chip-close" (click)="removeData(i,'phone')">
                                <svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                                    </path>
                                </svg>

                            </div>
                        </div>
                    </span>
                </div>
                <div *ngIf="isTemplate && eFormFields.length > 0">
                    <div class="row mt-2 mb-3">
                        <div class="col-5">
                            <h4>{{formName}}</h4>
                        </div>
                        <div class="col-4">
                            <ng-select [items]="eformList" [hideSelected]="true" bindLabel="name"
                                placeholder="Select User" [(ngModel)]="selectedFormName" appendTo="body"
                                class="akcess-id-dropdown" (change)="geteformData()">
                            </ng-select>
                        </div>
                        <div class="col-3 text-right">
                            <a class="template-link" (click)="editEform()">Edit eForm</a>
                        </div>
                    </div>
                    <div *ngFor="let data of eFormFields;let i = index;">
                        <div *ngIf="!(data.section_id)" class="form-group row">
                            <label for={{data.labelname}} class="col-12 col-md-4 col-form-label font-500">
                                {{data.labelname}}<span *ngIf="data.is_mandatory === 'yes'"
                                    class="required-label">*</span>
                            </label>
                            <div class="col-12 col-md-8 position-relative" [ngSwitch]="data.keytype">
                                <!--Common Fields-->
                                <div *ngSwitchDefault>
                                    <input type="{{data.keytype}}" class="form-control" id={{data.key}}
                                        placeholder="{{data.key}}" value="{{data.field_value}}" disabled>
                                </div>
                                <!--Textarea Field-->
                                <div *ngSwitchCase="'address'">
                                    <textarea class="form-control" id={{data.key}} rows="2"
                                        disabled>{{data.field_value}}</textarea>
                                </div>
                                <!--File Field-->
                                <div *ngSwitchCase="'file'">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id={{data.key}}
                                            value="{{data.field_value}}" disabled>
                                        <label class="custom-file-label" for={{data.field_value}}>Choose file</label>
                                    </div>
                                </div>
                                <!--Radio Field-->
                                <div *ngSwitchCase="'radio'">
                                    <div *ngFor="let option of data.options,let j=index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" value={{data.options[j].lable}}
                                                disabled>
                                            <label class="form-check-label">{{data.options[j].lable}}</label>
                                        </div>
                                    </div>
                                </div>
                                <!--Checkbox Field-->
                                <div *ngSwitchCase="'checkbox'">
                                    <div *ngFor="let option of data.options, let j=index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox"
                                                value={{data.options[j].lable}} disabled>
                                            <label class="form-check-label">{{data.options[j]['key']}}</label>
                                        </div>
                                    </div>
                                </div>
                                <!--Select Field-->
                                <!--<div *ngSwitchCase="'select'">
                                <select class="form-control">
                                    <option *ngFor="let option of data.options" value="{{option.key}}" id={{option.uid}}>{{option.lable}}</option>
                                </select>
                            </div>-->
                                <!--Select Field-->
                                <!--<div *ngSwitchCase="'list'">
                                <select class="form-control">
                                    <option *ngFor="let option of data.options" value="{{option.key}}" id={{option.uid}}>{{option.lable}}</option>
                                </select>
                            </div>-->
                            </div>
                            <!--Verification Fields-->
                            <div class="my-2" *ngIf="data.keytype=='text'">
                                <span class="font-500">Field needs to be verified: </span>{{data.file_verified}}
                            </div>
                            <!--File Verification Fields-->
                            <div class="" *ngIf="data.keytype=='file'">
                                <div class="my-2 col-12">
                                    <span class="font-500">Signature required:</span> {{data.signature_required}}
                                </div>
                                <div class="my-2 col-12">
                                    <span class="font-500">File needs to be verified: </span>{{data.file_verified}}
                                </div>
                            </div>
                            <!--Grade Fields-->
                            <div class="my-2" *ngIf="data.file_verified=='yes'">
                                <span class="font-500">Verification grade : </span>
                                <span *ngIf="data.verification_grade == 'G'">Goverment</span>
                                <span *ngIf="data.verification_grade == 'F'">Financial</span>
                                <span *ngIf="data.verification_grade == 'T'">Telecom</span>
                                <span *ngIf="data.verification_grade == 'A'">AKcess</span>
                                <span *ngIf="data.verification_grade == 'O'">Other</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  -->
            </div>
            <div class="modal-footer">
                <div class="btn-group">
                    <button class="btn btn-info" (click)="sendInvite()"
                        [disabled]="!isPhoneInvitation ? emailList.length == 0 : phoneNumberList.length == 0">Send</button>
                    &nbsp;
                    <button class="btn btn-danger" (click)="resetform()" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>