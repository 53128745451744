<div class="use-case-8-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Integration APIs for Banks</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Integration APIs for Banks</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-12 col-md-6 mb-3 mb-md-0" *ngIf="getAccessTokenFlag==true">
                        <label class="font-500">Apikey</label>
                        <input type="text" [(ngModel)]="apikey" name="apikey" class="form-control "
                               placeholder="Enter apiKey" />
                    </div>
                    <div class="col-12 col-md-6" *ngIf="getAccessTokenFlag==true">
                        <label class="font-500">AKcess token</label>
                        <input type="text" [(ngModel)]="akcesstoken" name="akcesstoken" class="form-control"
                               placeholder="Enter AKcess token" />
                    </div>
                </div>
                <div>
                    <tabset [justified]="true">
                        <tab heading="GetAkcessToken" id="tab1" (selectTab)="clearHistory(false)">
                            <form class="mb-3">
                                <div class="form-group">
                                    <label class="font-500">ApiKey</label>
                                    <input class="form-control" type="text" [(ngModel)]="apikey" name="passcode"
                                           placeholder='apikey' />
                                </div>
                                <div class="form-group">
                                    <label class="font-500">Domain Url</label>
                                    <input class="form-control" type="text" [(ngModel)]="domainUrl" name="passcode"
                                           placeholder='ex:bank.AKcess.app' />
                                </div>
                                <button class="btn waves-effect waves-light btn-info" type="button" (click)="GetAccessToken(apikey,domainUrl)">
                                    Run
                                </button>
                            </form>
                            <div>
                                <label class="font-500">Output</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="akcesstoken"></textarea>
                            </div>
                        </tab>
                        <tab heading="GetEFormResponses" (selectTab)="clearHistory(true)">
                            <form class="mb-3">
                                <div class="form-group">
                                    <label class="font-500">Body</label>
                                    <input class="form-control" type="text" [(ngModel)]="body" name="passcode"
                                           placeholder='example:{"orderBy":"nto","limit":2,"currentPage":1,"fromDate":"2020-10-03T08:50:17.013Z","toDate":"2020-10-14T08:50:17.013Z"}' />
                                </div>
                                <button class="btn waves-effect waves-light btn-info" type="button"
                                        (click)="GetEFormResponses('readEformresponse',body)">
                                    Run
                                </button>
                            </form>
                            <div>
                                <label class="font-500">Output</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="EFormResponses"></textarea>
                            </div>
                        </tab>
                        <tab heading="GetEFormData" (selectTab)="clearHistory(true)">
                            <form class="mb-3">
                                <!-- <input class="form-control" type="text" [(ngModel)]="apikey" name="apikey"
                                    class="form-control" placeholder="Enter apiKey" />
                                <input class="form-control" type="text" [(ngModel)]="akcesstoken"
                                    name="akcesstoken" class="form-control" placeholder="Enter akcesstoken" /> -->
                                <div class="form-group">
                                    <label class="font-500">Body</label>
                                    <input class="form-control" type="text" [(ngModel)]="body" name="passcode"
                                           placeholder='example:{"orderBy":"nto","limit":1,"currentPage":1,"eformId": "5f7ec7d14e1b5e06a6261063"}' />
                                </div>
                                <button class="btn waves-effect waves-light btn-info" type="button"
                                        (click)="GetEFormResponses('readEformresponseByEformId',body)">
                                    Run
                                </button>
                            </form>
                            <div>
                                <label class="font-500">Output</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="EFormResponses"></textarea>
                            </div>
                        </tab>
                        <tab heading="GetEFormField" (selectTab)="clearHistory(true)">
                            <form class="mb-3">
                                <!-- <input class="form-control" type="text" [(ngModel)]="apikey" name="apikey"
                                    placeholder="Enter apiKey" />
                                <input type="text" [(ngModel)]="akcesstoken" name="akcesstoken"
                                    class="form-control" placeholder="Enter akcesstoken" /> -->
                                <div class="form-group">
                                    <label class="font-500">Body</label>
                                    <input type="text" [(ngModel)]="body" name="passcode" class="form-control"
                                           placeholder='example:{"eformId": "5f7ec7d14e1b5e06a6261063","fields":["firstname","lastname"]}' />
                                </div>
                                <button class="btn waves-effect waves-light btn-info" type="button"
                                        (click)="GetEFormResponses('getEformresponseFieldByEformId',body)">
                                    Run
                                </button>
                            </form>
                            <div>
                                <label class="font-500">Output</label>
                                <textarea rows="5" class="form-control" [(ngModel)]="EFormResponses"></textarea>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="main-content">-->
<!-- Navbar -->
<!-- End Navbar -->
<!-- Header -->
<!--<div class="container-fluid mt--9">
        <div class="row">
            <div class="col-11 col-md-10 card">
                <div class="card-header bg-transparent border-0">

                    <div class="nav-wrapper">
                        <ul class="nav nav-pills nav-fill flex-column flex-md-row" id="tabs-icons-text" role="tablist">
                            <li class="nav-item" (click)="clearHistory(false)">
                                <a class="nav-link mb-sm-3 mb-md-0 active" id="tabs-icons-text-0-tab" data-toggle="tab"
                                   href="#tabs-icons-text-0" role="tab" aria-controls="tabs-icons-text-0"
                                   aria-selected="true">GetAkcessToken</a>
                            </li>
                            <li class="nav-item" (click)="clearHistory(true)">
                                <a class="nav-link mb-sm-3 mb-md-0 " id="tabs-icons-text-1-tab" data-toggle="tab"
                                   href="#tabs-icons-text-1" role="tab" aria-controls="tabs-icons-text-1"
                                   aria-selected="true">GetEFormResponses</a>
                            </li>
                            <li class="nav-item" (click)="clearHistory(true)">
                                <a class="nav-link mb-sm-3 mb-md-0" id="tabs-icons-text-2-tab" data-toggle="tab"
                                   href="#tabs-icons-text-2" role="tab" aria-controls="tabs-icons-text-2"
                                   aria-selected="false">GetEFormData</a>
                            </li>
                            <li class="nav-item" (click)="clearHistory(true)">
                                <a class="nav-link mb-sm-3 mb-md-0" id="tabs-icons-text-3-tab" data-toggle="tab"
                                   href="#tabs-icons-text-3" role="tab" aria-controls="tabs-icons-text-3"
                                   aria-selected="false">GetEFormField</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card shadow">
                        <div class="card-body">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="tabs-icons-text-0" role="tabpanel"
                                     aria-labelledby="tabs-icons-text-0-tab">

                                </div>
                                <div class="tab-pane fade" id="tabs-icons-text-1" role="tabpanel"
                                     aria-labelledby="tabs-icons-text-1-tab">

                                </div>
                                <div class="tab-pane fade" id="tabs-icons-text-2" role="tabpanel"
                                     aria-labelledby="tabs-icons-text-2-tab">

                                </div>
                                <div class="tab-pane fade" id="tabs-icons-text-3" role="tabpanel"
                                     aria-labelledby="tabs-icons-text-3-tab">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
