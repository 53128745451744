import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { CommonService } from 'src/app/_services/common.service';

declare var $: any;

var dataOutput = new FormData();

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    public portal = null;
    public portalLabel = '';
    public portalContacts = '';
    public portalLogo = '';
    public updated_at = '';
    imageurl: any;
    portaldata: any;
    loggedInName: string;

    portalUser;
    constructor(public sanitizer: DomSanitizer,
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private gs: GeneralServiceService,
        public commonService: CommonService) { }

    ngOnInit() {
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        this.getData();
        this.setImageEvent();
    }

    getData() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        //this.loggedInName = this.portalUser.firstName + ' ' + this.portalUser.lastName;

        //this.portaldata = this.commonService.portalDetails.portalDetails.portalData;

        // alert(this.portaldata)
        this.portalLabel = this.portaldata.setting.label;
        this.portalContacts = this.portaldata.setting.contacts;
        this.portalLogo = this.portaldata.setting.filename;
        this.updated_at = this.portaldata.setting.updateddate;
        this.imageurl = this.portaldata.setting.filename;
        // this.ds.getPortalData().subscribe(data => {
        //     if (data['portal'] != null) {
        //         this.portal = data['portal'];
        //         this.portalLabel = this.portal.label;
        //         this.portalContacts = this.portal.contacts;
        //         this.portalLogo = this.portal.logo;
        //         this.updated_at = this.portal.updated_at;

        //         let TYPED_ARRAY = new Uint8Array(this.portal.logo.data.data);
        //         const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);
        //         let base64String = btoa(STRING_CHAR);
        //         this.imageurl = this.sanitizer.bypassSecurityTrustUrl('data:'+this.portal.logo.contentType+';base64, ' + base64String);

        //     }

        //     this.spinner.hide();
        // },
        // error => { this.toast.error('something went wrong'); this.spinner.hide(); }
        // );
        this.spinner.hide();
    }

    saveData() {
        this.spinner.show();
        // var curdate = Date.now;
        dataOutput.set('label', this.portalLabel);
        dataOutput.set('contacts', this.portalContacts);
        dataOutput.set('domainname', this.commonService.portalDetails.portalDetails.portalDomainName);
        // dataOutput.set('updated_at', curdate);
        this.gs.savePortalData(dataOutput).subscribe(data => {
            this.toast.success("Saved successfully");
            this.spinner.hide();
        },
            error => { this.toast.error('something went wrong'); this.spinner.hide(); }
        );
    }

    getImage(input) {
        $(input)[0].click();
    }

    setImageEvent() {
        $('#imageInput')[0].addEventListener('change', function (event) {
            var inputTarget = event.target;

            if (inputTarget.files && inputTarget.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    $('#imagePreview').attr('src', e.target.result);
                };

                reader.readAsDataURL(inputTarget.files[0]);

                dataOutput.set('logo', inputTarget.files[0], inputTarget.files[0].name);
            }
        });
    }
}
