<div class="row page-titles">
    <div class="col-md-5 align-self-center">
        <h3
            class="text-themecolor"
            *ngIf="btnText.toLocaleLowerCase() != 'update'"
        >
            Add Staff
        </h3>
        <h3
            class="text-themecolor"
            *ngIf="btnText.toLocaleLowerCase() == 'update'"
        >
            Edit Staff
        </h3>
    </div>
    <div class="col-md-7 align-self-center">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <a href="javascript:void(0)">Home</a>
            </li>
            <li
                class="breadcrumb-item active"
                *ngIf="btnText.toLocaleLowerCase() != 'update'"
            >
                Add Staff
            </li>
            <li
                class="breadcrumb-item active"
                *ngIf="btnText.toLocaleLowerCase() == 'update'"
            >
                Edit Staff
            </li>
        </ol>
    </div>
</div>

<!-- Add Employee Start -->
<section *ngIf="!isEdit">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 text-left mb-3">
                                <a routerLink="/panel/employees">
                                    <button
                                        type="button"
                                        class="btn waves-light waves-effect waves-light btn-small btn-info f-14"
                                        tooltip="Back to staff list"
                                        placement="bottom"
                                        container="body"
                                    >
                                        <i
                                            class="fa fa-arrow-left"
                                            aria-hidden="true"
                                            style="font-size: 16px"
                                        ></i>
                                    </button>
                                </a>
                            </div>
                            <div
                                class="col-md-6 text-right mb-3"
                                *ngIf="btnText.toLocaleLowerCase() != 'update'"
                            >
                                <button
                                    type="button"
                                    (click)="openModal()"
                                    class="btn waves-light waves-effect waves-light btn-small btn-info f-14"
                                >
                                    Get Data From AKcessID
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xl-12">
                                <form
                                    [formGroup]="adduserform"
                                    id="add-employee-frm"
                                >
                                    <!-- *ngIf="act=='add'" -->
                                    <input
                                        type="hidden"
                                        formControlName="_id"
                                    />
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="font-500"
                                                >AKcess ID<span
                                                    class="required-label"
                                                    >*</span
                                                ></label
                                            >
                                            <!-- *ngIf="act!='add'" -->
                                            <input
                                                type="text"
                                                maxlength="11"
                                                minlength="11"
                                                class="form-control"
                                                formControlName="akcessId"
                                                placeholder="AKcess ID"
                                            />
                                            <!-- <input *ngIf="act=='add'" type="text" maxlength="12" minlength="11"
                                                class="form-control" formControlName="akcessId" placeholder="AKcess ID"
                                                required> -->
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    formControls.akcessId
                                                        .errors &&
                                                    formControls.akcessId.errors
                                                        .required
                                                "
                                                class="invalid-feedback"
                                            >
                                                AKcess ID is required
                                            </div>
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    formControls.akcessId
                                                        .errors &&
                                                    formControls.akcessId.errors
                                                        ?.minlength
                                                "
                                                class="invalid-feedback"
                                            >
                                                AKcessID must be at least 11
                                                characters long.
                                            </div>
                                            <div class="valid-feedback mt-0">
                                                <small
                                                    >Please make sure to add the
                                                    correct AKcess ID.</small
                                                >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                                formControlName="isAdmin"
                                                id="flexCheckDefault"
                                            />
                                            <label
                                                class="form-check-label"
                                                for="flexCheckDefault"
                                            >
                                                Make this staff as admin
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label
                                                class="font-500"
                                                for="firstName"
                                                >First Name<span
                                                    class="required-label"
                                                    >*</span
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                formControlName="firstName"
                                                placeholder="First Name"
                                                required
                                            />
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    formControls.firstName
                                                        .errors &&
                                                    formControls.firstName
                                                        .errors.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                First name is required
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label
                                                class="font-500"
                                                for="lastName"
                                                >Last Name<span
                                                    class="required-label"
                                                    >*</span
                                                ></label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                formControlName="lastName"
                                                placeholder="Last Name"
                                                required
                                            />
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    formControls.lastName
                                                        .errors &&
                                                    formControls.lastName.errors
                                                        .required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Last name is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="font-500" for="email"
                                                >Email<span
                                                    class="required-label"
                                                    >*</span
                                                ></label
                                            >
                                            <input
                                                type="email"
                                                class="form-control"
                                                formControlName="email"
                                                placeholder="Email"
                                                required
                                            />
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    formControls.email.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Email is required
                                            </div>
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    formControls.email.errors
                                                        ?.email
                                                "
                                                class="invalid-feedback"
                                            >
                                                Email is invalid
                                            </div>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="font-500" for="phone"
                                                >Mobile<span
                                                    class="required-label"
                                                    >*</span
                                                ></label
                                            >
                                            <ngx-intl-tel-input
                                                [cssClass]="'custom'"
                                                [preferredCountries]="
                                                    preferredCountries
                                                "
                                                [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [searchCountryField]="[
                                                    SearchCountryField.Iso2,
                                                    SearchCountryField.Name
                                                ]"
                                                [selectFirstCountry]="false"
                                                [selectedCountryISO]="
                                                    CountryISO.UnitedKingdom
                                                "
                                                [maxLength]="15"
                                                [phoneValidation]="true"
                                                [separateDialCode]="true"
                                                [numberFormat]="
                                                    PhoneNumberFormat.National
                                                "
                                                name="phone"
                                                formControlName="phone"
                                            >
                                            </ngx-intl-tel-input>
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    formControls.phone.errors
                                                        ?.required
                                                "
                                                class="invalid-feedback"
                                            >
                                                Mobile Number is required
                                            </div>
                                            <div
                                                *ngIf="
                                                    isSubmitted &&
                                                    !adduserform.controls[
                                                        'phone'
                                                    ].valid
                                                "
                                                class="invalid-feedback"
                                            >
                                                Mobile Number is not valid
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="font-500"
                                                >Date of Birth
                                            </label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="DOB"
                                                placeholder="Select Date"
                                                formControlName="DOB"
                                                bsDatepicker
                                                [bsConfig]="{
                                                    dateInputFormat:
                                                        'DD-MM-YYYY',
                                                    containerClass: 'theme-red',
                                                    isAnimated: true,
                                                    adaptivePosition: true
                                                }"
                                            />
                                            <!-- <div class="invalid-feedback"
                                                *ngIf="isSubmitted && formControls.DOB.errors && formControls.DOB.errors.required ">
                                                Please select DOB
                                            </div> -->
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label
                                                class="font-500"
                                                for="lastName"
                                                >Place of Birth</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                formControlName="placeOfBirth"
                                                placeholder="Address"
                                            />
                                            <!-- <div class="invalid-feedback"
                                                *ngIf="isSubmitted && formControls.placeOfBirth.errors && formControls.placeOfBirth.errors.required ">
                                                Please enter place of birth
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="font-500"
                                                >Gender</label
                                            >
                                            <select
                                                class="form-control"
                                                formControlName="gender"
                                            >
                                                <option value="">
                                                    Select Gender
                                                </option>
                                                <option value="male">
                                                    Male
                                                </option>
                                                <option value="female">
                                                    Female
                                                </option>
                                                <option value="other">
                                                    Other
                                                </option>
                                            </select>
                                            <!-- <div class="invalid-feedback"
                                                *ngIf="isSubmitted && formControls.gender.errors && formControls.gender.errors.required ">
                                                Please select gender
                                            </div> -->
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="font-500"
                                                >Nationality</label
                                            >
                                            <select
                                                name="wgtmsr"
                                                class="form-control"
                                                formControlName="nationality"
                                            >
                                                <option value="">
                                                    Select Country
                                                </option>
                                                <option
                                                    *ngFor="
                                                        let country of countryList
                                                    "
                                                    value="{{ country }}"
                                                >
                                                    {{ country }}
                                                </option>
                                            </select>
                                            <!-- <div class="invalid-feedback"
                                                *ngIf="isSubmitted && formControls.nationality.errors && formControls.nationality.errors.required ">
                                                Please select nationality
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <label>Address</label>
                                            <textarea
                                                class="form-control"
                                                formControlName="address"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <!-- {{adduserform.controls.value | json}} -->
                                </form>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <button
                                    type="submit"
                                    class="btn waves-effect waves-light btn-info mr-2"
                                    (click)="addEmployee()"
                                >
                                    <i class="fa fa-save"></i> {{ btnText }}
                                </button>
                                <button
                                    type="button"
                                    (click)="redirect()"
                                    *ngIf="
                                        btnText.toLocaleLowerCase() != 'update'
                                    "
                                    class="btn waves-effect waves-light btn-danger"
                                    id="addEmployeeDetailClose"
                                    data-dismiss="modal"
                                >
                                    <i class="fa fa-times"></i> Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="upload-img-parent">
                            <img
                                class="upload-profile-img"
                                src="{{ uploadProfileImage }}"
                            />
                            <label *ngIf="uploadProfileImage?.length < 100"
                                >Click here to upload photo</label
                            >
                            <input
                                class="form-control select-img"
                                type="file"
                                accept=".jpg;.png"
                                (change)="onUploadChange($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Add Employee End -->

<!-- View Employee Start -->
<section *ngIf="isEdit">
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 text-left mb-3">
                        <a routerLink="/panel/employees">
                            <button
                                type="button"
                                class="btn waves-light waves-effect waves-light btn-small btn-info f-14"
                                tooltip="Back to staff list"
                                placement="bottom"
                                container="body"
                            >
                                <i
                                    class="fa fa-arrow-left"
                                    aria-hidden="true"
                                    style="font-size: 16px"
                                ></i>
                            </button>
                        </a>
                    </div>
                    <div class="col-md-6 text-right mb-3">
                        <button
                            class="btn waves-light waves-effect waves-light btn-small btn-info f-14 mr-2"
                            (click)="editEmployee()"
                        >
                            <i class="fa fa-pencil"></i> &nbsp; Edit
                        </button>
                        <button
                            class="btn waves-light waves-effect waves-light btn-small btn-info f-14 mr-2"
                            (click)="deleteEmployee()"
                        >
                            <i class="fa fa-trash" style="cursor: pointer"></i>
                            &nbsp; Delete
                        </button>
                        <button
                            (click)="redirect()"
                            class="btn waves-light waves-effect waves-light btn-small btn-danger f-14"
                        >
                            <i class="fa fa-times"></i> &nbsp; Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header view-emp-header">
                        <i class="fa fa-address-card" aria-hidden="true"></i>
                        &nbsp; View Staff ( {{ userData.firstName }}
                        {{ userData.lastName }} )
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="font-w-500">AKcessID</label>
                                <p>{{ userData.akcessId }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">First Name</label>
                                <p>
                                    {{
                                        userData.firstName
                                            ? userData.firstName
                                            : "-"
                                    }}
                                </p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Last Name</label>
                                <p>
                                    {{
                                        userData.lastName
                                            ? userData.lastName
                                            : "-"
                                    }}
                                </p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Email</label>
                                <p>
                                    {{ userData.email ? userData.email : "-" }}
                                </p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Mobile Number</label>
                                <p *ngIf="userData.phone">
                                    +{{ userData.countryCode }}
                                    {{ userData.phone }}
                                </p>
                                <p *ngIf="!userData.phone">-</p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Date of Birth</label>
                                <p>{{ userData.dateofbirth | date }}</p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Place of Birth</label>
                                <p>
                                    {{
                                        userData.placeofbirth
                                            ? userData.placeofbirth
                                            : "-"
                                    }}
                                </p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Gender</label>
                                <p>
                                    {{
                                        userData.gender ? userData.gender : "-"
                                    }}
                                </p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Nationality</label>
                                <p>
                                    {{
                                        userData.nationality
                                            ? userData.nationality
                                            : "-"
                                    }}
                                </p>
                            </div>
                            <div class="col-12">
                                <label class="font-w-500">Address</label>
                                <p>
                                    {{
                                        userData.address
                                            ? userData.address
                                            : "-"
                                    }}
                                </p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Created</label>
                                <p>{{ userData.createdon | date }}</p>
                            </div>
                            <div class="form-group col-md-6 col-sm-12 col-xl-6">
                                <label class="font-w-500">Modified</label>
                                <p>{{ userData.modifiedon | date }}</p>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-3">
                                <label>Documents</label>
                            </div>
                            <div class="col-9">
                                <button
                                    class="btn btn-info f-14"
                                    data-toggle="modal"
                                    data-target=".upload-doc-modal-lg"
                                >
                                    <i class="fa fa-plus"></i>
                                    &nbsp; Add Documents
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="docList.length > 0">
                            <div class="col-md-12 mt-3">
                                <table
                                    class="table color-bordered-table info-bordered-table text-center"
                                    mdbTable
                                    #tableEl="mdbTable"
                                    hover="true"
                                    small="true"
                                >
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Document Name</th>
                                            <th>Document Type</th>
                                            <th>Expiry Date</th>
                                            <th colspan="3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let doc of docList;
                                                let i = index
                                            "
                                        >
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ doc.document_name }}</td>
                                            <td>{{ doc.document_type }}</td>
                                            <td>{{ doc.expiryDate | date }}</td>
                                            <td>
                                                <a
                                                    href="{{
                                                        getDocmentFullURL(
                                                            doc.file_url
                                                        )
                                                    }}"
                                                    target="_blank"
                                                >
                                                    <i class="fa fa-eye"></i>
                                                </a>
                                            </td>
                                            <td>
                                                <i
                                                    class="fa fa-trash"
                                                    (click)="
                                                        deleteDocument(doc)
                                                    "
                                                    style="cursor: pointer"
                                                ></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-3">
                                <label>IDs</label>
                            </div>
                            <div class="col-9" *ngIf="empCard == ''">
                                <button
                                    class="btn btn-info f-14"
                                    (click)="createIdCard()"
                                >
                                    <i class="fa fa-plus"></i>
                                    &nbsp; Create ID Card
                                </button>
                            </div>
                        </div>
                        <div class="row" *ngIf="empCard != ''">
                            <div class="col-md-12 mt-3">
                                <table
                                    class="table color-bordered-table info-bordered-table text-center"
                                    mdbTable
                                    #tableEl="mdbTable"
                                    hover="true"
                                    small="true"
                                >
                                    <thead>
                                        <tr>
                                            <th>Document Name</th>
                                            <th>Expiry Date</th>
                                            <th colspan="3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ empCard.file_name }}</td>
                                            <td>
                                                {{ empCard.expiryDate | date }}
                                            </td>
                                            <td>
                                                <a
                                                    href="{{
                                                        getIdCardFullURL(
                                                            empCard
                                                                .transaction[0]
                                                        )
                                                    }}"
                                                    target="_blank"
                                                >
                                                    <i class="fa fa-eye"></i>
                                                </a>
                                            </td>
                                            <td>
                                                <i
                                                    class="fa fa-trash"
                                                    (click)="
                                                        deleteEmployeeCard()
                                                    "
                                                    style="cursor: pointer"
                                                ></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="upload-img-parent">
                            <img
                                class="upload-profile-img"
                                src="{{ uploadProfileImage }}"
                            />
                            <label *ngIf="uploadProfileImage?.length < 100"
                                >Click here to upload photo</label
                            >
                            <input
                                class="form-control select-img"
                                type="file"
                                accept=".jpg;.png"
                                (change)="onUploadChange($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- View Employee End -->

<!-- Upload document start -->
<div
    class="modal fade upload-doc-modal-lg"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    Upload Documents
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" [formGroup]="uploadDocForm">
                    <div class="col-12">
                        <div class="form-group">
                            <label>
                                Document Name
                                <span class="required-label">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                formControlName="docName"
                            />
                            <span
                                class="invalid-feedback"
                                *ngIf="
                                    isUploadSubmit &&
                                    uploadDocForm.controls.docName.errors &&
                                    uploadDocForm.controls.docName.errors
                                        .required
                                "
                                >*Enter document name</span
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>
                                Document Type
                                <span class="required-label">*</span>
                            </label>
                            <select
                                class="form-control"
                                formControlName="docType"
                            >
                                <option value="">Select Document Type</option>
                                <option value="Id Document">ID Document</option>
                                <option value="Academic Transcript">
                                    Academic Transcript
                                </option>
                                <option value="Reference Letter">
                                    Reference Letter
                                </option>
                                <option value="CV">CV</option>
                                <option value="Others">Others</option>
                            </select>
                            <span
                                class="invalid-feedback"
                                *ngIf="
                                    isUploadSubmit &&
                                    uploadDocForm.controls.docType.errors &&
                                    uploadDocForm.controls.docType.errors
                                        .required
                                "
                                >*Enter document name</span
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>
                                Document Expiry Date
                                <span class="required-label">*</span>
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                bsDatepicker
                                formControlName="docExpiryDate"
                                [bsConfig]="{
                                    dateInputFormat: 'DD-MM-YYYY',
                                    containerClass: 'theme-red',
                                    isAnimated: true,
                                    adaptivePosition: true
                                }"
                            />
                            <span
                                class="invalid-feedback"
                                *ngIf="
                                    isUploadSubmit &&
                                    uploadDocForm.controls.docExpiryDate
                                        .errors &&
                                    uploadDocForm.controls.docExpiryDate.errors
                                        .required
                                "
                                >*Please select document expiry date</span
                            >
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>
                                Attach file
                                <span class="required-label">*</span>
                            </label>
                            <input
                                type="file"
                                (change)="onFileChange($event.target.files)"
                                class="form-control"
                                formControlName="docFile"
                            />
                            <span
                                class="invalid-feedback"
                                *ngIf="
                                    isUploadSubmit &&
                                    uploadDocForm.controls.docFile.errors &&
                                    uploadDocForm.controls.docFile.errors
                                        .required
                                "
                                >*Please attach document file</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-info"
                    (click)="uploadDocument()"
                >
                    Upload
                </button>
                <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    id="closeBtn"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Upload document end -->
