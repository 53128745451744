import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GeneralServiceService } from '../../../../_services/general-service.service';

@Component({
    selector: 'app-doc-view-form',
    templateUrl: './doc-view-form.component.html',
    styleUrls: ['./doc-view-form.component.css']
})
export class DocViewFormComponent implements OnInit {

    docId: string;
    idCardSents = [];
    idCardSentsEmail = [];
    idCardSentsPhone = [];
    sendList: any = [];

    //Pagination
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [5, 10, 20, 30, 40, 50];
    pageSize: any = 5;

    constructor(
        private _bsModalRef: BsModalRef,
        private akcessService: GeneralServiceService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
    ) { }

    ngOnInit(): void {
        this.getSentList("akcessid");
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    onSelect(type) {
        this.getSentList(type);
    }

    getSentList(type) {
        this.spinner.show();
        const obj = {
            senderType: type,
            DocumentID: this.docId,
            page: this.currentPage,
            limit: this.pageSize
        };

        this.akcessService.getViewSendDocument(obj).subscribe(
            (res: any) => {
                if (res.status) {
                    this.sendList = res.data.docs.reverse();
                    this.totalCount = res.data.totalDocs;
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //Pagination
    onProjectPageChange(event, senderType) {
        this.currentPage = event;
        this.getSentList(senderType)
        // this.getuserData(true);
    }

    changePage(value,senderType) {
        this.currentPage = 1;
        this.pageSize = parseInt(value);
        this.getSentList(senderType)
        // this.getuserData(true);
    }

}
