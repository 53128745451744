// // export const environment = {
// //     production: true,
// //     frontendurl: "https://sme-v2.akcess.app",
// //     urllogin: "https://ak-api.akcess.dev/",
// //     urlAkcess: "https://cbk.akcess.app:4100/",
// //     imgurl: "https://sme-uk.akcess.app/api/",
// //     //  url: 'http://localhost:3637/',
// //     url:"https://sme-uk.akcess.app/api/",
// //     apiKey: "04151cf92715fec2ff4d631be1df3b9b",//"a53fcc8e1bc7581cfd691cce0772eb0c", //local api key
// //     eformurl: "https://eforms.akcess.io/",
// //     akcessURL: "https://mobile.akcess.dev:3000/",
// //     eformname: "on-boarding client template",
// //     eformid: "5fa10f8f2606fc6d96ceb6fb",
// //     urlloginV2: "https://ak-api-v2.akcess.dev/",
// //     domainName: "sme-uk.akcess.app",
// //     domainUrl:"https://sme-uk.akcess.app/api/"
// // };


// export const environment = {
//     production: true,
//     frontendurl: "https://sme-v2.akcess.app",
//     urllogin: "https://ak-api.akcess.dev/",
//     urlAkcess: "https://cbk.akcess.app:4100/",
//     imgurl: "http://192.168.1.12:3000/",//"https://sme.akcess.app/api/",
//     //  url: 'http://localhost:3637/',
//     url:"http://192.168.1.12:3000/",//"https://sme.akcess.app/api/",
//     apiKey: "a53fcc8e1bc7581cfd691cce0772eb0c", //local api key
//     eformurl: "https://eforms.akcess.io/",
//     akcessURL: "https://mobile.akcess.dev:3000/",
//     eformname: "on-boarding client template",
//     eformid: "5fa10f8f2606fc6d96ceb6fb",
//     urlloginV2: "https://ak-api-v2.akcess.dev/",
//     domainName: "sme.akcess.app",
//     domainUrl: "http://192.168.1.12:3000/",//"https://sme.akcess.app/api/"
// };


// //192.168.1.13:3000   https://github.com/AKoncepts/SME-Frontend.git

export const environment = {
    production: true,
    frontendurl: "https://sme-v2.akcess.app",
    urllogin: "https://ak-api.akcess.dev/",
    urlAkcess: "https://cbk.akcess.app:4100/",
    imgurl: "https://sme-uk.akcess.app/api/",
    //  url: 'http://localhost:3637/',
    url:"https://sme-uk.akcess.app/api/",
    apiKey: '04151cf92715fec2ff4d631be1df3b9b',//"a53fcc8e1bc7581cfd691cce0772e>
    eformurl: "https://eforms.akcess.io/",
    akcessURL: "https://mobile.akcess.dev:3000/",
    eformname: "on-boarding client template",
    eformid: "5fa10f8f2606fc6d96ceb6fb",
    urlloginV2: "https://ak-api-v2.akcess.dev/",
    domainName: "sme-uk.akcess.app",
    domainUrl: "https://sme-uk.akcess.app/api/"
};
