import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Route, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { CommonService } from "src/app/_services/common.service";
import { ConstantService } from "src/app/_services/constant.service";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { ConfirmDialogService } from "../../confirm-dialog/confirm-dialog.service";
@Component({
    selector: "app-get-data-from-id",
    templateUrl: "./get-data-from-id.component.html",
    styleUrls: ["./get-data-from-id.component.css"],
})
export class GetDataFromIdComponent implements OnInit {
    constructor(
        public modalService: BsModalService,
        public dataService: DataservicesService,
        public toast: ToastrService,
        public http: HttpClient,
        public constantService: ConstantService,
        public fb: FormBuilder,
        public router: Router,
        public spinner: NgxSpinnerService,
        public commonService: CommonService,
        public refreshContentService: RefreshContentService,
        private confirmDialogService: ConfirmDialogService
    ) {}

    DataForm: FormGroup;

    public myContent;

    routeName: any = "";
    companyLogo: any = "";
    ngOnInit(): void {
        if (sessionStorage.getItem("selectedCompanyLogoImgLarge")) {
            this.companyLogo = sessionStorage.getItem(
                "selectedCompanyLogoImgLarge"
            );
        } else {
            this.http.get("/assets/default-logo.json").subscribe((res: any) => {
                this.companyLogo = res.logo;
            });
        }
        this.DataForm = this.fb.group({
            akcessId: ["", [Validators.required, Validators.minLength(11)]],
        });
        this.onClose = new Subject();
        if (window.location.href.includes("employees")) {
            this.routeName = "employees";
        } else {
            this.routeName = "clients";
        }
        // if (this.refreshContentService.subsVar == undefined) {
        this.refreshContentService.subsVar =
            this.refreshContentService.refreshTableFunction.subscribe(() => {});
        // }
    }

    closeModal() {
        this.modalService.hide();
    }

    public onClose: Subject<any>;

    requestData() {
        this.spinner.show();
        let data = {
            akcessId: this.DataForm.controls.akcessId.value,
            usertype: this.routeName == "clients" ? "Client" : "Employee",
        };
        let me = this;
        this.dataService.setUserDataFromAkcessId(data).subscribe(
            (res: any) => {
                if (res.status == false) {
                    if (res.removed_flg != undefined) {
                        if (res.removed_flg == true) {
                            this.spinner.hide();
                            me.confirmDialogService.confirmThis(
                                res.message,
                                function () {
                                    me.onClose.next(res);
                                    me.modalService.hide();
                                    me.restoreDeletedClientOnAdd(res.client_id);
                                },
                                function () {}
                            );
                        } else {
                            this.spinner.hide();
                            this.toast.error(res.message);
                        }
                    } else {
                        this.spinner.hide();
                        this.toast.error(res.message);
                    }
                }
                if (res.status == true) {
                    this.onClose.next(res);
                    this.requestUserInfo(res);
                    this.modalService.hide();
                    /*if (res.message.includes("user already exists")) {
                        this.toast.warning(res.message);
                        setTimeout(() => {
                            if (confirm("Do you want to send request again?")) {
                                this.requestUserInfo(res);
                            } else {
                                this.spinner.hide();
                            }
                        }, 1500);
                    } else {
                        this.requestUserInfo(res);
                        this.modalService.hide();
                    }*/
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(err.message);
            }
        );
    }

    restoreDeletedClientOnAdd(client_id) {
        this.spinner.show();
        const data = {
            component: "client",
            _id: client_id,
        };

        this.dataService.restoreDeletedData(data).subscribe(
            (res: any) => {
                if (res.status) {
                    this.toast.success("Client successfully restored");
                    this.router.navigate(["/panel/clients"]);
                    this.spinner.hide();
                } else {
                    this.toast.info("Failed to restore client");
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    async requestUserInfo(val) {
        let data: any = this.constantService.setData();
        let user: any = JSON.parse(sessionStorage.getItem("portalUser"));

        data.akcessId = this.DataForm.controls.akcessId.value;
        data.description = "Get data from AKcess id for " + this.routeName;
        data.logo = this.companyLogo;
        data.requestId = val._id;
        data.request_user =
            user.firstName + " " + user.lastName + " (" + user.akcessId + ")";
        data.request_type =
            this.routeName == "employees" ? "employee" : "client";
        data.type = this.routeName == "employees" ? "employee" : "client";
        data.domain_name = "sme.akcess.app";
        data.db_name = this.commonService.selectedCompanyDeatils.dbName;
        data.requestId = val.data;
        this.dataService.getUserInformation(data).subscribe(
            (res: any) => {
                if (res.status) {
                    this.spinner.hide();
                    this.toast.success("Request sent successfully.");
                    this.router.navigate(["/panel/" + this.routeName]);
                    this.modalService.hide();
                } else {
                    this.spinner.hide();
                    this.toast.success(res.message);
                    this.modalService.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
                this.modalService.hide();
            }
        );
    }

    getDefaultLogo() {
        this.http.get("/assets/default-logo.json").subscribe((res: any) => {
            return res.logo;
        });
    }
}
