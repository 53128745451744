import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { DataservicesService } from '../../../../_services/dataservices.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-eformresponse-grade',
    templateUrl: './eformresponse-grade.component.html',
    styleUrls: ['./eformresponse-grade.component.css']
})
export class EformresponseGradeComponent implements OnInit {

    graderLoader: boolean = false;
    fieldVerifierData: any;
    fieldVerifierDataLength: any;
    data: any;
    userAkcessID: any;
    gradedata = [];

    constructor(
        private _bsModalRef: BsModalRef,
        private calldata: DataservicesService,
        private toast: ToastrService,
    ) { }

    ngOnInit(): void {
        this.viewGradeVer();
    }

    closeModal() {
        this._bsModalRef.hide();
    }


    viewGradeVer() {
        this.graderLoader = true;
        this.fieldVerifierData = [];

        if (this.data.keytype == "undefined" || this.data.keytype == "") {
            return this.toast.error("Keytype is not valid");
        }

        else if (this.data.keytype == "file") {
            let object = {
                userAkcessId: this.userAkcessID,
                documentId: this.data.value
            };

            this.calldata.getDocVerifierOnPortal(object).subscribe((res: any) => {
                if (res.status) {
                    let resultObject = res.data.data.data;
                    for (const keyname in resultObject) {
                        if (this.data.key === keyname) {
                            this.fieldVerifierData = resultObject[keyname];
                        }
                    }
                    //this.fieldVerifierDataLength = this.fieldVerifierData.data.length;
                    this.fieldVerifierDataLength = Object.keys(this.fieldVerifierData).length;
                    this.graderLoader = false;

                } else {
                    this.graderLoader = false;
                }
            }, (err) => {
                this.graderLoader = false;
                this.toast.error("Oops, something went wrong. Please try again.")
            });
        }
        else {
            let object = {
                userAkcessId: this.userAkcessID,
                profileField: this.data.key
            };
            this.calldata.getFieldVerifierOnPortal(object).subscribe((res: any) => {
                if (res.status) {
                    //this.fieldVerifierData = res.data.data.data;
                    if (res.data.status) {
                        let resultObject = res.data.data.data;
                        for (const keyname in resultObject) {
                            if (this.data.key === keyname) {
                                this.fieldVerifierData = resultObject[keyname];
                            }
                        }
                        this.fieldVerifierDataLength = Object.keys(this.fieldVerifierData).length;
                    }
                    else {
                        this.toast.error(res.data.message);
                        this.toast.error('statusCode:' + res.data.statusCode);
                    }
                    this.graderLoader = false;

                } else {
                    this.graderLoader = false;
                }
            });

        }
        this.gradedata["key"] = this.data.key;

        //if (
        //    this.data.keytype == "string" ||
        //    this.data.keytype == "text" ||
        //    this.data.keytype == "list"
        //) {

        //    let object = {
        //        userAkcessId: this.userAkcessID,
        //        profileField: this.data.key
        //    };

        //    this.calldata.getFieldVerifierOnPortal(object).subscribe((res: any) => {
        //        if (res.status) {
        //            this.fieldVerifierData = res.data;
        //            this.graderLoader = false;
        //        } else {
        //            this.graderLoader = false;
        //        }
        //    });
        //}      

        //if (this.data.keytype == "file") {

        //    let object = {
        //        userAkcessId: this.userAkcessID,
        //        documentId: this.data.value
        //    };

        //    this.calldata.getDocVerifierOnPortal(object).subscribe((res: any) => {
        //        if (res.status) {
        //            this.graderLoader = false;
        //            this.fieldVerifierData = res.data;
        //        } else {
        //            this.graderLoader = false;
        //        }
        //    });
        //}

        //this.gradedata["key"] = this.data.key;
    }

}
