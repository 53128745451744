import { Component, OnInit, Input } from "@angular/core";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import Base64UploaderPlugin from "@ckeditor/Base64Upload";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit {
  public Editor = ClassicEditor;
  public editModeEditorConfig = {
    isReadOnly: true,
    toolbar: { items: [] },
  };
  public editorConfig = {
    extraPlugins: [Base64UploaderPlugin],
    placeholder: "Enter Your Text Here",
  };
  @Input()
  //url: string = sessionStorage.getItem('portalFrontendURL')+'/mobile/';
  // urlSafe: SafeResourceUrl;
  public accountData = [];
  userList: any = [];
  portalUser: any = {};
  description: string;
  updateddate: string;
  portaldata: any;
  constructor(
    private spinner: NgxSpinnerService,
    private ds: DataservicesService,
    private gs: GeneralServiceService,
    private toast: ToastrService,
    private refreshContentService: RefreshContentService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.refreshContentService.notifyToAll.subscribe(res => {
      if (res && res.hasOwnProperty("option")) {
        this.getData();
        this.getLiveUserData();

      }
      else {
        this.getData();
        this.getLiveUserData();
      }
    });

    // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  getData() {
    this.portaldata = this.commonService.portalDetails.portalDetails.portalData;
    // alert(this.portaldata.setting.desc);
    this.spinner.show();
    this.description = this.portaldata.setting.desc;
    this.updateddate = this.portaldata.setting.updateddate;
    this.spinner.hide();
    // this.ds.getAccountData().subscribe(data => {
    //     if (data['status']) {
    //         this.accountData = data['results'];
    //         this.description = data['results']['description'];
    //         this.updateddate = data['results']['updateddate'];
    //         this.spinner.hide();
    //     }
    // });
  }
  getLiveUserData() {
    this.spinner.show();
    this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    const filter = {
      akcessId: this.portalUser.akcessId,
      isLogin: true,
    };
    this.ds.getLiveUserList(filter).subscribe(
      (data) => {
        if (data["status"] === 1) {
          this.userList = [];
          this.userList = data["data"];
          this.spinner.hide();
          this.toast.success(data["message"]);
        } else {
          this.toast.info(data["message"]);
          this.spinner.hide();
        }
      },
      (error) => {
        this.toast.error("something went wrong");
        this.spinner.hide();
      }
    );
  }
  saveData() {
    this.spinner.show();
    const filedata = {
      desc: this.description,
      domainname: this.commonService.portalDetails.portalDetails.portalDomainName
    };
    this.gs.saveAccountData(filedata).subscribe((data) => {
      if (data["status"]) {
        // alert(data['status'])
        // this.portaldata = JSON.parse(sessionStorage.getItem("portalData"));
        this.portaldata = this.commonService.portalDetails.portalDetails.portalData;
        this.portaldata.setting.desc = this.description;
        sessionStorage.setItem("portalData", JSON.stringify(this.portaldata));
        this.toast.info(data["message"]);
        this.getData();
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    });
  }
}
