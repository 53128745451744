<div class="location-page">
  <!-- Bread crumb -->
  <div class="row page-titles">
    <div class="col-md-5 align-self-center">
      <h3 class="text-themecolor">Access Control Deleted List</h3>
    </div>
    <div class="col-md-7 align-self-center">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/panel/access-control']">Access Control</a>
        </li>
        <li class="breadcrumb-item active">Access Control Deleted List</li>
      </ol>
    </div>
  </div>
  <!-- Main Section -->
  <section>
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="card-title row align-items-center">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  name="searchName"
                  placeholder="Search Access Control"
                />
              </div>
            </div>
          </div>
          <div class="action-tab">
            <button
              type="button"
              class="mx-1 btn"
              container="body"
              [routerLink]="['/panel/access-control']"
            >
              <i class="fas fa-arrow-left"></i>
            </button>
            <span class="vertical-divider">|</span>
            <button
              type="button"
              class="mx-1 btn"
              tooltip="Remove Permanently"
              container="body"
              (click)="deletePermantly()"
            >
              <i class="fas fa-trash"></i>
            </button>
            <span class="vertical-divider">|</span>
            <button
              type="button"
              class="mx-1 btn"
              tooltip="Restore"
              container="body"
              (click)="restoreDeleted()"
            >
              <i class="fas fa-trash-restore"></i>
            </button>
          </div>
          <div class="table-responsive mb-1">
            <table
              class="table color-bordered-table info-bordered-table"
              mdbTable
              #tableEl="mdbTable"
              small="true"
              hover="true"
            >
              <thead>
                <tr>
                  <th>
                    <div class="form-check">
                      <input
                        class="datatable-row-checkbox form-check-input"
                        type="checkbox"
                        (change)="checkUncheckAll()"
                        [(ngModel)]="isMasterSel"
                      />
                    </div>
                  </th>
                  <th>
                    Name
                    <span
                      ><mdb-icon icon="sort" class="fas"
                        ><i class="fa-sort fas"></i></mdb-icon
                    ></span>
                  </th>
                  <th>Days</th>
                  <th>Open From</th>
                  <th>Open To</th>
                  <th>
                    Created On
                    <span
                      ><mdb-icon icon="sort" class="fas"
                        ><i class="fa-sort fas"></i></mdb-icon
                    ></span>
                  </th>
                  <th>
                    Deleted On
                    <span
                      ><mdb-icon icon="sort" class="fas"
                        ><i class="fa-sort fas"></i></mdb-icon
                    ></span>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="accessControlDeletedlist?.length == 0">
                <tr>
                  <td colspan="6" class="text-center">
                    No Deleted access-control Found
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="accessControlDeletedlist?.length > 0">
                <tr
                  [id]="location?._id"
                  *ngFor="
                    let location of accessControlDeletedlist
                      | paginate
                        : {
                            itemsPerPage: pageSize,
                            currentPage: currentPage,
                            totalItems: totalCount
                          };
                    let i = index
                  "
                >
                  <td>
                    <div class="form-check">
                      <input
                        class="datatable-row-checkbox form-check-input"
                        type="checkbox"
                        [(ngModel)]="location.isSelected"
                        [attr.id]="location?._id"
                        value="location?._id"
                        (change)="isAllSelected()"
                      />
                    </div>
                  </td>
                  <td>
                    {{ location.locationName }}
                  </td>
                  <td>
                    <div
                      *ngFor="let schedule of location.schedules"
                      class="capitalize"
                    >
                      {{ getDays(schedule.days) }}
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let schedule of location.schedules">
                      {{ schedule.openFrom | date : "hh:mm a" }}
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let schedule of location.schedules">
                      {{ schedule.openTo | date : "hh:mm a" }}
                    </div>
                  </td>
                  <td>{{ location.createdAt | date : "mediumDate" }}</td>
                  <td>{{ location.updatedAt | date : "mediumDate" }}</td>
                </tr>
              </tbody>
              <tfoot class="w-100">
                <tr>
                  <td colspan="6">
                    <div
                      class="table-page"
                      *ngIf="accessControlDeletedlist?.length > 10"
                    >
                      <div class="page-controls">
                        <pagination-controls
                          (pageChange)="onProjectPageChange($event)"
                          class="my-pagination"
                          directionLinks="true"
                          previousLabel="Previous"
                          nextLabel="Next"
                        ></pagination-controls>
                      </div>
                      <div class="page-size">
                        <label class="mb-0 mr-2">Item per page</label>
                        <select
                          #val
                          (change)="changePage(val.value)"
                          class="form-control form-control-sm"
                          id="pageSelector"
                        >
                          <option
                            *ngFor="let pG of numberOfPage"
                            value="{{ pG }}"
                          >
                            {{ pG }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
