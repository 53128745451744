<!--eForm Response View Modal -->
<div class="modal-content eform-response-modal no-border">
	<div class="modal-header">
		<h4 class="modal-title">{{ formname }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body background-light-blue">
		<div>
			<input type="hidden" [(ngModel)]="val" value="{{ val }}" />
			<input type="hidden" [(ngModel)]="fullname" value="{{ fullname }}" />
			<input type="hidden" [(ngModel)]="_id" value="{{ _id }}" />
			<input type="hidden" [(ngModel)]="akcessId" value="{{ akcessId }}" />
			<input type="hidden" [(ngModel)]="eformresponse" value="{{ eformresponse }}" />

			<!--<input type="hidden" [(ngModel)]="eformId" value="{{eformId}}" />
            <input type="hidden" [(ngModel)]="fields" value="{{fields}}" />
            <input type="hidden" [(ngModel)]="eformResponseEformId" value="{{eformResponseEformId}}" />
            <input type="hidden" [(ngModel)]="eformStatus" value="{{eformStatus}}" />
            <input type="hidden" [(ngModel)]="device_token" value="{{device_token}}" />
            <input type="hidden" [(ngModel)]="mobile_local_id" value="{{mobile_local_id}}" />
            <input type="hidden" [(ngModel)]="facematch" value="{{facematch}}" />
            <input type="hidden" [(ngModel)]="faceMatchPic" value="{{faceMatchPic}}" />
            <input type="hidden" [(ngModel)]="faceMatchPicname" value="{{faceMatchPicname}}" />
            <input type="hidden" [(ngModel)]="form_file" value="{{form_file}}" />
            <input type="hidden" [(ngModel)]="form_domain" value="{{form_domain}}" />
            <input type="hidden" [(ngModel)]="formname" value="{{formname}}" />
            <input type="hidden" [(ngModel)]="isFacialMatched" value="{{isFacialMatched}}" />
            <input type="hidden" [(ngModel)]="pullDataStatus" value="{{pullDataStatus}}" />-->
		</div>
		<div class="card mb-0">
			<div class="card-body">
				<div *ngIf="facematch == 'yes'" class="mb-2">
					<label class="font-500 mb-0">Face Match Status : </label>
					<p *ngIf="isFacialMatched == 'true'" class="text-success font-600 d-inline ml-1">
						Matched
					</p>
					<p *ngIf="isFacialMatched == 'false'" class="text-danger font-600 d-inline ml-1">
						Mismatched
					</p>
				</div>
				<div *ngIf="faceMatchPic != ''" class="mb-2">
					<label class="font-500 mb-0">Selfie : </label>
					<a href="{{ imageUrl }}{{ faceMatchPic }}" target="_blank"> {{ faceMatchPicname }}</a>
				</div>
				<div class="table-responsive mb-4">
					<table class="table color-bordered-table info-bordered-table background-white" mdbTable
						#tableEl="mdbTable" small="true" hover="true">
						<thead>
							<tr>
								<th></th>
								<th class="text-center">Check To Verify</th>
								<th class="text-center">Expiry Date</th>
								<th class="text-center">Grade Verification</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td></td>
								<td class="text-center">
									<span><b>Select All</b></span>
									<div class="custom-control custom-checkbox">
										<input type="checkbox" id="masterchk" [(ngModel)]="allCheck"
											(change)="selectAllcheck($event)" />
									</div>
								</td>
								<td></td>
								<td></td>
							</tr>
							<tr *ngFor="let data of fields; let i = index">
								<td>
									<div
										*ngIf="data.keytype == 'text' || data.keytype == 'Text' || data.keytype == 'arabicfirstname' ||  data.keytype == 'arabiclastname' ||
                      data.keytype == 'string' ||  data.keytype == 'list' ||  data.keytype == 'number' ||  data.keytype == 'address' ||  data.keytype == 'phone' ||  data.keytype == 'mobile'">
										<label class="font-500 d-block">{{ data?.labelname }}</label>
										<input type="{{ data.keytype }}" class="form-control form-control-sm mb-1"
											value="{{ data.value ? data.value : 'No Value Entered' }}"
											placeholder="{{ data.key }}" readonly />

									</div>
									<!-- TextArea / Address -->
									<div *ngIf="data.keytype == 'textarea'">
										<label class="mb-1 font-500 d-block">{{ data?.labelname }}</label>
										<textarea class="form-control form-control-sm" rows="4"
											placeholder="{{ data.key }}" readonly>{{ data.value }}</textarea>
									</div>
									<!-- Radio/ Date/ Password/ Checkbox/ Select -->
									<div *ngIf="
                      data.keytype == 'date' ||
                      data.keytype == 'radio' ||
                      data.keytype == 'password' ||
                      data.keytype == 'checkbox' ||
                      data.keytype == 'select'
                    ">
										<label class="mb-1 font-500 d-block">{{ data?.labelname }}</label>
										<input type="text" class="form-control form-control-sm" value="{{ data.value }}"
											placeholder="{{ data.key }}" readonly />

										<!-- Section fields  -->
										<ng-container *ngIf="
                        data && data.optionsData && data.optionsData.length > 0
                      ">
											<ng-container *ngFor="let secVal of data.optionsData">

												<ng-container *ngIf="
                            secVal &&
                            secVal.moreFields &&
                            (secVal.checked || secVal.lable == data.value)
                          ">
													<div class="mb-2 px-2 py-1 background-light-grey"
														*ngFor="let s of secVal?.moreFields">
														<label class="mb-1 d-block">{{ s?.labelname }}</label>
														<input class="form-control form-control-sm mb-1"
															value="{{ s?.value }}" type="text"
															placeholder="{{ secVal.key }}" readonly />
														<ng-container *ngFor="let d of parseMoreData(s?.options)">
															<div class="form-check mb-1" *ngIf="
                                  s &&
                                  (s.keytype == 'radio' ||
                                    s.keytype == 'checkbox') &&
                                  (d?.checked || s?.value === d?.lable)
                                ">
																<input class="form-check-input" type="{{ s?.keytype }}"
																	[checked]="
                                    d?.checked || s?.value === d?.lable
                                  " name="box-name" placeholder="{{ d?.key }}" disabled />
																<label class="form-check-label">{{ d?.lable }}</label>
															</div>
															<div class="mb-1" *ngIf="
                                  s &&
                                  s.keytype != 'radio' &&
                                  s.keytype != 'checkbox'
                                ">
																<label class="mb-1">{{ s?.labelname }}</label>
																<input class="form-control form-control-sm"
																	value="{{ s?.value }}" type="text"
																	placeholder="{{ secVal.key }}" readonly />
															</div>
														</ng-container>
													</div>
												</ng-container>
											</ng-container>
										</ng-container>
									</div>
									<div *ngIf="data.sectionfields && data.sectionfields.length > 0">
										<div *ngFor="let secVal of data.sectionfields">
											<label class="mb-1 font-500">{{ secVal?.labelname }}</label>
											<input type="text" class="form-control form-control-sm"
												value="{{ secVal.value }}" placeholder="{{ secVal.key }}" readonly />
										</div>
									</div>
									<div *ngIf="data.keytype == 'File' || data.keytype == 'file'">
										<a class="mx-2 btn btn-sm waves-effect waves-light btn-info"
											href="{{ imageUrl }}{{ data?.path }}" target="_blank">Document {{ i }}</a>
										<button class="btn btn-sm waves-effect waves-light btn-info mx-2"
											*ngIf="pullDataStatus == 'yes'" (click)="fetchDataFromDoc(data)">
											<i class="fas fa-download" tooltip="Fetch data from Document"
												container="body"></i>
										</button>
									</div>
								</td>
								<td class="text-center">
									<div>

										<div class="custom-control custom-checkbox" *ngIf="data.labelname">
											<ng-container *ngIf="!data.isDocFetched">
												<input type="checkbox" name="chk" [checked]="data.checked"
													(change)="setVerifiedItem($event, i, data._id)"
													id="chk{{ i }}" />
											</ng-container>
										</div>
									</div>
								</td>
								<td class="text-center">
									<div class="d-flex align-items-center justify-content-center">
										<label class="mb-0 font-500">{{ data?.expiryDate | date : "dd-MM-yyyy"
											}}</label>
										<button type="button" class="btn btn-sm waves-effect waves-light btn-info ml-2"
											[disabled]="!data.isChecked || data.isverified"
											(click)="openExpiryDateModal(i)">
											Add
										</button>
									</div>
								</td>
								<td class="text-center">
									<div *ngIf="!data.isDocFetched">
										<!--<button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#viewgrade"
                                                (click)="viewGradeVer(data)" id="sub">
                                            View
                                        </button>-->
										<button type="button" class="btn btn-sm waves-effect waves-light btn-info"
											(click)="openGradeModal(data)" id="sub">
											View
										</button>
									</div>
								</td>
							</tr>
						</tbody>
						<tfoot class="grey lighten-5 w-100">
							<tr>
								<td colspan="4"></td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div>
					<div class="form-group row">
						<label class="col-12 col-md-4 col-form-label font-500">eForm Status</label>
						<div class="col-8 col-md-6">
							<select class="custom-select" [(ngModel)]="eformStatus">
								<option selected disabled>Select</option>
								<option value="pending">Pending</option>
								<option value="alive">Alive</option>
								<option value="accept">Accept</option>
								<option value="verify and accept">Verify and Accept</option>
								<!-- <option value="createclient">Create Client </option> -->
								<option value="update client field">
									Create/Update Client
								</option>
								<option value="update employee field">
									Create/Update Staff
								</option>
								<option value="return">Return</option>
								<option value="reject">Reject</option>
							</select>
						</div>
						<div class="col-4 col-md-2">
							<button type="button" class="btn waves-effect waves-light btn-info" (click)="onSubmit()">
								Submit
							</button>
						</div>
					</div>
					<div class="row" *ngIf="eformStatus">
						<label class="col-12 col-md-4 col-form-label mb-0 font-500">
							<span *ngIf="eformStatus == 'update client field'">Update Client:</span>
							<span class="text-capitalize" *ngIf="eformStatus != 'update client field'">{{ eformStatus
								}}:</span>
						</label>
						<p class="col-12 col-md-8 mb-0">
							{{ StatusDescription[eformStatus] }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer justify-content-between">
		<button type="button" class="btn waves-effect waves-light btn-success mx-2" id="exportbtnpdf"
			(click)="openMobilePdf()">
			View As PDF
		</button>
		<button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">
			Close
		</button>
	</div>
</div>