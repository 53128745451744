import { AfterViewInit, Component, OnInit } from '@angular/core';

import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
declare var $: any;



@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit {

    constructor(public sanitizer: DomSanitizer,
    ) {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        // ==============================================================
        // This is for the top header part and sidebar part
        // ==============================================================
        var set = function () {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 70;
            if (width < 1170) {
                $("body").addClass("mini-sidebar");
                $('.navbar-brand span').hide();
                $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
                //$(".sidebartoggler i").addClass("ti-menu");
            }
            else {
                $("body").removeClass("mini-sidebar");
                $('.navbar-brand span').show();
                //$(".sidebartoggler i").removeClass("ti-menu");
            }

            var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
            height = height - topOffset;
            if (height < 1) height = 1;
            if (height > topOffset) {
                $(".page-wrapper").css("min-height", (height) + "px");
            }

        };
        $(window).ready(set);
        $(window).on("resize", set);
        // ==============================================================
        // Theme options
        // ==============================================================
        $("#sidebarToggler").on('click', function () {
            if ($("body").hasClass("mini-sidebar")) {
                $("body").trigger("resize");
                $(".scroll-sidebar, .slimScrollDiv").css("overflow", "hidden").parent().css("overflow", "visible");
                $("body").removeClass("mini-sidebar");
                $('.navbar-brand span').show();
                //$(".sidebartoggler i").addClass("ti-menu");
            }
            else {
                $("body").trigger("resize");
                $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
                $("body").addClass("mini-sidebar");
                $('.navbar-brand span').hide();
                //$(".sidebartoggler i").removeClass("ti-menu");
            }
        });
        // topbar stickey on scroll

        $(".fix-header .topbar").stick_in_parent({});


        // this is for close icon when navigation open in mobile view
        $("#navToggler").click(function () {
            $("body").toggleClass("show-sidebar");
            $(".nav-toggler i").toggleClass("mdi mdi-menu");
            $(".nav-toggler i").addClass("mdi mdi-close");
        });
    }


}





