import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import { DataservicesService } from 'src/app/_services/dataservices.service';
declare var $: any;

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css']
})
export class PortalComponent implements OnInit {
  akcessId: any;
  adduserform: FormGroup;
  isSubmitted  =  false;
  act:string;
  constructor(private calldata : DataservicesService, 
    private spinner : NgxSpinnerService, 
    private toast : ToastrService, 
    private formBuilder: FormBuilder, 
    private route: ActivatedRoute, 
    private router: Router) { }

  ngOnInit(): void {
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.akcessId = params['akcessId'];
      });
    // alert(this.akcessId)
    // this.getuserData();
    this.spinner.hide();
    this.adduserform = this.formBuilder.group({
      _id:[null],
      firstName:["", Validators.compose([Validators.required])],
      lastName:["",Validators.compose([Validators.required])],
      email:["",Validators.compose([Validators.required, Validators.email])],
      phone:["",Validators.compose([Validators.required, Validators.pattern("[0-9]*")])],
      active_flg:[null],
      address:["",Validators.compose([Validators.required])]
    })
  }
  get formControls() { return this.adduserform.controls; }
  reg(){
    // alert('here')
  }
  adduser(){
    this.act = "add";
    this.isSubmitted = true;
    const tmpdate = moment().format();
    if(this.adduserform.invalid){
      // alert("invalid")
      return;
    }else{

      this.spinner.show();
      var formData = this.adduserform.value;
      formData.akcessId = this.akcessId;
      // alert(JSON.stringify(formData));
      // return;
      // alert(this.adduserform.value);
      // alert('dd')
      if(formData._id==null){
        // alert(JSON.stringify(formData))
        this.calldata.adduserportal(formData).subscribe(data => {
          // alert(data["status"])
          if(data['status']){
            // $('#addformclose').click();
            this.resetform();
            // this.getuserData();
            this.spinner.show();
          } else {
            this.toast.info(data['message']);
            this.spinner.hide();
            // $('#addformclose').click();
            this.resetform();
          }
         },
          error=>{ this.toast.error('something went wrong'); this.spinner.hide(); })
      }else{
        this.calldata.edituser(formData).subscribe(data =>{
          if(data['status']){
            // $('#addformclose').click();
            this.resetform();
            // this.getuserData();
            this.spinner.show();
          }else{
            this.toast.info(data['message']);
            this.spinner.hide();
            // $('#addformclose').click();
            this.resetform();
          }
        })
      }

      // this.calldata.adduser()
    }
  }
  resetform(){

  }
}
