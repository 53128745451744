<div class="container bg-faded py-3">
    <h1 class="text-center">{{ documentName | titlecase }}</h1>
    <div class="row">
    </div>
    <hr>
    <div class="row">
        <div class="col">
            <div class="mx-auto w-50 p-3 text-center">
                <qrcode [qrdata]="qrcodeData" [size]="240" [level]="'M'"></qrcode>
            </div>
        </div>
    </div>
</div>