<div class="companies-list-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Company Management</h3>
        </div>
    </div>
    <!-- Main Section -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-1 col-md-1 col-lg-1 col-xl-1">
                        <button
                            type="button"
                            class="mx-1 btn"
                            (click)="deleteRows()"
                            tooltip="Delete Multiple"
                            container="body"
                            [disabled]="!getCheckedItemList().length"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                    <div class="col-10 col-md-5 col-lg-4 col-xl-3 pl-md-0">
                        <div class="input-group">
                            <input
                                type="text"
                                [(ngModel)]="search_companies"
                                class="form-control"
                                id="search"
                                placeholder="Search Companies"
                            />
                            <!--<div class="input-group-append">
                                <div class="input-group-text" (click)="getCertificateList()">
                                    <i class="fas fa-search"></i>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <!-- tooltip="Register Your Company" -->
                    <div
                        class="col-12 col-md-6 col-lg-7 col-xl-8 text-right mt-1 mt-md-0"
                    >
                        <button
                            type="button"
                            class="btn btn-primary btn-info mr-2 send-invitation"
                            data-toggle="modal"
                            data-target="#addCompanyKYC"
                            (click)="resetForm()"
                            placement="bottom"
                            container="body"
                        >
                            <i class="fa fa-user-circle"></i> Send Invitation
                        </button>
                        <button
                            type="button"
                            class="btn waves-effect waves-light btn-info add-btn"
                            data-toggle="modal"
                            data-target="#addCompany"
                            (click)="resetForm()"
                            placement="bottom"
                            container="body"
                        >
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
                <div class="table-responsive mb-1">
                    <table
                        class="table color-bordered-table info-bordered-table"
                        mdbTable
                        #tableEl="mdbTable"
                        hover="true"
                        small="true"
                    >
                        <thead>
                            <tr>
                                <th>
                                    <div class="form-check">
                                        <input
                                            class="datatable-row-checkbox form-check-input"
                                            type="checkbox"
                                            (change)="checkUncheckAll()"
                                            [(ngModel)]="isMasterSel"
                                        />
                                    </div>
                                </th>
                                <th [mdbTableSort]="listCompany" sortBy="name">
                                    Name
                                    <span
                                        ><mdb-icon icon="sort" class="fas"
                                            ><i
                                                class="fa-sort fas"
                                            ></i></mdb-icon
                                    ></span>
                                </th>
                                <th
                                    [mdbTableSort]="listCompany"
                                    sortBy="domain"
                                >
                                    Domain
                                    <span
                                        ><mdb-icon icon="sort" class="fas"
                                            ><i
                                                class="fa-sort fas"
                                            ></i></mdb-icon
                                    ></span>
                                </th>
                                <th
                                    [mdbTableSort]="listCompany"
                                    sortBy="domain"
                                >
                                    Created On
                                    <span
                                        ><mdb-icon icon="sort" class="fas"
                                            ><i
                                                class="fa-sort fas"
                                            ></i></mdb-icon
                                    ></span>
                                </th>
                                <!--<th>
                                    Api Key <span><i class="fas fa-arrows-alt-v"></i></span>
                                </th>-->
                                <th
                                    [mdbTableSort]="listCompany"
                                    sortBy="status"
                                >
                                    Status
                                    <span
                                        ><mdb-icon icon="sort" class="fas"
                                            ><i
                                                class="fa-sort fas"
                                            ></i></mdb-icon
                                    ></span>
                                </th>
                                <th class="text-nowrap text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listCompany?.length == 0">
                            <tr>
                                <td colspan="6" class="text-center">
                                    No Companies Found
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="listCompany?.length > 0">
                            <tr
                                [id]="data?._id"
                                *ngFor="
                                    let data of listCompany
                                        | paginate
                                            : {
                                                  itemsPerPage: pageSize,
                                                  currentPage: currentPage,
                                                  totalItems: totalCount
                                              }
                                        | filter : search_companies;
                                    let i = index
                                "
                            >
                                <td>
                                    <div
                                        class="form-check"
                                        *ngIf="
                                            portalUser.akcessId ==
                                            data.logInUserAkcessId
                                        "
                                    >
                                        <input
                                            class="datatable-row-checkbox form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="data.isSelected"
                                            [attr.id]="data?._id"
                                            value="data?._id"
                                            (change)="isAllSelected()"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <a
                                        class="d-inline-block"
                                        (click)="view(data)"
                                        data-toggle="modal"
                                        data-target="#viewCompanyForm"
                                    >
                                        {{ data?.name }}
                                    </a>
                                </td>
                                <td>
                                    <a
                                        class="d-inline-block"
                                        (click)="view(data)"
                                        data-toggle="modal"
                                        data-target="#viewCompanyForm"
                                    >
                                        {{ data?.domain }}
                                    </a>
                                </td>
                                <td>
                                    <a
                                        class="d-inline-block"
                                        (click)="view(data)"
                                        data-toggle="modal"
                                        data-target="#viewCompanyForm"
                                    >
                                        {{
                                            data?.createdAt
                                                | date : "mediumDate"
                                        }}
                                    </a>
                                </td>
                                <!--<td>
                                    <a class="d-inline-block"
                                       (click)="view(data)"
                                       data-toggle="modal"
                                       data-target="#viewCompanyForm">
                                        {{ data?.apikey || "-" }}
                                    </a>
                                </td>-->
                                <td
                                    class="paint-{{
                                        data.status === 'active'
                                            ? 'green'
                                            : 'red'
                                    }}"
                                >
                                    <a
                                        class="d-inline-block"
                                        (click)="view(data)"
                                        data-toggle="modal"
                                        data-target="#viewCompanyForm"
                                    >
                                        {{ data.status }}
                                    </a>
                                </td>
                                <td>
                                    <!--<button type="button" class="mx-2 btn btn-action-icon" data-toggle="modal" data-target="#viewCompanyForm"
                                            (click)="view(data)" tooltip="View" container="body" placement="bottom">
                                        <i class="fa fa-eye"></i>
                                    </button>-->
                                    <!--<button type="button" class="mx-2 btn btn-action-icon" data-toggle="modal" data-target="#updateForm"
                                            (click)="view(data)" tooltip="View" container="body" placement="bottom">
                                        <i class="far fa-edit"></i>
                                    </button>-->

                                    <!-- <span tooltip="Please connect with AKcess Admin to activate your company">
                                        <button *ngIf="data.status === 'inactive'" type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info" disabled>View Admin List</button>
                                    </span> -->
                                    <span>
                                        <button
                                            *ngIf="data.status === 'inactive'"
                                            type="button"
                                            class="mx-2 btn btn-sm waves-effect waves-light btn-danger"
                                            (click)="resendActivation(data)"
                                        >
                                            Resend Activation Request
                                        </button>
                                    </span>

                                    <button
                                        *ngIf="data.status === 'active'"
                                        type="button"
                                        class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                        (click)="
                                            navigateTo(
                                                data,
                                                '/panel/company-users'
                                            )
                                        "
                                    >
                                        View Admin List
                                    </button>
                                    <button
                                        *ngIf="
                                            portalUser.akcessId ==
                                            data.logInUserAkcessId
                                        "
                                        (click)="deleteSingleRows(data._id)"
                                        title="Remove Company"
                                        class="btn btn-link delete_btn"
                                    >
                                        <i
                                            class="fa fa-trash text-danger"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="5">
                                    <div
                                        class="table-page"
                                        *ngIf="listCompany?.length > 10"
                                    >
                                        <div class="page-controls">
                                            <pagination-controls
                                                (pageChange)="
                                                    onProjectPageChange($event)
                                                "
                                                class="my-pagination"
                                                directionLinks="true"
                                                previousLabel="Previous"
                                                nextLabel="Next"
                                            ></pagination-controls>
                                        </div>
                                        <div class="page-size">
                                            <label class="mb-0 mr-2"
                                                >Item per page</label
                                            >
                                            <select
                                                #val
                                                (change)="changePage(val.value)"
                                                class="form-control form-control-sm"
                                                id="pageSelector"
                                            >
                                                <option
                                                    *ngFor="
                                                        let pG of numberOfPage
                                                    "
                                                    value="{{ pG }}"
                                                >
                                                    {{ pG }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modals -->
<!-- View Company Modal -->
<div
    id="viewCompanyForm"
    class="modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="viewCompanyFormModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewCompanyFormModalLabel">
                    View {{ selectedCompany.name }} Detail
                </h4>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                >
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="font-500">Domain Name</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="selectedCompany.domain"
                        readonly
                    />
                </div>
                <div class="form-group">
                    <label class="font-500">Api Key</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="selectedCompany.apikey"
                        readonly
                    />
                </div>
                <div class="form-group">
                    <label class="font-500">Address</label>
                    <textarea
                        class="form-control"
                        [(ngModel)]="selectedCompany.address"
                        rows="2"
                        readonly
                    ></textarea>
                </div>
                <div class="form-group">
                    <label class="font-500">Country</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="selectedCompany.country"
                        readonly
                    />
                </div>
                <div class="form-group">
                    <label class="font-500">Status</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="selectedCompany.status"
                        readonly
                    />
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn waves-effect waves-light btn-info"
                    (click)="view(selectedCompany)"
                    data-toggle="modal"
                    data-target="#updateCompanyForm"
                    id="close"
                    data-dismiss="modal"
                >
                    Edit
                </button>
                <button
                    type="button"
                    class="btn waves-effect waves-light btn-danger"
                    id="close"
                    data-dismiss="modal"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Update Company Modal -->
<div
    class="modal"
    id="updateCompanyForm"
    tabindex="-1"
    role="dialog"
    aria-labelledby="updateCompanyFormModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="updateCompanyForm" (ngSubmit)="updateCompany()">
                <div class="modal-header">
                    <h4 class="modal-title" id="updateCompanyFormModalLabel">
                        Update {{ selectedCompany.name }} Detail
                    </h4>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                    >
                        &times;
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-500"
                            >Company Name<span class="required-label"
                                >*</span
                            ></label
                        >
                        <input
                            type="text"
                            class="form-control"
                            formControlName="name"
                            [(ngModel)]="selectedCompany.name"
                        />
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('name').hasError('required')
                            "
                        >
                            Company Name required
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500"
                            >Address<span class="required-label">*</span></label
                        >
                        <textarea
                            class="form-control"
                            formControlName="address"
                            [(ngModel)]="selectedCompany.address"
                            rows="2"
                        ></textarea>
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('address').hasError('required')
                            "
                        >
                            Address is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500"
                            >Country<span class="required-label">*</span></label
                        >
                        <select
                            class="form-control custom-select"
                            formControlName="country"
                            [(ngModel)]="selectedCompany.country"
                        >
                            <option
                                *ngFor="
                                    let country of filter_country_options
                                        | slice : 1
                                "
                                value="{{ country.value }}"
                            >
                                {{ country.label }}
                            </option>
                        </select>
                        <!--<div class="invalid-feedback" *ngIf="submitted && addForm.get('address').hasError('required')">
                            Address is required
                        </div>-->
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="submit"
                        class="btn waves-effect waves-light btn-info"
                        id="updateFormBtn"
                    >
                        Update
                    </button>
                    <button
                        type="button"
                        class="btn waves-effect waves-light btn-danger"
                        id="close"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Register/Add Company Modal -->
<div
    class="modal"
    id="addCompany"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addCompanyModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="addForm" (ngSubmit)="onCompanySubmit()">
                <div class="modal-header">
                    <h4 class="modal-title" id="addCompanyModalLabel">
                        Register Your Company
                    </h4>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-hidden="true"
                        (click)="resetForm()"
                    >
                        &times;
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-500"
                            >Upload Company Logo<span class="required-label"
                                >*</span
                            ></label
                        >
                        <div class="custom-file">
                            <input
                                type="file"
                                class="custom-file-input"
                                accept="image/png, image/jpeg"
                                (change)="onFileChange($event.target.files)"
                            />
                            <label
                                class="custom-file-label"
                                for="importFile"
                                *ngIf="!isFileChosen"
                                >Choose Document</label
                            >
                            <label
                                class="custom-file-label"
                                for="importFile"
                                *ngIf="isFileChosen"
                                >{{ fileName }}</label
                            >
                        </div>
                        <div class="invalid-feedback" *ngIf="isFileUploaded">
                            You must select an company logo image.
                        </div>
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('companyLogo').hasError('required')
                            "
                        >
                            Company logo image required
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500"
                            >Company Name<span class="required-label"
                                >*</span
                            ></label
                        >
                        <input
                            type="text"
                            class="form-control"
                            formControlName="name"
                            placeholder="Company Name"
                            required
                        />
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('name').hasError('required')
                            "
                        >
                            Company Name required
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500"
                            >Domain Name<span class="required-label"
                                >*</span
                            ></label
                        >
                        <input
                            type="text"
                            class="form-control"
                            formControlName="domain"
                            placeholder="Domain Name"
                            required
                        />
                        <div class="valid-feedback">
                            Domain should not have http: or https
                        </div>
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('domain').hasError('required')
                            "
                        >
                            Domain Name is required
                        </div>
                        <div
                            class="invalid-feedback"
                            *ngIf="addForm.get('domain').hasError('pattern')"
                        >
                            Domain name should be written in proper pattern
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500"
                            >Email<span class="required-label">*</span></label
                        >
                        <input
                            type="email"
                            class="form-control"
                            formControlName="email"
                            placeholder="Company Email"
                            required
                        />
                        <div class="valid-feedback">
                            Notification will be sent to this email
                        </div>
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('email').hasError('required')
                            "
                        >
                            Email is required
                        </div>
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('email').hasError('email')
                            "
                        >
                            Email is invalid
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500"
                            >Address<span class="required-label">*</span></label
                        >
                        <textarea
                            class="form-control"
                            formControlName="address"
                            rows="2"
                            placeholder="Company Address"
                            required
                        ></textarea>
                        <div
                            class="invalid-feedback"
                            *ngIf="
                                submitted &&
                                addForm.get('address').hasError('required')
                            "
                        >
                            Address is required
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500"
                            >Select Country<span class="required-label"
                                >*</span
                            ></label
                        >
                        <select
                            class="form-control custom-select"
                            formControlName="country"
                        >
                            <option
                                *ngFor="
                                    let country of filter_country_options
                                        | slice : 1
                                "
                                value="{{ country.value }}"
                            >
                                {{ country.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="submit"
                        class="btn waves-effect waves-light btn-info"
                    >
                        Register
                    </button>
                    <button
                        type="button"
                        class="btn waves-effect waves-light btn-danger"
                        id="addCompanyclose"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div
    class="modal"
    id="addCompanyKYC"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addCompanyKYCModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="addCompanyKYCModalLabel">
                    Send Invitation
                </h4>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row justify-content-around pb-2">
                    <div class="custom-radio">
                        <input
                            type="radio"
                            [(ngModel)]="phone_or_email"
                            value="email"
                            id="email"
                            class="with-gap radio-col-light-blue"
                        />
                        <label for="email" class="font-500">Email</label>
                    </div>
                    <div class="custom-radio">
                        <input
                            type="radio"
                            [(ngModel)]="phone_or_email"
                            value="phone"
                            id="phone"
                            class="with-gap radio-col-light-blue"
                        />
                        <label for="phone" class="font-500">Mobile</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" *ngIf="phone_or_email == 'email'">
                        <div class="input-group form-group">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Type Email Address.."
                                (keyup.enter)="addEmail($event)"
                            />
                            <div class="valid-feedback">
                                Press Enter to add Email.
                            </div>
                        </div>
                        <div class="chiplist">
                            <div
                                *ngFor="let chip of emailList; let i = index"
                                class="chips"
                            >
                                {{ chip }}
                                <span
                                    aria-hidden="true"
                                    (click)="removeData(i, 'email')"
                                    >&times;</span
                                >
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-12"
                        *ngIf="phone_or_email == 'phone'"
                        [formGroup]="phoneFG"
                    >
                        <ngx-intl-tel-input
                            [cssClass]="'form-control w-100'"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.UnitedKingdom"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            formControlName="phone"
                            [separateDialCode]="true"
                            (keyup.enter)="addPhone()"
                        >
                        </ngx-intl-tel-input>
                        <div class="valid-feedback">
                            Press Enter to add Phone Number.
                        </div>
                        <div class="chiplist">
                            <div
                                *ngFor="let chip of phoneList; let i = index"
                                class="chips"
                            >
                                {{ chip.countryCode }} {{ chip.phone }}
                                <span
                                    aria-hidden="true"
                                    (click)="removeData(i, 'phone')"
                                    >&times;</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button
                        type="submit"
                        class="btn waves-effect waves-light btn-info"
                        (click)="sendInvite()"
                    >
                        send
                    </button>
                    <button
                        type="button"
                        class="btn waves-effect waves-light btn-danger"
                        id="addCompanyKYCclose"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-confirm-dialog></app-confirm-dialog>
