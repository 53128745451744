import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-usecase8',
    templateUrl: './usecase8.component.html',
    styleUrls: ['./usecase8.component.css']
})
export class Usecase8Component implements OnInit {
    portalUser;
    httpOptions: any;
    httpMultipartOptions: any;
    akcesstoken: any;
    apikey: any;
    body: any;
    getAccessTokenBody: any;
    domainUrl: any;
    getAccessTokenFlag: boolean = false;

    EFormResponses;
    constructor(
        private http: HttpClient,
        private toast: ToastrService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit(): void { }

    getauth() {
        if (
            this.apikey &&
            this.apikey.length > 0 &&
            this.akcesstoken &&
            this.akcesstoken.length > 0
        ) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    api: this.apikey,
                    AkcessToken: this.akcesstoken,
                }),
            };

            this.httpMultipartOptions = {
                headers: new HttpHeaders({
                    Accept: "*/*",
                }),
                responseType: "text",
            };
            return true;
        } else {
            this.toast.error("apikey/akcesstoken missing");
            return false;
        }
    }
    GetEFormResponses(path, body) {
        if (this.getauth()) {
            this.spinner.show();
            this.http
                .post<any>(environment.url + path, body, this.httpOptions)
                .subscribe(
                    (data) => {
                        if (data["status"] === 1) {
                            this.EFormResponses = JSON.stringify(data);
                            this.spinner.hide();
                        } else {
                            this.toast.info(data["message"]);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
        }
    }

    GetAccessToken(apikey, domainUrl) {
        this.spinner.show();
        this.http
            .post<any>(
                "https://ak-api.akcess.dev/api/v1/getTokenForThirdParty",
                { api: apikey, domainUrl: domainUrl },
                {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                    }),
                }
            )
            .subscribe(
                (data) => {
                    if (data["status"] === 1) {
                        // let temp_token = JSON.stringify(data);
                        if (data && data["token"]) this.akcesstoken = data["token"];
                        this.spinner.hide();
                    } else {
                        this.toast.info(data["message"]);
                        this.spinner.hide();
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
    }
    clearHistory(val) {
        this.getAccessTokenFlag = val;
        this.body = "";
        this.EFormResponses = "";
    }
}
