import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from '../../../../_services/dataservices.service';
import { RefreshContentService } from '../../../../_services/refresh-content.service';

@Component({
    selector: 'app-certificate-deleted',
    templateUrl: './certificate-deleted.component.html',
    styleUrls: ['./certificate-deleted.component.css']
})
export class CertificateDeletedComponent implements OnInit {
    certificateDeletedList: any = [];
    //Pagination
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;

    constructor(
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        public refreshContentService: RefreshContentService,
    ) { }

    ngOnInit(): void {
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                // this.getuserData(true);
                this.getDeletedCertificateList();

            } else {
                // this.getuserData(true);
                this.getDeletedCertificateList();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
    }

    getDeletedCertificateList() {
        this.spinner.show();
        this.calldata.getDeletedList('certificate', '').subscribe(
            (res: any) => {
                if (res.status) {
                    this.certificateDeletedList = res.data.reverse();
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //Pagination
    onProjectPageChange(event) {
        this.currentPage = event;
        // this.getuserData(true);
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        // this.getuserData(true);
    }

    //Delete Permenatly
    deletePermantly() {
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));

        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data = {
                    component: "certificate",
                    _id: id
                };

                this.calldata.forceDeleteData(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getDeletedCertificateList();
                            this.toast.success("Certificate " + res.message);
                        } else {
                            this.toast.info("Certificate " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select a certificate");
            this.spinner.hide();
        }


    }

    //Restore Deleted
    restoreDeleted() {
        let restoreIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => restoreIDs.push(item.getAttribute("id")));

        if (restoreIDs.length > 0) {
            for (let id of restoreIDs) {
                const data = {
                    component: "certificate",
                    _id: id
                };

                this.calldata.restoreDeletedData(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getDeletedCertificateList();
                            this.toast.success("Certificate " + res.message);
                        } else {
                            this.toast.info("Certificate " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select a certificate");
            this.spinner.hide();
        }


    }

}
