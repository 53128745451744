import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { environment } from "src/environments/environment";
import { RefreshContentService } from "src/app/_services/refresh-content.service";

@Component({
    selector: "app-id-cards",
    templateUrl: "./id-cards.component.html",
    styleUrls: ["./id-cards.component.css"],
})
export class IdCardsComponent implements OnInit {
    portalUser: any;
    search_idcard: any = "";
    currentPage: number = 1;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: number = 12;
    idcarddata: any[] = [];
    totalCount: number = 0;
    sort: any = "";
    storage_url: string;
    constructor(
        private router: Router,
        private calldata: DataservicesService,
        private general: GeneralServiceService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        public refreshContentService: RefreshContentService
    ) {}

    ngOnInit(): void {
        this.storage_url = this.calldata.getStorageUrl();
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                this.getidcardData();
            } else {
                this.getidcardData();
            }
        });
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/idcard/${file}`;
    }

    getidcardData() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            search: this.search_idcard,
            currentPage: this.currentPage,
            limit: this.pageSize,
            sortby: this.sort,
        };
        this.calldata.geteidcarddata(tmpdata).subscribe(
            (data) => {
                if (data["status"] == 1) {
                    this.idcarddata = data["data"].reverse();
                    this.totalCount = this.idcarddata.length;
                    this.spinner.hide();
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            },
            (error) => {
                this.toast.error("something went wrong");
                this.spinner.hide();
            }
        );
    }

    searchIdCards(searchValue: string) {
        this.currentPage = 1;
        this.search_idcard = searchValue;
        this.getidcardData();
    }

    viewidcards(val) {
        this.router.navigate([
            "/panel/viewidcards",
            JSON.stringify({ data: val }),
        ]);
    }

    viewRecycle() {}
    createidcard() {
        var val = {
            firstName: "",
            lastName: "",
            userID: "",
            userDOB: "",
            IDExpDate: "",
        };
        this.router.navigate([
            "/panel/viewidcards",
            JSON.stringify({ data: val }),
        ]);
    }
    onProjectPageChange(event) {
        this.currentPage = event;
        this.getidcardData();
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        this.getidcardData();
    }

    deleteIdCard(idCard) {
        if (confirm("Are you sure want to delete this Id Card?")) {
            this.spinner.show();
            let data = {
                id: idCard._id,
            };
            this.general.deleteIdCard(data).subscribe(
                (res: any) => {
                    if (res.status) {
                        this.spinner.hide();
                        this.toast.success(res.message);
                    } else {
                        this.spinner.hide();
                        this.toast.error("Something went wrong");
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        }
    }

    deleteRow() {}
}
