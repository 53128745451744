import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { retry } from 'rxjs/operators';
import { User } from "../models/user";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: "root",
})
export class GeneralServiceService {
    httpOptions: any;
    httpOptions1: any;
    httpNewOptions: any;
    httpMultipartOptions: any;
    httpOptionsForParticularCompany: any;
    httpMultipartOptionsForParticularCompany: any;
    cbkToken: any = sessionStorage.getItem("ak-cbk-token");
    constructor(
        private http: HttpClient,
        public commonService: CommonService
    ) { }

    getAuth() {
        this.httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                api: environment.apiKey,
                AkcessToken: this.cbkToken,

                //'x-access-token' : this.token
            }),
        };
    }

    getAuthv2() {
        this.httpOptions1 = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                // Origin: 'localhost:4200',
                apikey: environment.apiKey,
                authorization: this.cbkToken,
                //'x-access-token' : this.token
            }),
        };
    }
    getAuthHeader3() {
        this.httpOptions1 = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                api: environment.apiKey,
                AkcessToken: this.cbkToken,
                logedInUserAckessId: sessionStorage.getItem(
                    "logedInUserAckessId"
                ),
                //'x-access-token' : this.token
            }),
        };
    }
    getAuthHeader4() {
        this.httpOptions1 = {
            headers: new HttpHeaders({
                // "Content-Type": "application/json",
                api: environment.apiKey,
                AkcessToken: this.cbkToken,
                logedInUserAckessId: sessionStorage.getItem(
                    "logedInUserAckessId"
                ),
                //'x-access-token' : this.token
            }),
        };
    }
    getauthForParticularCompany() {
        this.httpOptionsForParticularCompany = {
            headers: new HttpHeaders({
                // 'Content-Type': 'application/json',
                api: environment.apiKey,
                AkcessToken: this.cbkToken,
                logedInUserAckessId: sessionStorage.getItem(
                    "logedInUserAckessId"
                ),
                db_name: this.commonService.selectedCompanyDeatils.dbName,
                //'x-access-token' : this.token
            }),
        };
        this.httpMultipartOptionsForParticularCompany = {
            headers: new HttpHeaders({
                Accept: "*/*",
            }),
            responseType: "text",
        };
    }
    getmulti() {
        this.httpMultipartOptions = {
            headers: new HttpHeaders({
                Accept: "*/*",
            }),
            responseType: "text",
        };
    }
    addUser(user: User): Observable<User> {
        return this.http.post<User>(environment.url + "api/authenticate", user);
    }

    getQrData() {
        var httpOptions1 = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                // Origin: 'localhost:4200',
                api: environment.apiKey,
                akcesstoken: sessionStorage.getItem("ak-cbk-token"),
                //'x-access-token' : this.token
            }),
        };
        // const data = {
        //     api: environment.apiKey,
        //     akcesstoken: sessionStorage.getItem("ak-cbk-token"),
        // };

        // return this.http.post<any>(
        //     environment.urllogin + "api/v1/generateQrCode",
        //     data
        // );
        return this.http.post<any>(
            environment.url + "generateQrCode",
            {},
            httpOptions1
        );
        // return this.http.post<any>(environment.urlloginV2 +"auth/generate-uuid",{},httpOptions1);
    }

    // getPortalData() {
    //     const data = {
    //         api: environment.apiKey,
    //         AKcessToken: sessionStorage.getItem("ak-cbk-token"),
    //     };
    //     return this.http.post<any>(
    //         environment.urllogin + "api/v1/getPortalData",
    //         data
    //     );
    // }

    getPortalData() {
        this.getAuthv2();
        var httpOptionsv2 = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                apikey: environment.apiKey,
                authorization: this.cbkToken,
             }),
        };
        return this.http.get<any>(
            `${environment.urlloginV2}domain/profile`,
            httpOptionsv2
        );
    }
    getDataByAkcessId(aid) {
        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            akcessId: aid.aid,
            UserHost: "AKcess-dev",
        };
        // return this.http.post<any>(environment.urllogin + 'api/v1/getDataFromAKcessId', data);
        return this.http.post<any>(
            environment.urlloginV2 + "users/akcessId",
            data
        );
    }
    getToken() {
        var httpOptionsForToken = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                api: environment.apiKey,
                //   'origin':environment.domainName
            }),
        };
        // const data = { api: environment.apiKey };
        // return this.http.post<any>(environment.urllogin + "api/v1/getToken", data);
        return this.http.post<any>(
            environment.url + "auth/generate-token",
            {},
            httpOptionsForToken
        );
    }
    loginstatus(uuid) {
        const data = {
            uuid: uuid,
        };
        var httpOptions1 = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                // Origin: 'localhost:4200',
                api: environment.apiKey,
                akcesstoken: sessionStorage.getItem("ak-cbk-token"),
                //'x-access-token' : this.token
            }),
        };
        return this.http.post<any>(
            environment.url + "loginUser",
            data,
            httpOptions1
        );

        // return this.http.post<any>(
        //     environment.urllogin + "api/v1/scanLoginStatus",
        //     data
        // );
    }

    portalLoginStatus(data) {
        return this.http.post<any>(environment.url + "portalLoginStatus", data);
    }

    partyLogin(data) {
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        return this.http.post<any>(
            environment.urllogin + "api/v1/loginUser",
            data
        );
    }

    getCounts(data) {
        this.getAuth();
        return this.http.post<any>(
            environment.url + "getcounts",
            data,
            this.httpOptions
        );
    }

    addUsertoPortal(data) {
        return this.http.post<any>(environment.url + "adduser", data);
    }

    getglobalprofile(cdata) {
        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            country: cdata,
        };
        return this.http.post<any>(
            environment.akcessURL + "v5/getGlobalprofileField",
            data
        );
    }

    //pusheformconfig(eformdata) {
    //    const data = {
    //        api: environment.apiKey,
    //        AKcessToken: sessionStorage.getItem('ak-cbk-token'),
    //        eformconfig: eformdata,
    //        portalDomainName: sessionStorage.getItem('portalDomainName')
    //    };
    //    this.getAuth();
    //    // return this.http.post<any>(environment.urllogin + 'api/v1/pusheformtouser', data)
    //    return this.http.post<any>(environment.url + 'pusheformtouser', data, this.httpOptions)
    //}

    // fetcheformsents(data){
    //   data.api = environment.apiKey;
    //   data.AKcessToken = sessionStorage.getItem('ak-cbk-token');

    //   // this.getauth();
    //   return this.http.post<any>(environment.urllogin + "api/v1/getSentEforms",data)
    // }
    //fetcheformreceived(data) {
    //    data.api = environment.apiKey;
    //    data.AKcessToken = sessionStorage.getItem('ak-cbk-token');
    //    data.portalDomainName = sessionStorage.getItem('portalDomainName')
    //    this.getAuth();
    //    // return this.http.post<any>(environment.urllogin + "api/v1/getReceivedEforms",data)
    //    return this.http.post<any>(environment.url + "getReceivedEforms", data, this.httpOptions)
    //}

    sendinvitation(data) {
        this.getAuth();
        return this.http.post<any>(
            environment.url + "sendinvitation",
            data,
            this.httpOptions
        );
    }
    saveAccountData(data) {
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        return this.http.post<any>(
            environment.urllogin + "api/v1/saveaccountdata",
            data
        );
    }
    sendinvitationUser(data) {
        // this.getAuth();
        this.getAuthHeader3();
        return this.http.post<any>(
            environment.url + "companies/invite",
            data,
            this.httpOptions1
        );
    }
    checkinvitationUser(data) {
        this.getAuthHeader4();
        return this.http.post<any>(environment.url + `companies/invite/status`, '', this.httpOptions1).pipe(retry(2));
    }

    savePortalData(data) {
        // alert(JSON.stringify(data))
        this.getmulti();
        // alert(JSON.stringify(data))
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        // alert(JSON.stringify(data))
        return this.http.post<any>(
            environment.urllogin + "api/v1/save_portal_details",
            data,
            this.httpMultipartOptions
        );
    }

    updateStatusPriorScan(data) {
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        return this.http.post<any>(
            environment.url + "updateStatusPriorScan",
            data
        );
    }

    saveIdCardData(data) {
        this.getauthForParticularCompany();
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        // return this.http.post<any>(environment.url + 'idcard/create', data);
        const formData = new FormData();
        formData.append("profile_pic", data.file);
        formData.append("firstName", data.firstName);
        formData.append("lastName", data.lastName);
        formData.append("idNo", data.akcessId);
        formData.append("DOB", data.DOB);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("expiryDate", data.idCardExpiyDate);
        formData.append("domainName", data.domainName);
        formData.append("employee_id", data.empliyee_id);

        //    return this.http.post(environment.url + `idcard/create`, formData)
        return this.http.post(
            environment.url + `upload/idCard`,
            formData,
            this.httpOptionsForParticularCompany
        );
    }
    saveCertificateData(data) {
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        data.cert_num = data.cert_num.toString();
        var formdata = new FormData();
        formdata.append("owner_name", data.owner_name);
        formdata.append("certificate_name", data.certificate_name);
        formdata.append("director_name", data.director_name);
        formdata.append("manager_name", data.manager_name);
        formdata.append("cert_num", data.cert_num);
        formdata.append("expiry_date", data.certExpDate);
        formdata.append("domain_name", data.domain_name);
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `certificates`,
            formdata,
            this.httpOptionsForParticularCompany
        );
    }

    sendIdCardByEmail(data) {
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        return this.http.post(environment.url + `push/document/email`, data);
    }

    sendIdCardByPhone(data) {
        data.api = environment.apiKey;
        data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
        return this.http.post(environment.url + `push/document/phone`, data);
    }
    //idcard
    sendIdcardByAkcessId(data) {
        this.getauthForParticularCompany();
        // data.api = environment.apiKey;
        // data.AKcessToken = sessionStorage.getItem('ak-cbk-token');
        return this.http.post(
            environment.url + `idcard/sendpdfbyakcessId`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendIdcardByphone(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `idcard/sendpdfbyphone`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendIdcardByemail(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `idcard/sendpdfbyemail`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    //Document
    sendDocumentByAkcessId(data) {
        this.getauthForParticularCompany();
        // data.api = environment.apiKey;
        // data.AKcessToken = sessionStorage.getItem('ak-cbk-token');
        return this.http.post(
            environment.url + `document/sendpdfbyakcessId`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendDocumentByphone(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `document/sendpdfbyphone`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendDocumentByemail(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `document/sendpdfbyemail`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    //Certificate
    sendCertificateByphone(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `certificate/sendpdfbyphone`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendCertificateByemail(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `certificate/sendpdfbyemail`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendCertificateByAkcessId(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `certificate/sendpdfbyakcessId`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getSentIdCards(userId) {
        return this.http.get(
            environment.url + `push/document/email/userId?userId=${userId}`
        );
    }

    addUserAsAdmin(request) {
        return this.http.post(environment.url + `add-admin`, request);
    }

    deleteCompanyAdmin(data) {
        return this.http.put(environment.url + "deleteadmin", data);
    }

    getViewSendIdCard(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `idcard/viewsend`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getViewSendCertificate(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `certificate/viewsend`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getViewSendDocument(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `document/viewsend`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    //Save Custom Fields
    saveCustomField(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + `custom-field`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    //Get Custom Fields
    getCustomField(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + 'get-custom-fields', data,
            this.httpOptionsForParticularCompany
        );
    }

    //Get Dashboard Count
    getDashboardCount() {
        this.getauthForParticularCompany();
        return this.http.get(
            environment.url + `dashboard/company`,
            this.httpOptionsForParticularCompany
        );
    }

    // upload document employee vise
    uploadEmployeeDoc(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + "createuserdoc",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    // send invitation to employee
    sendInvitationToUser(data) {
        this.getauthForParticularCompany();
        return this.http.post(environment.url + 'sendinvitationtouser', data, this.httpOptionsForParticularCompany)
    }

    deleteEmployeeDocument(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + "deleteEmpDocument",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getIdDetailById(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + "empIdCard",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    deleteIdCard(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + "deleteEmpCard",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getDataById(aid) {
        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            akcessId: aid.aid,
            UserHost: "AKcess-dev",
        };
        return this.http.post<any>(
            environment.urllogin + "api/v1/getDataFromAKcessId",
            data
        );
    }

    generateDocumentLink(documenturl) {
        return this.http.post(environment.url + "generateDocumentLink", { documenturl: documenturl })
    }
}

// import { Injectable } from "@angular/core";
// import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { environment } from "../../environments/environment";
// import { Observable } from "rxjs";
// import { User } from "../models/user";
// import { CommonService } from "./common.service";

// @Injectable({
//     providedIn: "root",
// })
// export class GeneralServiceService {
//     httpOptions: any;
//     httpOptions1: any;
//     httpNewOptions: any;
//     httpMultipartOptions: any;
//     httpOptionsForParticularCompany: any;
//     httpMultipartOptionsForParticularCompany: any;
//     cbkToken: any = sessionStorage.getItem("ak-cbk-token");
//     constructor(private http: HttpClient, public commonService: CommonService) { }

//     getAuth() {
//         this.httpOptions = {
//             headers: new HttpHeaders({
//                 "Content-Type": "application/json",
//                 api: environment.apiKey,
//                 AkcessToken: this.cbkToken,

//                 //'x-access-token' : this.token
//             }),
//         };
//     }

//     getAuthv2() {
//         this.httpOptions1 = {
//             headers: new HttpHeaders({
//                 "Content-Type": "application/json",
//                 // Origin: 'localhost:4200',
//                 apikey: environment.apiKey,
//                 authorization: this.cbkToken,
//                 //'x-access-token' : this.token
//             }),
//         };
//     }
//     getAuthHeader3() {
//         this.httpOptions1 = {
//             headers: new HttpHeaders({
//                 "Content-Type": "application/json",
//                 api: environment.apiKey,
//                 AkcessToken: this.cbkToken,
//                 logedInUserAckessId: sessionStorage.getItem("logedInUserAckessId"),
//                 //'x-access-token' : this.token
//             }),
//         };
//     }
//     getAuthHeader4() {
//         this.httpOptions1 = {
//             headers: new HttpHeaders({
//                 // "Content-Type": "application/json",
//                 api: environment.apiKey,
//                 AkcessToken: this.cbkToken,
//                 logedInUserAckessId: sessionStorage.getItem("logedInUserAckessId"),
//                 //'x-access-token' : this.token
//             }),
//         };
//     }
//     getauthForParticularCompany() {
//         this.httpOptionsForParticularCompany = {
//             headers: new HttpHeaders({
//                 // 'Content-Type': 'application/json',
//                 api: environment.apiKey,
//                 AkcessToken: this.cbkToken,
//                 logedInUserAckessId: sessionStorage.getItem("logedInUserAckessId"),
//                 db_name: this.commonService.selectedCompanyDeatils.dbName,
//                 //'x-access-token' : this.token
//             }),
//         };
//         this.httpMultipartOptionsForParticularCompany = {
//             headers: new HttpHeaders({
//                 Accept: "*/*",
//             }),
//             responseType: "text",
//         };
//     }
//     getmulti() {
//         this.httpMultipartOptions = {
//             headers: new HttpHeaders({
//                 Accept: "*/*",
//             }),
//             responseType: "text",
//         };
//     }
//     addUser(user: User): Observable<User> {
//         return this.http.post<User>(environment.url + "api/authenticate", user);
//     }

//     getQrData() {
//         var httpOptions1 = {
//             headers: new HttpHeaders({
//                 "Content-Type": "application/json",
//                 // Origin: 'localhost:4200',
//                 api: environment.apiKey,
//                 akcesstoken: sessionStorage.getItem("ak-cbk-token"),
//                 //'x-access-token' : this.token
//             })
//         }
//         // const data = {
//         //     api: environment.apiKey,
//         //     akcesstoken: sessionStorage.getItem("ak-cbk-token"),
//         // };

//         // return this.http.post<any>(
//         //     environment.urllogin + "api/v1/generateQrCode",
//         //     data
//         // );
//         return this.http.post<any>(environment.url + "generateQrCode", {}, httpOptions1);
//         // return this.http.post<any>(environment.urlloginV2 +"auth/generate-uuid",{},httpOptions1);
//     }

//     getPortalData() {
//         const data = {
//             api: environment.apiKey,
//             AKcessToken: sessionStorage.getItem("ak-cbk-token"),
//         };
//         return this.http.post<any>(
//             environment.urllogin + "api/v1/getPortalData",
//             data
//         );
//     }
//     getDataByAkcessId(aid) {
//         const data = {
//             api: environment.apiKey,
//             AKcessToken: sessionStorage.getItem("ak-cbk-token"),
//             akcessId: aid.aid,
//             UserHost: "AKcess-dev",
//         };
//         // return this.http.post<any>(environment.urllogin + 'api/v1/getDataFromAKcessId', data);
//         return this.http.post<any>(environment.urlloginV2 + "users/akcessId", data);
//     }

//     getDataById(aid) {
//         const data = {
//             api: environment.apiKey,
//             AKcessToken: sessionStorage.getItem("ak-cbk-token"),
//             akcessId: aid.aid,
//             UserHost: "AKcess-dev",
//         };
//         return this.http.post<any>(environment.urllogin + 'api/v1/getDataFromAKcessId', data);
//     }

//     getToken() {
//         var httpOptionsForToken = {
//             headers: new HttpHeaders({
//                 'Content-Type': 'application/json',
//                 'api': environment.apiKey,
//                 //   'origin':environment.domainName
//             })
//         };
//         // const data = { api: environment.apiKey };
//         // return this.http.post<any>(environment.urllogin + "api/v1/getToken", data);
//         return this.http.post<any>(environment.url + 'auth/generate-token', {}, httpOptionsForToken);
//     }
//     loginstatus(uuid) {
//         const data = {
//             uuid: uuid
//         };
//         var httpOptions1 = {
//             headers: new HttpHeaders({
//                 "Content-Type": "application/json",
//                 // Origin: 'localhost:4200',
//                 api: environment.apiKey,
//                 akcesstoken: sessionStorage.getItem("ak-cbk-token"),
//                 //'x-access-token' : this.token
//             })
//         }
//         return this.http.post<any>(environment.url + "loginUser", data, httpOptions1);

//         // return this.http.post<any>(
//         //     environment.urllogin + "api/v1/scanLoginStatus",
//         //     data
//         // );
//     }

//     portalLoginStatus(data) {
//         return this.http.post<any>(environment.url + "portalLoginStatus", data);
//     }

//     partyLogin(data) {
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         return this.http.post<any>(environment.urllogin + "api/v1/loginUser", data);
//     }

//     getCounts(data) {
//         this.getAuth();
//         return this.http.post<any>(
//             environment.url + "getcounts",
//             data,
//             this.httpOptions
//         );
//     }

//     addUsertoPortal(data) {
//         return this.http.post<any>(environment.url + "adduser", data);
//     }

//     getglobalprofile(cdata) {
//         const data = {
//             api: environment.apiKey,
//             AKcessToken: sessionStorage.getItem("ak-cbk-token"),
//             country: cdata,
//         };
//         return this.http.post<any>(
//             environment.akcessURL + "v5/getGlobalprofileField",
//             data
//         );
//     }

//     //pusheformconfig(eformdata) {
//     //    const data = {
//     //        api: environment.apiKey,
//     //        AKcessToken: sessionStorage.getItem('ak-cbk-token'),
//     //        eformconfig: eformdata,
//     //        portalDomainName: sessionStorage.getItem('portalDomainName')
//     //    };
//     //    this.getAuth();
//     //    // return this.http.post<any>(environment.urllogin + 'api/v1/pusheformtouser', data)
//     //    return this.http.post<any>(environment.url + 'pusheformtouser', data, this.httpOptions)
//     //}

//     // fetcheformsents(data){
//     //   data.api = environment.apiKey;
//     //   data.AKcessToken = sessionStorage.getItem('ak-cbk-token');

//     //   // this.getauth();
//     //   return this.http.post<any>(environment.urllogin + "api/v1/getSentEforms",data)
//     // }
//     //fetcheformreceived(data) {
//     //    data.api = environment.apiKey;
//     //    data.AKcessToken = sessionStorage.getItem('ak-cbk-token');
//     //    data.portalDomainName = sessionStorage.getItem('portalDomainName')
//     //    this.getAuth();
//     //    // return this.http.post<any>(environment.urllogin + "api/v1/getReceivedEforms",data)
//     //    return this.http.post<any>(environment.url + "getReceivedEforms", data, this.httpOptions)
//     //}

//     sendinvitation(data) {
//         this.getAuth();
//         return this.http.post<any>(
//             environment.url + "sendinvitation",
//             data,
//             this.httpOptions
//         );
//     }
//     saveAccountData(data) {
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         return this.http.post<any>(
//             environment.urllogin + "api/v1/saveaccountdata",
//             data
//         );
//     }
//     savePortalData(data) {
//         // alert(JSON.stringify(data))
//         this.getmulti();
//         // alert(JSON.stringify(data))
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         // alert(JSON.stringify(data))
//         return this.http.post<any>(
//             environment.urllogin + "api/v1/save_portal_details",
//             data,
//             this.httpMultipartOptions
//         );
//     }

//     updateStatusPriorScan(data) {
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         return this.http.post<any>(environment.url + "updateStatusPriorScan", data);
//     }

//     saveIdCardData(data) {
//         this.getauthForParticularCompany();
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         // return this.http.post<any>(environment.url + 'idcard/create', data);
//         const formData = new FormData();
//         formData.append("profile_pic", data.file);
//         formData.append("firstName", data.firstName);
//         formData.append("lastName", data.lastName);
//         formData.append("idNo", data.akcessId);
//         formData.append("DOB", data.DOB);
//         formData.append("email", data.email);
//         formData.append("phone", data.phone);
//         formData.append("expiryDate", data.idCardExpiyDate);
//         formData.append("domainName", data.domainName);
//         formData.append("employee_id", data.empliyee_id);

//         //    return this.http.post(environment.url + `idcard/create`, formData)
//         return this.http.post(
//             environment.url + `upload/idCard`,
//             formData,
//             this.httpOptionsForParticularCompany
//         );
//     }
//     saveCertificateData(data) {
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         data.cert_num = data.cert_num.toString();
//         var formdata = new FormData();
//         formdata.append("owner_name", data.owner_name);
//         formdata.append("certificate_name", data.certificate_name);
//         formdata.append("director_name", data.director_name);
//         formdata.append("manager_name", data.manager_name);
//         formdata.append("cert_num", data.cert_num);
//         formdata.append("expiry_date", data.certExpDate);
//         formdata.append("domain_name", data.domain_name);
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `certificates`,
//             formdata,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     sendIdCardByEmail(data) {
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         return this.http.post(environment.url + `push/document/email`, data);
//     }

//     sendIdCardByPhone(data) {
//         data.api = environment.apiKey;
//         data.AKcessToken = sessionStorage.getItem("ak-cbk-token");
//         return this.http.post(environment.url + `push/document/phone`, data);
//     }
//     //idcard
//     sendIdcardByAkcessId(data) {
//         this.getauthForParticularCompany();
//         // data.api = environment.apiKey;
//         // data.AKcessToken = sessionStorage.getItem('ak-cbk-token');
//         return this.http.post(
//             environment.url + `idcard/sendpdfbyakcessId`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     sendIdcardByphone(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `idcard/sendpdfbyphone`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     sendIdcardByemail(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `idcard/sendpdfbyemail`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     //Document
//     sendDocumentByAkcessId(data) {
//         this.getauthForParticularCompany();
//         // data.api = environment.apiKey;
//         // data.AKcessToken = sessionStorage.getItem('ak-cbk-token');
//         return this.http.post(
//             environment.url + `document/sendpdfbyakcessId`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     sendDocumentByphone(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `document/sendpdfbyphone`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     sendDocumentByemail(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `document/sendpdfbyemail`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     //Certificate
//     sendCertificateByphone(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `certificate/sendpdfbyphone`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     sendCertificateByemail(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `certificate/sendpdfbyemail`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     sendCertificateByAkcessId(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `certificate/sendpdfbyakcessId`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     getSentIdCards(userId) {
//         return this.http.get(
//             environment.url + `push/document/email/userId?userId=${userId}`
//         );
//     }

//     addUserAsAdmin(request) {
//         return this.http.post(environment.url + `add-admin`, request);
//     }

//     deleteCompanyAdmin(data) {
//         return this.http.put(environment.url + "deleteadmin", data);
//     }

//     getViewSendIdCard(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `/idcard/viewsend`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }

//     getViewSendCertificate(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(
//             environment.url + `/certificate/viewsend`,
//             data,
//             this.httpOptionsForParticularCompany
//         );
//     }



//     //Save Custom Fields
//     saveCustomField(data) {
//         this.getauthForParticularCompany();
//         return this.http.post<any>(environment.url + `custom-field`, data, this.httpOptionsForParticularCompany);
//     }

//     //Get Custom Fields
//     getCustomField() {
//         this.getauthForParticularCompany();
//         return this.http.get(
//             environment.url + `get-custom-fields`, this.httpOptionsForParticularCompany
//         );
//     }

//     //Get Dashboard Count
//     getDashboardCount() {
//         this.getauthForParticularCompany();
//         return this.http.get(
//             environment.url + `/dashboard/company`, this.httpOptionsForParticularCompany
//         );
//     }

//     // upload document employee vise
//     uploadEmployeeDoc(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(environment.url + 'createuserdoc', data, this.httpOptionsForParticularCompany)
//     }

//     // send invitation to employee
//     sendInvitationToEmployee(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(environment.url + 'sendinvitationtoemployee', data, this.httpOptionsForParticularCompany)
//     }

//     deleteEmployeeDocument(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(environment.url + 'deleteEmpDocument', data, this.httpOptionsForParticularCompany)
//     }

//     getIdDetailById(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(environment.url + 'empIdCard', data, this.httpOptionsForParticularCompany)
//     }

//     deleteIdCard(data) {
//         this.getauthForParticularCompany();
//         return this.http.post(environment.url + 'deleteEmpCard', data, this.httpOptionsForParticularCompany)
//     }

//     checkinvitationUser(data) {
//         this.getAuthHeader4();
//         return this.http.post<any>(environment.url + `companies/invite/status`, '', this.httpOptions1)
//     }
// }
