import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { GeneralServiceService } from 'src/app/_services/general-service.service';

@Component({
    selector: 'app-company-settings',
    templateUrl: './company-settings.component.html',
    styleUrls: ['./company-settings.component.css']
})
export class CompanySettingsComponent implements OnInit {
    loggedInName: string;
    isFileChosen: boolean = false;
    isCompanyFileChosen: boolean = false;
    companyIdLogo: any;
    companyMiniLogo: any;
    msg = "";
    fileName: string = "";
    fileNameCompany: string = "";
    imageUrl: any;
    companyMiniImageUrl: any;
    fileToUpload: any;
    miniLogoToUpload: any;
    searchCustomFields: string = "";
    isCustomFieldListView: boolean;
    isCustomFieldDeleteListView: boolean;

    customFieldList: any;

    pageSize: any = 10;
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50]

    constructor(
        private gs: GeneralServiceService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private calldata: DataservicesService,
    ) { }

    ngOnInit(): void {
        this.isFileChosen = false;
        //this.isCustomFieldListView ;
        //this.isCustomFieldDeleteListView ;
    }

    searchValue: any = "";

    getCustomFieldList() {
        this.spinner.show();

        let data = {
            search: this.searchCustomFields,
            page: this.currentPage,
            limit: this.pageSize
        }

        this.gs.getCustomField(data).subscribe(data => {
            if (data["status"] == true) {
                this.customFieldList = [];
                let tempList = data['data']['docs'];
                this.totalCount = data['data']['totalDocs']
                //this.customFieldList = data['data'];
                this.isCustomFieldListView = true;
                this.isCustomFieldDeleteListView = false;

                tempList.forEach(el => {
                    if (el.key != null) {
                        let tempObj = {
                            countries: el.countries,
                            country: "Global",
                            date: el.updatedAt,
                            isVisible: el.isVisible,
                            items: el.items,
                            key: el.key,
                            labelname: el.labelname,
                            section: el.section,
                            type: el.type,
                            _id: el._id
                        }
                        this.customFieldList.push(tempObj);
                    }
                });
                this.spinner.hide();
            }
            else {
                this.toast.info(data['message']);
                this.spinner.hide();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
    }

    handleFileInput(file: FileList) {

        this.fileToUpload = file.item(0);

        //Show image preview
        let reader = new FileReader();
        reader.onload = (event: any) => {
            this.imageUrl = event.target.result;
        }
        reader.readAsDataURL(this.fileToUpload);

        if (!file[0] || file.length == 0) {
            this.msg = "You must select an image";
            this.isFileChosen = false;
            return;
        }

        var mimeType = file[0].type;

        if (mimeType.match(/image\/*/) == null) {
            this.msg = "Only images are supported";
            this.isFileChosen = false;
            return;
        }

        this.isFileChosen = true;
        this.fileName = file[0].name;
        this.companyIdLogo = file[0];
    }

    handleFileInputMiniLogo(file: FileList) {

        this.miniLogoToUpload = file.item(0);

        //Show image preview
        let reader = new FileReader();
        reader.onload = (event: any) => {
            this.companyMiniImageUrl = event.target.result;
        }
        reader.readAsDataURL(this.miniLogoToUpload);

        if (!file[0] || file.length == 0) {
            this.msg = "You must select an image";
            this.isCompanyFileChosen = false;
            return;
        }

        var mimeType = file[0].type;

        if (mimeType.match(/image\/*/) == null) {
            this.msg = "Only images are supported";
            this.isCompanyFileChosen = false;
            return;
        }

        this.isCompanyFileChosen = true;
        this.fileNameCompany = file[0].name;
        this.companyMiniLogo = file[0];
    }

    deleteRow() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));

        console.log(deleteIDs)
        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data = {
                    component: "custom-field",
                    _id: id,
                };
                this.calldata.softDelete(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getCustomFieldList();
                            this.toast.success("Clients " + res.message);
                            this.spinner.hide();
                        } else {
                            this.toast.info("Clients " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        } else {
            this.toast.error("Select an client");
            this.spinner.hide();
        }
    }

    viewRecycle() {
        this.isCustomFieldListView = false;
        this.isCustomFieldDeleteListView = true;
        this.getDeletedCustomFieldList();
    }

    goBackToCustomListView() {
        this.isCustomFieldListView = true;
        this.isCustomFieldDeleteListView = false;
        this.totalCount = 0;
        this.pageSize = 10;
        this.currentPage = 1;
        this.getCustomFieldList()
    }

    onProjectPageChange(event, type) {
        this.currentPage = event
        if (type == "customField") {
            this.getCustomFieldList()
        } else {
            this.getDeletedCustomFieldList()
        }
    }

    changePage(value, type) {
        this.currentPage = 1
        this.pageSize = parseInt(value)
        if (type == "customField") {
            this.getCustomFieldList()
        } else {
            this.getDeletedCustomFieldList()
        }
    }

    searchDeletedCustomFieldInput: any = "";
    deletedCustomFieldList: any;
    totalDeletedCount: any = 0;

    getDeletedCustomFieldList() {
        this.spinner.show();
        this.calldata.getDeletedList('custom-field', this.searchDeletedCustomFieldInput).subscribe(
            (res: any) => {
                if (res.status) {
                    this.deletedCustomFieldList = res.data.reverse();
                    this.totalDeletedCount = res.count;
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //Delete Permenatly
    deletePermantly() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")))
        if (deleteIDs.length > 0) {
            if (confirm("ARE YOU SURE YOU WANT TO PERMANENTLY DELETE THE SELECTED ITEM(S)?")) {
                for (let id of deleteIDs) {
                    const data = {
                        component: "custom-field",
                        _id: id
                    };

                    this.calldata.forceDeleteData(data).subscribe(
                        (res: any) => {
                            if (res.status) {
                                this.getDeletedCustomFieldList();
                                this.toast.success("Custom fields " + res.message);
                            } else {
                                this.toast.info("Custom fields " + res.message);
                                this.spinner.hide();
                            }
                        }, (err) => {
                            this.spinner.hide();
                            this.toast.error("Oops, something went wrong. Please try again.")
                        }
                    );
                }
            }
            this.spinner.hide();
        }
        else {
            this.toast.error("Select a Custom field");
            this.spinner.hide();
        }

    }

    //Restore Deleted
    restoreDeleted() {
        this.spinner.show();
        let restoreIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => restoreIDs.push(item.getAttribute("id")));

        if (restoreIDs.length > 0) {
            for (let id of restoreIDs) {
                const data = {
                    component: "custom-field",
                    _id: id
                };

                this.calldata.restoreDeletedData(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getDeletedCustomFieldList();
                            this.toast.success("Custom fields " + res.message);
                        } else {
                            this.toast.info("Custom fields " + res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select a Custom field");
            this.spinner.hide();
        }

    }

    search(value) {
        this.currentPage = 1;
        this.pageSize = 10;

        if (value == "customfield") {
            this.searchValue = this.searchCustomFields;
            this.getCustomFieldList()
        } else {
            this.getDeletedCustomFieldList();
        }
    }
}
