import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "../../../../_services/dataservices.service";
import { RefreshContentService } from "../../../../_services/refresh-content.service";

@Component({
  selector: "app-location-deleted",
  templateUrl: "./deleted.component.html",
  styleUrls: ["./deleted.component.css"],
})
export class DeletedLocationComponent implements OnInit {
  locationDeletedlist: any = [];
  //Pagination
  currentPage: any = 1;
  totalCount: any = 0;
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
  pageSize: any = 10;
  isMasterSel: Boolean;
  checkedLocationList: any;
  constructor(
    private calldata: DataservicesService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    public refreshContentService: RefreshContentService
  ) {
    this.isMasterSel = false;
  }

  ngOnInit(): void {
    this.getDeletedLocationList();
  }

  getDays(days) {
    return days
      .map(function (item) {
        return item.label;
      })
      .join(", ");
  }

  getDeletedLocationList() {
    this.spinner.show();
    this.calldata.getDeletedList("location",'').subscribe(
      (res: any) => {
        if (res.status) {
          this.locationDeletedlist = res.data.reverse();
          this.spinner.hide();
        } else {
          this.toast.info(res.message);
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
    }
    );
  }

  //Pagination
  onProjectPageChange(event) {
    this.currentPage = event;
  }

  changePage(value) {
    this.pageSize = parseInt(value);
  }

  //Delete Permenatly
  deletePermantly() {
    this.spinner.show();
    let deleteIDs = [];
    document
      .querySelector("table tbody")
      .querySelectorAll('tr input[type="checkbox"]:checked')
      .forEach((item) => deleteIDs.push(item.getAttribute("id")));

    if (deleteIDs.length > 0) {
      for (let id of deleteIDs) {
        const data = {
          component: "location",
          _id: id,
        };

        this.calldata.forceDeleteData(data).subscribe(
          (res: any) => {
            if (res.status) {
              this.isMasterSel = false;
              this.getDeletedLocationList();
              this.toast.success("Location " + res.message);
            } else {
              this.toast.info("Location " + res.message);
              this.spinner.hide();
            }
          }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        }
        );
      }
    } else {
      this.toast.error("Select a location");
      this.spinner.hide();
    }
  }

  checkUncheckAll() {
    for (var i = 0; i < this.locationDeletedlist.length; i++) {
      this.locationDeletedlist[i].isSelected = this.isMasterSel;
    }
  }

  isAllSelected() {
    this.isMasterSel = this.locationDeletedlist.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedLocationList = [];
    for (var i = 0; i < this.locationDeletedlist.length; i++) {
      if (this.locationDeletedlist[i].isSelected)
        this.checkedLocationList.push(this.locationDeletedlist[i]._id);
    }

    return this.checkedLocationList;
  }

  //Restore Deleted
  restoreDeleted() {
    this.spinner.show();
    let restoreIDs = this.getCheckedItemList();

    if (restoreIDs.length > 0) {
      for (let id of restoreIDs) {
        const data = {
          component: "location",
          _id: id,
        };

        this.calldata.restoreDeletedData(data).subscribe(
          (res: any) => {
            if (res.status) {
              this.getDeletedLocationList();
              this.toast.success("Location " + res.message);
            } else {
              this.toast.info("Location " + res.message);
              this.spinner.hide();
            }
          }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        }
        );
      }
    } else {
      this.toast.error("Select a location");
      this.spinner.hide();
    }
  }
}
