<div class="container-fluid" id="userprofile">
  <div class="back-button mt-2 mb-2">&lt; Back</div>
  <div class="bm mt-0"></div>

  <div class="header d-flex">
    <div class="user-info text-left d-flex">
      <i class="fas fa-user-alt  p-2  mb-2" style="height: 100%; font-size: 50px;    color: #c3c1c1;"></i>
      <div class="user-name ml-2 ">{{ userDetailss?.data.firstName }} {{ userDetailss?.data.surename }}</div>
      <!-- <div class="user-status border ml-3"><i class="fas fa-info-circle pr-2"></i>{{ userDetailss?.data.status }}</div> -->
      <div class="user-status border ml-3"  [ngClass]="{
        'border-green text-success': userDetailss?.data.status === 2,
        'border-red text-danger': userDetailss?.data.status === 1
      }">
      <i class="fas fa-info-circle pr-2"></i>
      <span style="font-weight: 600;" *ngIf="userDetailss?.data.status === 0"  >Not Invited</span>
      <span style="font-weight: 600;" *ngIf="userDetailss?.data.status === 1" >Invited</span>
      <span style="font-weight: 600;"  *ngIf="userDetailss?.data.status === 2">Active</span>

    </div>
    </div>
    <div class="quick-actions">
      <!-- <i class="fas fa-user-alt mr-3"></i> -->
      <button class="btn"  (click)="openQAsidebar()" ><i class="fas fa-bolt mr-2" style="color: #1680b4;"></i><span class="quick-action-text">Quick Actions</span></button>
    </div>
  
  </div>

  <!-- open quickaction -->

  <div class="sidebar2" [class.visible]="openSidebarQA">
    <div class="sidebarud-header">
      <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="d-flex row ">
        <div class="d-flex flex-column">
          <h4 class="ml-2 mt-1 mb-0"><b>What would you like to do?</b></h4>
          <p style="
          font-size: 12px;
          padding: 10px; margin-bottom: 0px; ">Select the action you would like to complete in Nelson's profile...</p>
        </div>
      </div>
    </div>
    <div class="sidebar-content">
      <div> 
        <div class="actions">
          <div class="conaction">
            <label class="action-item">
              <input type="radio" name="action" (click)="openSelectJourneyModal()" />
              <span>Create a journey</span>
              <small>Invite an individual to complete a journey or complete one on their behalf.</small>
            </label>
          </div>
          <div class="conaction">
            <label class="action-item">
              <input type="radio" name="action" (click)="openQAsidebarAfter()" />
              <span>Run a data check</span>
              <small>Run a data check using Nelson's full name, date of birth and postal address.</small>
            </label>
          </div>
         
  
    
    </div>
    
  </div>
</div>
</div>

  <!-- end quickaction -->

<!-- After click on service(quickaction) -->
<div class="sidebar" [class.visible]="openSidebarQAAfter">
  <div class="sidebar-header">
    <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
      <span aria-hidden="true">&times;</span>
    </button>
    <!-- <div class="d-flex flex-column">
      <div class="d-flex row">
        <h4 class="ml-2 mt-1"><b>What would you like to do?</b></h4>
        <p style="
        font-size: 12px;
        padding: 10px; ">Select the action you would like to complete in Nelson's profile...</p>
      </div>
    </div> -->
  </div>
  <div class="sidebar-content">
    <div> 
      <div class="actions flex-column">
        
        <a href="javascript:void(0);" (click)="addressopenclick()" class="d-flex mb-3">
          <!-- <input type="radio" name="action" style="pointer-events: none;"> disable click event for input to prevent double event firing -->
          <i class="fas fa-user-check mr-3" style="font-size: 32px; color: #757575;"></i>
          <div class="quickactiontext">Address and Mortality</div>
        </a>
        
     
        <a href="javascript:void(0);" (click)="sanctionopenclick()" class="d-flex mb-3">
          <i class="fas fa-globe mr-3" style="font-size: 32px; color: #757575;"></i>
          <div class="quickactiontext">Sanctions and PEPs</div>
        </a>
        <a href="javascript:void(0);" (click)="bankaccountopenclick()" class="d-flex mb-3">
        
          <i class="fas fa-university mr-3" style="font-size: 32px; color: #757575;"></i>
          <div class="quickactiontext">Bank account</div>
        </a>
      
  </div>
  
</div>
</div>
<div class="sidebar-footer">
  <button class="btn-greybottom" (click)="closeSidebar()">Cancel</button>
</div>
</div>

 <!--End After click on service(quickaction) -->
  
    <div class="openContactInfoModal" *ngIf="isModalOpen">
      <div class="openContactInfoModal-content">
        <span class="openContactInfoModalclose" (click)="openContactInfoModal()">&times;</span>
        <h2>Contact Information</h2>
        <p>Status: Active</p>
        <p>Personal Identifiers: EL9-J9Q</p>
        <p>Contact Information: {{ userDetailss.data.emailAddress }}</p>
      </div>
    </div>
    <div class="tabs  px-2" style="color: #545454;">
      <div  class="tab" [class.active]="activeTab === 1" (click)="setActiveTab(1)" data-tab="1">Identity Checks</div>
      <div  class="tab" [class.active]="activeTab === 2" (click)="setActiveTab(2)" data-tab="2">User Details</div>
      <div   class="tab" [class.active]="activeTab === 3"  (click)="setActiveTab(3)"  data-tab="3">Processes</div>
      <div  class="tab"  [class.active]="activeTab === 4"  (click)="setActiveTab(4)" data-tab="4">Evidence</div>
      <div class="tab"   [class.active]="activeTab === 5"  (click)="setActiveTab(5)"  data-tab="5">eSign Documents</div>
      <div  class="tab"  [class.active]="activeTab === 6" (click)="setActiveTab(6)"  data-tab="6">Exports</div>
      <div class="tab"  [class.active]="activeTab === 7"  (click)="setActiveTab(7)"   data-tab="7">Notes</div>
  </div>
  </div>
  
  <div class="content">
    <div class="section " *ngIf="activeTab === 1" data-content="1">
      
      <div class="container-fluid">
        <h3 class="mt-2 mb-12" style="color:#323343"><b>Active Checks</b></h3>
        <div class="row">
          <ng-container *ngFor="let item of items">
            <div class="col-md-6 col-lg-4 col-sm-6  col-xl-2 px-1 mt-1" *ngIf="item.status === 2 || item.status === 1 || item.status === 6 || item.status === 3">

              <div [ngClass]="{
                'box active-checks': item.status === 2,
                'box active-checks-green': item.status === 1,
                'box active-checks-yellow m-0': item.status === 6 || item.status === 3
              }">
                <div [ngClass]="{
                  'activecheck ': item.status === 2,
                  'activecheck-green ': item.status === 1,
                  'activecheck-yellow ': item.status === 6 || item.status === 3
                }">
                  <div class="d-flex justify-content-between">              
                    <!-- <i class="fa fa-video"  style="font-size: 30px;"></i> -->
                    <i *ngIf="item.name === 'ID verification'" class="fas fa-passport" style="font-size: 30px;"></i>
                    <i *ngIf="item.name === 'Liveness'" class="fas fa-video" style="font-size: 30px;"></i>
                    <i *ngIf="item.name === 'Address and Mortality'" class="fas fa-user-check" style="font-size: 30px;"></i>
                    <i *ngIf="item.name === 'Sanctions and PEPs'" class="fas fa-globe" style="font-size: 30px;"></i>
                    <i *ngIf="item.name === 'Bank account'" class="fas fa-university" style="font-size: 30px;"></i>
                   
                    <i class="fas fa-info-circle pr-2"></i>                 
                  </div>
                  <p class="mt-2 mb-2 idverify">{{ item.name }}</p>
                </div>
                <div class="checkbox">
                  <p class="status-text pt-0 mt-1"><b>
                    <ng-container *ngIf="item.status === 1">
                      <i class="fas fa-check-circle" style="color: green;"></i>
                    </ng-container>
                    <ng-container *ngIf="item.status === 2">
                      <i class="fa fa-exclamation-circle"  style="color: blue;"></i>
                    </ng-container>
                    <ng-container *ngIf="item.status === 6 || item.status === 3">
                      <i class="fa fa-exclamation-triangle"  style="color: #e69509;"></i>
                    </ng-container>
                    {{ getStatusTexts(item.status) }}</b></p>
                  <p class="mb-0 pb-0" style="font-size: 12px;color: #000;"> <i class="fa fa-send pr-1"></i>{{ formatDate(item.requestedDate) }}</p>
                  <p *ngIf="item.status == 6 || item.status == 3" class="calender mb-0 pb-0"> <i class="far fa-calendar pr-1"></i>{{ formatDate(item.requestedDate) }}</p>
                </div>
                <div 
                  [ngClass]="{
                    'activesent-box ' : item.status === 2,
                    'activesent-box-green ' : item.status === 1,
                    'activesent-box-yellow ': item.status === 6 || item.status === 3
                  }"
                  (click)="openSidebar(item)">
                  <a class="mb-0 pb-0" style="color:#000; font-size:12px; font-weight: 500; "><i class="fa fa-eye pr-1"></i> View</a> 
                  <!-- <a [ngClass]="{'upload': item.status === 6}" ><i class='fas fa-cloud-upload-alt'></i></a> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    <div class="container-fluid">
  <h3 class="mt-3 mb-9" style="color:#323343"><b>Available Checks</b></h3>
  <div class="row">
    <ng-container *ngFor="let item of items">
      <div class="col-md-6 col-lg-4 col-sm-6 col-2lg-4 col-xl-2  px-0 ml-0" *ngIf="item.status === 5">
        <div class="d-flex box p-0 m-1 active-check">
          <div class="checkbox">
            <div class="active d-flex justify-content-between">
              <!-- <i class="fa fa-send"></i> -->
              <i *ngIf="item.name === 'ID verification'" class="fas fa-passport" style="font-size: 30px;"></i>
              <i *ngIf="item.name === 'Liveness'" class="fas fa-video" style="font-size: 30px;"></i>
              <i *ngIf="item.name === 'Address and Mortality'" class="fas fa-user-check" style="font-size: 30px;"></i>
              <i *ngIf="item.name === 'Sanctions and PEPs'" class="fas fa-globe" style="font-size: 30px;"></i>
              <i *ngIf="item.name === 'Bank account'" class="fas fa-university" style="font-size: 30px;"></i>
              <i *ngIf="item.name === 'Open Banking'" class="fas fa-piggy-bank" style="font-size: 30px;"></i>
              <i *ngIf="item.name === 'Source of Funds'" class="fa fa-money" style="font-size: 30px;"></i>
              <i *ngIf="item.name === 'Supporting documents'" class="fas fa-file-invoice" style="font-size: 30px;"></i>
              <i class="fas fa-info-circle pr-2"></i>
            </div>
            <p class=" availablechecktext mt-4 mb-0" ><b>{{ item.name }}</b></p>

            <!-- <p class="status-text mt-3">{{ getStatusText(item.status) }}</p> -->
          </div>
          <div class="sent-box" (click)="openSidebar(item)">
            <i class="fa fa-send" (click)="openSidebar(item)" style="line-height: 0;"></i>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>




  </div>
  
  <div class="section mb-3" *ngIf="activeTab === 2" data-content="2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="d-flex mt-2">
            <div class="sidetitle">Personal Details</div>
          </div>
        </div>
        <div class="activesent-box-ud">
          <div class="col-md-4 mt-2">
            <i class="fas fa-pencil-alt" (click)="openpersonalsidebar()"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="personal-detail-containt d-flex flex-column ">
          <p class="mb-1">Registered email</p>
    
          <span class="personal-detail mailto:mb-2"> {{ userDetailss.data.emailAddress }}</span>
        </div>
        <div class="personal-detail-containt d-flex flex-column ">
          <p class="mb-1">Correspondence address</p>
          <span class="personal-detail mb-4">N/A</span>
        </div>
      </div>
      <div class="col-md-4">    
        <div class="personal-detail-containt d-flex flex-column ">
          <p class="mb-1">Phone number</p>
          <span class="personal-detail mb-2">-</span>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="bm"></div>

  </div>
  
  <!-- Personal Details Sidebar -->
  <div class="sidebar2" [class.visible]="opensidebarpersonal">
    <div class="sidebarud-header">
      <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="d-flex flex-column">
        <div class="d-flex">
          <h4 class="ml-2 mt-1"><b>Account</b></h4>
        </div>
      </div>
    </div>
    <div class="sidebar-content">
      <div class="sidebar-content-is">Invite Status</div>
    <div class="detail-check-text"><i class="fas fa-check-circle"></i>Active</div>
    <div class="sidebar-content-is ">Approval status</div>
  
    <div class="form-group mb-8">
      <!-- <label  for="dobMatching">Date of birth matching*</label> -->
      <select class="form-control" id="dobMatching"  name="dobMatching">
        <option>Approve</option>
        <option>Pending</option>
      </select>
    </div>
    <div class="bm"></div>
    <div class="sidebar-content-is">Invite Status</div>
      <div class="account-details">
        <div class="row">
        <div class="col-md-4">
        
          <div class="sidebar-personal-detail-containt d-flex flex-column">
            <p>Registration code</p>
            <span class="personal-detail mb-2"> {{ userDetailss?.data.registrationCode }} </span>
          </div>
          <div class="sidebar-personal-detail-containt d-flex flex-column">
            <p class="mb-1">Added by<span> </span></p>
              <span class="personal-detail mb-2">-</span>
          </div>
        </div>
        <div class="col-md-8">
          <p><button class="copy-btn-ud-sidebar">Copy</button> <i class="fa fa-copy"></i></p>
        </div>
        </div>
        
        <button class="btn-grey">Resend invite</button>
      </div>
      <div class="bm"></div>
      <div class="sidebar-content-is">Other details</div>
    <form>
      <div class="form-group mb-3 ">
        <label for="ClientReference">Client reference</label>
        <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName">
      </div>
    </form>
  
      <div class="sidebar-footer">
        <button class="btn-greybottom" (click)="closeSidebar()">Cancel</button>
        <button class="btn-darkgrey" color="primary" >Save</button>
        
      </div>
    </div>
    <div class="bm"></div>
  </div>
  <div class="section mb-3" *ngIf="activeTab === 2" data-content="2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="d-flex mt-2 ">
            <div class="sidetitle" >Account</div>
            
          </div>
        </div>
        
        <div class="activesent-box-ud">
          <div class="col-md-4 mt-2">
            <i class="fas fa-pencil-alt" (click)="openaccountsidebar()"></i>
          </div>
        </div>
      
      
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        
        <div class="personal-detail-containt d-flex flex-column">
          <p class="mb-1">Approval status</p>
          <span class="personal-detail mb-2"> {{ userDetailss?.data.status }}</span>
        </div>
        <div class="personal-detail-containt d-flex flex-column">
          <p class="mb-1">Sent on</p>
          <span class="personal-detail mb-2">{{ userDetailss?.data.dateCreated }}</span>
        </div>
        <div class="personal-detail-containt d-flex flex-column">
          <p class="mb-1">Client reference</p>
          <span class="personal-detail mb-4">N/A</span>
        </div>
      </div>
      <div class="col-md-4">
        
        <div class="personal-detail-containt d-flex flex-column">
          <p>Registration code</p>
          <span class="personal-detail mb-2">{{ userDetailss?.data.registrationCode }} </span>
        </div>
        <div class="personal-detail-containt d-flex flex-column">
          <p class="mb-1">Added by<span> </span></p>
            <span class="personal-detail mb-2">-</span>
        </div>
      </div>
      <div class="col-md-4">
        <p><button class="copy-btn-ud">Copy</button> <i class="fa fa-copy"></i></p>
      </div>
    
  </div>
  <div class="bm"></div>
  </div>

      <!-- Account sidebar -->
      <div class="sidebar2" [class.visible]="opensidebaraccount">
        <div class="sidebarud-header">
          <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="d-flex flex-column">
            <div class="d-flex">
              <h4 class="ml-2 mt-1"><b>Account</b></h4>
            </div>
          </div>
        </div>
        <div class="sidebar-content">
          <div class="sidebar-content-is">User name</div>
          <form *ngIf="userDetailss?.data">
            <div class="form-group mb-3 ">
              <label for="FirstName" style="font-size: 14px;">First name</label>
              <input type="text" class="form-control" id="firstName" [(ngModel)]="userDetailss?.data.firstName" name="firstName">
            </div>
            <div class="form-group mb-3">
              <label for="middleName"  style="font-size: 14px">Middle Name</label>
              <input type="text" class="form-control" id="middleName" name="middleName">
            </div>
            <div class="form-group mb-3">
              <label for="lastName" style="font-size: 14px;">Surname*</label>
              <!-- <input type="text" class="form-control" id="firstName" [(ngModel)]="userDetailss?.data.firstName" name="firstName"> -->

              <input type="text" class="form-control" id="surname" [(ngModel)]="userDetailss?.data.surname" name="surname">
            </div>
            
            <div class="form-group mb-3">
              <label for="dobDay" style="font-size: 14px;">Date of Birth</label>
              <div class="d-flex align-items-center">
                <div class="dob-label">
                  <!-- <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label> -->
                  <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-1" id="dobDay" placeholder="DD"  name="dobDay">
                </div>
                <div class="dob-label">
                  <!-- <label class="text-dark" for="dobMonth " style="font-weight: bold;">Month</label> -->
                  <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-1" id="dobMonth" placeholder="MM"  name="dobMonth">
                </div>
                <div class="dob-label">
                  <!-- <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label> -->
                  <input style="width: 80px;" type="text" class="form-control mx-1" id="dobYear" placeholder="YYYY" name="dobYear">
                </div>
              </div>
            </div>
            <div class="bm"></div>
           
           <div class="sidebar-content-is">Contact details</div>
          
            <div class="form-group mb-3 ">
              <label for="FirstName" style="font-size: 14px;" >Email address</label>
              <input type="text" class="form-control " id="emailAddress" [(ngModel)]="userDetailss?.data.emailAddress" name="emailAddress">
             
            </div>
            <!-- <div style="display: flex; gap: 10px;">
              <mat-form-field appearance="outline" style="flex: 1;">
                <mat-label>Dialling code</mat-label>
                <mat-select>
                  <option *ngFor="let code of diallingCodes" [value]="code">
                    {{ code }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          
              <mat-form-field appearance="outline" style="flex: 2;">
                <mat-label>Phone number</mat-label>
                <input matInput placeholder="Phone number">
              </mat-form-field>
            </div> -->
            <div class="form-group d-flex align-items-center">
              <div class="dropdown mb-3">
                <button class="btn btn-primary dropdownud-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Dialling code
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" *ngFor="let code of diallingCodes" (click)="selectCode(code)">
                    {{ code }}
                  </a>
                </div>
              </div>
          
              <input style="width: fit-content" type="text" id="phoneNumber" name="phoneNumber" placeholder="Phone number" class="form-control ml-2 mb-3">
            </div>

            

            <!-- <div class="form-group mb-3 ">
              <label for="FirstName">Phone Number</label>
              <input type="number" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName">
            </div> -->
            <div class="bm"></div>
            <div class="sidebar-content-is">Correspondence address</div>
            <div class="form-group mb-3">
              <label for="addressSearch" style="font-size: 14px;">Address</label>
              <p style="font-size: 12px;color: #000;">Search using a UK Postcode, or <span class="text-warning">enter manually</span></p>
              <div class="d-flex">
                <input type="text" class="form-control" id="addressSearch" placeholder="Search using a postcode" [(ngModel)]="addressSearch" name="addressSearch">
                <button style="border-radius: 20px;" type="button" class="btn btn-secondary ml-3 btn-sm" (click)="runDataChecks()" [disabled]="!addressSearch.trim()">Search</button>
              </div>
            </div>
          
            <div *ngIf=" addresses.length > 0" class="dropdown mb-3">
              <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Select Address
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" *ngFor="let address of addresses" (click)="selectAddress(address)">
                  {{ address.addressLine1 }}, {{ address.addressLine2 }}, {{ address.town }}, {{ address.county }}, {{ address.postcode }}
                </a>
              </div>
            </div>
          
            <div *ngIf="selectedAddresss">
              <div class="form-group">
                <label for="addressLine1">Address Line 1</label>
                <input type="text" class="form-control" id="addressLine1" name="addressLine1" [(ngModel)]="selectedAddresss.addressLine1">
              </div>
              <div class="form-group">
                <label for="addressLine2">Address Line 2</label>
                <input type="text" class="form-control" id="addressLine2" name="addressLine2" [(ngModel)]="selectedAddresss.addressLine2">
              </div>
              <div class="form-group">
                <label for="town">Town</label>
                <input type="text" class="form-control" id="town" name="town" [(ngModel)]="selectedAddresss.town">
              </div>
              <div class="form-group">
                <label for="county">County</label>
                <input type="text" class="form-control" id="county" name="county" [(ngModel)]="selectedAddresss.county">
              </div>
              <div class="form-group">
                <label for="postcode">Postcode</label>
                <input type="text" class="form-control" id="postcode" name="postcode" [(ngModel)]="selectedAddresss.postcode">
              </div>
            </div>

            <div class="  mb-3">
              <!-- <label for="addressSearch">Address</label>
              <p style="font-size: 12px;color: #000;">Serach using a UK Postcode, or <span class="text-warning">enter manually</span></p> -->
              <div class="margin d-flex row">
                <div class="ml-30">
            <input type="text" class="form-control" id="surname" [(ngModel)]="userDetailss?.data.surname" name="surname">

              <!-- <input type="text"  id="postcode" placeholder="Search using a postcode" [(ngModel)]="postcode" name="addressSearch" > -->
            </div>
             
              <div class="col-md-8">
              <button type="button" class="button-search-account" (click)="runDataCheck()" [disabled]="!addressSearch.trim()">Search</button>
              <button  type="button" class="button-account" (click)="runDataCheck()" [disabled]="!addressSearch.trim()">Enter Manualy</button>
            </div>
            </div>
            </div>
            <div class="sidebar-footer">
              <button class="btn-greybottom" (click)="closeSidebar()">Cancel</button>
              <button class="btn-darkgrey" color="primary" >Save</button>
              
            </div>
            </form>
        <!-- <div class="detail-check-text"><i class="fas fa-check-circle"></i>Active</div>
        <div class="sidebar-content-is ">Approval status</div> -->
      
        <!-- <div class="form-group mb-8">
          <label  for="dobMatching">Date of birth matching*</label>
          <select class="form-control" id="dobMatching" [(ngModel)]="dobMatching" name="dobMatching">
            <option>Approve</option>
            <option>Pending</option>
          </select>
        </div> -->
        <!-- <div class="bl"></div> -->
        
          
          
      
          
        </div>
        <div class="bm"></div>
        </div>

         <!-- group -->
      <div class="section mb-3" *ngIf="activeTab === 2" data-content="2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-8">
              <div class="d-flex mt-3 bt-3">
                <div class="sidetitle" >Groups</div>
              </div>
            </div>
            <div class="activesent-box-ud">
              <div class="col-md-4 mt-3">
                <i class="fas fa-pencil-alt" (click)="opengroupsidebar()"></i>
              </div>
            </div>
          </div>
        </div>
      </div> 
        <!-- Group sidebar -->
    
        <div class="sidebar" [class.visible]="opensidebargroup">
  <div class="sidebar-header">
    <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
      <span aria-hidden="true">&times;</span>
    </button>  
    <div class="d-flex flex-column">
      <div class="d-flex">
        <i class="fa fa-video" style="font-size: 30px;"></i>
        <!-- <h4 class="ml-2 mt-1"><b>{{ selectedItem.name }}</b></h4> -->
                 <h4 class="ml-2 mt-1"><b> selectedItem.name </b></h4>

      </div>     
      <p class="mt-4" *ngIf="selectedItem && selectedItem.name === 'Source of Funds'">
        Select the Source of Funds journey that you would like  userDetailss?.data.firstName  to complete...
      </p>    
    </div>
    <div class="pdf">
      <button type="button" class="btn btn-dark" (click)="viewPDF()">
        <i class='fas fa-file-pdf'></i> View PDF
      </button>
    </div>
  </div>
  
  
        </div>

  
    <div class="section" *ngIf="activeTab === 3" data-content="3">
      <!-- Processes content here -->
    </div>
    
    <div class="section" *ngIf="activeTab === 4" data-content="4">
      <!-- Evidence content here -->
    </div>
    
    <div class="section" *ngIf="activeTab === 5" data-content="5">
      <!-- eSign Documents content here -->
    </div>
    
    <div class="section" *ngIf="activeTab === 6" data-content="6">
      <!-- Exports content here -->
    </div>
    
    <div class="section" *ngIf="activeTab === 7" data-content="7">
      <!-- Notes content here -->
    </div>
  </div>

   
        

<!-- Select Journey Modal -->
<!-- <div class="modal" id="selectJourneyModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-2">
        <div class="d-flex flex-column">
          <h3 class="modal-title">Select Journey(s)</h3>
          <p>Select the journey(s) that you would like the individual to complete.</p>
        </div>
        <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('selectJourneyModal')">
          <span aria-hidden="true">&times;</span>
        </button>   
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group journey-box p-2 mb-1 d-flex justify-content-between align-items-start">
            <div>
              <label for="journeyOption1"><b>Identity</b></label>
              <p class="mb-1">*This journey is set to Web Only for demo purposes.</p>
              <p class="mb-1">This journey will run the following check:</p>
              <ul>
                <li>Identity verification</li>
              </ul>
            </div>
            <div class="radio-container" (change)="logSelectedValue('Identity verification')">
              <input type="radio" id="Identity verification" name="journeyOption" class="radio-input" value="option1" >
              <label for="Identity verification" class="radio-label"></label>
            </div>
          </div>
          <div class="form-group journey-box p-2 mb-1 d-flex justify-content-between align-items-start">
            <div>
              <label for="journeyOption2"><b>Identity | Address | International Screening</b></label>
              <p class="mb-1">*Native only in Live</p>
              <p class="mb-1">This journey will run the following checks:</p>
              <ul class="my-1">
                <li>Identity verification</li>
                <li>Address verification</li>
                <li>Mortality</li>
                <li>PEP status</li>
                <li>Sanction status</li>
              </ul>
            </div>
            <div class="radio-container" (change)="logSelectedValue('Identity | Address | International Screening')">
              <input type="radio" id="Identity | Address | International Screening" name="journeyOption" class="radio-input" value="option2">
              <label for="Identity | Address | International Screening" class="radio-label"></label>
            </div>
          </div>
          <div class="form-group journey-box p-2 mb-1 d-flex justify-content-between align-items-start">
            <div>
              <label for="journeyOption3"><b>Identity | International Screening</b></label>
              <p class="mb-1">*Native only in Live</p>
              <p class="mb-1"> This journey will run the following checks:</p>
              <ul class="my-1">
                <li>Identity verification</li>
                <li>PEP status</li>
                <li>Sanction status</li>
              </ul>
            </div>
            <div class="radio-container" (change)="logSelectedValue('Identity | International Screening')">
              <input type="radio" id="Identity | International Screening" name="journeyOption" class="radio-input" value="option3">
              <label for="Identity | International Screening" class="radio-label"></label>
            </div>
          </div>
          <div class="p-2 d-flex flex-column mt-1">
            
            <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('selectJourneyModal', 'configureJourneyModal')">Next</button>
            <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="closePopup('selectJourneyModal')" style="border-radius: 20px;">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->

<!-- Select Journey Modal -->
<div *ngIf="showSelectJourneyModal" class="modal" id="selectJourneyModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-2">
        <div class="d-flex flex-column">
          <h4 class="modal-title font-bold text-dark">Select Journey(s)</h4>
          <p class="mb-1 mt-1" style="font-size: 12px;">Select the journey(s) that you would like the individual to complete.</p>
        </div>
        <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('selectJourneyModal')">
          <span aria-hidden="true">&times;</span>
        </button>   
      </div>
      <div class="modal-body">
      
        <form class="journey">
          
          <!-- Iterate over journeys array -->
          <div *ngFor="let journey of journeys" class="form-group journey-box p-2 mb-1 d-flex justify-content-between align-items-start">
            <div>
              <label class="text-dark"  [for]="'journeyOption' + journey.id"><b>{{ journey.title }}</b></label>
              <div [innerHTML]="journey.description" class="custom-inner-html"></div>
            </div>
            <div class="radio-container">
              <input type="radio" [id]="'journeyOption' + journey.id" name="journeyOption" class="radio-input" [value]="journey.id" (change)="logSelectedValue(journey.id)">
              <label [for]="'journeyOption' + journey.id" class="radio-label"></label>
            </div>
          </div>

          <div class="p-2 d-flex flex-column mt-1">
            <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('selectJourneyModal', 'configureJourneyModal')">Next</button>
            <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="closePopup('selectJourneyModal')" style="border-radius: 20px;">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Configure Journey Modal -->
<div *ngIf="showConfigureJourneyModal" class="modal" id="configureJourneyModal" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
  
  <div class="modal-header">
    <div class="d-flex flex-column">
      <h4 class="modal-title text-dark font-bold">Configure Journey(s)</h4>
      <p class="mb-0" style="font-size: 12px;">Configure the Journey(s) that you would like the individual to complete.</p>
    </div>
    <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('configureJourneyModal')">
      <span aria-hidden="true">&times;</span>
    </button>  
  </div>
  <div class="modal-body config-body">
    <div class="d-flex justify-content-between align-items-center " *ngIf="selectedJourney">
     
      <div class="journey-section">
        <!-- <h3>Identity</h3> -->
        <label class="font-bold text-dark"><b>{{ selectedJourney.title }}</b></label>
        <div [innerHTML]="selectedJourney.description"></div>
      </div>
      <!-- <div (change)="logSelectedValue('Identity')"> 
        <input type="radio" id="Identity" name="Identity" class="radio-input">
        <label for="Identity" class="radio-label"></label>
      </div> -->
    </div>
    <div class="form-group ">
      <label class="text-dark font-bold" for="description"><b>Description (optional)</b></label><br>
      <input class="form-control col-md-12" type="text" id="description" placeholder="Give your journey a unique title to make it easier to search for. eg. 'Jane Doe's Enhanced AML'" style="font-size: 12px;">
    </div>
    <div class="form-group col-md-12 px-1" [formGroup]="uploadForm">
      <label class="text-dark font-bold"  for="applicant"><b>Applicant</b></label><br>
      <input class="form-control col-md-12" type="text" id="applicant" [value]="userDetailss.data.firstName" formControlName="applicant" disabled>
    </div>
  </div>

    <div class="p-2 d-flex flex-column">
      <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('configureJourneyModal', 'contactModal')">Next</button>
      <button type="button" class="btn btn-outline-secondary col-md-12 mt-2" (click)="goToPreviousModal('selectJourneyModal')" style="border-radius: 20px;">Back</button>
    </div>
  
</div>
</div>
</div>
<!-- contect modal -->

<div *ngIf="showContactModal"  class="modal" id="contectModal" tabindex="-1" role="dialog" >
  <div class="modal-dialog modal-lg" role="document">    
  <div class="modal-content">
    <div class="modal-header">
      <div class="d-flex flex-column">
        <h4 class="modal-title font-bold text-dark">Contact Methods</h4>
        <p  class="mb-0" style="font-size: 12px;">Select the contact methods for each of the individuals.</p>
      </div>
      <button type="button" class="close top-close" aria-label="Close" (click)="closePopup('contectModal')">
        <span aria-hidden="true">&times;</span>
      </button>  
    </div>
    <div class="container pb-1 d-flex flex-column">
    <label class="font-bold text-dark"><b>Individuals:</b></label>
    <p class="mb-0" style="font-size: 12px;">Set The contect preference for the individuals</p>
  </div>
    <div class="modal-body config-body mt-1">
    <form [formGroup]="uploadForm">
     
      <h5 class="pb-3 text-dark"><b>{{ userDetailss.data.firstName }}</b></h5>
      
      <div class="form-group">
        <label class="text-dark" style="font-size: 12px;" for="preference"> <b>Contact preference:</b></label>
        <select class="custom-select form-control " style="background-color: #fff;">
          <option>Send an email</option>
          <option>Send an SMS</option>  
          <option>Don't send an invite, I'll complete the  process myself.</option>         
        </select>
      </div>     
      <div class="form-group">
        <label class="text-dark " style="font-size: 12px;" for="email"><b>Email</b></label>
        <input type="email" id="email" placeholder="Email Address (optional)" formControlName="email" class="form-control" [(ngModel)]="formdata.email" [value]="userDetailss.data.emailAddress"required > 

      </div>
      <div class="form-group">
        <label class="text-dark" style="font-size: 12px;" for="telephone"><b>Telephone</b></label>
        <div class="input-group">
          <select formControlName="countryCode" id="countryCode" style="background-color: #fff;"  class="custom-select form-control " placeholder="Dailing code" >
            <option value="+61">Australia +61</option>
            <option value="+33">France +33</option>
            <option value="+49">Germany +49 </option>
            <option value="+353">Ireland +353 </option>
            <option value="+39">Italy +39</option>
            <option value="+34">Spain +34 </option>
            <option value="+44">UK +44</option>
            <option value="+1">United States +1</option>
            <!-- Add more options as needed -->
          </select>
          <input type="tel" id="telephone" formControlName="telephone" class="form-control ml-2" placeholder="Mobile Number (optinal)" [(ngModel)]="formdata.telephone">
        </div>
      </div>      
    </form>
    </div>
    <div class="p-2 mt-5 d-flex flex-column">
      <button type="button" class="btn btn-dark col-md-12 confirm-btn" style="border-radius: 20px;" (click)="goToNextModal('contectModal','thankyoumodal')">Create invite</button>
      <button type="button" class="btn btn-outline-secondary col-md-12 mt-2"  (click)="goToPreviousModal('configureJourneyModal')" style="border-radius: 20px;">Back</button>
    </div>
  </div>
</div>
</div>
<!-- journey(s) have been created -->

<div *ngIf="thankyoumodal" class="modal" id="thankyoumodal" tabindex="-1" role="dialog" [ngStyle]="{'display': thankyoumodal ? 'flex' : 'none'}">
  <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content p-4 py-5 text-center" style="background-color: #E7F6F2; border-radius: 10px;">
          <div class="icon mb-4">
              <!-- <img src="assets/check-icon.png" alt="Check Icon" style="width: 50px; height: 50px;"> -->
              <i class="fas fa-check-circle check-br"></i> 
          </div>
          <h3 class="title mb-3 text-dark font-bold">Great news, your journey(s) have been created for</h3>
          <div class="subtitle mb-4">
            
              <span class="profile" style="color: #1ABC9C; font-weight: bold;"> <i class="fas fa-check-circle"></i> {{ userDetailss.data.firstName }}</span>
              <br>You can now view the profiles below, or you can add another journey.
          </div>
          <button class="btn btn-light button-primary mb-2" style="width: 100%; padding: 10px 0; border-radius: 20px;background: #eee; border: none;" (click)="closePopup('thankyoumodal')">Go to {{ userDetailss.data.firstName }} profile</button>
          <button class="btn btn-dark button-secondary mb-2" style="width: 100%; padding: 10px 0; border-radius: 20px;">Send a new invite</button>
          <button class="btn btn-outline-secondary button-close" style="width: 100%; padding: 10px 0; border-radius: 20px;" (click)="closePopup('thankyoumodal')">Close</button>
      </div>
  </div>
</div>







<!-- call modal for loop -->


<!-- Sidebar -->

  <div  *ngIf="sidebarVisible" class="sidebar" [class.visible]="sidebarVisible" >
   
    <div class="sidebar-header">
      <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
        <span aria-hidden="true">&times;</span>
      </button>  
      
      <div class="d-flex flex-column" >
        <div class="d-flex">
          <i class="fa fa-video" style="font-size: 30px;"></i>
          <h4 class="ml-2 mt-1"><b>{{ selectedItem.name }}</b></h4>
        </div>     
          <p class="mt-4" *ngIf="selectedItem && selectedItem.name === 'Source of Funds'">Select the Source of Funds journey that you would like {{ userDetailss.data.firstName }} to complete...</p>    
         
        </div>
        <div class="pdf" *ngIf="selectedItem.status === 1 || selectedItem.status === 1 ||selectedItem.status === 6 "  >
          <button type="button" class="btn btn-dark" (click)="viewPDF()"><i class='fas fa-file-pdf'></i>View PDF</button>
        </div>
        
    </div>
    <ul class="nav nav-tabs">
      <li class="nav-item" (click)="setActiveTabs('check')" [class.active]="activeTabs === 'check'">
        <a class="nav-link">Check</a>
      </li>
      <li class="nav-item" (click)="setActiveTabs('info')" [class.active]="activeTabs === 'info'" *ngIf="selectedItem.status ! === 3">
        <a class="nav-link">Info</a>
      </li>
      <li class="nav-item" (click)="setActiveTabs('settings')" [class.active]="activeTabs === 'settings'">
        <a class="nav-link">Settings</a>
      </li>
      <li class="nav-item" (click)="setActiveTabs('archive')" [class.active]="activeTabs === 'archive'">
        <a class="nav-link">Archive</a>
      </li>
    </ul>
  
    
  
    <!-- Tab Content -->
     
    <div [ngSwitch]="activeTabs">
      <div *ngSwitchCase="'check'" >
          <!-- if status is true 2 awaiting -->

        <ng-container *ngIf="selectedItem.status === 2 && selectedItem.name == 'Liveness' ">
      <div class="mt-5 border-btm row" >
          <div class="col-md-4">
              <p class="sidebartexthead">Date Of Request</p>
              <p style="font-size: 14px;">{{ formatDate(selectedItem.requestedDate) }}</p>
          </div>
          <div class="col-md-4">
              <p class="sidebarcontainhead">Status</p>
              <p class="sidebartexthead"><i class="fa fa-exclamation-circle" style="color: blue;"></i> {{ getStatusText(selectedItem.status) }}
            </p>
          </div>
          <div class="col-md-4"></div>
      </div>
      <div class="border-btm mt-3">
      <div>
        <p class="sidebarcontainhead">Submited Selfie</p>
        <p *ngIf="!Selfieimg" style="font-size: 14px;">
          <i class="fa fa-ellipsis-h"></i> Awaiting Upload
        </p>
        <div class="photo-wrapper portrait">
          <app-image>
            <div  class="full-height">
          <pinch-zoom class="full-height" style="overflow: hidden; background-color: rgba(0, 0, 0, 0.85);">
          <div class="pinch-zoom-content"   style="display: flex; align-items: center; justify-content: center; transform-origin: 0px 0px; transition: all 200ms ease 0s; transform: matrix(1, 0, 0, 1, 0, 0);">
        <img *ngIf="Selfieimg" [src]="Selfieimg" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;">
      </div>
        <div class="pz-zoom-button pz-zoom-control-position-bottom ng-star-inserted"></div>
          </pinch-zoom>
            </div>
          </app-image>
        <!-- <p style="font-size: 14px;"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p> -->
      </div>
      </div>
    <div>
      <p class="sidebarcontainhead">Passive Liveness Status</p>
      <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p>
  </div>
      </div>
  
      <div class="mt-3">
        <div>
          <p class="sidebarcontainhead">Submited Unique action protocol (UAP)</p>
          <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p>
      </div>
      <div>
        <p class="sidebarcontainhead">Status</p>
        <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>{{ getStatusText(selectedItem.status) }}</p>
    </div>
    <div>
      <p class="sidebarcontainhead">UPA Status</p>
      <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p>
  </div>
        </div>
      </ng-container>
    
  <!--end of  if status is true 2 awaiting -->
  
  
  
  <!-- if status is true 1 pass -->
  
  <ng-container *ngIf="selectedItem.status === 1 && selectedItem.name == 'ID verification' ">
 
    <div class="statustrue">
    <h6 class="sidebarheading">ID Document Verification</h6>
    <p class="sidebartexthead">We ask the individual to take a photograph of their biometric document and then use sophisticated image analysis to detect any tampering or manipulation.
    </p>
    <div class="mt-3 row">
      <div class="col-md-3">
        <p class="sidebarcontainhead mb-1" ><b>Requested by</b></p>
        <p class="sidebartexthead">{{ selectedItem.requestedBy }}</p>
      </div>
      <div class="col-md-3">
        <p class="sidebarcontainhead mb-1" ><b>Uploaded by</b></p>
        <p class="sidebartexthead"> {{ idvDataIdentity.idDocuments[0].uploadedBy  }}</p>
      </div>
      <div class="col-md-3">
        <p class="sidebarcontainhead mb-1" ><b>Uploaded on</b></p>
        <p class="sidebartexthead"> {{ formatDate(idvDataIdentity.idDocuments[0].dateCreated) }}</p>
      </div>
      <div class="col-md-3">
        <p class=" sidebarcontainhead mb-1" ><b>Status</b></p>
        <p class=" pass">
          <b>
            <span *ngIf="idvDataIdentity.idDocuments[0].verificationResult === 1">Pass</span>
            <span *ngIf="idvDataIdentity.idDocuments[0].verificationResult !== 1">Fail</span>
          </b>
        </p>
      </div>
    </div>
    </div>
    <div class="row">
    <div class="col-md-5 mt-4">
      <p class="mb-0"><i class="fas fa-check-circle"></i><b class="detail-check-text">Passport ( {{ idvDataIdentity.idDocuments[0].country }} )  -</b><span class="detail-text-pass text-success"> Pass </span></p>
      <p class="text-primary mt-2" style="font-size: 12px;">View detailed report</p>
      <!-- <img class="passportimg mt-5" src=" ../../../../assets/img/AKcess IDverification 4 - Bad.png"> -->
      <img *ngIf="Iddocumentimg" [src]="Iddocumentimg" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;">

    </div>
    <div class="col-md-7 mt-4">
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Overall result  - </b><span class="detail-text-pass text-success"> Pass </span></p>
      <p class="detail-text mb-1 ">Overall result of the ID document check.</p>
      
      <p class="mb-0">
        <i class="fas fa-check-circle"></i>
        <b class="detail-check-text">Facial match result-</b>
        <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].facialMatch, 'detail-text-fail': !idvDataIdentity.idDocuments[0].facialMatch}">
          {{ idvDataIdentity.idDocuments[0].facialMatch ? 'Pass' : 'Fail' }}
        </span>
      </p>
            <p class="detail-text mb-1">Checks whether the face on the ID matches the face in the selfie.</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text" >Visual analysis -</b>
        <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].visualAnalysisPassed, 'detail-text-fail': !idvDataIdentity.idDocuments[0].visualAnalysisPassed}">
          {{ idvDataIdentity.idDocuments[0].visualAnalysisPassed ? 'Pass' : 'Fail' }}
        </span></p>
      <p class="detail-text mb-1">Checks whether the document is a valid Government-issued ID document.</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Name match result-</b>
        <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].nameCheckMatched, 'detail-text-fail': !idvDataIdentity.idDocuments[0].nameCheckMatched}">
          {{ idvDataIdentity.idDocuments[0].nameCheckMatched ? 'Pass' : 'Fail' }}
        </span></p>
      <p class="detail-text mb-1">Checks whether the name on the ID matches the name on the invite.</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">NFC result -</b>
        <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].nfcCheckPassed, 'detail-text-fail': !idvDataIdentity.idDocuments[0].nfcCheckPassed}">
          {{ idvDataIdentity.idDocuments[0].nfcCheckPassed ? 'Pass' : 'Fail' }}
        </span></p>
      <!-- <p class="detail-text mb-1">check whethere the face on ID matches face in the selfie</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Not performed-</b>
        <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].nameCheckMatched, 'detail-text-fail': !idvDataIdentity.idDocuments[0].nameCheckMatched}">
          {{ idvDataIdentity.idDocuments[0].nameCheckMatched ? 'Pass' : 'Fail' }}
        </span></p> -->
      <p class="detail-text mb-1">Indicates whether we were able to complete an NFC read on the passport.</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Age validation result-</b>
        <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].ageValidation, 'detail-text-fail': !idvDataIdentity.idDocuments[0].ageValidation}">
          {{ idvDataIdentity.idDocuments[0].ageValidation ? 'Pass' : 'Fail' }}
        </span></p>
      <p class="detail-text mb-1">Checks whether the age calculated from the document is equal to or greater than the minimum accepted age.
        Police record</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text"> Police record-</b>
         <span [ngClass]="{'detail-text-pass': !idvDataIdentity.idDocuments[0].policeRecordAlert, 'detail-text-fail': idvDataIdentity.idDocuments[0].policeRecordAlert}">
          {{ idvDataIdentity.idDocuments[0].ageValidation ? 'Fail' : 'Pass' }}
        </span>
         </p>
      <p class="detail-text mb-1">Checks whether the document has been identified as lost, stolen or otherwise compromised.</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Compromise alert -</b>
        <span [ngClass]="{'detail-text-pass': !idvDataIdentity.idDocuments[0].compromisedAlert, 'detail-text-fail': idvDataIdentity.idDocuments[0].compromisedAlert}">
          {{ idvDataIdentity.idDocuments[0].compromisedAlert ? 'Fail' : 'Pass' }}
        </span></p>
      <p class="detail-text mb-1">Asserts whether the document is publicly available as compromised.</p>
      
      <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Repeat attempts -</b>
        <span [ngClass]="{'detail-text-pass': !idvDataIdentity.idDocuments[0].repeatAttemptAlert, 'detail-text-fail': idvDataIdentity.idDocuments[0].repeatAttemptAlert}">
          {{ idvDataIdentity.idDocuments[0].repeatAttemptAlert ? 'Fail' : 'Pass' }}
        </span></p>
      <p class="detail-text mb-1">Asserts whether the document has been reused in a suspicious way.</p>
      
      <p class="font-bold mb-0 mt-2" style="font-size: 14px;">Notes</p>
      <p style="font-size: 12px;">No notes have been added yet.</p>
  
      <div class="addnote"> + Add note</div>
    </div>
    </div>
    
    </ng-container>
  <!-- end of if status is true 1 pass -->

  <ng-container *ngIf="selectedItem.status === 2 && selectedItem.name == 'ID verification'">
    <div class="idverification" style="background: #e5f2ff;  padding: 10px; margin-top: 30px;">
      <h6 class="sidebarheading">ID Document Verification</h6>
      <p class="sidebartexthead">We ask the individual to take a photograph of their biometric document and then use sophisticated image analysis to detect any tampering or manipulation.
      </p>
      <div class="mt-3 row">
        <div class="col-md-3">
          <p class="sidebarcontainhead mb-1" ><b>Requested by</b></p>
          <p style="font-size: 14px;color: #000000de;">{{ userDetailss.data.firstName }}</p>
        </div>
        <div class="col-md-3">
          <p class="sidebarcontainhead mb-1" ><b>Uploaded by</b></p>
          <p style="font-size: 14px;color: #000000de;"> {{ userDetailss.data.firstName }}</p>
        </div>
        <div class="col-md-3">
          <p class="sidebarcontainhead mb-1" ><b>Uploaded on</b></p>
          <p style="font-size: 14px;color: #000000de;"> {{ formatDate(selectedItem.requestedDate) }}</p>
        </div>
        <div class="col-md-3">
          <p class="sidebarcontainhead mb-1" ><b>Status</b></p>
          <p class="pass request" style="color: #000000de;"><b>Requested</b></p>
        </div>
      </div>
      </div>
  </ng-container>



  </div>
  
  
  
      <div *ngSwitchCase="'info'">
        <div class="row mt-5 border-btm">
          <div class="col-md-4">
            <p style="font-size: 12px;">Submit By</p>
            <p></p>
  
            <p style="font-size: 12px;">Attempted By user</p>
            <p style="font-size: 14px;">{{ selectedItem.attempts}}</p>
  
          </div>
          <div class="col-md-4">
            <p style="font-size: 12px;">Requested By</p>
            <p style="font-size: 14px;">{{ selectedItem.requestedBy}}</p>
  
            <p style="font-size: 12px;">Validation Required</p>
            <p style="font-size: 14px;"><i class="fa fa-times-circle-o"></i> No</p>
  
          </div>
          <div class="col-md-4">
            <p style="font-size: 12px;">Date Of Request</p>
            <p style="font-size: 14px;">{{ formatDate(selectedItem.requestedDate) }}</p>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="'settings'">
        
      </div>
      <div *ngSwitchCase="'archive'">
        
      </div>
    </div>

     <!-- Sidebar Sanctions and PEPs content -->
     <div class=" pt-2" *ngIf="sidebarContent">
     
      <form class="pep"  *ngIf="selectedItem.status !== 1 && selectedItem.status !== 3 && selectedItem && selectedItem.name === 'Sanctions and PEPs'">
        <div class="forms-data">
        <div class="form-group mb-3">
          <label for="firstName">First Names*</label>
          <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName">
        </div>
        <div class="form-group mb-3">
          <label for="middleName">Middle Name</label>
          <input type="text" class="form-control" id="middleName" [(ngModel)]="middleName" name="middleName">
        </div>
        <div class="form-group mb-3">
          <label for="lastName">Last Name*</label>
          <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName">
        </div>
        <div class="form-group mb-3 ">
          <label for="nameMatching">Name matching*</label>
          <select class="form-control" id="nameMatching" [(ngModel)]="nameMatching" name="nameMatching">
            <option>Exact</option>
            <option>Fuzzy</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label for="dobDay">Date of Birth</label>
          <div class="d-flex align-items-center">
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label>
              <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobDay" placeholder="DD" [(ngModel)]="dobDay" name="dobDay">
            </div>
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobMonth " style="font-weight: bold;">Month</label>
              <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobMonth" placeholder="MM" [(ngModel)]="dobMonth" name="dobMonth">
            </div>
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label>
              <input style="width: 80px;" type="text" class="form-control mx-0" id="dobYear" placeholder="YYYY" [(ngModel)]="dobYear" name="dobYear">
            </div>
          </div>
        </div>
        <div class="form-group mb-3 ">
          <label  for="dobMatching">Date of birth matching*</label>
          <select class="form-control" id="dobMatching" [(ngModel)]="dobMatching" name="dobMatching">
            <option>None</option>
            <option>Exact</option>
            <option>Same year</option>
          </select>
        </div>
        
          <!-- <div class="form-group mb-3 toggle" *ngIf="sanctionOptions.length > 0">
            <div class="d-flex mt-2" *ngFor="let option of sanctionOptions; let i = index">
              <input type="checkbox" id="toggle{{i}}" [(ngModel)]="option?.checked" (change)="onToggleChange(option,i)" />
              <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
              <span class="toggle-label-text" style="font-weight: 500;">{{ option.label }}</span>
            </div>
          </div> -->
          <!-- <div class="form-group mb-3 toggle">
            <div class="d-flex mt-2" *ngFor="let optionss of sanctionOptions; let i = index">
              <input type="checkbox" id="toggle{{i}}" [(ngModel)]="optionss.checked" (change)="onToggleChange(optionss,i)" />
              <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
              <span class="toggle-label-text" style="font-weight: 500;">{{ optionss.label }}</span>
            </div>
          </div> -->

          <!-- <div class="d-flex mt-2" *ngFor="let option of sanctionOptions; let i = index">
            <input 
              type="checkbox" 
              id="toggle{{i}}" 
              name="option-{{i}}" 
              [(ngModel)]="option.checked" 
              (change)="onToggleChange(option, i)" 
            />
            <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
            <span class="toggle-label-text" style="font-weight: 500;">{{ option.label }}</span>
          </div> -->
          <div class="form-group mb-3 toggle">
          <div class="d-flex mt-2" *ngFor="let option of sanctionOptions; let i = index">
            <input type="checkbox" id="toggle{{i}}"  name="option-{{i}}" [(ngModel)]="option.checked" [ngModelOptions]="{standalone: option.checked}" (change)="onToggleChange(option,i)" />
            <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
            <span class="toggle-label-text" style="font-weight: 500;">{{ option.label }}</span>
          </div>
          </div>
          
          <div class="form-group mb-3">
            <label for="filter">PEP tiers to filter on</label>
            <div class="custom-dropdown">
              <div class="dropdown-toggle" (click)="peptoggleDropdown()">
                {{ getSelectedTiers() }}
              </div>
              <div class="dropdown-menu" [ngClass]="{'show': dropdownOpen}">
                <div class="form-check" *ngFor="let tier of tiers">
                 
                  <input class="form-check-input" type="checkbox" [id]="tier" [value]="tier" [(ngModel)]="tierSelection[tier]" (change)="onCheckboxChange(tier, $event)">
                  <label class="form-check-label" [for]="tier">{{ tier }}</label>
                </div>
              </div>
            </div>
          </div>
          
       
       
        <div class="form-group mb-3">
          <div class="d-flex" >
            <input type="checkbox"/>
            <label class="toggle-label toggle-switch" ></label>
            <span class="toggle-label-text"   style="font-weight: 500;">Add to Watch list (on-going monitoring)</span>
          </div>
        </div>
      </div>

      <div class="check-footer">
        <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheckSencation()">Run Data Check</button>
        <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
      </div>
      </form>
     
    </div>
     <!--End Sidebar Sanctions and PEPs content -->



    <!-- Sidebar Address and Mortality content -->

    <div class="mt-2" *ngIf="sidebarContent">
      <div  *ngIf="selectedItem && selectedItem.name === 'Address and Mortality'">   
      <form>
         <ul class="selectaddress" *ngIf="!showadressForm">
        <li *ngFor="let item of filteredListAddressandMortality" (click)="addressopenclick()">
          <a>
            <i class="fa fa-bank mr-2"></i>
            {{ item.name }}<span *ngIf="item.isDefault"></span>
          </a>
        </li>
        </ul>
        <!-- <ul class="selectaddress" *ngIf="!showadressForm">
          <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i>Standard Check (Default)</a></li>
          <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> AML Check v2</a></li>
          <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> UK KYC</a></li>
          <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> Enhanced AML</a></li>
          <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> AML check v3</a></li>
          <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i>UK AML</a></li>
        </ul> -->
      </form>
  
      <form *ngIf="showadressForm">
        <!-- <div class="form-group mb-3">
          <label for="firstName">First Name*</label>
          <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName">
        </div>
        <div class="form-group mb-3">
          <label for="middleName">Middle Name</label>
          <input type="text" class="form-control" id="middleName" [(ngModel)]="middleName" name="middleName">
        </div>
        <div class="form-group mb-3">
          <label for="lastName">Last Name*</label>
          <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName">
        </div>
        
        <div class="form-group mb-3">
          <label for="dobDay">Date of Birth</label>
          <div class="d-flex align-items-center">
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label>
              <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobDay" placeholder="DD" [(ngModel)]="dobDay" name="dobDay">
            </div>
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobMonth " style="font-weight: bold;">Month</label>
              <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobMonth" placeholder="MM" [(ngModel)]="dobMonth" name="dobMonth">
            </div>
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label>
              <input style="width: 80px;" type="text" class="form-control mx-0" id="dobYear" placeholder="YYYY" [(ngModel)]="dobYear" name="dobYear">
            </div>
          </div>
        </div>
        <div class="form-group mb-3">
          <label  for="dobMatching">Date of birth matching*</label>
          <select class="form-control" id="dobMatching" [(ngModel)]="dobMatching" name="dobMatching">
            <option>Same year</option>
            <option>Exact date</option>
          </select>
        </div>
       
        <div class="form-group mb-3">
          <label for="addressSearch">Address</label>
          <p style="font-size: 12px;color: #000;">Serach using a UK Postcode, or <span class="text-warning">enter manually</span></p>
          <div class="d-flex">
          <input type="text" class="form-control" id="addressSearch" placeholder="Search using a postcode" [(ngModel)]="addressSearch" name="addressSearch" >
          <button style="border-radius: 20px;" type="button" class="btn btn-secondary ml-3 btn-sm" (click)="runDataCheck()" [disabled]="!addressSearch.trim()">Search</button>
        </div>
        </div>
         -->
         <div class="form-group mb-3">
          <label for="firstName">First Name*</label>
          <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName">
        </div>
        <div class="form-group mb-3">
          <label for="middleName">Middle Name</label>
          <input type="text" class="form-control" id="middleName" [(ngModel)]="middleName" name="middleName">
        </div>
        <div class="form-group mb-3">
          <label for="lastName">Last Name*</label>
          <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName">
        </div>
        <div class="form-group mb-3">
          <label for="dobDay">Date of Birth</label>
          <div class="d-flex align-items-center">
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label>
              <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobDay" placeholder="DD" [(ngModel)]="dobDay" name="dobDay">
            </div>
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobMonth" style="font-weight: bold;">Month</label>
              <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobMonth" placeholder="MM" [(ngModel)]="dobMonth" name="dobMonth">
            </div>
            <div class="dob-label d-flex flex-column mr-2">
              <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label>
              <input style="width: 80px;" type="text" class="form-control mx-0" id="dobYear" placeholder="YYYY" [(ngModel)]="dobYear" name="dobYear">
            </div>
          </div>
        </div>
        <div class="form-group mb-3">
          <label  for="dobMatching">Date of birth matching*</label>
          <select class="form-control" id="dobMatching" [(ngModel)]="dobMatching" name="dobMatching">
            <option>Same year</option>
            <option>Exact date</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label for="addressSearch">Address</label>
          <p style="font-size: 12px;color: #000;">Search using a UK Postcode, or <span class="text-warning">enter manually</span></p>
          <div class="d-flex">
            <input type="text" class="form-control" id="addressSearch" placeholder="Search using a postcode" [(ngModel)]="addressSearch" name="addressSearch">
            <button style="border-radius: 20px;" type="button" class="btn btn-secondary ml-3 btn-sm" (click)="runDataChecks()" [disabled]="!addressSearch.trim()">Search</button>
          </div>
        </div>
      
        <div *ngIf=" addresses.length > 0" class="dropdown mb-3">
          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Select Address
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" *ngFor="let address of addresses" (click)="selectAddress(address)">
              {{ address.addressLine1 }}, {{ address.addressLine2 }}, {{ address.town }}, {{ address.county }}, {{ address.postcode }}
            </a>
          </div>
        </div>
      
        <div *ngIf="selectedAddresss">
          <div class="form-group">
            <label for="addressLine1">Address Line 1</label>
            <input type="text" class="form-control" id="addressLine1" name="addressLine1" [(ngModel)]="selectedAddresss.addressLine1">
          </div>
          <div class="form-group">
            <label for="addressLine2">Address Line 2</label>
            <input type="text" class="form-control" id="addressLine2" name="addressLine2" [(ngModel)]="selectedAddresss.addressLine2">
          </div>
          <div class="form-group">
            <label for="town">Town</label>
            <input type="text" class="form-control" id="town" name="town" [(ngModel)]="selectedAddresss.town">
          </div>
          <div class="form-group">
            <label for="county">County</label>
            <input type="text" class="form-control" id="county" name="county" [(ngModel)]="selectedAddresss.county">
          </div>
          <div class="form-group">
            <label for="postcode">Postcode</label>
            <input type="text" class="form-control" id="postcode" name="postcode" [(ngModel)]="selectedAddresss.postcode">
          </div>
        </div>
      
        <div class="form-group mb-3">
          <label for="bankaccountsortcode">Bank Account Sort Code*</label> 
          <input type="number" class="form-control" id="bankaccountsortcode" [(ngModel)]="bankaccountsortcode" name="bankaccountsortcode">
        </div>        
        <div class="form-group mb-3">
          <label for="bankaccountnumber">Bank Account Number*</label> 
          <input type="number" class="form-control" id="bankaccountnumber" [(ngModel)]="bankaccountnumber" name="bankaccountnumber">
        </div>
      
        <div class="check-footer">        
            <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheckAddressMortaility()">Run Data Check</button>
            <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
 <!-- End of Sidebar Address and Mortality content -->


   
 

    <!-- Sidebar Source of found content -->

    <div class="mt-5 pt-5" *ngIf="sidebarContent">
      <!-- <h4>{{ sidebarTitle }}</h4> -->
      <form *ngIf="selectedItem && selectedItem.name === 'Source of Funds'">
<!--        
          <div class="check-footer">        
              <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheck()">Run Data Check</button>
              <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
          </div> -->
          <!-- <div class="check-footer">
            <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Complete Now</button>
          </div> -->
      </form>
    </div>
    <!-- End Sidebar Source of found content -->
     
      <!-- Sidebar Open Banking content -->       
      <div class="mt-5 pt-5 sidebar2">
        <div *ngIf="sidebarContent">
         <form *ngIf="selectedItem && selectedItem.name === 'Open Banking'">
   <!--        
             <div class="check-footer">        
                 <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheck()">Run Data Check</button>
                 <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
             </div> -->
             <!-- <div class="check-footer">
               <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Complete Now</button>
             </div> -->
         </form>
       </div>
       </div>
      <!-- end Sidebar Open Banking content -->

    <div  *ngIf="sidebarPersonalDetailsVisible" class="sidebar"  >
   
      <div class="sidebar-header">
        <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
          <span aria-hidden="true">&times;</span>
        </button>  
        
        <div class="d-flex flex-column" >
          <div class="d-flex">
            <i class="fa fa-video" style="font-size: 30px;"></i>
            <h4 class="ml-2 mt-1"><b>asdasdasd</b></h4>
          </div>     
            <p class="mt-4" *ngIf="selectedItem && selectedItem.name === 'Source of Funds'">Select the Source of Funds journey that you would like {{ userDetailss.data.firstName }} to complete...</p>    
           
          </div>
          <div class="pdf" >
            <button type="button" class="btn btn-dark"><i class='fas fa-file-pdf'></i>View PDF</button>
          </div>
      </div>
      
    </div>


  </div>

<!--End of  Sidebar -->
<!-- start view full report --> 
<div class="sidebar3"   [class.visible]="opensidebarviewfullreport">
  <div class="sidebarview-header">
    <!-- <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
      <span aria-hidden="true">&times;</span>
    </button> -->
    <div class="d-flex flex-column">
      <div class="d-flex row">
        <i class="fa fa-chevron-left" style=" font-size: 24px; color: #263238;" (click)="closeSidebar()"></i>
        <div class="column">
        <h4 class="ml-2 mt-1"><b>{{sancationandpepsDCinnerData?.firstName}}  {{sancationandpepsDCinnerData?.middleName}} {{sancationandpepsDCinnerData?.lastName}}</b></h4>
        <p style="font-size: 12px;">ID {{ sancationandpepsDCinnerData?.resourceId}}</p>
      </div>
      <i class="actionrequiredicon fa fa-exclamation-circle" style="color: #efaf41;"></i>
      <div class="actionrequired">Action Required</div>
      </div>
    </div>
    <ul class="nav nav-tabss">
      <li class="nav-item active" (click)="setActiveTabbs('Profile')" [class.active]="activeTabbs === 'Profile'">
        <a class="nav-link">Profile</a>
      </li>
      <li class="nav-item" (click)="setActiveTabbs('PEPs')" [class.active]="activeTabbs === 'PEPs'">
        <a class="nav-link">PEPs</a>
      </li>
      <li class="nav-item" (click)="setActiveTabbs('Sanctions')" [class.active]="activeTabbs === 'Sanctions'">
        <a class="nav-link">Sanctions</a>
      </li>
      <li class="nav-item" (click)="setActiveTabbs('Law enforcement')" [class.active]="activeTabbs === 'Law enforcement'">
        <a class="nav-link">Law enforcement</a>
      </li>
      <li class="nav-item" (click)="setActiveTabbs('Adverse media')" [class.active]="activeTabbs === 'Adverse media'">
        <a class="nav-link">Adverse media</a>
      </li>
      <li class="nav-item" (click)="setActiveTabbs('Disqualified director')" [class.active]="activeTabbs === 'Disqualified director'">
        <a class="nav-link">
          Disqualified director</a>
      </li>
      <li class="nav-item" (click)="setActiveTabbs('Notes')" [class.active]="activeTabbs === 'Notes'">
        <a class="nav-link">
          Notes</a>
      </li>
    </ul>
  </div>
  
  <div [ngSwitch]="activeTabbs"  >
    <div *ngSwitchCase="'Profile'" data-content="Profile" >
      
      <div class="sidebar-content-fullview row mt-5 ">
        <div class="card-body" *ngIf="sancationandpepsDCinnerData?.contactDetails && sancationandpepsDCinnerData.contactDetails.length > 0">
          <div class="row">
            <div class="col-md-3 mr-0">
          <div class="profile-pictureimg"  >
            <img *ngFor="let imageUrl of sanctionprofileimgURL" [src]="imageUrl" alt="Profile Picture" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;">

            <!-- <img *ngIf="sanctionprofileimgURL.length === 0" [src]="profileImageBlobReference" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;"> -->

            <!-- <img *ngIf="sanctionprofileimgURL.length === 0"  src="./../../../assets/img/pm-modi.jpg" alt="Profile Picture"> -->
          </div>
        </div>
        <div class="col-md-9">
          <div class="row">
            
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Status</p>
              <p class="sidebartexthead"><i class=" fa fa-exclamation-circle" style="color: #efaf41;"></i>Action Required</p>
            </div>
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Updated</p>
              <p class="sidebartexthead">{{ formatDate(sancationandpepsDCinnerData.createdDate) }}</p>
            </div>
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1"></p>
              <p class="sidebartexthead"></p>
            </div>
          </div>
          <div class="row">
            
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Date of birth</p>
              <p class="sidebartexthead">{{ formatDate(sancationandpepsDCinnerData.dateOfBirth) }}</p>
            </div>
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Gender</p>
              <p class="sidebartexthead">{{ sancationandpepsDCinnerData.gender === 1 ? 'Male' : 'Female'}}</p>
            </div>
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Nationality</p>
              <p class="sidebartexthead">{{ sancationandpepsDCinnerData.nationalities }}</p>
            </div>
          </div>
  
          <div class="border-bottom"></div>
          <div class="row mt-3">
            <div class="col profile-details">
             <p class="sidebarcontainhead mb-1">Sanctions</p> 
              <p class="sidebartexthead">{{ sancationandpepsDCinnerData.sanctions.length === 0 ? '-': sancationandpepsDCinnerData.sanctions}}</p>
            </div>
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">PEP level</p> 
              
              <p  class="sidebartexthead">{{ sancationandpepsDCinnerData.pepTier}}</p>
            </div>
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Is PEP</p>
              <p  class="sidebartexthead">{{ sancationandpepsDCinnerData.pepTier.length === 0 ? 'No':'Yes'}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Adverse media</p>
              <p class="sidebartexthead">-</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Law enforcement</p>
              <p class="sidebartexthead">{{ sancationandpepsDCinnerData.regulatoryEnforcements.length === 0 ? '-' : sancationandpepsDCinnerData.regulatoryEnforcements}} </p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Disqualified director</p>
              <p class="sidebartexthead">{{ sancationandpepsDCinnerData.disqualifiedDirectors.length === 0 ? '-' : sancationandpepsDCinnerData.disqualifiedDirectors}}</p>
            </div>
          </div>
          <div class="border-bottom"></div>
          <div class="row mt-3">
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">First name</p>
              <p class="sidebartexthead">{{sancationandpepsDCinnerData.firstName}}  </p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Middle name(s)</p>
              <p class="sidebartexthead">{{sancationandpepsDCinnerData.middleName}} </p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Last name</p>
              <p class="sidebartexthead">{{sancationandpepsDCinnerData.lastName}}</p>
            </div>
          </div>
          <div class="row ">
            <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Is deceased</p>
              <p class="sidebartexthead">{{sancationandpepsDCinnerData.isDeceased}}</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Date of death</p>
              <p class="sidebartexthead">-</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1"></p>
              <p class="sidebartexthead"></p>
            </div>
          </div>
          <div class="border-bottom"></div>
          
          <div class="column">
            <div class="mt-3 viewformhead">Contact details</div>
            <div *ngIf="sancationandpepsDCinnerData?.contactDetails && sancationandpepsDCinnerData.contactDetails.length > 0">
            <div class="col profile-details" *ngFor="let detail of sancationandpepsDCinnerData.contactDetails ">
              <ng-container [ngSwitch]="detail.category">
                <p class="sidebarcontainhead mb-1" *ngSwitchCase="1">Telephone</p>
                <p class="sidebarcontainhead mb-1" *ngSwitchCase="2">Fax</p>
                <p class="sidebarcontainhead mb-1" *ngSwitchCase="3">Mobile</p>
                <p class="sidebarcontainhead mb-1" *ngSwitchCase="4">Email</p>
                <p class="sidebarcontainhead mb-1" *ngSwitchDefault>Unknown</p>
                <p class="sidebartexthead">{{ detail.value }}</p>
              </ng-container>
            </div>
            </div>
            <!-- <div class="col profile-details">
              <p class="sidebarcontainhead mb-1">Fax</p>
              <p class="sidebartexthead">+91 01123010233, +91 011 23016857</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Email</p>
              <p class="sidebartexthead">n_modi2012@yahoo.com, narendramodi@narendramodi.in</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Telephone</p>
              <p class="sidebartexthead">+91 01123018939 , +91 01123012312, +91 011 23017660</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Mobile</p>
              <p class="sidebartexthead">+91 8980809224</p>
            </div> -->
          </div>
          <div class="border-bottom"></div>
          
          <div class="column">
            <div class="viewformhead mt-3">Identifiers</div>
            <div class="col profile-details" *ngFor="let identifiers of sancationandpepsDCinnerData.identifiers ">
              <p class="sidebarcontainhead mb-1">{{ identifiers.category}}</p>
              <p class="sidebartexthead">{{ identifiers.value}}</p>
            </div>
          </div>
          <div class="border-bottom"></div>
          <div class="column">
            <div class="viewformheadb mt-3">Addresses</div>
            <div *ngIf="sancationandpepsDCinnerData?.addresses && sancationandpepsDCinnerData.addresses.length > 0">
            <div class="col profile-details"  *ngFor="let address of sancationandpepsDCinnerData.addresses">
              <!-- <p class="sidebarcontainhead mb-1">Place of birth</p> -->
              <ng-container [ngSwitch]="address.addressType">
              <p class="sidebarcontainhead mb-1" *ngSwitchCase="1">Place of Birth</p>
              <p class="sidebarcontainhead mb-1" *ngSwitchCase="2">Residential</p>
              <p class="sidebarcontainhead mb-1" *ngSwitchCase="3">Business</p>
              <p class="sidebarcontainhead mb-1" *ngSwitchDefault>Unknown</p>
              <!-- <p class="sidebartexthead">Vadnagar, Mahesana, Gujarat</p> -->
              <p class="sidebartexthead">
                {{ address.line1 }} {{ address.line2 }} {{ address.city }}, {{ address.county }}  {{ address.postcode }} {{ address.countryIsoCode }}
              </p>
              </ng-container>
            </div>
            </div>
            <!-- <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Residential</p>
              <p class="sidebartexthead">7 Lok Kalyan Marg, New Delhi, Delhi, 110 011</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Business</p>
              <p class="sidebartexthead">Prime Minister's Office, South Block, Raisina Hill, New Delhi, Delhi, 110011</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Residential</p>
              <p class="sidebartexthead">C-1, Someshwar Tenament Ranip, Ahmedabad, Gujarat, 382480
              </p>
            </div> -->
          </div>
          <div class="border-bottom"></div>
          <div class="column">
            <div class=" viewformhead mt-3">Aliases</div>
            <div *ngIf="sancationandpepsDCinnerData?.aliases && sancationandpepsDCinnerData.aliases.length > 0">

            <div class="col profile-details" *ngFor="let name of sancationandpepsDCinnerData.aliases">
              <ng-container [ngSwitch]="name.type">
                <p class="sidebarcontainhead mb-1" *ngSwitchCase="1">Original Script Name</p>
                <p class="sidebarcontainhead mb-1" *ngSwitchCase="2">Name Spelling Variation</p>
                <p class="sidebarcontainhead mb-1" *ngSwitchCase="3">Shortened Name</p>
                <p class="sidebarcontainhead mb-1" *ngSwitchDefault>Unknown</p>
                
                <p class="sidebartexthead">
                  {{ name.firstName }} {{ name.middleName ? name.middleName + ' ' : '' }} {{ name.lastName }}
                </p>
              </ng-container>
            </div>
            </div>
            <!-- <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Name spelling variation</p>
              <p class="sidebartexthead">Modi Damodardas Narendra</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Original script name</p>
              <p class="sidebartexthead">नरेंद्र दामोदरदास मोदी</p>
            </div>
            <div class="col profile-details">
              
              <p class="sidebarcontainhead mb-1">Name spelling variation</p>
              <p class="sidebartexthead">N D Modi</p>
            </div> -->
          </div>
        </div>
        </div>
        </div>
    </div>
     
   </div>
   <div *ngSwitchCase="'PEPs'" data-content="PEPs" >
    <div class="sidebar-content-fullview row mt-5" >  
      <ul class="nav nav-tabss">
        <li class="nav-item active" (click)="setActiveTabspr('Current PEPs')" [class.active]="activeTabspr === 'Current PEPs'">
          <a class="nav-link">Current PEPs</a>
        </li>
        <li class="nav-item" (click)="setActiveTabspr('Former PEPs')" [class.active]="activeTabspr === 'Former PEPs'">
          <a class="nav-link">Former PEPs</a>
        </li>
        <li class="nav-item" (click)="setActiveTabspr('Associated PEPs')" [class.active]="activeTabspr === 'Associated PEPs'">
          <a class="nav-link">Associated PEPs</a>
        </li>
      </ul>
</div>
<div [ngSwitch]="activeTabspr"  >
  <div *ngSwitchCase="'Current PEPs'" data-content="Current PEPs" >
    <div class="sidebar-content row mt-0 ml-3" *ngIf="sancationandpepsDCinnerData.peps && sancationandpepsDCinnerData.peps.length > 0">
      <!-- one -->
      <div class="main-section" *ngFor="let peps of sancationandpepsDCinnerData.peps" >
        <div *ngIf="peps.isCurrent">
        <div class="row">
          <div class="col-md-4">
            <div class="section">
            <div class="font-bold" style="font-size: 12px;">Country</div>
            <p>{{ peps.countryIsoCode }}</p>
           </div>
           </div>
          <div class="col-md-4">
            <div class="section">
              <div class="font-bold" style="font-size: 12px;">Date From</div>
              <p> {{ peps?.dateFrom }} </p>
          </div>
            </div>
          <div class="col-md-4">
            <div class="section">
              <div class="font-bold" style="font-size: 12px;">Date to</div>
              <p> {{ peps?.dateTo }}</p>
          </div>
          </div>
          </div>
          <div class="section">
            <div class="font-bold" style="font-size: 12px;">Position</div>
            <p>{{ peps.position }}</p>
        </div>
        <div class="section">
            <div class="font-bold" style="font-size: 12px;">Segment</div>
            <p>{{ peps.segment}}</p>
        </div>
      <div class="section evidence" *ngFor="let evidences of peps.evidences" >
            <h5 class="font-bold text-dark">Evidence</h5>
            <div class="font-bold" style="font-size: 12px;">Title:</div> 
          <div class="font-bold" style="font-size: 12px;">summary</div>
        <p>{{ evidences.title }}  </p>
        <div class="row">
          <div class="col-md-4">
           <div class="font-bold" style="font-size: 12px;">ID:</div> 
           <p>{{ evidences.sourceEvidenceId }} </p>
          </div>
          <div class="col-md-4">
            <div class="font-bold" style="font-size: 12px;">Is copyrighted:</div>
            <p> {{ evidences.isCopyrighted }} </p>
          </div>
          <div class="col-md-4">
           <div class="font-bold" style="font-size: 12px;">Credibility:</div>
           <p> {{ evidences.credibility }} </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="font-bold" style="font-size: 12px;">Language:</div>
            <p> {{ evidences.language }} </p>
          </div>
          <div class="col-md-4">
            <div class="font-bold" style="font-size: 12px;">Capture date:</div> 
            <p> {{ evidences.captureDate }} </p>
          </div>
          <div class="col-md-4">
            <div class="font-bold" style="font-size: 12px;">Publication date:</div>
            <p> {{ evidences.publicationDate }} </p>
          </div>
        </div>
       <div class="font-bold" style="font-size: 12px;">Original URL:</div> 
       <a [href]="evidences.originalUrl" target="_blank">{{ evidences.originalUrl }}</a>
       <!-- <button class="btn btn-secondary" style="border-radius: 20px; background: #606060;color: #fff;">View-file</button> -->
       </div>
        <div class="border-bottom mb-3"></div>
      </div>
    </div>
    </div>
 </div>
 <div *ngSwitchCase="'Former PEPs'" data-content="Former PEPs" >
  <div class="sidebar-content row mt-1 ml-3"   *ngIf="sancationandpepsDCinnerData.peps && sancationandpepsDCinnerData.peps.length > 0">
  <!-- one -->
  <div class="main-section" *ngFor="let peps of sancationandpepsDCinnerData.peps">
    <div *ngIf="peps.isCurrent === false">
      <div class="row">
        <div class="col-md-4">
          <div class="section">
          <div class="font-bold" style="font-size: 12px;">Country</div>
          <p>{{ peps.countryIsoCode }}</p>
         </div>
         </div>
        <div class="col-md-4">
          <div class="section">
            <div class="font-bold" style="font-size: 12px;">Date From</div>
            <p> {{ peps?.dateFrom }} </p>
        </div>
          </div>
        <div class="col-md-4">
          <div class="section">
            <div class="font-bold" style="font-size: 12px;">Date to</div>
            <p> {{ peps?.dateTo }}</p>
        </div>
        </div>
        </div>
        <div class="section">
          <div class="font-bold" style="font-size: 12px;">Position</div>
          <p>{{ peps.position }}</p>
      </div>
      <div class="section">
          <div class="font-bold" style="font-size: 12px;">Segment</div>
          <p>{{ peps.segment}}</p>
      </div>
    <div class="section evidence" *ngFor="let evidences of peps.evidences" >
          <h5 class="font-bold text-dark">Evidence</h5>
          <div class="font-bold" style="font-size: 12px;">Title:</div> 
        <div class="font-bold" style="font-size: 12px;">summary</div>
      <p>{{ evidences.title }}  </p>
      <div class="row">
        <div class="col-md-4">
         <div class="font-bold" style="font-size: 12px;">ID:</div> 
         <p>{{ evidences.sourceEvidenceId }} </p>
        </div>
        <div class="col-md-4">
          <div class="font-bold" style="font-size: 12px;">Is copyrighted:</div>
          <p> {{ evidences.isCopyrighted }} </p>
        </div>
        <div class="col-md-4">
         <div class="font-bold" style="font-size: 12px;">Credibility:</div>
         <p> {{ evidences.credibility }} </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="font-bold" style="font-size: 12px;">Language:</div>
          <p> {{ evidences.language }} </p>
        </div>
        <div class="col-md-4">
          <div class="font-bold" style="font-size: 12px;">Capture date:</div> 
          <p> {{ evidences.captureDate }} </p>
        </div>
        <div class="col-md-4">
          <div class="font-bold" style="font-size: 12px;">Publication date:</div>
          <p> {{ evidences.publicationDate }} </p>
        </div>
      </div>
     <div class="font-bold" style="font-size: 12px;">Original URL:</div> 
     <a [href]="evidences.originalUrl" target="_blank">{{ evidences.originalUrl }}</a>
     <!-- <button class="btn btn-secondary" style="border-radius: 20px; background: #606060;color: #fff;">View-file</button> -->
     </div>
    <div class="border-bottom mb-3"></div>
</div>
  </div>
  
    
    
</div>
</div>
<div *ngSwitchCase="'Associated PEPs'" data-content="Associated PEPs" >
  <div class="sidebar-content row mt-1 ml-3">
  <p class="viewfullcontain">None found</p>
</div>
</div>

 </div>
  </div>
  <div *ngSwitchCase="'Sanctions'" data-content="Sanctions">
    <div class="sidebar-content-fullview row mt-5" > 
      <ul class="nav nav-tabss">
        <li class="nav-item active" (click)="setActiveTabsin('Current sanctions')" [class.active]="activeTabsin === 'Current sanctions'">
          <a class="nav-link">Current sanctions</a>
        </li>
        <li class="nav-item" (click)="setActiveTabsin('Former sanctions')" [class.active]="activeTabsin === 'Former sanctions'">
          <a class="nav-link">Former sanctions</a>
        </li>
      </ul>
    
    </div>
    <div [ngSwitch]="activeTabsin"  >
      <div *ngSwitchCase="'Current sanctions'" data-content="Current sanctions" >
        <div class="sidebar-content row mt-1 ml-3">
        <p class="viewfullcontain">None found</p>
      </div>
     </div>
     <div *ngSwitchCase="'Former sanctions'" data-content="Former sanctions" >
      <div class="sidebar-content row mt-1 ml-3">
      <p class="viewfullcontain">None found</p>
    </div>
   </div>

     </div>
      
    </div>
 
  <div *ngSwitchCase="'Law enforcement'" data-content="Law enforcement">
    <div class="sidebar-content-fullview row mt-5 ">
      <p class="viewfullcontain">To see more information, please rerun your check and include Law enforcement.
      </p>
    </div>
  </div>
  <div *ngSwitchCase="'Adverse media'" data-content="Adverse media">
    <div class="sidebar-content-fullview row mt-5 ">
      <p class="viewfullcontain">To see more information, please rerun your check and include Adverse media.
      </p>
    </div>
  </div>
  <div *ngSwitchCase="'Disqualified director'" data-content="Disqualified director">
    <div class="sidebar-content-fullview row mt-5">
      <p class="viewfullcontain">To see more information, please rerun your check and include Disqualified director.
      </p>
    </div>
  </div>
  <div *ngSwitchCase="'Notes'" data-content="Notes">
    <div class="sidebar-content-fullview row mt-5 ">
      <p class="viewfullcontain">None found.</p>
    </div>
  </div>
</div>
  
  
</div>

<!-- end view full report -->

<!-- Sidebar Liveliness -->
<div  *ngIf="sidebarlivelinessVisible" class="sidebar3" [class.visible]="sidebarlivelinessVisible" >
   
  <div class="sidebarview-header">
    <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
      <span aria-hidden="true">&times;</span>
    </button>  
    
    <div class="d-flex flex-column" >
      <div class="d-flex">
        <i class="fa fa-video" style="font-size: 30px;"></i>
        <h4 class="ml-2 mt-1"><b>{{ selectedItem.name }}</b></h4>
      </div>     
        <p class="mt-4" *ngIf="selectedItem && selectedItem.name === 'Source of Funds'">Select the Source of Funds journey that you would like {{ userDetailss.data.firstName }} to complete...</p>    
       
      </div>
      <div class="pdf" >
        <button type="button" class="btn btn-dark" (click)="viewPDF()"><i class='fas fa-file-pdf'></i>View PDF</button>
      </div>
      
  </div>
  

  <ul class="nav nav-tabs" *ngIf="selectedItem.status !== 5">
    <li class="nav-item" (click)="setActiveTabs('check')" [class.active]="activeTabs === 'check'">
      <a class="nav-link">Check</a>
    </li>
    <li class="nav-item" (click)="setActiveTabs('info')" [class.active]="activeTabs === 'info'" *ngIf="selectedItem.status !== 3" >
      <a class="nav-link">Info</a>
    </li>
    <li class="nav-item" (click)="setActiveTabs('settings')" [class.active]="activeTabs === 'settings'">
      <a class="nav-link">Settings</a>
    </li>
    <li class="nav-item" (click)="setActiveTabs('archive')" [class.active]="activeTabs === 'archive'">
      <a class="nav-link">Archive</a>
    </li>
  </ul>

  <!-- Tab Content -->
   
  <div [ngSwitch]="activeTabs">
    <div *ngSwitchCase="'check'" >
        <!-- if status is true 2 awaiting -->

      <ng-container *ngIf="selectedItem.status === 1 && selectedItem.name == 'Liveness' ">
    <div class="mt-5 border-btm row" >
        <div class="col-md-4">
            <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Date Of Request</p>
            <p class="sidebartexthead">{{ formatDate(selectedItem.requestedDate) }}</p>
        </div>
        <div class="col-md-4">
            <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Status</p>
            <p class="sidebartexthead" ><i class="fa fa-exclamation-circle" style="color: blue;"></i> {{ getStatusText(selectedItem.status) }}
          </p>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="border-btm mt-3">
    <div>
      <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Submited Selfie</p>
      <!-- <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p> -->
      <p *ngIf="!Selfieimg" class="sidebartexthead">
        <i class="fa fa-ellipsis-h"></i> Awaiting Upload
      </p>
      <div class="photo-wrapper portrait">
        <app-image>
          <div  class="full-height">
        <pinch-zoom class="full-height" style="overflow: hidden; background-color: rgba(0, 0, 0, 0.85);">
        <div class="pinch-zoom-content"   style="display: flex; align-items: center; justify-content: center; transform-origin: 0px 0px; transition: all 200ms ease 0s; transform: matrix(1, 0, 0, 1, 0, 0);">
      <img *ngIf="Selfieimg" [src]="Selfieimg" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;">
        </div>
      <div class="pz-zoom-button pz-zoom-control-position-bottom ng-star-inserted"></div>
    </pinch-zoom>
          </div>
        </app-image>
    </div>
    </div>
 
    </div>

   
    </ng-container>
  

    <ng-container *ngIf="selectedItem.status === 2 && selectedItem.name == 'Liveness' ">

      <div class="mt-5 border-btm row" >
          <div class="col-md-4">
              <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Date Of Request</p>
              <p class="sidebartexthead">{{ formatDate(selectedItem.requestedDate) }}</p>
          </div>
          <div class="col-md-4">
              <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Status</p>
              <p class="sidebartexthead"><i class="fa fa-exclamation-circle" style="color: blue;"></i> {{ getStatusText(selectedItem.status) }}
            </p>
          </div>
          <div class="col-md-4"></div>
      </div>
      <div class="border-btm mt-3">
      <div>
        <p style="font-size: 12px;  margin-bottom: 1px; color: #757575">Submited Selfie</p>
        <p *ngIf="!Selfieimg" class="sidebartexthead">
          <i class="fa fa-ellipsis-h"></i> Awaiting Upload
        </p>
        <div class="photo-wrapper portrait">
          <app-image>
          <div  class="full-height">
          <pinch-zoom class="full-height" style="overflow: hidden; background-color: rgba(0, 0, 0, 0.85);">
          <div class="pinch-zoom-content"   style="display: flex; align-items: center; justify-content: center; transform-origin: 0px 0px; transition: all 200ms ease 0s; transform: matrix(1, 0, 0, 1, 0, 0);">
        <img *ngIf="Selfieimg" [src]="Selfieimg" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;">
      </div>
        <div class="pz-zoom-button pz-zoom-control-position-bottom ng-star-inserted"></div>
          </pinch-zoom>
        </div>
      </app-image>
      </div>
      </div>
    <div>
      <p style="font-size: 12px;  margin-bottom: 1px; color: #757575">Passive Liveness Status</p>
      <p style=" font-size: 16px; color: #333;"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p>
  </div>
      </div>
  
      <div class="mt-3">
        <div>
          <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Submited Unique action protocol (UAP)</p>
          <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p>
      </div>
      <div>
        <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Status</p>
        <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>{{ getStatusText(selectedItem.status) }}</p>
    </div>
    <div>
      <p style="font-size: 12px;  margin-bottom: 1px; color: #757575">UPA Status</p>
      <p class="sidebartexthead"><i class="fa fa-ellipsis-h"></i>Awaiting Upload</p>
  </div>
        </div>
      </ng-container>
<!--end of  if status is true 2 awaiting -->



<!-- if status is true 1 pass -->

<ng-container *ngIf="selectedItem.status === 1 && selectedItem.name == 'ID verification' ">

  <div class="statustrue">
  <h6 class="sidebarheading">ID Document Verification</h6>
  <p style="font-size: 12px;">We ask the individual to take a photograph of their biometric document and then use sophisticated image analysis to detect any tampering or manipulation.
  </p>
  <div class="mt-3 row">
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Requested by</b></p>
      <p class="sidebartexthead">{{ selectedItem.requestedBy }}</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Uploaded by</b></p>
      <p class="sidebartexthead"> {{ idvDataIdentity.idDocuments[0].uploadedBy  }}</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Uploaded on</b></p>
      <p class="sidebartexthead"> {{ formatDate(idvDataIdentity.idDocuments[0].dateCreated) }}</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Status</b></p>
      <p class="pass" class="sidebartexthead">
        <b>
          <span *ngIf="idvDataIdentity.idDocuments[0].verificationResult === 1">Pass</span>
          <span *ngIf="idvDataIdentity.idDocuments[0].verificationResult !== 1">Fail</span>
        </b>
      </p>
    </div>
  </div>
  </div>
  <div class="row">
  <div class="col-md-5 mt-4">
    <p class="mb-0"><i class="fas fa-check-circle"></i><b class="detail-check-text">Passport ( {{ idvDataIdentity.idDocuments[0].country }} )  -</b><span class="detail-text-pass text-success"> Pass </span></p>
    <p class="text-primary mt-2" style="font-size: 12px;">View detailed report</p>
    <!-- <img class="passportimg mt-5" src=" ../../../../assets/img/AKcess IDverification 4 - Bad.png">
    <img *ngIf="Selfieimg" [src]="Selfieimg" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;"> -->
    <p *ngIf="!Iddocumentimg" style="font-size: 14px;">
      <i class="fa fa-ellipsis-h"></i> Awaiting Upload
    </p>
    <img *ngIf="Iddocumentimg" [src]="Iddocumentimg" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;">

  </div>
  <div class="col-md-7 mt-4">
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Overall result  - </b><span class="detail-text-pass text-success"> Pass </span></p>
    <p class="detail-text mb-1 ">Overall result of the ID document check.</p>
    
    <p class="mb-0">
      <i class="fas fa-check-circle"></i>
      <b class="detail-check-text">Facial match result-</b>
      <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].facialMatch, 'detail-text-fail': !idvDataIdentity.idDocuments[0].facialMatch}">
        {{ idvDataIdentity.idDocuments[0].facialMatch ? 'Pass' : 'Fail' }}
      </span>
    </p>
          <p class="detail-text mb-1">Checks whether the face on the ID matches the face in the selfie.</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text" >Visual analysis -</b>
      <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].visualAnalysisPassed, 'detail-text-fail': !idvDataIdentity.idDocuments[0].visualAnalysisPassed}">
        {{ idvDataIdentity.idDocuments[0].visualAnalysisPassed ? 'Pass' : 'Fail' }}
      </span></p>
    <p class="detail-text mb-1">Checks whether the document is a valid Government-issued ID document.</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Name match result-</b>
      <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].nameCheckMatched, 'detail-text-fail': !idvDataIdentity.idDocuments[0].nameCheckMatched}">
        {{ idvDataIdentity.idDocuments[0].nameCheckMatched ? 'Pass' : 'Fail' }}
      </span></p>
    <p class="detail-text mb-1">Checks whether the name on the ID matches the name on the invite.</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">NFC result -</b>
      <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].nfcCheckPassed, 'detail-text-fail': !idvDataIdentity.idDocuments[0].nfcCheckPassed}">
        {{ idvDataIdentity.idDocuments[0].nfcCheckPassed ? 'Pass' : 'Fail' }}
      </span></p>
    <!-- <p class="detail-text mb-1">check whethere the face on ID matches face in the selfie</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Not performed-</b>
      <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].nameCheckMatched, 'detail-text-fail': !idvDataIdentity.idDocuments[0].nameCheckMatched}">
        {{ idvDataIdentity.idDocuments[0].nameCheckMatched ? 'Pass' : 'Fail' }}
      </span></p> -->
    <p class="detail-text mb-1">Indicates whether we were able to complete an NFC read on the passport.</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Age validation result-</b>
      <span [ngClass]="{'detail-text-pass': idvDataIdentity.idDocuments[0].ageValidation, 'detail-text-fail': !idvDataIdentity.idDocuments[0].ageValidation}">
        {{ idvDataIdentity.idDocuments[0].ageValidation ? 'Pass' : 'Fail' }}
      </span></p>
    <p class="detail-text mb-1">Checks whether the age calculated from the document is equal to or greater than the minimum accepted age.
      Police record</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text"> Police record-</b>
       <span [ngClass]="{'detail-text-pass': !idvDataIdentity.idDocuments[0].policeRecordAlert, 'detail-text-fail': idvDataIdentity.idDocuments[0].policeRecordAlert}">
        {{ idvDataIdentity.idDocuments[0].ageValidation ? 'Fail' : 'Pass' }}
      </span>
       </p>
    <p class="detail-text mb-1">Checks whether the document has been identified as lost, stolen or otherwise compromised.</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Compromise alert -</b>
      <span [ngClass]="{'detail-text-pass': !idvDataIdentity.idDocuments[0].compromisedAlert, 'detail-text-fail': idvDataIdentity.idDocuments[0].compromisedAlert}">
        {{ idvDataIdentity.idDocuments[0].compromisedAlert ? 'Fail' : 'Pass' }}
      </span></p>
    <p class="detail-text mb-1">Asserts whether the document is publicly available as compromised.</p>
    
    <p class="mb-0"><i class="fas fa-check-circle"></i> <b class="detail-check-text">Repeat attempts -</b>
      <span [ngClass]="{'detail-text-pass': !idvDataIdentity.idDocuments[0].repeatAttemptAlert, 'detail-text-fail': idvDataIdentity.idDocuments[0].repeatAttemptAlert}">
        {{ idvDataIdentity.idDocuments[0].repeatAttemptAlert ? 'Fail' : 'Pass' }}
      </span></p>
    <p class="detail-text mb-1">Asserts whether the document has been reused in a suspicious way.</p>
    
    <p class="font-bold mb-0 mt-2" style="font-size: 14px;">Notes</p>
    <p style="font-size: 12px;">No notes have been added yet.</p>

    <div class="addnote"> + Add note</div>
  </div>
  </div>
  
  </ng-container>
<!-- end of if status is true 1 pass -->

 <!-- Sidebar Bank Account content -->

 <div class="mt-2 " *ngIf="selectedItem.status === 5 && selectedItem.name === 'Bank account'" >
  <div *ngIf="selectedItem && selectedItem.name === 'Bank account'">
   
     <form>
      <ul class="selectaddress" *ngIf="!showbankForm">
        <li *ngFor="let item of filteredList" (click)="bankopenclick()">
          <a>
            <i class="fa fa-bank mr-2"></i>
            {{ item.name }}<span *ngIf="item.isDefault">(Default)</span>
          </a>
        </li>
      </ul>
           
     </form>


     <form *ngIf="showbankForm">
      <div class="form-group mb-3">
        <label for="firstName">First Name*</label>
        <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName">
      </div>
      <div class="form-group mb-3">
        <label for="middleName">Middle Name</label>
        <input type="text" class="form-control" id="middleName" [(ngModel)]="middleName" name="middleName">
      </div>
      <div class="form-group mb-3">
        <label for="lastName">Last Name*</label>
        <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName">
      </div>
      <div class="form-group mb-3">
        <label for="dobDay">Date of Birth</label>
        <div class="d-flex align-items-center">
          <div class="dob-label d-flex flex-column mr-2">
            <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label>
            <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobDay" placeholder="DD" [(ngModel)]="dobDay" name="dobDay">
          </div>
          <div class="dob-label d-flex flex-column mr-2">
            <label class="text-dark" for="dobMonth" style="font-weight: bold;">Month</label>
            <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobMonth" placeholder="MM" [(ngModel)]="dobMonth" name="dobMonth">
          </div>
          <div class="dob-label d-flex flex-column mr-2">
            <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label>
            <input style="width: 80px;" type="text" class="form-control mx-0" id="dobYear" placeholder="YYYY" [(ngModel)]="dobYear" name="dobYear">
          </div>
        </div>
      </div>
      <div class="form-group mb-3">
        <label for="addressSearch">Address</label>
        <p style="font-size: 12px;color: #000;">Search using a UK Postcode, or <span class="text-warning">enter manually</span></p>
        <div class="d-flex">
          <input type="text" class="form-control" id="addressSearch" placeholder="Search using a postcode" [(ngModel)]="addressSearch" name="addressSearch">
          <button style="border-radius: 20px;" type="button" class="btn btn-secondary ml-3 btn-sm" (click)="runDataChecks()" [disabled]="!addressSearch.trim()">Search</button>
        </div>
      </div>
    
      <div *ngIf=" addresses.length > 0" class="dropdown mb-3">
        <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Select Address
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" *ngFor="let address of addresses" (click)="selectAddress(address)">
            {{ address.addressLine1 }}, {{ address.addressLine2 }}, {{ address.town }}, {{ address.county }}, {{ address.postcode }}
          </a>
        </div>
      </div>
    
      <div *ngIf="selectedAddresss">
        <div class="form-group">
          <label for="addressLine1">Address Line 1</label>
          <input type="text" class="form-control" id="addressLine1" name="addressLine1" [(ngModel)]="selectedAddresss.addressLine1">
        </div>
        <div class="form-group">
          <label for="addressLine2">Address Line 2</label>
          <input type="text" class="form-control" id="addressLine2" name="addressLine2" [(ngModel)]="selectedAddresss.addressLine2">
        </div>
        <div class="form-group">
          <label for="town">Town</label>
          <input type="text" class="form-control" id="town" name="town" [(ngModel)]="selectedAddresss.town">
        </div>
        <div class="form-group">
          <label for="county">County</label>
          <input type="text" class="form-control" id="county" name="county" [(ngModel)]="selectedAddresss.county">
        </div>
        <div class="form-group">
          <label for="postcode">Postcode</label>
          <input type="text" class="form-control" id="postcode" name="postcode" [(ngModel)]="selectedAddresss.postcode">
        </div>
      </div>
    
      <div class="form-group mb-3">
        <label for="bankaccountsortcode">Bank Account Sort Code*</label> 
        <input type="number" class="form-control" id="bankaccountsortcode" [(ngModel)]="bankaccountsortcode" name="bankaccountsortcode">
      </div>        
      <div class="form-group mb-3">
        <label for="bankaccountnumber">Bank Account Number*</label> 
        <input type="number" class="form-control" id="bankaccountnumber" [(ngModel)]="bankaccountnumber" name="bankaccountnumber">
      </div>
    
      <div class="check-footer">        
        <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheck()">Run Data Check</button>
        <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3" (click)="cancel()">Cancel</button>
      </div>
    </form>
    

 </div>
 </div>

 <ng-container *ngIf="selectedItem.status === 6 && selectedItem.name === 'Bank account'">
  <ul class="nav nav-tabs"  *ngIf="selectedItem && (selectedItem.status === 1 || selectedItem.status === 2)">
    <li class="nav-item" (click)="setActiveTabs('check')" [class.active]="activeTabs === 'check'">
      <a class="nav-link">Check</a>
    </li>        
    <li class="nav-item" (click)="setActiveTabs('settings')" [class.active]="activeTabs === 'settings'">
      <a class="nav-link">Settings</a>
    </li>
    <li class="nav-item" (click)="setActiveTabs('archive')" [class.active]="activeTabs === 'archive'">
      <a class="nav-link">Archive</a>
    </li>
  </ul>

  <div class="bankvalidation " style="background: #ffedc0;  padding: 10px;">
    <div class="mt-3 row">
      <div class="col-md-3">
        <p class="mb-1" style="font-size: 10px; font-weight: bold;"><b>Date Of Requeste</b></p>
        <p style="font-size: 12px;">{{ userDetailss.data.firstName }}</p>
      </div>
      <div class="col-md-3">
        <p class="mb-1" style="font-size: 10px; font-weight: bold;"><b>For</b></p>
        <p style="font-size: 12px;"> {{ userDetailss.data.firstName }}</p>
      </div>
      <div class="col-md-3">
        <p class="mb-1" style="font-size: 10px; font-weight: bold;"><b>Address</b></p>
        <p style="font-size: 12px;"> Green park london,SW1A 1AA</p>
      </div>
      <div class="col-md-3">
        <p class="mb-1" style="font-size: 10px; font-weight: bold;"><b>Status</b></p>
        <p class="pass request" style="font-size: 12px; background-color: #ff8f00; color:#000"><b>Refer</b></p>
      </div>
    </div>
  </div>

 
  <div class="accordion mt-3" *ngIf="addresMortalityresultData">
    <div class="accordion-item" (click)="banktoggleSection('section1')">
      <div class="accordion-header flex-column">
        <div class="d-flex justify-content-between">
          <b class="font-dark font-bold acc-head">UK Credit Header (AML)</b>
          <i [class]="currentSection === 'section1' ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </div>
        <p style="font-size: 16px;">{{ addresMortalityresultData.dataSources[0].description }}</p>
      </div>
      <div class="accordion-body" *ngIf="currentSection === 'section1'">
        <ul class="accordionlist pl-1">
          <li *ngFor="let remark of addresMortalityresultData.dataSources[0].remarks">
            <i class="fa fa-ellipsis-h"></i>{{ remark.description }}
          </li>
        </ul>
      </div>
    </div>

    <div class="accordion-item mt-2" (click)="banktoggleSection('section2')">
      <div class="accordion-header flex-column">
        <div class="d-flex justify-content-between">
          <b class="font-dark font-bold acc-head">UK Mortality</b>
          <i [class]="currentSection === 'section2' ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
        </div>
        <p style="font-size: 16px;">{{ addresMortalityresultData.dataSources[1].description }}</p>
      </div>
      <div class="accordion-body" *ngIf="currentSection === 'section2'">
        <ul class="accordionlist pl-1">
          <li *ngFor="let remark of addresMortalityresultData.dataSources[1].remarks">
            <i class="fa fa-ellipsis-h"></i>{{ remark.description }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  
</ng-container>
 <!-- End Sidebar Bank Account content -->






<ng-container *ngIf="selectedItem.status === 2 && selectedItem.name == 'ID verification'">
  <div class="idverification" style="background: #e5f2ff;  padding: 10px; margin-top: 30px;">
    <h6 class="sidebarheading">ID Document Verification</h6>
    <p class="sidebartexthead">We ask the individual to take a photograph of their biometric document and then use sophisticated image analysis to detect any tampering or manipulation.
    </p>
    <div class="mt-3 row">
      <div class="col-md-3">
        <p class="sidebarcontainhead mb-1" ><b>Requested by</b></p>
        <p class="sidebartexthead">{{ userDetailss.data.firstName }}</p>
      </div>
      <div class="col-md-3">
        <p class="sidebarcontainhead mb-1" ><b>Uploaded by</b></p>
        <p class="sidebartexthead"> {{ userDetailss.data.firstName }}</p>
      </div>
      <div class="col-md-3">
        <p class="sidebarcontainhead mb-1" ><b>Uploaded on</b></p>
        <p class="sidebartexthead"> {{ formatDate(selectedItem.requestedDate) }}</p>
      </div>
      <div class="col-md-3">
        <p class="sidebarcontainhead mb-1" ><b>Status</b></p>
        <p class="pass request" style=" color: #000000de;"><b>Requested</b></p>
      </div>
    </div>
    </div>
</ng-container>

<!-- Sidebar Sanctions and PEPs content -->
<div class="" *ngIf="sidebarContent">
  <!-- <h4>{{ sidebarTitle }}</h4> -->
  <form class="pep" style="margin-top: 90px;" *ngIf="selectedItem.status !== 1 && selectedItem.status !== 3 && selectedItem && selectedItem.name === 'Sanctions and PEPs'">
    <div class="forms-data">
    <div class="form-group mb-3">
      <label for="firstName">First Name*</label>
      <input type="text"  class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName" [ngModelOptions]="{ standalone: true }">
    </div>
    <div class="form-group mb-3">
      <label for="middleName">Middle Name</label>
      <input type="text" class="form-control" id="middleName" [(ngModel)]="middleName" name="middleName" [ngModelOptions]="{ standalone: true }">
    </div>
    <div class="form-group mb-3">
      <label for="lastName">Last Name*</label>
      <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName" [ngModelOptions]="{ standalone: true }">
    </div>
    <div class="form-group mb-3 ">
      <label for="nameMatching">Name matching*</label>
      <select class="form-control" id="nameMatching" [(ngModel)]="nameMatching" name="nameMatching" [ngModelOptions]="{ standalone: true }">
        <option>Exact</option>
        <option>Fuzzy</option>
      </select>
    </div>
    <div class="form-group mb-3">
      <label for="dobDay">Date of Birth</label>
      <div class="d-flex align-items-center">
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label>
          <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobDay" placeholder="DD" [(ngModel)]="dobDay" name="dobDay">
        </div>
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobMonth " style="font-weight: bold;">Month</label>
          <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobMonth" placeholder="MM" [(ngModel)]="dobMonth" name="dobMonth">
        </div>
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label>
          <input style="width: 80px;" type="text" class="form-control mx-0" id="dobYear" placeholder="YYYY" [(ngModel)]="dobYear" name="dobYear">
        </div>
      </div>
    </div>
    <div class="form-group mb-3 ">
      <label  for="dobMatching">Date of birth matching*</label>
      <select class="form-control" id="dobMatching" [(ngModel)]="dobMatching" name="dobMatching">
        <option>None</option>
        <option>Exact</option>
        <option>Same year</option>
      </select>
    </div>
    <!-- <div class="form-group toggle-switch mb-3">
      <div class="checkbox" *ngFor="let option of sanctionOptions">
        <mat-slide-toggle style="font-size: 14px;" [(ngModel)]="option.checked" [name]="option.id">{{ option.label }}</mat-slide-toggle>
      </div> -->

      <!-- <label class=" switch" >
        <input type="checkbox" (click)="toggle()" >
        <span class="slider round" ></span>
      </label> -->
      <!-- <div class="form-group mb-3 toggle">
        <div class="d-flex mt-2" *ngFor="let option of sanctionOptions; let i = index">
          <input type="checkbox" id="toggle{{i}}" [(ngModel)]="option.isChecked" (change)="onToggleChange(option)" />
          <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
          <span class="toggle-label-text" style="font-weight: 500;">{{ option.label }}</span>
        </div>
      </div> -->
      <!-- <div class="form-group mb-3 toggle">
        <div class="d-flex mt-2" *ngFor="let optionss of sanctionOptions; let i = index">
          <input type="checkbox" id="toggle{{i}}" [(ngModel)]="optionss.checked" (change)="onToggleChange(optionss,i)" />
          <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
          <span class="toggle-label-text" style="font-weight: 500;">{{ optionss.label }}</span>
        </div>
      </div> -->
      <!-- <div class="d-flex mt-2" *ngFor="let option of sanctionOptions; let i = index">
        <input 
          type="checkbox" 
          id="toggle{{i}}" 
          name="option-{{i}}" 
          [(ngModel)]="option.checked" 
          (change)="onToggleChange(option, i)" 
        />
        <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
        <span class="toggle-label-text" style="font-weight: 500;">{{ option.label }}</span>
      </div> -->

      <div class="d-flex mt-2" *ngFor="let option of sanctionOptions; let i = index">
        <input type="checkbox" id="toggle{{i}}"  name="option-{{i}}" [(ngModel)]="option.checked" [ngModelOptions]="{standalone: option.checked}" (change)="onToggleChange(option,i)" />
        <label for="toggle{{i}}" class="toggle-label toggle-switch"></label>
        <span class="toggle-label-text" style="font-weight: 500;">{{ option.label }}</span>
      </div>
      
      
      
      <div class="form-group mb-3">
        <label for="filter">PEP tiers to filter on</label>
        <div class="custom-dropdown">
          <div class="dropdown-toggle" (click)="peptoggleDropdown()">
            {{ getSelectedTiers() }}
          </div>
          <div class="dropdown-menu" [ngClass]="{'show': dropdownOpen}">
            <div class="form-check" *ngFor="let tier of tiers">
              <!-- <input class="form-check-input" type="checkbox" [id]="tier" [value]="tier" [(ngModel)]="tierSelection[tier]"> -->
              <input class="form-check-input" type="checkbox" [id]="tier" [value]="tier" [(ngModel)]="tierSelection[tier]" (change)="onCheckboxChange(tier, $event)">
              <label class="form-check-label" [for]="tier">{{ tier }}</label>
            </div>
          </div>
        </div>
      </div>
      
   
    <!-- <div class="form-group mb-3 ">
      <label for="filter">PEP tiers to filter on</label>
      <select class="form-control" id="filter" [(ngModel)]="filter" name="filter">
        <option><input class="form-check-input" type="checkbox" value="" id="Tier-1"> <label class="form-check-label" for="flexCheckDefault">Tier 1,Tier 2</label></option>
        <option><input class="form-check-input" type="checkbox" value="" id="Tier-2">Tier 2</option>
        <option><input class="form-check-input" type="checkbox" value="" id="Tier-3">Tier 3</option>
      </select>
    </div> -->
    <!-- <mat-slide-toggle [aria-label]="isSubscribedToEmailsMessage">
    </mat-slide-toggle> -->
    <!-- <p class="mb-4" style="font-size: 14px;"><mat-slide-toggle>Add to Watch list (on-going monitoring)</mat-slide-toggle></p> -->
    <div class="form-group mb-3">
      <div class="d-flex toggle">
        <input type="checkbox" id="watchListToggle" [(ngModel)]="addToWatchList" (change)="toggleWatchList()">
        <label class="toggle-label toggle-switch" for="watchListToggle"></label>
        <span class="toggle-label-text" style="font-weight: 500;">Add to Watch list (on-going monitoring)</span>
    </div>
    </div>
  </div>

  <div class="check-footer">
    <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheckSencation()">Run Data Check</button>
    <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
  </div>
  </form>
 
</div>
 <!--End Sidebar Sanctions and PEPs content -->
<!-- start active check sidebar Sanctions and PEPs -->

<ng-container  *ngIf="selectedItem.status === 1 && selectedItem.name === 'Sanctions and PEPs'">

<!-- <ul class="nav nav-tabs mb-0">
  <li class="nav-item" (click)="setActiveTabs('check')" [class.active]="activeTabs === 'check'">
    <a class="nav-link">Check</a>
  </li>

  <li class="nav-item" (click)="setActiveTabs('settings')" [class.active]="activeTabs === 'settings'">
    <a class="nav-link">Settings</a>
  </li>
  <li class="nav-item" (click)="setActiveTabs('archive')" [class.active]="activeTabs === 'archive'">
    <a class="nav-link">Archive</a>
  </li>
</ul>       -->
<div class="statustrue sanpep">

<div class="mt-3 row">
<div class="col-md-3">
  <p class="sidebarcontainhead mb-1" ><b>Status</b></p>
  <p class=" pass" >
    <!-- <b>{{ sanctionandpepresultData.status }}</b> -->
    <b>{{ sanctionandpepresultData.status === 2 ? 'Pass' : 'Fail' }}</b>

  </p>
</div>

  <div class="col-md-3">
    <p class="sidebarcontainhead mb-1" ><b>Date of Check</b></p>
    <p class="sidebartexthead"> {{ formatDate(sanctionandpepresultData.dateCreated) }}</p>
  </div>
  <div class="col-md-3">
    <p class="sidebarcontainhead mb-1" ><b>Number Of results</b></p>
    <p class="sidebartexthead"> {{  sanctionandpepresultData.resultsCount }}</p>
  </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <p class="sidebarcontainhead mb-1" ><b>Search name</b></p>
      <p class="sidebartexthead">{{ sanctionandpepresultData.request.firstName }} {{ sanctionandpepresultData.request.middleNames }} {{ sanctionandpepresultData.request.lastName }}</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Search date of birth</b></p>
      <p class="sidebartexthead">  {{  sanctionandpepresultData.request.dateOfBirth }}</p>
    </div>
  <div class="col-md-3">
    <p class=" sidebarcontainhead mb-1" ><b>Search Countries</b></p>
    <p *ngIf="sanctionandpepresultData.request.countries.length === 0;" class="sidebartexthead"> All</p>
    <p *ngIf="sanctionandpepresultData.request.countries.length !== 0;" class="sidebartexthead">  {{ sanctionandpepresultData.request.countries }}</p>
  </div>
  
</div>

</div>  
<ul class="nav mt-5">
<li class="nav-item mt-2 active" (click)="setpepactiveTabs('pending')" [class.active]="pepactiveTabs === 'pending'">
<a class="nav-item">Pending</a>
</li>

<li class="nav-item" (click)="setpepactiveTabs('matched')" [class.active]="pepactiveTabs === 'matched'">
<a class="nav-link">Matched</a>
</li>
<li class="nav-item" (click)="setpepactiveTabs('mismatched')" [class.active]="pepactiveTabs === 'mismatched'">
<a class="nav-link">Mismatched</a>
</li>
</ul>
<div [ngSwitch]="pepactiveTabs">
<div class="mt-3" *ngIf="pepactiveTabs === pending" data-content="pending">
<h5>There are no pending profile.</h5>
</div>
<div class="mt-3" *ngIf="pepactiveTabs === matched" data-content="matched">
<h5>There are no pending matched.</h5>
</div>
<div class="mt-3" *ngIf="pepactiveTabs === mismatched" data-content="mismatched">
<h5>There are no pending mismatched.</h5>
</div>
</div>

</ng-container>

<!--end start sidebar active check Sanctions and PEPs -->
<!-- status 3 Sanctions and PEPs-->

<ng-container  *ngIf="selectedItem.status === 3 && selectedItem.name === 'Sanctions and PEPs'">  

<div class="statusthree sanpep">

<div class="mt-3 row">
<div class="col-md-3">
  <p class="sidebarcontainhead mb-1" ><b>Status</b></p>
  <p class=" pending-validation py-2" >   
    <b>{{ sanctionandpepresultDataCheck?.status === 3 ? 'Pending validation':'fail' }}</b>
  </p>
</div>

  <div class="col-md-3">
    <p class="sidebarcontainhead mb-1" ><b>Date of Check</b></p>
    <p class="sidebartexthead"> {{ formatDate(sanctionandpepresultDataCheck?.dateCreated) }}</p>
  </div>
  <div class="col-md-3">
    <p class="sidebarcontainhead mb-1" ><b>Number Of results</b></p>
    <p class="sidebartexthead"> {{  sanctionandpepresultDataCheck?.resultsCount }}</p>
  </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <p class="sidebarcontainhead mb-1" ><b>Search name</b></p>
      <p class="sidebartexthead">{{ sanctionandpepresultDataCheck?.request.firstName }} {{ sanctionandpepresultDataCheck?.request.middleNames }} {{ sanctionandpepresultDataCheck?.request.lastName }}</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Search date of birth</b></p>
      <p class="sidebartexthead">  {{  formatDate(sanctionandpepresultDataCheck?.request.dateOfBirth) }}</p>
    </div>
  <div class="col-md-3">
    <p class=" sidebarcontainhead mb-1" ><b>Search Countries</b></p>
    <p *ngIf="sanctionandpepresultDataCheck?.request.countries.length === 0;" class="sidebartexthead"> All</p>
    <p *ngIf="sanctionandpepresultDataCheck?.request.countries.length !== 0;" class="sidebartexthead">  {{ sanctionandpepresultDataCheck?.request.countries }}</p>
  </div>
  
</div>

</div>  
<ul class="nav mt-5">
<li class="nav-item mt-2 active" (click)="setpepactiveTabs('pending')" [class.active]="pepactiveTabs === 'pending'">
<a class="nav-item">Pending</a>
</li>

<li class="nav-item" (click)="setpepactiveTabs('matched')" [class.active]="pepactiveTabs === 'matched'">
<a class="nav-link">Matched</a>
</li>
<li class="nav-item" (click)="setpepactiveTabs('mismatched')" [class.active]="pepactiveTabs === 'mismatched'">
<a class="nav-link">Mismatched</a>
</li>
</ul>

<div [ngSwitch]="pepactiveTabs">
<div class="mt-3" *ngIf="pepactiveTabs === 'pending'" data-content="pending">
  
  <div class="profile-card" *ngFor="let profiles  of sanctionandpepresultDataCheck?.results">
    <div class="card-header">
     
      <div class="profile-info">
        <h5 class="pro-heading font-bold text-dark">{{ profiles.firstName }} {{ profiles.middleNames }} {{ profiles.lastName }}</h5>
        <p class="profile-id mb-0">ID: {{ profiles.resourceId}}</p>
      </div>
      <div class="action-required">
        <i class="fa fa-exclamation-circle"></i>
        <span>Action Required</span>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 mr-0">
      <div class="profile-picture">
        <img *ngIf="profileImageBlobReference" [src]="profileImageBlobReference" draggable="false" style="max-width: 100%; max-height: 100%; height: 100%; object-fit: contain; width: 100%;">

        <!-- <img src="./../../../assets/img/pm-modi.jpg" alt="Profile Picture"> -->
      </div>
    </div>
    <div class="col-md-9">
      <div class="row">
        
        <div class="col profile-details">
          <strong>Date of birth</strong>
          <p> {{ formatDate(profiles.dateOfBirth) }}</p>
        </div>
        <div class="col profile-details">
          <strong>Gender</strong>
          <p>{{ profiles.gender === 1 ? 'Male' : 'Female' }}</p>
        </div>
        <div class="col profile-details">
          <strong>Nationality</strong>
          <p>{{ profiles.nationalities}}</p>
        </div>
      </div>

      <div class="border-bottom"></div>
      <div class="row">
        <div class="col profile-details">
          <strong>Sanctions</strong>
          <p>{{ profiles.sanctions.length === 0 ? '-': profiles.sanctions}}</p>
        </div>
        <div class="col profile-details">
          <strong>PEP level</strong>
          <p>{{ profiles.pepTier}}</p>
        </div>
        <div class="col profile-details">
          <strong>Is PEP</strong>
          <p>{{ profiles.pepTier.length === 0 ? 'No':'Yes'}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col profile-details">
          <strong>Adverse media</strong>
          <p>-</p>
        </div>
        <div class="col profile-details">
          <strong>Law enforcement</strong>
          <p>{{ profiles.regulatoryEnforcements.length === 0 ? '-' : profiles.regulatoryEnforcements}} </p>
        </div>
        <div class="col profile-details">
          <strong>Disqualified director</strong>
          <p>{{ profiles.disqualifiedDirectors.length === 0 ? '-' : profiles.disqualifiedDirectors}} 
          </p>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="card-footer">
      <button class="btn valid">Validate</button>
      <button class="btn full-pdf" (click)="openviewfullreportsidebar(profiles)">View full report <i class="fas fa-angle-right"></i></button>
    </div>
  </div>
  
</div>
<div class="mt-3" *ngIf="pepactiveTabs === 'matched'" data-content="matched">
  <h5>There are no pending matched.</h5>
</div>
<div class="mt-3" *ngIf="pepactiveTabs === 'mismatched'" data-content="mismatched">
  <h5>There are no pending mismatched.</h5>
</div>

</div>
<div class="profile-footer">
    <button class="profile-btn btn-dark">Review Now</button>
  </div>
</ng-container>

<!--end status 3 Sanctions and PEPs-->

<!-- Sidebar Address and Mortality content -->

<div class="mt-2" *ngIf="sidebarContent">
  <div  *ngIf="selectedItem && selectedItem.name === 'Address and Mortality'">   
  <form *ngIf="selectedItem.status === 5">
     <ul class="selectaddress" *ngIf="!showadressForm">
    <li *ngFor="let item of filteredListAddressandMortality" (click)="addressopenclick()">
      <a>
        <i class="fa fa-bank mr-2"></i>
        {{ item.name }}<span *ngIf="item.isDefault"></span>
      </a>
    </li>
    </ul>
    <!-- <ul class="selectaddress" *ngIf="!showadressForm">
      <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i>Standard Check (Default)</a></li>
      <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> AML Check v2</a></li>
      <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> UK KYC</a></li>
      <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> Enhanced AML</a></li>
      <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i> AML check v3</a></li>
      <li   (click)="addressopenclick()"><a> <i class="fas fa-user-check mr-2"></i>UK AML</a></li>
    </ul> -->
  </form>

  <form *ngIf="showadressForm">
    <!-- <div class="form-group mb-3">
      <label for="firstName">First Name*</label>
      <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName">
    </div>
    <div class="form-group mb-3">
      <label for="middleName">Middle Name</label>
      <input type="text" class="form-control" id="middleName" [(ngModel)]="middleName" name="middleName">
    </div>
    <div class="form-group mb-3">
      <label for="lastName">Last Name*</label>
      <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName">
    </div>
    
    <div class="form-group mb-3">
      <label for="dobDay">Date of Birth</label>
      <div class="d-flex align-items-center">
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label>
          <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobDay" placeholder="DD" [(ngModel)]="dobDay" name="dobDay">
        </div>
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobMonth " style="font-weight: bold;">Month</label>
          <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobMonth" placeholder="MM" [(ngModel)]="dobMonth" name="dobMonth">
        </div>
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label>
          <input style="width: 80px;" type="text" class="form-control mx-0" id="dobYear" placeholder="YYYY" [(ngModel)]="dobYear" name="dobYear">
        </div>
      </div>
    </div>
    <div class="form-group mb-3">
      <label  for="dobMatching">Date of birth matching*</label>
      <select class="form-control" id="dobMatching" [(ngModel)]="dobMatching" name="dobMatching">
        <option>Same year</option>
        <option>Exact date</option>
      </select>
    </div>
   
    <div class="form-group mb-3">
      <label for="addressSearch">Address</label>
      <p style="font-size: 12px;color: #000;">Serach using a UK Postcode, or <span class="text-warning">enter manually</span></p>
      <div class="d-flex">
      <input type="text" class="form-control" id="addressSearch" placeholder="Search using a postcode" [(ngModel)]="addressSearch" name="addressSearch" >
      <button style="border-radius: 20px;" type="button" class="btn btn-secondary ml-3 btn-sm" (click)="runDataCheck()" [disabled]="!addressSearch.trim()">Search</button>
    </div>
    </div>
     -->
     <div class="form-group mb-3">
      <label for="firstName">First Name*</label>
      <input type="text" class="form-control" id="firstName" [(ngModel)]="firstName" name="firstName">
    </div>
    <div class="form-group mb-3">
      <label for="middleName">Middle Name</label>
      <input type="text" class="form-control" id="middleName" [(ngModel)]="middleName" name="middleName">
    </div>
    <div class="form-group mb-3">
      <label for="lastName">Last Name*</label>
      <input type="text" class="form-control" id="lastName" [(ngModel)]="lastName" name="lastName">
    </div>
    <div class="form-group mb-3">
      <label for="dobDay">Date of Birth</label>
      <div class="d-flex align-items-center">
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobDay" style="font-weight: bold;">Day</label>
          <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobDay" placeholder="DD" [(ngModel)]="dobDay" name="dobDay">
        </div>
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobMonth" style="font-weight: bold;">Month</label>
          <input style="width: 60px; margin-right: 10px;" type="text" class="form-control mx-0" id="dobMonth" placeholder="MM" [(ngModel)]="dobMonth" name="dobMonth">
        </div>
        <div class="dob-label d-flex flex-column mr-2">
          <label class="text-dark" for="dobYear" style="font-weight: bold;">Year</label>
          <input style="width: 80px;" type="text" class="form-control mx-0" id="dobYear" placeholder="YYYY" [(ngModel)]="dobYear" name="dobYear">
        </div>
      </div>
    </div>
    <div class="form-group mb-3">
      <label  for="dobMatching">Date of birth matching*</label>
      <select class="form-control" id="dobMatching" [(ngModel)]="dobMatching" name="dobMatching">
        <option>Same year</option>
        <option>Exact date</option>
      </select>
    </div>
    <div class="form-group mb-3">
      <label for="addressSearch">Address</label>
      <p style="font-size: 12px;color: #000;">Search using a UK Postcode, or <span class="text-warning">enter manually</span></p>
      <div class="d-flex">
        <input type="text" class="form-control" id="addressSearch" placeholder="Search using a postcode" [(ngModel)]="addressSearch" name="addressSearch">
        <button style="border-radius: 20px;" type="button" class="btn btn-secondary ml-3 btn-sm" (click)="runDataChecks()" [disabled]="!addressSearch.trim()">Search</button>
      </div>
    </div>
  
    <div *ngIf=" addresses.length > 0" class="dropdown mb-3">
      <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Select Address
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" *ngFor="let address of addresses" (click)="selectAddress(address)">
          {{ address.addressLine1 }}, {{ address.addressLine2 }}, {{ address.town }}, {{ address.county }}, {{ address.postcode }}
        </a>
      </div>
    </div>
  
    <div *ngIf="selectedAddresss">
      <div class="form-group">
        <label for="addressLine1">Address Line 1</label>
        <input type="text" class="form-control" id="addressLine1" name="addressLine1" [(ngModel)]="selectedAddresss.addressLine1">
      </div>
      <div class="form-group">
        <label for="addressLine2">Address Line 2</label>
        <input type="text" class="form-control" id="addressLine2" name="addressLine2" [(ngModel)]="selectedAddresss.addressLine2">
      </div>
      <div class="form-group">
        <label for="town">Town</label>
        <input type="text" class="form-control" id="town" name="town" [(ngModel)]="selectedAddresss.town">
      </div>
      <div class="form-group">
        <label for="county">County</label>
        <input type="text" class="form-control" id="county" name="county" [(ngModel)]="selectedAddresss.county">
      </div>
      <div class="form-group">
        <label for="postcode">Postcode</label>
        <input type="text" class="form-control" id="postcode" name="postcode" [(ngModel)]="selectedAddresss.postcode">
      </div>
    </div>
  
    <div class="form-group mb-3">
      <label for="bankaccountsortcode">Bank Account Sort Code*</label> 
      <input type="number" class="form-control" id="bankaccountsortcode" [(ngModel)]="bankaccountsortcode" name="bankaccountsortcode">
    </div>        
    <div class="form-group mb-3">
      <label for="bankaccountnumber">Bank Account Number*</label> 
      <input type="number" class="form-control" id="bankaccountnumber" [(ngModel)]="bankaccountnumber" name="bankaccountnumber">
    </div>
  
    <div class="check-footer">        
        <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheckAddressMortaility()">Run Data Check</button>
        <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
</div>
<!-- End of Sidebar Address and Mortality content -->

<!-- Starting of  Address and mority active content -->
<ng-container *ngIf="selectedItem.status === 6 && selectedItem.name === 'Address and Mortality'">
<ul class="nav nav-tabs"  *ngIf="selectedItem && (selectedItem.status === 1 || selectedItem.status === 2)">
  <li class="nav-item" (click)="setActiveTabs('check')" [class.active]="activeTabs === 'check'">
    <a class="nav-link">Check</a>
  </li>        
  <li class="nav-item" (click)="setActiveTabs('settings')" [class.active]="activeTabs === 'settings'">
    <a class="nav-link">Settings</a>
  </li>
  <li class="nav-item" (click)="setActiveTabs('archive')" [class.active]="activeTabs === 'archive'">
    <a class="nav-link">Archive</a>
  </li>
</ul>

<div class="bankvalidation " style="background: #ffedc0;  padding: 10px;">
  <div class="mt-3 row">
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Date Of Requeste</b></p>
      <p class="sidebartexthead">{{ userDetailss.data.firstName }}</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>For</b></p>
      <p class="sidebartexthead"> {{ userDetailss.data.firstName }}</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Address</b></p>
      <p class="sidebartexthead"> A</p>
    </div>
    <div class="col-md-3">
      <p class="sidebarcontainhead mb-1" ><b>Status</b></p>
      <p class="pass request" style=" background-color: #ff8f00; color:#000"><b>Refer</b></p>
    </div>
  </div>
</div>


<div class="accordion mt-3" *ngIf="addresMortalityresultData">
  <div class="accordion-item" (click)="banktoggleSection('section1')">
    <div class="accordion-header flex-column">
      <div class="d-flex justify-content-between">
        <b class="font-dark font-bold acc-head">UK Credit Header (AML)</b>
        <i [class]="currentSection === 'section1' ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
      </div>
      <p style="font-size: 16px;">{{ addresMortalityresultData.dataSources[0].description }}</p>
    </div>
    <div class="accordion-body" *ngIf="currentSection === 'section1'">
      <ul class="accordionlist pl-1">
        <li *ngFor="let remark of addresMortalityresultData.dataSources[0].remarks">
          <i class="fa fa-ellipsis-h"></i>{{ remark.description }}
        </li>
      </ul>
    </div>
  </div>

  <div class="accordion-item mt-2" (click)="banktoggleSection('section2')">
    <div class="accordion-header flex-column">
      <div class="d-flex justify-content-between">
        <b class="font-dark font-bold acc-head">UK Mortality</b>
        <i [class]="currentSection === 'section2' ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
      </div>
      <p style="font-size: 16px;">{{ addresMortalityresultData.dataSources[1].description }}</p>
    </div>
    <div class="accordion-body" *ngIf="currentSection === 'section2'">
      <ul class="accordionlist pl-1">
        <li *ngFor="let remark of addresMortalityresultData.dataSources[1].remarks">
          <i class="fa fa-ellipsis-h"></i>{{ remark.description }}
        </li>
      </ul>
    </div>
  </div>
</div>

</ng-container>
<!-- End of  Address and mority active content -->


<!-- bank -->

<!-- Sidebar Source of found content -->

<div class="mt-5 pt-5" *ngIf="sidebarContent">
  <!-- <h4>{{ sidebarTitle }}</h4> -->
  <form *ngIf="selectedItem && selectedItem.name === 'Source of Funds'">
<!--        
      <div class="check-footer">        
          <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheck()">Run Data Check</button>
          <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
      </div> -->
      <!-- <div class="check-footer">
        <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Complete Now</button>
      </div> -->
  </form>
</div>
<!-- End Sidebar Source of found content -->
 
  <!-- Sidebar Open Banking content -->       
  <div class=" sidebar2">
    <div *ngIf="sidebarContent">
     <form *ngIf="selectedItem && selectedItem.name === 'Open Banking'">
<!--        
         <div class="check-footer">        
             <button style="border-radius: 20px;" type="button" class="btn btn-dark col-md-12" (click)="runDataCheck()">Run Data Check</button>
             <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Cancel</button>
         </div> -->
         <!-- <div class="check-footer">
           <button style="border-radius: 20px;" type="button" class="btn btn-secondary col-md-12 mt-3"  (click)="cancel()">Complete Now</button>
         </div> -->
     </form>
   </div>
   </div>
  <!-- end Sidebar Open Banking content -->

<div  *ngIf="sidebarPersonalDetailsVisible" class="sidebar"  >

  <div class="sidebar-header">
    <button type="button" class="close top-close" aria-label="Close" (click)="closeSidebar()">
      <span aria-hidden="true">&times;</span>
    </button>  
    
    <div class="d-flex flex-column" >
      <div class="d-flex">
        <i class="fa fa-video" style="font-size: 30px;"></i>
        <h4 class="ml-2 mt-1"><b>asdasdasd</b></h4>
      </div>     
        <p class="mt-2 mb-0" style="font-size: 14px;" *ngIf="selectedItem && selectedItem.name === 'Source of Funds'">Select the Source of Funds journey that you would like {{ userDetailss.data.firstName }} to complete...</p>    
       
      </div>
      <div class="pdf" >
        <button type="button" class="btn btn-dark" (click)="viewPDF()"><i class='fas fa-file-pdf'></i>View PDF</button>
      </div>
  </div>
  
</div>



</div>



    <div  *ngSwitchCase="'info'" >
      <div class="row mt-5 border-btm">
        <div class="col-md-4">
          <p style="font-size: 12px;  margin-bottom: 1px; color: #757575">Submit By</p>
          <p class="sidebartexthead"></p>

          <p style="font-size: 12px;  margin-bottom: 1px; color: #757575">Attempted By user</p>
          <p class="sidebartexthead">{{ selectedItem.attempts}}</p>

        </div>
        <div class="col-md-4">
          <p style="font-size: 12px;  margin-bottom: 1px; color: #757575">Requested By</p>
          <p class="sidebartexthead">{{ selectedItem.requestedBy}}</p>

          <p style="font-size: 12px;  margin-bottom: 1px; color: #757575">Validation Required</p>
          <p class="sidebartexthead"><i class="fa fa-times-circle-o"></i> No</p>

        </div>
        <div class="col-md-4">
          <p style="font-size: 12px; margin-bottom: 1px; color: #757575">Date Of Request</p>
          <p class="sidebartexthead">{{ formatDate(selectedItem.requestedDate) }}</p>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'settings'">
      
    </div>
    <div *ngSwitchCase="'archive'">
      <ng-container  *ngIf="selectedItem.status === 3 && selectedItem.name === 'Sanctions and PEPs' && !opensidebarviewfullreport">  
        <div class="check-item" *ngFor="let data of getdatachecksnactionanpepsArchive" >
          <div class="check-details">
            <i class="fas fa-globe check-icon"></i>
            <div class="check-info">
              <p class="mb-0">Check run on <strong class="check-font">{{data?.requestedDate}}</strong></p>
              <p class="mb-0">for <strong class="check-font">{{data?.searchName}}</strong> with date of birth <strong class="check-font">{{  formatDate(data?.searchDateOfBirth) }}</strong></p>
            </div>
          </div>
          <div class="check-status">
            <i class="fa fa-exclamation-circle"></i>
            <span class="check-validation"> {{ data?.status == 3 ? 'Pending Validation' : '' }}</span>
          </div>
          <i class="fas fa-arrow-right arrow"></i>
        </div>
       
        <div class="archive-footer">
        <button class="review-btn btn-dark">Review now</button>
      </div>
     
    
        
        </ng-container> 
    </div>
  </div>

   

</div>
        
  
  



  




  