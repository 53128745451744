<div class="dashboard-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Dashboard</h3>
        </div>
    </div>

    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="row" *ngIf="currentCompany.market_sector === 'Real Estate'">
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card" [routerLink]="['/panel/property']">
                        <div class="card-body">
                            <h4 class="card-title">Properties</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ propertiesCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fal fa-home"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div
                            class="card-body"
                            [routerLink]="['/panel/property/inquiry']"
                        >
                            <h4 class="card-title">Property Enquiries</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ propertyInquiriesCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fab fa-wpforms"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentCompany.market_sector === 'Real Estate'">
                <hr />
                <br />
            </div>
            <div class="row">
                <!--<div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div class="card-body" [routerLink]="['/panel/company-users']">
                            <h4 class="card-title">Total Admin</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">{{adminCounts}}</h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons"><i class="fad fa-users"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card" [routerLink]="['/panel/employees']">
                        <div class="card-body">
                            <h4 class="card-title">Total Staff</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ employeeCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fal fa-users"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div
                            class="card-body"
                            [routerLink]="['/panel/clients']"
                        >
                            <h4 class="card-title">Total Clients</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ clientCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fad fa-user-tie"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div class="card-body" [routerLink]="['/panel/eforms']">
                            <h4 class="card-title">Total eForms</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ eFormsCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fab fa-wpforms"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div
                            class="card-body"
                            [routerLink]="['/panel/eformsresponse']"
                        >
                            <h4 class="card-title">Total eForms Responses</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <!--<a [routerLink]="['/eformsresponse']"></a>-->
                                    <h4 class="font-medium m-b-0">
                                        {{ eFormResponseCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fal fa-file-check"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div
                            class="card-body"
                            [routerLink]="['/panel/received-docs']"
                        >
                            <h4 class="card-title">Total Documents</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ documentsCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fal fa-file-alt"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div
                            class="card-body"
                            [routerLink]="['/panel/certificate']"
                        >
                            <h4 class="card-title">Total Certificates</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ certificateCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fal fa-file-certificate"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div class="card-body" [routerLink]="['/panel/assets']">
                            <h4 class="card-title">Total Assets</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ assetsCount }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fal fa-clipboard-list"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="card">
                        <div class="card-body" [routerLink]="['/panel/logs']">
                            <h4 class="card-title">Logs</h4>
                            <div class="d-flex">
                                <div class="align-self-center">
                                    <h4 class="font-medium m-b-0">
                                        {{ logsCounts }}
                                    </h4>
                                </div>
                                <div class="ml-auto">
                                    <div class="dashboard-icons">
                                        <i class="fal fa-clipboard-list"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <!-- Chart -->
            <!--<div class="row">
                <div class="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div class="card card-default">
                        <div class="card-header">
                            <h4 class="card-title m-b-0">New Onboarded Users</h4>
                        </div>
                        <div class="card-body">-->
            <!--<p-chart type="pie" width="30vw" height="30vh" [data]="data1" [options]="options"></p-chart>-->
            <!--</div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div class="card card-default">
                        <div class="card-header">
                            <h4 class="card-title m-b-0">New Onboarded Users</h4>
                        </div>
                        <div class="card-body">-->
            <!-- <p-chart type="bar" width="30vw" height="30vh" [data]="data" [options]="options"></p-chart> -->
            <!--</div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div class="card card-default">
                        <div class="card-header">
                            <h4 class="card-title m-b-0">New Onboarded Users</h4>
                        </div>
                        <div class="card-body">-->
            <!--<p-chart type="bar" width="30vw" height="30vh" [data]="data" [options]="options"></p-chart>-->
            <!--</div>
                    </div>
                </div>
            </div>-->
        </div>
    </section>
</div>
