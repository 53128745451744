<!--Response Grade Modal -->
<div class="modal-content eform-response-download-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title">eForm Response Excel Download</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <input type="hidden" [(ngModel)]="excel_data" value="{{excel_data}}" />
            <!--<input type="hidden" [(ngModel)]="fields" value="{{fields}}" />-->
        </div>
        <!--<div>
            <table border="1" id="excel-table" class="d-none">
                <thead>
                    <tr>
                        <th colspan="3" *ngFor="let item of get_excel_header()">{{ cast_excel_label(item) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of excel_data">
                        <td colspan="3" *ngFor="let item of get_excel_value(data)">{{ item }}</td>
                    </tr>
                </tbody>
            </table>
        </div>-->
        <div class="table-responsive mb-1">
            <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" small="true"
                hover="true" id="excel-table">
                <thead>
                    <tr>
                        <th colspan="3" *ngFor="let item of get_excel_header()" class="text-center">{{
                            cast_excel_label(item) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of excel_data">
                        <td colspan="3" *ngFor="let item of get_excel_value(data)" class="text-left">{{ item }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- <div class="g-signin2" data-onsuccess="onSignIn" data-longtitle="true"></div> -->
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-info"
            (click)="exportTableToExcel()">Download</button>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">Close</button>
    </div>
</div>

<script>
    function start() {
      
    };

    // Load the JavaScript client library and invoke start afterwards.
    gapi.load('client', start);
  </script>
  <div id="results"></div>