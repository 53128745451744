<div class="modal-header">
    <h4 class="modal-title">Logs</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body background-light-blue">
    <div class="card mb-0">
        <div class="card-body">
            <div class="card-title row align-items-center">
                <div class="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div class="input-group">
                        <input type="text"
                               [(ngModel)]="search_text"
                               class="form-control"
                               id="search"
                               placeholder="Search Logs" />
                        <div class="input-group-append">
                            <div class="input-group-text" (click)="getLogs()">
                                <i class="fal fa-search"></i>
                            </div>
                            <div class="input-group-text" (click)="this.search_text='';getLogs()">
                                <i class="fal fa-times-circle"></i>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="action-tab">
                <button type="button" class="mx-1 btn" tooltip="Export" container="body" 
                        id="exportbtnpdf" (click)="exportexcel('user_logs')">
                    <i class="fas fa-file-export"></i>
                </button>
            </div>
            <div class="table-responsive mb-1">
                <table id="user_logs" class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" small="true" hover="true">
                    <thead>
                        <tr>
                            <th>AKcess Id</th>
                            <th>Domain Name</th>
                            <th>Name</th>
                            <th>IP</th>
                            <th>Created Date</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="eformdata?.length == 0">
                        <tr>
                            <td colspan="5" class="text-center">No Records Found</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="eformdata?.length > 0">
                        <tr *ngFor="let data of eformdata | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount };let i = index;">
                            <td>
                                {{data.akcessId }}
                            </td>
                            <td>
                                {{data.domainName}}
                            </td>
                            <td>
                                {{data.name }}
                            </td>
                            <td>
                                {{data.ip }}
                            </td>
                            <td>
                                {{data.createdDate | date:'medium'}}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="w-100">
                        <tr>
                            <td colspan="5">
                                <div class="table-page" *ngIf="eformdata?.length > 10">
                                    <div class="page-controls">
                                        <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                             class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                             nextLabel="Next"></pagination-controls>
                                    </div>
                                    <div class="page-size">
                                        <label class="mb-0 mr-2">Item per page</label>
                                        <select #val (change)="changePage(val.value)"
                                                class="form-control form-control-sm" id="pageSelector">
                                            <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>    
</div>
<div class="modal-footer">
    <button type="button" class="btn waves-effect waves-light btn-info" (click)="closeModal('changes saved!')">Save Changes</button>
    <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal" (click)="closeModal('close')">Close</button>
</div>