import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { data } from 'jquery';


@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, AfterViewInit{
  showSelectJourneyModal: boolean = false;
  showConfigureJourneyModal: boolean = false;
  showContactModal: boolean = false; 
  thankyoumodal: boolean = false;
  inviteProcessCompleted = false;
  showSideBar : boolean = false;
  callmodal: boolean = false;
  selectedValue: any;  
  sidebarVisible = false;
  sidebarlivelinessVisible = false;
  sidebarPersonalDetailsVisible = false;
  showFullReportSidebar: boolean =false;
  
 

  activeTabs: string = 'check';
  selectedItemStatus: number | null = null;
  portalUser: any;
  uploadForm: FormGroup;
  sidebarTitle: string;
  sidebarContent: string;
  isModalOpen = false;
  show:any;
  switch:any;
  // pepactiveTabs: string ='pending'
  pending: string = 'pending';
  matched: string = 'matched';
  mismatched: string = 'mismatched';
  
  selectedAddresss: any = null;
  diallingCodes: string[] = ['+1', '+44', '+91', '+61', '+81'];

  activeTab: number = 1; 
  formData2: any;

  addToWatchList: boolean = false;

  toggleWatchList() {
    this.addToWatchList = !this.addToWatchList;
    console.log("Add to Watch List (on-going monitoring) toggled:", this.addToWatchList);
    // You can perform other actions based on the toggle state here
  }
  user = {
    name: 'parth gajdhar',
    status: 'Not invited',
    email: 'pgajdhar@gmail.com',
    phone: '',
    address: 'N/A',
    approvalStatus: 'Pending',
    registrationCode: 'LSS-4JR',
    sentOn: '01 Jul 2024',
    clientReference: 'N/A'
  };

  journeys = [
    {
        "id": "fae35444-2710-43db-98a0-23fbfeef6f8b",
        "title": "Identity",
        "description": "<p>*This journey is set to&nbsp;<strong>Web Only</strong>&nbsp;for demo purposes.</p>\n<p>This journey will run the following check:</p>\n<ul>\n<li>Identity verification</li>\n</ul>",
        "endUserDescription": "<p>This check will seek to verify your identity.</p>",
        "isPublic": true,
        "isActive": true,
        "webhookEnabled": true,
        "actors": [
            {
                "id": 42,
                "title": "Applicant",
                "canAddMultiple": false,
                "isClient": false
            }
        ],
        "industries": [],
        "method": 1,
        "stepTypes": [
            11
        ],
        "showAllSummaryPages": false,
        "showIntroSummaryPage": false,
        "showJourneyDescriptionPage": false,
        "isRightToWork": false,
        "isRightToRent": false,
        "isSafeHarbour": false,
        "isOpenBanking": false,
        "isSourceOfFunds": false
    },
    {
        "id": "c69516c0-d6a6-4cb4-bfda-94dcfa50221e",
        "title": "Identity | Address | International Screening",
        "description": "<p><strong>*Native only in Live</strong></p>\n<p>This journey will run the following checks:</p>\n<ul>\n<li>Identity verification</li>\n<li>Address verification</li>\n<li>Mortality</li>\n<li>PEP status</li>\n<li>Sanction status</li>\n</ul>",
        "endUserDescription": "<p>This check will seek to verify your identity, your address, and identify if you are politically-exposed or subject to financial sanctions.</p>\n<p><span style=\"font-size: 8pt\"><strong>Note:&nbsp;</strong>This search will show on your credit file but will not impact your credit score.</span></p>",
        "isPublic": true,
        "isActive": true,
        "webhookEnabled": true,
        "actors": [
            {
                "id": 216,
                "title": "Applicant",
                "canAddMultiple": false,
                "isClient": false
            }
        ],
        "industries": [],
        "method": 1,
        "stepTypes": [
            1,
            2,
            11
        ],
        "showAllSummaryPages": false,
        "showIntroSummaryPage": false,
        "showJourneyDescriptionPage": false,
        "isRightToWork": false,
        "isRightToRent": false,
        "isSafeHarbour": false,
        "isOpenBanking": false,
        "isSourceOfFunds": false
    },
    {
        "id": "6b3569bc-6d5b-49eb-8fae-eb58a82f92f0",
        "title": "Identity | International Screening",
        "description": "<p><strong>*Native only in Live</strong></p>\n<p>This journey will run the following checks:</p>\n<ul>\n<li>Identity verification</li>\n<li>PEP status</li>\n<li>Sanction status</li>\n</ul>",
        "endUserDescription": "<p>This check will seek to verify your identity, your address, and identify if you are politically-exposed or subject to financial sanctions.</p>\n<p><span style=\"font-size: 8pt\"><strong>Note:&nbsp;</strong>This search will show on your credit file but will not impact your credit score.</span></p>",
        "isPublic": false,
        "isActive": true,
        "webhookEnabled": true,
        "actors": [
            {
                "id": 764,
                "title": "Applicant",
                "canAddMultiple": false,
                "isClient": false
            }
        ],
        "industries": [],
        "method": 1,
        "stepTypes": [
            1,
            2,
            11
        ],
        "showAllSummaryPages": false,
        "showIntroSummaryPage": false,
        "showJourneyDescriptionPage": false,
        "isRightToWork": false,
        "isRightToRent": false,
        "isSafeHarbour": false,
        "isOpenBanking": false,
        "isSourceOfFunds": false
    }
];
  

tiers: string[] = ['Tier 1', 'Tier 2', 'Tier 3'];
tierSelection: { [key: string]: boolean } = {};
dropdownOpen: boolean = false; 
  
  formdata = { 
    entityId:'',   
    firstName: '',
    lastName: '',
    birthdate: '',
    reference:'',
    email: '',
    telephone:'',
    address:'',
    countryCode:'',
};
entityidss: string;
userDetailss: any;
items:any;
selectedItem: any;

itemss = [
  { name: 'Bank account', status: 5 },
  { name: 'Source of Funds', status: 5 },
  { name: 'Open Banking', status: 5 },
  { name: 'Address and Mortality', status: 5 },
  { name: 'Sanctions and PEPs', status: 5 }
];

setActiveTabs(tab: string) {
  this.activeTabs = tab;
  // this.pepactiveTabs = tab; 
}

  setActiveTab(tab: number) {
    this.activeTab = tab;
  }
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private calldata: DataservicesService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer
    
  ) {
    this.uploadForm = this.fb.group({
      applicant: ['']
    });
   }




   openParams: string | null = null;
   OpenModel:string;

  ngOnInit(): void { 
    this.route.paramMap.subscribe(params => {
      this.entityidss = params.get('entityid');
            this.OpenModel = params.get('OpenModel');

      console.log(this.entityidss);
      console.log(this.OpenModel);

      this.fetchUserDetailss(this.entityidss);
      
      // You can now use this.entityid to fetch user details or perform other actions
    });


    const formData = sessionStorage.getItem('formData2');
  this.formData2 = formData ? JSON.parse(formData) : {};


//   if(this.OpenModel === 'Address and Mortality'){
//     this.addressopenclick();
    
//   }else if(this.OpenModel === 'Sanctions and PEPs'){
//       this.sanctionopenclick();
//   }else if(this.OpenModel === 'Bank account'){
//     this.bankaccountopenclick();
//  }

  this.tiers.forEach(tier => {
    this.tierSelection[tier] = false; // or true based on your initial state requirement
  });


  // Optionally set the form control value
  if (this.formData2 && this.formData2.firstName) {
    this.uploadForm.get('applicant').setValue(this.formData2.firstName);
  }

 
  }

  // openSelectJourneyModal(): void {
  //   this.showSelectJourneyModal = true;
  // }
  getIcon(type: number): string {
    const icons = {
      2: 'fa-video',
      1: 'fa-id-card',
      // Add other type to icon mappings
    };
    return icons[type] || 'fa-question';
  }
  
  ngAfterViewInit(): void {

    this.openModal('selectJourneyModal');
    // this.closePopup('selectJourneyModal');
  }
  openSelectJourneyModal() {
    this.showSelectJourneyModal = true;

    document.getElementById('selectJourneyModal').style.display = 'block';
    this.opensidebarpersonal = false;
    this.opensidebarviewfullreport = false;
    this.opensidebaraccount = false;
    this.opensidebargroup = false;
    this.openSidebarQA = false;

  }
  gouserprofile(){
    this.router.navigate(['/panel/verification-userdetails']).then(() => {
          this.spinner.hide();
        });
  }

  toggleoption :boolean = false;
  onToggleChange(option :any,indexss :any) {
    console.log(option);
    console.log(indexss);
    console.log(this.sanctionOptions[indexss].checked);
    if(this.sanctionOptions[indexss].checked === true){
      console.log("inside true");
      this.sanctionOptions[indexss].checked = false;
    }else{
      console.log("inside false");
      this.sanctionOptions[indexss].checked = true;
    }
    console.log(this.sanctionOptions);

 
    //  this.sanctionOptions.find(options => options.id === option.id);

    // console.log('${option.label} is now:', option);
  }
  toggle(){
    this.show = !this.show;

    if(this.show)
      this.switch = "Hide";
    else
      this.switch = "Show";
  }

  isSidebarOpen: boolean = false;

  toggleQuickActionSidebar() {
    console.log("inside toggleQuickActionSidebar");
    this.isSidebarOpen = !this.isSidebarOpen;
    console.log( this.isSidebarOpen);
  }

  fetchUserDetails(entityid: string): void {

    console.log('inside entity detail fetch');
    this.calldata.getdetailusingentityId(entityid).subscribe( 
        
      (res: any) => {
        console.log('inside entity detail fetch');
        console.log(res);
        this.userDetailss = res;
        console.log(this.userDetailss);
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 

    this.calldata.getActiveCheckResult(entityid).subscribe( 
        
      (res: any) => {
        console.log('inside getActiveCheckResult fetch');
        console.log(res);
        this.items = res.data;
        console.log(this.items);
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 
    
  }

  async fetchUserDetailss(entityid: string): Promise<void> {

    console.log('inside entity detail fetch');
   await this.calldata.getdetailusingentityId(entityid).subscribe( 
        
      (res: any) => {
        console.log('inside entity detail fetch');
        console.log(res);
        this.userDetailss = res;
        console.log(this.userDetailss);
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 

    await this.calldata.getActiveCheckResult(entityid).subscribe( 
        
      (res: any) => {
        console.log('inside getActiveCheckResult fetch');
        console.log(res);
        this.items = res.data;
        console.log(this.items);
        if(this.OpenModel === 'Address and Mortality'){
          this.addressopenclick();
          // Address and Mortality
          
        }else if(this.OpenModel === 'Sanctions and PEPs'){
            this.sanctionopenclick();
        }else if(this.OpenModel === 'Bank account'){
          this.bankaccountopenclick();
       }
       else if(this.OpenModel === 'Send new invite to an individual'){
        this.openSelectJourneyModal();
     }

       
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 

   
    
  }
  addressSearch: string = '';
  // Selfieimg :any = "";
  Selfieimg: SafeUrl | string = ''; // Initialize Selfieimg as an empty string
  Iddocumentimg: SafeUrl | string = ''; // Initialize Selfieimg as an empty string
  profileImageBlobReference: SafeUrl | string = ''; // Initialize Selfieimg as an empty string

  addresses: any[] = [];
idvDataIdentity :any;
sanctionandpepresultData :any;
sanctionandpepresultDataCheck :any;


showDropdown: boolean = false;


runDataChecks() {
  const postcode = this.addressSearch.trim();
  if (postcode) {
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));

    const tmpdata = { 'accessId': this.portalUser.akcessId };

    this.calldata.getAddressesByPostalCode(tmpdata,postcode).subscribe(
      (res: any) => {
        if (res.statusCode === 200 && res.data.isSuccess) {
          this.addresses = res.data.addresses;
          // this.showDropdown = true;
        } else {
          // Handle error or no addresses found
          this.addresses = [];
          // this.showDropdown = false;
        }
        console.log(this.showDropdown);
      },
      error => {
        // Handle error
        console.error('Error fetching addresses', error);
      }
    );
  }
}

selectAddress(address: any) {
  console.log('Selected address:', address);
  this.selectedAddresss = address;
  this.showDropdown = false;
  // Handle the selected address here
}
  fetchIDVerificationEntityIDVDetails(entityid: string,entityidv : string): void {
    this.calldata.getidventity(entityid,entityidv).subscribe( 
        
      (res: any) => {
        console.log('inside entityidv detail fetch');
        console.log(res);
        this.idvDataIdentity = res.data;
        console.log(this.idvDataIdentity);
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { 'accessId': this.portalUser.akcessId };
        this.calldata.idVerificationSelfie(tmpdata,this.idvDataIdentity.id,this.idvDataIdentity.idvId,this.idvDataIdentity.taskId).subscribe(
          (res: Blob) => {
            console.log("inside idVerificationSelfie");
            const fileURL = URL.createObjectURL(res);
            // window.open(fileURL);
            console.log(fileURL);
            this.Selfieimg = this.sanitizer.bypassSecurityTrustUrl(fileURL);

            // this.Selfieimg = fileURL;
            // Additional operations
          
          },
          error => {
            console.error('Error fetching PDF:', error);
          }
        );
    
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 


   

    

    // this.calldata.idVerificationSelfie(entityid,this.entityidss,this.idvDataIdentity.idvId,this.idvDataIdentity.taskId    ).subscribe( 
        
    //   (res: any) => {
    //     console.log('inside entityidv detail fetch');
    //     console.log(res);
    //     this.idvDataIdentity = res.data;
    //     console.log(this.idvDataIdentity);
    //   },
    //   error => {
    //     console.error('Error fetching user details:', error);
    //   }
    // ); 


   }

//profileImageBlobReference
   profileImageBlobReferencess(entityIds,dataCheckId,resultId,profileImageBlobReference){
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { 'accessId': this.portalUser.akcessId };
        //tmpdata: any, entityId: string, dataCheckId:any,resultId:any,profileImageBlobReference:any
    this.calldata.profileImageBlobReference(tmpdata,entityIds,dataCheckId,resultId,profileImageBlobReference).subscribe(
      (res: Blob) => {
        console.log("inside idVerificationSelfie");
        const fileURL = URL.createObjectURL(res);
        // window.open(fileURL);
        console.log(fileURL);
        this.profileImageBlobReference = this.sanitizer.bypassSecurityTrustUrl(fileURL);

        // this.Selfieimg = fileURL;
        // Additional operations
      
      },
      error => {
        console.error('Error fetching PDF:', error);
      }
    );
   }

   sanctionprofileimgURL:any =[];
   loadprofileImageBlobReferencess(entityIds,dataCheckId,resultId,profileImageBlobReference){
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { 'accessId': this.portalUser.akcessId };

        this.sanctionprofileimgURL=[];
        profileImageBlobReference.forEach(profile => {

          this.calldata.profileImageBlobReference(tmpdata,entityIds,dataCheckId,resultId,profile).subscribe(
            (res: Blob) => {
              console.log("inside idVerificationSelfie");
              const fileURL = URL.createObjectURL(res);
              // window.open(fileURL);
              console.log(fileURL);
              this.sanctionprofileimgURL.push(this.sanitizer.bypassSecurityTrustUrl(fileURL));// = this.sanitizer.bypassSecurityTrustUrl(fileURL);
      
              // this.Selfieimg = fileURL;
              // Additional operations
            
            },
            error => {
              console.error('Error fetching PDF:', error);
            }
          );

        });
        //tmpdata: any, entityId: string, dataCheckId:any,resultId:any,profileImageBlobReference:any
   
   }
   fetchIDVerificationEntityIDVDetailss(entityid: string,entityidv : string): void {
    this.calldata.getidventity(entityid,entityidv).subscribe( 
        
      (res: any) => {
        console.log('inside entityidv detail fetch');
        console.log(res);
        this.idvDataIdentity = res.data;
        console.log(this.idvDataIdentity);
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { 'accessId': this.portalUser.akcessId };
        this.calldata.iddocumentscan(tmpdata,entityidv,this.idvDataIdentity.id,this.idvDataIdentity.idvId,this.idvDataIdentity.taskId).subscribe(
          (res: Blob) => {
            console.log("inside idVerificationSelfie");
            const fileURL = URL.createObjectURL(res);
            // window.open(fileURL);
            console.log(fileURL);
            this.Iddocumentimg = this.sanitizer.bypassSecurityTrustUrl(fileURL);

            // this.Selfieimg = fileURL;
            // Additional operations
          
          },
          error => {
            console.error('Error fetching PDF:', error);
          }
        );
    
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 


   

    

    // this.calldata.idVerificationSelfie(entityid,this.entityidss,this.idvDataIdentity.idvId,this.idvDataIdentity.taskId    ).subscribe( 
        
    //   (res: any) => {
    //     console.log('inside entityidv detail fetch');
    //     console.log(res);
    //     this.idvDataIdentity = res.data;
    //     console.log(this.idvDataIdentity);
    //   },
    //   error => {
    //     console.error('Error fetching user details:', error);
    //   }
    // ); 


   }

   sansactionandpepsDetailss(entityid: string): void {
    this.calldata.getsancationandpep(entityid).subscribe( 
        
      (res: any) => {
        console.log('inside getsancationandpep detail fetch');
        console.log(res);
        this.sanctionandpepresultData = res.data;
        console.log(this.sanctionandpepresultData);
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
      
    
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 


   //getsancationandpepdatacheck

    

    // this.calldata.idVerificationSelfie(entityid,this.entityidss,this.idvDataIdentity.idvId,this.idvDataIdentity.taskId    ).subscribe( 
        
    //   (res: any) => {
    //     console.log('inside entityidv detail fetch');
    //     console.log(res);
    //     this.idvDataIdentity = res.data;
    //     console.log(this.idvDataIdentity);
    //   },
    //   error => {
    //     console.error('Error fetching user details:', error);
    //   }
    // ); 


   }
   getdatachecksnactionanpepsArchive:any;
   sansactionandpepsDatacheck(entityid: string,dataCheckId:any): void {
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
    const tmpdata = { 'accessId': this.portalUser.akcessId };
    this.calldata.getdatachecksnactionanpepsArchive(tmpdata,entityid,5).subscribe( 
      (res: any) => {
        console.log('inside getdatachecksnactionanpepsArchive detail fetch');
        console.log(res);
        this.getdatachecksnactionanpepsArchive  = res.data;
      }
    );
    this.calldata.getsancationandpepdatacheck(entityid,dataCheckId).subscribe( 
        
      (res: any) => {
        console.log('inside sanctionandpepresultDataCheck detail fetch');
        console.log(res);
        this.sanctionandpepresultDataCheck = res.data;
        console.log(this.sanctionandpepresultDataCheck);
       
        
        this.sanctionandpepresultDataCheck.results.forEach(profile => {

          this.profileImageBlobReferencess(entityid,dataCheckId,profile.id,profile.profileImageBlobReference);
          // if (profile.profileImageBlobReference) {
          //   // this.loadImage(profile);
          
          // }
        });
        this.sanctionandpepresultDataCheck.results.forEach(profile => {
          this.loadprofileImageBlobReferencess(entityid,dataCheckId,profile.id,profile.imageBlobReferences);
         
        });
    
      },
      error => {
        console.error('Error fetching user details:', error);
      }
    ); 


   //getsancationandpepdatacheck

    

    // this.calldata.idVerificationSelfie(entityid,this.entityidss,this.idvDataIdentity.idvId,this.idvDataIdentity.taskId    ).subscribe( 
        
    //   (res: any) => {
    //     console.log('inside entityidv detail fetch');
    //     console.log(res);
    //     this.idvDataIdentity = res.data;
    //     console.log(this.idvDataIdentity);
    //   },
    //   error => {
    //     console.error('Error fetching user details:', error);
    //   }
    // ); 


   }



      filteredList = [];
      filteredListAddressandMortality = [];
      

      fetchJourneyBankProfiless:any =[];
      fetchJourneyBankProfile(): void {
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { 'accessId': this.portalUser.akcessId };
        if(this.selectedItem.status  === 6){
          this.calldata.dataCheckBankResult(tmpdata,this.entityidss,this.selectedItem.dataCheckId).subscribe( 
              
            (res: any) => {
            this.addresMortalityresultData = res.data;
            console.log("addresMortalityresultData");
            console.log(this.addresMortalityresultData);
              // console.log('inside filteredListAddressandMortality detail fetch');
              // console.log(res.data);
              // this.filteredListAddressandMortality = res.data.filter(item => item.activeCheckType === 6);
              // console.log(this.filteredListAddressandMortality);
              // this.fetchJourneyBankProfiless = res.data;
              this.sidebarTitle = 'Bank Account Details';
              this.sidebarContent = 'Content specific to Bank Account.';
              // this.idvDataIdentity = res.data;
              // console.log(this.idvDataIdentity);
            },
            error => {
              console.error('Error fetchJourneyBankProfile user details:', error);
            }
          );
        }else{
          this.calldata.getJourneyBankProfile(tmpdata).subscribe( 
            
            (res: any) => {
              console.log('inside fetchJourneyBankProfile detail fetch');
              console.log(res.data);
              this.filteredList = res.data.filter(item => item.activeCheckType === 4);
              console.log(this.filteredList);
              this.fetchJourneyBankProfiless = res.data;
              this.sidebarTitle = 'Bank Account Details';
              this.sidebarContent = 'Content specific to Bank Account.';
              // this.idvDataIdentity = res.data;
              // console.log(this.idvDataIdentity);
            },
            error => {
              console.error('Error fetchJourneyBankProfile user details:', error);
            }
          ); 
        }
        
          }
          addresMortalityresultData :any = [];
      //getJourneyBankProfile
      fetchAddressandMortalityProfile(): void {
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        const tmpdata = { 'accessId': this.portalUser.akcessId };
        console.log("addresMortalityresultData");
        console.log(this.selectedItem.status);
        if(this.selectedItem.status  === 6){
          this.calldata.dataCheckAddressMortailityResult(tmpdata,this.entityidss,this.selectedItem.dataCheckId).subscribe( 
              
            (res: any) => {
            this.addresMortalityresultData = res.data;
            console.log("addresMortalityresultData");
            console.log(this.addresMortalityresultData);
              // console.log('inside filteredListAddressandMortality detail fetch');
              // console.log(res.data);
              // this.filteredListAddressandMortality = res.data.filter(item => item.activeCheckType === 6);
              // console.log(this.filteredListAddressandMortality);
              // this.fetchJourneyBankProfiless = res.data;
              this.sidebarTitle = 'Address and Mortality Details';
              this.sidebarContent = 'Content specific to Address and Mortality.';
              // this.idvDataIdentity = res.data;
              // console.log(this.idvDataIdentity);
            },
            error => {
              console.error('Error fetchJourneyBankProfile user details:', error);
            }
          );

        }else{
     
          this.calldata.getJourneyBankProfile(tmpdata).subscribe( 
              
            (res: any) => {
              console.log('inside filteredListAddressandMortality detail fetch');
              console.log(res.data);
              this.filteredListAddressandMortality = res.data.filter(item => item.activeCheckType === 6);
              console.log(this.filteredListAddressandMortality);
              this.fetchJourneyBankProfiless = res.data;
              this.sidebarTitle = 'Address and Mortality Details';
              this.sidebarContent = 'Content specific to Address and Mortality.';
              // this.idvDataIdentity = res.data;
              // console.log(this.idvDataIdentity);
            },
            error => {
              console.error('Error fetchJourneyBankProfile user details:', error);
            }
          ); 
        }
        
          }

      selectedJourney:any;
  getStatusClass(status: number): string {
    return status === 2 ? 'active-check' : status === 5 ? 'available-test' : '';
  }

  getStatusText(status: number): string {
    // Return the text based on the status
    switch(status) {
      case 1: return 'Approved';
      case 2: return 'Pending';
      case 3: return 'pending validation';
      case 4: return 'Refer';
      default: return 'Unknown';
    }
  }
  getStatusTexts(status: number): string {
    // Return the text based on the status
    switch(status) {
      case 1: return 'Pass';
      case 2: return 'Pending';
      case 3: return 'pending validation';
      case 5: return 'Refer';
      default: return 'Unknown';
    }
  }
  viewPDF() : void{
    console.log("selectedItem ");
    console.log(this.selectedItem);
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
    const tmpdata = { 'accessId': this.portalUser.akcessId };
    if(this.selectedItem.name === 'Address and Mortality'){

      this.calldata.dataCheckAddressMortailityResultPDF(tmpdata, this.entityidss, this.selectedItem.dataCheckId).subscribe(
        (res: Blob) => {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL);
  
          // Additional operations
        
        },
        error => {
          console.error('Error fetching PDF:', error);
        }
      );
      
    } else  if(this.selectedItem.name === 'Bank account'){

      this.calldata.dataCheckBankAccountResultPDF(tmpdata, this.entityidss, this.selectedItem.dataCheckId).subscribe(
        (res: Blob) => {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL);
  
          // Additional operations
          
        },
        error => {
          console.error('Error fetching PDF:', error);
        }
      );
      
    }else  if(this.selectedItem.name === 'ID verification'){

      this.calldata.dataCheckIDVResultPDF(tmpdata, this.entityidss, this.idvDataIdentity.id).subscribe(
        (res: Blob) => {
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL);
  
          // Additional operations
          
        },
        error => {
          console.error('Error fetching PDF:', error);
        }
      );
      
    }else  if(this.selectedItem.name === 'Sanctions and PEPs'){

      console.log("Sanctions and PEPs inside pdf view");
      this.calldata.dataChecksanscationVResultPDF(tmpdata, this.entityidss, this.selectedItem.dataCheckId).subscribe(
        (res: Blob) => {
          console.log("Sanctions and PEPs result");
          const fileURL = URL.createObjectURL(res);
          window.open(fileURL);
  
          // Additional operations
          
        },
        error => {
          console.error('Error fetching PDF:', error);
        }
      );
      
    }
    
  }
  logSelectedValue(value: any) {
    this.selectedValue = value;
    console.log("Modal data:", value);
    // console.log("Modal data:", value.id);

    this.selectedJourney = this.journeys.find(journey => journey.id === value);
    console.log("Form Data:", this.selectedJourney);

}

  openModal(modalId: string): void {

    // document.getElementById(modalId).style.display = 'block';

  }

  journeyss:any
    goToNextModal(currentModalId: string, nextModalId: string) {
      console.log("Modal data:", this.selectedValue);
      
      console.log("Modal data:", this.selectedJourney);

      // Hide the current modal and show the next modal
      console.log("showSelectJourneyModal1", this.showSelectJourneyModal);
      // if (currentModalId === 'selectJourneyModal') {
      //   console.log("showSelectJourneyModal2", this.showSelectJourneyModal);
      //   this.closePopup(currentModalId);
      //   this.showSelectJourneyModal = false;
      //   this.showConfigureJourneyModal = true;
      // } else if (currentModalId === 'configureJourneyModal') {
      //   console.log("showSelectJourneyModal3", this.showSelectJourneyModal);

      //   this.showSelectJourneyModal = false;
      //   this.showConfigureJourneyModal = false;
      //   this.showContactModal = true;
      // } else if (nextModalId === 'thankyoumodal') {
      //   console.log("showSelectJourneyModal4", this.showSelectJourneyModal);

      //   this.showSelectJourneyModal = false;
      //   this.showContactModal = false;
        
      //   this.thankyoumodal = true;
        
      

      // }else if (currentModalId === 'thankyoumodal') { 
      //   this.showSelectJourneyModal = false;
      //   this.showContactModal = false;   
      //   this.thankyoumodal = true;
      // }
      if (currentModalId === 'selectJourneyModal') {
        this.showSelectJourneyModal = false;
        this.closePopup(currentModalId);
        this.showConfigureJourneyModal = true;
      } else if (currentModalId === 'configureJourneyModal') {
        this.showConfigureJourneyModal = false;
        this.showContactModal = true;
      } else if (nextModalId === 'thankyoumodal') {
        this.showContactModal = false;
        this.thankyoumodal = true;

        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));       
console.log("inside thank you model");
        let datas = { 
          "entityId": this.entityidss,     
          "accessId": this.portalUser.akcessId,  
          "firstName": this.userDetailss.data.firstName,
          "surname": this.userDetailss.data.surname,
          "emailAddress": this.userDetailss.data.emailAddress,
          "phoneNumber": this.formdata.telephone,
          "title":this.selectedJourney.title,
          "journeyId": this.selectedJourney.id,
          'actorId':this.selectedJourney.actors[0].id
        };
        console.log(datas);

        console.log("outside upload validtion thank you model");
        if (this.uploadForm.valid) {
          console.log("inside upload validtion thank you model");

          console.log("Form is valid");
          console.log("Form Data:", this.formdata);
      
          // Retrieve fullData from sessionStorage
          const userProfileData = JSON.parse(sessionStorage.getItem('userProfileData') || '{}');
          console.log(userProfileData.firstName);
      
          // Call createprocess method
          this.calldata.createprocess(JSON.stringify(datas, null, 2)).subscribe( 
            
            (res: any) => { 
              console.log("hi")
              console.log("Response:", res);    
              if (res.statusCode === 200) {
                console.log('Updated datas:', datas);
              }
            }
          );
        } else {
          console.log("Form is not valid");
        }
      }
      
    }
  goToPreviousModal(previousModalId: string) {
    // Get the current visible modal
    const currentModal = document.querySelector('.modal[style*="display: block"]');
    if (currentModal) {
      currentModal.setAttribute('style', 'display: none');
    }

    // Show the previous modal
    const previousModal = document.getElementById(previousModalId);
    if (previousModal) {
      previousModal.style.display = 'block';
    }
    if (previousModalId === 'selectJourneyModal') {
      this.showSelectJourneyModal = true;
      this.showConfigureJourneyModal = false;
    } else if (previousModalId === 'configureJourneyModal') {
      this.showConfigureJourneyModal = true;
      this.showContactModal = false;
    }
    
  }

  closePopup(modalId: string) {
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.style.display = 'none';
    }
    if(modalId == 'thankyoumodal'){
      this.fetchUserDetails(this.entityidss);
    }
    if (modalId === 'selectJourneyModal') {
      this.showSelectJourneyModal = false;
    } else if (modalId === 'configureJourneyModal') {
      this.showConfigureJourneyModal = false;
    } else if (modalId === 'contactModal') {
      this.showContactModal = false;
    } else if (modalId === 'thankyoumodal') {
      this.thankyoumodal = false;
    }
  }
  




  formatDate(dateString: string): string {
    // console.log("inside dateformat");
    // console.log(dateString);
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  displayStyle = "none"; 

  openPopup() { 
    this.displayStyle = "block"; 
  } 
  selectJourney(event, value) {
    const radioButton = event.target.querySelector('input[type="radio"]');
    radioButton.checked = true;
  }
  
  
  openSidebar(items:any) {
    this.selectedItem = items;
   
    this.sidebarlivelinessVisible = true;


    switch (items.name)  {
      case 'Bank account':
      
        this.fetchJourneyBankProfile();
        break;
      case 'Source of Funds':
        this.sidebarTitle = 'Source of Funds Details';
        this.sidebarContent = 'Content specific to Source of Funds.';
        break;
      case 'Open Banking':
        this.sidebarTitle = 'Open Banking Details';
        this.sidebarContent = 'Content specific to Open Banking.';
        break;
      case 'Address and Mortality':
        this.fetchAddressandMortalityProfile();
      
        break;
        case 'Sanctions and PEPs':
          if (items.status === 1) {
            this.sansactionandpepsDetailss(this.entityidss);
          } else if (items.status === 3) {
            this.sidebarTitle = 'Sanctions and PEPs Details';
            this.sidebarContent = 'Content specific to Sanctions and PEPs for status 3.';       
            // this.fetchSanctionsAndPEPsDetails();
            this.sansactionandpepsDatacheck(this.entityidss,items.dataCheckId);
          }else{
            this.sanctionopenclick();
          }
        break;     
      case 'ID verification':
        (items.status == 1)? this.fetchIDVerificationEntityIDVDetailss(items.idDocuments[0].documentId,items.idvEntryId) :''
        break; 
      case 'Liveness':
        (items.status == 1)? this.fetchIDVerificationEntityIDVDetails(items.entityId,items.idvEntryId) :''
        break; 
          
    }
  
  }
  fetchSanctionsAndPEPsDetails() {   
    
    this.sanctionandpepresultData = {
      status: 3,
      dateCreated: '2023-07-25',
      resultsCount: 5,
      request: {
        firstName: 'John',
        middleNames: 'Doe',
        lastName: 'Smith',
        dateOfBirth: '1990-01-01',
        countries: ['USA', 'UK']
      }
    };
  }

  closeSidebar() {
    this.sidebarVisible = false;
    this.opensidebarpersonal = false;
    this.opensidebaraccount = false;
    this.opensidebargroup = false;
    this.sidebarlivelinessVisible = false;
    
    this.showadressForm = false;
    this.showbankForm = false;
    this.openSidebarQA = false;
    this.openSidebarQAAfter = false;
    this.opensidebarviewfullreport = false;


  }

  openContactInfoModal() {
    this.isModalOpen = !this.isModalOpen;
  }
  showadressForm = false; 
  showbankForm = false; 
  isOpenPersonalDetails = false;
  selectedTiers: string[] = [];
  onCheckboxChange(tier: string, event: any) {
    this.tierSelection[tier] = event.target.checked;

    if (event.target.checked) {
      this.selectedTiers.push(tier);
    } else {
      const index = this.selectedTiers.indexOf(tier);
      if (index > -1) {
        this.selectedTiers.splice(index, 1);
      }
    }
  }


  peptoggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  getSelectedTiers(): string {
    return this.selectedTiers.length ? this.selectedTiers.join(', ') : 'Select tiers';
  }
  addressopenclick() {
    console.log("inside addressopenclick");
    console.log(this.items);
    const itm = this.items.find(item => item.name === "Address and Mortality");

    console.log(itm);
    this.selectedItem = itm;
    this.showadressForm = true;  
    this.sidebarVisible = true;
    this.fetchAddressandMortalityProfile();
    // this.sidebarTitle = 'Address and Mortality Details';
    // this.sidebarContent = 'Content specific to Address and Mortality.';
    // console.log("Item clicked, showadresskForm set to:", this.showadresskForm);
  }
  bankaccountopenclick() {
    console.log("inside bankaccountopenclick");
    const itm = this.items.find(item => item.name === "Bank account");

    console.log(itm);
    this.selectedItem = itm;
  
    this.sidebarVisible = true;
    this.fetchJourneyBankProfile();
    // this.sidebarTitle = 'Address and Mortality Details';
    // this.sidebarContent = 'Content specific to Address and Mortality.';
    // console.log("Item clicked, showadresskForm set to:", this.showadresskForm);
  }
  sanctionopenclick() {
    console.log("inside sanctionopenclick");
    const itm = this.items.find(item => item.name === "Sanctions and PEPs");

    console.log(itm);
    this.selectedItem = itm;
 
    this.sidebarVisible = true;
    this.sidebarTitle = 'Sanctions and PEPs Details';
    this.sidebarContent = 'Content specific to Sanctions and PEPs. ';
    // this.fetchAddressandMortalityProfile();
    // this.sidebarTitle = 'Address and Mortality Details';
    // this.sidebarContent = 'Content specific to Address and Mortality.';
    // console.log("Item clicked, showadresskForm set to:", this.showadresskForm);
  }
  selectCode(code) {
    // this.showadressForm = true;  
    // console.log("Item clicked, showadresskForm set to:", this.showadresskForm);
  }
  bankopenclick() {
    this.showbankForm = true;  
    // console.log("Item clicked, showadresskForm set to:", this.showadresskForm);
  }

  OpenPersonalDetailsSidebar() {
    console.log("OpenPersonalDetailsSidebar");
    this.sidebarPersonalDetailsVisible = true;
    // this.isOpenPersonalDetails = !this.isOpenPersonalDetails;
  }



   firstName = '';
   middleName = '';
   lastName = '';
   nameMatching = 'Exact';
   dobDay = '';
   dobMonth = '';
   dobYear = '';
   dobMatching = 'Same year';
   filter= '';
   bankaccountsortcode='';
   bankaccountnumber='';
   
   sanctionOptions = [
     { id: 'includeMatchesWithoutDob', label: 'Include matches without a date of birth', checked: false },
     { id: 'includePEPs', label: 'Include PEPs', checked: false },
     { id: 'includeFormerPEPs', label: 'Include former PEPs', checked: false },
     { id: 'includeLinkedPEPs', label: 'Include linked PEPs', checked: false },
     { id: 'includeSanctions', label: 'Include sanctions', checked: false },
     { id: 'includeFormerSanctions', label: 'Include former sanctions', checked: false },
     { id: 'includeAdverseMedia', label: 'Include adverse media', checked: false },
     { id: 'includeLawEnforcement', label: 'Include law enforcement', checked: false },
     { id: 'includeDisqualifiedDirector', label: 'Include disqualified director', checked: false },
     { id: 'includeAllCountries', label: 'Include all countries', checked: false }
   ];
   parsedDate: Date;

   runDataCheckSencation() {
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
   

    console.log('First Name:', this.firstName);
    console.log('Middle Name:', this.middleName);
    console.log('Last Name:', this.lastName);
    console.log('Name Matching:', this.nameMatching);
    console.log('Date of Birth:', `${this.dobDay}-${this.dobMonth}-${this.dobYear}`);
    console.log('Date of Birth Matching:', this.dobMatching);
    console.log('Sanction Options:', this.sanctionOptions);
    console.log('PEP Tier Selection:', this.tierSelection);
    console.log("Add to Watch List (on-going monitoring) toggled:", this.addToWatchList);
    console.log("inside else condtion");
    this.parsedDate = new Date(`${this.dobDay}-${this.dobMonth}-${this.dobYear}`);
    console.log(this.parsedDate);
    console.log(this.parsedDate.getTimezoneOffset);
    console.log(this.parsedDate.getTime);
 
//     const result = this.getSelectedTierss(this.tierSelection);
// console.log(result); // Output: [1, 2]
    if (this.firstName==="") {
      alert("error in firstname")
    }else if (this.lastName==="") {
      alert("error in lastname")
    }else{
      console.log("inside else condtion");
      const result = this.getSelectedTierss(this.tierSelection);
console.log(result); // Output: [1, 2]


const formData = 
{
  "accessId":  this.portalUser.akcessId,
  "firstName": this.firstName,
  "middleNames":  this.middleName,
  "surname": this.lastName,
  "datasets": [
      1
  ],
  "dateOfBirth": this.parsedDate,
  "useAllCountries":  this.sanctionOptions[9].checked, //includeAllCountries
  "addToWatchList": false, //false
  "watchListPeriodType": 0, //0
  "watchListPeriod": 0, //0
  "dateOfBirthMatching": (this.dobMatching ==='Exact')? 1:(this.dobMatching ==='Same year')?2:0, //Date of Birth Matching
  "nameMatching": (this.dobMatching ==='Exact')? 0:1,// Name Matching
  "useAllPepTiers": false,// not there
  "includePepTiers": result,
  "includeMatchesWithoutDateOfBirth":  this.sanctionOptions[0].checked ,//includeMatchesWithoutDob
}
console.log(formData);
this.calldata.sanctionsandpepinsert(formData,this.entityidss).subscribe(
  (res: any) => {
    console.log("inside sanctionsandpepinsert");
    // const fileURL = URL.createObjectURL(res);
    // // window.open(fileURL);
    // console.log(fileURL);
    // this.Iddocumentimg = this.sanitizer.bypassSecurityTrustUrl(fileURL);

    // this.Selfieimg = fileURL;
    // Additional operations
    this.fetchUserDetails(this.entityidss);
    this.closeSidebar();
  },
  error => {
    console.error('Error fetching sanctionsandpepinsert:', error);
  }
);

      // sansactionandpepsDetailss();
      alert("success")
      // api call
    }
  
    
    // this.closeSidebar();
   
    // const formData = {
    //   accessId: this.portalUser.akcessId,
    //   firstName: this.firstName,
    //   middleNames: this.middleName,
    //   surname: this.lastName,
    //   // dobDay: this.dobDay,
    //   // dobMonth: this.dobMonth,
    //   // dobYear: this.dobYear,
    //   addressLine1: this.selectedAddresss ? this.selectedAddresss.addressLine1 : '',
    //   addressLine2: this.selectedAddresss ? this.selectedAddresss.addressLine2 : '',
    //   city: this.selectedAddresss ? this.selectedAddresss.town : '',
    //   county: this.selectedAddresss ? this.selectedAddresss.county : '',
    //   country: this.selectedAddresss ? this.selectedAddresss.county : '',

    //   postcode: this.selectedAddresss ? this.selectedAddresss.postcode : '',
    //   sortcode: this.bankaccountsortcode,
    //   accountNumber: this.bankaccountnumber
    // };
  }

   getSelectedTierss(tiersObj: { [key: string]: boolean }): number[] {
    const selectedTiers: number[] = [];
    console.log("inside else getSelectedTierss ");
    console.log(tiersObj);
    Object.entries(tiersObj).forEach(([key, value]) => {
      if (value) {
        switch (key) {
          case "Tier 1":
            selectedTiers.push(1);
            break;
          case "Tier 2":
            selectedTiers.push(2);
            break;
          case "Tier 3":
            selectedTiers.push(3);
            break;
          default:
            break;
        }
      }
    });
    console.log(selectedTiers);
    return selectedTiers;
  }

  runDataCheck() {
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
   
    const formData = {
      accessId: this.portalUser.akcessId,
      firstName: this.firstName,
      middleNames: this.middleName,
      surname: this.lastName,
      // dobDay: this.dobDay,
      // dobMonth: this.dobMonth,
      // dobYear: this.dobYear,
      addressLine1: this.selectedAddresss ? this.selectedAddresss.addressLine1 : '',
      addressLine2: this.selectedAddresss ? this.selectedAddresss.addressLine2 : '',
      city: this.selectedAddresss ? this.selectedAddresss.town : '',
      county: this.selectedAddresss ? this.selectedAddresss.county : '',
      country: this.selectedAddresss ? this.selectedAddresss.county : '',

      postcode: this.selectedAddresss ? this.selectedAddresss.postcode : '',
      sortcode: this.bankaccountsortcode,
      accountNumber: this.bankaccountnumber
    };
    this.calldata.dataCheckBank(formData,this.entityidss).subscribe(
      (res: any) => {
        console.log(res);
        if (res.statusCode === 200) {
          this.fetchUserDetails(this.entityidss);
          this.closeSidebar();
        } else {
          // Handle error or no addresses found
          this.closeSidebar();
          // this.showDropdown = false;
        }
       
      },
      error => {
        // Handle error
        console.error('Error fetching addresses', error);
      }
    );
    console.log('Form Data:', formData);
  }

  runDataCheckAddressMortaility() {
    this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
   
    const formData = {
      accessId: this.portalUser.akcessId,
      firstName: this.firstName,
      middleNames: this.middleName,
      surname: this.lastName,
      // dobDay: this.dobDay,
      // dobMonth: this.dobMonth,
      // dobYear: this.dobYear,
      addressLine1: this.selectedAddresss ? this.selectedAddresss.addressLine1 : '',
      addressLine2: this.selectedAddresss ? this.selectedAddresss.addressLine2 : '',
      city: this.selectedAddresss ? this.selectedAddresss.town : '',
      county: this.selectedAddresss ? this.selectedAddresss.county : '',
      country: this.selectedAddresss ? this.selectedAddresss.county : '',

      postcode: this.selectedAddresss ? this.selectedAddresss.postcode : '',
      sortcode: this.bankaccountsortcode,
      accountNumber: this.bankaccountnumber
    };
    this.calldata.dataCheckAddressMortaility(formData,this.entityidss).subscribe(
      (res: any) => {
        console.log(res);
        if (res.statusCode === 200) {
          this.fetchUserDetails(this.entityidss);
          this.closeSidebar();
        } else {
          // Handle error or no addresses found
          this.closeSidebar();
          // this.showDropdown = false;
        }
       
      },
      error => {
        // Handle error
        console.error('Error fetching addresses', error);
      }
    );
    console.log('Form Data:', formData);
  }

  cancel(): void {
    // Implement your cancel logic here
    console.log('Canceling the operation.');
  
}

  

opensidebarpersonal = false;
  opensidebaraccount = false;
  opensidebargroup = false;
  openSidebarQA = false;
  openSidebarQAAfter = false;
  opensidebarviewfullreport = false;

sancationandpepsDCinnerData:any;
  openviewfullreportsidebar(profile) {
    console.log("openviewfullreportsidebar");
    this.sancationandpepsDCinnerData = profile;
    this.opensidebarpersonal = false;
    this.opensidebaraccount = false;
    this.opensidebargroup = false;
    this.openSidebarQA = false;
    this.sidebarlivelinessVisible = false;
    // this.sidebarVisible = false;
    this.openSidebarQAAfter = false;
    this.opensidebarviewfullreport = true;
  }
  openQAsidebarAfter() {
    console.log("openQAsidebarAfter");
    this.opensidebarpersonal = false;
    this.opensidebaraccount = false;
    this.opensidebargroup = false;
    this.openSidebarQA = false;
    this.openSidebarQAAfter = true;
    this.opensidebarviewfullreport = false;
  }


  openQAsidebar() {
    console.log("openQAsidebar");
    this.opensidebarpersonal = false;
    this.opensidebaraccount = false;
    this.opensidebargroup = false;
    this.openSidebarQA = true;
    this.openSidebarQAAfter = false;
    this.opensidebarviewfullreport = false;
  }

 openpersonalsidebar() {
    console.log("openpersonalsidebar");
    this.opensidebarpersonal = true;
    this.opensidebaraccount = false;
    this.opensidebargroup = false;
    this.openSidebarQA = false;
    this.openSidebarQAAfter = false;
    this.opensidebarviewfullreport = false;
  }

  openaccountsidebar() {
    console.log("openaccountsidebar");
    this.opensidebaraccount = true;
    this.opensidebarpersonal = false;
    this.opensidebargroup = false;
    this.openSidebarQA = false;
    this.openSidebarQAAfter = false;
    this.opensidebarviewfullreport = false;
  }

  opengroupsidebar() {
    console.log("opengroupsidebar");
    this.opensidebargroup = true;
    this.opensidebarpersonal = false;
    this.opensidebaraccount = false;
    this.openSidebarQA = false;
    this.openSidebarQAAfter = false;
    this.opensidebarviewfullreport = false;
  }
  // test
  currentSection: string | null = null;

  banktoggleSection(section: string) {
    this.currentSection = this.currentSection === section ? null : section;
  }
  addtoggleSection(section: string) {
    this.currentSection = this.currentSection === section ? null : section;
    console.log('Current section toggled to:', this.currentSection);
  }
  setActiveTabbs(tab: string) {
    this.activeTabbs = tab;
    // this.pepactiveTabs = tab; 
  }
   setActiveTabsin(tab: string) {
      this.activeTabsin = tab;
      // this.pepactiveTabs = tab; 
    }
    setActiveTabspr(tab: string) {
      this.activeTabspr = tab;
      // this.pepactiveTabs = tab; 
    }
  activeTabss: string = 'checks';

activeTabination: string = 'Pending';
activeTabbs: string = "Profile";
activeTabsin:string ="Current sanctions";
activeTabspr:string ="Current PEPs";

pepactiveTabs: string = 'pending';

  setpepactiveTabs(tab: string) {
    this.pepactiveTabs = tab;
  }




}
