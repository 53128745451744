import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-company-confirm-modal',
    templateUrl: './company-confirm-modal.component.html',
    styleUrls: ['./company-confirm-modal.component.css']
})
export class CompanyConfirmModalComponent implements OnInit {

    constructor(
        private _bsModalRef: BsModalRef
    ) { }

    ngOnInit(): void {
    }

    closeModal() {
        this._bsModalRef.hide();
    }

}
