import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
    selector: 'app-add-eform-modal',
    templateUrl: './add-eform-modal.component.html',
    styleUrls: ['./add-eform-modal.component.css']
})
export class AddEformModalComponent implements OnInit {
    txteforms: string;
    txtdescriptions: string;
    txtinstructions: string = "";
    portalUser;
    domainname: string;
    portalLogo: string;

    constructor(
        private _bsModalRef: BsModalRef,
        private spinner: NgxSpinnerService,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    addGeneratedEform() {
        const tmpdate = moment().format();
        if (this.txteforms) {
            this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
            this.spinner.show();
            this.domainname = sessionStorage.getItem("domainName");
            this.portalLogo = sessionStorage.getItem("portalLogo");

            const tmpdata = {
                'akcessId': this.portalUser.akcessId,
                // 'createdBy': ,
                // ''
                'user': {
                    akcessId: this.portalUser.akcessId,
                    fullName: `${this.portalUser.firstName} ${this.portalUser.lastName}`,
                    email: this.portalUser.email,
                    countryCode: this.portalUser.countryCode,
                    phone: this.portalUser.phone,            
                }, 
                'status': 'alive',
                'formName': this.txteforms,
                'domainName': this.domainname,
                'logo': this.portalLogo,
                'redirectUrl': `${environment.url}saveeformresponse`,
                'AdminId': this.portalUser._id,
                'date': tmpdate,
                fields: '',
                'description': this.txtdescriptions,
                'instruction': this.txtinstructions
            };
            this.calldata.addeform(tmpdata).subscribe((res: any) => {
                if (res.status) {
                    let _id = res['_id'];
                    let name = res['formName'];
                    //$('#addformclose').click();
                    this.closeModal();
                    this.toast.success(res.message);
                    this.router.navigate(['panel/addeform', res.data._id, this.txteforms], { state: { example: 'eforms' } });

                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
            )
        } else {
            this.toast.info('Enter form name.');
        }
    }

}
