<div class="users-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Users</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Users</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <div class="card-title row align-items-center">
                    <div class="col-12 col-md-6 col-lg-3 mb-1 mb-lg-0">
                        <div class="input-group">
                            <input type="text" class="form-control" id="search"
                                   (change)="searchUsers($event.target.value)"
                                   placeholder="Search Users" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 offset-lg-1 mb-1 mb-lg-0">
                        <!--<label><b>Filter by eForm Status:</b></label>-->
                        <select class="form-control" [(ngModel)]="search_userStatus" (change)="this.getuserData(true)">
                            <option selected>Filter By User Status</option>
                            <option value=false>Active</option>
                            <!-- <option value="no">Inactive</option> -->
                            <option value=true>Deleted</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 offset-lg-1 mb-1 mb-lg-0">
                        <!--<label><b>Order by Date:</b></label>-->
                        <select class="form-control" [(ngModel)]="search_usertype" (change)="this.getuserData(true)">
                            <option selected>Filter By User Type</option>
                            <option value="Admin">Admin</option>
                            <option value="Manager">Manager</option>
                            <option value="Representative">Representative</option>
                            <option value="User">User</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3 offset-lg-1 text-right">
                        <button type="button" class="btn waves-effect waves-light btn-info add-btn" data-toggle="modal" data-target="#sendinvitation"
                                (click)="resetform();" tooltip="Send Invitation" placement="bottom" container="body"
                                *ngIf="portalUser.usertype=='Admin'">
                            <i class="fal fa-mail-bulk"></i>
                        </button>
                        <button type="button" class="btn waves-effect waves-light btn-info add-btn ml-3" data-toggle="modal" data-target="#addform"
                                (click)="resetform();act='add';" tooltip="Add User" placement="bottom" container="body"
                                *ngIf="portalUser.usertype=='Admin'">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="portalUser.usertype=='User' || portalUser.usertype=='Client'">
                    <div class="info-section" *ngIf="userlist.length > 0">
                        <h4>{{userlist[0]?.firstName+' '+userlist[0]?.lastName}}</h4>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="font-500">AKcess ID</label>
                                <p>{{userlist[0]?.akcessId}}</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <label class="font-500">Email</label>
                                <p>{{(userlist[0].email)?userlist[0]?.email:'-'}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="font-500">Mobile</label>
                                <p>{{(userlist[0].phone)?userlist[0].phone:'-'}}</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <label class="font-500">Address</label>
                                <p>{{(userlist[0].address)?userlist[0].address:'-'}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="font-500">User Type</label>
                                <p>{{userlist[0].usertype}}</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <label class="font-500">Status</label>
                                <p>{{userlist[0].clientStatus}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="font-500">Products</label>
                                <p>{{userlist[0].clientProducts}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <label class="font-500">USD Account Balance</label>
                                <p>{{userlist[0].USDAccountBalance}}</p>
                            </div>
                            <div class="col-12 col-md-6">
                                <label class="font-500">KD account balance</label>
                                <p>{{userlist[0].KDAccountBalance}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive mb-1" *ngIf="portalUser.usertype!='User' && portalUser.usertype!='Client'">
                    <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" hover="true" small="true">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>User Type</th>
                                <th>Active</th>
                                <th>AKcess Id</th>
                                <th>Created Date</th>
                                <th>Last Login</th>
                                <th class="text-nowrap">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="userlist?.length == 0">
                            <tr>
                                <td colspan="9" class="text-center">No Staff Found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="userlist?.length > 0">
                            <tr *ngFor="let data of userlist | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount };let i = index;">
                                <td>
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{data.firstName}} {{data.lastName}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{data.email}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{data.phone}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{data.usertype}}
                                    </a>
                                </td>
                                <td class="paint-{{(data.removed_flg) ? 'red':'green'}}">
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{(data.removed_flg)?'Deleted':'Active'}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{data.akcessId}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{data.createdon | date:'mediumDate'}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="view(data)" data-toggle="modal" data-target="#viewuser">
                                        {{data.lastLogged | date:'medium'}}
                                    </a>
                                </td>
                                <td>
                                    <a *ngIf="portalUser.usertype=='Admin' && !data.removed_flg" (click)="openedituser(data)"
                                       data-toggle="modal" data-target="#addform" class="mx-2">
                                        <i class="fal fa-edit"></i>
                                    </a>
                                    <a *ngIf="portalUser.usertype=='Admin'&&data.removed_flg==false" (click)="deleteuser(data._id)"
                                       class="paint-red mx-2">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                    <a *ngIf="portalUser.usertype=='Admin'&& data.removed_flg && domainName!='tijari-ak.akcess.app'" (click)="reactiveUser(data._id)"
                                       class="paint-green mx-2">
                                        <i class="fal fa-redo-alt" tooltip="Click to reactivate user" container="body"></i>
                                    </a>
                                    <button type="button" class="ml-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="viewEformsResponses(data.akcessId)"
                                            data-toggle="modal" data-target="#vieweformresponses"
                                            *ngIf="data.usertype=='Client'">
                                        eForm Responses
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="w-100">
                            <tr>
                                <td colspan="9">
                                    <div class="table-page" *ngIf="userlist?.length > 10">
                                        <div class="page-controls">
                                            <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                 class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                                 nextLabel="Next"></pagination-controls>
                                        </div>
                                        <div class="page-size">
                                            <label class="mb-0 mr-2">Item per page</label>
                                            <select #val (change)="changePage(val.value)" class="form-control form-control-sm"
                                                    id="pageSelector">
                                                <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Change Passcode -->
<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Change Passcode</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fal fa-lock"></i></div>
                    </div>
                    <input type="password" class="form-control" [(ngModel)]="passcode" placeholder="Password">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="changepasscode()">Save changes</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- Send Invitaion Modal -->
<div class="modal" id="sendinvitation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Send Invitation</h4>
                <button (click)="resetform()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div class="d-flex justify-content-around pb-2">
                    <div class="custom-control custom-radio">
                        <input type="radio" [(ngModel)]="phone_or_email" value="email" id="email"
                               (click)="placeholder='Enter Email'" class="with-gap radio-col-light-blue">
                        <label for="email" class="font-500">Email</label>
                        <!--<input type="radio" [(ngModel)]="phone_or_email" value="email" id="email"
                        (click)="placeholder='Enter email addresses comma separated'"
                        class="custom-control-input" required>
                         <label for="email" class="custom-control-label">Email</label>-->
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" [(ngModel)]="phone_or_email" value="phone" id="phone"
                               (click)="placeholder='Enter Mobile Number'" class="with-gap radio-col-light-blue">
                        <label for="phone" class="font-500">Mobile</label>
                        <!--<input type="radio" [(ngModel)]="phone_or_email" value="phone" id="phone"
                           (click)="placeholder='Enter phone numbers comma separated without space'"
                           class="custom-control-input">
                        <label for="phone" class="custom-control-label">Phone</label>-->
                    </div>
                </div>
                <!--<div class="row">
                    <div class="col-12" *ngIf="phone_or_email=='email'">
                        <ng-select [items]="userDataEmail"
                                   [addTag]="addTagFn"
                                   [hideSelected]="true"
                                   multiple="true"
                                   bindLabel="name"
                                   placeholder="Select/Enter Email"
                                   [(ngModel)]="userwithChips"
                                   appendTo="body">
                        </ng-select>
                        <div class="valid-feedback">Type the email address and press ENTER</div>
                    </div>
                    <div class="col-12" *ngIf="phone_or_email=='phone'">
                        <ng-select [items]="userDataEmail"
                                   [addTag]="addTagFn"
                                   [hideSelected]="true"
                                   multiple="true"
                                   bindLabel="name"
                                   placeholder="Select/Enter Email"
                                   [(ngModel)]="userwithChips"
                                   appendTo="body">
                        </ng-select>
                        <div class="valid-feedback">Select Country Code and Enter the phone number</div>
                    </div>
                </div>-->
                <div class="invitation-textarea">
                    <textarea class="form-control" rows="2"
                              (keyup)="checkUserInput($event.target.value)"
                              [(ngModel)]="emails" placeholder="{{placeholder}}" name="emails"
                              required></textarea>
                    <!--<label *ngIf="phone_or_email=='email'"></label>-->
                    <div class="valid-feedback" *ngIf="phone_or_email=='phone'">For Example: +965xxxxxxx,+965xxxxxxx</div>
                </div>
                <div class="invitation-ckeditor mt-2">
                    <label class="font-500">Body</label>
                    <div *ngIf="phone_or_email=='email'">
                        <ckeditor height="150px" [editor]="Editor"
                                  [(ngModel)]="editorData"></ckeditor>
                    </div>
                    <div>
                        <textarea class="form-control" *ngIf="phone_or_email=='phone'" rows="2"
                                  [(ngModel)]="editorData" placeholder="" required></textarea>
                    </div>
                </div>
                <div *ngIf="invalidemails!=null" class="invalid-feedback">
                    {{invalidemails}}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="sendForm()">Send</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="prepareformclose"
                        data-dismiss="modal">
                    Close
                </button>

            </div>
        </div>
    </div>
</div>

<!-- Add/Edit User Modal -->
<div class="modal" id="addform" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">{{(act=="add")?'Add':'Edit'}} User Information</h4>
                <button (click)="resetform()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body" *ngIf="act=='add'">
                <div>
                    <label class="font-500">Enter AKcess ID to add user</label>
                    <input type="text" name="searchakcessid" [(ngModel)]="searchakcessid"
                           class="form-control" placeholder="Enter AKcess ID">
                </div>
            </div>
            <div class="modal-footer" *ngIf="act=='add'">
                <button type="button" (click)="getDataByAkcessId()"
                        class="btn waves-effect waves-light btn-info">
                    Add
                </button>
            </div>
            <form (ngSubmit)="adduser()" [formGroup]="adduserform" class="login-container">
                <input *ngIf="act=='add'" type="hidden" formControlName="_id">
                <div class="modal-body" *ngIf="akcessid_success==true || act=='edit'">
                    <div class="form-group">
                        <label class="font-500" for="firstName">First Name</label>
                        <input class="form-control" formControlName="firstName"
                               placeholder="Enter first name" type="text" required>
                        <div *ngIf="isSubmitted && formControls.firstName.errors">
                            <div *ngIf="formControls.firstName.errors.required" class="invalid-feedback">
                                First Name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500" for="lastName">Last Name</label>
                        <input class="form-control" formControlName="lastName"
                               placeholder="Enter last name" type="text">
                        <div *ngIf="isSubmitted && formControls.lastName.errors">
                            <div *ngIf="formControls.lastName.errors.required" class="invalid-feedback">
                                Last Name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500" for="phone">Mobile</label>
                        <input class="form-control" formControlName="phone"
                               placeholder="Enter Mobile number" type="text">
                        <div *ngIf="isSubmitted && formControls.phone.errors?.required">
                            <div *ngIf="formControls.phone.errors.required" class="invalid-feedback">
                                Mobile number is required
                            </div>
                        </div>
                        <div *ngIf="isSubmitted && formControls.phone.errors?.pattern">
                            <div *ngIf="formControls.phone.errors?.pattern" class="invalid-feedback">
                                Mobile number is not valid
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500" for="email">Email</label>
                        <input class="form-control" formControlName="email"
                               placeholder="Enter email" type="text">
                        <div *ngIf="isSubmitted && formControls.email.errors?.required">
                            <div *ngIf="formControls.email.errors?.required" class="invalid-feedback">Email is required</div>
                        </div>
                        <div *ngIf="isSubmitted && formControls.email.errors?.email">
                            <div *ngIf="formControls.email.errors?.email" class="invalid-feedback">Email is invalid</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500" for="email">Address</label>
                        <input class="form-control" formControlName="address"
                               placeholder="Enter address" type="text">
                        <div *ngIf="isSubmitted && formControls.address.errors?.required">
                            <div *ngIf="formControls.address.errors?.required" class="invalid-feedback">
                                Address is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="font-500" for="email">User Type</label>
                        <select formControlName="usertype"
                                class="form-control custom-select">
                            <option value="" selected>Select Type</option>
                            <option value="Admin">Admin</option>
                            <option value="Manager">Manager</option>
                            <option value="Representative">Representative</option>
                            <!-- <option value="Client">Client</option> -->
                            <option value="User">User</option>
                        </select>
                        <div *ngIf="isSubmitted && formControls.usertype.errors?.required">
                            <div *ngIf="formControls.usertype.errors?.required" class="invalid-feedback">
                                User Type is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.usertype=='Client'">
                        <label class="font-500" for="clientStatus">Client Status</label>
                        <select formControlName="clientStatus"
                                class="form-control custom-select">
                            <option value="" selected>Select Type</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                            <option value="Blocked">Blocked</option>
                        </select>
                        <div *ngIf="isSubmitted && formControls.usertype.errors?.required">
                            <div *ngIf="formControls.usertype.errors?.required" class="invalid-feedback">
                                User Type is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.usertype=='Client'">
                        <label class="font-500" for="clientProducts">Products</label>
                        <input class="form-control"
                               formControlName="clientProducts" placeholder="Enter products"
                               type="text">
                        <div *ngIf="isSubmitted && formControls.clientProducts.errors">
                            <div *ngIf="formControls.clientProducts.errors.required" class="invalid-feedback">
                                Products is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.usertype=='Client'">
                        <label class="font-500" for="USDAccountBalance">USD Account Balance</label>
                        <input class="form-control"
                               formControlName="USDAccountBalance"
                               placeholder="Enter USD account balance" type="text">
                        <div *ngIf="isSubmitted && formControls.USDAccountBalance.errors">
                            <div *ngIf="formControls.USDAccountBalance.errors.required" class="invalid-feedback">
                                USD account balance is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="this.usertype=='Client'">
                        <label class="font-500" for="KDAccountBalance">KD Account Balance</label>
                        <input class="form-control"
                               formControlName="KDAccountBalance"
                               placeholder="Enter KD account balance" type="text">
                        <div *ngIf="isSubmitted && formControls.KDAccountBalance.errors">
                            <div *ngIf="formControls.KDAccountBalance.errors.required" class="invalid-feedback">
                                KD account balance is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn waves-effect waves-light btn-info" *ngIf="akcessid_success==true || act=='edit'">Save</button>
                    <button type="button" (click)="resetform()" class="btn waves-effect waves-light btn-danger" id="addformclose"
                            data-dismiss="modal"
                            *ngIf="akcessid_success==true || act=='edit'">
                        Close
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- View Modal -->
<div class="modal" id="viewuser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">{{fullname}} Details</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="fields">
                    <div class="form-group">
                        <label class="font-500">AKcess ID</label>
                        <input class="form-control" type="text" value="{{akcessId}}" readonly>
                    </div>
                    <div class="form-group">
                        <label class="font-500">Email</label>
                        <input class="form-control" type="text" value="{{(email)?email:'-'}}" readonly>
                    </div>
                    <div class="form-group">
                        <label class="font-500">Mobile</label>
                        <input class="form-control" type="text" value="{{(phone)?phone:'-'}}" readonly>
                    </div>
                    <div class="form-group">
                        <label class="font-500">Address</label>
                        <textarea class="form-control" rows="2" readonly>
                            {{akcessId}}
                        </textarea>
                    </div>
                    <div class="form-group">
                        <label class="font-500">User Type</label>
                        <input class="form-control" type="text" value="{{usertype}}" readonly>
                    </div>
                    <div class="form-group" *ngIf="usertype=='Client'">
                        <label class="font-500">Client Status</label>
                        <input class="form-control" type="text" value="{{clientStatus}}" readonly>
                    </div>
                    <div class="form-group" *ngIf="usertype=='Client'">
                        <label class="font-500">Client Products</label>
                        <input class="form-control" type="text" value="{{clientProducts}}" readonly>
                    </div>
                    <div class="form-group" *ngIf="usertype=='Client'">
                        <label class="font-500">USD account balance</label>
                        <input class="form-control" type="text" value="{{USDAccountBalance}}" readonly>
                    </div>
                    <div class="form-group" *ngIf="usertype=='Client'">
                        <label class="font-500">KD account balance</label>
                        <input class="form-control" type="text" value="{{KDAccountBalance}}" readonly>
                    </div>
                    <div class="form-group" *ngIf="usertype=='Client'">
                        <label class="font-500">Active</label>
                        <input class="form-control" type="text" value="{{(active_flg==true)?'Yes':'No'}}" readonly>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- View Eformresponses Modal -->
<div class="modal" id="vieweformresponses" tabindex="-1" role="dialog"
     aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">{{fullname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" hover="true" small="true">
                        <thead>
                            <tr>
                                <th>Full name</th>
                                <th>AKcessId</th>
                                <th>eForm name</th>
                                <th>Status</th>
                                <th>Last Login</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="eformresponse?.length == 0">
                            <tr>
                                <td colspan="5" class="text-center">No eForms Responses Found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="eformresponse?.length > 0">
                            <tr *ngFor="let data of eformresponse;let i = index;">
                                <td>
                                    <a (click)="viewEformResponses(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id);resetVerifiedItems()"
                                       data-toggle="modal" data-target="#viewform">
                                        {{data.userdata[0].firstName}} {{data.userdata[0].lastName}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="viewEformResponses(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id);resetVerifiedItems()"
                                       data-toggle="modal" data-target="#viewform">
                                        {{data.userdata[0].akcessId}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="viewEformResponses(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id);resetVerifiedItems()"
                                       data-toggle="modal" data-target="#viewform">
                                        {{data.formName}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="viewEformResponses(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id);resetVerifiedItems()"
                                       data-toggle="modal" data-target="#viewform">
                                        {{data.status}}
                                    </a>
                                </td>
                                <td>
                                    <a (click)="viewEformResponses(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id);resetVerifiedItems()"
                                       data-toggle="modal" data-target="#viewform">
                                        {{data.lastLogged | date:'medium'}}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- View Form Modal -->
<div class="modal" id="viewform" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">{{formname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div class="table-responsive mb-1">
                    <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" hover="true" small="true">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Check to verify</th>
                                <th>Grade/Verification</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of fields;let i = index;">
                                <td *ngIf="data.keytype == 'text' || data.keytype == 'Text'">
                                    <label class="font-500">{{data.key}}</label>
                                    <input type="{{data.keytype}}" class="form-control" value="{{data.value}}"
                                           placeholder="{{data.key}}" readonly>
                                </td>
                                <td *ngIf="data.keytype == 'File' || data.keytype == 'file'">
                                    <a href="{{imageUrl}}{{data.val.docUrl}}" target="_blank"> Document {{i}}</a>
                                </td>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <ng-container *ngIf="!data.isDocFetched">
                                            <input type="checkbox" name="chk" [(ngModel)]="data.isverified"
                                                   (change)="setVerifiedItem($event, i)" id="chk{{i}}">
                                            <!--<label class="custom-control-label" for="chk{{i}}"></label>-->
                                        </ng-container>
                                    </div>
                                </td>
                                <td>
                                    <button type="button" data-toggle="modal" data-target="#viewgrade" (click)="viewGradeVer(data)"
                                            class="btn btn-sm waves-effect waves-light btn-info" id="sub">
                                        View
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <label class="font-500">Choose your eForm Status :</label>
                    <select class="form-control custom-select" [(ngModel)]="eformStatus">
                        <option value="" selected>Select</option>
                        <option *ngFor="let status of Status" [ngValue]="status">
                            {{status}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="onSubmit()" id="sub">
                    Submit
                </button>
                <button type="button" class="btn waves-effect waves-light btn-primary" id="exportbtnpdf"
                        (click)="generatePdf(formname, fields['fullname'])">
                    Export to PDF
                </button>
                <button type="button" class="btn waves-effect waves-light btn-primary" id="exportbtnxls"
                        (click)="exportexcel(formname, fields['fullname'])">
                    Export to XLSX
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- View Grade -->
<div class="modal" id="viewgrade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">{{formname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <p>{{gradedata["key"]}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" hover="true" small="true" id="xls_formfields">
                <tr>
                    <th colspan="2">{{formname}}</th>
                </tr>
                <tr *ngFor="let data of fields; let i = index;">
                    <th>
                        {{data.key}}
                    </th>
                    <td *ngIf="data.keytype == 'text' || data.keytype == 'Text'">
                        {{data.value}}
                    </td>
                    <td *ngIf="data.keytype == 'File' || data.keytype == 'file'">
                        <a href="{{imageUrl}}{{data.val.docUrl}}" target="_blank"> Document {{i}}</a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div id="pdf_formfields" style="display: none;">
    <h3 class="text-center">{{formname}}</h3>
    <div *ngFor="let data of fields; let i = index;">
        <p><span class="font-500">{{data.key}}:</span> {{data.value}}</p>
    </div>
</div>
