<div class="property-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/property']">Property</a>
                </li>
                <li class="breadcrumb-item active">View Property</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7 pdf-col">
                    <div id="contentToConvert" class="card property-card">
                        <div class="card-body">
                            <img
                                *ngIf="property.photo"
                                src="{{ getFullURL(property.photo) }}"
                                class="img-fluid property-img"
                                style="
                                    width: 100%;
                                    height: auto;
                                    max-height: 280px;
                                "
                            />
                            <qrcode
                                #parent
                                class="qr-code"
                                *ngIf="property._id"
                                [qrdata]="qrCodeData"
                                [size]="128"
                                [level]="'S'"
                            ></qrcode>
                            <div class="d-block mt-3">
                                <p
                                    style="
                                        font-size: 20px;
                                        margin-bottom: 5px;
                                        font-weight: 500;
                                    "
                                >
                                    {{ property?.name }}
                                </p>
                                <p
                                    style="
                                        font-size: 16px;
                                        margin-bottom: 5px;
                                        font-weight: 400;
                                    "
                                >
                                    {{ property.description }}
                                </p>
                                <p
                                    style="
                                        font-size: 20px;
                                        color: #dc3545;
                                        font-weight: 500;
                                    "
                                >
                                    <i class="fal fa-pound-sign"></i>
                                    {{
                                        property?.price
                                            | number : "1.0" : "en-US"
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4">
                        <qrcode
                            #parent
                            class="qr-code-export d-none"
                            *ngIf="property._id"
                            [qrdata]="qrCodeData"
                            [size]="128"
                            [width]="500"
                            [level]="'S'"
                        ></qrcode>
                        <button
                            class="btn btn-info noprint mr-3"
                            (click)="exportPDF()"
                        >
                            Save as PDF
                        </button>
                        <button
                            class="btn btn-info noprint"
                            (click)="saveAsImage()"
                        >
                            Save QR Code
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- /Page Wrapper -->
