<!-- View eForm Modal -->
<!--<div class="modal-dialog modal-dialog-centered eform-modal" role="document" [ngClass]="{'modal-xl': fields?.length > 0 }">-->
<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewEformModalLabel">{{formName}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <input type="hidden" [(ngModel)]="eFormFields" value="{{eFormFields}}" />
        <input type="hidden" [(ngModel)]="eFormId" value="{{eFormId}}" />
        <input type="hidden" [(ngModel)]="eFormDescription" value="{{eFormDescription}}" />
        <input type="hidden" [(ngModel)]="eFormUrl" value="{{eFormUrl}}" />
        <input type="hidden" [(ngModel)]="eFormRedirectURL" value="{{eFormRedirectURL}}" />
        <div class="row align-items-center">
            <div class="col-md-3">
                <img class="loader-img" *ngIf="!qrcodeData" src="./assets/img/loading.gif" />
                <qrcode class="qr-code" *ngIf="qrcodeData!=''" [qrdata]="qrcodeData" [size]="256" [level]="'M'">
                </qrcode>
            </div>
            <div class="col-md-9">
                <div class="input-group">
                    <!-- <a (click)="viewscaneform(scaneformid)" data-toggle="modal" data-target="#viewscaneform" class="ml-3 list-link paint-light-blue">{{this.eformurl}}</a> -->
                    <input type="text" value="{{qrShortLink}}" #userinput class="form-control" readonly>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" title="Copy"
                            (click)="callServiceToCopy(userinput); viewScanEform(eFormId)" type="button">
                            <i class="fas fa-copy"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Form Normal Body -->
        <div class="row">
            <div class="col-md-6" *ngFor="let data of eFormFields;let i = index;">
                <div *ngIf="!(data.section_id)">
                    <label for={{data.labelname}} class="col-form-label font-500">
                        {{data.labelname}}<span *ngIf="data.is_mandatory === 'yes'" class="required-label">*</span>
                    </label>
                    <div class="position-relative" [ngSwitch]="data.keytype">
                        <!--Common Fields-->
                        <div *ngSwitchDefault>
                            <input type="{{data.keytype}}" class="form-control" id={{data.key}}
                                placeholder="{{data.key}}" value="{{data.field_value}}" disabled>
                        </div>
                        <!--Textarea Field-->
                        <div *ngSwitchCase="'address'">
                            <textarea class="form-control" id={{data.key}} rows="2"
                                disabled>{{data.field_value}}</textarea>
                        </div>
                        <!--File Field-->
                        <div *ngSwitchCase="'file'">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id={{data.key}}
                                    value="{{data.field_value}}" disabled>
                                <label class="custom-file-label" for={{data.field_value}}>Choose file</label>
                            </div>
                        </div>
                        <!--Radio Field-->
                        <div *ngSwitchCase="'radio'">
                            <div *ngFor="let option of data.options,let j=index">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" value={{data.options[j].lable}}
                                        disabled>
                                    <label class="form-check-label">{{data.options[j].lable}}</label>
                                </div>
                            </div>
                        </div>
                        <!--Checkbox Field-->
                        <div *ngSwitchCase="'checkbox'">
                            <div *ngFor="let option of data.options, let j=index">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value={{data.options[j].lable}}
                                        disabled>
                                    <label class="form-check-label">{{data.options[j]['key']}}</label>
                                </div>
                            </div>
                        </div>
                        <!--Select Field-->
                        <!--<div *ngSwitchCase="'select'">
                            <select class="form-control">
                                <option *ngFor="let option of data.options" value="{{option.key}}" id={{option.uid}}>{{option.lable}}</option>
                            </select>
                        </div>-->
                        <!--Select Field-->
                        <!--<div *ngSwitchCase="'list'">
                            <select class="form-control">
                                <option *ngFor="let option of data.options" value="{{option.key}}" id={{option.uid}}>{{option.lable}}</option>
                            </select>
                        </div>-->
                    </div>
                    <!--Verification Fields-->
                    <div class="my-2" style="font-size: 12px;" *ngIf="data.keytype=='text'">
                        <span class="font-500">Field needs to be verified: </span>{{data.file_verified}}
                    </div>
                    <!--File Verification Fields-->
                    <div class="row" *ngIf="data.keytype=='file'">
                        <div class="my-2 col-6" style="font-size: 12px;">
                            <span class="font-500">Signature required:</span> {{data.signature_required}}
                        </div>
                        <div class="my-2 col-6" style="font-size: 12px;">
                            <span class="font-500">File needs to be verified: </span>{{data.file_verified}}
                        </div>
                    </div>
                    <!--Grade Fields-->
                    <div class="my-2" style="font-size: 12px;" *ngIf="data.file_verified=='yes'">
                        <span class="font-500">Verification grade : </span>
                        <span *ngIf="data.verification_grade == 'G'">Goverment </span>
                        <span *ngIf="data.verification_grade == 'F'">Financial </span>
                        <span *ngIf="data.verification_grade == 'T'">Telecom </span>
                        <span *ngIf="data.verification_grade == 'A'">AKcess </span>
                        <span *ngIf="data.verification_grade == 'O'">Other</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Form Section Body -->
        <div class="form-row" *ngFor="let data of eFormFields;let i = index;">
            <div *ngIf="data.section_id" class="form-group col-12 section-body">
                <!-- Section Label -->
                <div class="form-group text-center">
                    <h3 class="section-label">Section Lable : {{data.labelname}}</h3>
                </div>
                <!-- Section Body -->
                <div *ngFor="let section of data.sectionfields | keyvalue;let k = index;">
                    <div class="form-group section-title">
                        <h4 class="m-0">{{section.key}}</h4>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6" *ngFor="let sectionFields of section.value;let x = index;">
                            <label for={{sectionFields.labelname}} class="font-500">{{sectionFields.labelname}}</label>
                            <div class="position-relative" [ngSwitch]="sectionFields.keytype">
                                <!--Common Fields-->
                                <div class="position-relative" *ngSwitchDefault>
                                    <input type="{{sectionFields.keytype}}" class="form-control"
                                        placeholder="{{sectionFields.keytype}}" id={{sectionFields.key}}
                                        value="{{sectionFields.field_value}}">
                                </div>
                                <!--Textarea Field-->
                                <div class="position-relative" *ngSwitchCase="'address'">
                                    <textarea class="form-control" placeholder="{{sectionFields.keytype}}"
                                        id={{sectionFields.key}} value="{{sectionFields.field_value}}"
                                        rows="2"></textarea>
                                </div>
                                <!--File Field-->
                                <div *ngSwitchCase="'file'">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input"
                                            placeholder="{{sectionFields.keytype}}" id={{sectionFields.key}}
                                            value="{{sectionFields.field_value}}">
                                        <label class="custom-file-label" for={{sectionFields.field_value}}>Choose
                                            file</label>
                                    </div>
                                </div>
                                <!--Radio Field-->
                                <div *ngSwitchCase="'radio'">
                                    <div *ngFor="let option of sectionFields.options,let j=index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" id={{option.uid}}
                                                name={{sectionFields.labelname}} value={{option.lable}}>
                                            <label class="form-check-label" for={{option.value}}>
                                                {{option.lable}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!--Checkbox Field-->
                                <div *ngSwitchCase="'checkbox'">
                                    <div *ngFor="let option of sectionFields.options, let j=index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id={{option.uid}}
                                                name={{sectionFields.labelname}} value={{option.lable}}>
                                            <label class="form-check-label" for={{option.value}}>
                                                {{option.lable}}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <!--Select Field-->
                                <!--<div *ngSwitchCase="'select'">
                                    <select class="form-control">
                                        <option *ngFor="let option of sectionFields.options" value="{{option.key}}" id={{option.uid}}>{{option.lable}}</option>
                                    </select>
                                </div>-->
                                <!--List Field-->
                                <!--<div *ngSwitchCase="'list'">
                                    <select class="form-control">
                                        <option *ngFor="let option of sectionFields.options" value="{{option.key}}" id={{option.uid}}>{{option.lable}}</option>
                                    </select>
                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div *ngFor="let data of fields;let i = index;" class="form-group">
            <div class="mb-2" *ngIf="data.section_lable && (i === 0 || (i>0 && fields[i-1].section_lable != fields[i].section_lable) )">
                <h5>Section Lable : {{data.section_lable}}</h5>
                <hr class="m-0" />
            </div>
            <div class="form-check disabled mb-2" *ngIf="data.section_title && (i === 0 || (i>0 && fields[i-1].section_title != fields[i].section_title) )">
                <input *ngIf="data.section_type === 'radio'" class="form-check-input" type="radio" name="{{data.section_title}}" id="dataSectionRadio" disabled>
                <label *ngIf="data.section_type === 'radio'" class="form-check-label" for="dataSectionRadio">
                    {{data.section_title}}
                </label>
            </div>
            <div class="form-group">
                <label for="demo">{{data.labelname}}</label>
                <div *ngIf="data.keytype !='radio' && data.keytype !='checkbox'">
                    <input type="{{data.keytype}}" disabled class="form-control"
                           placeholder="{{data.key}}" value="{{data.field_value}}">
                </div>
            </div>
            <div *ngIf="data.keytype =='radio' || data.keytype =='checkbox'">
                <div *ngFor="let item of data.options,let i = index">
                    <div class="form-check disabled mb-2">
                        <input class="form-check-input" type="{{data.keytype}}" placeholder="{{data.options[i]['key']}}" disabled>
                        <label class="form-check-label">
                            {{data.options[i].lable}}
                        </label>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
    <div class="modal-footer" *ngIf="componentType == 'eforms'">
        <button type="button" class="btn waves-effect waves-light btn-info"
            (click)="openEditEform(eFormId,formName)">Edit eFrom</button>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">Close</button>
    </div>
</div>