<div class="user-profile-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">{{loggedInName}} Profile</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Profile</li>
            </ol>
        </div>
    </div>
    <!--Main Section-->
    <section>
        <div class="container-fluid">
            <div class="card card-outline-info">
                <div class="card-header">
                    <h4 class="m-b-0 text-white">Portal Profile</h4>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label class="font-500">Portal Label</label>
                            <input type="text" class="form-control" value="{{portalLabel}}" readonly>
                        </div>
                        <div class="form-group col-lg-6">
                            <label class="font-500">Portal Contacts</label>
                            <input type="text" class="form-control" value="{{portalContacts}}" readonly>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label class="font-500">Portal Logo</label>
                            <img class="logo-image-preview" [src]="imageurl" id="imagePreview" alt="{{portalLabel}}" title="{{portalLabel}}">
                        </div>
                        <div class="form-group col-lg-6">
                            <label class="font-500">Updated</label>
                            <input type="text" class="form-control" value="{{updated_at | date:'medium'}}" readonly>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>