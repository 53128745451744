<div class="location-page">
  <!-- Bread crumb -->
  <div class="row page-titles">
    <div class="col-md-12">
      <ol class="breadcrumb float-left">
        <li class="breadcrumb-item">
          <a [routerLink]="['/panel/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/panel/access-control']">Access Control</a>
        </li>
        <li class="breadcrumb-item active" *ngIf="mode == 'create'">
          Add Access Control
        </li>
        <li class="breadcrumb-item active" *ngIf="mode == 'edit'">
          Edit Access Control
        </li>
      </ol>
    </div>
  </div>
  <!-- Main Section -->
  <section>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title mb-0">
            {{ mode == "edit" ? "Edit" : "Add" }} Access Control
          </h3>
        </div>
        <div class="card-body">
          <form
            class="location-form"
            [formGroup]="accessControlForm"
            (ngSubmit)="submitAccessControlForm(accessControlForm.value)"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label required">Name</label>
                  <input
                    type="text"
                    name="locationName"
                    class="form-control"
                    formControlName="locationName"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label required"
                    >Maximun number of Staff</label
                  >
                  <input
                    type="number"
                    min="0"
                    name="numberOfStaff"
                    formControlName="numberOfStaff"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div *ngFor="let schedule of schedules().controls; let i = index">
              <div
                class="row schedule-list align-items-center"
                [formGroup]="schedule"
              >
                <div class="col">
                  <ng-multiselect-dropdown
                    formControlName="days"
                    [placeholder]="'Select Days'"
                    [data]="dayOptions"
                    [settings]="dropdownSettings"
                  >
                    <ng-template
                      #optionsTemplate
                      let-item
                      let-option="option"
                      let-id="id"
                      let-isSelected="isSelected"
                    >
                      <div
                        style="
                          padding: 1px 10px;
                          margin-right: 10px;
                          right: 2px;
                          top: -2px;
                        "
                      >
                        <label
                          style="
                            color: #333;
                            min-width: 130px;
                            font-weight: normal;
                          "
                          [ngStyle]="{
                            'font-weight': isSelected ? 'bold' : ' normal',
                            color: getItems[id].isDisabled ? 'lightgrey' : ''
                          }"
                          >{{ getItems[id].label }}</label
                        >
                      </div>
                    </ng-template>
                    <ng-template
                      #optionSelectedTemplate
                      let-option="option"
                      let-id="id"
                    >
                      <div>
                        {{ option }}
                      </div>
                    </ng-template>
                  </ng-multiselect-dropdown>
                </div>
                <div class="col">
                  <div class="d-flex align-items-center">
                    <label class="form-label required mr-2">Open From:</label>
                    <timepicker
                      formControlName="openFrom"
                      [meridian]="meridian"
                    ></timepicker>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex align-items-center">
                    <label class="form-label required mr-2">Open To: </label>
                    <timepicker formControlName="openTo"></timepicker>
                  </div>
                </div>
                <div class="col-2">
                  <button
                    (click)="addRemoveDay(i)"
                    class="btn waves-effect waves-light btn-{{
                      i == 0 ? 'info' : 'danger'
                    }}"
                  >
                    <i class="fa fa-{{ i == 0 ? 'plus' : 'trash' }}"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label required">Address</label>
                  <textarea
                    name="address"
                    class="form-control"
                    formControlName="address"
                    required
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <div class="form-check custom-control pl-0">
                    <input
                      type="checkbox"
                      formControlName="onlyAllowAdmin"
                      id="onlyAllowAdmin"
                      class="form-check-input filled-in chk-col-light-blue"
                    />
                    <label class="form-check-label" for="onlyAllowAdmin">
                      Only allow admins</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="text-left mt-4">
                  <button
                    type="submit"
                    class="btn btn-info"
                    *ngIf="mode == 'create'"
                    [disabled]="!accessControlForm.valid"
                  >
                    Add Access Control
                  </button>
                  <button
                    type="submit"
                    class="btn btn-info"
                    *ngIf="mode == 'edit'"
                    [disabled]="!accessControlForm.valid"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- /Page Wrapper -->
