import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Property } from "src/app/models/property";
import { environment } from "src/environments/environment";
import { CommonService } from "../../../../_services/common.service";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

@Component({
    selector: "app-view-property",
    templateUrl: "./view.component.html",
    styleUrls: ["./view.component.css"],
})
export class ViewPropertyComponent implements OnInit {
    @ViewChild("pdfTable") pdfTable!: ElementRef;
    propertyId: string;
    property: Property = new Property();
    qrCodeData: any;
    storage_url = environment.url + "storage/";
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        public commonService: CommonService
    ) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.propertyId = params.propertyId;
        });
    }

    ngOnInit(): void {
        this.storage_url = this.ds.getStorageUrl();
        if (this.propertyId) {
            this.spinner.show();
            this.ds.getProperty(this.propertyId).subscribe(
                (response: any) => {
                    this.spinner.hide();
                    if (response.status) {
                        this.property = response.data;
                        this.qrCodeData = `https://${environment.domainName}/realestate/property-enquiry/${this.commonService.selectedCompanyDeatils.dbName}/${this.propertyId}`;
                        //this.qrCodeData = `https://akcess.io/marketplace/realestate/propertyrequestform/?property_id=${this.propertyId}&db_name=${this.commonService.selectedCompanyDeatils.dbName}&property=${this.property.name}`;
                    } else {
                        this.toast.error(response.message);
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        }
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/property/${file}`;
    }

    printQRCode() {
        window.print();
    }
    private convertBase64ToBlob(Base64Image: string) {
        // split into two parts
        const parts = Base64Image.split(";base64,");
        // hold the content type
        const imageType = parts[0].split(":")[1];
        // decode base64 string
        const decodedData = window.atob(parts[1]);
        // create unit8array of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);
        // insert all character code into uint8array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }
        // return blob image after conversion
        return new Blob([uInt8Array], { type: imageType });
    }

    saveAsImage() {
        let parentElement = null;

        parentElement = document.querySelector(".qr-code-export canvas");

        if (parentElement) {
            // converts base 64 encoded image to blobData
            let blobData = this.convertBase64ToBlob(
                parentElement.toDataURL("image/png")
            );
            // saves as image
            const blob = new Blob([blobData], { type: "image/png" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            // name of the file
            link.download = "QR-Code-" + Date();
            link.click();
        }
    }

    public exportPDF() {
        var data = document.getElementById("contentToConvert");
        html2canvas(data, { useCORS: true, allowTaint: true }).then(
            (canvas) => {
                var imgWidth = 309;
                var pageHeight = 295;
                var imgHeight = (canvas.height * imgWidth) / canvas.width;
                var heightLeft = imgHeight;

                const contentDataURL = canvas.toDataURL("image/png");
                let pdf = new jsPDF("l", "mm", "a4");
                var position = 0;
                pdf.addImage(
                    contentDataURL,
                    "PNG",
                    0,
                    position,
                    imgWidth - 10,
                    imgHeight
                );
                pdf.save("Property.pdf");
            }
        );
    }
}
