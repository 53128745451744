import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Title } from "@angular/platform-browser";
import { ApiListService } from "../_services/api-list.service";
import { CommonService } from "../_services/common.service";
import { Config } from "../config/config";
import { RecursiveTemplateAstVisitor } from "@angular/compiler";
//import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
    qrcodeData: string = "";
    uuid: string;
    id;
    qid;
    passcode: string;
    scanstatus: boolean = false;
    // imageUrl:string = environment.imgurl;
    mobile_email: string;
    imageurl: any;
    public portal = null;
    public portalLabel = "";
    public portalContacts = "";
    public portalLogo = "";
    currentYear: number = new Date().getFullYear();
    //bsModalRef: BsModalRef;

    constructor(
        public sanitizer: DomSanitizer,
        private ds: DataservicesService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
        private akcessService: GeneralServiceService,
        private auth: AuthenticationService,
        private titleService: Title,
        private apiListService: ApiListService,
        private commonService: CommonService,
        //public modalService: BsModalService,
    ) { }

    ngOnInit() {
        this.getToken();
        this.checklogin();

        //  this.getQrCode();
    }

    async getQrCode() {
        const token = await this.getToken();
        console.log("Token:", token);
        this.akcessService.getQrData().subscribe((res: any) => {

            if (res.status) {
                this.uuid = res.data.uuid;
                let n = JSON.stringify(res.data);
                this.qrcodeData = n;
                // if(noLoginTimer==0){
                //   this.calllogintimer();
                // }
            } else {
                // location.reload()
            }
        }, (err) => {
            this.spinner.hide();
            // this.toastr.error("Oops, something went wrong. Please try again.")
        });
    }
    // getToken(){
    // //   this.akcessService.getToken().subscribe(data => {
    // //     // sessionStorage.setItem('ak-cbk-token',data.token);
    // //     sessionStorage.setItem('ak-cbk-token',data.token);
    // //  })
    // //  this.loaderService.show();
    //   this.commonService.apiCall(Config.urlloginV2,this.apiListService.getToken, {})
    //     .then((res: any) => {
    //       // if (res.statusCode == 200) {
    //       //   // this.loaderService.hide();
    //       //   // this.dashboardData = res.result;
    //       // }
    //     })
    //     .catch(err => {
    //       //  this.loaderService.hide();
    //       });
    // }

    async getToken() {
        return new Promise((resolve, reject) => {
            this.akcessService.getToken().subscribe(async (res: any) => {
                if (res) {
                    sessionStorage.setItem("ak-cbk-token", res.data.token);
                    resolve(true);

                } else {
                    reject(false);
                }
            }, (err) => {
                this.spinner.hide();
                // this.toastr.error("Oops, something went wrong. Please try again.")
            });
        });
    }
    async checklogin() {
        try {
            await this.getToken();
            this.signInWithAkcess(0);
            // this.qid = setInterval(() => {
            // this.signInWithAkcess(1);
            // }, 40000);
        } catch (e) {
            this.router.navigate(["/login"]);
        }
    }
    getPortalData() {
        this.akcessService.getPortalData().subscribe(
            (data) => {
                console.log("data===>", data);
                if (data != null) {
                    this.portal = data.data.domainname;
                    this.portalLabel = data.data.setting.label;
                    this.portalContacts = data.data.setting.contacts;
                    this.portalLogo = data.data.setting.filename;
                    this.titleService.setTitle(this.portalLabel);
                    this.imageurl = data.data.setting.filename;
                    console.log(" this.portal ===>", this.portal);
                    console.log("this.portalLabel===>", this.portalLabel);
                    console.log("this.portalContacts===>", this.portalContacts);
                    console.log("this.portalLogo===>", this.portalLogo);
                    console.log("this.imageurl===>", this.imageurl);


                    sessionStorage.setItem("_id", data.domaindata._id);
                    sessionStorage.setItem('portalLogo', this.portalLogo);
                    sessionStorage.setItem('portalTitle', this.portalLabel);
                    sessionStorage.setItem('portalDomainName', this.portal);
                }
            }, (err) => {
                this.spinner.hide();
                // this.toastr.error("Oops, something went wrong. Please try again.")
            }
        );
    }
    signInWithAkcess(noLoginTimer) {
        this.akcessService.getQrData().subscribe((res: any) => {
            if (res.status) {
                this.uuid = res.data.webUUID;
                let n = JSON.stringify(res.data);
                this.qrcodeData = n;

                if (noLoginTimer == 0) {
                    this.calllogintimer();
                }
            } else {
                location.reload();
            }
        }, (err) => {
            this.spinner.hide();
            // this.toastr.error("Oops, something went wrong. Please try again.")
        });
    }

    calllogintimer() {
        this.id = setInterval(() => {
            this.logincheckService();
        }, 3000);
    }
    sleep(milliseconds) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if (new Date().getTime() - start > milliseconds) {
                break;
            }
        }
    }
    logincheckService() {
        const uuidData = { uuid: this.uuid };
        this.akcessService.loginstatus(this.uuid).subscribe((data) => {
            this.getPortalData();
            if (data["status"] === 1) {
                if (data["auth"] == "none") {
                    clearInterval(this.id);
                    clearInterval(this.qid);
                    this.checkPortalLogin(data["data"]);
                    // this.updateUserScan(data["data"]);
                } else if (data["auth"] == "sms" || data["auth"] == "email") {
                    if (data["auth"] == "sms") {
                        this.mobile_email = "Phone";
                    }
                    if (data["auth"] == "email") {
                        this.mobile_email = "Email";
                    }
                    clearInterval(this.id);
                    clearInterval(this.qid);
                    this.scanstatus = true;
                }
            }
        }, (err) => {
            this.spinner.hide();
            // this.toastr.error("Oops, something went wrong. Please try again.")
        });
    }
    checkPortalLogin(data) {
        this.spinner.show();
        let logindata = {
            auth: data.auth,
            akcessId: data.user.akcessId,
            email: data.user.email,
            firstName: data.user.firstName,
            lastName: data.user.lastName
        }
        let tmpData = {
            email: data.user.email,
            phone: data.user.phone
        }

        this.akcessService.portalLoginStatus(logindata).subscribe((data: any) => {
            console.log("portalLoginStatus - data", data);

            if (data.status == 1) {
                location.reload();
                this.auth.login(data["data"], tmpData);
                this.toastr.info(data["message"]);
                this.spinner.hide();
                // this.router.navigate(["/panel/company"]);

            } else {
                this.spinner.hide();
                this.toastr.info(data["message"]);
            }
        });
        // this.akcessService.checkinvitationUser(data.user.email, data.user.phone).subscribe(
        //     (data) =>{
        //         if(!data.data.list.length){
        //             if(data.data.list[0].status == null){
        //                 // // on bording
        //                 sessionStorage.setItem("portalUser", JSON.stringify(data.data));
        //                 sessionStorage.setItem("logedInUserAckessId", data.data.akcessId);
        //                 this.router.navigate(["/companyonboarding"]);
        //             }else if(data.data.list[0].status == false){
        //                 // new screen
        //             }
        //             // else if(data.data.list[0].status == true){
        //             //     // Back to dashboard
        //             // }
        //         }else{
        //             this.akcessService.portalLoginStatus(logindata).subscribe((data: any) => {
        //                 if (data.status == 1) {
        //                     this.auth.login(data["data"]);
        //                     this.toastr.info(data["message"]);
        //                     this.spinner.hide();
        //                 } else {
        //                     this.spinner.hide();
        //                     this.toastr.info(data["message"]);
        //                 }
        //             });
        //         }
        //     },
        //     (error)=>{
        //         this.toastr.error('Something went wrong');
        //         this.spinner.hide()
        //     }
        // )
    }

    updateUserScan(data) {
        this.spinner.show();
        this.akcessService.updateStatusPriorScan(data).subscribe((data) => {
            this.spinner.show();
        });
    }

    closepopup() {
        clearInterval(this.id);
    }

    clickakcess() {
        this.calllogintimer();
    }

    clickpasscode() {
        if (this.passcode) {
            this.spinner.show();
            const data = { otp: this.passcode, uuid: this.uuid };
            this.akcessService.partyLogin(data).subscribe((data) => {
                if (data["status"] === 1) {
                    this.auth.login(data["data"]);
                } else {
                    this.spinner.hide();
                    this.toastr.error(data["message"]);
                }
            }, (err) => {
                this.spinner.hide();
                // this.toastr.error("Oops, something went wrong. Please try again.")
            });
        } else {
            this.spinner.hide();
            this.toastr.info("Enter passCode");
        }
    }
}
