import {Component, OnInit} from '@angular/core';
import {DataservicesService} from 'src/app/_services/dataservices.service';
import {GeneralServiceService} from '../../_services/general-service.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { RefreshContentService } from 'src/app/_services/refresh-content.service';
import { CommonService } from 'src/app/_services/common.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    imageUrl: any;

    // public portal = null;
    public portalLabel = '';
    public portalLogo = '';
    public portalDomainName = '';
    public portal = null;
    constructor(public sanitizer: DomSanitizer, 
        private spinner: NgxSpinnerService, 
        private ds: DataservicesService, 
        private titleService: Title, 
        private gs: GeneralServiceService,
        public commonService:CommonService,
        private refreshContentService:RefreshContentService) {
        
    }

    ngOnInit(): void {
      this.getData();
    }

    getData() {
        this.gs.getPortalData().subscribe((data: any) => {
            if (data != null) {
                this.portalLabel = data.data.setting.label;
                this.portalLogo = data.data.setting.filename;
                this.portalDomainName = data.data.domainname;

                this.titleService.setTitle(this.portalLabel);
            
                var portal_detail={
                    portalData:data.data.domaindata,
                    portalLogo:data.data.setting.filename,
                    portalFrontendURL:environment.frontendurl,
                    portalBackendURL:environment.url,
                    portalTitle:data.data.setting.label,
                    portalDomainName:data.data.domainname
    
                }
console.log("this.portaldata",data.data);

                sessionStorage.setItem('portalDetails', JSON.stringify(portal_detail));
                sessionStorage.setItem('portalData',JSON.stringify(data.data));                
                sessionStorage.setItem('portalFrontendURL',environment.frontendurl);
                sessionStorage.setItem('portalBackendURL', environment.url);
                //sessionStorage.setItem('portalLogo', this.portalLogo);
                //sessionStorage.setItem('portalTitle',this.portalLabel);
                sessionStorage.setItem('portalDomainName', this.portalDomainName);

                this.refreshContentService.setPortalData({
                    portalDetails:portal_detail
                });               
            }
        }, err=> {
            this.spinner.hide()
        });
    }
    
}
