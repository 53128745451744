import { Component, OnInit } from '@angular/core';
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";
declare var $: any;

@Component({
    selector: 'app-bank-settings',
    templateUrl: './bank-settings.component.html',
    styleUrls: ['./bank-settings.component.css']
})
export class BankSettingsComponent implements OnInit {
    bank_name: any = '';
    bank_url: any = '';
    bank_permission: any = '';
    bank_list: any = [];
    operation: any = '';
    bank_id_update: any = '';
    url_check: Boolean = true;
    constructor(
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.listBank();
    }

    listBank() {
        this.spinner.show()
        let object = {
        }

        this.calldata.listBank(object).subscribe((res: any) => {
            if (res.status == '1') {
                this.bank_list = res.data;
                this.spinner.hide()
            } else {
                this.spinner.hide()
            }
        })
    }

    addBank() {
        if (this.bank_name == '') {
            this.toast.error('Please enter bank name');
            return;
        }

        if (this.bank_url == '') {
            this.toast.error('Please enter url');
            return;
        }

        if (this.bank_permission == '') {
            this.toast.error('Please Select permission');
            return;
        }

        if (this.bank_name && this.bank_permission && this.bank_url) {
            this.spinner.show()
            let object = {
                name: this.bank_name,
                url: this.bank_url,
                permission: this.bank_permission
            }

            this.calldata.addBank(object).subscribe((res: any) => {
                if (res.status == '1') {
                    this.listBank();
                    $('#addBank').modal('hide')
                    this.spinner.hide()
                    this.toast.success(res.message)
                    this.resetFields();
                } else {
                    this.spinner.hide()
                    this.toast.error(res.message)
                }
            })
        }

    }

    resetFields() {
        this.bank_name = "";
        this.bank_url = "";
        this.bank_permission = "";
    }

    delete(data) {
        var result = confirm("Are you sure you want to delete this record?");
        if (result) {
            this.spinner.show()
            let object = {
                _id: data._id,
            }

            this.calldata.deleteBank(object).subscribe((res: any) => {
                if (res.status == '1') {
                    this.listBank();
                    this.spinner.hide()
                    this.toast.success('Record has been deleted successfully.');
                } else {
                    this.spinner.hide()
                    this.toast.error(res.message)
                }
            })
        }
    }

    openEditModal(data) {
        this.operation = 'edit';
        this.bank_name = data.name;
        this.bank_url = data.url;
        this.bank_permission = data.permission;
        this.bank_id_update = data._id;
    }

    updateBank() {
        if (this.bank_name == '') {
            this.toast.error('Please enter bank name');
            return;
        }

        if (this.bank_url == '') {
            this.toast.error('Please enter url');
            return;
        }

        if (this.bank_permission == '') {
            this.toast.error('Please Select permission');
            return;
        }

        if (this.bank_name && this.bank_permission && this.bank_url) {
            this.spinner.show()
            let object = {
                name: this.bank_name,
                url: this.bank_url,
                permission: this.bank_permission,
                _id: this.bank_id_update
            }

            this.calldata.updateBank(object).subscribe((res: any) => {
                if (res.status == '1') {
                    this.listBank();
                    $('#addBank').modal('hide')
                    this.spinner.hide()
                    this.toast.success(res.message)
                    this.resetFields();
                } else {
                    this.spinner.hide()
                    this.toast.error(res.message)
                }
            })
        }

    }

    validateURL(textval) {
        //var urlregex = new RegExp( "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|app|dev|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
        var urlregex = new RegExp("^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?$")
        return urlregex.test(textval);
    }

    onSearchChange(searchValue: string): void {
        let value = this.validateURL(searchValue);
        this.url_check = value;
    }

    //Delete Rows
    deleteRow() { }

    //View Recycle
    viewRecycle() {
        this.router.navigate(["/panel/eformsresponse/deleted"]);
    }
}
