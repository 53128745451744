<div class="property-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/assets']">Assets</a>
                </li>
                <li class="breadcrumb-item active">View</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section id="printarea">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card property-card noprint" style="height: 96%">
                        <div class="card-body enquiry-details">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h3>Asset Details</h3>

                                    <div class="row mb-4">
                                        <div class="col-sm-3 mb-3">
                                            <label>Picture: </label>
                                            <div>
                                                <img
                                                    *ngIf="asset?.picture"
                                                    src="{{
                                                        getFullURL(
                                                            asset?.picture
                                                        )
                                                    }}"
                                                    class="img-fluid img-rounded"
                                                    alt="{{ asset?.name }}"
                                                    title="{{ asset?.name }}"
                                                    width="100"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label>Name: </label>
                                            <div>
                                                {{ asset?.name }}
                                            </div>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label>Type: </label>
                                            <div>
                                                {{ asset?.type }}
                                            </div>
                                        </div>

                                        <div class="col-sm-12 mb-3">
                                            <label>Description: </label>
                                            <div class="text-justify">
                                                {{ asset.description }}
                                            </div>
                                        </div>

                                        <div class="col-sm-9 mb-3">
                                            <label>Documents: </label>
                                            <ul
                                                class="documents-list list-unstyled"
                                                *ngIf="asset.documents"
                                            >
                                                <li
                                                    *ngFor="
                                                        let document of getDocuments(
                                                            asset.documents
                                                        )
                                                    "
                                                >
                                                    <a
                                                        href="{{
                                                            getFullURL(document)
                                                        }}"
                                                        target="_blank"
                                                    >
                                                        <i
                                                            class="fa fa-file"
                                                        ></i>
                                                        {{ document }}</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- /Page Wrapper -->
