import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "../../../../_services/dataservices.service";

@Component({
  selector: "app-upload-document",
  templateUrl: "./upload-document.component.html",
  styleUrls: ["./upload-document.component.css"],
})
export class UploadDocumentComponent implements OnInit {
  isFileUploaded: boolean = false;
  isFileChosen: boolean = false;
  fileName: string = "";
  document;
  url;
  documents = [{ firstName: "asa" }];
  totalCount: any = 0;
  initialState: any;
  data: any;
  folderList: any
  isSignature: any = "No";
  isEdit: any;

  constructor(
    private _bsModalRef: BsModalRef,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private calldata: DataservicesService,
  ) {
    // this.data.isEdit = this.data.isEdit ? true : false;
    console.log(this.isEdit)
  }

  ngOnInit(): void {
    this.getFolderList();
  }

  onFileChange(files) {
    this.document = files[0];

    if (!files[0] || files[0].length == 0) {
      this.isFileUploaded = true;
      return;
    } else {
      this.isFileChosen = true;
      this.fileName = this.document.name;
      this.document = files[0];
    }
  }

  selectedfolder: any = "";
  uploadDocument() {
    this._bsModalRef.hide();
    this.spinner.show();
    const formData: FormData = new FormData();
    formData.append("file", this.document);
    formData.append("folderId", this.selectedfolder);
    formData.append("tags", this.tagArray)
    formData.append("signature", this.isSignature);

    this.calldata.uploadDocument(formData).subscribe(
      (data) => {
        if (data["status"] == true) {
          this.documents = [];
          this.documents = data["data"];
          this.totalCount = this.documents.length;
          this.toast.success(data["message"]);
          window.location.reload();
          // this.getAllDocument();
          this.spinner.hide();
        } else {
          this.toast.info(data["message"]);
          // this.getAllDocument();
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      }
    );
  }

  getAllDocument() {
    this.calldata.getAllDocuments("").subscribe(
      (data) => {
        if (data["status"] == true) {
          this.documents = [];
          this.documents = data["data"].reverse();
          this.totalCount = this.documents.length;
          this.spinner.hide();
        } else {
          this.toast.info(data["message"]);
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      }
    );
  }

  closeModal() {
    this._bsModalRef.hide();
  }

  tagArray: any = [];
  tagField = '';

  addTag() {
    if (this.tagField != '') {
      this.tagArray.push(this.tagField);
      this.tagField = ""
    } else {
      this.toast.warning('Enter tag in tag box');
    }
  }

  removeData(i) {
    this.tagArray.splice(i, 1);
  }

  getFolderList() {
    this.spinner.show();
    this.calldata.getDocumentFolderList().subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.spinner.hide();
        this.folderList = res.data.list;
      } else {
        this.spinner.hide();
        this.toast.error(res.message);
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    })
  }

  primaryFolderId: any;
  moveFolderId: any;
  documentId: any;

  moveDocument() {
    if (this.primaryFolderId == this.moveFolderId) {
      this.toast.warning("You can't move the document to the same folder. Please select diffrent folder to move the document.");
      return;
    }
    this.spinner.show();
    let data = {
      documentId: this.documentId,
      movetofolderid: this.moveFolderId
    }

    this.calldata.moveDocument(data).subscribe((res: any) => {
      if (res.status) {
        this.toast.success(res.message);
        this._bsModalRef.hide();
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toast.error("Something went wrong.")
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    })

  }
}
