import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";
import { Location } from "src/app/models/location";
@Component({
  selector: "app-location",
  templateUrl: "./location.component.html",
  styleUrls: ["./location.component.css"],
})
export class LocationComponent implements OnInit {
  searchName: string = "";

  currentPage: number = 1;
  totalCount: number = 0;
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
  pageSize: number = 10;
  locationList: Array<Location> = [];
  responseId: any;
  pullDataStatus: any = "";
  isMasterSel: Boolean;
  checkedLocationList: any;
  constructor(
    private spinner: NgxSpinnerService,
    private ds: DataservicesService,
    private gs: GeneralServiceService,
    private toast: ToastrService,
    private refreshContentService: RefreshContentService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isMasterSel = false;
  }

  ngOnInit(): void {
    this.getLocations();
  }
  getDays(days) {
    return days
      .map(function (item) {
        return item.label;
      })
      .join(", ");
  }
  getLocations() {
    this.spinner.show();
    const params = {
      search: this.searchName,
      page: this.currentPage,
      limit: this.pageSize,
    };

    this.ds.getLocations(params).subscribe((res: any) => {
      if (res.status) {
        this.locationList = res.data.list;
        this.totalCount = res.data.total;
        this.toast.success(res.message);
        this.spinner.hide();
      } else {
        this.toast.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      this.toast.error("Oops, something went wrong. Please try again.")
    });
  }

  createLocation() { }

  viewLocation(data) { }

  viewRecycle() {
    this.router.navigate(["/panel/location/deleted"]);
  }

  onProjectPageChange(event) {
    this.currentPage = event;
    this.getLocations();
  }

  changePage(value) { }

  searchLocation(type: string, value: string) {
    if (type == "search") {
      if (value !== null) {
        this.currentPage = 1;
        this.searchName = value;
        this.getLocations();
      }
    } else {
      this.searchName = value;
      this.getLocations();
    }
  }

  checkUncheckAll() {
    for (var i = 0; i < this.locationList.length; i++) {
      this.locationList[i].isSelected = this.isMasterSel;
    }
  }

  isAllSelected() {
    this.isMasterSel = this.locationList.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedLocationList = [];
    for (var i = 0; i < this.locationList.length; i++) {
      if (this.locationList[i].isSelected)
        this.checkedLocationList.push(this.locationList[i]._id);
    }

    return this.checkedLocationList;
  }

  deleteSingleRows(id) {
    this.spinner.show();

    const data = {
      component: "location",
      _id: id,
    };
    this.ds.softDelete(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.locationList = this.locationList.filter((object) => {
            return object._id !== id;
          });
          this.spinner.hide();
          this.toast.success("Location " + res.message);
        } else {
          this.toast.info("Location " + res.message);
        }
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
      }
    );
  }

  deleteRows() {
    this.spinner.show();
    let deleteIDs = this.getCheckedItemList();

    if (deleteIDs.length > 0) {
      for (let id of deleteIDs) {
        const data = {
          component: "location",
          _id: id,
        };
        this.ds.softDelete(data).subscribe(
          (res: any) => {
            if (res.status) {
              this.locationList = this.locationList.filter((object) => {
                return !object.isSelected;
              });
              this.spinner.hide();
              this.toast.success("Location " + res.message);
            } else {
              this.toast.info("Location " + res.message);
            }
          }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
          }
        );
      }
    } else {
      this.toast.error("Select a Location");
      this.spinner.hide();
    }
  }
}
