import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  pageTitleList = [
    { value: 1, pageTitle: 'Company Users', route:'company-users' },
   
  ]
  constructor() { }

}
