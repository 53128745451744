import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import * as XLSX from "xlsx";
declare var $: any;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from "html-to-pdfmake";
import { DataservicesService } from "src/app/_services/dataservices.service";
@Component({
    selector: "app-logs-modal",
    templateUrl: "./logs-modal.component.html",
    styleUrls: ["./logs-modal.component.css"],
})
export class LogsModalComponent implements OnInit {
    @Input() fromParent;
    loglist: any = [];
    allUsers_uc: any = [];
    pageSize: any = 10;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageNumber: any = 0;
    RecordsCount: any = 0;
    portalUser;
    filtered: boolean = false;
    search_text: string = "";
    eformdata: any = [];
    pdf_fileName = "PDF.pdf";
    currentPage: any = 1;
    totalCount: any = 0;
    constructor(
        public activeModal: NgbActiveModal,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService
    ) { }

    ngOnInit(): void {
        /* Output:
     {prop1: "Some Data", prop2: "From Parent Component", prop3: "This Can be anything"}
    */
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        //this.totalRecordCount();
        //this.getlogData(this.pageNumber);
        this.getLogs();
    }

    getLogs() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const data = {
            akcessId: this.portalUser.akcessId,
            search: this.search_text,
        };

        const queryParams = {
            page: this.currentPage,
            limit: this.pageSize,
        };
        this.calldata.getAdminLogs(data, queryParams).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.eformdata = [];
                    this.eformdata = data["results"];
                    this.totalCount = data["totalCount"];
                    this.spinner.hide();
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            },
            (error) => {
                this.toast.error("something went wrong");
                this.spinner.hide();
            }
        );
    }

    closeModal(sendData) {
        this.activeModal.close(sendData);
    }

    getlogData(pageNumber) {
        this.spinner.show();
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
            pageNumber: pageNumber,
            pageSize: this.pageSize,
        };
        this.calldata.getloglist(tmpdata).subscribe(
            (data) => {
                // alert(JSON.stringify(data))
                if (data["status"] === 1) {
                    this.loglist = [];
                    this.loglist = data["data"];
                    this.allUsers_uc = data["data"];
                    this.spinner.hide();
                    //this.toast.success(data["message"]);
                } else {
                    //this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }
    generatePdf() {
        var date = new Date();
        var formattedDate = moment(date).format("YYYYMMDD");
        var fullname =
            this.portalUser.firstName + " " + this.portalUser.lastName;
        this.pdf_fileName = "Logs_" + formattedDate + "_" + fullname + ".pdf";
        const documentDefinition = this.getDocumentDefinition();
        pdfMake.createPdf(documentDefinition).download(this.pdf_fileName);
    }
    getDocumentDefinition() {
        var html = htmlToPdfmake(
            document.getElementById("pdf_formfields").innerHTML
        );
        return {
            content: [html],
            styles: {
                "html-strong": {
                    background: "", // it will add a yellow background to all <STRONG> elements
                },
            },
        };
    }
    totalRecordCount() {
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            usertype: this.portalUser.usertype,
        };
        this.calldata.getloglistCount(tmpdata).subscribe((data) => {
            this.RecordsCount = data["count"];
        });
    }
    // onPageChange() {
    //   this.getlogData(this.pageNumber);
    // }
    onProjectPageChange(event) {
        this.currentPage = event;
        this.getLogs();
    }
    changePage(value) {
        this.pageSize = parseInt(value);
        this.getLogs();
    }

    exportexcel(div): void {
        /* table id is passed over here */
        let element = document.getElementById(div);
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        let fileName = "";

        if (div === "pdf_formfields") {
            fileName = "UpdatedCustomers.xlsx";
        } else if (div === "client_reports") {
            fileName = "ClientList.xlsx";
        } else if (div === "user_reports") {
            fileName = "UserReport.xlsx";
        } else if (div === "user_logs") {
            fileName = "UserLogs.xlsx";
        }

        /* save to file */
        XLSX.writeFile(wb, fileName);
    }

    searchUsers_uc(filterValue: string) {
        if (filterValue !== null) {
            this.filtered = false;
            filterValue = filterValue.toLowerCase();
            this.loglist = this.allUsers_uc.filter(
                (user) =>
                    (user.akcessId &&
                        user.akcessId.toLowerCase().includes(filterValue)) ||
                    (user.domainName &&
                        user.domainName.toLowerCase().includes(filterValue)) ||
                    (user.name && user.name.toLowerCase().includes(filterValue))
            );
        } else {
            this.filtered = true;
            this.loglist = this.allUsers_uc;
        }
    }
}
