import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "src/app/models/location";
import { environment } from "src/environments/environment";
import { CommonService } from "../../../../_services/common.service";

@Component({
    selector: "app-view-location",
    templateUrl: "./view.component.html",
    styleUrls: ["./view.component.css"],
})
export class ViewLocationComponent implements OnInit {
    locationId: string;
    location: Location = new Location();
    qrCodeData: any;
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        public commonService: CommonService
    ) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.locationId = params.locationId;
        });
    }

    ngOnInit(): void {
        if (this.locationId) {
            this.spinner.show();
            this.ds.getLocation(this.locationId).subscribe((response: any) => {
                this.spinner.hide();
                if (response.status) {
                    this.location = response.data;
                    this.qrCodeData = JSON.stringify({
                        db_name:
                            this.commonService.selectedCompanyDeatils.dbName ||
                            "asd",
                        portal: environment.domainName,
                        type: "attendance",
                        classID: this.location._id,
                        className: this.location.locationName,
                        label_type: "location",
                    });
                } else {
                    this.toast.error(response.message);
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            });
        }
    }

    getDays(days) {
        return days
            .map(function (item) {
                return item.label;
            })
            .join(", ");
    }
}
