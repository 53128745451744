import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { environment } from 'src/environments/environment';
 

@Component({
  selector: 'app-eform-qrcode',
  templateUrl: './eform-qrcode.component.html',
  styleUrls: ['./eform-qrcode.component.css']
})
export class EformQrcodeComponent implements OnInit {
  eformId: any
  eformData: any
  eformName: any
  qrcodeData: any;
  dbname: any

  constructor(private route: ActivatedRoute, private _ds: DataservicesService) {
    this.eformId = this.route.snapshot.paramMap.get('id');
    this.eformName = this.route.snapshot.paramMap.get('eformname')
    this.dbname = this.route.snapshot.paramMap.get('dbname')
  }

  ngOnInit(): void {
    this.qrcodeData = environment.url+'eform/'+this.eformName + "/" + this.eformId + "/" + this.dbname
  }

}
