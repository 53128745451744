import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { link } from "fs";
import { CommonService } from "./common.service";



@Injectable({
    providedIn: "root",
})
export class DataservicesService {
    // token:string ='';
    httpOptions: any;
    httpMultipartOptions: any;
    httpOptionsForParticularCompany: any;
    httpOptionsForParticularCompanywithfile: any;
    httpMultipartOptionsForParticularCompany: any;
    data: any = {};
    portalUser;
    cbkToken: any;

    constructor(private http: HttpClient, public commonService: CommonService) {
        this.cbkToken = sessionStorage.getItem("ak-cbk-token");
    }

    

   
//     login(email: string, password: string) {
//     let headers = new Headers();
//     this.loading = true;
//     headers.append('Content-Type', 'application/json');
//     return this.http.post(this.loginUrl, JSON.stringify({ email, password }), { headers })
//         .subscribe(res => {
//             let data = res.json();

//             if (data.token) {
//                 this.loading = false;
//                 window.location.href = '/';
//             }
//             else {
//                 this.loading = false;
//                 this.router.navigateByUrl('/login');
//             }

//         }, error => {
//             this.loading = false;
//         })
// };

    getauth() {
        this.httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                api: environment.apiKey,
                AkcessToken: this.cbkToken,
                logedInUserAckessId: sessionStorage.getItem(
                    "logedInUserAckessId"
                ),
                //'x-access-token' : this.token
            }),
        };
        this.httpMultipartOptions = {
            headers: new HttpHeaders({
                Accept: "*/*",
            }),
            responseType: "text",
        };
    }
        

    getStorageUrl() {
        return `https://sme.akcess.app/api/storage/${this.commonService.selectedCompanyDeatils.dbName}`;
    }

    async getauthForParticularCompany() {
        if (!this.cbkToken || !sessionStorage.getItem("logedInUserAckessId")) {
            location.reload();
        }
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const data = { akcessId: this.portalUser.akcessId };
        this.httpOptionsForParticularCompany = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                api: environment.apiKey,
                AkcessToken: this.cbkToken || "token",
                akcessId: this.portalUser.akcessId,
                logedInUserAckessId: sessionStorage.getItem(
                    "logedInUserAckessId"
                )
                    ? sessionStorage.getItem("logedInUserAckessId")
                    : "asd",
                db_name:
                    this.commonService.selectedCompanyDeatils.dbName || "asd",
                //Origin: `https://${environment.domainName}`,
                //'x-access-token' : this.token
            }),
        };
        this.httpMultipartOptionsForParticularCompany = {
            headers: new HttpHeaders({
                Accept: "*/*",
            }),
            responseType: "text",
        };
    }

    async getAuthForCompanyWithStaticDB(dbName) {
        if (!this.cbkToken || !sessionStorage.getItem("logedInUserAckessId")) {
            location.reload();
        }
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));

       
        this.httpOptionsForParticularCompany = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                api: environment.apiKey,
                akcessId: this.portalUser.akcessId,
                AkcessToken: this.cbkToken || "token",
                logedInUserAckessId: sessionStorage.getItem(
                    "logedInUserAckessId"
                )
                    ? sessionStorage.getItem("logedInUserAckessId")
                    : "asd",
                db_name: dbName || "null",
                //'x-access-token' : this.token
            }),
        };
        this.httpMultipartOptionsForParticularCompany = {
            headers: new HttpHeaders({
                Accept: "*/*",
            }),
            responseType: "text",
        };
    }

    async getauthForParticularCompanywithfile() {
        this.httpOptionsForParticularCompanywithfile = {
            headers: new HttpHeaders({
                api: environment.apiKey,
                'X-API-KEY': 'MTM0ZGE2OGQtNTQwZS00NGY5LWI5OTktYzI4MWE1MjA2MWQw',

                AkcessToken: this.cbkToken,
                logedInUserAckessId: sessionStorage.getItem(
                    "logedInUserAckessId"
                ),
                db_name: this.commonService.selectedCompanyDeatils.dbName,
                //'x-access-token' : this.token
            }),
        };
        this.httpMultipartOptionsForParticularCompany = {
            headers: new HttpHeaders({
                Accept: "*/*",
            }),
            responseType: "text",
        };
    }

    getemployeeList(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getemployeeList",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    addEmployee(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "addEmployee",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    editEmployee(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "editEmployee",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    deleteEmployee(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "deleteEmployee",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getDeletedEmployeeList() {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            environment.url + "getrecyclebinemployeeList",
            this.httpOptionsForParticularCompany
        );
    }
    getSendInviteUsersData(data) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            environment.url +
                "getSendInviteUsersData?search=" +
                data.search +
                "&usertype=" +
                data.usertype +
                "&page=" +
                data.page +
                "&limit=" +
                data.limit,
            this.httpOptionsForParticularCompany
        );
    }

    // createcompanyclient(data) {
    //   data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(environment.url + "clients", data, this.httpOptionsForParticularCompany);
    // }

    // getclients(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.get<any>(environment.url + `clients?page=${data.page}&limit=${data.limit}&search=${data.search}`, this.httpOptionsForParticularCompany);
    // }
    // // =        = //

    // addclient(data) {
    //   data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(environment.url + "addClient", data, this.httpOptionsForParticularCompany);
    // }
    // editclient(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "editClient",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // getclientlist(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(environment.url + "getclientList", data, this.httpOptionsForParticularCompany);
    // }
    // deleteClient(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(environment.url + "deleteClient", data, this.httpOptionsForParticularCompany);
    // }

    sendInvitationBySMS(mobile, link) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendinvitationbysms",
            { mobile: mobile, link: link },
            this.httpOptionsForParticularCompany
        );
    }

    sendInvitation(email, link) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendinvitationtoclient",
            { email: email, link: link },
            this.httpOptionsForParticularCompany
        );
    }

    getCertificates(data) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            environment.url + "certificates",
            this.httpOptionsForParticularCompany
        );
    }

    geteformdata(data) {
        data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "eforms",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    deleforms(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "deleteeform",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    addeform(data) {
        data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "addeforms",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    editeform(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "updateform",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    getsinglerecordeform(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "fetchoneform",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    geteformsdata() {
        this.getauthForParticularCompany();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const data = { akcessId: this.portalUser.akcessId };
        return this.http.post<any>(
            environment.url + "geteformresponse",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    scanef(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "eformdata",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    sendEformData(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendeformdata",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    getuserdatabyakcessid(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getuserdatabyakcessid",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    fetchresponse(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "fetcheformresponse",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    duplicateEformAdd(obj) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "duplicate-eform",
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    fetcheformsents(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getSentEforms",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    fetcheformreceived(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getReceivedEforms",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    companiesOnboard(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "companies/onboarding",
            data,
            this.httpOptions
        );
    }

    
    addentity(data) {
        console.log(data);
        this.getauth();
        return this.http.post<any>(
            environment.url + "api/v1/credas/create-entity",
            data,
            this.httpOptions
        );
    }
    checkSummary(data) {
        console.log(data);
        this.getauth();
        return this.http.post<any>(
            environment.url + "api/v1/credas/check-summary",
            data,
            this.httpOptions
        );
    }

    createprocess(data) {
        console.log(data);
        this.getauth();
        return this.http.post<any>(
            environment.url + "api/v1/credas/create-process-new",
            data,
            this.httpOptions
        );
    }

  

    getdetailusingentityId(entityID){
        return this.http.get<any>(
            `${environment.url}api/v1/credas/getDetailByEntityId/${entityID}`,

            // environment.url + "api/v1/credas/getDetailByEntityId/"$entityID,
            {headers: this.httpOptionsForParticularCompany,

             }
        );
    }
    // idv-entries/52bd852a-f9d8-4d45-acdb-b30273f88a44/47f99023-4011-4d96-b4e9-03fdae6d77e4
    getidventity(entityID,entityidv){
        console.log(`${environment.url}api/v1/credas/idv-entries/${entityID}/${entityidv}`);
        return this.http.get<any>(
            `${environment.url}api/v1/credas/idv-entries/${entityID}/${entityidv}`,

            // environment.url + "api/v1/credas/getDetailByEntityId/"$entityID,
            {headers: this.httpOptionsForParticularCompany,

             }
        );
    }

    getsancationandpep(entityID){
        console.log(`${environment.url}api/v1/credas/data-checks/latest/sanctions-and-peps/${entityID}`);
        return this.http.get<any>(
            `${environment.url}api/v1/credas/data-checks/latest/sanctions-and-peps/${entityID}`,

            // environment.url + "api/v1/credas/getDetailByEntityId/"$entityID,
            {headers: this.httpOptionsForParticularCompany,

             }
        );
    }

    getsancationandpepdatacheck(entityID,dataCheckId){
        console.log(`${environment.url}api/v1/credas/data-checks/sanctions-and-peps/${entityID}/${dataCheckId}`);
        return this.http.get<any>(
            `${environment.url}api/v1/credas/data-checks/sanctions-and-peps/${entityID}/${dataCheckId}`,

            // environment.url + "api/v1/credas/getDetailByEntityId/"$entityID,
            {headers: this.httpOptionsForParticularCompany,

             }
        );
    }


    getdatachecksnactionanpepsArchive(tmpdata,entityID,dataCheckId){
        console.log(`${environment.url}api/v1/credas/active-checks/sanctions-peps/${entityID}/${dataCheckId}`);
        return this.http.post<any>(
            `${environment.url}api/v1/credas/active-checks/sanctions-peps/${entityID}/${dataCheckId}`,
            tmpdata,
            this.httpOptions
            // environment.url + "api/v1/credas/getDetailByEntityId/"$entityID,
            // {headers: this.httpOptionsForParticularCompany,

            //  }
        );
    }

    //'http://localhost:3000/api/v1/credas/active-checks/sanctions-peps/615c6848-c949-475b-a35a-a7c74596a8ef/5

    getProcessURL(processID){
        return this.http.get<any>(
            `${environment.url}api/v1/credas/send-registration-link/${processID}`,
            {headers: this.httpOptionsForParticularCompany,

             }
        );
    }
    getActiveCheckResult(entityID){
        
        return this.http.get<any>(
            `${environment.url}api/v1/credas/active-checks/${entityID}`,
            {headers: this.httpOptionsForParticularCompany,

             }
        );
    }
    getJourneyBankProfile(tmpdata){ 
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
      this.getauth();
        return this.http.post<any>(
            environment.url + "api/v1/credas/journey-bank-profile",
            tmpdata,
            this.httpOptions
        );
  }


  getAddressesByPostalCode(tmpdata,postalcode){ 
    this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
  this.getauth();
    return this.http.post<any>(
        `${environment.url}api/v1/credas/postcode-lookup/${postalcode}`,
        tmpdata,
        this.httpOptions
    );
}


dataCheckBank(tmpdata,entityId){ 
  this.getauth();
    return this.http.post<any>(
        `${environment.url}api/v1/credas/data-checks/bank-account/${entityId}`,
        tmpdata,
        this.httpOptions
    );
}

dataCheckAddressMortaility(tmpdata,entityId){ 
    this.getauth();
      return this.http.post<any>(
          `${environment.url}api/v1/credas/data-checks/address-mortality/${entityId}`,
          tmpdata,
          this.httpOptions
      );
  }

  dataCheckAddressMortailityResult(tmpdata,entityId,dataCheckId){ 
    this.getauth();
      return this.http.post<any>(
          `${environment.url}api/v1/credas/data-checks/get-address-mortality/${entityId}/${dataCheckId}`,
          tmpdata,
          this.httpOptions
      );
  }

  dataCheckBankResult(tmpdata,entityId,dataCheckId){ 
    this.getauth();
      return this.http.post<any>(
          `${environment.url}api/v1/credas/data-checks/get-bank-account/${entityId}/${dataCheckId}`,
          tmpdata,
          this.httpOptions
      );
  }
    dataCheckAddressMortailityResultPDF(tmpdata: any, entityId: string, dataCheckId: string): Observable<Blob> {

    this.getauth();

      const apiUrl = `${environment.url}api/v1/credas/data-checks/address-mortality/pdf/${entityId}/${dataCheckId}`;
    //   return this.http.post<Blob>(apiUrl, tmpdata, this.httpOptions) as Observable<Blob>;
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'blob' as 'json'  // Treat the response as Blob
      };
      return this.http.post(apiUrl,tmpdata ,httpOptions) as Observable<Blob>;

  }
  dataCheckBankAccountResultPDF(tmpdata: any, entityId: string, dataCheckId: string): Observable<Blob> {

    this.getauth();

      const apiUrl = `${environment.url}api/v1/credas/data-checks/bank-account/pdf/${entityId}/${dataCheckId}`;
    //   return this.http.post<Blob>(apiUrl, tmpdata, this.httpOptions) as Observable<Blob>;
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'blob' as 'json'  // Treat the response as Blob
      };
      return this.http.post(apiUrl,tmpdata ,httpOptions) as Observable<Blob>;

  }
  dataCheckIDVResultPDF(tmpdata: any, entityId: string, dataCheckId: string): Observable<Blob> {

    this.getauth();

      const apiUrl = `${environment.url}api/v1/credas/entities/idv-entries/pdf/${entityId}/${dataCheckId}`;
      console.log(apiUrl);
    //   return this.http.post<Blob>(apiUrl, tmpdata, this.httpOptions) as Observable<Blob>;
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'blob' as 'json'  // Treat the response as Blob
      };
      return this.http.post(apiUrl,tmpdata ,httpOptions) as Observable<Blob>;

  }
  
  dataChecksanscationVResultPDF(tmpdata: any, entityId: string, dataCheckId: string): Observable<Blob> {

    this.getauth();

      const apiUrl = `${environment.url}api/v1/credas/data-checks/sanctions-and-peps/pdf/${entityId}/${dataCheckId}`;
      console.log(apiUrl);
    //   return this.http.post<Blob>(apiUrl, tmpdata, this.httpOptions) as Observable<Blob>;
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'blob' as 'json'  // Treat the response as Blob
      };
      return this.http.get(apiUrl ,httpOptions) as Observable<Blob>;

  }


  idVerificationSelfie(tmpdata: any, entityId: string, idvId:any,taskId:any): Observable<Blob> {

    this.getauth();

      const apiUrl = `${environment.url}api/v1/credas/tasks/identity-verification/selfie/${entityId}/${idvId}/${taskId}`;
    //   return this.http.post<Blob>(apiUrl, tmpdata, this.httpOptions) as Observable<Blob>;
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'blob' as 'json'  // Treat the response as Blob
      };
      return this.http.post(apiUrl,tmpdata ,httpOptions) as Observable<Blob>;

  }


  profileImageBlobReference(tmpdata: any, entityId: string, dataCheckId:any,resultId:any,profileImageBlobReference:any): Observable<Blob> {

    this.getauth();

      const apiUrl = `${environment.url}api/v1/credas/data-checks/sanctions-and-peps/profile/${entityId}/${dataCheckId}/${resultId}/${profileImageBlobReference}`;
    //   return this.http.post<Blob>(apiUrl, tmpdata, this.httpOptions) as Observable<Blob>;
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'blob' as 'json'  // Treat the response as Blob
      };
      return this.http.post(apiUrl,tmpdata ,httpOptions) as Observable<Blob>;

  }
//   curl --location 'http://localhost:3000/api/v1/credas/tasks/identity-verification/id-documents/scan/86489100-9980-4ce3-9282-32c3b7e7a8b9/47f99023-4011-4d96-b4e9-03fdae6d77e4/23935/173593' \

  iddocumentscan(tmpdata: any, entityId: string,id:string, idvId:any,taskId:any): Observable<Blob> {

    this.getauth();
    // 'http://localhost:3000/api/v1/credas/tasks/identity-verification/id-documents/scan/86489100-9980-4ce3-9282-32c3b7e7a8b9/47f99023-4011-4d96-b4e9-03fdae6d77e4/23935/173593' \
    
      const apiUrl = `${environment.url}api/v1/credas/tasks/identity-verification/id-documents/scan/${entityId}/${id}/${idvId}/${taskId}`;
    //   return this.http.post<Blob>(apiUrl, tmpdata, this.httpOptions) as Observable<Blob>;
    const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'blob' as 'json'  // Treat the response as Blob
      };
      return this.http.post(apiUrl,tmpdata ,httpOptions) as Observable<Blob>;

  }


  sanctionsandpepinsert(tmpdata: any, entityId: string) {

    console.log("inside sanaction insert");
    this.getauth();
    return this.http.post<any>(
        `${environment.url}api/v1/credas/data-checks/sanctions-and-peps/${entityId}`,
        tmpdata,
        this.httpOptions
    );
  }
  

    // checkSummaryhistory(data) {
    //     console.log(data);
    //     this.getauth();
    //     return this.http.get<any>(
    //         environment.url + "get-summary-history",
    //         data,
    //         this.httpOptions
    //     );
    // }


    checkSummaryhistory(params: { id: string}) {
        console.log(params);        // Assuming getauthForParticularCompany and httpOptionsForParticularCompany are defined somewhere
        this.getauthForParticularCompany();
        // let queryParams = new HttpParams({ fromObject: params });
        return this.http.get<any>(
            `${environment.url}api/v1/credas/get-summary-history/${params.id}`,
            {headers: this.httpOptionsForParticularCompany }
        );
      }

      //http://192.168.1.12:3000/api/v1/credas/entity-data-list/PP102017148

      checkSummaryCredasEntityhistory(tmpdata) {
        console.log(tmpdata);        // Assuming getauthForParticularCompany and httpOptionsForParticularCompany are defined somewhere
        this.getauth();
        return this.http.get<any>(
            `${environment.url}api/v1/credas/entity-data-list/${tmpdata}`,
            {headers: this.httpOptionsForParticularCompany }
        );
      }
    pusheformconfig(eformdata) {
        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            eformconfig: eformdata,
            portalDomainName:
                this.commonService.portalDetails.portalDetails.portalDomainName,
        };

        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "pusheformtouser",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendEformUrlByEmail(obj) {
        this.getauthForParticularCompany();

        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            ...obj,
            portalDomainName:
                this.commonService.portalDetails.portalDetails.portalDomainName,
        };

        return this.http.post<any>(
            environment.url + "sendeformurlbyemail",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    sendEformUrlByPhone(obj) {
        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            ...obj,
            portalDomainName:
                this.commonService.portalDetails.portalDetails.portalDomainName,
        };

        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendeformurlbyPhone",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    getSentEformUrlByPhone_email(obj) {
        obj["api"] = environment.apiKey;
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getSentEformUrlByPhone_email",
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    adduser(data) {
        this.getauth();
        data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        return this.http.post<any>(
            environment.url + "adduser",
            data,
            this.httpOptions
        );
    }

    adduserportal(data) {
        return this.http.post<any>(
            environment.url + "adduser",
            data,
            this.httpOptions
        );
    }
    edituser(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "edituser",
            data,
            this.httpOptions
        );
    }

    getuserlist(data) {
        data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        this.getauth();
        return this.http.post<any>(
            environment.url + "getuserlist",
            data,
            this.httpOptions
        );
    }

    getLiveUserList(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "getLiveUsers",
            data,
            this.httpOptions
        );
    }
    patchUser(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "patchUser",
            data,
            this.httpOptions
        );
    }
    getloglist(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "getloglist",
            data,
            this.httpOptions
        );
    }
    getloglistCount(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "getloglistCount",
            data,
            this.httpOptions
        );
    }
    getuserlist_search() {
        this.getauthForParticularCompany();
        //this.data["domainName"] = dbName;
        return this.http.post<any>(
            environment.url + "getuserlist_search",
            null,
            this.httpOptionsForParticularCompany
        );
    }

    getEmployeeListWithoutSelectingCompany(dbName) {
        this.getAuthForCompanyWithStaticDB(dbName);
        //this.httpOptionsForParticularCompany.dbName = dbName;
        return this.http.post<any>(
            environment.url + "getuserlist_search",
            null,
            this.httpOptionsForParticularCompany
        );
    }
    deleteUsers(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "deleteuser",
            data,
            this.httpOptions
        );
    }
    reactiveUser(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "reactiveUser",
            data,
            this.httpOptions
        );
    }

    changepasscode(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "changepasscode",
            data,
            this.httpOptions
        );
    }

    // readhtmlfile(){
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "readfile",'',this.httpOptions)
    // }
    // writeFile(data){

    //   this.getauth();
    //   return this.http.post<any>(environment.url + "writeFile",data,this.httpOptions)
    // }
    getAccountData() {
        this.getauth();
        return this.http.post<any>(
            environment.url + "getaccountdata",
            "",
            this.httpOptions
        );
    }
    saveAccountData(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "saveaccountdata",
            data,
            this.httpOptions
        );
    }
    approveresponse(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "approveresponse",
            data,
            this.httpOptions
        );
    }
    verifyfield(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "verifyfield",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    getPortalData() {
        this.getauth();

        return this.http.post<any>(
            environment.url + "get_portal_details",
            "",
            this.httpOptions
        );
    }
    savePortalData(data) {
        // alert(JSON.stringify(data))
        this.getauth();

        return this.http.post(
            environment.url + "save_portal_details",
            data,
            this.httpMultipartOptions
        );
    }

    sendBulkInvitationEmails(bulkEmails, link) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendinvitationtoclientinbulk",
            { email: bulkEmails, link: link },
            this.httpOptionsForParticularCompany
        );
    }

    sendBulkInvitationBySMS(bulkMobile, link) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendbulkinvitationbysms",
            { mobile: bulkMobile, link: link },
            this.httpOptionsForParticularCompany
        );
    }

    updateClientDataWithReverseOnBoarding(object) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "updateclientDatawithreversennboarding",
            object,
            this.httpOptionsForParticularCompany
        );
    }

    updateEmployeeDataWithReverseOnBoarding(object) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "updateemployeeDatawithreversennboarding",
            object,
            this.httpOptionsForParticularCompany
        );
    }

    // sendEformUrlByEmail(obj) {
    //   obj['api'] = environment.apiKey
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "sendeformurlbyemail", obj, this.httpOptions)
    // }
    // sendEformUrlByPhone(obj) {
    //   obj['api'] = environment.apiKey
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "sendeformurlbyPhone", obj, this.httpOptions)
    // }
    // getSentEformUrlByPhone_email(obj) {
    //   obj['api'] = environment.apiKey
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "getSentEformUrlByPhone_email", obj, this.httpOptions)
    // }
    getFieldVerifierOnPortal(obj) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + `verifier/getFieldVerifierOnPortal`,
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    getDocVerifierOnPortal(obj) {
        this.getauthForParticularCompany();
        obj["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        obj["sessionId"] = sessionStorage.getItem("logedInUserAckessId");
        return this.http.post(
            environment.url + `verifier/getDocVerifierOnPortal`,
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    VerifyField(obj) {
        this.getauthForParticularCompany();
        obj["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        obj["sessionId"] = sessionStorage.getItem("logedInUserAckessId");
        return this.http.post(
            environment.url + `fields/Verify`,
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    docVerifyField(obj) {
        this.getauthForParticularCompany();
        obj["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        obj["sessionId"] = sessionStorage.getItem("logedInUserAckessId");
        return this.http.post(
            environment.url + `document/docVerify`,
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    getQRcodeURL(data) {
        return this.http.post(
            "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBSyCuodxjbfUX0TSRqtzpH0HUaUoE30QQ",
            data
        );
    }

    eformInfo(obj) {
        return this.http.post(environment.url + "eform-info", obj);
    }
    //field;

    importClientFromXsl(fileData: File) {
        const formData = new FormData();
        formData.append("file", fileData);
        formData.append("usertype", "Admin");

        return this.http.post(
            environment.url + `importclientinbulk`,
            formData,
            {
                headers: new HttpHeaders({
                    api: environment.apiKey,
                    AkcessToken: this.cbkToken,
                    logedInUserAckessId: sessionStorage.getItem(
                        "logedInUserAckessId"
                    ),
                }),
            }
        );
    }

    fetchNotifications(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "fetchNotifications",
            data,
            this.httpOptions
        );
    }

    fetchReports(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "fetchReports",
            data,
            this.httpOptions
        );
    }

    fetchUpdatedClients(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "fetchUpdateClients",
            data,
            this.httpOptions
        );
    }

    updateFieldsFromDocument(object) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "fetchdatafromdoc",
            object,
            this.httpOptions
        );
    }

    fetchClientReports(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "fetchClientReports",
            data,
            this.httpOptions
        );
    }

    fetchUpdatFields(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "fetchupdatfields",
            obj,
            this.httpOptions
        );
    }

    updateFields(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "updateuserfields",
            obj,
            this.httpOptions
        );
    }

    fetchVerificationLogs(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "fetchVerificationLogs",
            data,
            this.httpOptions
        );
    }

    getAdminLogs(data, params) {
        this.getauthForParticularCompany();
        let queryParams = new URLSearchParams(params);
        return this.http.post<any>(
            environment.url + `statics-logs?${queryParams.toString()}`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    geteidcarddata(params) {
        this.getauthForParticularCompany();
        let queryParams = new URLSearchParams(params);
        return this.http.get<any>(
            `${environment.url}getallidcards?${queryParams.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }

    deleteIdCard(idcard_id) {
        return this.http.delete<any>(
            environment.url + "idcard/delete?idNo=" + idcard_id,
            this.httpOptions
        );
    }

    sendbyemail(emailObject) {
        return this.http.post<any>(
            environment.url + "send-email",
            emailObject,
            this.httpOptions
        );
    }
    pdfsendbyemail(pdfObject) {
        return this.http.post<any>(
            environment.url + "send-email",
            pdfObject,
            this.httpOptions
        );
    }
    sendemail(obj) {
        return this.http.post<any>(
            environment.urlloginV2 + "send-email",
            obj,
            this.httpOptions
        );
    }
    listCompany(data) {
        return this.http.post<any>(
            environment.url + "list-company",
            data,
            this.httpOptions
        );
    }

    getCompanyDetails(data) {
        return this.http.post<any>(
            environment.url + "company-deteail",
            data,
            this.httpOptions
        );
    }
    listCompanyByDomain(data) {
        return this.http.post<any>(
            environment.url + "list-company-by-domain",
            data,
            this.httpOptions
        );
    }
    listactivecompany(data) {
        return this.http.post<any>(
            environment.url + "list-active-company",
            data,
            this.httpOptions
        );
    }

    addCompany(companyObject) {
        return this.http.post<any>(
            environment.url + "add-company",
            companyObject,
            this.httpOptions
        );
    }

    updateCompany(companyObject) {
        return this.http.post<any>(
            environment.url + "update-company",
            companyObject,
            this.httpOptions
        );
    }

    getAllDocument() {
        let domainName = this.commonService.selectedCompanyDeatils.domain;
        return this.http.get(
            environment.url + `document/all/?domainName=${domainName}`
        );
    }
    forceDeleteEmployee(data) {
        return this.http.post<any>(
            environment.url + "forcedeleteEmployee",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    restoreEmployee(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "updatedeleteEmployeestatus",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    //================NEW Client Api=========================//
    createcompanyclient(data) {
        data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "clients",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getclients(data) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            environment.url +
                `clients?page=${data.page}&limit=${data.limit}&search=${data.search}`,
            this.httpOptionsForParticularCompany
        );
    }
    // =        = //

    addclient(data) {
        data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "addClient",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    editclient(data) {
        data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
        this.getauthForParticularCompany();
        return this.http.put<any>(
            environment.url + "clients/" + data._id,
            data,
            this.httpOptionsForParticularCompany
        );
    }
    getclientlist(data) {
        this.getauthForParticularCompany();
        const params = new URLSearchParams(data);
        return this.http.get<any>(
            environment.url + "getclientList?" + params.toString(),
            this.httpOptionsForParticularCompany
        );
    }
    deleteClient(data) {
        this.getauthForParticularCompany();
        return this.http.delete<any>(
            environment.url + "clients/" + data._id,
            this.httpOptionsForParticularCompany
        );
    }

    // sendInvitationBySMS(mobile, link) {
    //   return this.http.post<any>(
    //     environment.url + "sendinvitationbysms",
    //     { mobile: mobile, link: link },
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    sendInvitationByEmail(email, link) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendinvitationtoclient",
            { email: email, link: link },
            this.httpOptionsForParticularCompany
        );
    }

    // sendInvitation(clientId, link, mode) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     `${environment.url}clients/${clientId}/invite`,
    //     { mode: mode, link: link },
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // getCertificates(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.get<any>(
    //     environment.url + "certificates",
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // geteformdata(data) {
    //   data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "eforms",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // deleforms(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "deleteeform",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // addeform(data) {
    //   data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "addeforms",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // editeform(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "updateform",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // getsinglerecordeform(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "fetchoneform",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // geteformsdata() {
    //   this.getauthForParticularCompany();
    //   this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    //   const data = { akcessId: this.portalUser.akcessId };
    //   return this.http.post<any>(
    //     environment.url + "geteformresponse",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // scanef(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "eformdata",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // sendEformData(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "sendeformdata",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // getuserdatabyakcessid(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "getuserdatabyakcessid",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // fetchresponse(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "fetcheformresponse",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // duplicateEformAdd(obj) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "duplicate-eform",
    //     obj,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // fetcheformsents(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "getSentEforms",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // fetcheformreceived(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "getReceivedEforms",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    saveeformresponse(data) {
        return this.http.post<any>(
            environment.url + "save-eformresponse",
            data,
            {
                headers: new HttpHeaders({
                    Accept: "*/*",
                    api: environment.apiKey,
                    //AkcessToken: this.cbkToken,
                }),
            }
        );
    }

    // pusheformconfig(eformdata) {
    //   const data = {
    //     api: environment.apiKey,
    //     AKcessToken: sessionStorage.getItem("ak-cbk-token"),
    //     eformconfig: eformdata,
    //     portalDomainName:
    //       this.commonService.portalDetails.portalDetails.portalDomainName,
    //   };

    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "pusheformtouser",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // sendEformUrlByEmail(obj) {
    //   this.getauthForParticularCompany();

    //   const data = {
    //     api: environment.apiKey,
    //     AKcessToken: sessionStorage.getItem("ak-cbk-token"),
    //     ...obj,
    //     portalDomainName:
    //       this.commonService.portalDetails.portalDetails.portalDomainName,
    //   };

    //   return this.http.post<any>(
    //     environment.url + "sendeformurlbyemail",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // sendEformUrlByPhone(obj) {
    //   const data = {
    //     api: environment.apiKey,
    //     AKcessToken: sessionStorage.getItem("ak-cbk-token"),
    //     ...obj,
    //     portalDomainName:
    //       this.commonService.portalDetails.portalDetails.portalDomainName,
    //   };

    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "sendeformurlbyPhone",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // getSentEformUrlByPhone_email(obj) {
    //   obj["api"] = environment.apiKey;
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "getSentEformUrlByPhone_email",
    //     obj,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // adduser(data) {
    //   this.getauth();
    //   data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   return this.http.post<any>(
    //     environment.url + "adduser",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // adduserportal(data) {
    //   return this.http.post<any>(
    //     environment.url + "adduser",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // edituser(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "edituser",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // getuserlist(data) {
    //   data["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "getuserlist",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // getLiveUserList(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "getLiveUsers",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // patchUser(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "patchUser",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // getloglist(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "getloglist",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // getloglistCount(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "getloglistCount",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // getuserlist_search() {
    //   this.getauthForParticularCompany();
    //   //this.data["domainName"] = dbName;
    //   return this.http.post<any>(
    //     environment.url + "getuserlist_search",
    //     null,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // getEmployeeListWithoutSelectingCompany(dbName) {
    //   this.getAuthForCompanyWithStaticDB(dbName);
    //   //this.httpOptionsForParticularCompany.dbName = dbName;
    //   return this.http.post<any>(
    //     environment.url + "getuserlist_search",
    //     null,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // deleteUsers(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "deleteuser",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // reactiveUser(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "reactiveUser",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // changepasscode(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "changepasscode",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // readhtmlfile(){
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "readfile",'',this.httpOptions)
    // }
    // writeFile(data){

    //   this.getauth();
    //   return this.http.post<any>(environment.url + "writeFile",data,this.httpOptions)
    // }
    // getAccountData() {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "getaccountdata",
    //     "",
    //     this.httpOptions
    //   );
    // }
    // saveAccountData(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "saveaccountdata",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // approveresponse(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "approveresponse",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // verifyfield(data) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "verifyfield",
    //     data,
    //     this.httpOptionsForParticularCompany
    //   );
    // }
    // getPortalData() {
    //   this.getauth();

    //   return this.http.post<any>(
    //     environment.url + "get_portal_details",
    //     "",
    //     this.httpOptions
    //   );
    // }
    // savePortalData(data) {
    //   // alert(JSON.stringify(data))
    //   this.getauth();

    //   return this.http.post(
    //     environment.url + "save_portal_details",
    //     data,
    //     this.httpMultipartOptions
    //   );
    // }

    // sendBulkInvitationEmails(bulkEmails, link) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "sendinvitationtoclientinbulk",
    //     { email: bulkEmails, link: link },
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // sendBulkInvitationBySMS(bulkMobile, link) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "sendbulkinvitationbysms",
    //     { mobile: bulkMobile, link: link },
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // updateClientDataWithReverseOnBoarding(object) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "updateclientDatawithreversennboarding",
    //     object,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // updateEmployeeDataWithReverseOnBoarding(object) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "updateemployeeDatawithreversennboarding",
    //     object,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // sendEformUrlByEmail(obj) {
    //   obj['api'] = environment.apiKey
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "sendeformurlbyemail", obj, this.httpOptions)
    // }
    // sendEformUrlByPhone(obj) {
    //   obj['api'] = environment.apiKey
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "sendeformurlbyPhone", obj, this.httpOptions)
    // }
    // getSentEformUrlByPhone_email(obj) {
    //   obj['api'] = environment.apiKey
    //   this.getauth();
    //   return this.http.post<any>(environment.url + "getSentEformUrlByPhone_email", obj, this.httpOptions)
    // }
    // getFieldVerifierOnPortal(obj) {
    //   this.getauthForParticularCompany();
    //   return this.http.post(
    //     environment.url + `verifier/getFieldVerifierOnPortal`,
    //     obj,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // getDocVerifierOnPortal(obj) {
    //   this.getauthForParticularCompany();
    //   obj["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   obj["sessionId"] = sessionStorage.getItem("logedInUserAckessId");
    //   return this.http.post(
    //     environment.url + `verifier/getDocVerifierOnPortal`,
    //     obj,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // VerifyField(obj) {
    //   this.getauthForParticularCompany();
    //   obj["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   obj["sessionId"] = sessionStorage.getItem("logedInUserAckessId");
    //   return this.http.post(
    //     environment.url + `fields/Verify`,
    //     obj,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // docVerifyField(obj) {
    //   this.getauthForParticularCompany();
    //   obj["domainName"] = this.commonService.selectedCompanyDeatils.domain;
    //   obj["sessionId"] = sessionStorage.getItem("logedInUserAckessId");
    //   return this.http.post(
    //     environment.url + `document/docVerify`,
    //     obj,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // getQRcodeURL(data) {
    //   return this.http.post(
    //     "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBSyCuodxjbfUX0TSRqtzpH0HUaUoE30QQ",
    //     data
    //   );
    // }

    // eformInfo(obj) {
    //   return this.http.post(environment.url + "eform-info", obj);
    // }
    //field;

    // importClientFromXsl(fileData: File) {
    //   const formData = new FormData();
    //   formData.append("file", fileData);
    //   formData.append("usertype", "Admin");

    //   return this.http.post(environment.url + `importclientinbulk`, formData, {
    //     headers: new HttpHeaders({
    //       api: environment.apiKey,
    //       AkcessToken: this.cbkToken,
    //       logedInUserAckessId: sessionStorage.getItem("logedInUserAckessId"),
    //     }),
    //   });
    // }

    // fetchNotifications(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "fetchNotifications",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // fetchReports(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "fetchReports",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // fetchUpdatedClients(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "fetchUpdateClients",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // updateFieldsFromDocument(object) {
    //   this.getauthForParticularCompany();
    //   return this.http.post<any>(
    //     environment.url + "fetchdatafromdoc",
    //     object,
    //     this.httpOptions
    //   );
    // }

    // fetchClientReports(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "fetchClientReports",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // fetchUpdatFields(obj) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "fetchupdatfields",
    //     obj,
    //     this.httpOptions
    //   );
    // }

    // updateFields(obj) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "updateuserfields",
    //     obj,
    //     this.httpOptions
    //   );
    // }

    // fetchVerificationLogs(data) {
    //   this.getauth();
    //   return this.http.post<any>(
    //     environment.url + "fetchVerificationLogs",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // getAdminLogs(data) {
    //   this.getauthForParticularCompany();

    //   return this.http.get<any>(
    //     environment.url +
    //     `statics-logs?page=${data.currentPage}&limit=${data.limit}`,
    //     this.httpOptionsForParticularCompany
    //   );
    // }

    // geteidcarddata(data) {
    //   let domainName = this.commonService.selectedCompanyDeatils.domain;

    //   this.getauth();
    //   return this.http.get<any>(
    //     environment.url + `idcard/all?limit=2000&skip=0&domainName=${domainName}`,
    //     this.httpOptions
    //   );
    // }

    // deleteIdCard(idNo) {
    //   return this.http.delete<any>(
    //     environment.url + "idcard/delete?idNo=" + idNo,
    //     this.httpOptions
    //   );
    // }

    // sendbyemail(emailObject) {
    //   return this.http.post<any>(
    //     environment.url + "send-email",
    //     emailObject,
    //     this.httpOptions
    //   );
    // }
    // pdfsendbyemail(pdfObject) {
    //   return this.http.post<any>(
    //     environment.url + "send-email",
    //     pdfObject,
    //     this.httpOptions
    //   );
    // }
    // listCompany(data) {
    //   return this.http.post<any>(
    //     environment.url + "list-company",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // getCompanyDetails(data) {
    //   return this.http.post<any>(
    //     environment.url + "company-deteail",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // listCompanyByDomain(data) {
    //   return this.http.post<any>(
    //     environment.url + "list-company-by-domain",
    //     data,
    //     this.httpOptions
    //   );
    // }
    // listactivecompany(data) {
    //   return this.http.post<any>(
    //     environment.url + "list-active-company",
    //     data,
    //     this.httpOptions
    //   );
    // }

    // addCompany(companyObject) {
    //   return this.http.post<any>(
    //     environment.url + "add-company",
    //     companyObject,
    //     this.httpOptions
    //   );
    // }

    // updateCompany(companyObject) {
    //   return this.http.post<any>(
    //     environment.url + "update-company",
    //     companyObject,
    //     this.httpOptions
    //   );
    // }

    // getAllDocument() {
    //   let domainName = this.commonService.selectedCompanyDeatils.domain;
    //   return this.http.get(
    //     environment.url + `document/all/?domainName=${domainName}`
    //   );
    // }

    uploadDocument(file) {
        this.getauthForParticularCompanywithfile();
        return this.http.post(
            environment.url + `uploaddoc`,
            file,
            this.httpOptionsForParticularCompanywithfile
        );
    }

    shareDocument(data) {
        return this.http.post(environment.url + `document/send`, data);
    }

    checkAndGetDomainInfo(domainName) {
        return this.http.get(
            environment.url + `viewerheader/?domain=${domainName}`
        );
    }

    updateClientCreditStatus(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "update-client-creditscore",
            obj,
            this.httpOptions
        );
    }

    getAllDocuments(docName) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            environment.url + "getalldocuments?search=" + docName,
            this.httpOptionsForParticularCompany
        );
    }

    getAllCertificates() {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            environment.url + "getallcertificates",
            this.httpOptionsForParticularCompany
        );
    }

    bulkEformDelete(payload) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "bulk-eform-delete",
            payload,
            this.httpOptionsForParticularCompany
        );
    }

    myfetchresponse(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "myfetcheformresponse",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    registerNewCompany(payload) {
        let object = {
            ...payload,
            api: environment.apiKey,
            akcesstoken: this.cbkToken,
        };
        return this.http.post<any>(
            environment.url + "compnayregister-deeplink",
            object
        );
    }

    //SoftDelete
    softDelete(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "deleteDocument",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    //Fetch Deleted List
    getDeletedList(typeName, search) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            environment.url +
                `getrecyclebincomponentdata?component=${typeName}&search=${search}`,
            this.httpOptionsForParticularCompany
        );
    }
    //Force Delete
    forceDeleteData(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "forcedeletecomponentdata",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    //Restore Delete
    restoreDeletedData(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "updatedeletecomponentstatus",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    //update client status
    updateClientStatus(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "updateClientStatus",
            obj,
            this.httpOptions
        );
    }
    //credit score list
    clientCreditScoreList(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "blacklist-client-list",
            obj,
            this.httpOptions
        );
    }

    //Asset List
    getassetlist(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "get-asset-listing",
            data,
            this.httpOptions
        );
    }

    //Verify Asset
    verifyAsset(data) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "assest/verify",
            data,
            this.httpOptions
        );
    }

    //Add Bank
    addBank(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "bank-add",
            obj,
            this.httpOptions
        );
    }

    //List Bank Other Setting
    listBank(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "bank-list",
            obj,
            this.httpOptions
        );
    }

    //Delete Bank
    deleteBank(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "bank-delete",
            obj,
            this.httpOptions
        );
    }

    //Update Bank
    updateBank(obj) {
        this.getauth();
        return this.http.post<any>(
            environment.url + "bank-update",
            obj,
            this.httpOptions
        );
    }

    /* Other bank */
    // otherBankClient(obj) {
    //   return this.http.post<any>(obj.url + "/api/other-bank-client", obj);
    // }

    //Get eForm short link
    generateeFormDeepLinking(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + `generateeformlink`,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendEmail(obj) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "send-email",
            obj,
            this.httpOptionsForParticularCompany
        );
    }
    sendMessage(obj) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "send-message",
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    sendNotification(obj) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "send-notification",
            obj,
            this.httpOptionsForParticularCompany
        );
    }
    getMessagesHistory(obj) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "get-messages-history",
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    //Get Locations
    getLocations(data) {
        this.getauthForParticularCompany();
        const params = new URLSearchParams(data);
        return this.http.get<any>(
            `${environment.url}location?${params.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }
    getLocation(locationId) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            `${environment.url}location/${locationId}`,
            this.httpOptionsForParticularCompany
        );
    }

    /* Other bank */
    otherBankClient(obj) {
        return this.http.post<any>(obj.url + "/api/other-bank-client", obj);
    }

    getDefaultInvitation(obj) {
        return this.http.post(
            environment.url + "fetch-eform-invitation",
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    cloneEform(data, db_name) {
        // let header: new HttpHeaders({
        //     db_name : db_name
        // })
        return this.http.post(environment.url + "clone-eform", data, {
            headers: { db_name: db_name },
        });
    }
    createLocation(entity) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            `${environment.url}location`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    updateLocation(entity, locationId) {
        this.getauthForParticularCompany();
        return this.http.put<any>(
            `${environment.url}location/${locationId}`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    getLocationStaff(data, locationId) {
        this.getauthForParticularCompany();
        const params = new URLSearchParams(data);
        return this.http.get<any>(
            `${
                environment.url
            }locationStaff/${locationId}?${params.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }

    addRemoveLocationStaff(entity, locationId) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            `${environment.url}addRemoveLocationStaff/${locationId}/`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    setUserDataFromAkcessId(aid) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + "setUserDataFromAkcessId",
            aid,
            this.httpOptionsForParticularCompany
        );
    }

    getUserInformation(data) {
        let headers = new HttpHeaders({
            "Content-Type": "application/json",
            apikey: environment.apiKey,
            authorization: this.cbkToken || "token",
            // logedInUserAckessId: sessionStorage.getItem("logedInUserAckessId")
            //   ? sessionStorage.getItem("logedInUserAckessId")
            //   : "asd",
            // db_name: this.commonService.selectedCompanyDeatils.dbName || "asd",
            // Origin: `https://${window.location.origin}`,
            Origin: "https://sme.akcess.app/",
            //'x-access-token' : this.token
        });

        return this.http.post(
            environment.urlloginV2 + "request-userinfo",
            data,
            { headers: headers }
        );
    }

    getInformationRequest(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + "getInformationRequest",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    createDocumentFolder(data) {
        this.getauthForParticularCompany();
        return this.http.post(
            environment.url + "folder",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    deleteDocumentFolder(data) {
        this.getauthForParticularCompany();
        return this.http.delete(
            environment.url + "folder/" + data._id,
            this.httpOptionsForParticularCompany
        );
    }

    updateDocumentFolder(data, id) {
        this.getauthForParticularCompany();
        return this.http.put(
            environment.url + "folder/" + id,
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getDocumentFolderList() {
        this.getauthForParticularCompany();
        return this.http.get(
            environment.url + "folder",
            this.httpOptionsForParticularCompany
        );
    }

    getIndividualFolderDetail(id) {
        this.getauthForParticularCompany();
        return this.http.get(
            environment.url + "folder/" + id,
            this.httpOptionsForParticularCompany
        );
    }

    getAttendanceList(data) {
        const params = new URLSearchParams(data);
        this.getauthForParticularCompany();
        return this.http.get<any>(
            `${environment.url}attendance?${params.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }

    //Get Locations
    getAccessControls(data) {
        this.getauthForParticularCompany();
        const params = new URLSearchParams(data);
        return this.http.get<any>(
            `${environment.url}accessControl?${params.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }
    getAccessControl(accessControlId) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            `${environment.url}accessControl/${accessControlId}`,
            this.httpOptionsForParticularCompany
        );
    }

    createAccessControl(entity) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            `${environment.url}accessControl`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    updateAccessControl(entity, accessControlId) {
        this.getauthForParticularCompany();
        return this.http.put<any>(
            `${environment.url}accessControl/${accessControlId}`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    getAccessControlStaff(data, accessControlId) {
        this.getauthForParticularCompany();
        const params = new URLSearchParams(data);
        return this.http.get<any>(
            `${
                environment.url
            }accessControlStaff/${accessControlId}?${params.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }

    addRemoveAccessControlStaff(entity, accessControlId) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            `${environment.url}addRemoveAccessControlStaff/${accessControlId}/`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    getStaffInvitationSentList(data) {
        this.getauthForParticularCompany();
        return this.http.get(
            environment.url +
                "getStaffInvitationSentList?page=" +
                data.currentPage +
                "&limit=" +
                data.totalData +
                "&usertype=" +
                data.usertype,
            this.httpOptionsForParticularCompany
        );
    }

    uploadPhoto(file) {
        this.getauthForParticularCompanywithfile();
        return this.http.post(
            environment.url + `uploadphoto`,
            file,
            this.httpOptionsForParticularCompanywithfile
        );
    }

    getProperties(data) {
        this.getauthForParticularCompany();
        const params = new URLSearchParams(data);
        return this.http.get<any>(
            `${environment.url}property?${params.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }

    getProperty(propertyId) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            `${environment.url}property/${propertyId}`,
            this.httpOptionsForParticularCompany
        );
    }

    createProperty(entity) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            `${environment.url}property`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    updateProperty(entity, propertyId) {
        this.getauthForParticularCompany();
        return this.http.put<any>(
            `${environment.url}property/${propertyId}`,
            entity,
            this.httpOptionsForParticularCompany
        );
    }

    getPropertyInquiryList(data, propertyId = "") {
        const params = new URLSearchParams(data);
        this.getauthForParticularCompany();
        if (propertyId) {
            return this.http.get<any>(
                `${
                    environment.url
                }property-inquiry/${propertyId}/?${params.toString()}`,
                this.httpOptionsForParticularCompany
            );
        } else {
            return this.http.get<any>(
                `${environment.url}property-inquiry/?${params.toString()}`,
                this.httpOptionsForParticularCompany
            );
        }
    }

    getPropertyInquirySingle(inquiryId) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            `${environment.url}property-inquiry-view/${inquiryId}`,
            this.httpOptionsForParticularCompany
        );
    }

    submitAssets(formData) {
        this.getauthForParticularCompanywithfile();
        return this.http.post<any>(
            `${environment.url}assets`,
            formData,
            this.httpOptionsForParticularCompanywithfile
        );
    }

    updateAsset(entity, assetId) {
        this.getauthForParticularCompanywithfile();
        return this.http.put<any>(
            `${environment.url}assets/${assetId}`,
            entity,
            this.httpOptionsForParticularCompanywithfile
        );
    }

    deleteCompany(data) {
        return this.http.post<any>(
            environment.url + "company/delete",
            data,
            this.httpOptions
        );
    }

    getAssets(data) {
        this.getauthForParticularCompany();
        const params = new URLSearchParams(data);
        return this.http.get<any>(
            `${environment.url}assets?${params.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }

    getAsset(assetId) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            `${environment.url}assets/${assetId}`,
            this.httpOptionsForParticularCompany
        );
    }

    pushAssetConfig(assetData) {
        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            assetconfig: assetData,
            portalDomainName:
                this.commonService.portalDetails.portalDetails.portalDomainName,
        };

        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "pushAssetToUser",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getPropertyForInquiry(propertyId, dbName) {
        return this.http.get<any>(
            `${environment.url}propertyforinquiry/${propertyId}`,
            {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    api: environment.apiKey,
                    db_name: dbName,
                }),
            }
        );
    }

    submitPropertyInquiryForm(data, dbName) {
        return this.http.post<any>(environment.url + "property-inquiry", data, {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                db_name: dbName,
            }),
        });
    }

    listCompanyBydbName(data) {
        return this.http.post<any>(
            environment.url + "list-company-by-dbname",
            data,
            this.httpOptions
        );
    }

    getSentAssets(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getSentAssets",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getAssetReceived(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getAssetReceived",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    sendAssetUrlByEmail(obj) {
        this.getauthForParticularCompany();

        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            ...obj,
            portalDomainName:
                this.commonService.portalDetails.portalDetails.portalDomainName,
        };

        return this.http.post<any>(
            environment.url + "sendAssetUrlByEmail",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    sendAssetUrlByPhone(obj) {
        const data = {
            api: environment.apiKey,
            AKcessToken: sessionStorage.getItem("ak-cbk-token"),
            ...obj,
            portalDomainName:
                this.commonService.portalDetails.portalDetails.portalDomainName,
        };

        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "sendAssetUrlByPhone",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getSentAssetUrlByPhoneOrEmail(obj) {
        obj["api"] = environment.apiKey;
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getSentAssetUrlByPhoneOrEmail",
            obj,
            this.httpOptionsForParticularCompany
        );
    }

    generateAssetDeepLinking(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + `generateAssetLink`,
            data,
            this.httpOptionsForParticularCompany
        );
    }
    gbgUploadDocument(data) {
        this.getauthForParticularCompany();
        console.log(data);
        // const params = new URLSearchParams(data);
        return this.http.post<any>(
            environment.url + "gbgUploadDocument",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    CredasUploadfront(data) {
        this.getauthForParticularCompany();
        console.log(data);
        // const params = new URLSearchParams(data);
        return this.http.post<any>(
            environment.url + "api/v1/credas/check-document",
            data,
            this.httpOptionsForParticularCompany
        );
    }
    gbgUploadDocumentResult(data) {
        this.getauthForParticularCompany();
       
        // const params = new URLSearchParams(data);
        return this.http.post<any>(
            environment.url + "getJourneyByPersonId/" + data,
            {},
            this.httpOptionsForParticularCompany
        );
    }
    getAllgbgDocument() {
        this.getauthForParticularCompany();
        // const params = new URLSearchParams(data);
        return this.http.get<any>(
            environment.url + "getAllgbgDocument",
            this.httpOptionsForParticularCompany
        );
    }

    getFilterOptions(component) {
        this.getauthForParticularCompany();
        return this.http.get<any>(
            `${environment.url}getFilterOptions?component=${component}`,
            this.httpOptionsForParticularCompany
        );
    }

    getNotifications(params) {
        this.getauthForParticularCompany();
        let queryParams = new URLSearchParams(params);
        return this.http.get<any>(
            `${environment.url}getnotifications?${queryParams.toString()}`,
            this.httpOptionsForParticularCompany
        );
    }

    getUserAdminList(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getUserAndAdminList",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    moveDocument(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "moveDocument",
            data,
            this.httpOptionsForParticularCompany
        );
    }

    getALLStoreSignature(data) {
        this.getauthForParticularCompany();
        return this.http.post<any>(
            environment.url + "getALLStoreSignature",
            data,
            this.httpOptionsForParticularCompany
        );
    }
}
