<!-- Add Certificate Modal -->
<div class="modal-content no-border view-certificate-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="viewCertificateModalLabel">Create Certificate</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form (ngSubmit)="createCertificate()" [formGroup]="adduserform">
        <div class="modal-body">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="owner_name" class="font-500">First Name<span class="required-label">*</span></label>
                    <input type="text" class="form-control" formControlName="owner_name" placeholder="Enter First Name"
                           [ngModel]="fn" (ngModelChange)="getChange($event)" required>
                    <div *ngIf="isSubmitted && adduserform.get('owner_name').hasError('required')" class="invalid-feedback">
                        First Name is required
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="certificate_name" class="font-500">Last Name<span class="required-label">*</span></label>
                    <input type="text" class="form-control" formControlName="certificate_name" placeholder="Enter Last Name"
                           [ngModel]="ln" (ngModelChange)="getChange2($event)" required>
                    <div *ngIf="isSubmitted && adduserform.get('certificate_name').hasError('required')" class="invalid-feedback">
                        Last Name is required
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="director_name" class="font-500">Director Name<span class="required-label">*</span></label>
                    <input type="text" class="form-control" formControlName="director_name" placeholder="Enter Director Name"
                           [ngModel]="director_name" required>
                    <div *ngIf="isSubmitted && adduserform.get('director_name').hasError('required')" class="invalid-feedback">
                        Director Name is required
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="manager_name" class="font-500">Manager Name<span class="required-label">*</span></label>
                    <input type="text" class="form-control" formControlName="manager_name" placeholder="Enter Manager Name"
                           [ngModel]="manager_name" required>
                    <div *ngIf="isSubmitted && adduserform.get('manager_name').hasError('required')" class="invalid-feedback">
                        Manager Name is required
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="cert_num" class="font-500">Certificate No.</label>
                    <input type="text" class="form-control" formControlName="cert_num" maxlength="12" readonly>
                </div>
                <div class="form-group col-md-6">
                    <label for="expiry_date" class="font-500">Certificate Expiry Date<span class="required-label">*</span></label>
                    <!--<input type="date" class="form-control" formControlName="expiry_date" placeholder="Select Date"
                           (change)="getCertexp($event)" required>-->
                    <input type="text" class="form-control" name="expiry_date" placeholder="Select Date" (bsValueChange)="getCertexp($event)"
                           formControlName="expiry_date" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red', isAnimated: true, adaptivePosition: true }" required>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn waves-effect waves-light btn-info">Create</button>
            <button type="button" class="btn waves-effect waves-light btn-danger" id="close" (click)="closeModal()">
                Close
            </button>
        </div>
    </form>
</div>