import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/_services/common.service';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { RefreshContentService } from 'src/app/_services/refresh-content.service';
declare var $: any;

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {

  selectedCompany:any={}
  companyDetails:any={}
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private calldata: DataservicesService,
    private router: Router,
    private refreshContentService:RefreshContentService,
    public commonService:CommonService
  ) { }

  ngOnInit(): void {
      
    this.refreshContentService.notifyToAll.subscribe(res => {
      if (res && res.hasOwnProperty("option")) {
        this.getcompanyDetails();
      }
      else {
        this.getcompanyDetails();
      }
    });
  }

  getcompanyDetails(){
    this.spinner.show();
    let data = {
      domainName: this.commonService.selectedCompanyDeatils.domain
    }

    this.calldata.getCompanyDetails(data).subscribe(
      (res:any) => {
        
        if (res.status) {
          this.companyDetails = res.data;
          
        } 
          // this.toast.info(data["message"]);
          this.spinner.hide();
        
      }, (err) => {
        this.spinner.hide();
        this.toast.error("Oops, something went wrong. Please try again.")
    }
    );
  }

}
