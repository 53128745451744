<!-- Create Sub Section Modal -->
<div class="modal-content no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="sectionModalLabel" *ngIf="sectionType">
            Create New Section
        </h4>
        <h4 class="modal-title" id="sectionModalLabel" *ngIf="!sectionType">
            Edit Section
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body background-light-blue">
        <div class="row">
            <div class="col-lg-8 pr-lg-0">
                <div class="card card-outline-info">
                    <h4 class="m-b-0 text-white">&nbsp;</h4>
                </div>
                <div class="card-body">
                    <div class="form-group row align-items-center">
                        <label class="col-lg-5 col-form-label font-500">Section Name</label>
                        <div class="col-lg-7 d-flex mb-0">
                            <input class="form-control" [(ngModel)]="section_title" placeholder="Enter section name"
                                type="text" />
                        </div>
                    </div>
                    <div class="form-group row align-items-center">
                        <label class="col-sm-2 col-md-5 col-form-label font-500">Hide Section</label>
                        <div class="col-sm-10 col-md-7 d-flex mb-0">
                            <div class="form-check">
                                <input class="with-gap radio-col-light-blue" type="radio" name="section_show"
                                    [(ngModel)]="section_show" value="yes" id="section_show_yes" />
                                <label for="section_show_yes"> Yes </label>
                            </div>
                            <div class="form-check ml-3">
                                <input class="with-gap radio-col-light-blue" type="radio" name="section_show"
                                    [(ngModel)]="section_show" value="no" id="section_show_no" />
                                <label for="section_show_no"> No </label>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="form-group row" *ngFor="let data of filter_section; let i = index;">
                        <label for="{{data.labelname}}" class="col-12 col-md-4 col-form-label font-500">
                            {{data?.labelname}}
                        </label>
                        <!-- Input Tags -->
                        <div class="col-12 col-md-8 position-relative" [ngSwitch]="data.keytype">
                            <!--Section Radio Field -->
                            <div *ngSwitchCase="'radio'">
                                <div *ngFor="let option of data.options; let j = index">
                                    <div class="custom-control custom-radio">
                                        <input type="radio" class="with-gap radio-col-light-blue" id="{{ option.uid }}"
                                            name="{{ data.labelname }}" value="{{ option.lable }}"
                                            (click)="onclick($event, i, j)"
                                            [checked]="option.checked == true ? true : false" />
                                        <label class="form-check-label" for="{{ option.value }}">
                                            {{ option.lable }}
                                        </label>
                                    </div>
                                </div>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <!-- Section Checkbox Field -->
                            <div *ngSwitchCase="'checkbox'">
                                <div *ngFor="let option of data.options; let j = index">
                                    <div>
                                        <input type="checkbox" id="{{ option.uid }}" name="{{ data.labelname }}"
                                            value="{{ option.lable }}" (click)="checkbox($event, i, j)"
                                            [checked]="option.checked == true ? true : false" />
                                        <label for="{{ option.value }}"> &nbsp; {{ option.lable }}</label>
                                    </div>
                                </div>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <!-- Section Select Field -->
                            <div *ngSwitchCase="'select'">
                                <select class="form-control custom-select">
                                    <option *ngFor="let option of data.options" value="{{ option.key }}"
                                        id="{{ option.uid }}">
                                        {{ option.lable }}
                                    </option>
                                </select>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <!-- Section List Field -->
                            <div *ngSwitchCase="'list'">
                                <select class="form-control custom-select">
                                    <option *ngFor="let option of data.options" value="{{ option.key }}"
                                        id="{{ option.uid }}">
                                        {{ option.lable }}
                                    </option>
                                </select>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <!-- Section Textarea -->
                            <div class="position-relative" *ngSwitchCase="'textarea'">
                                <textarea class="form-control" id="textarea" name="textarea" rows="2"
                                    value="{{ data.field_value }}"></textarea>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <!-- Section Address -->
                            <div class="position-relative" *ngSwitchCase="'address'">
                                <textarea class="form-control" placeholder="{{ data.keytype }}" id="{{ data.key }}"
                                    value="{{ data.field_value }}"
                                    (change)="onChangeEvent(data.labelname, $event, 'section')" rows="2"></textarea>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <!-- Section File -->
                            <div class="position-relative" *ngSwitchCase="'file'">
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="{{ data.key }}"
                                        value="{{ data.field_value }}"
                                        (change)="onChangeEvent(data.labelname, $event, 'section')" />
                                    <label class="custom-file-label" for="{{ data.key }}">Choose file</label>
                                </div>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                            <!-- Section Normal Fields -->
                            <div class="position-relative" *ngSwitchDefault>
                                <div>
                                    <input type="{{ data.keytype }}" class="form-control" value="{{ data.field_value }}"
                                        placeholder="{{ data.keytype }}" id="{{ data.key }}"
                                        (change)="onChangeEvent(data.labelname, $event, 'section')" />
                                    <select class="form-control custom-select-select" [(ngModel)]="data.date_type"
                                        *ngIf="data.keytype == 'date'">
                                        <option *ngFor="let fields of date_type_option" value="{{fields.value}}">
                                            {{fields.label}}
                                        </option>
                                    </select>
                                </div>
                                <button (click)="deleteele(data.labelname, 'delfilterrec')" class="btn btn-form-delete">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-block col-lg-4">
                <div class="card card-outline-info right-bar-filter mb-0">
                    <div class="card-header">
                        <h4 class="m-b-0 text-white">Add Fields</h4>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="form-group">
                                <label class="font-500">Label Name</label>
                                <input class="form-control" [(ngModel)]="section_field_lable"
                                    placeholder="{{ section_field_lable }}" type="text" />
                            </div>
                            <div class="form-group">
                                <label class="font-500">Field Type</label>
                                <select class="form-control custom-select" [(ngModel)]="section_field_type">
                                    <option *ngFor="let fields of field_type_option" value="{{ fields.value }}"> {{
                                        fields.label }} </option>
                                </select>
                            </div>
                            <div [ngSwitch]="section_field_type">
                                <div class="form-group" *ngSwitchCase="'checkbox'">
                                    <label class="font-500">Field Options</label>
                                    <ngx-tags-input class="form-control" [(ngModel)]="section_tags" name="section_tags"
                                        (onTagsChanged)="onTagsChanged($event, 'section')" onNoOptionsMatch="true"
                                        placeholder="Field Options"></ngx-tags-input>
                                    <p class="note-txt">Press enter after input the field.</p>
                                </div>
                                <div class="form-group" *ngSwitchCase="'radio'">
                                    <label class="font-500">Field Options</label>
                                    <ngx-tags-input class="form-control" [(ngModel)]="section_tags" name="section_tags"
                                        (onTagsChanged)="onTagsChanged($event, 'section')" onNoOptionsMatch="true"
                                        placeholder="Field Options"></ngx-tags-input>
                                    <p class="note-txt">Press enter after input the field.</p>
                                </div>
                                <div class="form-group" *ngSwitchCase="'select'">
                                    <label class="font-500">Field Options</label>
                                    <ngx-tags-input class="form-control" [(ngModel)]="section_tags" name="section_tags"
                                        (onTagsChanged)="onTagsChanged($event, 'section')" onNoOptionsMatch="true"
                                        placeholder="Field Options"></ngx-tags-input>
                                    <p class="note-txt">Press enter after input the field.</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-sm waves-effect waves-light btn-info" (click)="
                              addsectionfield(
                              'section',
                              this.section_field_lable,
                              this.key,
                              this.section_field_type,
                              this.section_tags,
                              this.section_title,
                              this.section_show
                              )
                              ">
                                    Add Custom Field in Section
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="searchLabel" class="font-500">Search Label:</label>
                            <input type="text" [(ngModel)]="search_eForm_labels" class="form-control"
                                placeholder="Search Label" />
                        </div>
                        <div class="form-group">
                            <label for="searchLabel" class="font-500">Search Label:</label>
                            <input type="text" [(ngModel)]="search_eForm_labels" class="form-control"
                                placeholder="Search Label" />
                        </div>
                        <div class="form-group">
                            <label for="filterByCountry" class="font-500">Filter by Country:</label>
                            <select class="form-control custom-select" [(ngModel)]="filter_country"
                                (change)="globalprofile()">
                                <option *ngFor="let country of filter_country_options" value="{{ country.value }}"> {{
                                    country.label }} </option>
                            </select>
                        </div>
                        <!--<ng-select [(ngModel)]="filter_country">
                        <ng-option *ngFor="let country of filter_country_options" [value]="country.value">{{country.label}}</ng-option>
                        </ng-select>-->
                        <div class="multi-fields">
                            <div *ngFor="let data of globallist| filter:search_eForm_labels; let i = index;"
                                class="mx-1 my-1">
                                <button type="button" data-toggle="modal"
                                    (click)="addsectionfield(data.type,data.labelname,data.key,'',data.type,section_title,this.section_show)"
                                    class="btn btn-sm waves-effect waves-light btn-secondary"> {{data.labelname}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-info" (click)="createSection()"> Create Section
        </button>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()"> Close </button>
    </div>
</div>