<div class="property-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/property']">Property</a>
                </li>
                <li class="breadcrumb-item active">Deleted</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div class="input-group">
                                <input
                                    type="search"
                                    class="form-control"
                                    id="search"
                                    name="searchName"
                                    placeholder="Search Property"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="action-tab">
                        <button
                            type="button"
                            class="mx-1 btn"
                            container="body"
                            [routerLink]="['/panel/property']"
                        >
                            <i class="fas fa-arrow-left"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button
                            type="button"
                            class="mx-1 btn"
                            tooltip="Remove Permanently"
                            container="body"
                            (click)="deletePermantly()"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button
                            type="button"
                            class="mx-1 btn"
                            tooltip="Restore"
                            container="body"
                            (click)="restoreDeleted()"
                        >
                            <i class="fas fa-trash-restore"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableEl="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th>
                                        <div class="form-check">
                                            <input
                                                class="datatable-row-checkbox form-check-input"
                                                type="checkbox"
                                                (change)="checkUncheckAll()"
                                                [(ngModel)]="isMasterSel"
                                            />
                                        </div>
                                    </th>
                                    <th>Photo</th>
                                    <th>
                                        Property Name
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th>Description</th>
                                    <th>
                                        <i class="fal fa-pound-sign"></i> Price
                                    </th>
                                    <th>
                                        Created On
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th>
                                        Deleted On
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="propertyDeletedlist?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No Deleted property Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="propertyDeletedlist?.length > 0">
                                <tr
                                    [id]="property?._id"
                                    *ngFor="
                                        let property of propertyDeletedlist
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                >
                                    <td>
                                        <div class="form-check">
                                            <input
                                                class="datatable-row-checkbox form-check-input"
                                                type="checkbox"
                                                [(ngModel)]="
                                                    property.isSelected
                                                "
                                                [attr.id]="property?._id"
                                                value="property?._id"
                                                (change)="isAllSelected()"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <img
                                            *ngIf="property.photo"
                                            src="{{
                                                getFullURL(property.photo)
                                            }}"
                                            class="img-fluid"
                                            alt="{{ property.name }}"
                                            title="{{ property.name }}"
                                            width="100"
                                        />
                                    </td>
                                    <td>
                                        {{ property.name }}
                                    </td>
                                    <td>
                                        {{ property.description }}
                                    </td>
                                    <td>
                                        <i class="fal fa-pound-sign"></i>
                                        {{ property.price }}
                                    </td>
                                    <td>
                                        {{
                                            property.createdAt
                                                | date : "mediumDate"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            property.updatedAt
                                                | date : "mediumDate"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div
                                            class="table-page"
                                            *ngIf="
                                                propertyDeletedlist?.length > 10
                                            "
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
