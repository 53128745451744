import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})

export class GoogleApiService {
    constructor(private http: HttpClient) { }

    getGoogleAccessToken(data) {
        return this.http.post('http://localhost:3000/googleAuthentication', data).pipe();
    }

    createGoogleSheet(gToken,data) {        
        return this.http.post("https://sheets.googleapis.com/v4/spreadsheets", data, { headers: { 'Authorization': 'Bearer ' + gToken } }).pipe();
    }
}