<div class="modal-content no-border modal-lg">
    <div class="modal-header">
        <h4 class="modal-title" id="viewuserModalLabel">Generate Id Card</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="addGenerateIdForm" (ngSubmit)="genearteIdCard(singleEmployeeDataModal)">
        <div class="modal-body">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <!-- *ngIf="!initialState.profileimg.includes('data')" -->                                        
                    <div class="form-group">
                        <label class="font-500" *ngIf="!initialState.profileimg">Upload Profile Image<span class="required-label">*</span></label>
                        <img class="upload-profile-img" src="{{initialState.profileimg}}" *ngIf="initialState.profileimg">
                        <label class="font-500" *ngIf="initialState.profileimg">Upload New Profile Image</label>
                        <div class="custom-file">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="importFile"
                                    formControlName="photoUpload" (change)="onFileChange($event)">
                                <label class="custom-file-label" #labelImport for="importFile">
                                    <span *ngIf=!isFileChosen>Choose Image</span><span
                                        *ngIf=isFileChosen>{{fileName}}</span>
                                </label>
                            </div>
                        </div>
                        <div [innerHtml]='msg' *ngIf="msg" class="small error-msg"></div>
                        <div class="invalid-feedback"
                            *ngIf="!initialState.profileimg && showUploadMsg">
                            Upload an image
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <label class="font-500">Date of Birth<span class="required-label">*</span></label>
                        <input type="text" class="form-control" name="DOB" placeholder="Select Date"
                            formControlName="DOB" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red', isAnimated: true, adaptivePosition: true }">
                        <div class="invalid-feedback" *ngIf="showDobMsg">
                            Please select DOB
                        </div>
                    </div> -->
                    <div class="form-group">
                        <label class="font-500">ID Card Expiry Date<span class="required-label">*</span></label>
                        <input type="text" class="form-control" placeholder="Select Date"
                            formControlName="idCardExpiyDate" bsDatepicker
                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red', isAnimated: true, adaptivePosition: true }"
                            [minDate]="minDate">
                        <div class="invalid-feedback" *ngIf="showExpMsg">
                            Please select expiry date
                        </div>
                        <div class="invalid-feedback" *ngIf="showExpMsgCompare">
                            Expiry date should be greater than current date
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6" *ngIf="showPreview">
                    <div class="id-card">
                        <div class="image-section">
                            <img class="card-image-2" src="../../assets/img/ooredoo_logo_preview-removebg-preview.png">
                        </div>
                        <div class="profile-picture">
                            <img class="profile-img" [src]="url" *ngIf="url" alt="Card image cap">
                        </div>
                        <div class="profile-content">
                            <div class="form-group d-flex">
                                <h5 class="title mb-0">ID # :</h5>
                                <h5 class="mb-0">{{ cardAkcessId}}</h5>
                            </div>
                            <div class="form-group d-flex align-items-center">
                                <h5 class="title mb-0">Name :</h5>
                                <h5 class="mb-0">{{cardEmpName}}</h5>
                            </div>
                            <div class="form-group d-flex align-items-center">
                                <h5 class="title mb-0">Company :</h5>
                                <h5 class="mb-0">{{domainName}}</h5>
                            </div>
                            <div class="form-group d-flex align-items-center">
                                <h5 class="title mb-0">Email :</h5>
                                <h5 class="mb-0">{{cardEmpEmail}}</h5>
                            </div>
                            <div class="form-group d-flex align-items-center">
                                <h5 class="title mb-0">Mobile :</h5>
                                <h5 class="mb-0">{{cardEmpMobile}}</h5>
                            </div>
                            <div class="form-group d-flex">
                                <h5 class="title mb-0">DOB :</h5>
                                <h5 class="mb-0">{{DOB | date:'d/M/yy'}}</h5>
                            </div>
                            <div class="form-group d-flex">
                                <h5 class="title mb-0">Valid Till :</h5>
                                <h5 class="mb-0">{{idCardExpiyDate | date:'d/M/yy'}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn waves-effect waves-light btn-success"
                (click)="preview(singleEmployeeDataModal)" *ngIf="!showSaveBtn">
                Preview
            </button>
            <button type="submit" class="btn waves-effect waves-light btn-info" *ngIf="showSaveBtn">
                Save
            </button>
            <button type="button" class="btn waves-effect waves-light btn-danger" id="close" (click)="closeModal()">
                Close
            </button>
        </div>
    </form>
</div>