import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/_services/common.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
    counts;
    usercounts;
    eformcounts;
    eformresponsecounts;
    portalUser;
    data: any;
    data1: any;
    options: any;

    adminCounts: number;
    employeeCounts: number;
    clientCounts: number;
    eFormsCounts: number;
    eFormResponseCounts: number;
    documentsCounts: number;
    certificateCounts: number;
    logsCounts: number;
    propertiesCounts: number;
    propertyInquiriesCounts: number;
    assetsCount: number = 0;
    currentCompany: any = {};
    constructor(
        private spinner: NgxSpinnerService,
        private akcessService: GeneralServiceService,
        private toast: ToastrService,
        public commonService: CommonService
    ) {}

    ngOnInit(): void {
        // this.spinner.hide();
        //this.portalUserPermissions();
        this.currentCompany = this.commonService.selectedCompanyDeatils;
        this.getCounts();
        this.data = {
            labels: ["Users"],
            datasets: [
                {
                    label: "Active Users",
                    backgroundColor: "#42A5F5",
                    borderColor: "#1E88E5",
                    data: [65],
                },
                {
                    label: "Inactive Users",
                    backgroundColor: "#9CCC65",
                    borderColor: "#7CB342",
                    data: [28],
                },
            ],
        };
        this.data1 = {
            labels: ["Active Users", "Inactive Users"],
            datasets: [
                {
                    data: [100, 20],
                    backgroundColor: ["#FF6384", "#36A2EB"],
                    hoverBackgroundColor: ["#FF7993", "#36B9FA"],
                },
            ],
        };
        this.options = {
            legend: {
                position: "right",
            },
        };
    }
    getCounts() {
        //this.spinner.show();
        //this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        //const tmpdata = {'akcessId':this.portalUser.akcessId, 'usertype':this.portalUser.usertype};
        //this.akcessService.getCounts(tmpdata).subscribe(data => {
        //  // alert(JSON.stringify(data))
        //  // alert(JSON.stringify(data["status"]))
        //  if(data["status"]===1){
        //    this.counts = data['data'];
        //    this.usercounts = data['data']['usercounts'];
        //    this.eformcounts = data['data']['eformcounts'];
        //    this.eformresponsecounts = data['data']['eformresponsecounts'];
        //    // alert(JSON.stringify(this.counts))
        //    this.spinner.hide();
        //  }
        //})
        this.spinner.show();
        //new Get Dashboard Count
        this.akcessService.getDashboardCount().subscribe(
            (data) => {
                if (data["status"] === true) {
                    let countData = data["data"];
                    this.adminCounts = countData.admin;
                    this.employeeCounts = countData.employees;
                    this.clientCounts = countData.clients;
                    this.eFormsCounts = countData.eforms;
                    this.eFormResponseCounts = countData.responses;
                    this.documentsCounts = countData.documents;
                    this.certificateCounts = countData.certificates;
                    this.logsCounts = countData.logs;
                    this.propertiesCounts = countData.properties;
                    this.propertyInquiriesCounts = countData.property_inquiries;
                    this.assetsCount = countData.assets;
                    this.spinner.hide();
                }
            },
            (err) => {
                this.spinner.hide();
                this.toast.error(
                    "Oops, something went wrong. Please try again."
                );
            }
        );
    }

    portalUserPermissions() {
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
    }
}
