<div class="container bg-faded py-3">
    <h1 class="text-center">{{ eformName | titlecase }}</h1>
    <div class="row">
        <!-- <div class="col text-center">{{ eformData?.description }}</div> -->
    </div>
    <hr />
    <div class="row">
        <div class="col">
            <div class="mx-auto w-50 p-3 text-center">
                <qrcode
                    [qrdata]="qrcodeData"
                    [size]="240"
                    [level]="'M'"
                ></qrcode>
                <p>
                    Please scan this QR code to open the eForm on AKcess
                    application
                </p>
            </div>
        </div>
    </div>
</div>
