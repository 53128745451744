<!--<ng-scrollbar>
    <div class="side-navbar-wrapper">-->
<!-- Sidebar Header -->
<!--<div class="sidenav-header d-flex align-items-center justify-content-center">-->
<!-- User Info-->
<!--<div class="sidenav-header-inner text-center">
    <img src="{{commonService.selectedCompanyDeatils?.logo || portalLogo }}" alt="Logo" class="img-fluid portal-logo">-->
<!-- <h2 class="h5">Nathan Andrews</h2><span>Web Developer</span>-->
<!--</div>-->
<!-- Small Brand information, appears on minimized sidebar-->
<!--<div class="sidenav-header-logo"><a href="index.html" class="brand-small text-center"><strong>AK</strong></a></div>
</div>-->
<!-- Sidebar Navigation Menus-->
<!--<div class="sidebar-content-menu">
            <app-sidebar></app-sidebar>
        </div>
    </div>
</ng-scrollbar>-->
<!-- Left Sidebar -->
<!-- ============================================================== -->
<app-sidebar></app-sidebar>
