import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AccessControl } from "src/app/models/AccessControl";
import { environment } from "src/environments/environment";
import { CommonService } from "../../../../_services/common.service";

@Component({
    selector: "app-view-access-control",
    templateUrl: "./view.component.html",
    styleUrls: ["./view.component.css"],
})
export class ViewAccessControlComponent implements OnInit {
    locationId: string;
    accessControl: AccessControl = new AccessControl();
    qrCodeData: any;
    constructor(
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        public commonService: CommonService
    ) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.locationId = params.locationId;
        });
    }

    ngOnInit(): void {
        if (this.locationId) {
            this.spinner.show();
            this.ds.getAccessControl(this.locationId).subscribe(
                (response: any) => {
                    this.spinner.hide();
                    if (response.status) {
                        this.accessControl = response.data;
                        this.qrCodeData = JSON.stringify({
                            db_name:
                                this.commonService.selectedCompanyDeatils
                                    .dbName || "asd",
                            portal: environment.domainName,
                            type: "attendance",
                            classID: this.accessControl._id,
                            className: this.accessControl.locationName,
                            label_type: "access-control",
                        });
                    } else {
                        this.toast.error(response.message);
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.toast.error(
                        "Oops, something went wrong. Please try again."
                    );
                }
            );
        }
    }

    getDays(days) {
        return days
            .map(function (item) {
                return item.label;
            })
            .join(", ");
    }
}
