import { Component, OnInit } from '@angular/core';
import { GeneralServiceService } from '../_services/general-service.service';
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import * as data from 'src/app/common/countryCode.json';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

    regEmail: string;
    regPhone: number;
    //regPhoneCountryCode: number;
    inputEmail: boolean;
    inputPhone: boolean;
    currentYear: number = new Date().getFullYear();
    regPhoneCountryCode: any = [];
    countryListCode: any = (data as any).default;
    submitBtn: boolean;

    imageurl: any;
    public portalLabel = "";
    public portal = null;
    public portalLogo = "";

    constructor(
        private akcessService: GeneralServiceService,
        private callservice: DataservicesService,
        private router: Router,
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.inputEmail = false;
        this.inputPhone = false;
        this.submitBtn = true;
    }

    saveEmail(event) {
        if (event.length > 0) {
            this.inputPhone = true;
            this.submitBtn = false;
        }
        else {
            this.inputPhone = false;
            this.submitBtn = true;
        }
    }

    savePhone(event) {
        if (event != null) {
            this.inputEmail = true;
            this.submitBtn = false;
        }
        else {
            this.inputEmail = false;
            this.submitBtn = true;
        }
    }

    registerNewCompany() {
        const countryCode = this.regPhoneCountryCode;
        let object = {
            email: this.regEmail,
            phone: countryCode + '' + this.regPhone,
            type: (!this.inputEmail) ? 'email' : 'phone'
        }
        this.callservice.registerNewCompany(object).subscribe((res: any) => {
            if (!this.inputEmail) {
                this.toastr.info('Email send successfully');
            }
            else {
                this.toastr.info('SMS sent');
            }
            this.router.navigate(['/'])
        }, err => {
            this.toastr.error('Something went wrong, Try again!')
        })
    }

}
