import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DataservicesService } from 'src/app/_services/dataservices.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { GeneralServiceService } from 'src/app/_services/general-service.service';
import { CommonService } from 'src/app/_services/common.service';

declare var $: any;

var dataOutput = new FormData();


@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
    public portal = null;
    public portalLabel = '';
    public portalContacts = '';
    public portalLogo = '';
    public updated_at = '';
    imageurl: any;
    portaldata: any;
    //loggedInName: string;

    akID: string;
    name: string;
    email: string;
    phone: string;
    joinDate: any;
    userType: string;



    portalUserData;
    constructor(public sanitizer: DomSanitizer,
        private spinner: NgxSpinnerService,
        private ds: DataservicesService,
        private toast: ToastrService,
        private gs: GeneralServiceService,
        public commonService: CommonService) { }

    ngOnInit() {
        this.portalUserData = JSON.parse(sessionStorage.getItem('portalUser'));

        this.akID = this.portalUserData.akcessId;
        this.name = this.portalUserData.firstName + ' ' + this.portalUserData.lastName;
        this.email = this.portalUserData.email;
        this.phone = this.portalUserData.phone;
        this.joinDate = this.portalUserData.createdon;
        this.userType = this.portalUserData.usertype;


    }




}
