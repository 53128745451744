
<!-- Modals -->
<!-- View Employee Modal -->
<div id="viewuser">
    <div>
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="viewuserModalLabel">View Details</h4>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <div class="row">
                    <label class="col-12 col-md-4 font">AKcess ID</label>
                    <div class="col-12 col-md-8">{{akcessId}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Firstname</label>
                    <div class="col-12 col-md-8">{{(firstName)?firstName:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Lastname</label>
                    <div class="col-12 col-md-8">{{(lastName)?lastName:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Email</label>
                    <div class="col-12 col-md-8">{{(email)?email:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Mobile</label>
                    <div class="col-12 col-md-8">{{(phone)?phone:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">Address</label>
                    <div class="col-12 col-md-8">{{(address)?address:'-'}}</div>
                </div>
                <div class="row">
                    <label class="col-12 col-md-4 font">User Type</label>
                    <div class="col-12 col-md-8">{{usertype}}</div>
                </div> -->


                <form>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="font-500">AKcess ID</label>
                            <input type="text" class="form-control"
                                [value]="akid" disabled>
                        </div>
                    </div>                                                 
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="font-500">First Name</label>
                            <input type="text" class="form-control"
                                [value]="fname" disabled>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="font-500">Last Name</label>
                            <input type="text" class="form-control"
                                [value]="lname" disabled>
                        </div>
                    </div>                                                 
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="font-500">Phone Number</label>
                            <input type="text" class="form-control"
                                [value]="phone" disabled>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="font-500">Email</label>
                            <input type="text" class="form-control"
                                [value]="email" disabled>
                        </div>
                    </div>                                                 
                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label class="font-500">User Status</label>
                            <input type="text" class="form-control"
                                [value]="status" disabled>
                        </div>
                        <div class="form-group col-md-6">
                            <label class="font-500">User Type</label>
                            <input type="text" class="form-control"
                                [value]="userType" disabled>
                        </div>
                    </div>                                                 
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-danger" (click)="close()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div> 

