import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
import * as moment from "moment";
//import * as _ from "lodash";
import * as _ from "lodash-es";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { values } from "pdf-lib";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/_services/common.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ViewCertificateComponent } from "../view-certificate/view-certificate.component";
import * as data from 'src/app/common/countryCode.json';
import { CreateCertificateComponent } from "./create-certificate/create-certificate.component";

@Component({
    selector: "app-certificate",
    templateUrl: "./certificate.component.html",
    styleUrls: ["./certificate.component.css"],
})
export class CertificateComponent implements OnInit {
    // adduseridcard: FormGroup;
    eformdata: any = [];
    uploadedFile: any = null;
    act: string;
    search_idcardName: string = "";
    eidcardresponse = [];
    AllEidcardsResponse = [];
    fields = [];
    idcardname: any;
    imageUrl: string = environment.imgurl;
    imageUrlsForPDF = [];
    portalUser;
    portaldata;
    pdfcolums = [{}];
    xls_fileName = "ExcelSheet.xlsx";
    pdf_fileName = "PDF.pdf";
    device_token: string;
    isSubmitted = false;
    idcardStatus: string = "";
    gradedata = [];
    eFormverifyCheck: any = [];

    verifiedItems: Array<String> = [];
    fieldVerifierData: any;
    userAkcessID: any;
    graderLoader: boolean = false;

    eidcardDataWithForExpiryDate: any;
    expiryDateIndex: any;
    addExpiryDate: any;

    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;
    certificateList: any = [];
    responseId: any;
    pullDataStatus: any = "";

    userwithChips: any = [];
    userwithChips_phone: any = [];
    userDatamodel: string;
    tmpeform: any = "";
    akcessIDChips: any = [];
    userData: any = [];
    userDataAKcessId: any = [];
    userDataEmail: any = [];
    userDataPhone: any = [];
    saveuserData: any = [];
    search_formName: string = "";
    eformsentsEmail = [];
    eformsentsPhone = [];
    eformsents = [];
    formname: string;

    phone_or_email: String = "";
    placeholder: String = "Enter email";

    certificateId: string;
    sendList: any = [];
    isLengthProper: boolean;

    selectedCountryCode: any = [];
    listPhone = [1];
    counter = 1;
    countryListCode: any = (data as any).default;

    //Modal
    bsModalRef: BsModalRef;

    // Status: any = [
    //   "Pending",
    //   "Accept",
    //   "Update Client Field",
    //   "Verify and Accept",
    //   "Return",
    //   "Reject",
    // ];
    StatusDescription: any = {
        alive: "Alive",
        pending: "Pending",
        createclient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        createClient:
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        Accept:
            " When you apply 'accept' action, user will receive a notification that you accepted his response. The eidcard response will be accepted without any verification",
        accept:
            " When you apply 'accept' action, user will receive a notification that you accepted his response. The eidcard response will be accepted without any verification",
        "update Client Field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        "update client field":
            "This action is only for portal. When you apply this action, the client's fields will be updated in clients section. If the client is not in your list, he will be added into the list on clients.",
        "verify and Accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        "verify and accept":
            "Check the fields you want to verify and click on submit. User will receive your verification and can add it to his profile.",
        Return:
            "When you choose to apply 'return' action, user will have to re-fill and re-submit the eidcard.",
        return:
            "When you choose to apply 'return' action, user will have to re-fill and re-submit the eidcard.",
        Reject:
            "When you apply 'reject' action, user will receive a notification that you rejected his response",
        reject:
            "When you apply 'reject' action, user will receive a notification that you rejected his response",
    };

    orderByFilter: any = "nto";

    //ng-select variable
    selectSubmitDisabled: boolean = false;

    constructor(
        private gs: GeneralServiceService,
        private router: Router,
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private spinner: NgxSpinnerService,
        public commonService: CommonService,
        public refreshContentService: RefreshContentService,
        public modalService: BsModalService
    ) { }
    // constructor(private idcardBuilder: FormBuilder,
    //   private messageService: MessageService,
    //   private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                this.getCertificateList();
                this.getserachuserData();
            } else {
                this.getCertificateList();
                this.getserachuserData();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
    }

    addTagFn(name) {
        return { name: name, tag: true };
    }

    pushAkcessData(e) {
        this.akcessIDChips.push(e.value.split(" ")[0]);
    }
    removeAkcessData(e) {
        let indexOfEmail = this.akcessIDChips.findIndex((e) => e == e.value);
        this.akcessIDChips.splice(indexOfEmail, 1);
    }
    // sendeIdCardByAkcessId() {
    //     let logo = this.commonService.portalDetails.portalDetails.portalLogo;
    //     var reqBody = {};

    //     reqBody['pdf_title'] = "Certificate"
    //     reqBody['pdf_url'] = this.tmpeform.file_url || "/test.pdf"
    //     reqBody['expiry_date'] = this.tmpeform.expiry_date
    //     reqBody['logo'] = logo
    //     reqBody['receivers'] = this.akcessIDChips[0]
    //     reqBody['userId'] = this.tmpeform['_id'];
    //     reqBody['AkcessID'] = sessionStorage.getItem("logedInUserAckessId");
    //     reqBody['fileName'] = this.tmpeform.file_name;
    //     reqBody['documentId'] = this.tmpeform.document_ID;
    //     reqBody['domainName'] = this.commonService.selectedCompanyDeatils.domain || "sme.akcess.app"
    //     reqBody['akcessToken'] = sessionStorage.getItem('token')
    //     reqBody['apiKey'] = environment.apiKey
    //     reqBody['isIdCard'] = false
    //     reqBody['isDocument'] = true

    //     this.gs.sendCertificateByAkcessId(reqBody).subscribe(data => {
    //         // $('#sendIdCardModelId').modal('hide');
    //         $('#sendIdCardAkcessIdModel').click();
    //         this.toast.success("Id card sent successfully");
    //         this.spinner.hide();
    //     },
    //         error => {
    //             this.toast.error(error.error.message);
    //             $('#sendIdCardAkcessIdModel').click();
    //             this.spinner.hide();
    //         }
    //     )

    // }
    //akcessIDChipsarray = [];

    addListPhone() {
        this.listPhone.push(++this.counter);
    }

    removeListPhone(x: number) {
        this.listPhone.splice(x, 1);
    }

    sendeCertificateByAkcessId(data) {


        if (this.akcessIDChips.length == 0) {
            this.toast.info('Enter user AKcess Id');
            return false;
        }
        this.isLengthProper = true;
        this.spinner.show();
        let akcessIDChipsarray = [];

        //this.akcessIDChips.forEach(element => {
        //    if (element && !element.name && element.split(' ')[0] != 'undefined' && element.split(' ')[0]) {
        //        akcessIDChipsarray.push(element.split(' ')[0]);
        //    } else if (element && element.name) {
        //        akcessIDChipsarray.push(element.name);
        //    }
        //});

        this.akcessIDChips.forEach(element => {
            if (element && !element.name && element.split(' ')[3] != 'undefined' && element.split(' ')[3]) {
                akcessIDChipsarray.push(element.split(' ')[3]);
            } else if (element && element.name) {
                akcessIDChipsarray.push(element.name);
            }
        });

        //this.akcessIDChips.forEach(element => {
        //    if (element && !element.name && element != 'undefined') {
        //        akcessIDChipsarray.push(element);
        //    } else if (element && element.name) {
        //        akcessIDChipsarray.push(element.name);
        //    }
        //});

        akcessIDChipsarray = akcessIDChipsarray.map(el => el.toUpperCase());

        let arrayLength = akcessIDChipsarray.map(function (id) {
            return id.length;
        })

        arrayLength.forEach(item => {
            if (item < 11 || item > 12) {
                this.isLengthProper = false;
                return;
            }
        });

        if (!this.isLengthProper) {
            this.toast.error("AKcess Id should be of 11 or 12 letters.");
            this.spinner.hide();
        }
        else {
            let obj = {
                akcessId: akcessIDChipsarray,
                Pdf_name: data.file_name,
                Description: "certificate",
                DocumentID: data._id,
                domainName: `${environment.domainName}`,
                companyName: this.commonService.selectedCompanyDeatils.domain,
                logo: this.commonService.selectedCompanyDeatils.logo,
                recievedType: 'certificate'
            };
            this.spinner.show();
            this.gs.sendCertificateByAkcessId(obj).subscribe(
                (data: any) => {
                    $('#emailsendCertificate').modal('hide');
                    //   $('#sendIdCardAkcessIdModel').click();
                    let result = data;
                    let successID = result.data.success.toString();
                    let failureID = result.data.failure.toString();
                    if (successID.length > 0) {
                        this.toast.success(result.message);
                    }
                    if (failureID.length > 0) {
                        this.toast.error("Failed to send certificate to " + failureID);
                    }
                    //this.toast.success(data["message"]);
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
        //this.spinner.hide();
        //akcessIDChipsarray = this.akcessIDChips.map((id) => id.substr(0, 12));
    }

    //phoneChipsarray = []
    sendeCertificateByPhone(data) {
        if (this.userDataPhone.length == 0) {
            this.toast.info('ENTER or SELECT mobile number');
            return false;
        }

        this.spinner.show();
        let phoneChipsarray = [];
        let phoneNumberList = [];
        const countryCode = this.selectedCountryCode;
        const userPhoneNumber = this.userwithChips_phone;




        //this.userwithChips_phone.forEach(element => {
        //    if (element && !element.name && element.split(' ')[2] != 'undefined' && element.split(' ')[2]) {
        //        phoneChipsarray.push(element.split(' ')[2])
        //    } else if (element && element.name) {
        //        phoneChipsarray.push(element.name)
        //    }
        //});

        //this.userwithChips_phone.forEach(element => {
        //    if (element && !element.name && element != 'undefined') {
        //        phoneChipsarray.push(element);
        //    } else if (element && element.name) {
        //        phoneChipsarray.push(element.name);
        //    }
        //});

        this.userwithChips_phone.forEach(element => {
            if (element && !element.name && element.split(' ')[3] != 'undefined' && element.split(' ')[3]) {
                phoneChipsarray.push(element.split(' ')[3]);
            } else if (element && element.name) {
                phoneChipsarray.push(element.name);
            }
        });


        let countryCodeList = phoneChipsarray.map(cc => cc.slice(0, -10));

        //Check if country code is less
        if (countryCodeList.findIndex(val => val === "") === -1) {

            //Getting the phone number
            if (countryCodeList.length === phoneChipsarray.length) {
                for (let i = 0; i < phoneChipsarray.length; i++) {
                    phoneNumberList.push({
                        countryCode: countryCodeList[i].split("+")[1],
                        phone: phoneChipsarray[i].replace(countryCodeList[i], "")
                    });
                }
            }

            //Country Code as Individual
            //const mapArrays = (countryCode, userPhoneNumber) => {
            //    const res = [];
            //    for (let i = 0; i < countryCode.length; i++) {
            //        res.push({
            //            countryCode: countryCode[i],
            //            phone: userPhoneNumber[i]
            //        });
            //    };
            //    return res;
            //};
            //phoneChipsarray = mapArrays(countryCode, userPhoneNumber);  

            let obj = {
                //contacts: phoneChipsarray,
                contacts: phoneNumberList,
                Pdf_name: data.file_name,
                Description: "certificate",
                //phone: this.userwithChips_phone,
                DocumentID: data._id,
                domainName: `${environment.domainName}`,
                companyName: this.commonService.selectedCompanyDeatils.domain,
                logo: this.commonService.selectedCompanyDeatils.logo,
            };

            this.spinner.show();
            this.gs.sendCertificateByphone(obj).subscribe(
                (data: any) => {
                    $('#emailsendCertificate').modal('hide');
                    let result = data;
                    let successID = result.data.success.toString();
                    let failureID = result.data.failure.toString();
                    if (successID.length > 0) {
                        this.toast.success(result.message);
                    }
                    if (failureID.length > 0) {
                        this.toast.error("Failed to send certificate to " + failureID);
                    }
                    //this.toast.success(data["message"]);
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        } else {
            this.toast.error("Please enter phone number with country code");
            this.spinner.hide();
        }
    }

    //emailChipsarray = []
    sendeCertificateByEmail(data) {

        if (this.userDataEmail.length == 0) {
            this.toast.info('ENTER or SELECT email');
            return false;
        }

        this.spinner.show();

        let emailChipsarray = [];


        //this.userwithChips.forEach(element => {
        //    if (element && !element.name && element.split(' ')[1] != 'undefined' && element.split(' ')[1]) {
        //        emailChipsarray.push(element.split(' ')[1])
        //    } else if (element && element.name) {
        //        emailChipsarray.push(element.name)
        //    }
        //});

        //this.userwithChips.forEach(element => {
        //    if (element && !element.name && element != 'undefined') {
        //        emailChipsarray.push(element);
        //    } else if (element && element.name) {
        //        emailChipsarray.push(element.name);
        //    }
        //});

        this.userwithChips.forEach(element => {
            if (element && !element.name && element.split(' ')[3] != 'undefined' && element.split(' ')[3]) {
                emailChipsarray.push(element.split(' ')[3]);
            } else if (element && element.name) {
                emailChipsarray.push(element.name);
            }
        });

        let obj = {
            email: emailChipsarray,
            Pdf_name: data.file_name,
            Description: "certificate",
            DocumentID: data._id,
            domainName: `${environment.domainName}`,
            companyName: this.commonService.selectedCompanyDeatils.domain,
            logo: this.commonService.selectedCompanyDeatils.logo,
        };
        this.spinner.show();
        this.gs.sendCertificateByemail(obj).subscribe(
            (data: any) => {
                $('#emailsendCertificate').modal('hide');
                // $("#sendIdCardAkcessIdModel").click();
                //this.toast.success(data["message"]);
                let result = data;
                let successID = result.data.success.toString();
                let failureID = result.data.failure.toString();
                if (successID.length > 0) {
                    this.toast.success(result.message);
                }
                if (failureID.length > 0) {
                    this.toast.error("Failed to send certificate to " + failureID);
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    getCertificateList() {
        // this.portalUser = JSON.parse(sessionStorage.getItem('portalUser'));
        // const tmpdata = {
        //   'akcessId': this.portalUser.akcessId,
        //   'search_idcardName': this.search_idcardName,
        //   'currentPage': this.currentPage,
        //   'limit': this.pageSize
        // };
        this.spinner.show();
        this.calldata.getAllCertificates().subscribe(
            (res: any) => {
                if (res.status) {
                    this.certificateList = res.data.reverse();
                    this.totalCount = this.certificateList.length;
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }
    view(val) {
        this.router.navigate([
            "/panel/view-certificate",
            JSON.stringify({ data: val }),
        ]);
    }
    /**
     * Send data to navigated page without showinhg it in url
     * @param data
     * @param path
     */

    //Add Certificate Modal
    createCertificate() {
        this.bsModalRef = this.modalService.show(CreateCertificateComponent, {
            animated: true,
            backdrop: "static",
            class: "modal-dialog-centered modal-dialog-scrollable modal-lg",
        });
    }
    viewCertificate(data) {
        const initialState: any = {
            selectedCertificate: data.file_url,
        };
        this.bsModalRef = this.modalService.show(ViewCertificateComponent, {
            initialState,
            animated: true,
            backdrop: "static",
            class: "modal-dialog-centered modal-dialog-scrollable modal-lg",
        });
    }
    onProjectPageChange(event) {
        this.currentPage = event;
        this.getCertificateList();
    }

    changePage(value) {
        this.pageSize = parseInt(value);
        this.getCertificateList();
    }

    deleteIdCard(idNo) {
        this.calldata.deleteIdCard(idNo).subscribe(
            (data) => {
                if (data) {
                    this.toast.success("Id card deleted");
                    this.getCertificateList();
                    this.spinner.hide();
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //------------------------starts----------------------------------------------
    eformData: any;
    openEformEmail(data) {
        this.eformData = "";
        this.eformData = data;
        this.userDatamodel = "";
        this.tmpeform = data;
    }

    //openSendEform(val) {
    //    this.userDatamodel = '';
    //    this.tmpeform = val;
    //}

    pusheFormData(e) {
        let data = e.value;
        let str = data.split(" ")[0];
        let checkEmailCustom = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,8})+$/.test(
            str
        );

        if (checkEmailCustom) {
            e.value = `New ${e.value} No Phone`;
        }

        let checkEmail = data.split(" ")[1] ? data.split(" ")[1] : "No";
        if (checkEmail === "No" && !checkEmailCustom) {
            this.toast.error("Can't add into list");
        } else {
            this.userwithChips.push(e.value);
            data = "";
            e.value = "";
        }
    }

    removeeFormData(e) {
        if (e.display.split(" ")[1]) {
        } else {
            e.display = `New ${e.display} No Phone`;
        }
        let indexOfEmail = this.userwithChips.findIndex((ele) => ele == e.display);
        this.userwithChips.splice(indexOfEmail, 1);
    }

    pusheFormData_phone(e) {
        let data = e.value;
        let phone = data.split(" ")[0];
        var regExp = /^\+?\d{10,15}(,\+?\d{10,15})*$/g;

        let checkEmailCustom = regExp.test(phone);

        if (checkEmailCustom) {
            e.value = `New ${e.value} No Phone`;
        }

        let checkEmail = data.split(" ")[1] ? data.split(" ")[1] : "No";
        if (checkEmail === "No" && !checkEmailCustom) {
            this.toast.error("Can't add into list");
        } else {
            this.userwithChips_phone.push(e.value);
            data = "";
            e.value = "";
        }
    }

    removeeFormData_phone(e) {
        if (e.display.split(" ")[1]) {
        } else {
            e.display = `New ${e.display} No Phone`;
        }
        let indexOfEmail = this.userwithChips_phone.findIndex(
            (ele) => ele == e.display
        );
        this.userwithChips_phone.splice(indexOfEmail, 1);
    }

    // onSubmit() {
    //     alert('working');
    // }

    //sendEformEmail() {
    //    let emailList = [];

    //    this.userwithChips.forEach((element) => {
    //        let emailOnly = element.split(" ")[1];
    //        emailList.push(emailOnly);
    //    });

    //    var reqBody = {};
    //    reqBody["pdf_title"] = "Id Card";
    //    reqBody["pdf_url"] = this.eformData.fileUrl || "/test.pdf";
    //    reqBody["expiry_date"] = this.eformData.idCardExpiyDate;
    //    reqBody["logo"] = "logo.png";
    //    reqBody["emails"] = emailList;
    //    reqBody["userId"] = this.eformData["_id"];

    //    this.gs.sendIdCardByEmail(reqBody).subscribe(
    //        (data) => {
    //            $("#sendIdCardModelId").click();
    //            this.toast.success("Id card sent successfully");
    //            this.spinner.hide();
    //        },
    //        (error) => {
    //            this.toast.error(error.error.message);
    //            this.spinner.hide();
    //        }
    //    );
    //}

    //viewSentIdCards(userId, testa, tests) {
    //  this.gs.getSentIdCards(userId).subscribe(
    //    (data) => {
    //      if (data["status"] === 1) {
    //        this.eformsentsEmail = [];
    //        this.eformsentsPhone = [];
    //        this.eformsentsEmail = _.filter(data["data"], { isEmail: true });
    //        this.eformsentsPhone = _.filter(data["data"], { isPhone: true });
    //        this.spinner.hide();
    //      } else {
    //        this.toast.info(data["msg"]);
    //        this.spinner.hide();
    //      }
    //    },
    //    (error) => {
    //      this.toast.error("something went wrong");
    //      this.spinner.hide();
    //    }
    //  );
    //}

    viewSentIdCards(cerId) {
        this.certificateId = cerId;
        this.getSentList('akcessid');
    }

    isSelect: any = "akcessid";

    onSelect(type) {
        this.isSelect = type
        this.getSentList(type);
    }

    getSentList(type) {
        const obj = {
            senderType: type,
            DocumentID: this.certificateId
        };

        this.gs.getViewSendCertificate(obj).subscribe(
            (res: any) => {
                if (res.status) {
                    this.sendList = res.data.reverse();
                    this.spinner.hide();
                } else {
                    this.toast.info(res.message);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //  getSentList(type) {
    //    const obj = {
    //        senderType: type,
    //        DocumentID: this.cardId
    //    };

    //    this.akcessService.getViewSendIdCard(obj).subscribe(
    //        (res: any) => {
    //            if (res.status) {
    //                this.sendList = res.data.reverse();
    //                this.spinner.hide();
    //            } else {
    //                this.toast.info(res.message);
    //                this.spinner.hide();
    //            }
    //        },
    //        (error) => {
    //            this.toast.error("Server did not respond");
    //            //this.spinner.hide();
    //        }
    //    );
    //}

    //sendEform_phone() {
    //    let phoneList = [];
    //    this.userwithChips_phone.forEach((element) => {
    //        let phoneOnly = element.split(" ")[2];
    //        phoneList.push(phoneOnly);
    //    });

    //    var reqBody = {};
    //    reqBody["pdf_title"] = "Id Card";
    //    reqBody["pdf_url"] = this.eformData.fileUrl || "/test.pdf";
    //    reqBody["expiry_date"] = this.eformData.idCardExpiyDate;
    //    reqBody["logo"] = "logo.png";
    //    reqBody["phones"] = phoneList;
    //    reqBody["userId"] = this.eformData["_id"];

    //    this.gs.sendIdCardByPhone(reqBody).subscribe(
    //        (data) => {
    //            $("#sendIdCardModelId").click();
    //            this.toast.success("Certificate sent successfully");
    //            this.spinner.hide();
    //        },
    //        (error) => {
    //            this.toast.error(error.error.message);
    //            this.spinner.hide();
    //        }
    //    );
    //}

    //----------function imported from efoem---------------------
    // getserachuserData() {
    //   this.calldata.getuserlist_search().subscribe(
    //     data => {
    //       this.saveuserData = data['data'];
    //       data['data'].forEach(val => {
    //         var email = (val['email'] == 'undefined' || val['email'] == '' || val['email'] == null) ? 'No Email' : val['email'];
    //         var phone = (val['phone'] == 'undefined' || val['phone'] == null || val['phone'] == '') ? 'No Phone' : val['phone'];

    //         this.userData.push(val['akcessId'] + ' ' + email + ' ' + phone);
    //       });
    //     },
    //     error => {
    //     });
    // }

    getserachuserData() {
        this.calldata.getuserlist_search().subscribe(
            (res: any) => {
                if (res.status) {
                    this.saveuserData = res.data;

                    this.saveuserData.forEach((val: any) => {

                        var name = val["firstName"] + " " + val["lastName"];

                        var email =
                            val["email"] == "undefined" ||
                                val["email"] == "" ||
                                val["email"] == null
                                ? "No-Email"
                                : val["email"];
                        var phone =
                            val["phone"] == "undefined" ||
                                val["phone"] == null ||
                                val["phone"] == ""
                                ? "No-Phone"
                                : val["phone"];

                        var akcessId = (val["akcessId"]) ? val["akcessId"] : 'undefined'

                        this.userData.push(akcessId + " " + email + " " + phone);
                        this.userDataAKcessId.push(name + " - " + akcessId);
                        this.userDataEmail.push(name + " - " + email);
                        this.userDataPhone.push(name + " - +" + val.countryCode + phone);
                    });
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    geteformData() {
        this.spinner.show();
        this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
        const tmpdata = {
            akcessId: this.portalUser.akcessId,
            search_formName: this.search_formName,
            currentPage: this.currentPage,
            limit: this.pageSize,
        };
        this.calldata.geteformdata(tmpdata).subscribe(
            (data) => {
                if (data["status"] === 1) {
                    this.eformdata = [];
                    this.eformdata = data["results"];
                    this.totalCount = data["totalCount"];
                    this.spinner.hide();
                } else {
                    this.toast.info(data["message"]);
                    this.spinner.hide();
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }
    //-------------------------ends------------------------------------------------

    //Delete Rows
    deleteRow() {
        this.spinner.show();
        let deleteIDs = [];
        document
            .querySelector("table tbody")
            .querySelectorAll('tr input[type="checkbox"]:checked')
            .forEach((item) => deleteIDs.push(item.getAttribute("id")));

        if (deleteIDs.length > 0) {
            for (let id of deleteIDs) {
                const data = {
                    component: "certificate",
                    _id: id
                };
                this.calldata.softDelete(data).subscribe(
                    (res: any) => {
                        if (res.status) {
                            this.getCertificateList();
                            //this.toast.success(res.message);
                            this.toast.success("Certificate " + res.message);
                            this.spinner.hide();
                        } else {
                            this.toast.info(res.message);
                            this.spinner.hide();
                        }
                    }, (err) => {
                        this.spinner.hide();
                        this.toast.error("Oops, something went wrong. Please try again.")
                    }
                );
            }
        }
        else {
            this.toast.error("Select a certificate");
            this.spinner.hide();
        }


    }

    viewRecycle() {
        this.router.navigate(["/panel/certificate/deleted"]);
    }

    //ng-select
    onOpenDropdown() {
        this.selectSubmitDisabled = true;
    }

    onCloseDropdown() {
        this.selectSubmitDisabled = false;
    }

    close() {
        this.isSelect = "akcessid";
    }
}
