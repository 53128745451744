<div class="property-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Property</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="input-group">
                                <input type="search" [(ngModel)]="searchName" (search)="
                                        searchProperty(
                                            'search',
                                            $event.target.value
                                        )
                                    " class="form-control" id="search" placeholder="Search" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-9 col-xl-9 text-right mt-1 mt-md-0">
                            <a type="button" class="btn waves-effect waves-light btn-info add-btn"
                                [routerLink]="['/panel/property/add']">
                                <i class="fas fa-plus-circle" tooltip="Create New Property" placement="bottom"
                                    container="body"></i>
                                Add Property
                            </a>
                        </div>
                    </div>
                    <div class="action-tab">
                        <button type="button" class="mx-1 btn" (click)="deleteRows()" tooltip="Delete" container="body">
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button type="button" class="mx-1 btn" (click)="viewRecycle()" tooltip="Recycle Bin"
                            container="body">
                            <i class="fal fa-recycle"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table class="table datatable-table color-bordered-table info-bordered-table" mdbTable
                            small="true" hover="true">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="form-check">
                                            <input class="datatable-row-checkbox form-check-input" type="checkbox"
                                                (change)="checkUncheckAll()" [(ngModel)]="isMasterSel" />
                                        </div>
                                    </th>
                                    <th>Photo</th>
                                    <th [mdbTableSort]="propertyList" sortBy="name">
                                        Property Name / Address
                                        <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th>Description</th>
                                    <th [mdbTableSort]="propertyList" sortBy="price">
                                        <i class="fal fa-pound-sign"></i> Price
                                        <span><mdb-icon icon="sort" class="fas"><i
                                                    class="fa-sort fas"></i></mdb-icon></span>
                                    </th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="propertyList?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No Property Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="propertyList?.length > 0">
                                <tr [id]="property?._id" *ngFor="
                                        let property of propertyList
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    ">
                                    <td>
                                        <div class="form-check">
                                            <input class="datatable-row-checkbox form-check-input" type="checkbox"
                                                [(ngModel)]="
                                                    property.isSelected
                                                " [attr.id]="property?._id" value="property?._id"
                                                (change)="isAllSelected()" />
                                        </div>
                                    </td>
                                    <td [routerLink]="[
                                    '/panel/property/' +
                                        property._id +
                                        '/view'
                                ]" style="cursor: pointer;">
                                        <img *ngIf="property.photo" src="{{
                                                getFullURL(property.photo)
                                            }}" class="img-fluid" alt="{{ property.name }}" title="{{ property.name }}"
                                            width="100" />
                                    </td>
                                    <td [routerLink]="[
                                    '/panel/property/' +
                                        property._id +
                                        '/view'
                                ]" style="cursor: pointer;">
                                        {{ property.name }}
                                    </td>
                                    <td>
                                        <span class="text-truncate d-inline-block" style="max-width: 250px;">{{ property.description }}</span>
                                    </td>
                                    <td>
                                        <i class="fal fa-pound-sign"></i>
                                        {{
                                        property.price
                                        | number : "1.0" : "en-US"
                                        }}
                                    </td>
                                    <td>
                                        <div class="actions-div">
                                            <a class="btn btn-info btn-vinquiry btn-sm" [routerLink]="[
                                                    '/panel/property/inquiry/' +
                                                        property._id
                                                ]" data-toggle="tooltip" title="View Enquiries"
                                                data-original-title="View Enquiries"><i class="fab fa-wpforms"
                                                    aria-hidden="true"></i>
                                                View Enquiries</a>
                                            <a [routerLink]="[
                                                    '/panel/property/' +
                                                        property._id +
                                                        '/view'
                                                ]" data-toggle="tooltip" title="View Property"
                                                data-original-title="View Property"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a>

                                            <a [routerLink]="[
                                                    '/panel/property/' +
                                                        property._id +
                                                        '/edit'
                                                ]" data-toggle="tooltip" title="Edit Property"
                                                data-original-title="Edit Property"><i class="fa fa-edit green-txt"
                                                    aria-hidden="true"></i></a>
                                            <button (click)="
                                                    deleteSingleRows(
                                                        property._id
                                                    )
                                                " title="Remove Property" class="btn btn-link delete_btn">
                                                <i class="fa fa-trash red-txt" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div class="table-page" *ngIf="propertyList?.length > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    " class="my-pagination" directionLinks="true"
                                                    previousLabel="Previous" nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="
                                                        changePage(val.value)
                                                    " class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="
                                                            let pG of numberOfPage
                                                        " value="{{ pG }}">
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-confirm-dialog></app-confirm-dialog>