<div class="account-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Account</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                <li class="breadcrumb-item active">Account</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <tabset [justified]="true">
                        <tab heading="Account" id="accountsTab">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 col-lg-6 form-group">
                                            <ckeditor id="ckeditor" class="ckeditor" [editor]="Editor"
                                                      [(ngModel)]="description" [config]="editorConfig"
                                                      placeholder="Type the content here!">
                                            </ckeditor>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-6 form-group">
                                            <!-- <div class="row" [innerHTML]="description"
                                                style="min-height: 400px; width: 900px; border-radius: 10px; border: 0.5px solid #a1a1a1 !important; padding: 5px;">
                                            </div> -->
                                            <ckeditor class="ckeditor display-ck-output" [editor]="Editor" [(ngModel)]="description" disabled=true
                                                      [config]="editModeEditorConfig">
                                            </ckeditor>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="font-500">Updated :</label> {{updateddate | date:'medium'}}
                                    </div>
                                    <div>
                                        <button type="button" (click)="saveData()" class="btn waves-effect waves-light btn-info">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab heading="Live Users" id="liveUsers">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <div class="table-responsive mb-1">
                                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" hover="true" small="true">
                                            <thead>
                                                <tr>
                                                    <th [mdbTableSort]="userList" sortBy="firstName">S No <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>
                                                    <th [mdbTableSort]="userList" sortBy="email">Name <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>
                                                    <th [mdbTableSort]="userList" sortBy="phone">Email <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>
                                                    <th [mdbTableSort]="userList" sortBy="usertype">Mobile <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>
                                                    <th [mdbTableSort]="userList" sortBy="createdon">Status <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="userList?.length == 0">
                                                <tr>
                                                    <td colspan="5" class="text-center">No Clients Found</td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="userList?.length > 0">
                                                <tr *ngFor="let data of userList;let i = index;">
                                                    <td>{{i+1}}</td>
                                                    <td>
                                                        {{data.firstName}} {{data.lastName}}
                                                    </td>
                                                    <td>
                                                        {{data?.email}}
                                                    </td>
                                                    <td>
                                                        {{data?.mobile}}
                                                    </td>
                                                    <td class="paint-{{data.active_flg == true ? 'green':'red'}}">
                                                        <span>{{(data.active_flg)?'Active':'InActive'}}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="w-100">
                                                <tr>
                                                    <td colspan="5">
                                                        <!--<div class="table-page" *ngIf="userList?.length > 10">
                                                            <div class="page-controls">
                                                                <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                                                     class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                                                     nextLabel="Next"></pagination-controls>
                                                            </div>
                                                            <div class="page-size">
                                                                <label class="mb-0 mr-2">Item per page</label>
                                                                <select #val (change)="changePage(val.value)" class="form-control form-control-sm"
                                                                        id="pageSelector">
                                                                    <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                                                </select>
                                                            </div>
                                                        </div>-->
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </section>
</div>