import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RefreshContentService {

    refreshTableFunction = new EventEmitter();
    subsVar: Subscription;

    private notifyRefreshContent: BehaviorSubject<Object> = new BehaviorSubject<any>(
        null
    );
    notifyToAll = this.notifyRefreshContent.asObservable();
    private notifyTitle: BehaviorSubject<Object> = new BehaviorSubject<any>(
        null
    );
    notify = this.notifyTitle.asObservable();

    private portalData: BehaviorSubject<Object> = new BehaviorSubject<any>(
        null
    );
    getPortalData = this.portalData.asObservable();

    constructor() { }

    notiFyToAllChildComponents(notification) {
        this.notifyRefreshContent.next(notification);
    }

    setPortalData(portalData) {
        this.portalData.next(portalData);
    }

    notiFyToNavbar(title) {
        this.notifyTitle.next(title);
    }

    resetAll() {
        this.notifyRefreshContent.next(null)
    }

    //refreshEformTable
    onEformResponseStatusUpdate() {
        this.refreshTableFunction.emit();
    }

    resendInvitation() {
        this.refreshTableFunction.emit()
    }

}