import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PDFProgressData } from "ng2-pdf-viewer";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { GeneralServiceService } from "../../../../_services/general-service.service";
import { DataservicesService } from "src/app/_services/dataservices.service";

@Component({
    selector: "app-edit-document",
    templateUrl: "./edit-document.component.html",
    styleUrls: ["./edit-document.component.css"],
})
export class DocumentEditComponent implements OnInit {
    docId: string;
    editDocumentForm: FormGroup;
    fileUrl: "assets/doc/sample.pdf";
    documentId: "";

    constructor(
        private akcessService: GeneralServiceService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        public callData: DataservicesService
    ) {
        this.documentId = this.router.getCurrentNavigation().extras.state.data.documentId;
        this.fileUrl = this.router.getCurrentNavigation().extras.state.data.fileUrl;
    }

    ngOnInit(): void {
        this.editDocumentForm = this.fb.group({
            tags: [""],
        });
        this.spinner.show();
        this.getSignature();
    }

    onProgress(progressData: PDFProgressData) {
        this.spinner.hide();
    }

    signatures: any = [];

    getSignature() {
        this.spinner.show();
        let data = {
            documentId: this.documentId
        }
        this.callData.getALLStoreSignature(data).subscribe((res: any) => {
            if (res.status) {
                this.signatures = res.data.docs;
                this.spinner.hide()
            } else {
                this.spinner.hide()
                this.toast.error(res.message);
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }
}
