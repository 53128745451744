import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import * as moment from "moment";

@Component({
    selector: 'app-eformresponse-expiry-date',
    templateUrl: './eformresponse-expiry-date.component.html',
    styleUrls: ['./eformresponse-expiry-date.component.css']
})
export class EformresponseExpiryDateComponent implements OnInit {

    index: number;
    fields = [];
    expiryDateIndex: any;
    addExpiryDate: any;
    eformDataWithForExpiryDate: any;

    minDate: Date;

    constructor(
        private _bsModalRef: BsModalRef,
    ) { }

    ngOnInit(): void {
        this.minDate = new Date();
        this.fetchExpData();
    }

    fetchExpData() {
        this.expiryDateIndex = null;
        this.expiryDateIndex = this.index;
        this.eformDataWithForExpiryDate = {};
        this.eformDataWithForExpiryDate = this.fields[this.index];
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    pushExpiryDateInFiedls() {
        this.fields[this.expiryDateIndex].expiryDate = moment(this.addExpiryDate).format()
        this.addExpiryDate = "";
        this.closeModal();
    }
}
