import { Component, OnInit,Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.css']
})
    export class IframeModalComponent implements OnInit {

      @Input() url: string = 'https://credas.freshdesk.com/support/solutions/44000817962';
      isVisible: boolean = true;

      closeIframe() {
        this.isVisible = false;
        this.router.navigate(['/panel/verifications']);
      }

      constructor(private router: Router) { }

      ngOnInit(): void {
        if (!this.url) {
          this.url = 'https://credas.freshdesk.com/support/solutions/44000817962'; // Fallback to facebook.com if no URL provided
        }
      }
      

    }
