<!-- Logs Page -->
<div class="logs-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Logs</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-4 col-lg-4">
                            <div class="input-group">
                                <input
                                    type="text"
                                    [(ngModel)]="search_text"
                                    class="form-control"
                                    id="search"
                                    placeholder="Search Logs"
                                />
                                <div class="input-group-append">
                                    <div
                                        class="input-group-text"
                                        (click)="getLogs()"
                                    >
                                        <i class="fas fa-search"></i>
                                    </div>
                                    <div
                                        class="input-group-text"
                                        (click)="
                                            this.search_text = ''; getLogs()
                                        "
                                    >
                                        <i class="fal fa-times-circle"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 col-lg-4">
                            <input
                                type="text"
                                class="form-control"
                                name="from_date"
                                placeholder="Select date range"
                                bsDaterangepicker
                                [bsConfig]="{
                                    rangeInputFormat: 'DD-MM-YYYY',
                                    dateInputFormat: 'DD-MM-YYYY',
                                    containerClass: 'theme-red',
                                    isAnimated: true,
                                    adaptivePosition: true
                                }"
                            />
                        </div>
                        <div
                            class="col-12 col-md-4 col-lg-4 text-right mt-1 mt-md-0"
                        >
                            <button
                                type="button"
                                class="btn waves-effect waves-light btn-info add-btn"
                                (click)="exportexcel('user_logs')"
                                tooltip="Export Logs"
                                placement="bottom"
                                container="body"
                            >
                                <i class="fas fa-file-export"></i>
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive mb-1">
                        <table
                            id="user_logs"
                            class="table color-bordered-table info-bordered-table"
                            mdbTable
                            #tableEl="mdbTable"
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th
                                        [mdbTableSort]="loglist"
                                        sortBy="headers.logedinuserackessid"
                                    >
                                        AKcess ID<span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th
                                        [mdbTableSort]="loglist"
                                        sortBy="route.path"
                                    >
                                        Base Route<span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th
                                        [mdbTableSort]="loglist"
                                        sortBy="route.method"
                                    >
                                        Method
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th
                                        [mdbTableSort]="loglist"
                                        sortBy="response.code"
                                    >
                                        Status Code
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th
                                        [mdbTableSort]="loglist"
                                        sortBy="createdAt"
                                    >
                                        Created Date
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <!-- chnsage -->
                                </tr>
                            </thead>
                            <tbody *ngIf="loglist?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No Logs Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="loglist?.length > 0">
                                <tr
                                    [id]="data?._id"
                                    *ngFor="
                                        let data of loglist
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                    (click)="openLogDetailModal(template, data)"
                                >
                                    <td>
                                        {{ data.headers.logedinuserackessid }}
                                    </td>
                                    <td>{{ data.route.path }}</td>
                                    <td>{{ data.route.method }}</td>
                                    <td>{{ data.response.code }}</td>
                                    <td>
                                        {{ data.createdAt | date : "medium" }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div
                                            class="table-page"
                                            *ngIf="loglist?.length > 0"
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Log Details</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="hideModal()"
        >
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <code>
            <pre>{{ modalService.config.initialState | json }}</pre>
        </code>
    </div>
</ng-template>
