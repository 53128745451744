import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-eformresponse-download',
    templateUrl: './eformresponse-download.component.html',
    styleUrls: ['./eformresponse-download.component.css']
})
export class EformresponseDownloadComponent implements OnInit {

    excel_data: any = [];
    googleSignInLink: any = "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/spreadsheets%20https://www.googleapis.com/auth/drive.file%20https://www.googleapis.com/auth/drive&include_granted_scopes=true&response_type=token&state=state_parameter_passthrough_value&redirect_uri=http://localhost:4200&client_id=825330757877-t7gqka9mt8cff0lnr0duadb08vj8lo1j.apps.googleusercontent.com"
    headersValue = []
    constructor(
        private _bsModalRef: BsModalRef,
    ) { }

    ngOnInit(): void {
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    cast_excel_label(label: string): string {
        let labels = {
            eformId: 'Form ID',
            _id: 'ResponseID',
            formName: 'Form Name',
            akcessId: 'AKcessID',
            firstName: 'FirstName',
            lastName: 'LastName',
            email: 'Email',
            phone: 'Mobile',
            updatedAt: 'SubmitDateTime'
        }

        return labels[label] || label
    }

    get_excel_header(): string[] {
        let headers = []
        if (!this.excel_data.length)
            return headers

        this.excel_data.forEach(obj => {
            headers = headers.concat(Object.keys(obj))
        })
        this.headersValue = [...new Set(headers)]
        return [...new Set(headers)]
    }

    get_excel_value(data: any): string[] {
        return Object.values(data)
    }

    exportTableToExcel() {
        let headerField = this.get_excel_header();
        let options = {
            showLabels: true,
            headers: headerField,
            removeEmptyValues: true
        }
        new ngxCsv(this.excel_data, 'received-eforms', options);

        this.closeModal();
    }

    update_excel_data(data: any, status): void {
        // add default haeder available in each rows
        let headers = ['eformId', '_id', 'formName', 'akcessId', 'firstName', 'lastName', 'email', 'phone', 'updatedAt']

        // add other headers as per previous excel data available
        if (this.excel_data.length)
            headers = headers.concat(Object.keys(this.excel_data[0]))

        // add new header fields given in data
        data.fields.forEach(item => headers.push(item.labelname))

        // remove duplicate headers
        headers = [...new Set(headers)]

        if (status) {

            // cast headers into object with null value
            let obj = headers.reduce((obj, item) => {
                obj[item] = null
                return obj
            }, {})

            // replace fields value into obj
            for (let field of data.fields)
                obj[field.labelname] = field.value

            // replace other valus excluding fields
            for (let key in data)
                if (obj.hasOwnProperty(key))
                    obj[key] = data[key]
            this.excel_data.push(obj)

            return
        }

        // find records by id and remove from excel-data
        for (let index in this.excel_data) {
            if (data._id === this.excel_data[parseInt(index)]._id)
                this.excel_data.splice(parseInt(index), 1)
        }
    }


    colspan(): void {
        let size = document.getElementById('excel-table').querySelectorAll(`thead tr th`).length

        for (let i = size; i > 0; i--) {
            let selected_cell: any = null

            document.getElementById('excel-table').querySelectorAll(`tbody tr td:nth-child(${i})`).forEach(html => {
                if (html.innerHTML) {
                    if (!selected_cell) {
                        selected_cell = html
                    } else {
                        if (html.innerHTML == selected_cell.innerHTML) {
                            let rowspan = parseInt(selected_cell.getAttribute('rowspan') || 1)
                            selected_cell.setAttribute('rowspan', rowspan + 1)
                            html.remove()
                        } else {
                            selected_cell = html
                        }
                    }
                }
            })
        }
    }
}
