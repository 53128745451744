<div class="document-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-5 align-self-center">
            <h3 class="text-themecolor">Documents</h3>
        </div>
        <div class="col-md-7 align-self-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0)" (click)="reset()">Home</a>
                </li>
                <li class="breadcrumb-item active">
                    <a href="javascript:void(0)" (click)="redirect()">Documents</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="isShowDocumentTable">
                    {{ docNameForNav }}
                </li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-3" *ngIf="isShowDocumentTable">
                            <div>
                                <input type="text" (change)="
                                        searchDocument($event.target.value)
                                    " class="form-control" id="search" placeholder="Search By Document" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-group">
                                <div ngbDropdown autoClose class="d-inline-block">
                                    <button type="button" class="btn-sorting" id="dropdownBasic1" ngbDropdownToggle>
                                        Sort by
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <p class="heading">SORT BY</p>
                                        <a ngbDropdownItem>Name</a>
                                        <a ngbDropdownItem>Date</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 text-right">
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn mr-3"
                                (click)="openModel('create folder','')" tooltip="Create Folder" placement="bottom"
                                container="body">
                                <i class="fa fa-folder" aria-hidden="true" style="font-size: 18px"></i>
                            </button>
                            <button type="button" class="btn waves-effect waves-light btn-info add-btn"
                                (click)="openModel('upload document','')" tooltip="Upload Document" placement="bottom"
                                container="body">
                                <i class="fas fa-file-upload" style="font-size: 18px"></i>
                            </button>
                        </div>
                    </div>
                    <div class="action-tab" *ngIf="isShowDocumentTable">
                        <button type="button" class="mx-1 btn" container="body" (click)="redirect()">
                            <i class="fas fa-arrow-left"></i>
                        </button>
                        <button type="button" class="mx-1 btn" (click)="deleteRow()" tooltip="Delete" container="body">
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button type="button" class="mx-1 btn" (click)="viewRecycle()" tooltip="Recycle Bin"
                            container="body">
                            <i class="fal fa-recycle"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table *ngIf="!isShowDocumentTable" border="2"
                            class="table color-bordered-table info-bordered-table text-center" mdbTable
                            #tableEl="mdbTable" small="true" hover="true">
                            <thead>
                                <tr>
                                    <th rowspan="2"></th>
                                    <th rowspan="2" class="header">Name</th>
                                    <th rowspan="2" class="header">Date</th>
                                    <th colspan="3" class="text-center header">
                                        Number Of Document
                                    </th>
                                    <th rowspan="2" class="header">Action</th>
                                </tr>
                                <tr class="header">
                                    <th class="header">With Signature</th>
                                    <th class="header">Without Signature</th>
                                    <th class="header">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let folder of folderList" style="cursor: pointer">
                                    <td (click)="
                                            getIndividualFolderDetail(folder)
                                        ">
                                        <i class="fa fa-folder folder-grey"></i>
                                    </td>
                                    <td (click)="
                                            getIndividualFolderDetail(folder)
                                        ">
                                        {{ folder.name }}
                                    </td>
                                    <td (click)="
                                            getIndividualFolderDetail(folder)
                                        ">
                                        {{ folder.createdAt | date }}
                                    </td>
                                    <td>
                                        {{
                                        folder.withSignature
                                        ? folder.withSignature
                                        : 0
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        folder.withoutSignature
                                        ? folder.withoutSignature
                                        : 0
                                        }}
                                    </td>
                                    <td>
                                        {{ folder.total ? folder.total : 0 }}
                                    </td>
                                    <td>
                                        <i class="fas fa-trash mr-3" (click)="deleteFolder(folder)"></i>
                                        <i class="fas fa-edit" (click)="editFolder(folder)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable"
                            small="true" hover="true" *ngIf="isShowDocumentTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th [mdbTableSort]="documents" sortBy="origional_name">
                                        Document Name
                                        <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span>
                                    </th>
                                    <th [mdbTableSort]="documents" sortBy="file_size">
                                        Document Size
                                        <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span>
                                    </th>
                                    <th [mdbTableSort]="documents" sortBy="createdAt">
                                        Created Date
                                        <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span>
                                    </th>
                                    <th [mdbTableSort]="documents" sortBy="expiryDate">
                                        Expiry Date
                                        <span>
                                            <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                        </span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="documents?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No Documents Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="documents?.length > 0">
                                <tr [id]="data?._id" *ngFor="
                                        let data of documents
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    ">
                                    <td>
                                        <input class="" type="checkbox" [attr.id]="data?._id" />
                                    </td>
                                    <td>
                                        <a class="d-inline-block" size (click)="editDocument(data)">
                                            {{ data.origional_name }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="editDocument(data)">
                                            {{ data.file_size }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="editDocument(data)">
                                            {{
                                            data.createdAt | date : "medium"
                                            }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="d-inline-block" (click)="editDocument(data)">
                                            {{
                                            data.expiryDate
                                            | date : "medium"
                                            }}
                                        </a>
                                    </td>
                                    <td>
                                        <a class="mx-2 btn btn-sm waves-effect waves-light btn-info w-auto"
                                            (click)="openModel('edit document',data)">
                                            <i class="fa fa-edit text-white" aria-hidden="true"></i>
                                            <span style="color: white">Edit Document</span>
                                        </a>
                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="openSendModal(data)">
                                            Send Document
                                        </button>
                                        <button type="button" class="mx-2 btn btn-sm waves-effect waves-light btn-info"
                                            (click)="
                                                openViewSendModal(
                                                    data.document_ID
                                                )
                                            ">
                                            View Sent
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div class="table-page" *ngIf="documents?.length > 10">
                                            <div class="page-controls">
                                                <pagination-controls (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    " class="my-pagination" directionLinks="true"
                                                    previousLabel="Previous" nextLabel="Next"></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2">Item per page</label>
                                                <select #val (change)="
                                                        changePage(val.value)
                                                    " class="form-control form-control-sm" id="pageSelector">
                                                    <option *ngFor="
                                                            let pG of numberOfPage
                                                        " value="{{ pG }}">
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>