import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { RefreshContentService } from "../../../../_services/refresh-content.service";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/_services/common.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { environment } from "src/environments/environment.prod";
import { NgxSpinnerService } from "ngx-spinner";
import * as data from "src/app/common/countryCode.json";

@Component({
    selector: "app-send-id-card",
    templateUrl: "./send-id-card.component.html",
    styleUrls: ["./send-id-card.component.css"],
})
export class SendIdCardComponent implements OnInit {
    phone_or_email: String = "user";
    akcessIDChips: any = [];
    userData: any = [];
    userDataAKcessId: any = [];
    userDataEmail: any = [];
    userDataPhone: any = [];
    saveuserData: any = [];
    userwithChips: any = [];
    userwithChips_phone: any = [];
    sendsingleEmployeeDataModal: any = [];
    //tmpeform: any = "";
    placeholder: String = "Enter AKcess ID";
    isLengthProper: boolean;
    selectedCountryCode: any = [];

    //sameReference = [{ data: 1 }];
    //listPhone = [{ ...this.sameReference }];
    listPhone = [1];
    counter = 1;
    countryListCode: any = (data as any).default;

    //ng-select variable
    selectSubmitDisabled: boolean = false;
    storage_url: string;
    //   gs: any;
    // spinner: any;

    constructor(
        public refreshContentService: RefreshContentService,
        private spinner: NgxSpinnerService,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private gs: GeneralServiceService,
        public commonService: CommonService,
        private _bsModalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.storage_url = this.calldata.getStorageUrl();

        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                this.getserachuserData();
            } else {
                this.getserachuserData();
            }
        });
    }

    getFullURL(file = null) {
        if (file) return `${this.storage_url}/idcard/${file}`;
    }

    addTagFn(name) {
        return { name: name, tag: true };
    }

    addListPhone() {
        //this.listPhone.push({ data: 1 });
        //this.listPhone.push({ ...this.sameReference });
        this.listPhone.push(++this.counter);
    }

    removeListPhone(x: number) {
        this.listPhone.splice(x, 1);
    }

    //pushAkcessData(e) {
    //    this.akcessIDChips.push(e.value.split(" ")[0]);
    //}

    //removeAkcessData(e) {
    //    let indexOfEmail = this.akcessIDChips.findIndex((e) => e == e.value);
    //    this.akcessIDChips.splice(indexOfEmail, 1);
    //}

    getserachuserData() {
        this.calldata.getuserlist_search().subscribe(
            (res: any) => {
                if (res.status) {
                    this.saveuserData = res.data;

                    this.saveuserData.forEach((val) => {
                        var name = val["firstName"] + " " + val["lastName"];
                        var email =
                            val["email"] == "undefined" ||
                                val["email"] == "" ||
                                val["email"] == null
                                ? "No-Email"
                                : val["email"];
                        var phone =
                            val["phone"] == "undefined" ||
                                val["phone"] == null ||
                                val["phone"] == ""
                                ? "No-Mobile-Number"
                                : val["phone"];

                        var akcessId = val["akcessId"]
                            ? val["akcessId"]
                            : "undefined";

                        this.userData.push(
                            akcessId + " " + email + " " + phone
                        );
                        this.userDataAKcessId.push(name + " - " + akcessId);
                        this.userDataEmail.push(name + " - " + email);
                        this.userDataPhone.push(
                            name + " - +" + val.countryCode + phone
                        );
                    });
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //sendEformEmail(data) {
    //    let emailList = [];

    //    this.userwithChips.forEach((element) => {
    //        let emailOnly = element.split(" ")[1];
    //        emailList.push(emailOnly);
    //    });

    //    var reqBody = {};
    //}

    //sendEform_phone(data) {
    //    let phoneList = [];
    //    // this.userwithChips_phone.forEach((element) => {
    //    //   let phoneOnly = element.split(" ")[2];
    //    //   phoneList.push(phoneOnly);
    //    // });
    //    var reqBody = {
    //        phone: this.userwithChips_phone,
    //    };
    //}

    sendeIdCardByAkcessId(data) {
        this.isLengthProper = true;

        // if (this.akcessIDChips.length == 0) {
        //     this.toast.info('ENTER or SELECT AKcess Id');
        //     return false;
        // }

        let akcessIDChipsarray = [];

        this.akcessIDChips.forEach((element) => {
            if (
                element &&
                !element.name &&
                element.split(" ")[3] != "undefined" &&
                element.split(" ")[3]
            ) {
                akcessIDChipsarray.push(element.split(" ")[3]);
            } else if (element && element.name) {
                akcessIDChipsarray.push(element.name);
            }
        });

        akcessIDChipsarray = akcessIDChipsarray.map((el) => el.toUpperCase());

        let arrayLength = akcessIDChipsarray.map(function (id) {
            return id.length;
        });

        arrayLength.forEach((item) => {
            if (item < 11 || item > 12) {
                this.isLengthProper = false;
                return;
            }
        });

        if (!this.isLengthProper) {
            this.toast.error("AKcess Id should be of 11 or 12 letters.");
            this.spinner.hide();
        } else {
            let obj = {
                akcessId: [data.akcessId],
                Pdf_name: data.pdf_name,
                Description: "Hello! Your Staff ID is ready. Open it to save it in your IDs folder",
                DocumentID: data.idcard_id,
                domainName: `${environment.domainName}`,
                companyName: this.commonService.selectedCompanyDeatils.domain,
                logo: this.commonService.selectedCompanyDeatils.logo,
                file_url: this.getFullURL(data.pdf_name),
                recievedType: "idcard"
            };
            this.spinner.show();
            this.gs.sendIdcardByAkcessId(obj).subscribe(
                (data: any) => {
                    this._bsModalRef.hide();
                    let result = data;
                    let successID = result.data.success.toString();
                    let failureID = result.data.failure.toString();
                    if (successID.length > 0) {
                        this.toast.success(result.message);
                    }
                    if (failureID.length > 0) {
                        this.toast.error(
                            "Failed to send ID Card to " + failureID
                        );
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }
    }

    sendIdcardByphone(data) {
        // if (this.userwithChips_phone.length == 0) {
        //     this.toast.info('ENTER or SELECT AKcess mobile number');
        //     return false;
        // }

        let phoneChipsarray = [];
        let phoneNumberList = [];
        const countryCode = this.selectedCountryCode;
        const userPhoneNumber = this.userwithChips_phone;

        this.userwithChips_phone.forEach((element) => {
            if (element && !element.name && element != "undefined") {
                phoneChipsarray.push(element);
            } else if (element && element.name) {
                phoneChipsarray.push(element.name);
            }
        });

        let countryCodeList = phoneChipsarray.map((el) => el.slice(0, -10));

        //Check if country code is less
        if (countryCodeList.findIndex((val) => val === "") === -1) {
            //Getting the phone number
            if (countryCodeList.length === phoneChipsarray.length) {
                for (let i = 0; i < phoneChipsarray.length; i++) {
                    phoneNumberList.push({
                        countryCode: countryCodeList[i],
                        phone: phoneChipsarray[i].replace(
                            countryCodeList[i],
                            ""
                        ),
                    });
                }
            }

            let obj = {
                //contacts: phoneChipsarray,
                contacts: [
                    { countryCode: data.countryCode, phone: data.phone },
                ],
                Pdf_name: data.pdf_name,
                Description: "idcard",
                DocumentID: data.idcard_id,
                domainName: `${environment.domainName}`,
                companyName: this.commonService.selectedCompanyDeatils.domain,
                logo: this.commonService.selectedCompanyDeatils.logo,
                file_url: this.getFullURL(data.pdf_name),
                recievedType: "idcard"
            };

            this.spinner.show();

            this.gs.sendIdcardByphone(obj).subscribe(
                (data: any) => {
                    this._bsModalRef.hide();
                    let result = data;
                    let successID = result.data.success.toString();
                    let failureID = result.data.failure.toString();
                    if (successID.length > 0) {
                        this.toast.success(result.message);
                    }
                    if (failureID.length > 0) {
                        this.toast.error(
                            "Failed to send ID Card to " + failureID
                        );
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        } else {
            this.toast.error("Please enter phone number with country code");
            this.spinner.hide();
        }
    }

    sendIdcardByemail(data) {
        // if (this.userwithChips.length == 0) {
        //     this.toast.info('ENTER or SELECT email id');
        //     return false;
        // }

        let emailChipsarray = [];

        this.userwithChips.forEach((element) => {
            if (
                element &&
                !element.name &&
                element.split(" ")[3] != "undefined" &&
                element.split(" ")[3]
            ) {
                emailChipsarray.push(element.split(" ")[3]);
            } else if (element && element.name) {
                emailChipsarray.push(element.name);
            }
        });

        this.spinner.show();

        let obj = {
            email: [data.email],
            Pdf_name: data.pdf_name,
            Description: "Hello! Your Student ID is ready.Open it to save it in your IDs folder",
            DocumentID: data.idcard_id,
            domainName: `${environment.domainName}`,
            companyName: this.commonService.selectedCompanyDeatils.domain,
            logo: this.commonService.selectedCompanyDeatils.logo,
            file_url: this.getFullURL(data.pdf_name),
            recievedType: "idcard"
        };
        this.gs.sendIdcardByemail(obj).subscribe(
            (data: any) => {
                this._bsModalRef.hide();
                let result = data;
                let successID = result.data.success.toString();
                let failureID = result.data.failure.toString();
                if (successID.length > 0) {
                    this.toast.success(result.message);
                }
                if (failureID.length > 0) {
                    this.toast.error("Failed to send ID Card to " + failureID);
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    //removeeFormData(e) {
    //    if (e.display.split(" ")[1]) {
    //    } else {
    //        e.display = `New ${e.display} No Phone`;
    //    }
    //    let indexOfEmail = this.userwithChips.findIndex((ele) => ele == e.display);
    //    this.userwithChips.splice(indexOfEmail, 1);
    //}

    //removeeFormData_phone(e) {
    //    if (e.display.split(" ")[1]) {
    //    } else {
    //        e.display = `New ${e.display} No Phone`;
    //    }
    //    let indexOfEmail = this.userwithChips_phone.findIndex(
    //        (ele) => ele == e.display
    //    );

    //    this.userwithChips_phone.splice(indexOfEmail, 1);
    //}

    //pusheFormData(e) {
    //    let data = e.value;
    //    let str = data.split(" ")[0];
    //    let checkEmailCustom = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,8})+$/.test(
    //        str
    //    );

    //    if (checkEmailCustom) {
    //        e.value = `New ${e.value} No Phone`;
    //    }

    //    let checkEmail = data.split(" ")[1] ? data.split(" ")[1] : "No";
    //    if (checkEmail === "No" && !checkEmailCustom) {
    //        this.toast.error("Can't add into list");
    //    } else {
    //        this.userwithChips.push(e.value);
    //        data = "";
    //        e.value = "";
    //    }
    //}

    //pusheFormData_phone(e) {
    //    let data = e.value;

    //    let phone = data.split(" ")[0];
    //    var regExp = /^\+?\d{10,15}(,\+?\d{10,15})*$/g;

    //    let checkEmailCustom = regExp.test(phone);

    //    if (checkEmailCustom) {
    //        e.value = `${e.value}`;
    //    }

    //    let checkEmail = data.split(" ")[1] ? data.split(" ")[1] : "No";
    //    if (checkEmail === "No" && !checkEmailCustom) {
    //        this.toast.error("Can't add into list");
    //    } else {
    //        this.userwithChips_phone.push(e.value);

    //        data = "";
    //        e.value = "";
    //    }
    //}

    closeModal() {
        this._bsModalRef.hide();
    }

    //ng-select
    onOpenDropdown() {
        this.selectSubmitDisabled = true;
    }

    onCloseDropdown() {
        this.selectSubmitDisabled = false;
    }
}
