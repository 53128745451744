<div class="modal-header">
    <h5 class="modal-title">Get Data From AKcess ID</h5>
    <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
    >
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [formGroup]="DataForm">
    <input
        type="text"
        class="form-control"
        placeholder="Enter AKcess ID"
        formControlName="akcessId"
    />
    <div
        class="invalid-feedback"
        *ngIf="
            DataForm.controls.akcessId.errors &&
            DataForm.controls.akcessId.errors.required
        "
    >
        AKcessId required
    </div>
    <div
        class="invalid-feedback"
        *ngIf="
            DataForm.controls.akcessId.errors &&
            DataForm.controls.akcessId.errors.minlength
        "
    >
        Enter AKcessId
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="requestData()">
        Request Data
    </button>
    <button type="button" class="btn btn-danger" (click)="closeModal()">
        Close
    </button>
</div>

<app-confirm-dialog></app-confirm-dialog>
