import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
//import * as _ from 'lodash';
import * as _ from "lodash-es";
import {
    MdbTableDirective,
    MdbTablePaginationComponent,
} from "angular-bootstrap-md";

@Component({
    selector: "app-view-sent-asset-modal",
    templateUrl: "./view-sent-asset-modal.component.html",
    styleUrls: ["./view-sent-asset-modal.component.css"],
})
export class ViewSentAssetModalComponent implements OnInit {
    dataId: string;
    sentType: string;
    assetName: string;
    portalUser;
    assetSents = [];
    assetSentsEmail = [];
    assetSentsPhone = [];
    assetReceived = [];
    currentPage: any = 1;
    totalCount: any = 0;
    numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
    pageSize: any = 10;
    elements: any = [];
    previous: any = [];

    constructor(
        private _bsModalRef: BsModalRef,
        private spinner: NgxSpinnerService,
        private calldata: DataservicesService,
        private toast: ToastrService
    ) { }

    ngOnInit(): void {
        this.getSendDetails();
    }

    closeModal() {
        this._bsModalRef.hide();
    }

    getSendDetails() {
        if (this.sentType == "sent") {
            this.spinner.show();
            this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
            const tmpdata = {
                akcessId: this.portalUser.akcessId,
                assetId: this.dataId,
            };
            this.calldata.getSentAssets(tmpdata).subscribe(
                (data: any) => {
                    if (data.status) {
                        this.assetSents = [];
                        this.assetSents = data.data;
                        this.spinner.hide();
                    } else {
                        this.toast.info(data["msg"]);
                        this.spinner.hide();
                    }
                },
                (error) => {
                    this.toast.error("something went wrong");
                    this.spinner.hide();
                }
            );
            const xtmpdata = { assetId: this.dataId };

            this.calldata.getSentAssetUrlByPhoneOrEmail(xtmpdata).subscribe(
                (data: any) => {
                    if (data.status) {
                        this.assetSentsEmail = [];
                        this.assetSentsPhone = [];
                        this.assetSentsEmail = _.filter(data.data, {
                            emailFlag: true,
                        });
                        this.assetSentsPhone = _.filter(data.data, {
                            phoneFlag: true,
                        });
                        this.spinner.hide();
                    } else {
                        this.toast.info(data["msg"]);
                        this.spinner.hide();
                    }
                },
                (error) => {
                    this.toast.error("something went wrong");
                    this.spinner.hide();
                }
            );
        } else if (this.sentType == "received") {
            this.spinner.show();
            this.portalUser = JSON.parse(sessionStorage.getItem("portalUser"));
            const tmpdata = {
                akcessId: this.portalUser.akcessId,
                assetId: this.dataId,
            };
            this.calldata.getAssetReceived(tmpdata).subscribe(
                (data: any) => {
                    if (data.status) {
                        this.assetReceived = [];
                        this.assetReceived = data.data;
                    } else {
                        this.toast.info(data["msg"]);
                    }

                    this.spinner.hide();
                },
                (error) => {
                    this.toast.error("something went wrong");
                    this.spinner.hide();
                }
            );
        }
    }

    onProjectPageChange(event) {
        this.currentPage = event;
    }

    changePage(value) {
        this.pageSize = parseInt(value);
    }
}
