<div class="modal-content view-sent-eForm-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewSentModalLabel">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="(history$ | async) as history">
        <!-- {{history | json}} -->
        <div class="modal-tab">
            <div class="table-responsive mb-1">
                <table class="table color-bordered-table info-bordered-table" mdbTable #tableViewById="mdbTable" small="true" hover="true">
                    <thead>
                        <tr>
                            <th>Username </th>
                            <th>AKcess Id</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>DateTime</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="history?.result?.data?.length == 0">
                        <tr>
                            <td colspan="5" class="text-center">No sent eForms Found</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="history?.result?.data?.length > 0">
                            <tr *ngFor="let data of history?.result?.data ; let i = index">
                                <td>
                                    {{data?.userName}}
                                </td>
                                <td>
                                    {{data?.akcessId}}
                                </td>
                                <td>
                                    {{data?.subject}}
                                </td>
                                <td>
                                    {{data?.message}}
                                </td>
                                <td>
                                    {{data?.subject}}
                                </td>
                            </tr>
                    </tbody>
                    <tfoot class="w-100">
                        <tr>
                            <td colspan="5">
                                <div class="table-page">
                                    <div class="page-controls">
                                        <button (click)="prevPage()" [disabled]="currentPage == 0">Prev</button>
                                        <!-- &#x21d0; &#x21d2; -->
                                        <input type="number" [value]="currentPageDisplay" min="1" max="7">
                                        <button (click)="nextPage()">Next </button>
                                    </div>
                                    <div class="page-size">
                                        <label class="mb-0 mr-2">Item per page</label>
                                        <select #val (change)="changePage(val.value)" [value]="pageSize" class="form-control form-control-sm"
                                                id="pageSelector">
                                            <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">
            Close
        </button>
    </div>
</div>