<!-- View Staff List Modal-->
<div class="modal-content view-sent-eForm-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="viewStaffListModalLabel">Users</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="closeModal()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="modal-tab">
            <div class="table-responsive mb-1">
                <table
                    class="table color-bordered-table info-bordered-table"
                    mdbTable
                    #tableViewById="mdbTable"
                    small="true"
                    hover="true"
                >
                    <thead>
                        <tr>
                            <th>
                                <div class="form-check">
                                    <input
                                        class="datatable-row-checkbox form-check-input"
                                        type="checkbox"
                                        (change)="checkUncheckAll()"
                                        [(ngModel)]="isMasterSel"
                                    />
                                </div>
                            </th>
                            <th>AKcess Id</th>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="allUsers?.length == 0">
                        <tr>
                            <td colspan="3" class="text-center">
                                No users Found
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="allUsers?.length > 0">
                        <ng-container
                            *ngFor="
                                let data of allUsers
                                    | paginate
                                        : {
                                              itemsPerPage: 10,
                                              currentPage: currentPage
                                          };
                                let i = index
                            "
                        >
                            <tr *ngIf="data.akcessId">
                                <td>
                                    <div class="form-check">
                                        <input
                                            class="datatable-row-checkbox form-check-input"
                                            type="checkbox"
                                            [(ngModel)]="data.isSelected"
                                            [attr.id]="data?._id"
                                            value="data?._id"
                                            (change)="isAllSelected()"
                                        />
                                    </div>
                                </td>
                                <td>
                                    {{ data?.akcessId }}
                                </td>
                                <td>
                                    {{ data?.name }}
                                </td>
                                <td>{{ data?.email }}</td>
                                <td>
                                    {{ data?.usertype }}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <tfoot class="w-100">
                        <tr>
                            <td colspan="3">
                                <div
                                    class="table-page"
                                    *ngIf="allUsers?.length > 0"
                                >
                                    <div class="page-controls">
                                        <pagination-controls
                                            (pageChange)="currentPage = $event"
                                            class="float-right"
                                        ></pagination-controls>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn waves-effect waves-light btn-info"
            (click)="addRemoveLocationStaff()"
            [disabled]="getCheckedItemList().length == 0"
        >
            Add
        </button>
        <button
            type="button"
            class="btn waves-effect waves-light btn-danger"
            (click)="closeModal()"
        >
            Close
        </button>
    </div>
</div>
