<div class="modal-header">
    <h4 class="modal-title">E Form Response Report</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal('dismiss')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body background-light-blue">
    <div class="card">
        <div class="card-body">
            <div class="card-title row align-items-center">
                <div class="col-12 col-md-6 col-lg-4 mb-1 mb-lg-0">
                    <div class="input-group">
                        <input type="text" class="form-control" id="search"
                               (change)="searchEformResponse('search',$event.target.value)"
                               placeholder="Search" />
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-1 mb-lg-0">
                    <!--<label><b>Filter by eForm Status:</b></label>-->
                    <select class="form-control" (change)="searchEformResponse('select',$event.target.value)">
                        <option selected>Filter By eFrom</option>
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="alive">Alive</option>
                        <option value="accept">Accept</option>
                        <option value="verify and accept">Verify and Accept</option>
                        <option value="update client field">Update Client</option>
                        <option value="return">Return</option>
                        <option value="reject">Reject</option>
                    </select>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <!--<label><b>Order by Date:</b></label>-->
                    <select class="form-control" (change)="orderBy($event.target.value); geteformresponse(null)">
                        <option selected>Filter By Date</option>
                        <option value="nto">Newest to Oldest</option>
                        <option value="otn">Oldest to Newest</option>
                    </select>
                </div>
            </div>
            <div class="action-tab">
                <button type="button" class="mx-1 btn" tooltip="Export" container="body"
                        id="exportbtnpdf" (click)="exportexcel2('user_logs')">
                    <i class="fas fa-file-export"></i>
                </button>
            </div>
            <div class="table-responsive mb-1">
                <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" small="true" hover="true" id="user_logs">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>AKcessId</th>
                            <th>eForm Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="eformresponse?.length == 0">
                        <tr>
                            <td colspan="5" class="text-center">No Records Found</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="eformresponse?.length > 0">
                        <tr *ngFor="let data of eformresponse | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount };let i = index;">
                            <td>
                                <a (click)="view(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data.pulldata"
                                   data-toggle="modal" data-target="#viewform">
                                    {{ (data?.userdata[0]?.firstName) ? data?.userdata[0]?.firstName : data?.resFirstName }}
                                </a>
                            </td>
                            <td>
                                <a (click)="view(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data.pulldata"
                                   data-toggle="modal" data-target="#viewform">
                                    {{ (data?.userdata[0]?.lastName) ? data.userdata[0]?.lastName : data?.resLastName }}
                                </a>
                            </td>
                            <td>
                                <a (click)="view(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data.pulldata"
                                   data-toggle="modal" data-target="#viewform">
                                    {{data?.akcessId}}
                                </a>
                            </td>
                            <td>
                                <a (click)="view(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data.pulldata"
                                   data-toggle="modal" data-target="#viewform">
                                    {{data.formName}}
                                </a>
                            </td>
                            <td *ngIf="data.status=='Update Client Field'">
                                <a (click)="view(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data.pulldata"
                                   data-toggle="modal" data-target="#viewform">
                                    Update Client
                                </a>
                            </td>
                            <td *ngIf="data.status !=='Update Client Field'">
                                <a (click)="view(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName,data._id, data?.akcessId);resetVerifiedItems(); pullDataStatus = data.pulldata"
                                   data-toggle="modal" data-target="#viewform">
                                    {{data.status}}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="w-100">
                        <tr>
                            <td colspan="5">
                                <div class="table-page" *ngIf="eformresponse?.length > 10">
                                    <div class="page-controls">
                                        <pagination-controls (pageChange)="onProjectPageChange($event)"
                                                             class="my-pagination" directionLinks="true" previousLabel="Previous"
                                                             nextLabel="Next"></pagination-controls>
                                    </div>
                                    <div class="page-size">
                                        <label class="mb-0 mr-2">Item per page</label>
                                        <select #val (change)="changePage(val.value)"
                                                class="form-control form-control-sm" id="pageSelector">
                                            <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}</option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal" (click)="closeModal('dismiss')">Close</button>
</div>


<!-- Modal View Form -->
<div class="modal" id="viewform" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">{{formname}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div *ngFor="let data of fields;let i = index;">
                    <div class="form-group" *ngIf="data.keytype == 'text' || data.keytype == 'Text' || data.keytype == 'string' || data.keytype == 'list' || data.keytype == 'select' || data.keytype == 'number' || data.keytype == 'address' || data.keytype == 'phone' || data.keytype == 'mobile'">
                        <label class="font-500">{{data.key}}</label>
                        <input class="form-control" type="{{data.keytype}}" value="{{data.value}}" placeholder="{{data.key}}" readonly>
                    </div>
                    <div class="form-group" *ngIf="data.keytype == 'date' || data.keytype == 'radio' || data.keytype == 'password' || data.keytype == 'checkbox'">
                        <label class="font-500">{{data.key}}</label>
                        <input class="form-control" type="text" value="{{data.value}}" placeholder="{{data.key}}" readonly>
                    </div>
                    <div class="form-group" *ngIf="data.keytype == 'File' || data.keytype == 'file'">
                        <a href="{{imageUrl}}{{data?.path}}" target="_blank"> Document {{i}}</a>
                        <button class="btn waves-effect waves-light btn-info mx-2" *ngIf="pullDataStatus == 'yes'" (click)="fetchDataFromDoc(data)">
                            <i class="fas fa-download" tooltip="Fetch data from Document" container="body"></i>
                        </button>
                    </div>
                </div>
                <div *ngIf="eformStatus">
                    <label class="font-500" *ngIf="eformStatus=='Update Client Field'">Update Client :</label>
                    <label class="font-500" *ngIf="eformStatus!='Update Client Field'">{{eformStatus}} :</label>
                    <p>{{StatusDescription[eformStatus]}}</p>
                </div>
                <div id="pdf_formfields_testmaxcan" style="display: none;">
                    <h5>{{formname}}</h5>
                    <div *ngFor="let data of fields; let i = index;">
                        <p><span class="font-500">{{data.key}} : </span>{{data.value}}</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info"
                        id="exportbtnpdf" (click)="generatePdf(formname, fields['fullname'])">
                    Export
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger" id="close" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- Modal View Grade Form -->
<div class="modal" id="viewgrade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <div class="form-close-button" aria-hidden="true"></div>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="graderLoader" class="text-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div *ngIf="!graderLoader">
                    <label class="font-500">This field has the following grades:</label>
                    <div *ngFor="let data of fieldVerifierData?.data">
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'G'">Goverment</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'F'">Financial</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'T'">Telecom</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'A'">AKcess</p>
                        <p *ngIf="data && data.veriier && data.veriier.verifierGrade == 'O'">Other</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal View Expiry Date-->
<div class="modal" id="expiryDateModal" tabindex="-1" role="dialog" aria-labelledby="expiryDateModal" aria-hidden="true" *ngIf="eformDataWithForExpiryDate">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4>Expiry Date</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="font-500">{{addExpiryDate}}</label>
                    <label class="font-500">Expiry Date</label>
                    <input type="text"
                           [(ngModel)]="addExpiryDate"
                           name="addExpiryDate"
                           id="addExpiryDate"
                           placeholder="DD-MM-YYYY"
                           class="form-control"
                           bsDatepicker
                           [minDate]="minDate"
                           [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red' }">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-info" (click)="pushExpiryDateInFiedls()">Submit</button>
                <button type="button" class="btn waves-effect waves-light btn-danger" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
