import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConstantService {

    constructor() { }

    countries() {
        return [
            {
                "value": "null",
                "label": "Global"
            },
            {
                "value": "Afghanistan",
                "label": "Afghanistan"
            },
            {
                "value": "Åland Islands",
                "label": "Åland Islands"
            },
            {
                "value": "Albania",
                "label": "Albania"
            },
            {
                "value": "Algeria",
                "label": "Algeria"
            },
            {
                "value": "American Samoa",
                "label": "American Samoa"
            },
            {
                "value": "AndorrA",
                "label": "AndorrA"
            },
            {
                "value": "Angola",
                "label": "Angola"
            },
            {
                "value": "Anguilla",
                "label": "Anguilla"
            },
            {
                "value": "Antarctica",
                "label": "Antarctica"
            },
            {
                "value": "Antigua and Barbuda",
                "label": "Antigua and Barbuda"
            },
            {
                "value": "Argentina",
                "label": "Argentina"
            },
            {
                "value": "Armenia",
                "label": "Armenia"
            },
            {
                "value": "Aruba",
                "label": "Aruba"
            },
            {
                "value": "Australia",
                "label": "Australia"
            },
            {
                "value": "Austria",
                "label": "Austria"
            },
            {
                "value": "Azerbaijan",
                "label": "Azerbaijan"
            },
            {
                "value": "Bahamas",
                "label": "Bahamas"
            },
            {
                "value": "Bahrain",
                "label": "Bahrain"
            },
            {
                "value": "Bangladesh",
                "label": "Bangladesh"
            },
            {
                "value": "Barbados",
                "label": "Barbados"
            },
            {
                "value": "Belarus",
                "label": "Belarus"
            },
            {
                "value": "Belgium",
                "label": "Belgium"
            },
            {
                "value": "Belize",
                "label": "Belize"
            },
            {
                "value": "Benin",
                "label": "Benin"
            },
            {
                "value": "Bermuda",
                "label": "Bermuda"
            },
            {
                "value": "Bhutan",
                "label": "Bhutan"
            },
            {
                "value": "Bolivia",
                "label": "Bolivia"
            },
            {
                "value": "Bosnia and Herzegovina",
                "label": "Bosnia and Herzegovina"
            },
            {
                "value": "Botswana",
                "label": "Botswana"
            },
            {
                "value": "Bouvet Island",
                "label": "Bouvet Island"
            },
            {
                "value": "Brazil",
                "label": "Brazil"
            },
            {
                "value": "British Indian Ocean Territory",
                "label": "British Indian Ocean Territory"
            },
            {
                "value": "Brunei Darussalam",
                "label": "Brunei Darussalam"
            },
            {
                "value": "Bulgaria",
                "label": "Bulgaria"
            },
            {
                "value": "Burkina Faso",
                "label": "Burkina Faso"
            },
            {
                "value": "Burundi",
                "label": "Burundi"
            },
            {
                "value": "Cambodia",
                "label": "Cambodia"
            },
            {
                "value": "Cameroon",
                "label": "Cameroon"
            },
            {
                "value": "Canada",
                "label": "Canada"
            },
            {
                "value": "Cape Verde",
                "label": "Cape Verde"
            },
            {
                "value": "Cayman Islands",
                "label": "Cayman Islands"
            },
            {
                "value": "Central African Republic",
                "label": "Central African Republic"
            },
            {
                "value": "Chad",
                "label": "Chad"
            },
            {
                "value": "Chile",
                "label": "Chile"
            },
            {
                "value": "China",
                "label": "China"
            },
            {
                "value": "Christmas Island",
                "label": "Christmas Island"
            },
            {
                "value": "Cocos (Keeling) Islands",
                "label": "Cocos (Keeling) Islands"
            },
            {
                "value": "Colombia",
                "label": "Colombia"
            },
            {
                "value": "Comoros",
                "label": "Comoros"
            },
            {
                "value": "Congo",
                "label": "Congo"
            },
            {
                "value": "Congo, The Democratic Republic of the",
                "label": "Congo, The Democratic Republic of the"
            },
            {
                "value": "Cook Islands",
                "label": "Cook Islands"
            },
            {
                "value": "Costa Rica",
                "label": "Costa Rica"
            },
            {
                "value": "Cote D\"Ivoire",
                "label": "Cote D\"Ivoire"
            },
            {
                "value": "Croatia",
                "label": "Croatia"
            },
            {
                "value": "Cuba",
                "label": "Cuba"
            },
            {
                "value": "Cyprus",
                "label": "Cyprus"
            },
            {
                "value": "Czech Republic",
                "label": "Czech Republic"
            },
            {
                "value": "Denmark",
                "label": "Denmark"
            },
            {
                "value": "Djibouti",
                "label": "Djibouti"
            },
            {
                "value": "Dominica",
                "label": "Dominica"
            },
            {
                "value": "Dominican Republic",
                "label": "Dominican Republic"
            },
            {
                "value": "Ecuador",
                "label": "Ecuador"
            },
            {
                "value": "Egypt",
                "label": "Egypt"
            },
            {
                "value": "El Salvador",
                "label": "El Salvador"
            },
            {
                "value": "Equatorial Guinea",
                "label": "Equatorial Guinea"
            },
            {
                "value": "Eritrea",
                "label": "Eritrea"
            },
            {
                "value": "Estonia",
                "label": "Estonia"
            },
            {
                "value": "Ethiopia",
                "label": "Ethiopia"
            },
            {
                "value": "Falkland Islands (Malvinas)",
                "label": "Falkland Islands (Malvinas)"
            },
            {
                "value": "Faroe Islands",
                "label": "Faroe Islands"
            },
            {
                "value": "Fiji",
                "label": "Fiji"
            },
            {
                "value": "Finland",
                "label": "Finland"
            },
            {
                "value": "France",
                "label": "France"
            },
            {
                "value": "French Guiana",
                "label": "French Guiana"
            },
            {
                "value": "French Polynesia",
                "label": "French Polynesia"
            },
            {
                "value": "French Southern Territories",
                "label": "French Southern Territories"
            },
            {
                "value": "Gabon",
                "label": "Gabon"
            },
            {
                "value": "Gambia",
                "label": "Gambia"
            },
            {
                "value": "Georgia",
                "label": "Georgia"
            },
            {
                "value": "Germany",
                "label": "Germany"
            },
            {
                "value": "Ghana",
                "label": "Ghana"
            },
            {
                "value": "Gibraltar",
                "label": "Gibraltar"
            },
            {
                "value": "Greece",
                "label": "Greece"
            },
            {
                "value": "Greenland",
                "label": "Greenland"
            },
            {
                "value": "Grenada",
                "label": "Grenada"
            },
            {
                "value": "Guadeloupe",
                "label": "Guadeloupe"
            },
            {
                "value": "Guam",
                "label": "Guam"
            },
            {
                "value": "Guatemala",
                "label": "Guatemala"
            },
            {
                "value": "Guernsey",
                "label": "Guernsey"
            },
            {
                "value": "Guinea",
                "label": "Guinea"
            },
            {
                "value": "Guinea-Bissau",
                "label": "Guinea-Bissau"
            },
            {
                "value": "Guyana",
                "label": "Guyana"
            },
            {
                "value": "Haiti",
                "label": "Haiti"
            },
            {
                "value": "Heard Island and Mcdonald Islands",
                "label": "Heard Island and Mcdonald Islands"
            },
            {
                "value": "Holy See (Vatican City State)",
                "label": "Holy See (Vatican City State)"
            },
            {
                "value": "Honduras",
                "label": "Honduras"
            },
            {
                "value": "Hong Kong",
                "label": "Hong Kong"
            },
            {
                "value": "Hungary",
                "label": "Hungary"
            },
            {
                "value": "Iceland",
                "label": "Iceland"
            },
            {
                "value": "India",
                "label": "India"
            },
            {
                "value": "Indonesia",
                "label": "Indonesia"
            },
            {
                "value": "Iran, Islamic Republic Of",
                "label": "Iran, Islamic Republic Of"
            },
            {
                "value": "Iraq",
                "label": "Iraq"
            },
            {
                "value": "Ireland",
                "label": "Ireland"
            },
            {
                "value": "Isle of Man",
                "label": "Isle of Man"
            },
            {
                "value": "Israel",
                "label": "Israel"
            },
            {
                "value": "Italy",
                "label": "Italy"
            },
            {
                "value": "Jamaica",
                "label": "Jamaica"
            },
            {
                "value": "Japan",
                "label": "Japan"
            },
            {
                "value": "Jersey",
                "label": "Jersey"
            },
            {
                "value": "Jordan",
                "label": "Jordan"
            },
            {
                "value": "Kazakhstan",
                "label": "Kazakhstan"
            },
            {
                "value": "Kenya",
                "label": "Kenya"
            },
            {
                "value": "Kiribati",
                "label": "Kiribati"
            },
            {
                "value": "Korea, Democratic People\"S Republic of",
                "label": "Korea, Democratic People\"S Republic of"
            },
            {
                "value": "Korea, Republic of",
                "label": "Korea, Republic of"
            },
            {
                "value": "Kuwait",
                "label": "Kuwait"
            },
            {
                "value": "Kyrgyzstan",
                "label": "Kyrgyzstan"
            },
            {
                "value": "Lao People\"S Democratic Republic",
                "label": "Lao People\"S Democratic Republic"
            },
            {
                "value": "Latvia",
                "label": "Latvia"
            },
            {
                "value": "Lebanon",
                "label": "Lebanon"
            },
            {
                "value": "Lesotho",
                "label": "Lesotho"
            },
            {
                "value": "Liberia",
                "label": "Liberia"
            },
            {
                "value": "Libyan Arab Jamahiriya",
                "label": "Libyan Arab Jamahiriya"
            },
            {
                "value": "Liechtenstein",
                "label": "Liechtenstein"
            },
            {
                "value": "Lithuania",
                "label": "Lithuania"
            },
            {
                "value": "Luxembourg",
                "label": "Luxembourg"
            },
            {
                "value": "Macao",
                "label": "Macao"
            },
            {
                "value": "Macedonia, The Former Yugoslav Republic of",
                "label": "Macedonia, The Former Yugoslav Republic of"
            },
            {
                "value": "Madagascar",
                "label": "Madagascar"
            },
            {
                "value": "Malawi",
                "label": "Malawi"
            },
            {
                "value": "Malaysia",
                "label": "Malaysia"
            },
            {
                "value": "Maldives",
                "label": "Maldives"
            },
            {
                "value": "Mali",
                "label": "Mali"
            },
            {
                "value": "Malta",
                "label": "Malta"
            },
            {
                "value": "Marshall Islands",
                "label": "Marshall Islands"
            },
            {
                "value": "Martinique",
                "label": "Martinique"
            },
            {
                "value": "Mauritania",
                "label": "Mauritania"
            },
            {
                "value": "Mauritius",
                "label": "Mauritius"
            },
            {
                "value": "Mayotte",
                "label": "Mayotte"
            },
            {
                "value": "Mexico",
                "label": "Mexico"
            },
            {
                "value": "Micronesia, Federated States of",
                "label": "Micronesia, Federated States of"
            },
            {
                "value": "Moldova, Republic of",
                "label": "Moldova, Republic of"
            },
            {
                "value": "Monaco",
                "label": "Monaco"
            },
            {
                "value": "Mongolia",
                "label": "Mongolia"
            },
            {
                "value": "Montserrat",
                "label": "Montserrat"
            },
            {
                "value": "Morocco",
                "label": "Morocco"
            },
            {
                "value": "Mozambique",
                "label": "Mozambique"
            },
            {
                "value": "Myanmar",
                "label": "Myanmar"
            },
            {
                "value": "Namibia",
                "label": "Namibia"
            },
            {
                "value": "Nauru",
                "label": "Nauru"
            },
            {
                "value": "Nepal",
                "label": "Nepal"
            },
            {
                "value": "Netherlands",
                "label": "Netherlands"
            },
            {
                "value": "Netherlands Antilles",
                "label": "Netherlands Antilles"
            },
            {
                "value": "New Caledonia",
                "label": "New Caledonia"
            },
            {
                "value": "New Zealand",
                "label": "New Zealand"
            },
            {
                "value": "Nicaragua",
                "label": "Nicaragua"
            },
            {
                "value": "Niger",
                "label": "Niger"
            },
            {
                "value": "Nigeria",
                "label": "Nigeria"
            },
            {
                "value": "Niue",
                "label": "Niue"
            },
            {
                "value": "Norfolk Island",
                "label": "Norfolk Island"
            },
            {
                "value": "Northern Mariana Islands",
                "label": "Northern Mariana Islands"
            },
            {
                "value": "Norway",
                "label": "Norway"
            },
            {
                "value": "Oman",
                "label": "Oman"
            },
            {
                "value": "Pakistan",
                "label": "Pakistan"
            },
            {
                "value": "Palau",
                "label": "Palau"
            },
            {
                "value": "Palestinian Territory, Occupied",
                "label": "Palestinian Territory, Occupied"
            },
            {
                "value": "Panama",
                "label": "Panama"
            },
            {
                "value": "Papua New Guinea",
                "label": "Papua New Guinea"
            },
            {
                "value": "Paraguay",
                "label": "Paraguay"
            },
            {
                "value": "Peru",
                "label": "Peru"
            },
            {
                "value": "Philippines",
                "label": "Philippines"
            },
            {
                "value": "Pitcairn",
                "label": "Pitcairn"
            },
            {
                "value": "Poland",
                "label": "Poland"
            },
            {
                "value": "Portugal",
                "label": "Portugal"
            },
            {
                "value": "Puerto Rico",
                "label": "Puerto Rico"
            },
            {
                "value": "Qatar",
                "label": "Qatar"
            },
            {
                "value": "Reunion",
                "label": "Reunion"
            },
            {
                "value": "Romania",
                "label": "Romania"
            },
            {
                "value": "Russian Federation",
                "label": "Russian Federation"
            },
            {
                "value": "RWANDA",
                "label": "RWANDA"
            },
            {
                "value": "Saint Helena",
                "label": "Saint Helena"
            },
            {
                "value": "Saint Kitts and Nevis",
                "label": "Saint Kitts and Nevis"
            },
            {
                "value": "Saint Lucia",
                "label": "Saint Lucia"
            },
            {
                "value": "Saint Pierre and Miquelon",
                "label": "Saint Pierre and Miquelon"
            },
            {
                "value": "Saint Vincent and the Grenadines",
                "label": "Saint Vincent and the Grenadines"
            },
            {
                "value": "Samoa",
                "label": "Samoa"
            },
            {
                "value": "San Marino",
                "label": "San Marino"
            },
            {
                "value": "Sao Tome and Principe",
                "label": "Sao Tome and Principe"
            },
            {
                "value": "Saudi Arabia",
                "label": "Saudi Arabia"
            },
            {
                "value": "Senegal",
                "label": "Senegal"
            },
            {
                "value": "Serbia and Montenegro",
                "label": "Serbia and Montenegro"
            },
            {
                "value": "Seychelles",
                "label": "Seychelles"
            },
            {
                "value": "Sierra Leone",
                "label": "Sierra Leone"
            },
            {
                "value": "Singapore",
                "label": "Singapore"
            },
            {
                "value": "Slovakia",
                "label": "Slovakia"
            },
            {
                "value": "Slovenia",
                "label": "Slovenia"
            },
            {
                "value": "Solomon Islands",
                "label": "Solomon Islands"
            },
            {
                "value": "Somalia",
                "label": "Somalia"
            },
            {
                "value": "South Africa",
                "label": "South Africa"
            },
            {
                "value": "South Georgia and the South Sandwich Islands",
                "label": "South Georgia and the South Sandwich Islands"
            },
            {
                "value": "Spain",
                "label": "Spain"
            },
            {
                "value": "Sri Lanka",
                "label": "Sri Lanka"
            },
            {
                "value": "Sudan",
                "label": "Sudan"
            },
            {
                "value": "Suriname",
                "label": "Suriname"
            },
            {
                "value": "Svalbard and Jan Mayen",
                "label": "Svalbard and Jan Mayen"
            },
            {
                "value": "Swaziland",
                "label": "Swaziland"
            },
            {
                "value": "Sweden",
                "label": "Sweden"
            },
            {
                "value": "Switzerland",
                "label": "Switzerland"
            },
            {
                "value": "Syrian Arab Republic",
                "label": "Syrian Arab Republic"
            },
            {
                "value": "Taiwan, Province of China",
                "label": "Taiwan, Province of China"
            },
            {
                "value": "Tajikistan",
                "label": "Tajikistan"
            },
            {
                "value": "Tanzania, United Republic of",
                "label": "Tanzania, United Republic of"
            },
            {
                "value": "Thailand",
                "label": "Thailand"
            },
            {
                "value": "Timor-Leste",
                "label": "Timor-Leste"
            },
            {
                "value": "Togo",
                "label": "Togo"
            },
            {
                "value": "Tokelau",
                "label": "Tokelau"
            },
            {
                "value": "Tonga",
                "label": "Tonga"
            },
            {
                "value": "Trinidad and Tobago",
                "label": "Trinidad and Tobago"
            },
            {
                "value": "Tunisia",
                "label": "Tunisia"
            },
            {
                "value": "Turkey",
                "label": "Turkey"
            },
            {
                "value": "Turkmenistan",
                "label": "Turkmenistan"
            },
            {
                "value": "Turks and Caicos Islands",
                "label": "Turks and Caicos Islands"
            },
            {
                "value": "Tuvalu",
                "label": "Tuvalu"
            },
            {
                "value": "Uganda",
                "label": "Uganda"
            },
            {
                "value": "Ukraine",
                "label": "Ukraine"
            },
            {
                "value": "United Arab Emirates",
                "label": "United Arab Emirates"
            },
            {
                "value": "United Kingdom",
                "label": "United Kingdom"
            },
            {
                "value": "United States",
                "label": "United States"
            },
            {
                "value": "United States Minor Outlying Islands",
                "label": "United States Minor Outlying Islands"
            },
            {
                "value": "Uruguay",
                "label": "Uruguay"
            },
            {
                "value": "Uzbekistan",
                "label": "Uzbekistan"
            },
            {
                "value": "Vanuatu",
                "label": "Vanuatu"
            },
            {
                "value": "Venezuela",
                "label": "Venezuela"
            },
            {
                "value": "Viet Nam",
                "label": "Viet Nam"
            },
            {
                "value": "Virgin Islands, British",
                "label": "Virgin Islands, British"
            },
            {
                "value": "Virgin Islands, U.S.",
                "label": "Virgin Islands, U.S."
            },
            {
                "value": "Wallis and Futuna",
                "label": "Wallis and Futuna"
            },
            {
                "value": "Western Sahara",
                "label": "Western Sahara"
            },
            {
                "value": "Yemen",
                "label": "Yemen"
            },
            {
                "value": "Zambia",
                "label": "Zambia"
            },
            {
                "value": "Zimbabwe",
                "label": "Zimbabwe"
            }
        ];
    }

    grade() {
        return [
            {
                "value": "G",
                "label": "Government"
            },
            {
                "value": "F",
                "label": "Financial"
            },
            {
                "value": "T",
                "label": "Telecom"
            },
            {
                "value": "A",
                "label": "AKcess"
            },
            {
                "value": "O",
                "label": "Other"
            }
        ]
    }

    setData() {
        return {
            "akcessId": "",
            "domain_name": "",
            "db_name": "",
            "request_type": "",
            "type": "",
            "description": "",
            "request_user": "",
            "logo": "",
            "requestId": "",
            "status": "pending",
            "date": "2022-11-08 09:05:59",
            "fields": [
                {
                    "options": [],
                    "labelname": "First Name",
                    "key": "firstname",
                    "keytype": "string",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "First Name",
                    "is_mandatory": "yes"
                },
                {
                    "options": [],
                    "labelname": "Last Name",
                    "key": "lastname",
                    "keytype": "string",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Last Name",
                    "is_mandatory": "yes"
                },
                {
                    "options": [],
                    "labelname": "Email",
                    "key": "email",
                    "keytype": "string",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Email",
                    "is_mandatory": "yes"
                },
                {
                    "options": [],
                    "labelname": "Mobile Number",
                    "key": "mobile",
                    "keytype": "phone",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Mobile Number",
                    "is_mandatory": "yes"
                },
                {
                    "options": [],
                    "labelname": "Date of Birth",
                    "key": "dateofbirth",
                    "keytype": "date",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Date of Birth",
                    "is_mandatory": "yes"
                },
                {
                    "options": [],
                    "labelname": "Place of Birth",
                    "key": "place of birth",
                    "keytype": "text",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Place of Birth",
                    "is_mandatory": "yes"
                },
                {
                    "options": [
                        {
                            "checked": "false",
                            "keyfields": "male",
                            "lable": "Male",
                            "uid": "nh7gdsaz0xt1"
                        },
                        {
                            "checked": "false",
                            "keyfields": "female",
                            "lable": "Female",
                            "uid": "qgb629jztp37"
                        }
                    ],
                    "labelname": "Gender",
                    "key": "gender",
                    "keytype": "list",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Gender",
                    "is_mandatory": "yes"
                },
                {
                    "options": [
                        {
                            "checked": "false",
                            "keyfields": "afghan",
                            "lable": "Afghan",
                            "uid": "3tg7oriaf1sq"
                        },
                        {
                            "checked": "false",
                            "keyfields": "albanian",
                            "lable": "Albanian",
                            "uid": "9pbv5n0ikf8s"
                        },
                        {
                            "checked": "false",
                            "keyfields": "algerian",
                            "lable": "Algerian",
                            "uid": "nsew7qx3mhrl"
                        },
                        {
                            "checked": "false",
                            "keyfields": "american",
                            "lable": "American",
                            "uid": "th6d79bx2s4f"
                        },
                        {
                            "checked": "false",
                            "keyfields": "andorran",
                            "lable": "Andorran",
                            "uid": "580mucdhw9fy"
                        },
                        {
                            "checked": "false",
                            "keyfields": "angolan",
                            "lable": "Angolan",
                            "uid": "p7rs1nyq6e3o"
                        },
                        {
                            "checked": "false",
                            "keyfields": "antiguans",
                            "lable": "Antiguans",
                            "uid": "pmkyar03ewj2"
                        },
                        {
                            "checked": "false",
                            "keyfields": "argentinean",
                            "lable": "Argentinean",
                            "uid": "1m7f3qeh9iyj"
                        },
                        {
                            "checked": "false",
                            "keyfields": "armenian",
                            "lable": "Armenian",
                            "uid": "wd185lv0k6f7"
                        },
                        {
                            "checked": "false",
                            "keyfields": "australian",
                            "lable": "Australian",
                            "uid": "e93rvqybsl5k"
                        },
                        {
                            "checked": "false",
                            "keyfields": "austrian",
                            "lable": "Austrian",
                            "uid": "rb80gf6w1zcs"
                        },
                        {
                            "checked": "false",
                            "keyfields": "azerbaijani",
                            "lable": "Azerbaijani",
                            "uid": "sg5niklf86xv"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bahamian",
                            "lable": "Bahamian",
                            "uid": "fsuedvgt2j36"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bahraini",
                            "lable": "Bahraini",
                            "uid": "25umxvbzkr03"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bangladeshi",
                            "lable": "Bangladeshi",
                            "uid": "1sqze97i0t62"
                        },
                        {
                            "checked": "false",
                            "keyfields": "barbadian",
                            "lable": "Barbadian",
                            "uid": "r68hwanfl92g"
                        },
                        {
                            "checked": "false",
                            "keyfields": "barbudans",
                            "lable": "Barbudans",
                            "uid": "q6vxjrsifo3e"
                        },
                        {
                            "checked": "false",
                            "keyfields": "batswana",
                            "lable": "Batswana",
                            "uid": "t4bjaog0w8v1"
                        },
                        {
                            "checked": "false",
                            "keyfields": "belarusian",
                            "lable": "Belarusian",
                            "uid": "bgdvl2nw3xz9"
                        },
                        {
                            "checked": "false",
                            "keyfields": "belgian",
                            "lable": "Belgian",
                            "uid": "15qfjb0r9k2h"
                        },
                        {
                            "checked": "false",
                            "keyfields": "belizean",
                            "lable": "Belizean",
                            "uid": "p0n4qw78dhlt"
                        },
                        {
                            "checked": "false",
                            "keyfields": "beninese",
                            "lable": "Beninese",
                            "uid": "tn3vazowc051"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bhutanese",
                            "lable": "Bhutanese",
                            "uid": "o12c6yjx0wmz"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bolivian",
                            "lable": "Bolivian",
                            "uid": "1gwchdjlk05s"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bosnian",
                            "lable": "Bosnian",
                            "uid": "8fca46x9y0i5"
                        },
                        {
                            "checked": "false",
                            "keyfields": "brazilian",
                            "lable": "Brazilian",
                            "uid": "gonw6yai425f"
                        },
                        {
                            "checked": "false",
                            "keyfields": "british",
                            "lable": "British",
                            "uid": "tjfnoxcig0lm"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bruneian",
                            "lable": "Bruneian",
                            "uid": "gw5bnzxq2tok"
                        },
                        {
                            "checked": "false",
                            "keyfields": "bulgarian",
                            "lable": "Bulgarian",
                            "uid": "sd3oh9kut78g"
                        },
                        {
                            "checked": "false",
                            "keyfields": "burkinabe",
                            "lable": "Burkinabe",
                            "uid": "na35ckg16sft"
                        },
                        {
                            "checked": "false",
                            "keyfields": "burmese",
                            "lable": "Burmese",
                            "uid": "jzdb2g1w9chn"
                        },
                        {
                            "checked": "false",
                            "keyfields": "burundian",
                            "lable": "Burundian",
                            "uid": "ogwxr9u517ev"
                        },
                        {
                            "checked": "false",
                            "keyfields": "cambodian",
                            "lable": "Cambodian",
                            "uid": "lacyuko180fp"
                        },
                        {
                            "checked": "false",
                            "keyfields": "cameroonian",
                            "lable": "Cameroonian",
                            "uid": "s8wrm1n3kaqp"
                        },
                        {
                            "checked": "false",
                            "keyfields": "canadian",
                            "lable": "Canadian",
                            "uid": "3cwlrh76tyod"
                        },
                        {
                            "checked": "false",
                            "keyfields": "cape verdean",
                            "lable": "Cape Verdean",
                            "uid": "z5nlyvr89jtx"
                        },
                        {
                            "checked": "false",
                            "keyfields": "central african",
                            "lable": "Central African",
                            "uid": "ydwa6c3o9x4g"
                        },
                        {
                            "checked": "false",
                            "keyfields": "chadian",
                            "lable": "Chadian",
                            "uid": "pus3h9wc2vye"
                        },
                        {
                            "checked": "false",
                            "keyfields": "chilean",
                            "lable": "Chilean",
                            "uid": "a2w9rys1mgbz"
                        },
                        {
                            "checked": "false",
                            "keyfields": "chinese",
                            "lable": "Chinese",
                            "uid": "v5p4iyl79rg6"
                        },
                        {
                            "checked": "false",
                            "keyfields": "colombian",
                            "lable": "Colombian",
                            "uid": "qtrcld0g8pz2"
                        },
                        {
                            "checked": "false",
                            "keyfields": "comoran",
                            "lable": "Comoran",
                            "uid": "1h0qlpjn64zg"
                        },
                        {
                            "checked": "false",
                            "keyfields": "congolese",
                            "lable": "Congolese",
                            "uid": "ibjuoafn4257"
                        },
                        {
                            "checked": "false",
                            "keyfields": "costa rican",
                            "lable": "Costa Rican",
                            "uid": "n79z03ljbhkx"
                        },
                        {
                            "checked": "false",
                            "keyfields": "croatian",
                            "lable": "Croatian",
                            "uid": "ipmuwz8rqc51"
                        },
                        {
                            "checked": "false",
                            "keyfields": "cuban",
                            "lable": "Cuban",
                            "uid": "7qfwiecvbr8a"
                        },
                        {
                            "checked": "false",
                            "keyfields": "cypriot",
                            "lable": "Cypriot",
                            "uid": "4bqo5g3jvcl7"
                        },
                        {
                            "checked": "false",
                            "keyfields": "czechv",
                            "lable": "Czechv",
                            "uid": "ro36pksv5we1"
                        },
                        {
                            "checked": "false",
                            "keyfields": "danish",
                            "lable": "Danish",
                            "uid": "xiulp3f91sqv"
                        },
                        {
                            "checked": "false",
                            "keyfields": "djibouti",
                            "lable": "Djibouti",
                            "uid": "ti7rglp3wqov"
                        },
                        {
                            "checked": "false",
                            "keyfields": "dominican",
                            "lable": "Dominican",
                            "uid": "7gacfhnrl6ki"
                        },
                        {
                            "checked": "false",
                            "keyfields": "dutch",
                            "lable": "Dutch",
                            "uid": "kz4smf5yc6bi"
                        },
                        {
                            "checked": "false",
                            "keyfields": "east timorese",
                            "lable": "East Timorese",
                            "uid": "mgi509uobefd"
                        },
                        {
                            "checked": "false",
                            "keyfields": "ecuadorean",
                            "lable": "Ecuadorean",
                            "uid": "9u37j4x1rgdk"
                        },
                        {
                            "checked": "false",
                            "keyfields": "egyptian",
                            "lable": "Egyptian",
                            "uid": "46oexdsrj2na"
                        },
                        {
                            "checked": "false",
                            "keyfields": "emirian",
                            "lable": "Emirian",
                            "uid": "3qa8j1fg9nms"
                        },
                        {
                            "checked": "false",
                            "keyfields": "equatorial guinean",
                            "lable": "Equatorial Guinean",
                            "uid": "uohwd3sf75iq"
                        },
                        {
                            "checked": "false",
                            "keyfields": "eritrean",
                            "lable": "Eritrean",
                            "uid": "bmpaqg3s26ur"
                        },
                        {
                            "checked": "false",
                            "keyfields": "estonian",
                            "lable": "Estonian",
                            "uid": "wx1f8idgclm9"
                        },
                        {
                            "checked": "false",
                            "keyfields": "ethiopian",
                            "lable": "Ethiopian",
                            "uid": "5nlk7jwe96r2"
                        },
                        {
                            "checked": "false",
                            "keyfields": "fijian",
                            "lable": "Fijian",
                            "uid": "b7hvtuic0q8s"
                        },
                        {
                            "checked": "false",
                            "keyfields": "filipino",
                            "lable": "Filipino",
                            "uid": "p3w2lkr49g8z"
                        },
                        {
                            "checked": "false",
                            "keyfields": "finnish",
                            "lable": "Finnish",
                            "uid": "7n9f2wm1tqya"
                        },
                        {
                            "checked": "false",
                            "keyfields": "french",
                            "lable": "French",
                            "uid": "8wv5uo3q0ixs"
                        },
                        {
                            "checked": "false",
                            "keyfields": "gabonese",
                            "lable": "Gabonese",
                            "uid": "my6915j3knfg"
                        },
                        {
                            "checked": "false",
                            "keyfields": "gambian",
                            "lable": "Gambian",
                            "uid": "ruezfmqtg31h"
                        },
                        {
                            "checked": "false",
                            "keyfields": "georgian",
                            "lable": "Georgian",
                            "uid": "7cjg9odxykf1"
                        },
                        {
                            "checked": "false",
                            "keyfields": "german",
                            "lable": "German",
                            "uid": "5odu7zfj409t"
                        },
                        {
                            "checked": "false",
                            "keyfields": "ghanaian",
                            "lable": "Ghanaian",
                            "uid": "4fpoydg2cwni"
                        },
                        {
                            "checked": "false",
                            "keyfields": "greek",
                            "lable": "Greek",
                            "uid": "dsjcbu24i9xf"
                        },
                        {
                            "checked": "false",
                            "keyfields": "grenadian",
                            "lable": "Grenadian",
                            "uid": "y4eztxbusr75"
                        },
                        {
                            "checked": "false",
                            "keyfields": "guatemalan",
                            "lable": "Guatemalan",
                            "uid": "gubwazedhr4f"
                        },
                        {
                            "checked": "false",
                            "keyfields": "guinea-bissauan",
                            "lable": "Guinea-Bissauan",
                            "uid": "dc047pa91szk"
                        },
                        {
                            "checked": "false",
                            "keyfields": "guinean",
                            "lable": "Guinean",
                            "uid": "bfs5d8ryxuwj"
                        },
                        {
                            "checked": "false",
                            "keyfields": "guyanese",
                            "lable": "Guyanese",
                            "uid": "kbq0i5tp72yx"
                        },
                        {
                            "checked": "false",
                            "keyfields": "haitian",
                            "lable": "Haitian",
                            "uid": "6vzmo5hjfpwg"
                        },
                        {
                            "checked": "false",
                            "keyfields": "herzegovinian",
                            "lable": "Herzegovinian",
                            "uid": "wi6kg0jtorvb"
                        },
                        {
                            "checked": "false",
                            "keyfields": "honduran",
                            "lable": "Honduran",
                            "uid": "1c3dgyk25ueh"
                        },
                        {
                            "checked": "false",
                            "keyfields": "hungarian",
                            "lable": "Hungarian",
                            "uid": "hr819c3yknjw"
                        },
                        {
                            "checked": "false",
                            "keyfields": "icelander",
                            "lable": "Icelander",
                            "uid": "a4m2jp9gic1w"
                        },
                        {
                            "checked": "false",
                            "keyfields": "indian",
                            "lable": "Indian",
                            "uid": "1dwoh0ajpibu"
                        },
                        {
                            "checked": "false",
                            "keyfields": "indonesian",
                            "lable": "Indonesian",
                            "uid": "3zc9afjbhop1"
                        },
                        {
                            "checked": "false",
                            "keyfields": "iranian",
                            "lable": "Iranian",
                            "uid": "4h0mcu8fitl9"
                        },
                        {
                            "checked": "false",
                            "keyfields": "iraqi",
                            "lable": "Iraqi",
                            "uid": "vbr3esx9ikg0"
                        },
                        {
                            "checked": "false",
                            "keyfields": "irish",
                            "lable": "Irish",
                            "uid": "om0nku9rhyw7"
                        },
                        {
                            "checked": "false",
                            "keyfields": "italian",
                            "lable": "Italian",
                            "uid": "4mhiqrt9jeyl"
                        },
                        {
                            "checked": "false",
                            "keyfields": "ivorian",
                            "lable": "Ivorian",
                            "uid": "ckntbfrioa2v"
                        },
                        {
                            "checked": "false",
                            "keyfields": "jamaican",
                            "lable": "Jamaican",
                            "uid": "xh3uw1lmji2r"
                        },
                        {
                            "checked": "false",
                            "keyfields": "japanese",
                            "lable": "Japanese",
                            "uid": "nfv6cmkb7rxa"
                        },
                        {
                            "checked": "false",
                            "keyfields": "jordanian",
                            "lable": "Jordanian",
                            "uid": "2rypz0mwsn85"
                        },
                        {
                            "checked": "false",
                            "keyfields": "kazakhstani",
                            "lable": "Kazakhstani",
                            "uid": "7t9b5o4mxu38"
                        },
                        {
                            "checked": "false",
                            "keyfields": "kenyan",
                            "lable": "Kenyan",
                            "uid": "ruwgpoxkb31l"
                        },
                        {
                            "checked": "false",
                            "keyfields": "kittian and nevisian",
                            "lable": "Kittian and Nevisian",
                            "uid": "hnltcse9i75v"
                        },
                        {
                            "checked": "false",
                            "keyfields": "kuwaiti",
                            "lable": "Kuwaiti",
                            "uid": "j0b872akne9d"
                        },
                        {
                            "checked": "false",
                            "keyfields": "kyrgyz",
                            "lable": "Kyrgyz",
                            "uid": "tg2re483ks0w"
                        },
                        {
                            "checked": "false",
                            "keyfields": "laotian",
                            "lable": "Laotian",
                            "uid": "pl2kodu8hxq0"
                        },
                        {
                            "checked": "false",
                            "keyfields": "latvian",
                            "lable": "Latvian",
                            "uid": "ro0igvz8b4ea"
                        },
                        {
                            "checked": "false",
                            "keyfields": "lebanese",
                            "lable": "Lebanese",
                            "uid": "ofjh3sebmkcy"
                        },
                        {
                            "checked": "false",
                            "keyfields": "liberian",
                            "lable": "Liberian",
                            "uid": "hzo3g7kyt1a5"
                        },
                        {
                            "checked": "false",
                            "keyfields": "libyan",
                            "lable": "Libyan",
                            "uid": "r2i1jm5upq0b"
                        },
                        {
                            "checked": "false",
                            "keyfields": "liechtensteiner",
                            "lable": "Liechtensteiner",
                            "uid": "ejza49hq5mxp"
                        },
                        {
                            "checked": "false",
                            "keyfields": "lithuanian",
                            "lable": "Lithuanian",
                            "uid": "ba0ze4nhdk83"
                        },
                        {
                            "checked": "false",
                            "keyfields": "luxembourger",
                            "lable": "Luxembourger",
                            "uid": "48xyj7nwf6oh"
                        },
                        {
                            "checked": "false",
                            "keyfields": "macedonian",
                            "lable": "Macedonian",
                            "uid": "wr40su2en5qk"
                        },
                        {
                            "checked": "false",
                            "keyfields": "malagasy",
                            "lable": "Malagasy",
                            "uid": "huw86ckbamfd"
                        },
                        {
                            "checked": "false",
                            "keyfields": "malawian",
                            "lable": "Malawian",
                            "uid": "582mr4kdv7ti"
                        },
                        {
                            "checked": "false",
                            "keyfields": "malaysian",
                            "lable": "Malaysian",
                            "uid": "g0alybk5odsx"
                        },
                        {
                            "checked": "false",
                            "keyfields": "maldivan",
                            "lable": "Maldivan",
                            "uid": "gdx4eypbuzst"
                        },
                        {
                            "checked": "false",
                            "keyfields": "malian",
                            "lable": "Malian",
                            "uid": "15ur0ymck93o"
                        },
                        {
                            "checked": "false",
                            "keyfields": "maltese",
                            "lable": "Maltese",
                            "uid": "k6z2hrd5yinp"
                        },
                        {
                            "checked": "false",
                            "keyfields": "marshallese",
                            "lable": "Marshallese",
                            "uid": "rf06u5vej4bi"
                        },
                        {
                            "checked": "false",
                            "keyfields": "mauritanian",
                            "lable": "Mauritanian",
                            "uid": "y6nx8sp92kad"
                        },
                        {
                            "checked": "false",
                            "keyfields": "mauritian",
                            "lable": "Mauritian",
                            "uid": "anlyq436jhxu"
                        },
                        {
                            "checked": "false",
                            "keyfields": "mexican",
                            "lable": "Mexican",
                            "uid": "nf4p1jvbz80w"
                        },
                        {
                            "checked": "false",
                            "keyfields": "micronesian",
                            "lable": "Micronesian",
                            "uid": "lya6c7s802eu"
                        },
                        {
                            "checked": "false",
                            "keyfields": "moldovan",
                            "lable": "Moldovan",
                            "uid": "bmpqsx681or2"
                        },
                        {
                            "checked": "false",
                            "keyfields": "monacan",
                            "lable": "Monacan",
                            "uid": "gfyc725rhxtj"
                        },
                        {
                            "checked": "false",
                            "keyfields": "mongolian",
                            "lable": "Mongolian",
                            "uid": "l04a2ycxsi7p"
                        },
                        {
                            "checked": "false",
                            "keyfields": "moroccan",
                            "lable": "Moroccan",
                            "uid": "ye6px2juhtr5"
                        },
                        {
                            "checked": "false",
                            "keyfields": "mosotho",
                            "lable": "Mosotho",
                            "uid": "zkc59pu6b3la"
                        },
                        {
                            "checked": "false",
                            "keyfields": "motswana",
                            "lable": "Motswana",
                            "uid": "un36tiv79fj0"
                        },
                        {
                            "checked": "false",
                            "keyfields": "mozambican",
                            "lable": "Mozambican",
                            "uid": "smlat7cpio3y"
                        },
                        {
                            "checked": "false",
                            "keyfields": "namibian",
                            "lable": "Namibian",
                            "uid": "50uwonqki3z4"
                        },
                        {
                            "checked": "false",
                            "keyfields": "nauruan",
                            "lable": "Nauruan",
                            "uid": "u9otkd5z0nys"
                        },
                        {
                            "checked": "false",
                            "keyfields": "nepalese",
                            "lable": "Nepalese",
                            "uid": "47qtphwdbz25"
                        },
                        {
                            "checked": "false",
                            "keyfields": "new zealander",
                            "lable": "New Zealander",
                            "uid": "qi4kx17v5nfu"
                        },
                        {
                            "checked": "false",
                            "keyfields": "ni-vanuatu",
                            "lable": "Ni-Vanuatu",
                            "uid": "7k5drh1ieyal"
                        },
                        {
                            "checked": "false",
                            "keyfields": "nicaraguan",
                            "lable": "Nicaraguan",
                            "uid": "xqgivbmcs2f1"
                        },
                        {
                            "checked": "false",
                            "keyfields": "nigerien",
                            "lable": "Nigerien",
                            "uid": "fsn39846u5gi"
                        },
                        {
                            "checked": "false",
                            "keyfields": "north korean",
                            "lable": "North Korean",
                            "uid": "pnf39g1kizhv"
                        },
                        {
                            "checked": "false",
                            "keyfields": "northern irish",
                            "lable": "Northern Irish",
                            "uid": "f45msuyqcz8v"
                        },
                        {
                            "checked": "false",
                            "keyfields": "norwegian",
                            "lable": "Norwegian",
                            "uid": "s0bjdm6iz2af"
                        },
                        {
                            "checked": "false",
                            "keyfields": "omani",
                            "lable": "Omani",
                            "uid": "6xndavzpbtqe"
                        },
                        {
                            "checked": "false",
                            "keyfields": "pakistani",
                            "lable": "Pakistani",
                            "uid": "epv3lz0n6dhx"
                        },
                        {
                            "checked": "false",
                            "keyfields": "palauan",
                            "lable": "Palauan",
                            "uid": "atkhey20zq4w"
                        },
                        {
                            "checked": "false",
                            "keyfields": "panamanian",
                            "lable": "Panamanian",
                            "uid": "hvap3821jksu"
                        },
                        {
                            "checked": "false",
                            "keyfields": "papua new guinean",
                            "lable": "Papua New Guinean",
                            "uid": "kpl10q87h3a2"
                        },
                        {
                            "checked": "false",
                            "keyfields": "paraguayan",
                            "lable": "Paraguayan",
                            "uid": "qtsal3pux52v"
                        },
                        {
                            "checked": "false",
                            "keyfields": "peruvian",
                            "lable": "Peruvian",
                            "uid": "3wtpm18yoq2g"
                        },
                        {
                            "checked": "false",
                            "keyfields": "polish",
                            "lable": "Polish",
                            "uid": "xtm30f7u2ikw"
                        },
                        {
                            "checked": "false",
                            "keyfields": "portuguese",
                            "lable": "Portuguese",
                            "uid": "53fczl6v4got"
                        },
                        {
                            "checked": "false",
                            "keyfields": "qatari",
                            "lable": "Qatari",
                            "uid": "4hze1k9dcabg"
                        },
                        {
                            "checked": "false",
                            "keyfields": "romanian",
                            "lable": "Romanian",
                            "uid": "j1k28sgy7lx5"
                        },
                        {
                            "checked": "false",
                            "keyfields": "russian",
                            "lable": "Russian",
                            "uid": "94ml3wthifun"
                        },
                        {
                            "checked": "false",
                            "keyfields": "rwandan",
                            "lable": "Rwandan",
                            "uid": "sefumr1vck06"
                        },
                        {
                            "checked": "false",
                            "keyfields": "saint",
                            "lable": "Saint",
                            "uid": "mebsk2ugwqdx"
                        },
                        {
                            "checked": "false",
                            "keyfields": "lucian",
                            "lable": "Lucian",
                            "uid": "r9sovdl3ektg"
                        },
                        {
                            "checked": "false",
                            "keyfields": "salvadoran",
                            "lable": "Salvadoran",
                            "uid": "yvw5zsh4mlqo"
                        },
                        {
                            "checked": "false",
                            "keyfields": "samoan",
                            "lable": "Samoan",
                            "uid": "ahspycu320gj"
                        },
                        {
                            "checked": "false",
                            "keyfields": "san marinese",
                            "lable": "San Marinese",
                            "uid": "oqf1pbjdx9yw"
                        },
                        {
                            "checked": "false",
                            "keyfields": "sao tomean",
                            "lable": "Sao Tomean",
                            "uid": "hon28jwsx1i6"
                        },
                        {
                            "checked": "false",
                            "keyfields": "saudi",
                            "lable": "Saudi",
                            "uid": "rp0fzqsiah52"
                        },
                        {
                            "checked": "false",
                            "keyfields": "scottish",
                            "lable": "Scottish",
                            "uid": "ekadgp6h8yn7"
                        },
                        {
                            "checked": "false",
                            "keyfields": "senegalese",
                            "lable": "Senegalese",
                            "uid": "86g3ji7c42zv"
                        },
                        {
                            "checked": "false",
                            "keyfields": "serbian",
                            "lable": "Serbian",
                            "uid": "4t1dyl76gij9"
                        },
                        {
                            "checked": "false",
                            "keyfields": "seychellois",
                            "lable": "Seychellois",
                            "uid": "y9zvimpe7oga"
                        },
                        {
                            "checked": "false",
                            "keyfields": "sierra leonean",
                            "lable": "Sierra Leonean",
                            "uid": "x47v2kq6dgbm"
                        },
                        {
                            "checked": "false",
                            "keyfields": "singaporean",
                            "lable": "Singaporean",
                            "uid": "90iluad1k4oj"
                        },
                        {
                            "checked": "false",
                            "keyfields": "slovakian",
                            "lable": "Slovakian",
                            "uid": "gvamtc07fj8l"
                        },
                        {
                            "checked": "false",
                            "keyfields": "slovenian",
                            "lable": "Slovenian",
                            "uid": "c7ozxyg4bfkp"
                        },
                        {
                            "checked": "false",
                            "keyfields": "solomon islander",
                            "lable": "Solomon Islander",
                            "uid": "vcq40mhugfbw"
                        },
                        {
                            "checked": "false",
                            "keyfields": "somali",
                            "lable": "Somali",
                            "uid": "6y9iswgedmpu"
                        },
                        {
                            "checked": "false",
                            "keyfields": "south african",
                            "lable": "South African",
                            "uid": "5tdu97k8zibj"
                        },
                        {
                            "checked": "false",
                            "keyfields": "south korean",
                            "lable": "South Korean",
                            "uid": "vix2yfmcpz6s"
                        },
                        {
                            "checked": "false",
                            "keyfields": "spanish",
                            "lable": "Spanish",
                            "uid": "pby9fzvh8dkg"
                        },
                        {
                            "checked": "false",
                            "keyfields": "sri lankan",
                            "lable": "Sri Lankan",
                            "uid": "ut3mlh5nds20"
                        },
                        {
                            "checked": "false",
                            "keyfields": "sudanese",
                            "lable": "Sudanese",
                            "uid": "3rntbfdmco06"
                        },
                        {
                            "checked": "false",
                            "keyfields": "surinamer",
                            "lable": "Surinamer",
                            "uid": "2csod1zpgfm6"
                        },
                        {
                            "checked": "false",
                            "keyfields": "swazi",
                            "lable": "Swazi",
                            "uid": "09fqx1tw4rai"
                        },
                        {
                            "checked": "false",
                            "keyfields": "swedish",
                            "lable": "Swedish",
                            "uid": "hzty4pna2cui"
                        },
                        {
                            "checked": "false",
                            "keyfields": "swiss",
                            "lable": "Swiss",
                            "uid": "wrknd4fitpy6"
                        },
                        {
                            "checked": "false",
                            "keyfields": "syrian",
                            "lable": "Syrian",
                            "uid": "p2rg4i1v57cm"
                        },
                        {
                            "checked": "false",
                            "keyfields": "taiwanese",
                            "lable": "Taiwanese",
                            "uid": "9qyvo7dkemuj"
                        },
                        {
                            "checked": "false",
                            "keyfields": "tajik",
                            "lable": "Tajik",
                            "uid": "ljut6x5pmg4q"
                        },
                        {
                            "checked": "false",
                            "keyfields": "tanzanian",
                            "lable": "Tanzanian",
                            "uid": "pio1lh38gv5q"
                        },
                        {
                            "checked": "false",
                            "keyfields": "thaiv",
                            "lable": "Thaiv",
                            "uid": "7u6kcrntdx2q"
                        },
                        {
                            "checked": "false",
                            "keyfields": "togolese",
                            "lable": "Togolese",
                            "uid": "lm5wfuokhz8i"
                        },
                        {
                            "checked": "false",
                            "keyfields": "tongan",
                            "lable": "Tongan",
                            "uid": "uw7bv0re5ofa"
                        },
                        {
                            "checked": "false",
                            "keyfields": "trinidadian or tobagonian",
                            "lable": "Trinidadian or Tobagonian",
                            "uid": "u5n164kfz8dp"
                        },
                        {
                            "checked": "false",
                            "keyfields": "tunisian",
                            "lable": "Tunisian",
                            "uid": "ygpb79o4mw08"
                        },
                        {
                            "checked": "false",
                            "keyfields": "turkish",
                            "lable": "Turkish",
                            "uid": "5wf2loj7ktp3"
                        },
                        {
                            "checked": "false",
                            "keyfields": "tuvaluan",
                            "lable": "Tuvaluan",
                            "uid": "leyftd7gv6pk"
                        },
                        {
                            "checked": "false",
                            "keyfields": "ugandan",
                            "lable": "Ugandan",
                            "uid": "5r7wy6qiep9g"
                        },
                        {
                            "checked": "false",
                            "keyfields": "ukrainian",
                            "lable": "Ukrainian",
                            "uid": "oretsb3imhyu"
                        },
                        {
                            "checked": "false",
                            "keyfields": "uruguayan",
                            "lable": "Uruguayan",
                            "uid": "vmkiw0ge9qon"
                        },
                        {
                            "checked": "false",
                            "keyfields": "uzbekistani",
                            "lable": "Uzbekistani",
                            "uid": "b4qp1n52dm6h"
                        },
                        {
                            "checked": "false",
                            "keyfields": "venezuelan",
                            "lable": "Venezuelan",
                            "uid": "njlovz49t6u7"
                        },
                        {
                            "checked": "false",
                            "keyfields": "vietnamese",
                            "lable": "Vietnamese",
                            "uid": "v7txumwo8zks"
                        },
                        {
                            "checked": "false",
                            "keyfields": "welsh",
                            "lable": "Welsh",
                            "uid": "nlj1mgowe9hy"
                        },
                        {
                            "checked": "false",
                            "keyfields": "yemenite",
                            "lable": "Yemenite",
                            "uid": "52q7e19zcbhw"
                        },
                        {
                            "checked": "false",
                            "keyfields": "zambian",
                            "lable": "Zambian",
                            "uid": "2vpai9sbm6jn"
                        },
                        {
                            "checked": "false",
                            "keyfields": "zimbabwean",
                            "lable": "Zimbabwean",
                            "uid": "etqz3i4h9pg7"
                        }
                    ],
                    "labelname": "Nationality",
                    "key": "nationality",
                    "keytype": "list",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Nationality",
                    "is_mandatory": "yes"
                },
                {
                    "options": [],
                    "labelname": "Address",
                    "key": "homeaddress",
                    "keytype": "address",
                    "signature_required": "no",
                    "file_verified": "no",
                    "verification_grade": "",
                    "instructions": "Address",
                    "is_mandatory": "yes"
                }
            ]
        }
    }
}
