<!-- Send Document modal -->
<div class="modal-content send-doc-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title" id="sendDocModalLabel">Send Document</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row justify-content-around pb-2">
            <div class="custom-radio">
                <!--<input type="radio" [(ngModel)]="phone_or_email" value="user" id="user"
                       (click)="placeholder='Enter AKcess Id'" class="custom-control-input" mdbInput>
                <label for="user" class="custom-control-label"><b>AKcess Id</b></label>-->
                <input type="radio" [(ngModel)]="phone_or_email" value="user" id="user"
                    (click)="placeholder='Enter AKcess Id'" class="with-gap radio-col-light-blue" />
                <label for="user" class="font-500">AKcess Id</label>
            </div>
            <div class="custom-radio">
                <!--<input type="radio" [(ngModel)]="phone_or_email" value="email" id="email"
                       (click)="placeholder='Enter Email'" class="custom-control-input" mdbInput>
                <label for="email" class="custom-control-label"><b>Email</b></label>-->
                <input type="radio" [(ngModel)]="phone_or_email" value="email" id="email"
                    (click)="placeholder='Enter Email'" class="with-gap radio-col-light-blue">
                <label for="email" class="font-500">Email</label>
            </div>
            <div class="custom-radio">
                <!--<input type="radio" [(ngModel)]="phone_or_email" value="phone" id="phone"
                       (click)="placeholder='Enter Phone Number'" class="custom-control-input" mdbInput>
                <label for="phone" class="custom-control-label"><b>Phone</b></label>-->
                <input type="radio" [(ngModel)]="phone_or_email" value="phone" id="phone"
                    (click)="placeholder='Enter Mobile Number'" class="with-gap radio-col-light-blue">
                <label for="phone" class="font-500">Mobile</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="phone_or_email=='user'">
                <ng-select [items]="userDataAKcessId" [addTag]="addTagFn" [hideSelected]="true" multiple="true"
                    bindLabel="name" placeholder="Select User" [(ngModel)]="akcessIDChips" appendTo="body"
                    class="akcess-id-dropdown" (open)="onOpenDropdown()" (close)="onCloseDropdown()">
                </ng-select>
                <div class="valid-feedback">Type AKcessID and press ENTER to validate</div>
            </div>
            <div class="col-12" *ngIf="phone_or_email=='email'">
                <ng-select [items]="userDataEmail" [addTag]="addTagFn" [hideSelected]="true" multiple="true"
                    bindLabel="name" placeholder="Select/Enter Email" [(ngModel)]="userwithChips" appendTo="body"
                    (open)="onOpenDropdown()" (close)="onCloseDropdown()">
                </ng-select>
                <div class="valid-feedback">Type the email address and press ENTER</div>
            </div>
            <div class="col-12" *ngIf="phone_or_email=='phone'">
                <ng-select [items]="userDataPhone" [addTag]="addTagFn" [hideSelected]="true" multiple="true"
                    bindLabel="name" placeholder="Select/Enter Mobile Number (ex. +123 45678912)"
                    [(ngModel)]="userwithChips_phone" appendTo="body" (open)="onOpenDropdown()"
                    (close)="onCloseDropdown()">
                </ng-select>
                <div class="valid-feedback">Type the phone number with the country code and press ENTER (ex. +123
                    456789)</div>
                <!--<div class="row send-by-phone-section align-items-center" *ngFor="let phone of listPhone; let i = index;">
                    <div class="col-3">
                        <ng-select class="country-code" [(ngModel)]="selectedCountryCode[i]" placeholder="Code" appendTo="body">
                            <ng-option *ngFor="let country of countryListCode" [value]="country.dial_code">{{country.dial_code}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-8 pl-0">
                        <input type="number" class="form-control" placeholder="Enter Phone Number" [(ngModel)]="userwithChips_phone[i]" />
                    </div>
                    <div class="col-1 pl-0">
                        <button type="button" class="btn btn-sm btn-action-icon blue-action pl-0 pr-0" (click)="addListPhone()" *ngIf="i == 0">
                            <i class="fas fa-plus-circle"></i>
                        </button>
                        <button type="button" class="btn btn-sm btn-action-icon red-action pl-0 pr-0" (click)="removeListPhone(i)" *ngIf="i > 0">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </div>
                <div class="valid-feedback">Select Country Code and Enter the phone number</div>-->
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="phone_or_email=='user'" type="button" class="btn waves-effect waves-light btn-info"
            [disabled]="selectSubmitDisabled" (click)="sendDocByAkcessId(sendsingleEmployeeDataModal)">
            Send
        </button>
        <button *ngIf="phone_or_email=='email'" type="button" class="btn waves-effect waves-light btn-info"
            [disabled]="selectSubmitDisabled" (click)="sendDocByemail(sendsingleEmployeeDataModal)">
            Send to Mail
        </button>
        <button *ngIf="phone_or_email=='phone'" type="button" class="btn waves-effect waves-light btn-info"
            [disabled]="selectSubmitDisabled" (click)="sendDocByphone(sendsingleEmployeeDataModal)">
            Send to Mobile
        </button>
        <button *ngIf="phone_or_email=='email' || phone_or_email=='phone'" type="button"
            class="btn waves-effect waves-light btn-danger" (click)="closeModal()">
            Close
        </button>
        <button *ngIf="phone_or_email=='user'" type="button" class="btn waves-effect waves-light btn-danger"
            (click)="akcessIDChips = [];userwithChips_phone=[];userwithChips=[];closeModal()">
            Close
        </button>
    </div>
</div>