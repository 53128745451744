<!-- Register Page -->
<div class="register-page">
    <div class="container">
        <div class="form-outer text-center d-flex align-items-center">
            <div class="card w-100">
                <div class="card-body">
                    <div class="image-section mb-3">
                        <a href="../index.html">
                            <!--<img src="../assets/img/aub-logo.png" />
                            <img src="{{portalLogo}}" class="navbar-brand-img" />-->
                            <!--<img [src]="imageurl" class="navbar-brand-img" alt="{{portalLabel}}" title="{{portalLabel}}">-->
                            <img src="../assets/img/akcess-sme-logo.png" class="navbar-brand-img" alt="{{portalLabel}}" title="{{portalLabel}}">
                        </a>
                    </div>
                    <div class="welcome text-uppercase mb-4">REGISTER</div>
                    <div class="form-group">
                        <input type="email" class="form-control" [(ngModel)]="regEmail" name="reg_email"
                               (ngModelChange)="saveEmail($event)" placeholder="By Email" [disabled]="inputEmail" />
                    </div>
                    <div class="position-relative my-4">
                        <hr />
                        <p class="new-user-p">OR</p>
                    </div>
                    <div class="mb-4 d-flex">
                        <ng-select class="country-code mr-2" [(ngModel)]="regPhoneCountryCode" placeholder="Code" appendTo="body"
                                   (change)="savePhone($event)" [disabled]="inputPhone">
                            <ng-option *ngFor="let country of countryListCode" [value]="country.dial_code">{{country.dial_code}}</ng-option>
                        </ng-select>
                        <!--<input type="number" class="form-control country-code mr-3" [(ngModel)]="regPhoneCountryCode" name="reg_phone_code"
                        (ngModelChange)="savePhone($event)" placeholder="Code" [disabled]="inputPhone" />-->
                        <input type="number" class="form-control" [(ngModel)]="regPhone" name="reg_phone"
                               (ngModelChange)="savePhone($event)" placeholder="By Mobile" [disabled]="inputPhone" />
                    </div>
                    <div class="text-center">
                        <button type="button" class="btn waves-effect waves-light btn-info" (click)="registerNewCompany()" [disabled]="submitBtn">Register</button>
                    </div>
                    <hr />
                    <p class="mb-0">Already have an account ? <a [routerLink]="['/login']">Sign in</a></p>

                </div>
            </div>
            <div class="copyrights text-center">
                <p>
                    Copyright &#169; {{currentYear}} AKcess Labs. All rights reserved
                </p>
            </div>
        </div>
    </div>
</div>