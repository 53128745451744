<div class="header bg-gradient-info pb-8 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body">
     
    
    </div>
  </div>
</div>
<div class="container-fluid mt--9">
   
  <!-- Dark table -->
  <div class="row mt-5">
    <div class="col">
      <div class="card bg-default shadow">
        <div class="card-header bg-transparent border-0">
          <h3 class="text-white mb-0">Sent eForms</h3>
        </div>
        <div  class="table-responsive">
          <div class="alert alert-info m-3" *ngIf="eformsents?.length == 0" >No sent eForms Found</div>
          <table *ngIf="eformsents?.length > 0" class="table align-items-center table-dark table-flush">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Full name</th>
                <th scope="col">AKcessId</th>
                <th scope="col">eForm name</th>
                <th scope="col">Status</th>
                 <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of eformsents;let i = index;">
                <th scope="row">
                  <div class="media align-items-center">
                     <div class="media-body">
                      <span class="mb-0 text-sm"> {{data.userdata[0]?.firstName}} {{data.userdata[0]?.lastName}}</span>
                    </div>
                  </div>
                </th>
                <td>
                  {{data.userdata[0]?.akcessId}}
                </td>
                <td>
                    {{data.formName}}
                </td>
                <td>
                  {{data.status}}
                </td>
                 <td>
                        <!-- <a (click)="deleteform(data._id)" ><i class="fas fa-2x fa-trash-alt"></i></a> -->
                        <a class="ml-3" (click)="view(i,data.userdata[0]?.firstName+'_'+data.userdata[0]?.lastName)" data-toggle="modal" data-target="#viewform" ><i class="far fa-2x fa-eye"></i></a>
                        
                 </td>
              </tr>
             </tbody>
          </table>
        </div>
        <div class="card-footer bg-default shadow py-4">
            <nav aria-label="...">
              <!-- <ul class="pagination justify-content-end mb-0">
                <li class="page-item disabled">
                  <a class="page-link" href="#" tabindex="-1">
                    <i class="fas fa-angle-left"></i>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                </li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <i class="fas fa-angle-right"></i>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul> -->
            </nav>
          </div>
      </div>
    </div>
  </div>
  <!-- Footer -->
 <app-footer></app-footer>
</div>

<div class="modal fade" id="viewform" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{formname}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngFor="let data of fields;let i = index;" class="form-group">
          <label for="demo">{{data.key}}</label>
          <div *ngIf="data.keytype == 'text' || data.keytype == 'Text'" class="row">
                  <div class="col-md-10">
                      <input value="{{data.value}}" type="{{data.keytype}}" readonly  class="form-control" placeholder="{{data.key}}">
                  </div>
             </div>
             <div *ngIf="data.keytype == 'File' || data.keytype == 'file'" class="row">
              <div class="col-md-10">
                 <a href="{{imageUrl}}{{data.val.docUrl}}" target="_blank"> Document {{i}}</a>
                  
              </div>
         </div>
        </div>
       </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" id="exportbtnpdf" (click)="generatePdf(formname, fields['fullname'])">Export to PDF</button> -->
        <!-- <button type="button" class="btn btn-secondary" id="exportbtnxls" (click)="exportexcel(formname, fields['fullname'])">Export to XLSX</button> -->
        <button type="button" class="btn btn-secondary" id="close" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<table id="xls_formfields">
  <tr>
      <th colspan="2">{{formname}}</th>
  </tr>
  <tr *ngFor="let data of fields; let i = index;">
      <th style="font-weight: bold;">
          {{data.key}}
      </th>
      <td *ngIf="data.keytype == 'text' || data.keytype == 'Text'">
          {{data.value}}
      </td>
      <td *ngIf="data.keytype == 'File' || data.keytype == 'file'">
          <a href="{{imageUrl}}{{data.val.docUrl}}" target="_blank"> Document {{i}}</a>
      </td>
  </tr>
</table> 
<span id="pdf_formfields" style=" display: none;">
  <h3 style="text-align: center;">{{formname}}</h3>
    <p *ngFor="let data of fields; let i = index;">
      <b>{{data.key}}: </b>{{data.value}}<br>
    </p>
  
</span>