import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { RefreshContentService } from "../../../../_services/refresh-content.service";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "src/app/_services/common.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { environment } from "src/environments/environment.prod";
import { NgxSpinnerService } from "ngx-spinner";
import * as data from "src/app/common/countryCode.json";

@Component({
    selector: "app-doc-send-form",
    templateUrl: "./doc-send-form.component.html",
    styleUrls: ["./doc-send-form.component.css"],
})
export class DocSendFormComponent implements OnInit {
    phone_or_email: String = "";
    akcessIDChips: any = [];
    userData: any = [];
    userDataAKcessId: any = [];
    userDataEmail: any = [];
    userDataPhone: any = [];
    saveuserData: any = [];
    userwithChips: any = [];
    userwithChips_phone: any = [];
    sendsingleEmployeeDataModal: any = [];
    tmpeform: any = "";
    placeholder: String = "Enter AKcess ID";
    isLengthProper: boolean;
    selectedCountryCode: any = [];
    listPhone = [1];
    counter = 1;
    countryListCode: any = (data as any).default;

    //ng-select variable
    selectSubmitDisabled: boolean = false;

    // spinner: any;

    constructor(
        public refreshContentService: RefreshContentService,
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
        private gs: GeneralServiceService,
        public commonService: CommonService,
        private _bsModalRef: BsModalRef
    ) {}

    ngOnInit(): void {
        this.refreshContentService.notifyToAll.subscribe((res) => {
            if (res && res.hasOwnProperty("option")) {
                this.getserachuserData();
            } else {
                this.getserachuserData();
            }
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        });
    }

    addListPhone() {
        this.listPhone.push(++this.counter);
    }

    removeListPhone(x: number) {
        this.listPhone.splice(x, 1);
    }

    addTagFn(name) {
        return { name: name, tag: true };
    }

    pushAkcessData(e) {
        this.akcessIDChips.push(e.value.split(" ")[0]);
    }

    removeAkcessData(e) {
        let indexOfEmail = this.akcessIDChips.findIndex((e) => e == e.value);
        this.akcessIDChips.splice(indexOfEmail, 1);
    }

    getserachuserData() {
        this.spinner.show()
        this.calldata.getuserlist_search().subscribe(
            (res: any) => {
                if (res.status) {
                    this.saveuserData = res.data;
                    this.spinner.hide()
                    this.saveuserData.forEach((val) => {
                        var name = val["firstName"] + " " + val["lastName"];
                        var email =
                            val["email"] == "undefined" ||
                            val["email"] == "" ||
                            val["email"] == null
                                ? "No-Email"
                                : val["email"];
                        var phone =
                            val["phone"] == "undefined" ||
                            val["phone"] == null ||
                            val["phone"] == ""
                                ? "No-Phone"
                                : val["phone"];
                        var countryCode =
                            val["countryCode"] == "undefined" ||
                            val["countryCode"] == null ||
                            val["countryCode"] == ""
                                ? "No Country Code"
                                : val["countryCode"];
                        var akcessId = val["akcessId"]
                            ? val["akcessId"]
                            : "undefined";

                        this.userData.push(
                            akcessId + " " + email + " " + phone
                        );
                        this.userDataAKcessId.push(name + " - " + akcessId);
                        this.userDataEmail.push(name + " - " + email);
                        this.userDataPhone.push(
                            name + " - +" + countryCode + phone
                        );
                    });
                }else{
                    this.spinner.hide()
                }
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );
    }

    sendEformEmail(data) {
        let emailList = [];

        this.userwithChips.forEach((element) => {
            let emailOnly = element.split(" ")[1];
            emailList.push(emailOnly);
        });

        var reqBody = {};
    }

    sendEform_phone(data) {
        let phoneList = [];

        var reqBody = {
            phone: this.userwithChips_phone,
        };
    }
    // akcessIDChipsarray = [];

    sendDocByAkcessId(data) {
        if (this.akcessIDChips.length == 0) {
            this.toast.info("ENTER or SELECT AKcess Id");
            return false;
        }

        this.spinner.show();
        // this.akcessIDChipsarray = this.akcessIDChips.map((id) => id.substr(0, 12));
        this.isLengthProper = true;

        let akcessIDChipsarray = [];

        this.akcessIDChips.forEach((element) => {
            if (
                element &&
                !element.name &&
                element.split(" ")[3] != "undefined" &&
                element.split(" ")[3]
            ) {
                akcessIDChipsarray.push(element.split(" ")[3]);
            } else if (element && element.name) {
                akcessIDChipsarray.push(element.name);
            }
        });

        //this.akcessIDChips.forEach(element => {
        //    if (element && !element.name && element != 'undefined') {
        //        akcessIDChipsarray.push(element);
        //    } else if (element && element.name) {
        //        akcessIDChipsarray.push(element.name);
        //    }
        //});

        akcessIDChipsarray = akcessIDChipsarray.map((el) => el.toUpperCase());

        let arrayLength = akcessIDChipsarray.map(function (id) {
            return id.length;
        });

        arrayLength.forEach((item) => {
            if (item < 11 || item > 12) {
                this.isLengthProper = false;
                return;
            }
        });

        if (!this.isLengthProper) {
            this.toast.error("AKcess Id should be of 11 or 12 letters.");
            this.spinner.hide();
        } else {
            let obj = {
                akcessId: akcessIDChipsarray,
                // Pdf_name: data.file_name,
                Description: "Hello! You received one document from " + sessionStorage.getItem("selectedCompanyName") + ".",
                DocumentID: data._id,
                domainName: `${environment.domainName}`,
                companyName: this.commonService.selectedCompanyDeatils.domain,
                logo: this.commonService.selectedCompanyDeatils.logo,
                recievedType: "document",
                Pdf_name: this.sendsingleEmployeeDataModal.origional_name,
                file_url: this.sendsingleEmployeeDataModal.fileUrl,
            };

            this.gs.sendDocumentByAkcessId(obj).subscribe(
                (data: any) => {
                    this._bsModalRef.hide();
                    let result = data;
                    let successID = result.data.success.toString();
                    let failureID = result.data.failure.toString();

                    if (successID.length > 0) {
                        this.toast.success(result.message);
                    }
                    if (failureID.length > 0) {
                        this.toast.error(
                            "Failed to send document to " + failureID
                        );
                    }
                    this.spinner.hide();
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                }
            );
        }

        //this.akcessIDChips.forEach(element => {
        //    if (element.split(' ')[0] != 'undefined') {
        //        akcessIDArray.push(element.split(' ')[0])
        //    }
        //});
    }

    sendDocByphone(data) {
        if (this.userwithChips_phone.length == 0) {
            this.toast.info("ENTER or SELECT by mobile number");
            return false;
        }

        this.spinner.show();
        let phoneChipsarray = [];
        let phoneNumberList = [];
        const countryCode = this.selectedCountryCode;
        const userPhoneNumber = this.userwithChips_phone;

        this.userwithChips_phone.forEach((element) => {
            if (
                element &&
                !element.name &&
                element.split(" ")[3] != "undefined" &&
                element.split(" ")[3]
            ) {
                phoneChipsarray.push(element.split(" ")[3]);
            } else if (element && element.name) {
                phoneChipsarray.push(element.name);
            }
        });

        let countryCodeList = phoneChipsarray.map((cc) => cc.slice(0, -10));

        //Check if country code is less
        // if (countryCodeList.findIndex(val => val === "") === -1) {

        //Getting the phone number
        if (countryCodeList.length === phoneChipsarray.length) {
            for (let i = 0; i < phoneChipsarray.length; i++) {
                phoneNumberList.push({
                    countryCode: countryCodeList[i].split("+")[1],
                    phone: phoneChipsarray[i].replace(countryCodeList[i], ""),
                });
            }
        }
        let obj = {
            //contacts: phoneChipsarray,
            contacts: phoneNumberList,
            // Pdf_name: data.file_name,
            Pdf_name: this.sendsingleEmployeeDataModal.origional_name,
            Description: "document",
            // phone: this.userwithChips_phone,
            DocumentID: data._id,
            domainName: `${environment.domainName}`,
            companyName: this.commonService.selectedCompanyDeatils.domain,
            logo: this.commonService.selectedCompanyDeatils.logo,
            file_url: this.sendsingleEmployeeDataModal.fileUrl,
            recievedType : "document"
        };

        this.gs.sendDocumentByphone(obj).subscribe(
            (data: any) => {
                this._bsModalRef.hide();
                let result = data;
                let successID = result.data.success.toString();
                let failureID = result.data.failure.toString();
                if (successID.length > 0) {
                    this.toast.success(result.message);
                }
                if (failureID.length > 0) {
                    this.toast.error("Failed to send document to " + failureID);
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );

        // let documentUrl = "https://sme.akcess.app/document/" + data.document_ID + "/" + data.origional_name + "/" + this.commonService.selectedCompanyDeatils.dbName;

        // this.gs.generateDocumentLink(documentUrl).subscribe((res: any) => {
        //     if (res.status) {
        //         let obj = {
        //             //contacts: phoneChipsarray,
        //             contacts: phoneNumberList,
        //             // Pdf_name: data.file_name,
        //             Pdf_name: this.sendsingleEmployeeDataModal.origional_name,
        //             Description: "document",
        //             // phone: this.userwithChips_phone,
        //             DocumentID: data._id,
        //             domainName: `${environment.domainName}`,
        //             companyName: this.commonService.selectedCompanyDeatils.domain,
        //             logo: this.commonService.selectedCompanyDeatils.logo,
        //             deepLink: res.data.shortLink
        //         };

                
        //     }
        // })

    }

    sendDocByemail(data) {
        if (this.userwithChips.length == 0) {
            this.toast.info("ENTER or SELECT email id");
            return false;
        }

        this.spinner.show();

        let emailChipsarray = [];

        this.userwithChips.forEach((element) => {
            if (
                element &&
                !element.name &&
                element.split(" ")[3] != "undefined" &&
                element.split(" ")[3]
            ) {
                emailChipsarray.push(element.split(" ")[3]);
            } else if (element && element.name) {
                emailChipsarray.push(element.name);
            }
        });

        // let documentUrl = "https://sme.akcess.app/document/" + data.document_ID + "/" + data.origional_name + "/" + this.commonService.selectedCompanyDeatils.dbName;
        let obj = {
            email: emailChipsarray,
            Pdf_name: this.sendsingleEmployeeDataModal.origional_name,
            Description: "document",
            DocumentID: data._id,
            domainName: `${environment.domainName}`,
            companyName: this.commonService.selectedCompanyDeatils.domain,
            logo: this.commonService.selectedCompanyDeatils.logo,
            file_url: this.sendsingleEmployeeDataModal.fileUrl,
            recievedType : "document"
        };

        this.gs.sendDocumentByemail(obj).subscribe(
            (data: any) => {
                this._bsModalRef.hide();
                let result = data;
                let successID = result.data.success.toString();
                let failureID = result.data.failure.toString();
                if (successID.length > 0) {
                    this.toast.success(result.message);
                }
                if (failureID.length > 0) {
                    this.toast.error("Failed to send document to " + failureID);
                }
                this.spinner.hide();
            }, (err) => {
                this.spinner.hide();
                this.toast.error("Oops, something went wrong. Please try again.")
            }
        );

    }

    //removeeFormData(e) {
    //    if (e.display.split(" ")[1]) {
    //    } else {
    //        e.display = `New ${e.display} No Phone`;
    //    }
    //    let indexOfEmail = this.userwithChips.findIndex((ele) => ele == e.display);
    //    this.userwithChips.splice(indexOfEmail, 1);
    //}

    //removeeFormData_phone(e) {
    //    if (e.display.split(" ")[1]) {
    //    } else {
    //        e.display = `New ${e.display} No Phone`;
    //    }
    //    let indexOfEmail = this.userwithChips_phone.findIndex(
    //        (ele) => ele == e.display
    //    );

    //    this.userwithChips_phone.splice(indexOfEmail, 1);
    //}

    //pusheFormData(e) {
    //    let data = e.value;
    //    let str = data.split(" ")[0];
    //    let checkEmailCustom = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,8})+$/.test(
    //        str
    //    );

    //    if (checkEmailCustom) {
    //        e.value = `New ${e.value} No Phone`;
    //    }

    //    let checkEmail = data.split(" ")[1] ? data.split(" ")[1] : "No";
    //    if (checkEmail === "No" && !checkEmailCustom) {
    //        this.toast.error("Can't add into list");
    //    } else {
    //        this.userwithChips.push(e.value);
    //        data = "";
    //        e.value = "";
    //    }
    //}

    //pusheFormData_phone(e) {
    //    let data = e.value;

    //    let phone = data.split(" ")[0];
    //    var regExp = /^\+?\d{10,15}(,\+?\d{10,15})*$/g;

    //    let checkEmailCustom = regExp.test(phone);

    //    if (checkEmailCustom) {
    //        e.value = `${e.value}`;
    //    }

    //    let checkEmail = data.split(" ")[1] ? data.split(" ")[1] : "No";
    //    if (checkEmail === "No" && !checkEmailCustom) {
    //        this.toast.error("Can't add into list");
    //    } else {
    //        this.userwithChips_phone.push(e.value);

    //        data = "";
    //        e.value = "";
    //    }
    //}

    closeModal() {
        this._bsModalRef.hide();
    }

    //ng-select
    onOpenDropdown() {
        this.selectSubmitDisabled = true;
    }

    onCloseDropdown() {
        this.selectSubmitDisabled = false;
    }

    getFullURL(file) {
        let storage_url = this.calldata.getStorageUrl();
        return `${storage_url}/document/${file}`;
    }
}
