<div class="location-page">
    <!-- Bread crumb -->
    <div class="row page-titles">
        <div class="col-md-12">
            <ol class="breadcrumb float-left">
                <li class="breadcrumb-item">
                    <a [routerLink]="['/panel/dashboard']">Dashboard</a>
                </li>
                <li class="breadcrumb-item active">Access Control</li>
            </ol>
        </div>
    </div>
    <!-- Main Section -->
    <section>
        <div class="container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="card-title row align-items-center">
                        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
                            <div class="input-group">
                                <input
                                    type="text"
                                    [(ngModel)]="searchName"
                                    (change)="
                                        searchAccessControl(
                                            'search',
                                            $event.target.value
                                        )
                                    "
                                    class="form-control"
                                    id="search"
                                    placeholder="Search"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="input-group">
                                <div
                                    ngbDropdown
                                    autoClose
                                    class="d-inline-block"
                                >
                                    <button
                                        type="button"
                                        class="btn-sorting"
                                        id="dropdownBasic1"
                                        ngbDropdownToggle
                                    >
                                        Sort by
                                    </button>
                                    <div
                                        ngbDropdownMenu
                                        autoClose
                                        aria-labelledby="dropdownBasic1"
                                    >
                                        <p class="heading">SORT BY</p>
                                        <a ngbDropdownItem>Name</a>
                                        <a ngbDropdownItem>Date</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-6 col-lg-3 col-xl-3 text-right mt-1 mt-md-0"
                        >
                            <a
                                type="button"
                                class="btn waves-effect waves-light btn-info add-btn"
                                [routerLink]="['/panel/access-control/add']"
                            >
                                <i
                                    class="fas fa-plus-circle"
                                    tooltip="Create New Access Control"
                                    placement="bottom"
                                    container="body"
                                ></i>
                                Add Access Control
                            </a>
                        </div>
                    </div>
                    <div class="action-tab">
                        <button
                            type="button"
                            class="mx-1 btn"
                            (click)="deleteRows()"
                            tooltip="Delete"
                            container="body"
                        >
                            <i class="fas fa-trash"></i>
                        </button>
                        <span class="vertical-divider">|</span>
                        <button
                            type="button"
                            class="mx-1 btn"
                            (click)="viewRecycle()"
                            tooltip="Recycle Bin"
                            container="body"
                        >
                            <i class="fal fa-recycle"></i>
                        </button>
                    </div>
                    <div class="table-responsive mb-1">
                        <table
                            class="table datatable-table color-bordered-table info-bordered-table"
                            mdbTable
                            small="true"
                            hover="true"
                        >
                            <thead>
                                <tr>
                                    <th>
                                        <div class="form-check">
                                            <input
                                                class="datatable-row-checkbox form-check-input"
                                                type="checkbox"
                                                (change)="checkUncheckAll()"
                                                [(ngModel)]="isMasterSel"
                                            />
                                        </div>
                                    </th>
                                    <th
                                        [mdbTableSort]="accessControlList"
                                        sortBy="locationName"
                                    >
                                        Name
                                        <span
                                            ><mdb-icon icon="sort" class="fas"
                                                ><i
                                                    class="fa-sort fas"
                                                ></i></mdb-icon
                                        ></span>
                                    </th>
                                    <th>Days</th>
                                    <th>Open From</th>
                                    <th>Open To</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="accessControlList?.length == 0">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        No Access Control List Found
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="accessControlList?.length > 0">
                                <tr
                                    [id]="location?._id"
                                    *ngFor="
                                        let location of accessControlList
                                            | paginate
                                                : {
                                                      itemsPerPage: pageSize,
                                                      currentPage: currentPage,
                                                      totalItems: totalCount
                                                  };
                                        let i = index
                                    "
                                >
                                    <td>
                                        <div class="form-check">
                                            <input
                                                class="datatable-row-checkbox form-check-input"
                                                type="checkbox"
                                                [(ngModel)]="
                                                    location.isSelected
                                                "
                                                [attr.id]="location?._id"
                                                value="location?._id"
                                                (change)="isAllSelected()"
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        {{ location.locationName }}
                                    </td>
                                    <td>
                                        <div
                                            *ngFor="
                                                let schedule of location.schedules
                                            "
                                            class="capitalize"
                                        >
                                            {{ getDays(schedule.days) }}
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            *ngFor="
                                                let schedule of location.schedules
                                            "
                                        >
                                            {{
                                                schedule.openFrom
                                                    | date : "hh:mm a"
                                            }}
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            *ngFor="
                                                let schedule of location.schedules
                                            "
                                        >
                                            {{
                                                schedule.openTo
                                                    | date : "hh:mm a"
                                            }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="actions-div">
                                            <button
                                                [routerLink]="[
                                                    '/panel/access-control/' +
                                                        location._id +
                                                        '/manageStaff'
                                                ]"
                                                data-toggle="tooltip"
                                                class="btn btn-link"
                                                title="Manage Staff"
                                                data-original-title="Manage Staff"
                                            >
                                                <i
                                                    class="fa fa-user-plus"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                            <a
                                                [routerLink]="[
                                                    '/panel/attendance-report'
                                                ]"
                                                [queryParams]="{
                                                    search: location._id
                                                }"
                                                data-toggle="tooltip"
                                                title="Attendance Report"
                                                data-original-title="Attendance Report"
                                                ><i
                                                    class="fal fa-chart-line"
                                                    aria-hidden="true"
                                                ></i
                                            ></a>

                                            <a
                                                [routerLink]="[
                                                    '/panel/access-control/' +
                                                        location._id +
                                                        '/view'
                                                ]"
                                                data-toggle="tooltip"
                                                title="View Access Control"
                                                data-original-title="View Access Control"
                                                ><i
                                                    class="fa fa-eye"
                                                    aria-hidden="true"
                                                ></i
                                            ></a>

                                            <a
                                                [routerLink]="[
                                                    '/panel/access-control/' +
                                                        location._id +
                                                        '/edit'
                                                ]"
                                                data-toggle="tooltip"
                                                title="Edit Access Control"
                                                data-original-title="Edit Access Control"
                                                ><i
                                                    class="fa fa-edit green-txt"
                                                    aria-hidden="true"
                                                ></i
                                            ></a>
                                            <button
                                                (click)="
                                                    deleteSingleRows(
                                                        location._id
                                                    )
                                                "
                                                title="Remove Access Control"
                                                class="btn btn-link delete_btn"
                                            >
                                                <i
                                                    class="fa fa-trash red-txt"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="w-100">
                                <tr>
                                    <td colspan="6">
                                        <div
                                            class="table-page"
                                            *ngIf="
                                                accessControlList?.length > 10
                                            "
                                        >
                                            <div class="page-controls">
                                                <pagination-controls
                                                    (pageChange)="
                                                        onProjectPageChange(
                                                            $event
                                                        )
                                                    "
                                                    class="my-pagination"
                                                    directionLinks="true"
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                ></pagination-controls>
                                            </div>
                                            <div class="page-size">
                                                <label class="mb-0 mr-2"
                                                    >Item per page</label
                                                >
                                                <select
                                                    #val
                                                    (change)="
                                                        changePage(val.value)
                                                    "
                                                    class="form-control form-control-sm"
                                                    id="pageSelector"
                                                >
                                                    <option
                                                        *ngFor="
                                                            let pG of numberOfPage
                                                        "
                                                        value="{{ pG }}"
                                                    >
                                                        {{ pG }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
