import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { GeneralServiceService } from "src/app/_services/general-service.service";
import { RefreshContentService } from "src/app/_services/refresh-content.service";
import { CommonService } from "src/app/_services/common.service";
import { AccessControl } from "src/app/models/AccessControl";
@Component({
  selector: "app-access-control",
  templateUrl: "./access-control.component.html",
  styleUrls: ["./access-control.component.css"],
})
export class AccessControlComponent implements OnInit {
  searchName: string = "";

  currentPage: any = 1;
  totalCount: any = 0;
  numberOfPage: Array<Number> = [10, 20, 30, 40, 50];
  pageSize: any = 10;
  accessControlList: Array<AccessControl> = [];
  responseId: any;
  pullDataStatus: any = "";
  isMasterSel: Boolean;
  checkedAccessControlList: any;

  constructor(
    private spinner: NgxSpinnerService,
    private ds: DataservicesService,
    private gs: GeneralServiceService,
    private toast: ToastrService,
    private refreshContentService: RefreshContentService,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isMasterSel = false;
  }

  ngOnInit(): void {
    this.refreshContentService.notifyToAll.subscribe((res) => {
      if (res && res.hasOwnProperty("option")) {
        this.getAccessControlList();
      } else {
        this.getAccessControlList();
      }
    }, (err) => {
      this.spinner.hide();
      // this.toast.error("Oops, something went wrong. Please try again.")
    });
  }

  getAccessControlList() {
    this.spinner.show();
    const params = {
      search: this.searchName,
      page: this.currentPage,
      limit: this.pageSize,
    };

    this.ds.getAccessControls(params).subscribe((res: any) => {
      if (res.status) {
        this.accessControlList = res.data.list;
        this.totalCount = res.data.total;
        this.toast.success(res.message);
        this.spinner.hide();
      } else {
        this.toast.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      // this.toast.error("Oops, something went wrong. Please try again.")
    });
  }

  onProjectPageChange(event) {
    this.currentPage = event;
    this.getAccessControlList();
  }

  changePage(value) { }

  viewRecycle() {
    this.router.navigate(["/panel/access-control/deleted"]);
  }

  searchAccessControl(type: string, value: string) {
    this.searchName = value;
    this.getAccessControlList();
  }

  getDays(days) {
    return days
      .map(function (item) {
        return item.label;
      })
      .join(", ");
  }

  checkUncheckAll() {
    for (var i = 0; i < this.accessControlList.length; i++) {
      this.accessControlList[i].isSelected = this.isMasterSel;
    }
  }

  isAllSelected() {
    this.isMasterSel = this.accessControlList.every(function (item: any) {
      return item.isSelected == true;
    });
  }

  getCheckedItemList() {
    this.checkedAccessControlList = [];
    for (var i = 0; i < this.accessControlList.length; i++) {
      if (this.accessControlList[i].isSelected)
        this.checkedAccessControlList.push(this.accessControlList[i]._id);
    }

    return this.checkedAccessControlList;
  }

  deleteSingleRows(id) {
    this.spinner.show();

    const data = {
      component: "accesscontrol",
      _id: id,
    };
    this.ds.softDelete(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.accessControlList = this.accessControlList.filter((object) => {
            return object._id !== id;
          });
          this.spinner.hide();
          this.toast.success("Access Control " + res.message);
        } else {
          this.toast.info("Access Control " + res.message);
        }
      },
      (error) => {
        this.toast.error("Access Control not deleted");
        this.spinner.hide();
      }
    );
  }

  deleteRows() {
    this.spinner.show();
    let deleteIDs = this.getCheckedItemList();

    if (deleteIDs.length > 0) {
      for (let id of deleteIDs) {
        const data = {
          component: "accesscontrol",
          _id: id,
        };
        this.ds.softDelete(data).subscribe(
          (res: any) => {
            if (res.status) {
              this.accessControlList = this.accessControlList.filter(
                (object) => {
                  return !object.isSelected;
                }
              );
              this.spinner.hide();
              this.toast.success("Access Control " + res.message);
            } else {
              this.toast.info("Access Control " + res.message);
            }
          },
          (error) => {
            this.toast.error("Access Control not deleted");
            this.spinner.hide();
          }
        );
      }
    } else {
      this.toast.error("Select a Access Control");
      this.spinner.hide();
    }
  }
}
