import { Injectable, HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { User } from "../models/user";
import { GeneralServiceService } from "./general-service.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private loggedInUser: BehaviorSubject<Object> = new BehaviorSubject<Object>(
    null
  );
  castUser = this.loggedInUser.asObservable();

  constructor(private http: HttpClient, private router: Router, public akcessService : GeneralServiceService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(sessionStorage.getItem("portalUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  setObservableData(userData) {
    this.loggedInUser.next(userData);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(data: any, logindata?:any) {

    if (data) {
      sessionStorage.setItem("portalUser", JSON.stringify(data));
      sessionStorage.setItem("logedInUserAckessId", data.akcessId);
      this.currentUserSubject.next(data);
      this.akcessService.checkinvitationUser(logindata).subscribe(
        (res)=>{
          if(res["data"]["page"] == undefined){
            if(res["data"]["page"] == 'dashboard'){
              this.router.navigate(["/panel/company"]);
            }else if (res["data"]["page"] === 'onboard'){
              // this.router.navigate(
                // [`/companyonboarding`], {state : { companyInviteId : res["data"].company_invite_id}});
                this.router.navigate([`/companyonboarding/${res["data"].company_invite_id}`], );   
                
                // this.router.navigate([`/companyonboarding/${res["data"].company_invite_id}`], );   
              }
            }else{
              this.router.navigate(["/panel/company"]);
            }
        },
        (error)=>{
                
            this.router.navigate(["/panel/company"]);          
        }
      )
      
      // this.router.navigate(["/panel/company"]);
    }
  }

  logout() {
    sessionStorage.clear();
    this.currentUserSubject.next(null);
    this.loggedInUser.next(null);
    this.router.navigate(["/login"]);
  }
}
