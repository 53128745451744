<!-- Document-Up-modal -->
<div class="modal-content no-border document-upload-modal" *ngIf="!isEdit">
    <div class="modal-header">
        <h4 class="modal-title" id="viewuserModalLabel">Upload Document</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="custom-file">
                <input type="file" class="custom-file-input" accept="application/pdf" id="importFile"
                    (change)="onFileChange($event.target.files)" />
                <!--<label class="custom-file-label" for="importFile">Choose Document</label>-->
                <label class="custom-file-label" for="importFile" *ngIf="!isFileChosen">Choose Document</label>
                <label class="custom-file-label" for="importFile" *ngIf="isFileChosen">{{ fileName }}</label>
            </div>
            <div class="invalid-feedback" *ngIf="isFileUploaded">
                You must select a document
            </div>
        </div>
        <div class="form-group">
            <label>Select Folder To Upload Document</label>
            <select class="form-control" [(ngModel)]="selectedfolder">
                <option selected value="">Select Folder</option>
                <option *ngFor="let folder of folderList" [value]="folder._id">{{folder.name}}</option>
            </select>
        </div>
        <div class="form-group">
            <label class="mr-2">Document Have Signature?</label>
            <!-- <div class="form-check"> -->
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" [(ngModel)]="isSignature" value="Yes">
                <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                </label>
                &nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked  [(ngModel)]="isSignature" value="No">
                <label class="form-check-label" for="flexRadioDefault2">
                    No
                </label>
            <!-- </div> -->
        </div>
        <div class="form-group">
            <label>Add Tags</label>
            <input type="text" class="form-control" (keyup.enter)="addTag()" [(ngModel)]="tagField"
                placeholder="Enter Tags">
        </div>
        <span *ngIf="tagArray.length > 0" class="mt-2">
            <div class="chip" *ngFor="let tag of tagArray;let i =index">
                <div class="chip-content">{{tag}}</div>
                <div class="chip-close" (click)="removeData(i)">
                    <svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path
                            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                        </path>
                    </svg>

                </div>
            </div>
        </span>
    </div>
    <div class="modal-footer">
        <!--<button type="submit" class="btn btn-primary" (click)="deleteUser(userSendData,companyID)">
                Yes
            </button>-->
        <button type="button" class="btn waves-effect waves-light btn-info" id="close" (click)="uploadDocument()">
            Upload Document
        </button>
    </div>
</div>

<!-- Document move modal -->
<div class="modal-content no-border document-upload-modal" *ngIf="isEdit">
    <div class="modal-header">
        <h4 class="modal-title" id="viewuserModalLabel">Edit Document</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Select Folder To Move Document</label>
            <select class="form-control" [(ngModel)]="moveFolderId">
                <option *ngFor="let folder of folderList" [value]="folder._id">{{folder.name}}</option>
            </select>
        </div>
        <!-- <div class="form-group">
            <label class="mr-2">Document Have Signature?</label>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" [(ngModel)]="isSignature" value="Yes">
                <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                </label>
                &nbsp;&nbsp;&nbsp;
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked  [(ngModel)]="isSignature" value="No">
                <label class="form-check-label" for="flexRadioDefault2">
                    No
                </label>
            </div>
        </div>
        <div class="form-group">
            <label>Add Tags</label>
            <input type="text" class="form-control" (keyup.enter)="addTag()" [(ngModel)]="tagField"
                placeholder="Enter Tags">
        </div>
        <span *ngIf="tagArray.length > 0" class="mt-2">
            <div class="chip" *ngFor="let tag of tagArray;let i =index">
                <div class="chip-content">{{tag}}</div>
                <div class="chip-close" (click)="removeData(i)">
                    <svg class="chip-svg" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path
                            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                        </path>
                    </svg>

                </div>
            </div>
        </span> -->
    </div>
    <div class="modal-footer">
        <!--<button type="submit" class="btn btn-primary" (click)="deleteUser(userSendData,companyID)">
                Yes
            </button>-->
        <button type="button" class="btn waves-effect waves-light btn-info" id="close" (click)="moveDocument()">
            Move Document
        </button>
    </div>
</div>