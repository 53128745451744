<!--Response Grade Modal -->
<div class="modal-content eform-response-grade-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title">View Grade</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <input type="hidden" [(ngModel)]="data" value="{{data}}" />
            <input type="hidden" [(ngModel)]="userAkcessID" value="{{userAkcessID}}" />
        </div>
        <div *ngIf="graderLoader" class="text-center">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>        
        <div *ngIf="!graderLoader">
            <div *ngIf="this.fieldVerifierDataLength > 0">
                <label class="font-500">This field has the following grades:</label>
                <div *ngFor="let data of fieldVerifierData">
                    <p *ngIf="data && data.verifier && data.verifier.grade == 'G'">Goverment</p>
                    <p *ngIf="data && data.verifier && data.verifier.grade == 'F'">Financial</p>
                    <p *ngIf="data && data.verifier && data.verifier.grade == 'T'">Telecom</p>
                    <p *ngIf="data && data.verifier && data.verifier.grade == 'A'">AKcess</p>
                    <p *ngIf="data && data.verifier && data.verifier.grade == 'O'">Other</p>
                    <p *ngIf="data && data.verifier && data.verifier.verifierName">Verifier Name - <span>{{ data.verifier.verifierName }}</span></p>
                    <p *ngIf="data && data.verifier && data.verifier.grade">Verifier Grade - <span>{{ data.verifier.grade }}</span></p>
                    <p *ngIf="data && data.verifier && data.verifier.akcessId">AKcess Id - <span>{{ data.verifier.akcessId }}</span></p>
                </div>
            </div>
            <div *ngIf="this.fieldVerifierDataLength == 0">
                <h5>No Verifier Available</h5>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">Close</button>
    </div>
</div>
