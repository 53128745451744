<div class="modal-header">
    <h5 class="modal-title">View {{userType}} Invitation List</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <tabset [justified]="true">
        <tab heading="{{userType}} Invitation" [active]="true" (selectTab)="onSelect('invitationData')">
            <table class="table color-bordered-table info-bordered-table text-center" mdbTable #tableEl="mdbTable"
                hover="true" small="true">
                <thead>
                    <tr>
                        <th class="text-left" [mdbTableSort]="data" sortBy="email">
                            Email/Phone
                            <span>
                                <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                            </span>
                        </th>
                        <th [mdbTableSort]="data" sortBy="createdAt">
                            Sent Date
                            <span>
                                <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                            </span>
                        </th>
                        <th>
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="data.length == 0" class="text-center">
                    <tr>
                        <td colspan="2" class="text-center">No sent invitation found</td>
                    </tr>
                </tbody>
                <tbody *ngIf="data.length > 0">
                    <tr *ngFor="let i of data| paginate
            : {
                itemsPerPage: pageSize,
                currentPage: currentPage,
                totalItems: totalCount
              };">
                        <td *ngIf="i.phone != null" class="text-left">+{{i.countryCode}}{{i.phone}}</td>
                        <td *ngIf="i.email != null" class="text-left">{{i.email}}</td>
                        <td>{{i.createdAt | date:'medium'}}</td>
                        <td>{{i.status ? 'Accepted' : 'Pending'}}</td>
                    </tr>
                </tbody>
                <tfoot class="w-100">
                    <tr>
                        <td colspan="4">
                            <div class="table-page" *ngIf="totalCount > 5">
                                <div class="page-controls">
                                    <pagination-controls (pageChange)="onProjectPageChange($event,'send invitation')"
                                        class="my-pagination" directionLinks="true" previousLabel="Previous"
                                        nextLabel="Next"></pagination-controls>
                                </div>
                                <div class="page-size">
                                    <label class="mb-0 mr-2">Item per page</label>
                                    <select #val (change)="changePage(val.value,'invitation')" class="form-control form-control-sm"
                                        id="pageSelector">
                                        <option *ngFor="let pG of numberOfPage" value="{{ pG }}">
                                            {{ pG }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </tab>
        <tab heading="Fetch data from AKcess ID requests" (selectTab)="onSelect('fetchData')">
            <div class="table-responsive mb-1">
                <table class="table color-bordered-table info-bordered-table" mdbTable #tableEl="mdbTable" hover="true"
                    small="true">
                    <thead class="text-center">
                        <tr>
                            <th [mdbTableSort]="userlist" sortBy="firstName">Name <span>
                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                </span></th>
                            <th [mdbTableSort]="userlist" sortBy="akcessId">By AKcess ID <span>
                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                </span></th>
                            <th [mdbTableSort]="userlist" sortBy="email">By Email Id.<span>
                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                </span></th>
                            <th [mdbTableSort]="userlist" sortBy="phone">By Mobile No.<span>
                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                </span></th>
                            <!--<th [mdbTableSort]="userlist" sortBy="usertype">User Type <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>-->
                            <th [mdbTableSort]="userlist" sortBy="createdon">Date <span>
                                    <mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon>
                                </span></th>
                            <!--<th [mdbTableSort]="userlist" sortBy="removed_flg">Active <span><mdb-icon icon="sort" class="fas"><i class="fa-sort fas"></i></mdb-icon></span></th>-->
                            <th class="text-nowrap">User Type</th>
                            <th class="text-nowrap">Status</th>
                            <th class="text-nowrap">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="userlist?.length == 0">
                        <tr>
                            <td colspan="9" class="text-center">No Data Found</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="userlist?.length > 0" class="text-center">
                        <tr [id]="data?._id"
                            *ngFor="let data of userlist | paginate: { itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalCount } ;">
                            <td>
                                <a class="d-inline-block">
                                    <span>{{data.firstName ? data.firstName + ' '+ data.lastName : '-'}}</span>
                                </a>
                            </td>
                            <td>
                                <a class="d-inline-block">
                                    {{data.akcessId ? data.akcessId : '-'}}
                                </a>
                            </td>
                            <td>
                                <a class="d-inline-block">
                                    {{data.email ? data.email : '-'}}
                                </a>
                            </td>
                            <td>
                                <a class="d-inline-block">
                                    {{data.phone ? '+' + data.countryCode + data.phone : '-'}}
                                </a>
                            </td>
                            <td>
                                <a class="d-inline-block">
                                    <span *ngIf="data.createdon">{{data.createdon | date:'medium'}}</span>
                                    <span *ngIf="!data.createdon">-</span>
                                </a>
                            </td>
                            <td>
                                <a class="d-inline-block">
                                    {{data.usertype ? data.usertype : '-'}}
                                </a>
                            </td>
                            <td>
                                <a class="d-inline-block">
                                    <span class="badge badge-primary"
                                        *ngIf="data.userStatus == 'Pending'">{{data.userStatus}}</span>
                                    <span class="badge badge-success"
                                        *ngIf="data.userStatus == 'Verified/Submitted'">{{data.userStatus}}</span>
                                </a>
                            </td>
                            <td>
                                <a class="d-inline-block" (click)="resendInvitation(data)"
                                    [ngStyle]="{'pointer-events': data.firstName ? 'none' : 'fill'}">
                                    Resend Invitation
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot class="w-100">
                        <tr>
                            <td colspan="7">
                                <div class="table-page" *ngIf="totalCount > 1">
                                    <div class="page-controls">
                                        <pagination-controls (pageChange)="onProjectPageChange($event,'akcessId request')"
                                            class="my-pagination" directionLinks="true" previousLabel="Previous"
                                            nextLabel="Next"></pagination-controls>
                                    </div>
                                    <div class="page-size">
                                        <label class="mb-0 mr-2">Item per page</label>
                                        <select #val (change)="changePage(val.value,'akcessId request')"
                                            class="form-control form-control-sm" id="pageSelector">
                                            <option *ngFor="let pG of numberOfPage" value="{{pG}}">{{pG}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </tab>
    </tabset>

</div>