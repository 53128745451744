<div class="modal-content no-border document-upload-modal">
    <div class="modal-header">
        <h4 class="modal-title" id="viewuserModalLabel">{{btnText}} Folder</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group" [formGroup]="createFolderForm">
            <div class="row">
                <div class="col-12">
                    <input class="form-control" type="text" formControlName="folderName" placeholder="Folder Name">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-info" id="close"
            [disabled]="createFolderForm.invalid" (click)="submit()">
            {{btnText}}
        </button>
    </div>
</div>