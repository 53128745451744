import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-edit-assets",
    templateUrl: "./edit.component.html",
    styleUrls: ["./edit.component.css"],
})
export class EditAssetsComponent implements OnInit {
    assetId: string;
    constructor(private route: ActivatedRoute, private router: Router) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.assetId = params.assetId;
        });
    }

    ngOnInit(): void {}
}
