import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { DataservicesService } from "src/app/_services/dataservices.service";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";

@Component({
    selector: 'app-credit-score-black-list',
    templateUrl: './credit-score-black-list.component.html',
    styleUrls: ['./credit-score-black-list.component.css']
})
export class CreditScoreBlackListComponent implements OnInit {
    clientList: any = [];
    search: any = '';
    sortByScore: any = '';
    sortByCreditScore: any = '';

    constructor(
        private calldata: DataservicesService,
        private spinner: NgxSpinnerService,
        private toast: ToastrService,
    ) { }

    ngOnInit(): void {
        this.getCreditScoreList();
    }

    getCreditScoreList() {
        this.spinner.show()
        this.calldata.clientCreditScoreList({ search: this.search, sortByScore: this.sortByScore, sortByCreditScore: this.sortByCreditScore }).subscribe((res: any) => {
            this.clientList = res.data;
            this.spinner.hide();
        }, (err) => {
            this.spinner.hide();
            this.toast.error("Oops, something went wrong. Please try again.")
        })
    }

    sortByScoreMethod(val) {
        this.sortByCreditScore = '';
        this.sortByScore = val;
        this.getCreditScoreList();
    }

    sortByCreditScoreMethod(val) {
        this.sortByScore = '';
        this.sortByCreditScore = val;
        this.getCreditScoreList();
    }

}
