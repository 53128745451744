import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ElementRef } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { DataservicesService } from "src/app/_services/dataservices.service";

import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-property-inquiry-form",
    templateUrl: "./inquiry-form.component.html",
    styleUrls: ["./inquiry-form.component.css"],
})
export class PropertyInquiryFormComponent implements OnInit {
    propertyId: string;
    dbName: string;
    propertyInquiryFG: FormGroup;
    public isSubmitted: boolean = false;
    allFields = [];
    countryList: any = [];
    property: any = {};
    storage_url = environment.url + "storage/";
    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];
    propertyCompany: any = {};

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private calldata: DataservicesService,
        private toast: ToastrService,
        private el: ElementRef,
        private spinner: NgxSpinnerService,
        public http: HttpClient
    ) {
        let me = this;
        this.route.params.subscribe((params) => {
            me.propertyId = params.propertyId;
            me.dbName = params.dbName;
        });

        this.propertyInquiryFG = this.formBuilder.group({
            property_id: [this.propertyId, [Validators.required]],
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
            mobile: [undefined, [Validators.required]],
            notes: [null, []],
        });
    }

    ngOnInit(): void {
        if (this.propertyId) {
            this.spinner.show();

            this.calldata
                .listCompanyBydbName({ dbName: this.dbName })
                .subscribe((response: any) => {
                    if (response.status) {
                        this.propertyCompany = response.data;
                    }
                });

            this.calldata
                .getPropertyForInquiry(this.propertyId, this.dbName)
                .subscribe((response: any) => {
                    if (response.status) {
                        this.property = response.data;
                        this.spinner.hide();
                    } else {
                        this.spinner.hide();
                        this.toast.error(response.message);
                    }
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                });

            this.getCountryList();
        }
    }

    changePreferredCountries() {
        this.preferredCountries = [CountryISO.India, CountryISO.Canada];
    }

    getCountryList() {
        this.http.get("/assets/country-list.json").subscribe((res: any) => {
            res.filter((e) => {
                this.countryList.push(e.label);
            });
        });
    }

    submitFrom() {
        this.isSubmitted = true;
        let number: any = this.propertyInquiryFG.controls.mobile.value;
        let formData = this.propertyInquiryFG.value;
        if (number !== null) {
            let phoneNumber = number.e164Number.split(number.dialCode);
            let countryCode = number.dialCode.split("+");
            this.propertyInquiryFG.controls.mobile = phoneNumber[1];
            formData.mobile = phoneNumber[1];
        }
        if (this.propertyInquiryFG.valid) {
            this.spinner.show();
            this.calldata
                .submitPropertyInquiryForm(formData, this.dbName)
                .subscribe((res) => {
                    this.spinner.hide();
                    this.isSubmitted = false;
                    this.toast.success(res["message"]);
                    setTimeout(() => {
                        this.reloadComponent(true);
                    }, 1000);
                }, (err) => {
                    this.spinner.hide();
                    this.toast.error("Oops, something went wrong. Please try again.")
                });
        }
    }

    reloadComponent(self: boolean, urlToNavigateTo?: string) {
        const url = self ? this.router.url : urlToNavigateTo;
        this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => {
                this.router.navigate([`/${url}`]);
            });
    }
}
