
<div class="row">
    <div class="col-md-12">
        <div class="card card-user">
            <div class="card-body" id="pdfGenerate">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-10 d-flex justify-content-center">
                        <qrcode [qrdata]="url" [width]="240" [errorCorrectionLevel]="'M'" style="border: 2px solid black;"></qrcode>
                    </div>

                    <div class="col-md-10 d-flex justify-content-center">
                        <br>
                    </div>
                    
                    <div class="col-md-10 d-flex justify-content-center">
                        <table>
                            <tr>
                                <td style="font-size: 20px; padding-right: 10px;">Name: </td>
                                <td style="font-size: 20px; color: #1680b5;">{{ data?.merchantName | titlecase }}</td>
                            </tr>
                            <tr>
                                <td style="font-size: 20px; padding-right: 10px;">Location: </td>
                                <td style="font-size: 20px; color: #1680b5;">{{ data?.location | titlecase }}</td>
                            </tr>
                            <tr>
                                <td style="font-size: 20px; padding-right: 10px;">Url: </td>
                                <td style="font-size: 20px;"><a href="{{url}}" style="color: #1680b5;" target="_blank">Visit by url</a></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <button class="btn btn-info" (click)="print()">Download & Print</button>
        </div>
    </div>
</div>

