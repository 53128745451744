<!--Response Grade Modal -->
<div class="modal-content eform-response-expiry-modal no-border">
    <div class="modal-header">
        <h4 class="modal-title">Add Expiry Date</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <input type="hidden" [(ngModel)]="index" value="{{index}}" />
            <input type="hidden" [(ngModel)]="fields" value="{{fields}}" />
        </div>
        <div class="form-group">
            <label class="font-500">Expiry Date</label>
            <!-- OLD CODE -->
            <!--<p-calendar [(ngModel)]="addExpiryDate" 
                [minDate]="minDate" 
                name="addExpiryDate" 
                [readonlyInput]="true" 
                inputId="min-max"></p-calendar>-->
            <!-- NEW CODE -->
            <input type="text"
                   [(ngModel)]="addExpiryDate"
                   placeholder="DD-MM-YYYY"
                   class="form-control"
                   bsDatepicker
                   [minDate]="minDate"
                   [bsConfig]="{ isAnimated: true, adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-red' }">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn waves-effect waves-light btn-info" (click)="pushExpiryDateInFiedls()">Submit</button>
        <button type="button" class="btn waves-effect waves-light btn-danger" (click)="closeModal()">Close</button>
    </div>
</div>